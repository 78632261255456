import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { SegmentInterface } from "interfaces/ProfileEditor";

interface SegmentProps {
    name: string,
    order: number,
    segment: SegmentInterface,
    editSegmentName: (name: string, index: number) => void,
    removeChannelFromFixture: (deleteKey: number, type: string) => void,
    changeSegmentValues: (value: string, type: string, order: number) => void,
}

const Segment = ({
    name,
    order,
    segment,
    editSegmentName,
    removeChannelFromFixture,
    changeSegmentValues,
}: SegmentProps) => {
    return (
        <Grid style={{ alignItems: "baseline" }} container>
            <Grid sm={1} className="profile-order" item>
                {order + 1}
            </Grid>
            <Grid sm={10} item>
                <Grid container alignItems="center">
                    <Grid sm={6} className="channel-name" item>
                        <TextField
                            style={{ width: "100%" }}
                            id="channel-name-edit"
                            defaultValue={name}
                            onChange={(e) => editSegmentName(e.target.value, order)}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid sm={2} item>
                        <input
                            type="number"
                            defaultValue={segment.start}
                            onChange={(e) =>
                                changeSegmentValues(e.target.value, "start", order)
                            }
                            style={{ width: "100%", color: "rgb(83, 98, 108)" }}
                            id="segment-name-edit"
                            // margin="normal"
                            // variant="outlined"
                        />
                    </Grid>
                    <Grid sm={1} item style={{ textAlign: "center" }}>
                        <i
                            style={{ color: "#173245" }}
                            className="far fa-chevron-right"
                        ></i>
                    </Grid>
                    <Grid sm={2} item>
                        <input
                            type="number"
                            value={segment.end}
                            onChange={(e) =>
                                changeSegmentValues(e.target.value, "end", order)
                            }
                            style={{ width: "100%", color: "rgb(83, 98, 108)" }}
                            id="segment-name-edit"
                            // margin="normal"
                            // variant="outlined"
                        />
                    </Grid>
                    <Grid
                        sm={1}
                        item
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            type="submit"
                            onClick={() => removeChannelFromFixture(segment.key, "segments")}
                            className="btn"
                        >
                            <i style={{ color: "#90293a" }} className="far fa-trash-alt"></i>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Segment;
