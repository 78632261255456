import React from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { SideDrawerTab, ViewContext } from "context/View";
import { UserContext } from "context/User";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { RootState } from "reducers";
import Loading from "components/utils/Loading";
import { makeAxiosCall } from "utils";

interface AwaitingApprovalInterface {
    title: string,
    subtitle: string,
    link: string
}

const AwaitingApprovals = () => {
    const { saveCurrentPanel } = React.useContext(ViewContext);
    const dispatch = useDispatch();
    const so = useSelector((state: RootState) => state.so);
    const { currentUser, internalUser } = React.useContext(UserContext);
    const [awaiting, setAwaiting] = React.useState<AwaitingApprovalInterface[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        if(internalUser) {
            makeAxiosCall("get", `awaiting-approval`, {}, {timeout: 30000}).then((res) => {
                setLoading(false);
                if(res.data) {
                    let newAwaiting: AwaitingApprovalInterface[] = [];
                    for (let ai = 0; ai < res.data.users.length; ai++) {
                        const account = res.data.users[ai];
                        
                        newAwaiting.push({
                            title: account.first_name + " " + account.last_name,
                            subtitle: account.portal_account_signup_status.charAt(0).toUpperCase() + account.portal_account_signup_status.substr(1).toLowerCase(),
                            link: `/edit-user/${account.id}`
                        });
                    }

                    for (let ai = 0; ai < res.data.portal_new_reseller_invites.length; ai++) {
                        const invite = res.data.portal_new_reseller_invites[ai];
                        
                        newAwaiting.push({
                            title: invite.new_user.email,
                            subtitle: "Needs Company Assignment",
                            link: `/edit-user/${invite.new_user.id}`
                        });
                    }

                    for (let ci = 0; ci < res.data.portal_companies.length; ci++) {
                        const company = res.data.portal_companies[ci];
                        
                        newAwaiting.push({
                            title: company.name,
                            subtitle: "Awaiting approval",
                            link: `/edit-company/${company.id}`
                        });
                    }

                    setAwaiting(newAwaiting);
                }
            });
        }

    }, [currentUser]);

    return (
        <Paper style={{ padding: 15, height: "100%", minHeight: 300, overflow: "scroll", borderRadius: 10 }} >
            <Grid container alignItems="center" >
                <Grid item xs={6} xl={9}>
                    <h3 className="block-title reveal-text" style={{ padding: "5px" }}>Awaiting Approval</h3>
                </Grid>
                <Grid item xs={6} xl={3}>
                    <Link to="admin">
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            className="btn blue"
                            onClick={() => saveCurrentPanel(SideDrawerTab.orders)}
                        >
                            Admin Console
                        </Button>
                    </Link>
                </Grid>
                <Grid item container xs={12} style={{ padding: "0 10px", fontWeight: "bold" }}>
                    <Grid item xs={6}>Who</Grid>
                    <Grid item xs={6} style={{ textAlign: "center" }}>What</Grid>
                </Grid>
                <Grid item container xs={12} style={{maxHeight: 340, overflow: "scroll"}}>
                    {!loading ? (awaiting && awaiting.length > 0 ? awaiting.map(
                        (a, i) => {
                            return (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    key={i}
                                    className="line-item home-so"
                                    style={{ margin: "6px 0" }}
                                    component={Link}
                                    to={`${a.link}`}
                                >
                                    <Grid item xs={6} >{a.title}</Grid>
                                    <Grid item xs={6} style={{ textAlign: "center" }}>{a.subtitle}</Grid>
                                </Grid>
                            );
                        }
                        
                    ) : <h2 style={{ margin: "20px auto" }}>All good! 👍</h2>) : <Loading height="20vh" position="relative" title="" />}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AwaitingApprovals;
