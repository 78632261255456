
export interface NewsfeedPostInterface {
    title: string,
    audience: number,
    id?: number,
    createdAt?: Date,
    topText?: string,
    bottomText?: string,
    imageUrl?: string,
    videoUrl?: string,
    tags?: string[],
}

export const buildNewsfeedPost = (rawData): NewsfeedPostInterface => {
    return {
        id: rawData.id,
        createdAt: rawData.created_at ? new Date(rawData.created_at) : null,
        title: rawData.title,
        audience: rawData.audience,
        topText: rawData.top_text ? rawData.top_text : "",
        bottomText: rawData.bottom_text ? rawData.bottom_text : "",
        imageUrl: rawData.image_url ? rawData.image_url : "",
        videoUrl: rawData.video_url ? rawData.video_url : "",
        tags: rawData.tags ? rawData.tags : [],
    };
};

export const buildPostList = (rawListData): NewsfeedPostInterface[] => {
    return rawListData.map((data) => buildNewsfeedPost(data));
};