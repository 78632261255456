import { CartContext } from "context/Cart";
import { UserContext } from "context/User";
import { useNavigate } from "react-router-dom";

import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { makeAxiosCall } from "utils";
import { OrderInterface } from "interfaces/Order";
import { SideDrawerTab, ViewContext } from "context/View";

interface OrderButtonProps {
  existingOrder?: OrderInterface,
}

const OrderButton = ({ existingOrder }: OrderButtonProps) => {
  const { 
    currentOrder, 
    clearCart, 
    orderingOnBehalfOf,
    orderStatusOptions,
    currentOrderNote
  } = React.useContext(CartContext);
  const { currentUser } = React.useContext(UserContext);
  const { saveCurrentPanel } = React.useContext(ViewContext);
  const [submitting, setSubmitting] = React.useState(false);
  const [order, setOrder] = React.useState<OrderInterface>();
  let navigate = useNavigate();

  React.useEffect(() => {
    if (existingOrder) {
      setOrder(existingOrder);
    } else {
      setOrder(currentOrder);
    }
  }, [existingOrder]);

  // const getOrderNo = async () => {
  //   //eslint-disable-next-line
  //   makeAxiosCall(
  //     "get",
  //     `get-latest-order/${currentUser.company.fishbowlCustomerId}`,
  //   ).then((res) => {
  //     sendEmail(res.data);
  //   });
  // };

  // const calculateTotal = () => {
  //   let total = 0;

  //   po.forEach(function (value, index, arry) {
  //     if (value.qtyPrice < value.price) {
  //       total += +value.qtyPrice * value.qty;
  //     } else {
  //       total += +value.price * value.qty;
  //     }
  //   });
  //   return total;
  // };

  // const sendAttemptMessage = async () => {
  //   const webhookURL =
  //     "https://discord.com/api/webhooks/822540271057240064/xYEu_VBa0d-x6zkGoQN2OwAwg_W67s_zTHu_wreAldeycB8XJVDQHLOhScdaMRG_Vp3N";

  //   const discordMessage = {
  //     embeds: [
  //       {
  //         color: 3447003,
  //         title: `Customer (${user.email}) is checking out via the portal`,
  //         url: "http://portal.blizzardpro.com",
  //       },
  //     ],
  //   };
  //   makeAxiosCall(
  //     "post",
  //     webhookURL,
  //     discordMessage
  //   )
  //   .then((result) => {})
  //   .catch((err) => console.log(err));
  // };

  // const sendDiscordMessage = async (order) => {
  //   const webhookURL =
  //     "https://discord.com/api/webhooks/822540271057240064/xYEu_VBa0d-x6zkGoQN2OwAwg_W67s_zTHu_wreAldeycB8XJVDQHLOhScdaMRG_Vp3N";

  //   const discordMessage = {
  //     embeds: [
  //       {
  //         color: 3447003,
  //         title: `Order Queued for Fishbowl Import`,
  //         url: "http://portal.blizzardpro.com",
  //         fields: [
  //           {
  //             name: "Customer",
  //             value: currentUser.email,
  //           },
  //         ],
  //       },
  //     ],
  //   };
  //   makeAxiosCall(
  //     "post",
  //     webhookURL,
  //     discordMessage,
  //   )
  //   .then((result) => {})
  //   .catch((err) => console.log(err));
  // };

  // const sendEmail = (orderId: number) => {

  //   /* EMAIL TEMPLATE REQUIREMENTS 

  //     - User who made the order
  //     - Flag for Ordering On Behalf of 
  //     - Company name
  //     - Shipping Address
  //     - Billing Address
  //     - Order Summary
  //     - Itemized List


  //     - Send to user who made the order
  //     - If order made on behalf of, send order to their company
  //     - Send to orders@blizzardpro.com
  //   */

  //   let templateParams = {
  //     orderedByEmail: currentUser.email,
  //     orderedByName: currentUser.displayName && currentUser.displayName.length ? currentUser.displayName : currentUser.firstName + currentUser.lastName,
  //     companyName: orderingOnBehalfOf ? orderingOnBehalfOf.name : currentUser.company.name,
  //     orderSummary: buildOrderForEmail(),
  //     billingAddress: buildAddressForEmail(order.billingAddress, "Billing Address"),
  //     shippingAddress: buildAddressForEmail(order.shippingAddress, "Shipping Address"),
  //     // notes: buildNotesForEmail(),
  //     orderingOnBehalfOfNote: orderingOnBehalfOf && orderingOnBehalfOf.id != currentUser.company.id ? "<h3>This order is being made on behalf of another company.</h3>" : "",
  //     poDownloadLink: buildPODownloadLinkForEmail(),
  //     orderLink: buildOrderLinkForEmail(orderId)
  //   };

  //   emailjs
  //     .send(
  //       "sendgrid",
  //       "temp_order_confirmed",
  //       templateParams,
  //       "user_oZweBlOTjKeqsJOmkI2PM"
  //     )
  //     .then(
  //       () => {
  //         clearCart();
  //         saveCurrentPanel(SideDrawerTab.home);
  //         navigate(`/checkout/thank-you`);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };


  // const buildOrderLinkForEmail = (orderId: number) => {
    
  //   let body = `<div>
  //     <h3>View Order</h3>
  //     <a href="https://portal.blizzardpro.com/pending-orders/${orderId}" target="_blank">To the Portal</a>
  //   </div>`;

  //   return body;
  // };

  // const buildPODownloadLinkForEmail = () => {
    
  //   if(order.customerPOURL && order.customerPOURL.length > 0) {
  //     let body = `<div>
  //       <h3>Customer PO File</h3>
  //       <a href="${order.customerPOURL}" target="_blank">Download Link</a>
  //     </div>`;

  //     return body;
  //   } else {
  //     return "";
  //   }
  // };


  // const buildAddressForEmail = (address: AddressInterface, title: string) => {
  //   let body = `<div>
  //     <h3>${title}</h3>
  //     <p>${address.contactName}</p>
  //     <p>${address.streetAddress}</p>
  //     ${address.streetAddressTwo ? ("<p>" + address.streetAddressTwo + "</p>") : ""}
  //     <p>${address.city}, ${address.state}</p>
  //     <p>${address.zip}</p>
  //     ${address.country && address.country != "United States"  ? ("<p>" + address.country + "</p>") : ""}
  //   </div>`;

  //   return body;
  // };

  // const buildOrderForEmail = () => {
  //   let body = `<table border="0" cellpadding="0" cellspacing="0" align="center" width=100%>
  //   <tbody>
  //     <tr>
  //       <td style="padding-top:24px" align="left">
  //         Item
  //       </td>
  //       <td style="padding-top:24px" align="center">
  //         Qty
  //       </td>
  //       <td style="padding-top:24px" align="right">
  //         Total
  //       </td>
  //     </tr>
  //     ${buildLineItemForEmail()}
  //   </tbody>
  // </table>`;

  //   return body;
  // };

  // const buildLineItemForEmail = () => {
  //   const lineItem = _.map(order.orderItems, (obj: OrderItemInterface) => {
  //     return `<tr>
  //         <td align="left" style="padding-top:16px" valign="top">
  //           <span style="height:20px;font-family:Arial;font-size:14px;font-weight:bold;line-height:1.43;letter-spacing:normal;text-align:left;color:#000000">
  //             ${obj.product.name}
  //           </span>
  //           <br />
  //           <span>
  //             <b>$ - </b>
  //           </span>
  //         </td>
  //         <td align="center" style="padding-top:16px" valign="top">
  //           <span style="height:20px;font-family:Arial;font-size:14px;line-height:1.43;letter-spacing:normal;text-align:left;color:#000000">
  //             ${obj.quantity}
  //           </span>
  //         </td>
  //         <td align="right" style="padding-top:16px" valign="top">
  //           <span style="height:20px;font-family:Arial;font-size:14px;line-height:1.43;letter-spacing:normal;text-align:left;color:#000000">
  //           $ - 
  //           </span>
  //         </td>
  //       </tr>`;
  //   });

  //   return lineItem;
  // };

  // const buildNotesForEmail = () => {
  //   let note = "<div><h3>Notes</h3>";
  //   if (currentOrder.notes !== "") {
  //     note = note.concat(`<p>Customer Note: ${currentOrder.notes}</p>`);
  //   }
  //   note = note.concat('</div>');
  //   return note;
  // };

  const createOrder = async (): Promise<number> => {
    if(order.id) {
      return submitExistingOrder();
    }

    let poNum = order.customerPONum;
    if(!poNum || poNum.trim().length < 1) {
      poNum = new Date().toLocaleString("en-us", {dateStyle: "short"});
    }

    const response = await makeAxiosCall(
      "post",
      `order`,
      {
        ordered_by_id: currentUser.uid,
        company_id: orderingOnBehalfOf ? orderingOnBehalfOf.id : currentUser.company.id,
        billing_address_id: order.billingAddress.id,
        shipping_address_id: order.shippingAddress.id,
        billing_contact_id: order.billingContact.id,
        shipping_contact_id: order.shippingContact.id,
        customer_po_upload_url: order.customerPOURL,
        customer_po_num: poNum,
        notes: currentOrderNote && currentOrderNote.length > 0 ? [
          {
            content: currentOrderNote
          }
        ] : [],
        authorize_payment_profile_id: order.authorizePaymentProfileId,
        order_status_id: orderStatusOptions.find((s)=> s.name == "Submitted").id,
        drop_ship: order.isDropShip,
        preferred_packing_list_upload_url: order.preferredPackingListURL,
        shipping_preference: {
          account_number: order.shippingPreference.accountNumber,
          carrier: order.shippingPreference.carrier,
          service: order.shippingPreference.service
        },
        order_items: order.orderItems
      },
      { headers: {"Content-Type": "application/json"} },
    ).catch(error => {
      console.log(error);
      return 0;
    });

    return response.data;
  }


  const submitExistingOrder = async (): Promise<number> => {
    const result = await makeAxiosCall(
      "post",
      `order/${order.id}`,
      {
        id: order.id,
        company_id: order.company.id,
        ordered_by_id: order.orderedBy.id,
        billing_address_id: order.billingAddress.id,
        shipping_address_id: order.shippingAddress.id,
        billing_contact_id: order.billingContact.id,
        shipping_contact_id: order.shippingContact.id,
        order_items: order.orderItems,
        order_status_id: orderStatusOptions.find((option) => option.name == "Submitted").id,
      }
    ).catch((error) => {
      console.error(error);
    });

    if (result && result.data && result.data.id) {
      return result.data.id;
    } else {
      console.log("ERROR WHILE SAVING");
      return 0;
    }
  }

  const submitOrder = async () => {
    setSubmitting(true);
    const orderId = await createOrder();

    if(orderId) {
      clearCart();
      saveCurrentPanel(SideDrawerTab.home);
      navigate(`/checkout/thank-you`);
      // sendEmail(orderId);
    } else {
      // ORDER NOT CREATED
      setSubmitting(false);
    }

    

    // let billingAddress = currentAddress.streetAddressTwo.length > 0 ?
    //     currentAddress.streetAddress + "\n" + currentAddress.streetAddressTwo :
    //     currentAddress.streetAddress;

    // let shippingAddress = currentBilling.streetAddressTwo.length > 0 ?
    //     currentBilling.streetAddress + "\n" + currentBilling.streetAddressTwo :
    //     currentBilling.streetAddress;

    // const order = {
    //   flag: "SO",
    //   customerName: currentCustomer.name,
    //   soNumber: "",
    //   carrierName: currentCustomer.carrierName, //default
    //   billToAddress: billingAddress,
    //   billToCity: currentAddress.city,
    //   billToCountry: currentAddress.country,
    //   billToName: currentAddress.contactName,
    //   billToState: currentAddress.state,
    //   billToZip: currentAddress.zip,
    //   carrierId: currentCustomer.defaultCarrierId, //default
    //   carrierServiceId: currentCustomer.carrierServiceId, //default
    //   customerContact: currentBilling.contactName, //change to default billing
    //   customerId: currentCustomer.id,
    //   poNum: "",
    //   dateIssued: "",
    //   email: currentUser.email, //billing email
    //   note: renderNote(),
    //   paymentTermsId: currentCustomer.defaultPaymentTermsId ? currentCustomer.defaultPaymentTermsId : "33", //default payment terms
    //   salesmanId: currentCustomer.defaultSalesmanId, // default customer
    //   shipTermsId: currentCustomer.defaultShipTermsId, //default
    //   shipToAddress: shippingAddress,
    //   shipToCity: currentBilling.city,
    //   shipToCountry: currentBilling.country,
    //   shipToName: currentBilling.contactName,
    //   shipToState: currentBilling.state,
    //   shipToZip: currentBilling.zip,
    //   status: "20",
    //   taxRateId: currentCustomer.taxRateId,
    //   taxRateName:
    //     currentCustomer && currentCustomer.taxRateName
    //       ? currentCustomer.taxRateName
    //       : "None",
    //   typeId: "10",
    // };

    // const getUnitPricing = (prod) => {
    //   if (prod.salesPricing) {
    //     return prod.salesPricing;
    //   } else if (prod.bstockDiscount) {
    //     return prod.price - prod.price * prod.bstockDiscount;
    //   } else {
    //     return prod.price;
    //   }
    // };

    // let item = [];
    // //eslint-disable-next-line
    // const consolidateItems = _.map(cart, (p) => {
    //   const poItem = {
    //     flag: p.flag,
    //     typeId: p.typeId,
    //     productNum: p.productNum,
    //     productDescription: p.description,
    //     qty: p.qty,
    //     uomId: p.uomId,
    //     productPrice: getUnitPricing(p),
    //   };
    //   item.push(poItem);
    // });

    // let uid = generateUID();

    // let lineItemHTML = [];
    // let lb = "..................................";

    // _.map(item, (lineItem, i) => {
    //   const htmlObj = `<div>
    //     <h3>Product: ${lineItem.productNum}</h3>
    //     <h3>Qty: ${lineItem.qty}</h3>
    //     <h3>Price per: ${Intl.NumberFormat("en-US", {
    //       style: "currency",
    //       currency: "USD",
    //     }).format(lineItem.productPrice)}</h3>
    //     </div>${lb}`;
    //   console.log(htmlObj);
    //   lineItemHTML.push(htmlObj);

    //   if (i === lineItemHTML.length - 1) {
    //     return lineItemHTML;
    //   }
    // });

    // const triggerHubspotWorkflow = () => {
    //   const emailBody = `
    //     <h3>Customer Name: ${order.customerName}</h3>
    //     <h3>Customer Email: ${order.email}</h3>
    //     <h3>PO Number: ${order.poNum}</h3>
    //     ${lb}
    //     <h3>Bill To Name: ${order.billToName}</h3>
    //     <h3>Bill To Address: ${order.billToAddress}</h3>
    //     <h3>Bill To City: ${order.billToCity}</h3>
    //     <h3>Bill To State: ${order.billToState}</h3>
    //     <h3>Bill To Zip: ${order.billToZip}</h3>
    //     ${lb}
    //     <h3>Ship To Name: ${order.shipToName}</h3>
    //     <h3>Ship To Address: ${order.shipToAddress}</h3>
    //     <h3>Ship To City: ${order.shipToCity}</h3>
    //     <h3>Ship To State: ${order.shipToState}</h3>
    //     <h3>Ship To Zip: ${order.shipToZip}</h3>
    //     <h3>Note: ${order.note}</h3>
    //     ${lb}
    //     ${lineItemHTML}
    //   `;

    //   const obj = {
    //     email: order.email,
    //     order_info: emailBody,
    //   };

    //   updateHubspotContact(obj);
    // };

    // triggerHubspotWorkflow();

    // makeAxiosCall(
    //   "post",
    //   "submit-order",
    //   {
    //     ...order,
    //     lineItems: [...item],
    //     uid: uid,
    //     submittedAt: Date.now(),
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   },
    // )
    // .then(() => {
    //   localStorage.removeItem("cart");
    //   clearCart();
    //   navigate(`/checkout/thank-you`);
    //   // sendDiscordMessage(order);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });

    // db.collection("draftOrders")
    //   .doc(uid)
    //   .set({
    //     ...order,
    //     lineItems: [...item],
    //     uid: uid,
    //     submittedAt: Date.now(),
    //   })
    //   .then(() => {
    //     navigate(`/checkout/thank-you`);
    //     // sendDiscordMessage(order);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // makeAxiosCall(
    //   "post",
    //   "submitOrder/",
    //   [order, ...item],
    // )
    // .then((res) => {
    //   getOrderNo();
    //   localStorage.removeItem("po");
    //   setPo([]);
    // })
    // .catch((error) => console.log(error));
  };

  // const selectedCC = activeCC.customerPaymentProfileId !== undefined;

  return (
    <div>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={submitOrder}
        className="btn"
        fullWidth={true}
      >
        {submitting ? (
          <CircularProgress 
            color="inherit"
            size={24}
          />
        ) : (
          "Submit PO"
        )}
      </Button>
      {/* {!selectedCC &&
        currentCustomer &&
        currentCustomer.defaultPaymentTermsName === "Prepaid" && (
          <p className="highlight red">Please select a payment method</p>
        )} */}
    </div>
  );
};

export default OrderButton;
