import React, { useCallback, useState } from "react";
import { app } from "base.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

interface ForgotPasswordProps {
    setActiveAuth: (auth: string) => void,
}

const ForgotPassword = ({ setActiveAuth }: ForgotPasswordProps) => {
    const [errorMsg, setErrorMsg] = useState("");

    const handleForgottenPassword = useCallback(async event => {
        event.preventDefault();
        const { email } = event.target.elements;
        try {
            await app.auth().sendPasswordResetEmail(email.value);
            setErrorMsg(
                "An email with instructions to reset your password have been sent to the email you've entered."
            );
        } catch (error) {
            alert(error);
        }
    }, []);

    return (
        <div>
            <p className="underline" onClick={() => setActiveAuth("login")}>
                <i className="fad fa-arrow-left" style={{ marginRight: 10 }}></i>Login
            </p>

            <h1>Forgot Your Password?</h1>
            <p>
                Enter your email address and we&apos;ll send you instructions on how to reset
                your password.
            </p>
            <form onSubmit={handleForgottenPassword}>
                <TextField
                    className="input-full"
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                        name: "email"
                    }}
                />
                <Button
                    style={{ width: "100%" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Send Email
                </Button>
                <p>{errorMsg}</p>
            </form>
        </div>
    );
};

export default ForgotPassword;
