import React from "react";
import 'scss/components/Checkout/OrderOnBehalfOf.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { CartContext } from "context/Cart";
import { SideDrawerTab, ViewContext } from "context/View";
import { useNavigate } from "react-router-dom";

const NewDemoButton = ({topNavButton}: {topNavButton?: boolean}) => {
  
    const { saveOrderingDemo } = React.useContext(CartContext);
    const { saveCurrentPanel } = React.useContext(ViewContext);

    const [showDemoInfoOpen, setDemoShowInfo] = React.useState<boolean>(false);
    const [moreDemoInfo, setMoreDemoInfo] = React.useState<boolean>(false);
    const navigate = useNavigate();    


  const hideDemoInfo = () => {
    setDemoShowInfo(false);
};

const showDemoInfo = () => {
    setDemoShowInfo(true);
};

  const demoInfoDialog = () => {
    return (
        <Dialog
            open={showDemoInfoOpen}
            onClose={hideDemoInfo}
        >
            <DialogTitle>
                You are ordering a Demo
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Demo orders are subject to approval by our sales team.
                    {moreDemoInfo ? (
                        <ul>
                            <li>40% discount off of reseller price</li>
                            <li>Net 90 terms</li>
                            <li>Free shipping, unless returned by RMA prior to 90 day expiration</li>
                        </ul>
                    ) : (
                        <ul>
                            <li>
                                Demo Eligibility - Only reps are eligible for demos. If a reseller needs a demo it has to be processed through a rep firm.
                            </li>

                            <li>
                                Pricing - 40% discount off of reseller price.
                            </li>

                            <li>
                                Default Terms - Net 90
                            </li>

                            <li>
                                Shipping
                                <ul>
                                    <li>If rep purchases the demo gear, Blizzard pays for shipping.</li>
                                    <li>If rep returns the demo gear, the Rep pays for shipping both ways.</li>
                                </ul>
                            </li>

                            <li>
                                Product Condition
                                <ul>
                                    <li>If the demo is being returned, the product needs to be returned in like-new condition with all accessories and the original packaging the rep received the demo in.</li>
                                    <li>If a product is returned in anything other than like-new condition, the rep firm is subject to a 25% restocking fee.</li>
                                </ul>
                            </li>

                            <li>
                                Returns - Once approved for return, all demo returns need to follow the same process as any other return, i.e., follow Blizzard’s standard RMA process.
                            </li>

                            <li>
                                Payment
                                <ul>
                                    <li>All demo invoices need to be settled prior to the expiration of the payment terms provided.</li>
                                    <li>Any demo invoices that haven’t been settled prior to the expiration of the payment terms will be deducted from the next Rep commission payment.</li>
                                </ul>
                            </li>
                        </ul>
                    )}

                    <br />
                    If there are any questions or comments, a member of our team will reach out personally to provide more details on the individual product and request.<br /><br />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setMoreDemoInfo(!moreDemoInfo)} autoFocus>
                    {!moreDemoInfo ? "Less" : "More"} Info
                </Button>
                <Button onClick={hideDemoInfo} autoFocus>
                    Nevermind
                </Button>
                <Button onClick={() => {
                    hideDemoInfo();
                    saveOrderingDemo(true);
                    saveCurrentPanel(SideDrawerTab.products);
                    navigate("/products");
                }} autoFocus>
                    Start Demo Order
                </Button>
            </DialogActions>
        </Dialog>
    );
};

  return (<>
        <Button
            style={topNavButton ? {
                    backgroundColor: "white",
                    color: "blue",
                    width: "auto",
                    marginRight: 20,
                    borderBottom: "none"
                } : { width: "100%" }}
            variant="contained"
            color="primary"
            className="btn"
            onClick={showDemoInfo}  
        >
            {topNavButton ? "New Demo" : "Create Demo Order"}
        </Button>

        { demoInfoDialog() }
    </>);
};

export default NewDemoButton;
