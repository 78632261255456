/* eslint-disable react/prop-types */
import React from "react";

import Infobox from "components/utils/Infobox.js";

const RequestInvite = (props) => {
    return (
        <div>
            <h1>Request an Invite</h1>
            <p className="underline" onClick={() => props.setActiveAuth("signup")}>
                Create an account
            </p>
            <p className="underline" onClick={() => props.setActiveAuth("login")}>
                Login
            </p>
            <Infobox
                title="Looking for an Invite?"
                paragraph="Request an invite code to gain access to the Blizzard Customer Portal"
                buttonTitle="Request Invite"
            />
        </div>
    );
};

export default RequestInvite;
