import { productFromHasuraObject, ProductInterface } from "./Product"

export interface PromotionInterface {
    id?: number,
    startDate: Date,
    endDate: Date,
    type: string,
    discountType: string,
    useCount: number,
    discountCode: string,
    products?: ProductInterface[],
    productTypes?: string[],
    usageLimit: number,
    discountValue: number,
    active: boolean,
    uses?: PromotionUsageInterface[],
    autoApply: boolean,
    notes: string
}

export const DefaultPromotion = (): PromotionInterface => {
    const today = new Date();
    const nextMonth = new Date();
    nextMonth.setMonth(today.getMonth() + 1);

    return {
        startDate: today,
        endDate: nextMonth,
        type: "any",
        discountType: "total_amount",
        useCount: 0,
        discountCode: "",
        usageLimit: 0,
        discountValue: 10,
        active: false,
        autoApply: false,
        notes: ""
    }
}


export const promotionFromHasuraObject = (data, products: ProductInterface[]) : PromotionInterface => {
    let appliedProducts: ProductInterface[] = [];

    if(data.product_ids) {
        for (let pi = 0; pi < data.product_ids.length; pi++) {
            const productData = products.find((p) => p.id == data.product_ids[pi]);
            if(productData)
            appliedProducts.push(productData);
        }
    }

    const now = new Date();
    const start = data.start_at ? new Date(data.start_at) : null;
    const end = data.end_at ? new Date(data.end_at) : null;

    let usages = null;

    if(data.usages) {
        usages = buildPromotionUsagesList(data.usages);
    }

    return {
        id: data.id,
        startDate: start,
        endDate: end,
        type: data.qualification_type,
        discountType: data.discount_type,
        useCount: data.usages_aggregate.aggregate.count,
        discountCode: data.discount_code,
        products: appliedProducts,
        productTypes: data.product_types,
        usageLimit: data.usage_limit,
        discountValue: data.discount_type == "percent" ? data.discount_value * 100  : data.discount_value,
        active: start > now && end < now,
        uses: usages,
        autoApply: data.auto_apply ?? false,
        notes: data.notes ?? ""
    };
};

export const buildPromotionsList = (rawPromotionList, products: ProductInterface[]) => {
    const promotions: PromotionInterface[] = [];
    for (let rali = 0; rali < rawPromotionList.length; rali++) {
        const rawPromotion = rawPromotionList[rali];
        promotions.push(promotionFromHasuraObject(rawPromotion, products));
    }

    return promotions;
};


export interface PromotionUsageInterface {
    id: number,
    amount: number,
    createdAt: Date,
    customerEmail: string,
    customerId?: string,
    orderId: number
}


export const buildPromotionUsagesList = (rawPromotionUsageList) => {
    const usages: PromotionUsageInterface[] = [];
    for (let rali = 0; rali < rawPromotionUsageList.length; rali++) {
        const rawPromotion = rawPromotionUsageList[rali];
        usages.push(promotionUsageFromHasuraObject(rawPromotion));
    }

    return usages;
};


export const promotionUsageFromHasuraObject = (data) : PromotionUsageInterface => {
    return {
        id: data.id,
        amount: data.amount,
        customerEmail: data.customer ? data.customer.email : data.order_email,
        customerId: data.customer ? data.customer.id : null,
        orderId: data.order_id,
        createdAt: data.created_at ? new Date(data.created_at) : null,
    };
};

export const PromotionQualificationTypeOptions = [
    "any",
    "by_types",
    "by_products"
]

export const PromotionDiscountTypeOptions = [
    "total_amount",
    "percent"
]