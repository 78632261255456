import React from "react";
import { CartContext } from "context/Cart";
import { FormControl, Grid, TextField } from "@material-ui/core";
import { buildCompanyInfo } from "interfaces/Company";
import { UserContext } from "context/User";
import { makeAxiosCall } from "utils";
import { Autocomplete } from "@material-ui/lab";
import 'scss/components/Checkout/OrderOnBehalfOf.scss';


const OrderOnBehalfOf = (props) => {
  const [potentialCompanyNames, setPotentialCompanyNames] = React.useState<Array<string>>([]);
  const [potentialCompanies, setPotentialCompanies] = React.useState<Array<{name: string, id: number}>>([]);
  const [inputValue, setInputValue] = React.useState<string>("");
  const { orderingOnBehalfOf, setOrderAs } = React.useContext(CartContext);
  const { currentUser, internalUser } = React.useContext(UserContext);

  React.useEffect(() => {
    if (currentUser.company.companyType === "Rep") {
      setPotentialCompanies(
          currentUser.company.resellers.map((r) => {
              return {name: r.name, id: r.id};
          })
      );

      setPotentialCompanyNames(
        currentUser.company.resellers.map((r) => {
            return r.name;
        })
    );
    } else if(internalUser) {
        fetchAllCompanies();
    }
  }, [currentUser]);

  const fetchAllCompanies = () => {
    makeAxiosCall(
      "get",
      "companies/simple"
    ).then(async res => {
      const companyList = [];
      const companyNameList = [];
      for (let ci = 0; ci < res.data.length; ci++) {
        const company = res.data[ci];
        companyList.push({id: company.id, name: company.name});
        companyNameList.push(company.name);
      }
      setPotentialCompanyNames(companyNameList);
      setPotentialCompanies(companyList);
    })
  }

  const selectCompany = (companyName) => {
    let selectedCompany = potentialCompanies.find((c) => c.name === companyName);
    if(selectedCompany) {
        if(selectedCompany.id == currentUser.company.id) {
            setOrderAs({...currentUser.company});
        } else {
            makeAxiosCall(
              "get",
              "company/" + selectedCompany.id
              ).then(async res => {
                  let company = await buildCompanyInfo(res.data);
                  setOrderAs({...company});
              })
        }
    }
    
  }

  return (
    <Grid container id="order-on-behalf-of-container">
      <Grid item xs={12}>
        <FormControl
          fullWidth
        >
          <Autocomplete
            // disablePortal
            id="on-behalf-select"
            options={potentialCompanyNames}
            value={orderingOnBehalfOf ? orderingOnBehalfOf.name : currentUser.company.name}
            onChange={(event: any, newValue: string | null) => {
              if(newValue) {
                selectCompany(newValue);
              } else {
                selectCompany(currentUser.company.name);
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newValue) => {
              setInputValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Order On Behalf Of" />}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default OrderOnBehalfOf;
