import React from "react";
import { db } from "base.js";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";
import { Button, TextField } from "@material-ui/core";
import { logFileDownload } from "utils";

interface FixtureProfileFileInterface {
  filename: string,
  src: string,
  tags: string,
  type: string,
  uid: string,
}

const FixtureProfiles = () => {
  const [profiles, setProfiles] = React.useState<FixtureProfileFileInterface[]>([]);
  const [input, setInput] = React.useState<string>("");
  const [activeFolder, setActiveFolder] = React.useState<string>("");

  let isMounted = true;

  React.useEffect(() => {
    fetchProfiles();
    return () => {
      isMounted = false;
    }
  }, []);

  const fetchProfiles = async () => {
    const profileRef = db.collection("profiles");
    const getProfiles = await profileRef.get();
    if (isMounted) {
      setProfiles(getProfiles.docs.map((doc) => {
        let data = doc.data();
        return {
          filename: data.filename,
          src: data.src,
          tags: data.tags,
          type: data.type,
          uid: data.uid,
        }
      }));
    }
  }

  const handleDownload = (file: FixtureProfileFileInterface) => {
    window.open(file.src, "_blank");
    logFileDownload(file.filename, file.src);
  };

  const renderFileTree = () => {
    const files = _.filter(profiles, { tags: activeFolder });
    return _.map(files, (file: FixtureProfileFileInterface) => {
      return (
        <div className="line-item" key={file.filename}>
          <div className="name">
            {file.filename}
          </div>
          <div className="download" onClick={() => handleDownload(file)}>
            Download
            <i className="fal fa-download" style={{ marginLeft: 5 }}></i>
          </div>
        </div>
      );
    });
  };

  let searchResults = [];

  const renderResult = () => {
    const searchInput = input.toLowerCase();
    const folders = _.uniqBy(profiles, "tags");

    searchResults = _.filter(folders, (o) => {
      let folderName = "";

      if (o.tags !== undefined) {
        folderName = o.tags.toLowerCase().replace(/[^a-zA-Z ]/g, " ");
      }

      return _.includes(folderName, searchInput);
    });

    if (searchResults.length > 0) {
      return _.map(
        _.orderBy(searchResults, [(results) => results.tags], ["asc"]),
        (o) => {
          return (
            <div className="folder" onClick={() => setActiveFolder(o.tags)} key={o.tags}>
              <i className="fad fa-folder"></i>
              <h3>{o.tags}</h3>
            </div>
          );
        }
      );
    }
  };

  return (
    <div className="fixture-profiles view">
      <div className="container">
        <h1>Fixture Profiles</h1>
        <TextField
          className="prod-search"
          label="Search for fixture profile by product"
          margin="normal"
          variant="outlined"
          style={{ width: "100%", marginBottom: 30 }}
          onChange={(e) => setInput(e.target.value)}
          value={input ? input : ""}
        />
        <CSSTransition
          in={activeFolder.length != 0}
          unmountOnExit
          timeout={200}
          classNames="dialog"
        >
          <div>
            <Button
              onClick={() => setActiveFolder("")}
            >
              <i className="far fa-long-arrow-left" style={{ marginRight: 5 }}></i>Back to Folders
            </Button>
            {renderFileTree()}
          </div>
        </CSSTransition>
        <CSSTransition
          in={activeFolder.length == 0}
          unmountOnExit
          timeout={200}
          classNames="dialog"
        >
          <div className="grid-container">{renderResult()}</div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default FixtureProfiles;
