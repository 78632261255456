import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import { UserProvider } from "context/User";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FixtureProfiles from "components/MiscRoutes/FixtureProfiles";
import Importer from "components/MiscRoutes/Importer";
import ProfileEditor from "components/ProfileEditor/ProfileEditor";
import Overview from "components/Home/Overview";
import CreatePO from "components/Products/CreatePO";
import OrderHistory from "components/OrderHistory/OrderHistory";
import Checkout from "components/Checkout/Checkout";
import MediaLibrary from "components/MediaLibrary/MediaLibrary";
import Admin from "components/Admin/Admin";
import Help from "components/Help/Help";
import FAQPage from "components/Help/FAQ";
import QuoteBuilder from "components/Quote Builder/QuoteBuilder";
import { ErrorBoundary } from "components/ErrorBoundary";
import Status from "components/Admin/Status";
import RequestInvite from "components/Auth/RequestInvite";
import SO from "components/OrderHistory/SO";
import MyFiles from "components/MiscRoutes/MyFiles";
import WarrantyCenter from "components/WarrantyCenter/WarrantyCenter";
import PDCenter from "components/PDCenter/PDCenter";
import PDItem from "components/PDCenter/PDItem";
import ThankYou from "components/Checkout/ThankYou";
import JotformPage from "components/MiscRoutes/JotformPage";
import EditUser from "components/Admin/EditUser";
import MyCompany from "components/MyCompany/MyCompany";
import EditCompany from "components/Admin/EditCompany";
import Newsfeed from "components/Newsfeed/Newsfeed";
import { CompanyProvider } from "context/Company";
import { UserListProvider } from "context/UserList";
import ViewResellerInfoForm from "components/Admin/Approval/ViewResellerInfoForm";
import PendingOrders from "components/OrderHistory/PendingOrders";
import PO from "components/OrderHistory/PO";
import ReactGA from "react-ga4";
import { PDProvider } from "context/PDProducts";
import { CartProvider } from "context/Cart";
import { ProductProvider } from "context/Product";
import { AddressProvider } from "context/Address";
import Account from "components/Account/Account";
import SingleProductPage from "components/Products/SingleProductPage";
import ResellerApplication from "components/Account/Wizard/ResllerApplication";
import Wizard from "components/Account/Wizard/Wizard";
import WebOrderDetails from "components/Admin/Web/Order/WebOrderDetails";
import WebOrderCreateRefund from "components/Admin/Web/Order/WebOrderCreateRefund";
import WebCustomerDetails from "components/Admin/Web/Customer/WebCustomerDetails";
import PromotionDetails from "components/Admin/Promotions/PromotionDetails";
import PromotionForm from "components/Admin/Promotions/PromotionForm";
import DemoFeedbackForm from "components/Demos/DemoFeedbackForm";
import Demos from "components/Demos/Demos";
import SingleBaseProduct from "components/Admin/BaseProducts/SingleBaseProduct";
import ProductDevelopmentDashboard from "components/ProductDevelopment/Dashboard";
import NewPDProjectForm from "components/ProductDevelopment/NewProject";
import SinglePDProject from "components/ProductDevelopment/SingleProject";
import AllProductSales from "components/Reports/AllProductSales";
import { ProductDevelopmentProvider } from "context/ProductDevelopment";

const App = () => {
    const theme = createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            primary: { main: "#282bf6" },
            secondary: { main: "#081016" },
            alert: { main: "#f44336" },
        },
        status: {
            danger: { 500: "#ff4d4d" },
        },
    });

    React.useEffect(() => {
        ReactGA.initialize("G-DQW0MK47SZ");
    }, []);

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <UserProvider>
                    <CartProvider>
                        <ProductProvider>
                            <CompanyProvider>
                                <AddressProvider>
                                    <ProductDevelopmentProvider>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Router>
                                                <Routes>
                                                    <Route
                                                        exact
                                                        path="/account"
                                                        element={(
                                                            <PrivateRoute>
                                                                <Account />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/ach"
                                                        element={(
                                                            <JotformPage jotformId="220824430507145" />
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/rep/marketing"
                                                        element={(
                                                            <JotformPage jotformId="230244344180143" />
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/cc-auth"
                                                        element={(
                                                            <JotformPage jotformId="220935849652163" />
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/warranty"
                                                        component={WarrantyCenter}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/fixture-profiles"
                                                        component={FixtureProfiles}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/demos"
                                                        element={(
                                                            <PrivateRoute>
                                                                <Demos />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/demo-form"
                                                        element={(
                                                            <PrivateRoute>
                                                                <DemoFeedbackForm />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/files"
                                                        element={(
                                                            <PrivateRoute>
                                                                <MyFiles />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/request-invite"
                                                        component={RequestInvite}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/fixtureprofiles"
                                                        element={(
                                                            <PrivateRoute>
                                                                <FixtureProfiles />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/profile-editor"
                                                        element={(
                                                            <PrivateRoute>
                                                                <ProfileEditor />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/products"
                                                        element={(
                                                            <PrivateRoute>
                                                                <CreatePO />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/products/:id"
                                                        element={(
                                                            <PrivateRoute>
                                                                <SingleProductPage />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/order-history"
                                                        element={(
                                                            <PrivateRoute>
                                                                <OrderHistory />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        path="/order-history/:id"
                                                        element={(
                                                            <PrivateRoute>
                                                                <SO />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/pending-orders"
                                                        element={(
                                                            <PrivateRoute>
                                                                <PendingOrders />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        path="/pending-orders/:id"
                                                        element={(
                                                            <PrivateRoute>
                                                                <PO />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/status"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <Status />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/newsfeed"
                                                        element={(
                                                            <PrivateRoute>
                                                                <Newsfeed />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/company"
                                                        element={(
                                                            <PrivateRoute>
                                                                <MyCompany />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/checkout"
                                                        element={(
                                                            <PrivateRoute>
                                                                <Checkout />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/checkout/thank-you"
                                                        element={(
                                                            <PrivateRoute>
                                                                <ThankYou />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/media-library"
                                                        element={(
                                                            <PrivateRoute>
                                                                <MediaLibrary />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/pd"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <ProductDevelopmentDashboard />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/pd/new"
                                                        element={(
                                                            <PrivateRoute>
                                                                <NewPDProjectForm />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        path="/pd/:id"
                                                        element={(
                                                            <PrivateRoute>
                                                                <SinglePDProject />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    {/* <Route
                                                        path="/pd/:id"
                                                        element={(
                                                            <PDProvider>
                                                                <PrivateRoute>
                                                                    <ProductDevelopmentSingleProject />
                                                                </PrivateRoute>
                                                            </PDProvider>
                                                        )}
                                                    /> */}
                                                    
                                                    <Route
                                                        exact
                                                        path="/pd-overview"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <PDCenter />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/iris-quote-builder"
                                                        element={(
                                                            <PrivateRoute>
                                                                <QuoteBuilder />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/admin"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <UserListProvider>
                                                                    <Admin />
                                                                </UserListProvider>
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/reports"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <UserListProvider>
                                                                    <AllProductSales />
                                                                </UserListProvider>
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/edit-user/:uid"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <EditUser />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/base-products/:id"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <SingleBaseProduct />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/base-products"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <SingleBaseProduct />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/view-reseller-info-form/:id"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <ViewResellerInfoForm />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/edit-company/:id"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <EditCompany />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/web-orders/:id"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <WebOrderDetails />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/web-customer/:id"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <WebCustomerDetails />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/create-refund/:id"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <WebOrderCreateRefund />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/promotion/:id"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <PromotionDetails />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/new-promotion"
                                                        element={(
                                                            <PrivateRoute admin>
                                                                <PromotionForm />
                                                            </PrivateRoute>
                                                        )}
                                                    />

                                                    <Route
                                                        exact
                                                        path="/help/faq"
                                                        element={(
                                                            <PrivateRoute>
                                                                <FAQPage />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/help"
                                                        element={(
                                                            <PrivateRoute>
                                                                <Help />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/apply"
                                                        element={(
                                                            <ResellerApplication hideNav={true} />
                                                        )}
                                                    />
                                                    <Route 
                                                        exact
                                                        path="/approval"
                                                        element={(
                                                            <PrivateRoute>
                                                                <Wizard hideNav={true} />
                                                            </PrivateRoute>
                                                        )}
                                                    />
                                                    <Route exact path="/import" component={Importer} />

                                                    <Route exact path="/" element={(<PrivateRoute>
                                                            <Overview />
                                                        </PrivateRoute>)} />
                                                    <Route path="/s" element={(<PrivateRoute />)} />
                                                </Routes>
                                            </Router>
                                        </MuiPickersUtilsProvider>
                                    </ProductDevelopmentProvider>
                                </AddressProvider>
                            </CompanyProvider>
                        </ProductProvider>
                    </CartProvider>
                </UserProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export default App;
