import React from "react";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { CompanyInterface } from "interfaces/Company";
import { makeAxiosCall } from "utils";
import Loading from "components/utils/Loading";
import { buildInvite, InviteInterface } from "interfaces/Invite";
import MemberInvite from "components/MyCompany/MemberInvite";
import InviteCard from "./InviteCard";
import { UserContext } from "context/User";

interface CompanyInvitesProps {
    company: CompanyInterface,
    canEdit?: boolean,
}

const CompanyInvites = ({ company, canEdit = true }: CompanyInvitesProps) => {
    const { currentUser } = React.useContext(UserContext);
    const [invites, setInvites] = React.useState<InviteInterface[]>();
    const [loading, setLoading] = React.useState(true);

    let isMounted = true;

    React.useEffect(() => {
        if (company) {
            fetchContacts();
        }
        return () => {
            isMounted = false;
        };
    }, [company]);

    const fetchContacts = async () => {
        const response = await makeAxiosCall(
            "get",
            `invites/${company.id}`
        ).catch(error => {
            console.error("Caught error getting contact info:");
            console.error(error);
        });

        if (response.data) {
            const invitesList = await Promise.all(_.map(
                response.data,
                (invite) => {
                    return buildInvite(invite);
                }
            ));
            if (isMounted) {
                setInvites(invitesList);
                setLoading(false);
            }
        }
    };

    const handleNewInvite = (invite: InviteInterface): void => {
        setInvites([...invites, invite]);
    };

    const renderBody = () => {
        if (loading) {
            return <Loading height="40vh" title="" position="relative" />;
        } else {
            if (invites && invites.length > 0) {
                return invites.map(invite => {
                    return <InviteCard key={invite.id} invite={invite} canEdit={canEdit} removeCallback={fetchContacts} updateCallback={fetchContacts} />;
                });
            } else {
                return <p className="body-message">No Invites Found</p>;
            }
        }
    };

    return company ? (
        <div className="grid-search">
            <Grid container alignItems="center" >
                <Grid item xs={12} sm={9}>
                    <h2>Invites</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {canEdit && currentUser.accountLevel.canManageMembers && (
                        <MemberInvite company={company} creationCallback={handleNewInvite} />
                    )}
                </Grid>
            </Grid>
            <Grid container className="results">
                <Grid container item xs={12} className="center-text grid-search-headers">
                    <Grid xs={5} item className="result-name">
                        Email
                    </Grid>
                    <Grid item xs={3}>
                        Last Sent
                    </Grid>
                    <Grid item xs={2}>
                        Account Level
                    </Grid>
                    <Grid item xs={1}>
                        {canEdit && currentUser.accountLevel.canManageMembers && (
                            "Resend"
                        )}
                    </Grid>
                    <Grid item xs={1}>
                        {canEdit && currentUser.accountLevel.canManageMembers && (
                            "Remove"
                        )}
                    </Grid>
                </Grid>
                {renderBody()}
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyInvites;