import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

export default class DiagonalInput extends Component {
  render() {
    const config = this.props.config;
    return (
      <TextField
        id="diagonal"
        label="Diagonal"
        margin="normal"
        maxLength="3"
        value={config.diagSelected || ""}
        onChange={this.props.handleInputChange(
          "diagSelected",
          this.props.dimSelect
        )}
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment variant="filled" position="end">
              {config.format}
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
