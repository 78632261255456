import { useEffect, useRef } from "react";
import _ from "lodash";
import { states, countries } from "temp/index";
import axios from "axios";
import { API_URL } from "../config";

// export function handleDownload(file) {
//   const xhr = new XMLHttpRequest();
//   xhr.responseType = "blob";
//   xhr.onload = function () {
//     const a = document.createElement("a");
//     a.href = window.URL.createObjectURL(xhr.response);
//     a.download = file.filename;
//     a.style.display = "none";
//     document.body.appendChild(a);
//     a.click();
//   };
//   xhr.open("GET", file.src);
//   xhr.send();
// }

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const makeAxiosCall = (method, endpoint, data, config) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: method,
        url: API_URL + endpoint,
        data: data,
        timeout: 10000,
        withCredentials: true,
        ...config,
      });

      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      console.error(`CAUGHT ERROR IN AXIOS ${method}`);
      console.error(error.response ? error.response : error);
      reject(error.response);
    }
  });
}

export const updateHubspotContact = (obj) => {
  return makeAxiosCall(
    "post", 
    "update-contact",
    obj
  );
};

export const getHubspotCompanyInfo = (companyId) => {
  companyId = parseInt(companyId);
  if(!companyId) {
    console.log("Company ID required");
    return Promise.reject(new Error('Company ID required'));
  }
  return makeAxiosCall(
    "post",
    "get-hs-company",
    {
      companyId: companyId
    },
  ).then((response) => {
    return response.data;
  }).catch((e) => {
    console.log(e);
  });
};

export const createHubspotCompany = (obj, userToken) => {
  return makeAxiosCall(
    "post",
    "hubspot/create-company",
    obj,
    { headers: {"Content-Type": "application/json"} }
  );
};
export const updateHubspotCompany = (obj, userToken) => {
  makeAxiosCall(
    "post",
    "update-company",
    obj,
    { headers: {"Content-Type": "application/json"} }
  );
};

export const associateContactWithCompany = (obj, userToken) => {
  axios
    .post(`${API_URL}hubspot/contact-to-company`, obj, {
      timeout: 10000,
      headers: {
        authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
    })
    .catch((err) => console.log(err));
};
export const getHubspotContactByEmail = async (email, userToken) => {
  const hsContact = await makeAxiosCall(
    "get",
    `hubspot/get-contact-by-email/${email}`,
    null,
    { headers: {"Content-Type": "application/json"} }
  );
  return hsContact.data;
};

export const createMarkup = (markup) => {
  return {
    __html: markup,
  };
};

export const generateInviteCode = () => {
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;

  firstPart = ("000" + firstPart.toString(36)).slice(-5);
  secondPart = ("000" + secondPart.toString(36)).slice(-5);
  return firstPart + secondPart;
};

export const findState = (id) => {
  return _.find(states, (o) => {
    return o.id === id;
  });
};
export const findCountry = (id) => {
  return _.find(countries, (o) => {
    return o.id === id;
  });
};
export const findStateId = (stateData) => {
  let state = _.find(states, (o) => o.code === stateData);
  if (!state) {
    state = _.find(states, (o) => o.name.toLowerCase() === stateData.toLowerCase());
  }
  if (!state) {
    state = {id: 1}
  }
  return state.id;
};
export const findCountryId = (countryData) => {
  let country = _.find(countries, (o) => o.name.toLowerCase() === countryData.toLowerCase());
  if (!country) {
    country = _.find(countries, (o) => o.abbreviation.toLowerCase() === countryData.toLowerCase());
  }
  if (!country) {
    country = {id: 1}
  }
  return country.id;
};
export const validateEmail = (email) => {
  //eslint-disable-next-line
  var re =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let testEmail = re.test(String(email).toLowerCase());
  return testEmail;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = "";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  if (parseInt(colour, 16) > 15658734) return "#081016";
  return `#${colour}`;
}

export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const generateKey = () => {
  return Math.floor(Math.random() * 1000000000);
};

export function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-9);
  secondPart = ("000" + secondPart.toString(36)).slice(-9);
  return firstPart + secondPart;
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function determineFileType(fileExt) {
  const supportedImageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/svg",
    "image/ico",
  ];
  if (supportedImageTypes.includes(fileExt)) {
    return "image";
  } else return null;
}

export function determineRepFirm(id) {
  switch (id) {
    //Brownestone
    case 81:
    case 82:
      return 1285;
    //Audio Biz
    case 10:
    case 27:
    case 28:
    case 33:
    case 56:
      return 52;
    //Audio Pros
    case 69:
    case 70:
      return 1185;
    //Cadon
    case 75:
    case 76:
      return 1237;
    //Salesforce
    case 54:
    case 41:
      return 889;
    //Holloway
    case 64:
    case 66:
      return 1147;
    //Thor
    case 104:
    case 105:
      return 1690;
    //Metro Joes
    case 17:
    case 52:
      return 314;
    //JAMS
    case 67:
    case 68:
      return 1167;
    //RTM
    case 53:
    case 21:
      return 437;
    //Dobbs
    case 93:
      return 1500;
    default:
      return 0;
  }
}

export function parseMetafields(metafields, query) {
  const results = _.filter(metafields, (x) => {
    return x.name === query;
  });
  if (results.length) {
    return results[0].value;
  } else {
    return 0;
  }
}

export function getText(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export function niceText(text) {
  if(text === undefined | text === null) {
    return "";
  }

  text = "" + text;

  text = text.replace("_", " ");
  const strSplit = text.split(' ');

  for (let wi = 0; wi < strSplit.length; wi++) {
    strSplit[wi] = strSplit[wi].charAt(0).toUpperCase() + strSplit[wi].slice(1);  
  }

  return strSplit.join(' ');
}

export function stringToMoney(str) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(str);
}

export function handleDownload(file, name) {
  const xhr = new XMLHttpRequest();
  let filename;
  if (name) {
    filename = name;
  } else {
    filename = /[^/]*$/.exec(file)[0];
  }
  xhr.responseType = "blob";
  xhr.onload = function () {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = filename; // Name the file anything you'd like.
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
  };
  xhr.open("GET", file);
  xhr.send();

  logFileDownload(filename, file);
}

export async function updateProductInFishbowl(objArr, callback) {
  try {
    const response = await makeAxiosCall(
      "post",
      "update-product",
      objArr,
    );
    console.log(response);
    callback();
  } catch (err) {
    console.log(err);
  }
}
export async function updatePartInFishbowl(objArr, callback) {
  try {
    const response = await makeAxiosCall(
      "post",
      "update-part",
      objArr,
    );
    console.log(response);
    callback();
  } catch (err) {
    console.log(err);
  }
}

export function formatMoney(number) {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
}

export function convertFishbowlJSON(string) {
  if(string == undefined || string.length < 1) return string;
  return string
    .replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
    .toLowerCase();
}

export function convertSpecialChars(string) {
  if (typeof string !== "string") {
    return string;
  }
  return string
    .replace(/—/g, "&#8212")
    .replace(/–/g, "&ndash;")
    .replace(/•/g, "&bull;")
    .replace(/™/g, "&trade;")
    .replace(/-/g, "&#45;")
    .replace(/°/g, "&deg;")
    .replace(/“/g, "&#34;")
    .replace(/”/g, "&#34;")
    .replace(/®/g, "&reg;")
    .replace(/'/g, "&apos;")
    .replace(/’/g, "&apos;")
    .replace(/›/g, "&rsaquo;");
}

export function logFileDownload(name, url, mediaId, documentId) {
  try {
    return makeAxiosCall(
      "post",
      "log-download",
      {
        name: name,
        url: url,
        productMediaId: mediaId,
        companyDocumentId: documentId
      }
    );
  } catch(e) {
    console.log("failed to log download, ¯\\_(ツ)_/¯");
  }
}