import React from "react";

import TextField from "@material-ui/core/TextField";
import { DatePicker } from "@material-ui/pickers";
import { Button, Grid } from "@material-ui/core";
import { makeAxiosCall } from "utils";
import { AddressInterface } from "interfaces/Address";
import { CCDataInterface } from "interfaces/Payment";
import { CompanyInterface } from "interfaces/Company";
import moment from "moment";
import ContactSelect from "./Contact/ContactSelect";
import { ContactInterface } from "interfaces/Contact";
import ContactEditForm from "components/MyCompany/ContactEditForm";
import SnackBar from "components/material-ui/SnackBar";
import AddressBook from "./Address/AddressBook";

interface CCFormPropsInterface {
    selectedCC?: CCDataInterface,
    company: CompanyInterface,
    newCC: boolean,
    setActiveCC: (data: CCDataInterface) => void,
    closeForm: () => void,
}

interface CCFormErrorInterface {
    address: boolean,
    contact: boolean,
    cardNumber: boolean,
    expirationDate: boolean
}

const CCFormErrorInterfaceDefaults = {
    address: false,
    contact: false,
    cardNumber: false,
    expirationDate: false
};

const CCForm = ({ newCC, company, selectedCC, setActiveCC, closeForm }: CCFormPropsInterface) => {
    const [ccData, setCCData] = React.useState<CCDataInterface>(selectedCC);
    const [newBillingAddress, setNewBillingAddress] = React.useState<boolean>(false);
    const [newBillingContact, setNewBillingContact] = React.useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = React.useState<boolean>(false);
    const [saveFailure, setSaveFailure] = React.useState<boolean>(false);
    const [formErrors, setFormErrors] = React.useState<CCFormErrorInterface>(CCFormErrorInterfaceDefaults);


    React.useEffect(() => {
        console.log(selectedCC);
    }, []);

    const handleSave = () => {

        console.log(ccData);

        console.log(ccData.card.expirationDate);


        let noSaveFlag = false;
        const newFormErrors = { ...CCFormErrorInterfaceDefaults };

        if (!ccData.address) {
            newFormErrors.address = noSaveFlag = true;
        }

        if (!ccData.contact) {
            newFormErrors.contact = noSaveFlag = true;
        }

        ccData.card.cardNumber = ccData.card.cardNumber.trim();

        if (ccData.card.cardNumber.length < 15) {
            newFormErrors.cardNumber = noSaveFlag = true;
        }

        if (ccData.card.expirationDate.length < 4) {
            newFormErrors.expirationDate = noSaveFlag = true;
        }

        setFormErrors(newFormErrors);

        if (noSaveFlag) {
            console.log(newFormErrors);

            return;
        }

        makeAxiosCall(
            "post",
            newCC ? "cc-create" : "cc-update",
            {
                ...ccData,
                card: {
                    ...ccData.card,
                    expirationDate: moment(ccData.card.expirationDate).format("MM/YY")
                },
                customerProfileId: company.gatewayProfileId
            },
            { headers: { "Content-Type": "application/json" } }
        )
            .then((res) => {
                if (res.status == 200) {
                    closeForm();
                    setSaveSuccess(true);
                } else {
                    setSaveFailure(true);
                }
                console.log(res);
            })
            .catch((error) => {
                setSaveFailure(true);
                console.log(error);
            });
    };

    return (
        <>

            <SnackBar
                severity="success"
                anchorOrigin="right"
                snackOpen={saveSuccess}
                setSnackOpen={setSaveSuccess}
                message={"Card Saved Successfully"}
            />
            <SnackBar
                severity="error"
                duration={10000}
                anchorOrigin="left"
                snackOpen={saveFailure}
                setSnackOpen={setSaveFailure}
                message={"Error saving payment method. Please check your card info and try again."}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <p
                        onClick={() => closeForm()}
                        style={{ cursor: "pointer" }}
                    >
                        <i className="fad fa-arrow-left"></i> Back to All Payment Methods
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <h2>{!newCC ? "Edit Payment Info" : "Add a New Payment"}</h2>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center" className="new-cc-form">
                        <Grid item xs={12}>
                            <h3>Card Details</h3>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <TextField
                                style={{ width: "100%", paddingRight: 20 }}
                                error={formErrors.cardNumber}
                                label="Card Number"
                                margin="normal"
                                value={ccData.card.cardNumber}
                                onChange={(e) => setCCData({ ...ccData, card: { ...ccData.card, cardNumber: e.target.value } })}
                                inputProps={{
                                    maxLength: 19,
                                }}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <DatePicker
                                style={{ width: "100%" }}
                                error={formErrors.expirationDate}
                                disablePast
                                margin="normal"
                                inputVariant="outlined"
                                openTo="month"
                                format="MM/YY"
                                label="Exp. Date"
                                views={["month", "year"]}

                                value={ccData.card.expirationDate && ccData.card.expirationDate != "XXXX" ? ccData.card.expirationDate : moment(moment.now()).format()}
                                onChange={(date) => {
                                    setCCData({ ...ccData, card: { ...ccData.card, expirationDate: moment(date).format() } });
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {!newCC ? <Grid item xs={12}>
                    <h3>For security reasons, editing a payment method requires re-entry of card info.</h3>
                </Grid> : null}
                <Grid item xs={12}>
                    <AddressBook
                        currentReseller={company}
                        key={2}
                        containerClass={"cc-billing-address"}
                        title="Select Billing Address"
                        onSelect={(address: AddressInterface) => {
                            setCCData({ ...ccData, address: address });
                            setNewBillingAddress(false);
                        }}
                    />
                    {/* <AddressSelect
            addresses={company.addresses}
            hasError={formErrors.address}
            label="Billing Address"
            setNewFormActive={(open) => {
              setNewBillingAddress(open);
              if(open) {
                setCCData({...ccData, address: null})
              }
            }} 
            onSelect={(selectedAddress: AddressInterface) => {
              setNewBillingAddress(false);
              setCCData({...ccData, address: selectedAddress})
            }}
            currentSelection={ccData.address}
          />

          { newBillingAddress ?
          <Paper style={{padding: 20}}>
            <Grid container spacing={1} className="new-form">
              <h3>New Address</h3>

            </Grid>
          </Paper> : null } */}
                </Grid>
                <Grid item xs={12} style={{ padding: 16 }}>
                    <Grid container spacing={2} className="new-cc-form">
                        <Grid item xs={12}>
                            <h3>Select Billing Contact</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <ContactSelect
                                company={company}
                                hasError={formErrors.contact}
                                label="Billing Contact"
                                setNewFormActive={(open) => {
                                    setNewBillingContact(open);
                                    if (open) {
                                        setCCData({ ...ccData, contact: null });
                                    }
                                }}
                                currentSelection={ccData.contact}
                                onSelect={(newContact: ContactInterface) => setCCData({ ...ccData, contact: newContact })}
                            />

                            {newBillingContact ? <ContactEditForm
                                createCallback={(contact: ContactInterface, newId: number) => {
                                    contact.id = newId;
                                    setCCData({ ...ccData, contact: contact });
                                }}
                                companyId={company.id}
                            /> : null}
                        </Grid>
                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    style={{ width: 300, margin: "50px auto" }}
                    variant="contained"
                    color="primary"
                    className="btn"
                    onClick={() => handleSave()}
                >
                    Save
                </Button>
            </Grid>
        </>
    );
};

export default CCForm;
