import React from "react";
import _ from "lodash";
import { CompanyInterface } from "interfaces/Company";
import { OrderInterface } from "interfaces/Order";
import { makeAxiosCall } from "utils";
import Loading from "components/utils/Loading";
import { Grid, TextField } from "@material-ui/core";
import { List, WindowScroller } from "react-virtualized";
import { FishbowlSalesOrderInterface } from "interfaces/FishbowlSalesOrder";
import OrderSearchResult from "components/OrderHistory/OrderSearchResult";

interface CompanySOsProps {
    company: CompanyInterface
}

const CompanySOs = ({ company }: CompanySOsProps) => {
    const [orders, setOrders] = React.useState<FishbowlSalesOrderInterface[]>();
    const [input, setInput] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    let isMounted = true;

    React.useEffect(() => {
        if (company?.fishbowlCustomerId) {
            fetchFishbowlOrders();
        }
        return () => {
            isMounted = false;
        };
    }, [company]);

    // React.useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const searchFromURL = urlParams.get("search");
    //     if (searchFromURL != null && input == "" && input != searchFromURL) {
    //         setInput(searchFromURL);
    //     }
    // }, [window.location.search]);

    // React.useEffect(() => {
    //     if (historyState) {
    //         const urlParams = new URLSearchParams(window.location.search);
    //         const tabFromURL = urlParams.get("tab");
    //         const tabString = tabFromURL ? ('&tab=' + tabFromURL) : ""
    //         var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?search=' + input + tabString;
    //         window.historyState({path:newurl},'',newurl);
    //     }
    // }, [input]);

    const fetchFishbowlOrders = async () => {
        const result = await makeAxiosCall(
            "get",
            `fishbowl-orders/${company.fishbowlCustomerId}`,
        ).catch(error => {
            console.error("Caught error getting fishbowl orders:");
            console.error(error);
        });

        if (result.data) {
            if (isMounted) {
                setOrders(result.data);
            }
        }
        setLoading(false);
    };

    const searchResults: OrderInterface[] = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        let results: FishbowlSalesOrderInterface[] = orders;
        if (searchInput.length > 0) {
            results = _.filter(orders, (o: FishbowlSalesOrderInterface) => {
                return o.num && _.includes(o.num, searchInput);
            });
        }

        return _.orderBy(results, ["dateCreated"], ["desc"]);
    }, [input, orders]);

    const renderRow = ({ index, key, style }, phoneMode) => {
        const order: OrderInterface = searchResults[index];
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <OrderSearchResult order={order} phoneMode={phoneMode} companyMember={false} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return (
            <h4 style={{ textAlign: "center", fontSize: 23, fontWeight: "normal" }}>
                No sales orders found
            </h4>
        );
    };

    return company ? (
        <div className="admin">
            <div className="grid-search">
                <h2 className="reveal-text">Customer SOs</h2>
                <p>These are orders inside of Fishbowl</p>
                <div className="search-field">
                    <TextField
                        className="prod-search"
                        label="Search for previous orders"
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setInput(e.target.value)}
                        value={input}
                        style={{ marginRight: 0, width: "100%" }}
                    />
                </div>
                <Grid container className="results">

                    {loading ?
                        (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) : (
                            <WindowScroller>
                                {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                                    const phoneMode = width < 500;

                                    return <Grid container>
                                        <Grid container item xs={12} className="center-text grid-search-headers">
                                            <Grid md={1} xs={2} item className="result-name">
                                                Order #
                                            </Grid>

                                            <Grid item xs={3} md={1}>
                                                PO
                                            </Grid>

                                            {(!phoneMode) && <Grid item md={2} xs={6}>
                                                Date
                                            </Grid>}

                                            <Grid item xs={4} className="result-name">
                                                Customer
                                            </Grid>

                                            <Grid item xs={3} md={2}>
                                                Status
                                            </Grid>

                                            {!phoneMode && <Grid item xs={2}>
                                                View
                                            </Grid>}
                                        </Grid>
                                        <List
                                            autoHeight
                                            width={width}
                                            height={height}
                                            isScrolling={isScrolling}
                                            onScroll={onChildScroll}
                                            scrollTop={scrollTop}
                                            rowHeight={80}
                                            rowRenderer={(props) => renderRow(props, phoneMode)}
                                            noRowsRenderer={renderPlaceholder}
                                            rowCount={searchResults.length}
                                            overscanRowCount={3}
                                        />
                                    </Grid>;
                                }}
                            </WindowScroller>
                        )}
                </Grid>
            </div>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanySOs;