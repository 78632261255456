import { Grid, TextField, InputAdornment, Button } from "@material-ui/core";
import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import WarrantyUploader from "components/WarrantyCenter/WarrantyUploader";
import WarrantyProductAdd from "components/WarrantyCenter/WarrantyProductAdd";
import { makeAxiosCall, validateEmail } from "utils";
import SuccessAni from "components/utils/SuccessAni";
import moment from "moment";

import {
  ShowWindowDimensions,
  useWindowSize,
  useWindowWidth,
} from "hooks/useWindowSize";
import { storage } from "base.js";
import _ from "lodash";

const WarrantyCenter = () => {
  const [step, setStep] = useState(1);
  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState("tyhahn13@gmail.com");
  const [dateOfPurchase, setDateOfPurchase] = useState(new Date());

  const [receipt, setReceipt] = useState();

  const [userProducts, setUserProducts] = useState([]);

  const handleDateChange = (date) => {
    setDateOfPurchase(moment(date).format("YYYY-MM-DD"));
  };

  React.useEffect(() => {
    makeAxiosCall(
      "get",
      "products"
    )
    .then((res) => setProducts(res.data))
    .catch((err) => console.log(err));
  }, []);

  const submitRegistration = async () => {
    try {
      _.map(receipt, (r) => {
        const date = Date.now();
        const storageRef = storage.ref();
        let fileRef = storageRef.child(
          `receipts/${date}.${r.type.replace("image/", "")}`
        );
        let metadata = { contentType: r.type };

        fileRef
          .put(r, metadata)
          .then((storageData) => {
            storageData.ref.getDownloadURL().then((url) => {
              console.log(url);
              let payload = {
                ownerEmail: email,
                purchaseDate: dateOfPurchase,
                receiptUrl: url,
                products: [...userProducts],
              };

              makeAxiosCall(
                "post",
                "products/registration",
                payload
              )
              .then(() => {
                setStep(4);
              })
              .catch((err) => console.log(err));
            });
          })
          .catch((err) => console.log(err));
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearForm = () => {
    setStep(1);

    setEmail();
    setReceipt();
    setUserProducts([]);
  };

  const windowWidth = useWindowWidth();

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div style={{ margin: "0 auto", textAlign: "center" }}>
              <h3 className="reveal-text">Your Info</h3>
            </div>
            <TextField
              style={{ width: "100%", marginBottom: 20 }}
              label=""
              margin="normal"
              variant="outlined"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email Address"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fas fa-envelope"></i>
                  </InputAdornment>
                ),
              }}
            />

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <h4>Date of Purchase</h4>
              <DatePicker
                inputVariant="outlined"
                format="MM/DD/yyyy"
                autoOk
                value={dateOfPurchase}
                style={{ width: "100%", marginTop: 18 }}
                onChange={handleDateChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i className="fas fa-calendar-alt"></i>{" "}
                    </InputAdornment>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </>
        );
      case 2:
        return (
          <>
            <WarrantyUploader
              maxFileSize={500000000}
              uploadType="media"
              metadata={true}
              setReceipt={setReceipt}
            />
          </>
        );
      case 3:
        return (
          <>
            <h3 className="reveal-text">Choose Products</h3>

            <WarrantyProductAdd
              products={products}
              userProducts={userProducts}
              setUserProducts={setUserProducts}
            />
          </>
        );
      case 4:
        return (
          <>
            <SuccessAni
              marginTop={20}
              title="Submitted Successfully"
            ></SuccessAni>
            <Button
              onClick={() => clearForm()}
              type="submit"
              style={{ margin: "0 auto", display: "table", width: 150 }}
              variant="contained"
              color="primary"
              className="btn"
            >
              Submit Another
            </Button>
          </>
        );
      default:
        return "Nothing";
    }
  };

  const getProgress = () => {
    switch (step) {
      case 1:
        return "10%";
      case 2:
        return "40%";
      case 3:
        return "70%";
      case 4:
        return "100%";

      default:
        return "100%";
    }
  };

  const isDisabled = () => {
    const step1Complete = validateEmail(email) && dateOfPurchase;
    const step2Complete = receipt;
    const step3Complete = userProducts.length > 0;

    if (step === 1 && step1Complete) {
      return false;
    } else if (step === 2 && step2Complete) {
      return false;
    } else if (step === 3 && step3Complete) {
      return false;
    } else return true;
  };

  return (
    <div className="warranty-center">
      <div className="flex-container">
        <div className="progress-bar">
          <div className="progress-icons">
            <div className={`progress-icon ${step === 1 ? "active" : null}`}>
              <i className="fal fa-user-circle"></i>
              <p>Purchase Information</p>
            </div>
            <div className={`progress-icon ${step === 2 ? "active" : null}`}>
              <i className="fal fa-receipt"></i>
              <p>Receipt Upload</p>
            </div>
            <div className={`progress-icon ${step === 3 ? "active" : null}`}>
              <i className="fal fa-lightbulb"></i>

              <p>Product Selection</p>
            </div>
            <div className={`progress-icon ${step === 4 ? "active" : null}`}>
              <i className="fal fa-check-circle"></i>
            </div>
          </div>
          <div className="progress-tube">
            {windowWidth >= 768 && (
              <div style={{ height: getProgress() }} className="tube-gel"></div>
            )}
            {windowWidth < 768 && (
              <div
                style={{ height: "100%", width: getProgress() }}
                className="tube-gel"
              ></div>
            )}
          </div>
        </div>

        <div className="form-container">
          <div className="info-form">{renderStep()}</div>
          <div className="button-group">
            {step > 1 && step !== 4 && (
              <Button
                type="submit"
                onClick={() => setStep(step - 1)}
                style={{ width: 150, marginRight: 20 }}
                variant="contained"
                color="primary"
                className="btn"
              >
                Previous
              </Button>
            )}

            {step < 3 ? (
              <Button
                id="dark-bg-button"
                type="submit"
                onClick={() => setStep(step + 1)}
                style={{ width: 150 }}
                variant="contained"
                color="primary"
                disabled={isDisabled()}
                className="btn"
              >
                {step === 3 ? "Submit" : "Next"}
              </Button>
            ) : (
              <Button
                id="dark-bg-button"
                type="submit"
                onClick={() => submitRegistration()}
                style={{ width: 150 }}
                variant="contained"
                color="primary"
                disabled={isDisabled()}
                className="btn"
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarrantyCenter;
