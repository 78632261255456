import React, { useState } from "react";
import _ from "lodash";
import bgImage from "assets/images/pd-bg.jpg";
import PDPhase from "components/PDCenter/PDPhase";
import moment from "moment";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import ConnectionAlert from "components/utils/ConnectionAlert";

import { makeAxiosCall } from "utils";
import { PDCenterItemInterface } from "interfaces/PDItem";
import { UserContext } from "context/User";

const PDCenter = () => {
  const { user, internalUser } = React.useContext(UserContext);
  const [products, setProducts] = useState<PDCenterItemInterface[]>([]);
  const [discontinued, setDiscontinued] = useState<{name: string, notes: string}[]>([]);
  const [legacySalesTotal, setLegacySalesTotal] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  // const [dbotm, setDbotm] = useState([]);

  let isMounted = true;

  React.useEffect(() => {
    fetchProductData();
    fetchDiscontinuedProducts();
    fetchSalesData();
    // fetchDBOTM();
    setFetchInterval();
    return () => {
      isMounted = false;
    }
  }, [user]);

  const researchItems = React.useMemo(() => {
    let filterTitles = ["Research"];
    return products?.filter((prod) => filterTitles.includes(prod.phase));
  }, [products]);

  const developmentItems = React.useMemo(() => {
    let filterTitles = ["Development"];
    return products?.filter((prod) => filterTitles.includes(prod.phase));
  }, [products]);

  const marketingItems = React.useMemo(() => {
    let filterTitles = ["Marketing", "Launch Prep"];
    return products?.filter((prod) => filterTitles.includes(prod.phase));
  }, [products]);

  const launchItems = React.useMemo(() => {
    let filterTitles = ["Launch", "In Production", "GTM"];
    return products?.filter((prod) => filterTitles.includes(prod.phase));
  }, [products]);

  const fetchAllData = () => {
    fetchProductData();
    fetchDiscontinuedProducts();
    fetchSalesData();
    // fetchDBOTM();
    setLastUpdated(Date.now());
    setLoading(false);
  };

  const setFetchInterval = () => {
    setInterval(() => {
      fetchAllData();
    }, 60000);
  };

  const fetchProductData = async () => {
    setLoading(true);

    const result = await makeAxiosCall(
      "post",
      "sheets",
      {
        docId: "195SjHT0OfxwgswglAvxyD1Inv4Zv4k7zzsIOebm9dX0",
        sheetIndex: 0,
        valueRenderOption: "FORMULA",
        range: "A2:J27"
      },
    );

    if (result?.status == 200) {
      let productData = result.data.map((row) => {
        return {
          name: row[0],
          phase: row[1],
          subPhase: row[2],
          daysToProduction: row[6],
          daysToLaunch: row[4],
          image: row[8]
            ? row[8]
              .replace("=image(", "")
              .replace("=IMAGE(", "")
              .replace(")", "")
              .replace(/["]+/g, "")
            : "",
          notes: row[9],
        }
      });

      if (isMounted) {
        setProducts(productData);
      }
    }

    setLoading(false);
  };

  const fetchDiscontinuedProducts = async () => {
    const result = await makeAxiosCall(
      "post",
      "sheets",
      {
        docId: "195SjHT0OfxwgswglAvxyD1Inv4Zv4k7zzsIOebm9dX0",
        sheetIndex: 0,
        valueRenderOption: "FORMULA",
        range: "Discontinued!A2:H25"
      },
    );

    if (result?.data && isMounted) {
      setDiscontinued(result.data.map((row) => {
        return {
          name: row[0],
          notes: row[7]
        }
      }));
    }
  }

  const fetchSalesData = () => {
    makeAxiosCall(
      "post",
      "sheets",
      {
        docId: "1krNZHd5XTyOuYN2sQ8HmmXEFoaH6NPTFkUBZPNGLy08",
        sheetIndex: 1,
        range: "A39",
        valueRenderOption: "UNFORMATTED_VALUE",
      },
    ).then((result) => {
      if (!isNaN(result?.data) && isMounted) {
        setTotalSales(result.data);
      }
    });

    makeAxiosCall(
      "post",
      "sheets",
      {
        docId: "1krNZHd5XTyOuYN2sQ8HmmXEFoaH6NPTFkUBZPNGLy08",
        sheetIndex: 1,
        range: "A41",
        valueRenderOption: "UNFORMATTED_VALUE",
      },
    ).then((result) => {
      if (!isNaN(result?.data) && isMounted) {
        setLegacySalesTotal(result.data);
      }
    });
  };

  // const fetchDBOTM = () => {
  //   makeAxiosCall(
  //     "post",
  //     "sheets",
  //     {
  //       docId: "195SjHT0OfxwgswglAvxyD1Inv4Zv4k7zzsIOebm9dX0",
  //       sheetIndex: 1,
  //       valueRenderOption: "FORMULA",
  //     },
  //   )
  //     .then((result) => {
  //       const data = result.data[0];
  //       setDbotm({
  //         name: data[0],
  //         image: data[1]
  //           .replace("=IMAGE(", "")
  //           .replace("=image", "")
  //           .replace(")", "")
  //           .replace(/["]+/g, ""),
  //       });
  //     });
  // };

  const renderDiscontinued = () => {
    return _.map(discontinued, (product) => {
      return (
        <div className="discontinued-notes" key={product.name}>
          <h3> {product.name}</h3>
          {product.notes?.length > 0 && <p>{product.notes}</p>}
        </div>
      );
    });
  };

  return (
    <div
      className="pdcenter"
      style={{ backgroundImage: "url(" + bgImage + ")" }}
    >
      <div className="pd-header">
        <div className="pd-container">
          <ConnectionAlert />
          <Link to="/">
            <i className="fas fa-sign-out"></i>
          </Link>
        </div>
      </div>
      {user && internalUser ? (
        <Grid container className="phase-grid-parent">
          {/* SIDEBAR */}
          <Grid item sm={2} className="pd-sidebar" style={{ position: "sticky", top: 0 }}>
            {/* SALES NUMBERS */}
            <div className="pd-sales-numbers">
              <div className="pd-top-nav">
                <h2>Sales Numbers</h2>
              </div>

              <div className="sales-numbers">
                <h4 style={{ margin: 0, paddingBottom: 8 }}>Annual Sales</h4>
                <div className="number-large">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(totalSales)}
                </div>
              </div>

              <div className="sales-numbers">
                <h4 style={{ margin: 0, paddingBottom: 8 }}>Legacy Sales</h4>
                <div className="number-large">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(legacySalesTotal)}
                </div>

                <div className="pd-progress-bar">
                  <div
                    className="pd-progress-filled"
                    style={{
                      width: `${(legacySalesTotal / 80000000) * 1000}%`,
                    }}
                  ></div>
                </div>
                <p className="fine">*of $8MM Goal</p>
              </div>
            </div>

            {/* DISCONTINUED */}

            <div className="discontinued-list" style={{ overflow: "scroll", paddingTop: 0 }}>
              <h2>Discontinued</h2>
              {renderDiscontinued()}
            </div>

            {/* DBOTM */}

            {/* <div className="pd-dbotm">
                <div className="pd-top-nav">
                  <h2>DBOTM</h2>
                  <div className="dbotm-image">
                    {dbotm && <img style={{ width: "100%" }} src={dbotm.image} />}
                  </div>
                </div>

                <div className="dbotm-container"></div>
              </div> */}
          </Grid>

          {/* PD BOARD */}
          <Grid item sm={10} className="pd-board">
            <Grid item xs={12} className="last-updated">
              {loading && (
                <CircularProgress size={20} style={{ marginRight: 10 }} />
              )}
              Last Updated:{" "}
              {moment(lastUpdated).format("MMMM Do YYYY, h:mm:ss a")}
              <span
                onClick={() => fetchAllData()}
                style={{ cursor: "pointer", paddingLeft: 10 }}
              >
                Refresh now
              </span>
            </Grid>

            
            <Grid item container xs={12} spacing={2}>
              <Grid item md={6} xl={3}>
                <PDPhase index={0} title="Research" products={researchItems} />
              </Grid>
              <Grid item md={6} xl={3}>
                <PDPhase index={1} title="Development" products={developmentItems} />
              </Grid>
              <Grid item md={6} xl={3}>
                <PDPhase index={2} title="Marketing" products={marketingItems} />
              </Grid>
              <Grid item md={6} xl={3}>
                <PDPhase index={3} title="Launch" products={launchItems} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ): (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1 style={{ width: "100%", color: "white" }}>Prohibited</h1>
            <Button
              component={Link}
              to="/"
              style={{ margin: "0 auto" }}
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDCenter;
