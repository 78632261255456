import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

export default class SaveDialog extends Component {
  render() {
    const props = this.props;

    return (
      <Dialog
        open={props.saveDialog}
        // onClose={props.closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"You're gonna lose some work"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Beware. You haven't saved your project since you last made changes.
            Are you sure you want to create a new project and lose all unsaved
            progress?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeDialog("saveDialog")} color="primary">
            Cancel
          </Button>
          <Button onClick={props.resetForm} color="primary" autoFocus>
            New Project
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
