import React from "react";
import _ from "lodash";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CompanyInterface } from "interfaces/Company";
import ContactCard from "./ContactCard";
import ContactEditForm from "./ContactEditForm";
import ModalBasic from "components/utils/ModalBasic";
import { buildContacts, ContactInterface } from "interfaces/Contact";
import { makeAxiosCall } from "utils";
import Loading from "components/utils/Loading";
import { ResellerApprovalFormInterface } from "interfaces/ResellerApprovalForm";
import { UserContext } from "context/User";

interface CompanyContactsProps {
    company: CompanyInterface,
    resellerInfoForm?: boolean,
    resellerFormData?: ResellerApprovalFormInterface,
    setResellerFormData?: (formData: ResellerApprovalFormInterface) => void,
    autoOpenContact?: ContactInterface
}

const CompanyContacts = ({ company, resellerInfoForm = false, resellerFormData, setResellerFormData, autoOpenContact }: CompanyContactsProps) => {
    const { currentUser, updateCurrentUser } = React.useContext(UserContext);
    const [contacts, setContacts] = React.useState<ContactInterface[]>();
    const [loading, setLoading] = React.useState(true);
    const [newForm, setNewForm] = React.useState(false);

    let isMounted = true;

    React.useEffect(() => {
        if (!resellerInfoForm) {
            if (company) {
                fetchContacts();
            }
        } else {
            if (company?.approved) {
                fetchContacts();
            } else {
                setContacts(resellerFormData.contacts);
                setLoading(false);
            }
        }
        return () => {
            isMounted = false;
        };
    }, [company]);


    React.useEffect(() => {
        if (resellerInfoForm) {
            setResellerFormData({ ...resellerFormData, contacts: contacts });
        }
        
        if(currentUser && currentUser.company) {
            updateCurrentUser({...currentUser, company: {...currentUser.company, contacts: contacts}});
        }
        
    }, [contacts]);


    const fetchContacts = async () => {
        const response = await makeAxiosCall(
            "get",
            `contacts/${company.id}`
        ).catch(error => {
            console.error("Caught error getting contact info:");
            console.error(error);
        });

        if (response.data) {
            const contactData = buildContacts(response.data);
            if (isMounted) {
                setContacts(_.orderBy(contactData, ["lastName"], ["asc"]));
                setLoading(false);
            }
        }
    };

    const addContact = (contactData: ContactInterface, newId: number) => {
        setContacts(_.orderBy([...contacts, { ...contactData, id: newId }], ["lastName"], ["asc"]));
        setNewForm(false);
    };

    const updateContact = (contactData: ContactInterface, id: number) => {
        const unchanged = contacts.filter((contact) => contact.id !== id);
        setContacts(_.orderBy([...unchanged, { ...contactData, id: id }], ["lastName"], ["asc"]));
    };

    const deleteContact = (contactId: number) => {
        setContacts(_.orderBy(contacts.filter((contact) => contact.id !== contactId), ["lastName"], ["asc"]));
    };

    const renderBody = () => {
        if (loading) {
            return <Loading height="40vh" title="" position="relative" />;
        } else {
            if (contacts && contacts.length > 0) {
                return contacts.map(contact => {
                    const canEdit = contact.contactTypes.some((type) => type.name == "Billing") ? currentUser.accountLevel.canEditPaymentMethods : currentUser.accountLevel.canEditInfo;
                    return <ContactCard autoOpenContact={autoOpenContact} key={contact.id} companyId={company.id} contact={contact} canEdit={canEdit} updateCallback={updateContact} deleteCallback={deleteContact} />;
                });
            } else {
                return <p className="body-message">No Contacts Found</p>;
            }
        }
    };

    return company ? (
        <>
            <ModalBasic
                open={newForm}
                close={() => { setNewForm(false); }}
            >
                <Grid container spacing={2} className="contact-card contact-modal">
                    <Grid item xs={12}>
                        <h3>New Contact</h3>
                    </Grid>
                    <ContactEditForm companyId={company.id} createCallback={addContact} />
                </Grid>
            </ModalBasic>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={9}>
                    <h2>Contacts</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setNewForm(true);
                        }}
                    >
                        New Contact
                    </Button>
                </Grid>
            </Grid>
            {renderBody()}
        </>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyContacts;