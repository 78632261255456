import React from "react";
import { CartContext } from "context/Cart";
import ModalBasic from "components/utils/ModalBasic";
import { Button, Checkbox, Grid, TextareaAutosize, Tooltip } from "@material-ui/core";
import { OrderItemInterface } from "interfaces/Order";
import { KeyboardArrowUp, NoteAdd } from "@material-ui/icons";

const OrderItem = (props:{cartItem: OrderItemInterface}) => {
  const { cartItem } = props;
  const { deleteCartItem, currentOrder, updateCartItem } = React.useContext(CartContext);
  const [ openDelete, setOpenDelete ] = React.useState<boolean>(false);
  const [ showBstock, setShowBstock ] = React.useState<boolean>(false);
  const [ showNotes, setShowNotes ] = React.useState<boolean>(false);

  
  React.useEffect(() => {
    if(currentOrder.orderItems && currentOrder.orderItems.find((i) => i.product.bstock > 0)) {
      setShowBstock(true);
    }
    
  }, [currentOrder.orderItems]);

  const close = () => {
    setOpenDelete(false);
  };

  const updateBstock = useBstock => {
    cartItem.fillWithBstock = useBstock;
    updateCartItem(cartItem);
  };

  const updateItemNotes = notes => {
    cartItem.notes = notes;
    updateCartItem(cartItem);
  };

  const updateQuantity = quantity => {
    if (isNaN(quantity)) return;

    cartItem.quantity = quantity;
    updateCartItem(cartItem);
  };

  const validateQuantity = () => {
    if (isNaN(cartItem.quantity) || cartItem.quantity < 1) {
      updateQuantity(1);
    } else if (cartItem.quantity != Math.round(cartItem.quantity)) {
      updateQuantity(Math.round(cartItem.quantity));
    }
  }

  // const calculateSubtotal = (qty) => {
  //   const { volumePricing, salesPricing } = prod;
  //   if (salesPricing) {
  //     return salesPricing;
  //   } else if (volumePricing && volumePricing.length > 0) {
  //     const filterPricing = _.filter(volumePricing, (o) => {
  //       let max = 0;
  //       if (o.qtyMax === "0E-9") {
  //         max = 1000000;
  //       } else {
  //         max = parseFloat(o.qtyMax);
  //       }

  //       if (qty >= parseFloat(o.qtyMin) && qty <= max) {
  //         return o;
  //       }
  //     });
  //     if (filterPricing.length > 0) {
  //       return filterPricing[0].paAmount;
  //     } else {
  //       return prod.originalPrice;
  //     }
  //   } else {
  //     return prod.price;
  //   }
  // };

  // const getUnitPricing = (prod: ProductInterface) => {
  //   return prod.price;


  //   // if (prod.salesPricing) {
  //   //   return prod.salesPricing;
  //   // } else if (prod.bstockDiscount) {
  //   //   return prod.price - prod.price * prod.bstockDiscount;
  //   // } else {
  //   //   return prod.price;
  //   // }
  // };

  return (
    <React.Fragment>
      <ModalBasic close={close} open={openDelete}>
        <div style={{ padding: 60 }}>
          <h3> Remove From Cart?</h3>
          <div className="btn-container">
            <Button
              type="submit"
              style={{ width: "48%" }}
              variant="contained"
              color="primary"
              className="btn otl"
              onClick={() => close()}
            >
              No
            </Button>
            <Button
              onClick={() => {
                deleteCartItem(cartItem);
                close();
              }}
              type="submit"
              style={{ width: "48%" }}
              variant="contained"
              color="primary"
              className="btn red"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalBasic>
      <div className="order-item">
        <Grid container className="line-item">
          <Grid item className="num">
            {cartItem.product.name}
          </Grid>
          {showBstock ? 
            <Grid item className="bstock">
              {cartItem.product.bstock == 0 ? 
                <Tooltip title={"B-Stock not available for this item"}>
                  <p>N / A</p>
                </Tooltip> :
                <Checkbox
                  className="bstock-checkbox"
                  checked={cartItem.fillWithBstock == true ? true : false}
                  onChange={(e) => updateBstock(!cartItem.fillWithBstock)}
                  disabled={cartItem.product.bstock == 0}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            </Grid> : null
          }
          <Grid item className="price-per">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(cartItem.product.price)}
          </Grid>
          <Grid item className="price">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(cartItem.product.price * cartItem.quantity)}
          </Grid>
          <Grid item className="qty">
            <input
              type="number"
              min={1}
              value={cartItem.quantity}
              onChange={(e) => updateQuantity(e.target.value)}
              onBlur={validateQuantity}
            />
          </Grid>

          <Grid item className="notes" onClick={() => setShowNotes(!showNotes)}>
            {!showNotes ? <NoteAdd /> : <KeyboardArrowUp />}
          </Grid>

          <Grid item className="delete" onClick={() => setOpenDelete(true)}>
            <i className="far fa-trash-alt"></i>
          </Grid>
          {showNotes ? <Grid item xs={12}>
            <TextareaAutosize
              aria-label="notes"
              style={{height: "auto"}}
              minRows={1}
              placeholder="Item Notes"
              value={cartItem.notes}
              onChange={(e) => updateItemNotes(e.target.value)}
              className="order-item-notes"
            />
          </Grid> : null}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default OrderItem;
