import React from "react";
import _ from "lodash";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Paper, TextField } from "@material-ui/core";
import { emptyPDProjectUpdate, PDProjectInterface, PDProjectUpdateInterface } from "interfaces/ProductDevelopment";
import PDProjectCommunicationsListItem from "./ProjectCommunicationsListItem";
import DraftEditor from "components/utils/DraftEditor";
import 'scss/components/ProductDevelopment.scss';

interface PDProjectCommunicationsListProps {
    project: PDProjectInterface,
    addUpdate: (newUpdate: PDProjectUpdateInterface) => void,
    deleteUpdate: (deadUpdate: PDProjectUpdateInterface) => void,
    showErrorText: (errorText: string) => void
}

const PDProjectCommunicationsList = ({ project, addUpdate, deleteUpdate, showErrorText }: PDProjectCommunicationsListProps) => {
    const [updateElementList, setUpdateElementList] = React.useState([]);
    const [showNewUpdateForm, setShowNewUpdateForm] = React.useState<boolean>(false);
    const [showDeleteUpdateForm, setShowDeleteUpdateForm] = React.useState<boolean>(false);
    const [newUpdate, setNewUpdate] = React.useState<PDProjectUpdateInterface>(emptyPDProjectUpdate());
    const [susUpdate, setSusUpdate] = React.useState<PDProjectUpdateInterface>(emptyPDProjectUpdate());

    const floridaManText = [
        "Florida man breaks into a house, cleans it and leaves behind origami",
        "Florida Man blows a .339 blood alcohol level and gets a DUI on a golf cart",
        "Florida Man flees cops so fast that some of his clothes come off. So police identify him with DNA from his sock",
        "Florida man tries to walk out of a store with a chainsaw stuffed down his pants",
        "Florida man arrested for crashing car into a mall; says he was trying to time travel",
        "Police say a Florida man with no arms and no legs is armed and on the run"
    ];

    React.useEffect(() => {
        let elementList = []

        for (let pdi = 0; pdi < project.updates.length; pdi++) {
            const update = project.updates[pdi];
            elementList.push(_buildCommunicationUpdate(update, pdi));
        }

        setUpdateElementList(elementList);
    }, [project]);


    const newUpdatePopup = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={showNewUpdateForm}
                onClose={() => setShowNewUpdateForm(false)}
            >
                <DialogTitle>
                    This just in...
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    aria-label="update-header"
                                    label={"Headline"}
                                    variant="outlined"
                                    fullWidth={true}
                                    placeholder={floridaManText[Math.floor(Math.random() * floridaManText.length)]}
                                    value={newUpdate.headline}
                                    autoFocus={true}
                                    onChange={(e) => {
                                        setNewUpdate({...newUpdate, headline: e.target.value})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} id={"product-development-details-editor"}>
                                <DraftEditor
                                    onChange={(newHTML: string) => {
                                        setNewUpdate({...newUpdate, details: newHTML})
                                    }}
                                    startingHTML={newUpdate.details ?? ""}
                                    style={{ width: "100%" }}
                                    fieldName={"Details (optional)"}
                                    alwaysShowToolbar={true}
                                />
                                {/* <TextField
                                    aria-label="update-details"
                                    multiline={true}
                                    label={"Details (optional)"}
                                    variant="outlined"
                                    fullWidth={true}
                                    placeholder=""
                                    value={newUpdate.details}
                                    onChange={(e) => {
                                        
                                    }}
                                /> */}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="outlined"
                        color="primary"
                        className="btn"
                        style={{marginRight: 8}}
                        onClick={() => {
                            setShowNewUpdateForm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={() => {
                            addUpdate(newUpdate);
                            setNewUpdate(emptyPDProjectUpdate());
                            setShowNewUpdateForm(false);
                        }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const deleteUpdatePopup = () => {
      return (
          <Dialog
              fullWidth={true}
              open={showDeleteUpdateForm}
              onClose={() => setShowDeleteUpdateForm(false)}
          >
              <DialogTitle>
                  Mistakes happen. The Portal RX is here to help.
              </DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      Are you sure you want to delete this update?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button 
                      variant="outlined"
                      color="primary"
                      className="btn"
                      style={{marginRight: 8}}
                      onClick={() => {
                          setShowDeleteUpdateForm(false);
                      }}
                  >
                      Cancel
                  </Button>
                  <Button 
                      variant="contained"
                      color="primary"
                      className="btn"
                      onClick={() => {
                          deleteUpdate(susUpdate);
                          setShowDeleteUpdateForm(false);
                      }}>
                      Yes DELETE
                  </Button>
              </DialogActions>
          </Dialog>
      );
  };

    const _buildCommunicationUpdate = (update: PDProjectUpdateInterface, updateIndex: number) => {
        // if(!editMode) return _buildSampleStatic(sample, sampleIndex);
        return (
            <PDProjectCommunicationsListItem 
                key={"com-list-item-" + update.id} 
                update={update} 
                deleteUpdate={() => {
                    setSusUpdate(update);
                    setShowDeleteUpdateForm(true);
                }} 
            />
        );
    }

    return (
        <Grid item xs={12} container>
            {newUpdatePopup()}
            {deleteUpdatePopup()}
            <Grid item xs={12}>
                <div style={{height: 50}}>
                    <Button 
                        variant="contained"
                        color="primary"
                        className="btn"
                        style={{float: "right"}}
                        onClick={() => {
                            setShowNewUpdateForm(true);
                        }}>
                        Add Update
                    </Button>
                </div>
                <List>
                    {updateElementList}
                </List>
            </Grid>
        </Grid>
    )
};

export default PDProjectCommunicationsList;

