import React from "react";
import SettingsBar from "components/utils/SetttingsBar";
import { CSSTransition } from "react-transition-group";
import Upload from "components/utils/Upload";
const MediaLibrary = () => {
  const [activeMenu, setActiveMenu] = React.useState("Product Photos");

  const menu = [
    {
      name: "Product Photos",
      title: "Products",
    },
    {
      name: "Fixture Profiles",
      title: "Fixture Profiles",
    },
  ];
  return (
    <div className="media-library view">
      <h1>Media Library</h1>
      <SettingsBar
        menu={menu}
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
      />

      <CSSTransition
        in={activeMenu === "Product Photos"}
        unmountOnExit
        timeout={200}
        classNames="dialog"
      >
        <Upload maxFileSize={500000000} uploadType="media" metadata={true} />
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "Fixture Profiles"}
        unmountOnExit
        timeout={200}
        classNames="dialog"
      >
        <Upload maxFileSize={500000000} uploadType="profiles" metadata={true} />
      </CSSTransition>
    </div>
  );
};

export default MediaLibrary;
