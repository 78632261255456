import React from "react";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { UserInterface } from "interfaces/User";
import { Link } from "react-router-dom";

interface UserSearchResultProps {
    user: UserInterface
}

const UserSearchResult = ({ user }: UserSearchResultProps) => {
    const renderStatusIcon = () => {
        let icon: JSX.Element;
        let message: string;

        if (user.accountSignupStatus == "active") {
            icon = <i className="fa-solid fa-check green"></i>;
            message = "Active";
        } else if (user.accountSignupStatus == "needs review") {
            icon = <i className="fa-solid fa-circle-question orange"></i>;
            message = "Needs Review";
        } else if (user.accountSignupStatus == "applied") {
            icon = <i className="fa-solid fa-circle-exclamation orange"></i>;
            message = "Pending Application";
        } else if (user.accountSignupStatus == "declined") {
            icon = <i className="far fa-close tomato"></i>;
            message = "Denied";
        }

        if (icon && message) {
            return (
                <Tooltip
                    title={message}
                >
                    {icon}
                </Tooltip>
            );
        }
    };

    return (
        <Paper key={user.uid} elevation={2} className="grid-search-result">
            <Grid container item xs={12}>
                <Grid item xs={12} md={2} className="result-name">
                    <Typography noWrap variant="body2">
                        {user.displayName}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography noWrap variant="body2">
                        {user.email}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography noWrap variant="body2">
                        {user.company ? (
                            <Link
                                to={`/edit-company/${user.company.id}`}
                            >
                                {user.company.name}
                            </Link>
                        ) : ("No Company")}
                    </Typography>
                </Grid>
                <Grid item container xs={6} md={3} className="center-text">
                    <Grid item xs={6}>
                        {(user.lastActivity && (typeof user.lastActivity == "object")) ? ((user.lastActivity.getMonth() + 1) + "/" + user.lastActivity.getDate() + "/" + user.lastActivity.getFullYear()) : "None"}
                    </Grid>
                    <Grid item xs={3}>
                        {renderStatusIcon()}
                    </Grid>
                    <Grid item xs={3}>
                        <Link to={"/edit-user/" + user.uid} className="no-underline">
                            <i className="fas fa-eye blue"></i>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default UserSearchResult;