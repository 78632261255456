import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { OrderInterface } from "interfaces/Order";
import { Link } from "react-router-dom";

interface PortalOrderSearchResultProps {
    order: OrderInterface,
    mobileMode: boolean
}

const PortalOrderSearchResult = ({ order, mobileMode }: PortalOrderSearchResultProps) => {

    const renderStatus = (status) => {
        if (status === "Submitted") {
            return "status highlight blue";
        } else if (status === "Accepted") {
            return "status highlight green";
        } else if (status === "Canceled") {
            return "status highlight red";
        } else if (status === "Rep Responded") {
            return "status highlight purple";
        } else {
            return "status highlight subtle";
        }
    };

    return (
        <Paper key={order.id} elevation={2} className="grid-search-result">
            <Link to={"/pending-orders/" + order.id} className="no-underline">
                <Grid container item xs={12} className="center-text" alignItems="center">
                    <Grid item container md={4} xs={12}>
                        <Grid item xs={8} md={5} className="result-name">
                            <Typography noWrap variant="body2" style={mobileMode ? {fontWeight: "bold"} : {}}>
                                {order.customerPONum ? order.customerPONum : " N/A "}
                            </Typography>
                        </Grid>
                        {!mobileMode && <Grid item xs={5}>
                            <Typography noWrap variant="body2">
                                {order.fishbowlSONum ? order.fishbowlSONum : " N/A "}
                            </Typography>
                        </Grid>}
                        <Grid item xs={4} md={2}>
                            {order.createdAt.toLocaleString("en-US", { dateStyle: "short" })}
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={8}>
                        <Typography noWrap variant="body2" style={mobileMode ? {textAlign: "left"} : {}}>
                            {order.company.name}
                        </Typography>
                    </Grid>
                    {!mobileMode && <Grid item xs={2}>
                        <Typography noWrap variant="body2">
                            {order.orderItems.length + " items"}
                        </Typography>
                    </Grid>}
                    <Grid item md={2} xs={4}>
                        <div className={order.orderStatus ? renderStatus(order.orderStatus.name) : ""} style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis" }}>
                            {order.orderStatus ? order.orderStatus.name : ""}
                        </div>
                    </Grid>
                    {!mobileMode && <Grid item xs={1}>
                        <i className="fas fa-eye blue"></i>
                    </Grid>}
                </Grid>
            </Link>
        </Paper>
    );
};

export default PortalOrderSearchResult;