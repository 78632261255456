import React from "react";
import Segment from "components/ProfileEditor/Segment";
import { ProfileInterface, SegmentInterface } from "interfaces/ProfileEditor";
import _ from "lodash";

interface SegmentListProps {
    removeChannelFromFixture: (deleteKey: number, type: string) => void,
    activeProfile: ProfileInterface,
    activeSegment: number,
    changeSegmentValues: (value: string, type: string, order: number) => void,
    editSegmentName: (name: string, index: number) => void,
}

const SegmentList = ({
    removeChannelFromFixture,
    activeProfile,
    activeSegment,
    changeSegmentValues,
    editSegmentName,
}: SegmentListProps) => {
    const renderList = () => {
        try {
            if (activeProfile.channels[activeSegment].segments) {
                return _.map(
                    activeProfile.channels[activeSegment].segments,
                    (item: SegmentInterface, index: number) => {
                        return (
                            <div className="draggable-channel" key={item.key}>
                                <Segment
                                    changeSegmentValues={changeSegmentValues}
                                    segment={item}
                                    editSegmentName={editSegmentName}
                                    removeChannelFromFixture={removeChannelFromFixture}
                                    name={item.name}
                                    order={index}
                                />
                            </div>
                        );
                    }
                );
            }
        } catch (err) {
            return null;
        }
    };

    return <div>{renderList()}</div>;
};

export default SegmentList;
