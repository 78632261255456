import React from "react";

import { Grid, InputLabel, MenuItem, FormControl, Select, TextField, Button, CircularProgress, Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import _ from "lodash";
import { makeAxiosCall, validateEmail } from "utils";
import { UserContext } from "context/User";
import { buildInvite, InviteInterface } from "interfaces/Invite";
import { CompanyInterface } from "interfaces/Company";
import ModalBasic from "components/utils/ModalBasic";
import PermissionDisplay from "components/utils/PermissionDisplay";

interface MemberInviteProps {
    company: CompanyInterface,
    creationCallback?: (invite: InviteInterface) => void,
}

const MemberInvite = ({ company, creationCallback }: MemberInviteProps) => {
    const { currentUser, accountLevels, isAdmin } = React.useContext(UserContext);
    const [email, setEmail] = React.useState("");
    const [emailValidation, setEmailValidation] = React.useState("");
    const [availableAccountLevels, setAvailableAccountLevels] = React.useState(accountLevels);
    const [level, setLevel] = React.useState(availableAccountLevels[2]);
    const [sendingInvite, setSendingInvite] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState<{ text: string, severity: Color }>({ text: "", severity: "error" });
    const [modal, setModal] = React.useState(false);

    React.useEffect(() => {
        setAvailableAccountLevels(accountLevels.filter((level) => level.name !== "Super Admin"));
    }, [currentUser]);


    const inviteUser = () => {
        const additionalHelp = "\n\nPlease reach out to a Blizzard Portal Admin if you believe this is a mistake.";

        makeAxiosCall(
            "post",
            "invite",
            {
                accountLevelId: level.id,
                companyId: company.id,
                email: email.toLowerCase().trim(),
            }
        ).then((response) => {
            if (response.status === 201 && response.data) {
                setEmail("");
                if (creationCallback) {
                    creationCallback(buildInvite(response.data));
                }
                setModal(false);
            } else if (response.status === 200 && response.data) {
                if (response.data.message) {
                    setSnackbar({ text: `${email} has been added to your company.`, severity: "success" });
                }
                setEmail("");
                setModal(false);
            } else {
                const errorMessage = response.data.display ?
                    response.data.error + additionalHelp :
                    "An error occurred. Please try again.";
                setSnackbar({ text: errorMessage, severity: "error" });
            }
            setSendingInvite(false);
        }).catch((error) => {
            const errorMessage = error.data.display ?
                error.data.error + additionalHelp :
                "An error occurred. Please try again.";
            setSnackbar({ text: errorMessage, severity: "error" });
            setSendingInvite(false);
        });
    };

    const validate = () => {
        if (email.length < 1) {
            setEmailValidation("Required");
            return false;
        }
        if (!validateEmail(email)) {
            setEmailValidation("Not a valid email");
            return false;
        }
        return true;
    };

    const closeModal = () => {
        setEmail("");
        setEmailValidation("");
        setLevel(availableAccountLevels[2]);
        setModal(false);
    };


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        style={{ width: "100%", color: "white" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        Invite User
                    </Button>
                </Grid>
            </Grid>
            <ModalBasic
                open={modal}
                close={closeModal}
                unmountOnExit
            >
                <div style={{ padding: 25, margin: 10, maxWidth: 1000 }}>
                    <h3>Invite Member</h3>
                    <Grid container className="address-line" style={{ textAlign: "center" }} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Email"

                                value={email}
                                variant="outlined"
                                onChange={(e) => {
                                    setEmailValidation("");
                                    setEmail(e.target.value);
                                }}
                                error={emailValidation.length !== 0}
                                helperText={emailValidation}
                                inputProps={{ autoComplete: "off", type: "email" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                style={{ width: "100%" }}
                                className="state"
                                variant="outlined"
                            >
                                <InputLabel id="demo-simple-select-outlined-label" style={{ backgroundColor: "white" }}>
                                    Account Level
                                </InputLabel>
                                <Select
                                    onChange={(e: any) => setLevel(e.target.value)}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={level}
                                >
                                    {_.map(availableAccountLevels, (level) => {
                                        return (
                                            <MenuItem key={level.id} value={level}>
                                                {level.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <PermissionDisplay levelName={level.name} />
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSnackbar({ ...snackbar, text: "" });
                                    if (validate()) {
                                        setSendingInvite(true);
                                        inviteUser();
                                    }
                                }}
                                className="btn blue"
                            >
                                {sendingInvite ?
                                    <CircularProgress
                                        color="inherit"
                                        size={25}
                                    /> :
                                    "Invite"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </ModalBasic>
            <Snackbar
                open={snackbar.text.length > 0}
                autoHideDuration={5000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }
                    setSnackbar({ ...snackbar, text: "" });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity}
                    style={{ textAlign: "center" }}
                >
                    {snackbar.text}
                </Alert>
            </Snackbar>
        </>
    );
};

export default MemberInvite;