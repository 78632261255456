import React from "react";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { PromotionInterface } from "interfaces/Promotion";
import { niceText } from "utils";

interface PromotionSearchResultProps {
    promotion: PromotionInterface,
    mobileMode: boolean,
}

const PromotionSearchResult = ({ promotion, mobileMode }: PromotionSearchResultProps) => {
    const navigate = useNavigate();

    const renderActiveIcon = () => {
        let icon: JSX.Element;
        let message: string;
        const now = new Date();
        if (now < promotion.startDate) {
            icon = <i className="fa-solid fa-circle-question blue"></i>;
            message = "Not yet";
        } else if (now > promotion.endDate) {
            icon = <i className="fa-solid fa-close grey"></i>;
            message = "Over";
        } else {
            icon = <i className="fa-solid fa-check green"></i>;
            message = "Active";
        }

        return (
            <Link
                to={{
                    pathname: `/promotion/${promotion.id}`
                }}
                className="no-underline"
            >
                <Tooltip
                    title={message}
                >
                    {icon}
                </Tooltip>
            </Link>
        );
    };

    return (
        <Paper key={promotion.id} elevation={2} className="grid-search-result" onClick={() => {
            navigate(`/promotion/${promotion.id}`);
        }}>
            <Grid container item xs={12} className={mobileMode ? "" : "center-text"}>
                <Grid item container justifyContent="space-between" xs={12} md={3} className="result-name">
                    <Grid item xs={12}>
                        <Typography noWrap variant={mobileMode ? "h6" : "body2"}>
                            {promotion.discountCode}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={1} xs={7}>
                    <Link
                        to={{
                            pathname: `/promotion/${promotion.id}`
                        }}
                        className="no-underline"
                    >
                        {mobileMode ? "Uses: " : ""}{promotion.useCount}
                    </Link>
                </Grid>
                <Grid item md={2} xs={4}>
                    {niceText(promotion.discountType)}
                </Grid>
                <Grid item md={2} xs={4}>
                    {niceText(promotion.type)}
                </Grid>
                <Grid item md={1} xs={4}>
                    {promotion.startDate.toLocaleString("en-US", {dateStyle: "short"})}
                </Grid>
                <Grid item md={1} xs={4}>
                    {promotion.endDate.toLocaleString("en-US", {dateStyle: "short"})}
                </Grid>
                <Grid item md={1} xs={1}>
                    {renderActiveIcon()}
                </Grid>
                <Grid item xs={1}>
                    <Link to={"/edit-promotion/" + promotion.id} className="no-underline">
                        <i className="fas fa-eye blue"></i>
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PromotionSearchResult;