import React, { useContext, useEffect } from "react";
import { Button, Grid, Snackbar, TextField, FormControl, Select, Box, OutlinedInput, MenuItem, Tooltip, FormControlLabel, Checkbox, FormLabel, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import _ from "lodash";
import { makeAxiosCall, niceText, stringToMoney } from "utils";
import Loading from "components/utils/Loading";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import 'scss/components/Orders/PendingOrderSummary.scss';
import { BuildWebOrderObject, EmptyWebOrderRefund, WebOrderRefundTypeOptions, WebOrderInterface, WebOrderRefundInterface, WebOrderItemRefundInterface } from "interfaces/WebOrder";
import { useNavigate } from "react-router-dom";
import { DefaultPromotion, PromotionDiscountTypeOptions, promotionFromHasuraObject, PromotionInterface, PromotionQualificationTypeOptions } from "interfaces/Promotion";
import { ProductContext } from "context/Product";
import { DateRange } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { Moment } from "moment";
import { ProductInterface } from "interfaces/Product";

interface PromotionFormProps {
    existingPromotion?: PromotionInterface
}

const PromotionForm = ({existingPromotion} : PromotionFormProps) => {
    const [promotion, setPromotion] = React.useState<PromotionInterface>(existingPromotion ?? DefaultPromotion);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);

    const initialSnackbarState: {
        isOpen: boolean,
        message: string,
        severity: Color,
    } = {
        isOpen: false,
        message: '',
        severity: 'success',
    };
    
    const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
    const [productTypes, setProductTypes] = React.useState<string[]>([]);
    const { products } = useContext(ProductContext);

    let isMounted = true;

    let navigate = useNavigate();

    useEffect(() => {
        loadProductTypes();

        return () => {
            isMounted = false;
        };
    }, []);


    const loadProductTypes = () => {
        let productTypes = [];
        for (let pi = 0; pi < products.length; pi++) {
        const product = products[pi];
        if(product.productType && !productTypes.includes(product.productType)) {
            productTypes.push(product.productType);
        }
        }
        setProductTypes(productTypes);
    }

    const validatePromotion = () => {
        if(promotion.endDate < promotion.startDate) {
            setSnackbar({
                isOpen: true,
                message: "A promotion cannot start after it has ended",
                severity: "error",
            });
            return false;
        }

        if(promotion.discountValue <= 0) {
            setSnackbar({
                isOpen: true,
                message: "A promotion must have a positive discount",
                severity: "error",
            });
            return false;
        }

        if(promotion.type == "by_products" && promotion.products.length == 0) {
            setSnackbar({
                isOpen: true,
                message: "You must select at least one product for a By Product promotion",
                severity: "error",
            });
            return false;
        }

        if(promotion.type == "by_types" && promotion.productTypes.length == 0) {
            setSnackbar({
                isOpen: true,
                message: "You must select at least one product type for a By Types promotion",
                severity: "error",
            });
            return false;
        }

        if(promotion.discountCode.length == 0) {
            setSnackbar({
                isOpen: true,
                message: "Please set a promotion code",
                severity: "error",
            });
            return false;
        }

        return true;
    }

    const deletePromotion = () => {
        setOpenDelete(false);
        makeAxiosCall(
            "delete",
            "promotions/" + promotion.id
        ).then((res) => {
            navigate(-1);
        }).catch((error) => {
            setSnackbar({
                isOpen: true,
                message: "Failed to delete promotion. Check the logs",
                severity: "error",
            });
            console.log("Error:" + error);
        });
    }
    const savePromotion = () => {

        if(saving || !validatePromotion()) return;

        setSaving(true);

        let productIds: number[] = [];

        if(promotion.products) {
            for (let pi = 0; pi < promotion.products.length; pi++) {
                productIds.push(promotion.products[pi].id);
            }
        }

        makeAxiosCall(
            "post",
            "promotions/" + (promotion.id ? promotion.id : ""),
            {
                id: promotion.id,
                startDate: promotion.startDate.toISOString(),
                endDate: promotion.endDate.toISOString(),
                type: promotion.type,
                discountType: promotion.discountType,
                discountCode: promotion.discountCode,
                products: productIds,
                productTypes: promotion.productTypes,
                usageLimit: promotion.usageLimit,
                discountValue: promotion.discountType == "percent" ? promotion.discountValue / 100  : promotion.discountValue,
                autoApply: promotion.autoApply,
                notes: promotion.notes
            }
        ).then((res) => {
            setSaving(false);
            if(res.data) {
                setPromotion(promotionFromHasuraObject(res.data, products));
                navigate(-1);
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Failed to save promotion.",
                    severity: "error",
                });
            }
            
        }).catch((error) => {
            setSaving(false);
            if(error && error.data && error.data.error) {
                if(error.data.error.indexOf("Uniqueness violation") > -1) {
                    setSnackbar({
                        isOpen: true,
                        message: "Promotion code must be unique",
                        severity: "error",
                    });
                } else {
                    setSnackbar({
                        isOpen: true,
                        message: error.data.error,
                        severity: "error",
                    });
                }
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Failed to save promotion. Check the logs",
                    severity: "error",
                });
            }
            console.log("Error:" + error);
        }
        )
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, isOpen: false });
    };

    const buildTypeSelector = () => {
        if(promotion.type !== "by_types") {
            return <div></div>;
        }
        return (
            <Grid container style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
                <Grid container item xs={12} spacing={2} alignItems="center" >
                    <Autocomplete
                        value={promotion.productTypes}
                        multiple={true}
                        fullWidth={true}
                        options={productTypes}
                        onChange={(event, newValue) => {
                            setPromotion({...promotion, productTypes: newValue})
                        }}
                        getOptionLabel={(type) => type}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Product Types"}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }

    const buildProductSelector = () => {
        if(promotion.type !== "by_products") {
            return <div></div>;
        }
        return (
            <Grid container style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
                <Grid container item xs={12} spacing={2} alignItems="center" >
                    <Autocomplete
                        value={promotion.products}
                        multiple={true}
                        fullWidth={true}
                        options={products}
                        onChange={(event, newValue: ProductInterface[]) => {
                            setPromotion({...promotion, products: newValue})
                        }}
                        getOptionLabel={(product) => product ? product.name : "Product Not Found"}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Products"}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }

  
    return (
        <Grid container className="admin view" style={{paddingLeft: 60, paddingRight: 60}}>
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={30000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                <Alert
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
            >
                <DialogTitle>Delete Promotion?</DialogTitle>
                <DialogContent className="wizard">
                    <p>Are you sure you want to delete this promotion?</p>
                    <p>Usage data will be deleted as well. If you want to stop this promotion, perhaps setting the End Date to a passed would be better?</p>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button
                            onClick={() => setOpenDelete(false)}
                            variant="contained"
                            color="primary"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={deletePromotion}
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "tomato" }}
                        >
                            Delete
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Grid item xs={12}>
                <h1 style={{fontSize: 34}}>{existingPromotion ? existingPromotion.discountCode : "New Promotion"}</h1>
            </Grid>


            <Grid container style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
                <Grid container item xs={12} spacing={2} alignItems="center" >
                    <Grid item xs={12}>
                        <h3>Types</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            variant="outlined"
                            style={{ width: "100%", marginBottom: 8 }}
                        >
                            <FormLabel>Qualification Type</FormLabel>
                            <Select
                                style={{ width: "100%" }}
                                key="promotion-type"
                                label="Qualification Type"
                                value={promotion.type}
                                renderValue={() => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                        {niceText(promotion.type)}
                                    </Box>
                                )}
                                onChange={(e: React.ChangeEvent<{
                                    name?: string;
                                    value: string;
                                }>) => {
                                    let productTypes = promotion.productTypes;
                                    let discountedProducts = promotion.products;
                                    if(e.target.value == "by_types" && !promotion.productTypes) productTypes = [];
                                    if(e.target.value == "by_products" && !promotion.products) discountedProducts = [];

                                    setPromotion({...promotion, type: e.target.value, productTypes: productTypes, products: discountedProducts});
                                }}
                                input={<OutlinedInput />}
                            >
                                {PromotionQualificationTypeOptions.map(
                                    (t) => {
                                        return (
                                            <MenuItem
                                                key={t}
                                                value={t}
                                            >
                                                <i className={t == promotion.type ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: t == promotion.type ? "green" : "grey" }}></i>
                                                {niceText(t)}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            variant="outlined"
                            style={{ width: "100%", marginBottom: 8 }}
                        >
                            <FormLabel>Discount Type</FormLabel>
                            <Select
                                style={{ width: "100%" }}
                                key="discount-type"
                                label="Discount Type"
                                value={promotion.discountType}
                                renderValue={() => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                        {niceText(promotion.discountType)}
                                    </Box>
                                )}
                                onChange={(e: React.ChangeEvent<{
                                    name?: string;
                                    value: string;
                                }>) => {
                                    setPromotion({...promotion, discountType: e.target.value});
                                }}
                                input={<OutlinedInput />}
                            >
                                {PromotionDiscountTypeOptions.map(
                                    (t) => {
                                        return (
                                            <MenuItem
                                                key={t}
                                                value={t}
                                            >
                                                <i className={t == promotion.discountType ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: t == promotion.discountType ? "green" : "grey" }}></i>
                                                {niceText(t)}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={2} alignItems="center" >
                    <Grid item xs={12}>
                        <h3>Details</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: "100%" }}
                            label="Discount Amount"
                            margin="normal"
                            type={"number"}
                            variant="outlined"
                            onChange={(e) => {
                                let newValue = parseFloat(e.target.value);
                                if(!newValue) newValue = 0;
                                setPromotion({...promotion, discountValue: newValue})
                            }}
                            value={promotion.discountValue}
                            helperText={(promotion.discountType == "percent" ? "% off " : "Amount off per ") + (promotion.type == "any" ? "order" : "qualifying product")}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            style={{ width: "100%" }}
                            label="Usage Limit"
                            margin="normal"
                            type={"number"}
                            variant="outlined"
                            onChange={(e) => {
                                let newValue = parseInt(e.target.value);
                                if(!newValue) newValue = 0;
                                setPromotion({...promotion, usageLimit: newValue})
                            }}
                            value={promotion.usageLimit}
                            helperText={"0 means no limit"}
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <TextField
                            style={{ width: "100%" }}
                            label="Promotion Code"
                            margin="normal"
                            type={"text"}
                            variant="outlined"
                            onChange={(e) => {
                                setPromotion({...promotion, discountCode: e.target.value})
                            }}
                            value={promotion.discountCode}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Tooltip title={"Should this applied automatically if somebody adds a qualifying product?"} placement="top" arrow>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={promotion.autoApply}
                                        onChange={() => {
                                            setPromotion({...promotion, autoApply: !promotion.autoApply})
                                        }}
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label={"Auto Apply"}
                                classes={{ label: "search-filter-option" }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Start Date</h4>
                        <DateRange />
                        <DatePicker 
                            value={promotion.startDate}
                            onChange={function (date: Moment): void {
                                setPromotion({...promotion, startDate: date.toDate()});
                            }}
                            format={"MM/DD/yy"}
                            style={{width: "80%"}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <h4>End Date</h4>
                        <DateRange />
                        <DatePicker 
                            value={promotion.endDate}
                            onChange={function (date: Moment): void {
                                setPromotion({...promotion, endDate: date.toDate()});
                            }}
                            format={"MM/DD/yy"}
                            style={{width: "80%"}}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            style={{ width: "100%" }}
                            label="Notes"
                            helperText={"Description of the promotion."}
                            margin="normal"
                            multiline={true}
                            type={"text"}
                            variant="outlined"
                            onChange={(e) => {
                                setPromotion({...promotion, notes: e.target.value})
                            }}
                            value={promotion.notes}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {buildTypeSelector()}
            {buildProductSelector()}

            <Grid container spacing={4} alignItems="center" style={{marginTop: 20}}>
                <Grid item md={6} xs={2}></Grid>
                {promotion.id && promotion.discountCode != "blizzard_rewards" ? <Grid item md={3} xs={5}>
                    <Button
                        onClick={() => setOpenDelete(true)}
                        variant={"outlined"}
                        color={"secondary"}
                        fullWidth={true}
                    >
                        Delete
                    </Button>
                </Grid> : <Grid item md={3} xs={5}></Grid>}
                <Grid item md={3} xs={6}>
                    <Button
                        onClick={savePromotion}
                        variant={"contained"}
                        color={"primary"}
                        fullWidth={true}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PromotionForm;
