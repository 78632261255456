import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import { CompanyInterface } from "interfaces/Company";
import moment from "moment";
import { makeAxiosCall } from "utils";

interface ApprovalExpirationCardProps {
    company: CompanyInterface,
    update: (updateData: object) => Promise<boolean>
    refresh?: () => void,
}

const ApprovalExpirationCard = ({ company, update, refresh }: ApprovalExpirationCardProps) => {

    const defaultDate = moment().add(90, "days").format("YYYY-MM-DD");
  
    const [expirationDate, setExpirationDate] = React.useState<string>(defaultDate);
    const [numberOfDays, setNumberOfDays] = React.useState("90");
    const [expirationReason, setExpirationReason] = React.useState<string>("");
    const [currentProcess, setCurrentProcess] = React.useState<string>("");
    const [error, setError] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);

    const isEditable: boolean = React.useMemo(() => {
        return company.approved &&
                company.approvalStatus &&
                (
                    !company.approvalStatus.infoFormComplete ||
                    company.approvalStatus.infoFormDenialReason?.length > 0 ||
                    !company.approvalStatus.agreementComplete ||
                    company.approvalStatus.agreementDenialReason?.length > 0 ||
                    !company.approvalStatus.certificateUploaded ||
                    company.approvalStatus.certificateDenialReason?.length > 0
                );
    }, [company]);

    const activeExpiration: boolean = React.useMemo(() => {
        return company.approvalStatus.expirationDate !== null;
    }, [company]);

    const submitForm = async (): Promise<void> => {
        setCurrentProcess("submitting");
        setError(false);

        if (moment(expirationDate).isAfter(moment())) {
            const updateData = {
                expirationDate: expirationDate,
                expirationReason: expirationReason
            };
    
            const success = await update(updateData);
    
            if (success) {
                refresh();
                closeForm();
            }
        } else {
            setError(true);
        }

        setCurrentProcess("");
    };

    const cancelExpiration = async (): Promise<void> => {
        setCurrentProcess("cancelling");
        
        const success = await makeAxiosCall(
            "post",
            `clear-expiration/${company.approvalStatus.id}`,
        ).catch(error => {
            console.error(error);
        });

        if (success) {
            refresh();
        }

        setCurrentProcess("");
    };

    const openForm = (): void => {
        setShowForm(true);
    };

    const closeForm = (): void => {
        setExpirationDate(defaultDate);
        setError(false);
        setNumberOfDays("90");
        setExpirationReason("");
        setShowForm(false);
    };

    const renderForm = (): JSX.Element => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TextField 
                        label="Expiration Date"
                        variant="outlined"
                        fullWidth
                        value={expirationDate}
                        type="date"
                        onChange={(e) => setExpirationDate(e.target.value)}
                        onBlur={() => setNumberOfDays((moment(expirationDate).diff(moment(), "days") + 1).toString())}
                        helperText={error ? "Must be a future date" : ""}
                        error={error}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Days"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={numberOfDays}
                        onChange={e => setNumberOfDays(e.target.value)}
                        onBlur={() => setExpirationDate(moment().add(numberOfDays, "days").format("YYYY-MM-DD"))}
                        error={error}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Reason"
                        fullWidth
                        multiline
                        value={expirationReason}
                        onChange={(e) => {
                            setExpirationReason(e.target.value);
                        }}
                        variant="outlined"
                        helperText={"Optional"}
                    />
                </Grid>
            </Grid>
        );
    };


    return (
        <>
            <Dialog
                open={showForm}
                onClose={closeForm}
                scroll="body"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Set Portal Access Expiration</DialogTitle>
                <DialogContent>
                    {renderForm()}
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{ width: "100%", backgroundColor: "tomato" }}
                        variant="contained"
                        color="primary"
                        onClick={closeForm}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                    >
                        {currentProcess === "submitting" ?
                            <CircularProgress 
                                color="inherit"
                                size={24}
                            /> :
                            "Submit"
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper elevation={2} className="contact-card">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                        <Typography variant="h5">Portal Access Expiration</Typography>
                    </Grid>
                    <Grid item container xs={4} justifyContent="center" alignItems="center">
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            {activeExpiration && (
                                <i className="far fa-triangle-exclamation orange"></i>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                            {activeExpiration && (
                                `Access Until ${company.approvalStatus.expirationDate.getUTCMonth() + 1}/${company.approvalStatus.expirationDate.getUTCDate()}/${company.approvalStatus.expirationDate.getUTCFullYear()}`
                            )}
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} alignItems={"center"}>
                        <Grid item xs={8}>
                            <p>
                                Set a date for the company&apos;s access to be 
                                revoked if they do not complete all requirements.
                            </p>
                        </Grid>
                        <Grid item xs={4}>
                            {activeExpiration ? (
                                <Button
                                    style={{ width: "100%", backgroundColor: "tomato" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={cancelExpiration}
                                >
                                    {currentProcess === "cancelling" ?
                                        <CircularProgress 
                                            color="inherit"
                                            size={24}
                                        /> :
                                        "Cancel"
                                    }
                                </Button>
                            ) : (
                                <Tooltip
                                    title={!isEditable ? "Only available to companies with final approval and incomplete or denied items" : ""}
                                >
                                    <span>
                                        <Button
                                            style={{ width: "100%" }}
                                            variant="contained"
                                            color="primary"
                                            onClick={openForm}
                                            disabled={!isEditable}
                                        >
                                            Revoke Access
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default ApprovalExpirationCard;