import { BasicCompanyInterface, buildCompanyInfo, CompanyInterface } from "./Company";

export interface UserInterface {
    uid: string;
    displayName: string,
    firstName: string,
    lastName: string,
    email: string,
    accountLevel: UserAccountPermissionsInterface,
    company?: CompanyInterface,
    accountSignupStatus?: string,
    userProvidedCompanyName?: string,
    createdAt?: Date,
    hasApplication: boolean,
    otherCompanyOptions: BasicCompanyInterface[],
    lastActivity?: Date,
    salesTeamMember: boolean
}

export interface UserAccountPermissionsInterface {
    viewInventory: boolean,
    viewOrders: boolean,
    viewProducts: boolean,
    canOrder: boolean,
    canManageMembers: boolean,
    canEditInfo: boolean,
    canEditPaymentMethods: boolean,
    name: string,
    id: number,
}


export const buildUser = async (userData): Promise<UserInterface> => {
    let companyData: CompanyInterface | null;
    let isAdmin = false;
    if (userData.company) {
        companyData = await buildCompanyInfo(userData.company);

        if (companyData.companyType == "Internal" || userData.account_level.name == "Super Admin") {
            isAdmin = true;
        }
    }

    let userEmail = userData.email;
    if (!userEmail && userData.user && userData.user.email) {
        userEmail = userData.user.email;
    }

    let companyOptions = [];
    if(userData.other_company_assignments && userData.other_company_assignments.length) {
        for (let ocai = 0; ocai < userData.other_company_assignments.length; ocai++) {
            companyOptions.push(userData.other_company_assignments[ocai].company);
        }
    }

    let test = {
        email: userEmail ?? "No Email!",
        uid: userData.id,
        displayName: (userData.name && userData.name != "anonymous" && userData.name != "No Name" ? userData.name : userData.first_name + " " + userData.last_name) ?? "No Name",
        firstName: userData.first_name ?? "",
        lastName: userData.last_name ?? "",
        company: companyData,
        accountLevel: buildAccountPermissions(userData.account_level, isAdmin),
        accountSignupStatus: userData.portal_account_signup_status,
        userProvidedCompanyName: userData.user_provided_company_name,
        createdAt: userData.created_at ? new Date(userData.created_at) : null,
        hasApplication: userData.application && userData.application.id ? true : false,
        otherCompanyOptions: companyOptions,
        lastActivity: userData.last_activity ? new Date(userData.last_activity) : null,
        salesTeamMember: userData.sales_team_member ? userData.sales_team_member : false
    };

    return test;
};


export const buildUserList = async (userListData): Promise<Array<UserInterface>> => {
    const userList: Array<UserInterface> = [];

    for (let uli = 0; uli < userListData.length; uli++) {
        const userData = userListData[uli];
        userList.push(await buildUser(userData));
    }

    return userList;
};



export const buildAccountPermissions = (permissionData, isAdmin?: boolean): UserAccountPermissionsInterface => {
    return {
        viewInventory: isAdmin ? true : permissionData.can_view_inventory,
        viewOrders: isAdmin ? true : permissionData.can_view_orders,
        viewProducts: isAdmin ? true : permissionData.can_view_products,
        canOrder: isAdmin ? true : permissionData.can_order,
        canManageMembers: permissionData.can_manage_members,
        canEditInfo: permissionData.can_edit_info,
        canEditPaymentMethods: permissionData.can_edit_payment_methods,
        name: permissionData.name,
        id: permissionData.id,
    };
};

export const emptyUser = () => {
    return {
        email: "Nobody@here.com",
        uid: "",
        displayName: "Nobody",
        firstName: "Nobody",
        lastName: "Here",
        accountLevel: {
            viewInventory: false,
            viewOrders: false,
            viewProducts: false,
            canOrder: false,
            canManageMembers: false,
            canEditInfo: false,
            canEditPaymentMethods: false,
            name: "Restricted"
        }
    };
};
export const buildBasicUserInterfaceList = (rawData): BasicUserInterface[] => {
    let users: BasicUserInterface[] = [];


    if(rawData) {
        for (let ui = 0; ui < rawData.length; ui++) {

            let name = rawData[ui].name;

            if(name == "No Name" || name == "anonymous") name = rawData[ui].email

            users.push({
                id: rawData[ui].id,
                name: name,
                email: rawData[ui].email,
                companyId: rawData[ui].associated_company_id,
            })
        }
    }

    return users;
}


export interface BasicUserInterface {
    id: string;
    name: string,
    email: string,
    companyId?: number
}