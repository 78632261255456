import React from "react";
import _ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, MenuItem, OutlinedInput, Paper, Select, TextField } from "@material-ui/core";
import { emptyPDProjectSample, PDProductBaubleInterface, PDProjectBaubleStatuses, PDProjectBaubleTypes, PDProjectInterface } from "interfaces/ProductDevelopment";
import { Add, ExpandMore, HelpOutline } from "@material-ui/icons";
import DraftEditor from "components/utils/DraftEditor";
import { makeAxiosCall } from "utils";

interface PDProjectSampleListProps {
    project: PDProjectInterface,
    onUpdate: (newSamples: PDProductBaubleInterface[]) => void,
    newProjectForm: boolean,
    showErrorText: (errorText: string) => void
    saveBauble: (bauble: PDProductBaubleInterface) => void
}

const PDProjectSampleList = ({ project, onUpdate, newProjectForm, saveBauble }: PDProjectSampleListProps) => {
    const [sampleElementList, setSampleElementList] = React.useState([]);
    const [canAddAnother, setCanAddAnother] = React.useState(true);
    const [hasChanges, setHasChanges] = React.useState({});
    const [showNameHelp, setShowNameHelp] = React.useState(false);

    React.useEffect(() => {
        let elementList = []
        let addAnother = true;

        for (let pdi = 0; pdi < project.baubles.length; pdi++) {
            const sample = project.baubles[pdi];
            if(!sample.name || sample.name.length < 1) {
                addAnother = false;
            }
            elementList.push(_buildSample(sample, pdi));
        }

        setCanAddAnother(addAnother);
        setSampleElementList(elementList);
    }, [project, hasChanges]);

    const _removeSample = (sample: PDProductBaubleInterface) => {
        makeAxiosCall(
            "delete",
            `pd/baubles/${sample.id}`
        ).then(async (_) => {
            let newList = [...project.baubles];
            onUpdate(newList.filter((s) => s.id != sample.id));
        }).catch((error) => {
            console.error(error);   
        });
    }

    const _buildDeleteButton = (sample: PDProductBaubleInterface) => {
      return (
          <Button 
              variant="contained"
              color="secondary"
              style={{height: "100%", textAlign: "right", float: "right"}}
              onClick={() => {
                _removeSample(sample);
              }}
              disabled={sample.mainSample}
          >
              Delete <i className="fas fa-trash grey" style={{fontSize: "1.2em", paddingLeft: 8}}></i>
          </Button>
      );
    }

    const _buildSampleStatic = (sample: PDProductBaubleInterface, sampleIndex: number) => {
        return (<ListItem key={sampleIndex + "-demoed-products-list-item-static"} style={{padding: 0}}>
            <Paper style={{padding: 32, marginBottom: 32, width: "100%"}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={9}>
                        <h3>{sample.name} {sample.vendorPartName && (<span style={{ color: "gray" }}>({sample.vendorPartName})</span>)}</h3>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Select
                            key={"sample-status-" + sampleIndex}
                            value={sample.status}
                            onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                let sampleListCopy = [...project.baubles];

                                sampleListCopy[sampleIndex].status = e.target.value;

                                onUpdate(sampleListCopy);
                                setHasChanges({...hasChanges, [sampleIndex]: true});
                            }}
                            input={<OutlinedInput />}
                            style={{ width: "100%", textAlign: "left" }}
                        >
                            {PDProjectBaubleStatuses.map(
                                (stat) => {
                                    return (
                                        <MenuItem
                                            key={stat + "-dropdown-status-option-" + sampleIndex}
                                            value={stat}
                                        >
                                            {stat}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            aria-label={"notes-" + sampleIndex}
                            label={"Notes"}
                            variant="outlined"
                            fullWidth={true}
                            multiline={true}
                            placeholder="Just a basic 'what is this thing'"
                            value={sample.description}
                            onChange={(e) => {
                                let sampleListCopy = [...project.baubles];

                                sampleListCopy[sampleIndex].description = e.target.value;
                                onUpdate(sampleListCopy);
                                setHasChanges({...hasChanges, [sampleIndex]: true});
                            }}
                            className="order-item-notes"
                        />
                    </Grid>
                    <Grid item xs={12} key={`${sample.id}-changelog`}>
                        <Accordion elevation={1}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                style={{
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    alignItems: "center",
                                }}
                                className="line-item"
                            >
                                Changelog
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <DraftEditor
                                        onChange={(newHTML: string) => {
                                            let sampleListCopy = [...project.baubles];

                                            sampleListCopy[sampleIndex].changelog = newHTML;
                                            onUpdate(sampleListCopy);
                                            setHasChanges({...hasChanges, [sampleIndex]: true});
                                        }}
                                        startingHTML={sample.changelog ?? ""}
                                        style={{ width: "100%" }}
                                        fieldName={"Changelog"}
                                        alwaysShowToolbar={true}
                                    />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {!newProjectForm && hasChanges[sampleIndex] && <Button
                            variant="contained"
                            color="primary"
                            style={{height: "100%"}}
                            onClick={() => {
                                setHasChanges({...hasChanges, [sampleIndex]: false});
                                saveBauble(project.baubles[sampleIndex]);
                            }}
                        >
                            Save Changes
                        </Button>}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {!sample.mainSample ? <div style={{width: "100%"}}>
                            {_buildDeleteButton(sample)}

                            <Button
                                variant="outlined"
                                color="primary"
                                style={{height: "100%", textAlign: "right", float: "right", marginRight: 8}}
                                onClick={() => {
                                    let previousValue = sample.mainSample;
                                    if(previousValue) {
                                        // showErrorText("Each project can have one and only one Main Sample. To choose a new main sample, go to that sample and select it.")
                                    } else {
                                        let sampleListCopy = [...project.baubles];
                                        
                                        for (let slci = 0; slci < sampleListCopy.length; slci++) {
                                            sampleListCopy[slci].mainSample = (slci == sampleIndex);
                                        }

                                        onUpdate(sampleListCopy);
                                    }
                                }}
                                disabled={sample.mainSample}
                            >
                                Make Main Sample <i className="fas fa-star grey" style={{fontSize: "1.2em", paddingLeft: 8}}></i>
                            </Button>
                        </div> : <h3 style={{color: "grey", textAlign: "right"}}>Main Sample</h3>}
                    </Grid>
                </Grid>
            </Paper>
        </ListItem>);
    }

    const _buildSample = (bauble: PDProductBaubleInterface, sampleIndex: number) => {
        if(!newProjectForm && bauble.id != 0) return _buildSampleStatic(bauble, sampleIndex);
        
        return (
            <ListItem key={sampleIndex + "-demoed-products-list-item"} style={{padding: 0}}>
                <Paper style={{padding: 32, marginBottom: 32, width: "100%"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Select
                                key={"sample-type-" + sampleIndex}
                                value={bauble.type}
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    let sampleListCopy = [...project.baubles];
    
                                    sampleListCopy[sampleIndex].type = e.target.value;

                                    onUpdate(sampleListCopy);
                                }}
                                input={<OutlinedInput />}
                                style={{ width: "100%", textAlign: "left" }}
                            >
                                {PDProjectBaubleTypes.map(
                                    (type) => {
                                        return (
                                            <MenuItem
                                                key={type + "-dropdown-option-" + sampleIndex}
                                                value={type}
                                            >
                                                {type}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Select
                                key={"sample-status-" + sampleIndex}
                                value={bauble.status}
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    let sampleListCopy = [...project.baubles];
    
                                    sampleListCopy[sampleIndex].status = e.target.value;

                                    onUpdate(sampleListCopy);
                                }}
                                input={<OutlinedInput />}
                                style={{ width: "100%", textAlign: "left" }}
                            >
                                {PDProjectBaubleStatuses.map(
                                    (stat) => {
                                        return (
                                            <MenuItem
                                                key={stat + "-dropdown-status-option-" + sampleIndex}
                                                value={stat}
                                            >
                                                {stat}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                aria-label={"vendor-name" + sampleIndex}
                                label={"Vendor's Part Name"}
                                variant="outlined"
                                helperText={"What does the factory call it? e.g. The Cue Slayer is a 'Victory 3'"}
                                fullWidth={true}
                                placeholder=""
                                value={bauble.vendorPartName}
                                onChange={(e) => {
                                    let sampleListCopy = [...project.baubles];
    
                                    sampleListCopy[sampleIndex].vendorPartName = e.target.value;

                                    if(bauble.type == "Sample") {

                                    }

                                    onUpdate(sampleListCopy);
                                }}
                            />
                        </Grid>

                        <Grid item md={11} xs={9}>
                            <TextField
                                aria-label={"sample-name" + sampleIndex}
                                label={"Fishbowl Name"}
                                variant="outlined"
                                helperText={"Check the help if this is new. SAMPLE + VENDOR + NAME"}
                                fullWidth={true}
                                placeholder="SAMPLE-"
                                value={bauble.name}
                                onChange={(e) => {
                                    let sampleListCopy = [...project.baubles];
    
                                    sampleListCopy[sampleIndex].name = e.target.value;
                                    onUpdate(sampleListCopy);
                                }}
                            />
                        </Grid>
                        <Grid item md={1} xs={3} style={{paddingLeft: 8}}>
                            <Button
                                onClick={() => setShowNameHelp(true)}
                                variant="contained"
                                color="primary"
                                className="btn"
                                style={{height: 55}}
                            >
                                <HelpOutline 
                                    style={{color: "white"}}
                                /> HELP
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                aria-label={"notes-" + sampleIndex}
                                label={"Notes"}
                                variant="outlined"
                                fullWidth={true}
                                multiline={true}
                                placeholder="Just a basic 'what is this thing'"
                                value={bauble.description}
                                onChange={(e) => {
                                    let sampleListCopy = [...project.baubles];
    
                                    sampleListCopy[sampleIndex].description = e.target.value;
                                    
                                    onUpdate(sampleListCopy);
                                }}
                                className="order-item-notes"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {(bauble.id == 0 || hasChanges[sampleIndex]) && !newProjectForm && <Button
                                variant="contained"
                                color="primary"
                                style={{height: "100%"}}
                                onClick={() => {
                                    setHasChanges({...hasChanges, [sampleIndex]: false});
                                    saveBauble(project.baubles[sampleIndex]);
                                }}
                            >
                                Save Changes
                            </Button>}
                        </Grid>
                        <Grid item xs={8}>  
                            {!bauble.mainSample ? <div style={{width: "100%"}}>
                                {_buildDeleteButton(bauble)}

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{height: "100%", textAlign: "right", float: "right", marginRight: 8}}
                                    onClick={() => {
                                        let previousValue = bauble.mainSample;
                                        if(previousValue) {
                                            // showErrorText("Each project can have one and only one Main Sample. To choose a new main sample, go to that sample and select it.")
                                        } else {
                                            let sampleListCopy = [...project.baubles];
                                            
                                            for (let slci = 0; slci < sampleListCopy.length; slci++) {
                                                sampleListCopy[slci].mainSample = (slci == sampleIndex);
                                            }

                                            onUpdate(sampleListCopy);
                                        }
                                    }}
                                    disabled={bauble.mainSample}
                                >
                                    Make Main Sample <i className="fas fa-star grey" style={{fontSize: "1.2em", paddingLeft: 8}}></i>
                                </Button>
                            </div> : <h3 style={{color: "grey", textAlign: "right"}}>Main Sample</h3>}
                        </Grid>
                    </Grid>
                </Paper>
            </ListItem>
        );
    }

  const nameHelpDialog = () => {
        return (
            <Dialog
                open={showNameHelp}
                onClose={() => setShowNameHelp(false)}
            >
                <DialogTitle>
                    What is a name?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        - If it's a sample, start the name with "SAMPLE-". <br />
                        - If it's anything else, it should end with "-PART".<br />
                        - Add the two letter factory code if you can. I don't know who came up with the 2-letter factory codes, but they seem to make sense.<br />
                        - The rest is just whatever the factory calls it. Trying to keep it close to that so when Tech references it down the road, the factories are on the same page.<br />
                        <br /><br />
                        e.g. <br />
                        A new hotbox sample from Daisy might be called:<br />
                        "SAMPLE-DL-WS2004 RGBALC"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowNameHelp(false)} autoFocus>
                        K
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Grid item xs={12} container>
            {nameHelpDialog()}
            {newProjectForm ? <h3>What samples/baubles <i className="far fa-wand-sparkles"></i> are associated with this project? (samples, firmware flashers, parts, accessories)</h3> : null}
            <Grid item xs={12}>
                <List>
                    {sampleElementList}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={() => {
                        if(canAddAnother) {
                            let sampleListCopy = [...project.baubles];

                            sampleListCopy.push(emptyPDProjectSample());
                            onUpdate(sampleListCopy);
                        }
                    }}
                    disabled={!canAddAnother}
                    variant="contained"
                    color="primary"
                    className="btn"
                >
                    <Add /> Add Another Sample
                </Button>
            </Grid>
        </Grid>
    )
};

export default PDProjectSampleList;

