import React, { useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import Loading from "components/utils/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getSO } from "actions/getSO";
// import { getCustomers } from "actions/getCustomers";
import { UserContext } from "context/User";
import { useParams } from "react-router-dom";
import { RootState } from "reducers";
import { List, WindowScroller } from "react-virtualized";
import OrderSearchResult from "./OrderSearchResult";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeAxiosCall } from "utils";
import { FishbowlSalesOrderInterface } from "interfaces/FishbowlSalesOrder";
import { CompanyInterface } from "interfaces/Company";
import Autocomplete from "@material-ui/lab/Autocomplete";

const OrderHistory = () => {
  const dispatch = useDispatch();

  const [input, setInput] = useState("");

  const so = useSelector((state: RootState) => state.so);
  // const customers = useSelector((state: RootState) => state.customer);
  const { currentUser, internalUser } = useContext(UserContext);
  const [orders, setOrders] = useState<FishbowlSalesOrderInterface[]>([]);
  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [orderHelpRequested, setOrderHelpRequested] = useState<number>(0);
  const [orderStatusFilters, setOrderStatusFilters] = useState<string[]>([]);
  const [filteredList, setFilteredList] = useState<FishbowlSalesOrderInterface[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [sortMethods, setSortMethods] = useState<string[]>([]);

  const windowScrollerRef = React.useRef<WindowScroller>();

  let { accountType } = useParams();

  const [selectedCustomer, setSelectedCustomer] = useState<any>({id: null, name: "All"});
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 800,
        width: 250,
      },
    },
  };

  const sortOptions = [
    {
      label: "Order Number (A - Z)",
      value: 'num',
      order: 'asc',
    },
    {
      label: "Order Number (Z - A)",
      value: 'num',
      order: 'desc',
    },
    {
      label: "Newest",
      value: 'dateCreated',
      order: 'desc',
    },
    {
      label: "Oldest",
      value: 'dateCreated',
      order: 'asc',
    },
  ];

  const orderStatusOptions = [
    "Cancelled",
    "Closed Short",
    "Estimate",
    "Expired",
    "Fulfilled",
    "In Progress",
    "Issued",
    "Voided",
  ];

  React.useEffect(() => {
    if(firstLoad) {
      if (currentUser && internalUser) {
        // dispatch(getCustomers());
        fetchAllCompanies();
        dispatch(getSO(currentUser.company.fishbowlCustomerId, "internal"));
        getOrderHelpCount();
        setFirstLoad(false);
      } else if (currentUser.company.companyType === "Rep" || accountType === "Rep" ) {
        dispatch(getSO(currentUser.company.fishbowlCustomerId, "rep"));
        setCompanies([...currentUser.company.resellers, currentUser.company]);
        
        getOrderHelpCount();
      } else {
        dispatch(getSO(currentUser.company.fishbowlCustomerId, "reseller"));
      }
    }
  }, [currentUser]);

  React.useEffect(() => {
    if(so.orders) {
      setOrders(so.orders);

      if(currentUser && currentUser.company && currentUser.company.companyType == "Internal") {
        if(so.orders.length > 10000) {
          setLoading(false);
        }
      } else if(currentUser && currentUser.company && currentUser.company.companyType == "Rep") {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [so, currentUser, internalUser]);

  React.useEffect(() => {
    if (orders) {
      filterOrders();
    }
  }, [orders, orderStatusFilters, selectedCustomer]);

  React.useEffect(() => {
    if (companies) {
      
      const lastCompanySearched = localStorage.getItem("lastCompanyOrders");
      if(lastCompanySearched) {
        let lastCompany = companies.find((c) => c.name == lastCompanySearched);
        if(lastCompany) selectCustomer(lastCompany);
      }
    }
  }, [companies]);

  React.useLayoutEffect(() => {
    // Adjust the scroll view when filters are opened or closed
    if (windowScrollerRef && windowScrollerRef.current) {
      windowScrollerRef.current.updatePosition();
    }
  }, [showFilters]);


  const fetchAllCompanies = () => {
    makeAxiosCall(
      "get",
      "companies/simple"
    ).then(async res => {
      const companyList = [];

      for (let ci = 0; ci < res.data.length; ci++) {
        const company = res.data[ci];

        companyList.push({id: company.fishbowl_id, name: company.name});
      }
      companyList.sort((a, b) => a.name.localeCompare(b.name));
      setCompanies(companyList);
    })
  }

  // const fetchSO = (id) => {
  //   dispatch(getSO(id, ((currentUser && internalUser) ? "internal" : "reseller")));
  // };

  const getOrderHelpCount = async () => {
    const res = await makeAxiosCall(
      "get",
      "orders-requested-help",
    );
    if (res.data) {
      const { data } = res;
      setOrderHelpRequested(data.length);
    } else {
    }
  };

  const selectCustomer = (v: CompanyInterface) => {
    setSelectedCustomer(v);

    if (v && v.name) {
      localStorage.setItem("lastCompanyOrders", v.name);
    } else {
      localStorage.removeItem("lastCompanyOrders");
    }
  };

  const filterOrders = () => {
    // Only apply filters once one is selected
    const applyFilters = orderStatusFilters.length > 0 || selectedCustomer;

    if (applyFilters) {
      let results: FishbowlSalesOrderInterface[] = [];

      orders.forEach((order: FishbowlSalesOrderInterface) => {
        if ((orderStatusFilters.length == 0 || orderStatusFilters.some((status) => status == order.status)) &&
        (!selectedCustomer || !selectedCustomer.id || order.customerName == selectedCustomer.name)
        ) {
          results.push(order);
        }
      });
      setFilteredList(results);
    } else {
      setFilteredList(orders);
    }
  }

  const sortOrders = (searchResults: FishbowlSalesOrderInterface[]) => {
    let returnable: FishbowlSalesOrderInterface[];

    if (sortMethods.length > 0) {
      returnable = _.orderBy(
        searchResults, 
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).value),
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
      );
    } else {
      returnable = _.orderBy(searchResults, (order) => order.dateCreated, ['desc']);
    }

    return returnable;
  }

  const searchResults = React.useMemo(() => {
    const searchInput = input.toLowerCase();

    // eslint-disable-next-line
    let results = _.filter(filteredList, (o: FishbowlSalesOrderInterface) => {
      if (o.num !== null && o.customerName !== null) {
        let orderNum = "",
          customerName = "",
          customerPO = "";
        if (o.num !== undefined) {
          orderNum = o.num.toLowerCase();
        }
        if (o.customerName !== undefined) {
          customerName = o.customerName.toLowerCase();
        }
        if (o.customerPO !== undefined) {
          customerPO = o.customerPO.toLowerCase();
        }

        const arr1 = [orderNum, customerName, customerPO];
        const allTerms = arr1.join();

        return _.includes(allTerms, searchInput);
      }
    });

    return sortOrders(results);
  }, [input, orders, filteredList, sortMethods]);

  const renderSortOptions = () => {
    return (
      <FormControl style={{width: "100%"}}>
        <Select
          key="sortMethods"
          multiple
          value={sortMethods}
          renderValue={(_) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {sortMethods.map((method) => (
                <Chip key={method} label={method} style={{marginRight: 5}} />
              ))}
            </Box>
          )}
          onChange={(e: React.ChangeEvent<{
            name?: string;
            value: string[];
          }>) => {
            setSortMethods(e.target.value);
          }}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          {sortOptions.map(
            (option) => {
              let index = sortMethods.findIndex((n) => n == option.label);
              return (
                <MenuItem
                  key={option.label}
                  value={option.label}
                >
                  <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                  {option.label}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    );
  }

  const renderOrderStatusFilterOption = (option: string) => {
    return (
      <Grid key={option} item xs={12} md={4}>
        <Tooltip title={option} placement="top" arrow>
          <FormControlLabel
            control={
              <Checkbox 
              checked={orderStatusFilters.some((status) => status === option)}
              onChange={(e) => {
                if (orderStatusFilters.some((type) => type === option)) {
                  setOrderStatusFilters(orderStatusFilters.filter((type) => type !== option));
                } else {
                  setOrderStatusFilters([...orderStatusFilters, option]);
                }
              }}
              color="primary"
              size="small"
              />
            }
            label={option}
            classes={{label: "search-filter-option"}}
          />
        </Tooltip>
      </Grid>
    );
  }

  const renderOrderStatusFilters = () => {
    return orderStatusOptions.map((type) => {
      return renderOrderStatusFilterOption(type);
    });
  }

  const renderRow = ({ index, key, style }, phoneMode: boolean, companyMember: boolean) => {
    const order = searchResults[index];

    return (
      <div style={style} key={key} >
        <div style={{padding: 5}}>
          <OrderSearchResult order={order} phoneMode={phoneMode} companyMember={companyMember} />
        </div>
      </div>
    );
  }

  const renderPlaceholder = () => {
    return (
      <h4 style={{ textAlign: "center", fontSize: 23, fontWeight: "normal" }}>
        No orders found with that criteria
      </h4>
    );
  }

  return (
    <div className="admin view">
      <div className="grid-search">
        <Grid container>
          <Grid item xs={12}>
            <h1 className="reveal-text">Order History</h1>
          </Grid>
          <Grid item xs={12} md={4} lg={3} style={{ padding: 10 }}>
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              className="btn"
              component={Link}
              to="/pending-orders"
            >
              {currentUser && internalUser ? "Portal Orders" : "Pending and Saved Orders"}
            </Button>
          </Grid>
          {orderHelpRequested > 0 && (
            <Grid item xs={12} md={4} lg={3} style={{ padding: 10 }}>
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                className="btn"
                component={Link}
                to="/pending-orders?help=true"
              >
                Order Help Requested ({orderHelpRequested})
              </Button>
          </Grid>
          )}
        </Grid>
        <div className="search-field">
          <TextField
            className="prod-search"
            label="Search for previous orders"
            margin="normal"
            variant="outlined"
            onChange={(e) => setInput(e.target.value)}
            value={input}
            style={{marginRight: 0, width: "100%"}}
          />
        </div>
        <div className="inventory-search">
          {(currentUser && internalUser)  || (currentUser.company && currentUser.company.resellers && currentUser.company.resellers.length > 0) ? (
            // List of resellers
              <Autocomplete
                id="company-select"
                options={companies}
                value={selectedCustomer}
                getOptionLabel={(customer) => customer ? customer.name : "All"}
                onChange={(event: any, newValue: any) => {
                  if(newValue) {
                    let selectedCompany = companies.find((c) => c.name === newValue.name);
                    selectCustomer(selectedCompany);
                  } else {
                    selectCustomer(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select Company" />}
              />
          ): <></>}
        </div>

        <Grid container className="results">
          <Grid container className="container-spacing">
            <Grid item container alignItems="center" xs={12}>
              <Grid item xs={9}><h3>Filter and Sort {`(${searchResults ? searchResults.length : ""})`}</h3></Grid>
              <Grid item xs={3}>
                <Button
                  variant={showFilters ? "contained" : "outlined"}
                  color={showFilters ? "primary" : "secondary"}
                  onClick={() => setShowFilters(!showFilters)}
                  fullWidth
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
            </Grid>
            {showFilters && (
              <>
                <Grid item container xs={9}>
                  <Grid item container alignContent="flex-start" xs={12}>
                    <Grid item xs={12}>Order Status</Grid>
                    {renderOrderStatusFilters()}
                  </Grid>
                </Grid>
                <Grid item alignContent="flex-start" container xs={3}>
                  <Grid item xs={12}>Sort By</Grid>
                  <Grid item xs={12}>
                    {renderSortOptions()}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        
          {loading ? 
            (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) : (
            <WindowScroller ref={windowScrollerRef} >
              {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                const phoneMode = width < 500;

                const notCompanyMember = (currentUser && currentUser.company && (
                  (currentUser.company.companyType === "Rep" || accountType === "Rep") || 
                  internalUser
                ));

                // In mobile mode
                // Company members see the PO #, Date, status
                // Admins see Order #, PO #, Customer Name, and Status

                return (
                  <Grid container item xs={12}>
                    <Grid container item xs={12} className="center-text grid-search-headers">
                      {(!phoneMode || notCompanyMember) && <Grid md={1} xs={2} item className="result-name">
                        Order #
                      </Grid>}
                      
                      <Grid item xs={3} md={1}>
                        PO
                      </Grid>
                      
                      {(!phoneMode || !notCompanyMember) && <Grid item md={2} xs={6}>
                        Date
                      </Grid>}
                      
                      {(!phoneMode || notCompanyMember) && <Grid item xs={4} className="result-name">
                        Customer
                      </Grid>}

                      <Grid item xs={3} md={2}>
                        Status
                      </Grid>

                      {!phoneMode && <Grid item xs={2}>
                        View
                      </Grid>}
                    </Grid>
                    <List
                      autoHeight
                      width={width}
                      height={height}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      rowHeight={80}
                      rowRenderer={(props) => renderRow(props, phoneMode, !notCompanyMember)}
                      noRowsRenderer={renderPlaceholder}
                      rowCount={searchResults.length}
                      overscanRowCount={3}
                      />
                  </Grid>
                )
              }}
            </WindowScroller>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default OrderHistory;
