import React from "react";
import Order from "components/Checkout/Order";
import PaymentBook from "components/Account/PaymentBook";
import AddressBook from "components/Account/Address/AddressBook";

import { makeStyles } from "@material-ui/core/styles";
import { CartContext } from "context/Cart";
import {
    Button,
    Typography,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Snackbar,
    Grid,
    FormControlLabel,
    RadioGroup,
    Radio,
    Switch,
    InputLabel,
    Paper,
    CircularProgress,
} from "@material-ui/core";
import { SideDrawerTab, ViewContext } from "context/View";
import { UserContext } from "context/User";

import ModalBasic from "components/utils/ModalBasic";
import SuccessAni from "components/utils/SuccessAni";
import { useDispatch } from "react-redux";
// import { fetchRepFirmResellers } from "actions/getCustomers";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Alert } from "@material-ui/lab";
import OrderOnBehalfOf from "./OrderOnBehalfOf";
import "scss/components/Checkout/Checkout.scss";
import { generateUID, makeAxiosCall } from "utils";
import { buildCompanyInfo } from "interfaces/Company";
import { buildShippingOptions, ShippingCarrierInterface } from "interfaces/ShippingPreference";
import { DropzoneArea } from "material-ui-dropzone";
import { storage } from "base";
import { useNavigate } from "react-router-dom";
import PendingOrderSummary from "components/OrderHistory/PendingOrderSummary";
import { AddressInterface } from "interfaces/Address";
import ContactSelect from "components/Account/Contact/ContactSelect";
import ContactEditForm from "components/MyCompany/ContactEditForm";
import { ContactInterface } from "interfaces/Contact";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ["Cart", "PO Details", "Shipping", "Billing", "Summary"];
}

const Checkout = () => {
    const dispatch = useDispatch();
    const { saveCurrentPanel } = React.useContext(ViewContext);
    const [open, setOpen] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [showShippingOptions, setShowShippingOptions] = React.useState(false);
    const [returningDemo, setReturningDemo] = React.useState(false);
    const [showShippingContactForm, setShowShippingContactForm] = React.useState(false);
    const [showThirdPartyCarriers, setShowThirdPartyCarriers] = React.useState(false);
    const [shippingOptions, setShippingOptions] = React.useState<ShippingCarrierInterface[]>();
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const classes = useStyles();
    const {
        currentOrder,
        saveCurrentOrder,
        setOrderAs,
        saveCurrentActiveStep,
        currentActiveStep,
        orderingOnBehalfOf,
        clearCart
    } = React.useContext(CartContext);
    const steps = getSteps();
    const { fetchCurrentUser, currentUser } = React.useContext(UserContext);

    const [error, setError] = React.useState<string>("");


    const navigate = useNavigate();

    React.useEffect(() => {
        if (orderingOnBehalfOf && orderingOnBehalfOf.id != currentUser.company.id) {
            makeAxiosCall(
                "get",
                "company/" + orderingOnBehalfOf.id
            ).then(async res => {
                const company = await buildCompanyInfo(res.data);
                setOrderAs(company);
            });
        }

    }, [refresh]);


    // React.useEffect(() => {
    //   if (currentUser.company.companyType === "Rep") {
    //     dispatch(fetchRepFirmResellers(currentUser.company.fishbowlCustomerId));
    //   }

    // }, [currentUser]);



    React.useEffect(() => {
        if (!currentOrder) return;

        if (shippingOptions && shippingOptions.length && currentOrder.shippingPreference && currentOrder.shippingPreference.carrier && currentOrder.shippingPreference.carrier.id) {
            setShowShippingOptions(true);
            if (currentOrder.shippingPreference.carrier.thirdParty) {
                setShowThirdPartyCarriers(true);
            }
        }
    }, [currentOrder, shippingOptions]);


    React.useEffect(() => {
        makeAxiosCall(
            "get",
            "shipping-options"
        ).catch((error) => {
            console.log(error);
        }).then((result) => {
            setShippingOptions(buildShippingOptions(result.data));
        });
    }, []);

    const uploadPOFile = (files: File[]) => {
        if (files.length > 0) {
            for (let fi = 0; fi < files.length; fi++) {
                const file = files[fi];
                if (file.name.substring(0, 24) != "portal_company_documents") {
                    const uid = generateUID();
                    storage.ref(`/portal_company_documents/${currentUser.company.id}/customer_pos/${uid + file.name}`).put(file)
                        .then(async (storageData) => {
                            saveCurrentOrder({ ...currentOrder, customerPOURL: await storageData.ref.getDownloadURL() });
                        }).catch((err) => {
                            console.log(err);
                        });
                }
            }
        }
    };

    const cancelOrder = () => {
        clearCart();
        saveCurrentPanel(SideDrawerTab.home);
        navigate("/");
    };

    const updateOrder = async () => {
        if (currentOrder && currentOrder.id) {
            setSaving(true);
            // Only update it if it's saved already
            const result = await makeAxiosCall(
                "post",
                `order/${currentOrder.id}`,
                {
                    id: currentOrder.id,
                    ordered_by_id: currentUser.uid,
                    company_id: orderingOnBehalfOf ? orderingOnBehalfOf.id : currentUser.company.id,
                    billing_address_id: currentOrder.billingAddress.id,
                    shipping_address_id: currentOrder.shippingAddress.id,
                    billing_contact_id: currentOrder.billingContact ? currentOrder.billingContact.id : null,
                    shipping_contact_id: currentOrder.shippingContact.id,
                    customer_po_num: currentOrder.customerPONum,
                    customer_po_upload_url: currentOrder.customerPOURL,
                    authorize_payment_profile_id: currentOrder.authorizePaymentProfileId,
                    drop_ship: currentOrder.isDropShip,
                    preferred_packing_list_upload_url: currentOrder.preferredPackingListURL,
                    order_status_id: currentOrder.orderStatus.id,
                    shipping_preference: currentOrder.shippingPreference ? {
                        id: currentOrder.shippingPreference.id,
                        account_number: currentOrder.shippingPreference.accountNumber,
                        carrier: currentOrder.shippingPreference.carrier,
                        service: currentOrder.shippingPreference.service
                    } : null,
                    order_items: currentOrder.orderItems
                }
            ).catch((error) => {
                console.error(error);
                return false;
            }).then((r) => {
                if (r.data.error) {
                    console.error(r.data.error);
                    return false;
                } else {
                    return true;
                }

            });
            setSaving(false);
            return result;
        } else {
            return true;
        }
    };

    const uploadPreferredPackingList = (files: File[]) => {
        if (files.length > 0) {

            for (let fi = 0; fi < files.length; fi++) {
                const file = files[fi];
                if (file.name.substring(0, 24) != "portal_company_documents") {
                    const uid = generateUID();
                    storage.ref(`/portal_company_documents/${currentUser.company.id}/customer_packing_lists/${uid + file.name}`).put(file)
                        .then(async (storageData) => {
                            saveCurrentOrder({ ...currentOrder, preferredPackingListURL: await storageData.ref.getDownloadURL() });
                        }).catch((err) => {
                            console.log(err);
                        });
                }
            }
        }
    };

    function buildPODetailsStep() {
        return <React.Fragment>
            {orderingOnBehalfOf && currentUser.company.id != orderingOnBehalfOf.id && (
                <div>
                    <h3 style={{ display: "inline" }}>Ordering on behalf of {orderingOnBehalfOf.name}</h3>
                    <Button onClick={handleBack} className={classes.button}>
                        <i className={"fas fa-edit active"}></i>
                    </Button>
                </div>
            )}

            <FormControl
                style={{ marginTop: 40, width: "100%", padding: 20 }}
                variant="outlined"
                className="account-info"
            >
                <h3 className="center">Your PO Number</h3>
                <TextField
                    style={{ width: "100%" }}
                    label="PO Number"
                    margin="normal"
                    value={currentOrder.customerPONum ? currentOrder.customerPONum : ""}
                    variant="outlined"
                    onChange={(e) => saveCurrentOrder({ ...currentOrder, customerPONum: e.target.value })}
                    placeholder={"Optional"}
                />
            </FormControl>

            <div className="po-upload-container">

                <h3>Upload PO File<span style={{ fontSize: ".7em" }}> - {orderingOnBehalfOf && orderingOnBehalfOf.id !== currentUser.company.id ? "Required for ordering on behalf of other companies" : "Optional"}</span></h3>
                <DropzoneArea
                    maxFileSize={100000000}
                    filesLimit={1}
                    dropzoneClass="file-uploader"
                    acceptedFiles={["image/*", "application/pdf"]}
                    onChange={uploadPOFile}
                    // useChipsForPreview={true}
                    // previewText={poFileUrl ? poFileUrl.substring(poFileUrl.lastIndexOf("%")) : ""}
                    initialFiles={currentOrder.customerPOURL ? [currentOrder.customerPOURL] : []}
                    onDropRejected={(file) => console.log(file)}
                />
            </div>

            {/* <div className="account-info">
        <h3 className="center">Order Notes</h3>
        <TextField
          variant="filled"
          style={{
            width: "100%",
            borderRadius: 20,
            padding: 10,
          }}
          value={currentOrderNote ?? currentOrder.notes}
          onChange={(e) => saveCurrentOrderNote(e.target.value)}
          multiline
        />
      </div> */}

        </React.Fragment>;
    }

    function buildShippingStep() {
        return (
            <React.Fragment>
                {orderingOnBehalfOf && currentUser.company.id != orderingOnBehalfOf.id && (

                    <div>
                        <h3 style={{ display: "inline" }}>Ordering on behalf of {orderingOnBehalfOf.name}</h3>

                        <Button onClick={handleReset} className={classes.button}>
                            <i className={"fas fa-edit active"}></i>
                        </Button>
                    </div>
                )}
                {/* 
        <AddressBook
          currentReseller={orderingOnBehalfOf}
          key={2}
          title="Select Billing Address"
          type="Billing"
          onSelect={saveBillingAddress}
          currentSelection={billingAddress}
        /> */}

                <FormControl
                    style={{ marginTop: 40, width: "100%", padding: 20 }}
                    variant="outlined"
                    className="account-info"
                >
                    <h3>Is this a Drop Shipment?</h3>
                    <RadioGroup onChange={(e) => saveCurrentOrder({ ...currentOrder, isDropShip: !currentOrder.isDropShip })}>
                        <FormControlLabel control={<Radio value={true} checked={currentOrder.isDropShip === true} />} label="Yes" />
                        <FormControlLabel control={<Radio value={false} checked={currentOrder.isDropShip !== true} />} label="No" />
                    </RadioGroup>
                    {
                        currentOrder.isDropShip ?
                            <>
                                <h3>Upload preferred packing list<span style={{ fontSize: ".7em" }}> - optional</span></h3>
                                <DropzoneArea
                                    maxFileSize={100000000}
                                    filesLimit={1}
                                    dropzoneClass="file-uploader"
                                    acceptedFiles={["image/*", "application/pdf"]}
                                    onChange={uploadPreferredPackingList}
                                    // useChipsForPreview={true}
                                    // previewText={packingListUrl ? packingListUrl.substring(packingListUrl.lastIndexOf("%")) : ""}
                                    initialFiles={currentOrder.preferredPackingListURL ? [currentOrder.preferredPackingListURL] : []}
                                    onDropRejected={(file) => console.log(file)}
                                />
                            </> : null
                    }
                </FormControl>

                <AddressBook
                    currentReseller={orderingOnBehalfOf}
                    key={1}
                    title="Select Shipping Address"
                    type={currentOrder.isDropShip ? "Drop Ship" : "Shipping"}
                    onSelect={(address: AddressInterface) => {
                        const tempOrder = { ...currentOrder, shippingAddress: address };
                        if (address.additionalShippingDetails && address.additionalShippingDetails.contact) {
                            tempOrder.shippingContact = address.additionalShippingDetails.contact;
                        }
                        saveCurrentOrder(tempOrder);
                    }}
                    currentSelection={currentOrder.shippingAddress}
                    refresh={() => setRefresh(!refresh)}
                />


                {currentOrder.shippingAddress.additionalShippingDetails ?
                    <Grid container item xs={12}>
                        <Grid item container md={4} xs={12}>
                            <Paper className={"bordered-container"}>
                                <Grid item xs={12}>
                                    <h3 className="center">Loading Dock</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.hasTruckHeightLoadingDock ? "Dock is truck height" :
                                        (currentOrder.shippingAddress.additionalShippingDetails.liftGateRequired ? "Dock requires a lift gate" :
                                            "Dock is not truck height, but does not require a lift gate")}
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.deliveryHoursMorning && currentOrder.shippingAddress.additionalShippingDetails.deliveryHoursAfternoon ? "Delivery Hours: 8AM - 4PM" :
                                        (currentOrder.shippingAddress.additionalShippingDetails.deliveryHoursMorning ? "Delivery Hours: 8AM - Noon" :
                                            (currentOrder.shippingAddress.additionalShippingDetails.deliveryHoursAfternoon ? "Delivery Hours: Noon - 4PM" :
                                                ("Delivery hours not set")))}
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item container md={4} xs={12}>
                            <Paper className={"bordered-container"}>
                                <Grid item xs={12}>
                                    <h3 className="center">Location Details</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.isCommercialLocation ? "This is a commercial location" : "This is a residential location"}
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.isJobsiteLocation ? "This is a job site" : "This is NOT a job site"}
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.locationNotes}
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item container md={4} xs={12}>
                            <Paper className={"bordered-container"}>
                                <Grid item xs={12}>
                                    <h3 className="center">Recieving Contact</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.contact.firstName + " " + currentOrder.shippingAddress.additionalShippingDetails.contact.lastName}
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.contact.phoneNumber}
                                </Grid>
                                <Grid item xs={12}>
                                    {currentOrder.shippingAddress.additionalShippingDetails.contact.email}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    : null}


                <Grid item xs={12} style={{ padding: 8, marginTop: 32 }}>
                    <Grid container spacing={2} className="new-cc-form">
                        <Grid item xs={12}>
                            <h3>Shipping Contact</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <ContactSelect
                                company={orderingOnBehalfOf ? orderingOnBehalfOf : currentUser.company}
                                label="Shipping Contact"
                                setNewFormActive={(open) => {
                                    setShowShippingContactForm(open);
                                }}
                                currentSelection={currentOrder.shippingContact}
                                onSelect={(contact: ContactInterface) => saveCurrentOrder({ ...currentOrder, shippingContact: contact })}
                            />

                            {showShippingContactForm ? <ContactEditForm
                                createCallback={(contact: ContactInterface, newId: number) => {
                                    contact.id = newId;
                                    (orderingOnBehalfOf ? orderingOnBehalfOf : currentUser.company).contacts.push(contact);
                                    setShowShippingContactForm(false);
                                    fetchCurrentUser();
                                }}
                                companyId={(orderingOnBehalfOf ? orderingOnBehalfOf : currentUser.company).id}
                            /> : null}
                        </Grid>
                    </Grid>
                </Grid>

                <FormControl
                    style={{ marginTop: 40, width: "100%", padding: 20 }}
                    variant="outlined"
                    className="account-info"
                >
                    <Grid container item xs={12}>
                        <Grid item>
                            <h3 className="center">Specify Carrier Options?</h3>
                        </Grid>
                        <Grid item style={{ marginTop: 8 }}>
                            <Switch
                                checked={showShippingOptions}
                                onChange={() => {
                                    setShowShippingOptions(!showShippingOptions);
                                    if (showShippingOptions) {
                                        // Take prefs off
                                        saveCurrentOrder({ ...currentOrder, shippingPreference: null });
                                    } else {
                                        // Default start to ups
                                        const ups = shippingOptions.find((c) => !c.thirdParty);
                                        saveCurrentOrder({
                                            ...currentOrder, shippingPreference: {
                                                ...currentOrder.shippingPreference,
                                                accountNumber: "",
                                                carrier: ups,
                                                service: ups.services[0]
                                            }
                                        });
                                    }

                                }}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!showShippingOptions && (<p style={{ textAlign: "center" }}>By default, Blizzard Lighting ships orders via UPS Ground.</p>)}
                            <p style={{ textAlign: "center", paddingBottom: 18 }}>
                                Specifying carrier options may result in additional shipping charges, regardless of whether or not the order qualifies for free shipping.
                            </p>
                        </Grid>
                    </Grid>

                    {showShippingOptions && shippingOptions && shippingOptions.length ? <Grid container spacing={2}>
                        <Grid container item xs={12}>
                            <Grid container item xs={12}>
                                <Grid item>
                                    <h3 className="center">3rd Party Carrier? <span style={{ fontSize: 12, fontWeight: "lighter" }}>(Supply an Account Number)</span></h3>
                                </Grid>
                                <Grid item style={{ marginTop: 8 }}>
                                    <Switch
                                        checked={showThirdPartyCarriers}
                                        onChange={() => {
                                            if (showThirdPartyCarriers) {
                                                // We're hiding the 3rd party options, default to ups
                                                const ups = shippingOptions.find((c) => !c.thirdParty);
                                                saveCurrentOrder({
                                                    ...currentOrder, shippingPreference: {
                                                        ...currentOrder.shippingPreference,
                                                        accountNumber: "",
                                                        carrier: ups,
                                                        service: ups.services[0]
                                                    }
                                                });
                                            } else {
                                                // We're showing the 3rd party options, default to ups
                                                saveCurrentOrder({
                                                    ...currentOrder, shippingPreference: {
                                                        ...currentOrder.shippingPreference,
                                                        accountNumber: "",
                                                        carrier: null,
                                                        service: null
                                                    }
                                                });
                                            }
                                            setShowThirdPartyCarriers(!showThirdPartyCarriers);
                                        }}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>
                            </Grid>
                            <FormControl fullWidth>
                                <InputLabel id="shipping-carrier-select">Carrier</InputLabel>
                                <Select
                                    onChange={(e: any) => {

                                        if (e.target.value) {
                                            const selectedOption = shippingOptions.find((o) => o.id == e.target.value);
                                            saveCurrentOrder({
                                                ...currentOrder,
                                                shippingPreference: {
                                                    ...currentOrder.shippingPreference,
                                                    carrier: selectedOption,
                                                    service: null
                                                }
                                            });
                                        }
                                    }}
                                    labelId="ship-pref-car-select-label"
                                    id="ship-pref-car-select"
                                    style={{ minWidth: 400 }}
                                    value={currentOrder.shippingPreference.carrier ? currentOrder.shippingPreference.carrier.id : 0}
                                >
                                    {buildShippingCarrierSelections()}
                                </Select>
                            </FormControl>
                        </Grid>
                        {currentOrder.shippingPreference.carrier && currentOrder.shippingPreference.carrier.services && currentOrder.shippingPreference.carrier.services.length ?
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="shipping-service-select">Service</InputLabel>
                                    <Select
                                        onChange={(e: any) => {
                                            if (e.target.value) {
                                                const currentCarrierSelected = shippingOptions.find((o) => o.id == currentOrder.shippingPreference.carrier.id);
                                                saveCurrentOrder({
                                                    ...currentOrder, shippingPreference: {
                                                        ...currentOrder.shippingPreference,
                                                        service: currentCarrierSelected.services.find((s) => s.id == e.target.value)
                                                    }
                                                });
                                            }
                                        }}
                                        labelId="ship-pref-serv-select-label"
                                        id="ship-pref-serv-select"
                                        style={{ minWidth: 400 }}
                                        value={currentOrder.shippingPreference.service ? currentOrder.shippingPreference.service.id : 0}
                                    >
                                        {buildShippingServiceSelections()}
                                    </Select>
                                </FormControl>
                            </Grid> : null}


                        {showThirdPartyCarriers ?
                            <Grid item xs={12}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label="Shipping Account Number"
                                    margin="normal"
                                    value={currentOrder.shippingPreference.accountNumber}
                                    variant="outlined"
                                    onChange={(e) => {
                                        saveCurrentOrder({
                                            ...currentOrder, shippingPreference: {
                                                ...currentOrder.shippingPreference,
                                                accountNumber: e.target.value
                                            }
                                        });
                                    }}
                                    inputProps={{ maxLength: 40 }}
                                    placeholder={"Optional"}
                                />
                            </Grid> : null
                        }

                    </Grid> : null}
                </FormControl>

                {buildKeepingDemoOption()}

            </React.Fragment>
        );
    }

    function buildKeepingDemoOption() {
        if(!currentOrder.demoOrder) return <></>;
        
        return (
            <FormControl
                style={{ marginTop: 40, width: "100%", padding: 20 }}
                variant="outlined"
                className="account-info"
            >
                <Grid container item xs={12}>
                    <Grid item>
                        <h3 className="center">Returning This Demo?</h3>
                    </Grid>
                    <Grid item style={{ marginTop: 8 }}>
                        <Switch
                            checked={returningDemo}
                            onChange={() => {
                                saveCurrentOrder({ ...currentOrder, returningDemo: !returningDemo });
                                setReturningDemo(!returningDemo);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {!returningDemo ? (<p style={{ textAlign: "center" }}>Free shipping, unless returned by RMA prior to 90 day expiration</p>) :
                            (<ul>
                                <h4>Heads up:</h4>
                                <li>If purchasing the demo gear, Blizzard pays for shipping.</li>
                                <li>If returning the demo gear, you pay for shipping both ways.</li>
                                <li>If the demo is being returned, the product needs to be returned in like-new condition with all accessories and the original packaging the rep received the demo in.</li>
                                <li>If a product is returned in anything other than like-new condition, you are subject to a 25% restocking fee.</li>
                                <li>Once approved for return, all demo returns need to follow the same process as any other return, i.e., follow Blizzard’s standard RMA process.</li>
                                <li>Any demo invoices that haven’t been settled prior to the expiration of the payment terms will be deducted from the your next commission payment.</li>
                            </ul>)}
                    </Grid>
                </Grid>
            </FormControl>
        );
    }

    function buildSummaryStep() {
        return (
            <PendingOrderSummary />
        );
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <Order />;
            case 1:
                return buildPODetailsStep();
            case 2:
                return buildShippingStep();
            case 3:
                return <PaymentBook cart title="Payment" company={orderingOnBehalfOf} />;
            case 4:
                return buildSummaryStep();
            default:
                return null;
        }
    }

    const buildShippingCarrierSelections = () => {
        const inHouse: ShippingCarrierInterface[] = [];
        const thirdParty: ShippingCarrierInterface[] = [];

        if (shippingOptions) {
            shippingOptions.forEach((option) => {
                if (option.thirdParty) {
                    thirdParty.push(option);
                } else {
                    inHouse.push(option);
                }
            });
        }

        if (showThirdPartyCarriers) {
            return [
                ..._.map(
                    _.orderBy(thirdParty, ["name"], ["asc"]),
                    (option) => {
                        return <MenuItem key={"shipping-option-" + option.id} value={option.id}>{option.name}</MenuItem>;
                    }
                )
            ];
        } else {

            return [
                ..._.map(
                    _.orderBy(inHouse, ["id"], ["asc"]),
                    (option) => {
                        return <MenuItem key={"shipping-option-" + option.id} value={option.id}>{option.name}</MenuItem>;
                    }
                )
            ];
        }
    };


    const buildShippingServiceSelections = () => {
        const options = [];

        if (shippingOptions && currentOrder.shippingPreference.carrier && currentOrder.shippingPreference.carrier.id && currentOrder.shippingPreference.carrier.id) {
            const currentCarrierSelected = shippingOptions.find((o) => o.id == currentOrder.shippingPreference.carrier.id);
            currentCarrierSelected.services.forEach((option) => {
                options.push(option);
            });
        }

        if (options.length == 0) {
            return [
                <MenuItem key={"shipping-option-0"} value={0}>None</MenuItem>
            ];
        }

        return [
            ..._.map(
                _.orderBy(options, ["name"], ["asc"]),
                (option) => {
                    return <MenuItem key={"shipping-option-" + option.id} value={option.id}>{option.name}</MenuItem>;
                }
            ),
        ];
    };

    // const isStepSkipped = (step) => {
    //   return skipped.has(step);
    // };

    const handleNext = async () => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //   newSkipped = new Set(newSkipped.values());
        //   newSkipped.delete(activeStep);
        // }
        if (currentActiveStep === 1) {
            if (
                !currentOrder.demoOrder &&
                orderingOnBehalfOf &&
                orderingOnBehalfOf.id != currentUser.company.id &&
                (
                    !currentOrder.customerPOURL ||
                    currentOrder.customerPOURL.length == 0
                )
            ) {
                setError("A copy of the internal PO is required when ordering on behalf of somebody else.");
                return;
            }

            if (!currentOrder.customerPONum || currentOrder.customerPONum.trim().length == 0) {
                saveCurrentOrder({ ...currentOrder, customerPONum: new Date().toLocaleString("en-us", { dateStyle: "short" }) });
            }
        } else if (currentActiveStep === 2) {
            // Check addresses selected
            // Check PO uploaded if ordering on behalf of somebody

            if (
                currentOrder.shippingPreference &&
                currentOrder.shippingPreference.carrier &&
                currentOrder.shippingPreference.carrier.id &&
                currentOrder.shippingPreference.carrier.thirdParty &&
                (
                    !currentOrder.shippingPreference.accountNumber ||
                    currentOrder.shippingPreference.accountNumber.length == 0
                )
            ) {
                setError("An account number is required when using a third party shipping service.");
                return;
            }

            if (currentOrder.company.defaultPaymentTermsName != "Prepaid") {
                saveCurrentActiveStep(currentActiveStep + 2);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                return;
            }
        }
        const updated = await updateOrder();
        if (updated) {
            saveCurrentActiveStep(currentActiveStep + 1);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else {
            setError("We're sorry. There was an internal error saving your order, please reload the page.");
        }
    };

    const handleBack = () => {
        if (currentActiveStep == 4) {
            if (currentOrder.company.defaultPaymentTermsName != "Prepaid") {
                saveCurrentActiveStep(currentActiveStep - 2);
            } else {
                saveCurrentActiveStep(currentActiveStep - 1);
            }
        } else {
            saveCurrentActiveStep(currentActiveStep - 1);
        }
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const handleReset = () => {
        saveCurrentActiveStep(0);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const close = () => {
        setOpen(false);
    };

    const buildTitle = () => {
        switch (currentActiveStep) {
            case 0: return "Your Order";
            case 1: return "Order Details";
            case 2: return "Shipping";
            case 3: return "Billing";
        }
    };

    return (
        <div className="checkout view">
            <ModalBasic open={open} close={close}>
                <div className="add-prompt">
                    <SuccessAni title="Order Submitted" />
                    <p style={{ fontSize: 16, paddingBottom: 10 }}>
                        We are processing your order.
                        <br /> If you have any questions, please contact us at (414)
                        395-8365.
                    </p>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={() => {
                            close();
                            saveCurrentPanel(SideDrawerTab.home);
                        }}
                    >
                        Sounds Good
                    </Button>
                </div>
            </ModalBasic>

            <Snackbar
                open={error.length > 0}
                autoHideDuration={5000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }
                    setError("");
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={"error"}
                    style={{ textAlign: "center" }}
                >
                    {error}
                </Alert>
            </Snackbar>

            {currentOrder && currentOrder.orderItems.length > 0 ? (
                <div className={classes.root}>
                    <h1 className="reveal-text">{buildTitle()}</h1>
                    <div>
                        {currentActiveStep === steps.length ? (
                            <div style={{ margin: "50px 0" }}>
                                <Typography className={classes.instructions}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <Grid>
                                <Grid item xs={12}>
                                    {getStepContent(currentActiveStep)}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    style={{ paddingTop: 30 }}
                                >
                                    <Grid item xs={2}>
                                        {currentActiveStep === 0 ? (
                                            <Button
                                                onClick={cancelOrder}
                                                className={classes.button}
                                            >
                                                Cancel Order
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                Back
                                            </Button>
                                        )
                                        }

                                    </Grid>

                                    <Grid style={{ visibility: currentActiveStep === steps.length - 1 ? "hidden" : "visible" }} item xs={2}>
                                        <Button
                                            onClick={handleNext}
                                            fullWidth={true}
                                            variant="contained"
                                            disabled={saving}
                                            color="primary"
                                            className={classes.button}
                                        >
                                            {saving ?
                                                <CircularProgress
                                                    color="inherit"
                                                    size={24}
                                                /> : "Next"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </div>
            ) : (
                <div style={{ height: "80vh", width: "100%" }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            height: "100%",
                            maxWidth: 600,
                            margin: "20px auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {currentUser.company.companyType === "Rep" || currentUser.company.companyType === "Internal" ? (
                            <>
                                <h1>Select Who to Order on Behalf Of</h1>
                                <Grid item style={{ width: "100%" }}>
                                    <OrderOnBehalfOf />
                                </Grid>
                                <h1>Or</h1>
                            </>
                        ) : null}
                        <h2 style={{ fontSize: 20 }}>Add products to your cart</h2>
                        <Grid item>
                            <div style={{ marginTop: 20 }}>
                                <Link style={{ width: "100%" }} to="/products">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn"
                                        onClick={() => saveCurrentPanel(SideDrawerTab.products)}
                                    >
                                        Browse Products
                                    </Button>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default Checkout;
