import React from "react";
import { Button, Snackbar, TextareaAutosize } from "@material-ui/core";
import { UserContext } from "context/User";
import { useNavigate } from "react-router-dom";
import { makeAxiosCall } from "utils";
import { Alert } from "@material-ui/lab";

const Help = () => {
  const { currentUser } = React.useContext(UserContext);
  const [showHelp, setShowHelp] = React.useState<boolean>(false);
  const [portalHelpMessage, setPortalHelpMessage] = React.useState<string>("");
  const [snackbar, setSnackbar] = React.useState({
    isOpen: false,
    message: '',
    severity: 'success',
  });

  let navigate = useNavigate();

  const repInfoCard = () => {
    return currentUser && currentUser.company && currentUser.company.companyType === "Reseller" && (
      currentUser.company.rep && currentUser.company.rep.contact ? (
        <div>
          <i className="fal fa-comment-dots"></i>
          <h2>Contact your Representative</h2>
          <h4>
            {currentUser.company.rep.name}
          </h4>
          <p><i className="fa-regular fa-envelope" style={{fontSize: "1em", color: "Blue"}}></i>&nbsp;{currentUser.company.rep.contact.email ? currentUser.company.rep.contact.email : "None"}</p>
          <p><i className="fa-solid fa-phone" style={{fontSize: "1em", color: "Blue"}}></i>&nbsp;{currentUser.company.rep.contact.phoneNumber ? currentUser.company.rep.contact.phoneNumber : "None"}</p>
        </div>
      ) : (
        <div>
          <i className="fal fa-comment-dots"></i>
          <h2>Contact Us</h2>
          <h4>
            Blizzard Lighting
          </h4>
          <p><i className="fa-regular fa-envelope" style={{fontSize: "1em", color: "Blue"}}></i>&nbsp; orders@blizzardpro.com</p>
          <p><i className="fa-solid fa-phone" style={{fontSize: "1em", color: "Blue"}}></i>&nbsp; (414) 395-8365</p>
        </div>
      )
    )
  }

  const sendPortalHelp = () => {
    if(portalHelpMessage && portalHelpMessage.length > 0) {

      return makeAxiosCall(
        "post",
        "email/help",
        {
          message: portalHelpMessage
        },
      ).then((_) => {
        setSnackbar({
          isOpen: true,
          message: 'Thank you for reporting the issue. We will look into this ASAP and will reach out.',
          severity: 'success',
        });
        setShowHelp(false);
        setPortalHelpMessage("");
      }).catch((e) => {
        console.log(e);
      });
    } else {
      setSnackbar({
        isOpen: true,
        message: 'Please tell us what\'s going on',
        severity: 'error',
      });
    }
  }
  
  return (
    <div className="view">
      <h1 className="reveal-text">Help Center</h1>
      <>
        <div className="help-info two-col">
          <div className="container">
            <div>
              <i className="fal fa-truck-medical"></i>
              <h2>FAQ</h2>
              <p>
                We do our best to make everything obvious, but sometimes it's not!
              </p>
              <Button
                type="submit"
                style={{ width: "48%" }}
                onClick={() => {
                  navigate(`/help/faq`);
                }}
                variant="contained"
                color="primary"
                className="btn"
              >
                FAQ
              </Button>
            </div>
            <div>
              <i className="fal fa-ticket"></i>
              <h2>Open a Ticket</h2>
              <p>
                Submit a support ticket and our heroes in Tech Support will come to your aid.
              </p>
              <Button
                type="submit"
                style={{ width: "48%" }}
                onClick={() => window.open("https://blizzardlightingllc.freshdesk.com/support/tickets/new", "_blank")}
                variant="contained"
                color="primary"
                className="btn"
              >
                Submit New Ticket
              </Button>
            </div>
            <div>
              <i className="fal fa-clock-rotate-left"></i>
              <h2>Existing Tickets</h2>
              <p>
                Check in on your existing support ticket to find out where we're at with it.
              </p>
              <Button
                type="submit"
                style={{ width: "48%" }}
                onClick={() => window.open("https://blizzardlightingllc.freshdesk.com/support/login", "_blank")}
                variant="contained"
                color="primary"
                className="btn"
              >
                Check Ticket Status
              </Button>
            </div>
            {repInfoCard()}
            <div>
              <i className="fal fa-bug-slash"></i>
              <h2>Report Portal Issue</h2>
              <p>
                Having a problem with the portal? Email us at <span style={{fontWeight: "bold"}}>portal@blizzardpro.com</span> and we'll look into it.
              </p>
              <Button
                type="submit"
                style={{ width: "48%" }}
                onClick={() => setShowHelp(true)}
                variant="contained"
                color="primary"
                className="btn"
              >
                Report Web Issue
              </Button>
            </div>
            {
              showHelp ? (
                <div>
                  <h2>What's going on?</h2>
                  
                  <TextareaAutosize
                    aria-label="notes"
                    style={{height: "auto", padding: 10}}
                    minRows={3}
                    placeholder="Describe the issue"
                    value={portalHelpMessage}
                    onChange={(e) => setPortalHelpMessage(e.target.value)}
                    className="order-item-notes"
                  />
                  <Button
                    type="submit"
                    style={{ width: "40%", marginRight: "10%" }}
                    onClick={() => setShowHelp(false)}
                    variant="outlined"
                    color="primary"
                    className="btn"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    style={{ width: "40%" }}
                    onClick={() => sendPortalHelp()}
                    variant="contained"
                    color="primary"
                    className="btn"
                  >
                    Send Report
                  </Button>
                </div>
              ) : null}
          </div>
        </div>
      </>

      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={3000}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          setSnackbar({ ...snackbar, isOpen: false });
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Help;
