import React from "react";
import { Grid, Button } from "@material-ui/core";
import ModalBasic from "components/utils/ModalBasic";

interface DeletePromptProps {
    open: boolean,
    close: React.Dispatch<React.SetStateAction<boolean>>,
    callback: (any: any) => void,
    obj: any,
    type: string,
}

const DeletePrompt = ({ open, close, callback, obj, type }: DeletePromptProps) => {
    return (
        <ModalBasic open={open} close={() => close(false)}>
            <div className="container" style={{ padding: "4em 2em" }}>
                <i
                    style={{
                        fontSize: "4em",
                        textAlign: "center",
                        display: "block",
                        color: "#ff4d4d",
                    }}
                    className="fas fa-skull-crossbones"
                ></i>
                <h2
                    style={{
                        maxWidth: 400,
                        textAlign: "center",
                        paddingBottom: "1em",
                    }}
                >
                    {`Are you sure you want to delete this ${type}? This cannot be undone.`}
                </h2>
                <Grid container>
                    <Grid style={{ margin: "0 auto" }} item sm={6}>
                        <Button
                            type="submit"
                            onClick={() => {
                                callback(obj);
                                close(false);
                            }}
                            style={{ width: "100%", background: "#ff4d4d" }}
                            variant="contained"
                            color="primary"
                            className="btn"
                        >
                            Delete
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            type="submit"
                            style={{ width: "100%", marginLeft: 5 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => close(false)}
                            className="btn"
                        >
                            Nope
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </ModalBasic>
    );
};

export default DeletePrompt;
