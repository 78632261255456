import React, { useState, useContext } from "react";
import _ from "lodash";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, ButtonGroup, Box, Chip, OutlinedInput, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import { UserContext } from "context/User";
import { useNavigate } from "react-router-dom";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import { makeAxiosCall } from "utils";
import { buildSalesOrderList, SalesOrderInterface } from "interfaces/SalesOrder";
import { DatePicker } from "@material-ui/pickers";
import { ProductInterface } from "interfaces/Product";
import { BasicCompanyInterface, CompanyInterface } from "interfaces/Company";
import { CSVLink } from "react-csv";

interface SalesItemListing {
  id: number,
  soNumber: string,
  createdAt: Date,
  customer: BasicCompanyInterface,
  quantity: number,
  total: number,
  status: string,
  isDemo: boolean,
  isReturn: boolean
};

interface ProductSalesHistoryProps {
  product: ProductInterface,
  startDate?: Date,
  endDate?: Date
};

const ProductSalesHistory = ({ product, startDate, endDate }: ProductSalesHistoryProps) => {
  const { internalUser } = useContext(UserContext);
  const [companies, setCompanies] = React.useState<BasicCompanyInterface[]>();
  const [salesOrders, setSalesOrders] = React.useState<SalesOrderInterface[]>();
  
  const [visibleSales, setVisibleSales] = React.useState<SalesItemListing[]>();
  const [salesListings, setSalesListings] = React.useState<SalesItemListing[]>([]);

  const [input, setInput] = useState<string>("");
  const [filterStartDate, setFilterStartDate] = React.useState<Date>(startDate ? startDate : null);
  const [filterEndDate, setFilterEndDate] = React.useState<Date>(endDate ? endDate : null);
  const [sortMethods, setSortMethods] = React.useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string>("");

  const [loading, setLoading] = React.useState(true);

  const windowScrollerRef = React.useRef<WindowScroller>();
  const virtualizedListRef = React.useRef<List>();

  const navigate = useNavigate();

  const sortOptions = [
    {
      label: "Newest",
      value: "createdAt",
      order: "desc",
    },
    {
      label: "Oldest",
      value: "createdAt",
      order: "asc",
    },
    {
      label: "Quantity (Low - High)",
      value: "quantity",
      order: "asc",
    },
    {
      label: "Quantity (High - Low)",
      value: "quantity",
      order: "desc",
    },
    {
      label: "Total (Low - High)",
      value: "total",
      order: "asc",
    },
    {
      label: "Total (High - Low)",
      value: "total",
      order: "desc",
    },
  ];

  const orderStatusOptions = [
    "Picked",
    "Built",
    "Cancelled",
    "Picking",
    "Partial",
    "Closed Short",
    "Historical",
    "Fulfilled",
    "Voided",
    "Awaiting Build",
    "Entered",
    "Building"
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 800,
        width: 250,
      },
    },
  };

  React.useEffect(() => {
    fetchCompanies();
  }, [])

  React.useEffect(() => {
    if (product && product.id) {
      loadSalesOrders();
    }
  }, [product]);

  React.useEffect(() => {
    filterProducts();
  }, [salesListings, input, sortMethods, filterStartDate, filterEndDate, statusFilter]);

  React.useEffect(() => {
    if (companies && salesOrders) {
      buildListings();
    }
  }, [companies, salesOrders]);

  const today = new Date();

  const isToday = (date?: Date) => {
    return date ? (
      date.toDateString() == today.toDateString()
    ) : false;
  }

  const thirtyDays = React.useMemo(() => {
    return new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString());
  }, []);
  
  const isThirtyDays = React.useMemo(() => {
    return filterStartDate ? (
      filterStartDate.toDateString() == thirtyDays.toDateString() &&
      isToday(filterEndDate)
    ) : false;
  }, [filterStartDate, filterEndDate]);

  const oneYear = React.useMemo(() => {
    return new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toDateString());
  }, []);

  const isOneYear = React.useMemo(() => {
    return filterStartDate ? (
      filterStartDate.toDateString() == oneYear.toDateString() &&
      isToday(filterEndDate)
    ) : false;
  }, [filterStartDate, filterEndDate]);

  const startOfYear = React.useMemo(() => {
    let startOfYear = new Date();
    startOfYear.setDate(1);
    startOfYear.setMonth(0);
    return new Date(startOfYear.toDateString());
  }, []);

  const isYTD = React.useMemo(() => {
    return filterStartDate ? (
      filterStartDate.toDateString() == startOfYear.toDateString() &&
      isToday(filterEndDate)
    ) : false;
  }, [filterStartDate, filterEndDate]);

  const totalQuantity = React.useMemo(() => {
    let result = 0;
    if (visibleSales && visibleSales.length > 0) {
      visibleSales.forEach((prod) => {
        if (!prod.isDemo && !prod.isReturn && prod.status == "Fulfilled") result += prod.quantity;
      });
    }
    return result;
  }, [visibleSales]);

  const totalPrice = React.useMemo(() => {
    let result = 0;
    if (visibleSales && visibleSales.length > 0) {
      visibleSales.forEach((prod) => {
        if (!prod.isDemo && prod.status == "Fulfilled") result += prod.total;
      });
    }
    return result;
  }, [visibleSales]);

  const totalReturns = React.useMemo(() => {
    let result = 0;
    if (visibleSales && visibleSales.length > 0) {
      visibleSales.forEach((prod) => {
        if (!prod.isDemo && prod.isReturn && prod.status == "Fulfilled") result += prod.quantity;
      });
    }
    return result;
  }, [visibleSales]);

  const fetchCompanies = () => {
    makeAxiosCall(
      "get",
      "companies/simple"
    ).then(res => {
      const companyList: BasicCompanyInterface[] = [];
      for (let ci = 0; ci < res.data.length; ci++) {
        const company = res.data[ci];
        companyList.push({ id: company.id, name: company.name });
      }
      setCompanies(companyList);
    });
  }

  const loadSalesOrders = async () => {
    setLoading(true);

    const response = await makeAxiosCall(
      "GET",
      `sales-order-history/${product.id}`
    );

    if (response?.status == 200) {
      setSalesOrders(buildSalesOrderList(response.data));
    }
  }

  const buildListings = () => {
    const listings: SalesItemListing[] = [];

    salesOrders.forEach((order) => {
      order.items.forEach((item) => {
        if (item.productId == product.id) {
          listings.push({
            id: item.id,
            soNumber: order.soNumber,
            createdAt: new Date(order.createdAt),
            customer: companies.find((company) => company.id == order.customerId),
            quantity: item.quantityOrdered,
            total: item.totalPrice,
            status: item.status.name,
            isDemo: order.soNumber.toLowerCase().includes("demo"),
            isReturn: item.totalPrice < 0
          });
        }
      });
    });

    setSalesListings(listings);
  }

  const goToSalesOrder = async (id: String) => {
    navigate(`/order-history/${id}`);
  };

  const goToCompany = async (id: number) => {
    navigate(`/edit-company/${id}`);
  }

  const filterProducts = () => {
    let searchResults: SalesItemListing[] = salesListings;

    if (filterStartDate) {
      let validEndDate = filterEndDate ? filterEndDate : today;
      searchResults = _.filter(salesListings, (p: SalesItemListing) => {
        return p.createdAt >= filterStartDate && p.createdAt <= validEndDate;
      });
    }

    if (input.length > 0) {
      const searchInput = input.toLowerCase(),
        inputIgnoredChar = searchInput;

      searchResults = _.filter(searchResults, (p: SalesItemListing) => {
        let soNum = "";
        let companyName = "";
        if (p.soNumber !== null) {
          soNum = p.soNumber.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");
        }

        if (p.customer.name != null) {
          companyName = p.customer.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");
        }

        const arr = [soNum, companyName];
        const regexQuery = RegExp(inputIgnoredChar.replaceAll(" ", ".*").replaceAll("-", ".*"));
        for (let ati = 0; ati < arr.length; ati++) {
          const element = arr[ati];

          if (regexQuery.test(element)) return true;
        }
        return false;
      });
    }

    if (statusFilter.length > 0) {
      searchResults = _.filter(searchResults, (p: SalesItemListing) => p.status == statusFilter);
    }

    if (sortMethods.length > 0) {
      searchResults = _.orderBy(
        searchResults,
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).value),
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
      );
    } else {
      searchResults = _.orderBy(searchResults, ["createdAt"], ["desc"]);
    }

    setVisibleSales(searchResults);

    if (loading && searchResults?.length > 0) setLoading(false);
  }

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format((price));
  };

  const buildFilters = () => {
    return (
      <div className="product-filters-container" style={{ marginTop: 0, paddingTop: 0 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} md={3}>
            <DatePicker
              style={{ width: "100%" }}
              margin="normal"
              inputVariant="outlined"
              format={"MM/DD/yy"}
              label="Start Date"
              views={["date"]}
              disableFuture={true}
              value={filterStartDate}
              onChange={(date: moment.Moment) => {
                setFilterStartDate(date.toDate())
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DatePicker
              style={{ width: "100%" }}
              margin="normal"
              inputVariant="outlined"
              format={"MM/DD/yy"}
              label="End Date"
              views={["date"]}
              disableFuture={true}
              value={filterEndDate}
              onChange={(date: moment.Moment) => {
                setFilterEndDate(date.toDate())
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonGroup fullWidth={true} color="primary" aria-label="outlined primary button group" style={{ height: "56px", marginTop: 8 }}>
              <Button
                className={
                    isThirtyDays ?
                    "active-filter-button" :
                    "inactive-filter-button"
                }
                onClick={() => {
                  let newStartDate = thirtyDays;
                  let newEndDate = today;
                  setFilterStartDate(newStartDate);
                  setFilterEndDate(newEndDate);
                }}
              >
                30 Days
              </Button>
              <Button
                className={
                  isOneYear ?
                  "active-filter-button" :
                  "inactive-filter-button"
                }
                onClick={() => {
                  let newStartDate = oneYear;
                  let newEndDate = today;
                  setFilterStartDate(newStartDate);
                  setFilterEndDate(newEndDate);
                }}
              >
                1 Year
              </Button>
              <Button
                className={
                    isYTD ? 
                    "active-filter-button" :
                    "inactive-filter-button"
                }
                onClick={() => {
                  setFilterStartDate(startOfYear);
                  setFilterEndDate(new Date());
                }}
              >
                YTD
              </Button>
              <Button
                className={
                    (filterStartDate == null && filterEndDate == null) ? 
                    "active-filter-button" : 
                    "inactive-filter-button"
                }
                onClick={() => {
                  setFilterStartDate(null);
                  setFilterEndDate(null);
                }}
              >
                All Time
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} md={6}>
            <div className="inventory-search">
              <TextField
                label="Order Search"
                variant="outlined"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="select-status-type"
                value={statusFilter}
                label="Type"
                onChange={(event) => {
                  setStatusFilter(event.target.value + "");
                }}
              >
                <MenuItem value={""}>Any</MenuItem>
                {_.map(
                  orderStatusOptions,
                  (type: string, i: number) => {
                    return <MenuItem key={"type-" + i} value={type}>{type}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item container alignContent="flex-start" xs={6} md={3}>
            <Grid item xs={12}>Sort By</Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: "100%" }}>
                <Select
                  key="sortMethods"
                  multiple
                  value={sortMethods}
                  renderValue={() => (
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {sortMethods.map((method) => (
                        <Chip key={method} label={method} style={{ marginRight: 5 }} />
                      ))}
                    </Box>
                  )}
                  onChange={(e: React.ChangeEvent<{
                    name?: string;
                    value: string[];
                  }>) => {
                    setSortMethods(e.target.value);
                  }}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                >
                  {sortOptions.map(
                    (option) => {
                      const index = sortMethods.findIndex((n) => n == option.label);
                      return (
                        <MenuItem
                          key={option.label}
                          value={option.label}
                        >
                          <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                          {option.label}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}></Grid>
          <Grid item xs={12} md={3}>
            <Button
              style={{ height: 56, marginTop: 8 }}
              variant="contained"
              color="primary"
              className="btn"
              fullWidth
              disabled={!visibleSales || visibleSales.length == 0}
            >
              <CSVLink
                data={visibleSales ? visibleSales : ""}
                headers={[
                  { label: "SO Item ID", key: "id" },
                  { label: "SO Number", key: "soNumber" },
                  { label: "Date", key: "createdAt" },
                  { label: "Customer", key: "customer.name" },
                  { label: "Quantity", key: "quantity" },
                  { label: "Total", key: "total" },
                  { label: "Status", key: "status" }
                ]}
                filename={`${product.name}${new Date().valueOf()}.csv`}
                style={{ color: "white", border: "none" }}
                target="_blank"
              >
                Download CSV &nbsp;<i className="far fa-download"></i>
              </CSVLink>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  const renderSummaryItem = (value: number, title: string, price: boolean) => {
    return (
      <Grid item xs={12} md={4}>
        <p style={{
          fontSize: 25,
          color: value < 0 ? "red" : "black"
        }}>{price ? formatPrice(value) : value}</p>
        <h3 style={{
          color: "white",
          backgroundColor: "blue",
          borderRadius: "100px",
          margin: "0 15px",
          padding: 5,
        }}>{title}</h3>
      </Grid>
    );
  }

  const buildSummary = () => {
    return (
      <Grid container spacing={2} alignItems="center" style={{
        textAlign: "center",
        paddingBottom: 25,
        marginBottom: 15,
        borderBottom: "3px solid gray"
      }}>
        {renderSummaryItem(totalQuantity, "Total Sold", false)}
        {renderSummaryItem(totalPrice, "Total Price", true)}
        {renderSummaryItem(totalReturns, "Returned", false)}
      </Grid>
    );
  }

  const renderSalesListing = (order: SalesItemListing, key, subProduct: boolean, phoneMode: boolean) => {
    return (
      <Grid style={{ marginBottom: (subProduct ? 10 : 0), minHeight: 80, paddingBottom: 10, width: "100%", border: (subProduct ? "1px solid rgba(0, 0, 0, 0.12)" : "") }} container spacing={1} className="line-item" key={key}>
        <Grid item container xs={6} sm={2} md={1}
          style={{ fontWeight: "bold" }}
          className="num"
        >
          {order.createdAt.toLocaleDateString()}
        </Grid>
        <Grid item container xs={6} sm={2} md={2}
          style={{ cursor: "pointer", fontWeight: "bold" }}
          className="num"
          onClick={() => {
            goToSalesOrder(order.soNumber);
          }}
        >
          {order.isDemo && (<Grid
            item
            xs={12}
            className={
              "qty highlight"
            }
            style={{
              margin: "0 5",
              color: "white",
              backgroundColor: "blueviolet",
              fontSize: 10,
              textAlign: "center"
            }}
          >
            Demo
          </Grid>)}
          <Grid item xs={12}>
            {order.soNumber}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={8} md={3}
          style={{ cursor: "pointer", fontWeight: "bold", textDecoration: "underline" }}
          onClick={() => {
            goToCompany(order.customer.id);
          }}
        >
          {order.customer.name}
        </Grid>
        <Grid item xs={3} sm={2} md={1} style={{ fontWeight: "bold", color: order.isReturn ? "red" : "black" }}>
          {formatPrice(order.total)}
        </Grid>
        <Grid item xs={3} sm={2} md={1} style={{ textAlign: "center", color: order.isReturn ? "red" : "black" }}>
          <div
            style={{
              width: 70,
              margin: "0 auto"
            }}
          >
            {order.quantity}
          </div>
        </Grid>
        {renderStatus(order.status)}
        {!phoneMode &&
          <Grid item xs={12} sm={2} md={1}
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              goToSalesOrder(order.soNumber);
            }}
          >
            <i className={"fas fa-eye"} style={{ color: "blue" }}></i>
          </Grid>
        }
      </Grid>
    );
  }

  const calcRowHeight = (index, phoneMode: boolean): number => {
    return 91 + (phoneMode ? 40 : 0);
  }

  const renderStatus = (status: string) => {
    let classString = "";
    if (status === "Fulfilled") {
      classString = "status highlight green";
    } else if (
      status === "Entered" ||
      status === "Picked" ||
      status === "Built" ||
      status === "Picking" ||
      status === "Awaiting Build" ||
      status === "Building"
    ) {
      classString = "status highlight blue";
    } else if (
      status === "Voided" ||
      status === "Closed Short" ||
      status === "Cancelled"
    ) {
      classString = "status highlight red";
    } else if (
      status === "Partial"
    ) {
      classString = "status highlight purple";
    } else if (status === "Historical") {
      classString = "status highlight grey";
    }

    return (
      <Grid item xs={3} sm={2} md={1} style={{ textAlign: "center" }}>
        <div className={classString}>
          {status}
        </div>
      </Grid>
    );
  };

  const renderRow = (index, key, style, phoneMode: boolean) => {
    const prod: SalesItemListing = visibleSales[index];

    return (
      <div key={key} style={style} className={"single-product-line"}>
        {renderSalesListing(prod, key, false, phoneMode)}
      </div>
    );
  }

  const renderPlaceholder = () => {
    return <h3 className="body-message center-text">No orders meet your search parameters.</h3>;
  }

  const renderResults = () => {
    if (loading) {
      return <Loading height="100vh" title={"Loading Products"} position={"center"} />;
    } else {
      return <WindowScroller ref={windowScrollerRef} style={{ overflow: "hidden" }}>
        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
          const phoneMode = width < 500;

          return (<Grid container className="results" style={{ overflow: "hidden" }}>
            <Grid container item spacing={1} className="row-heads io" style={{ overflow: "hidden" }}>
              <Grid item xs={6} sm={2} md={1} >Date</Grid>
              <Grid item xs={6} sm={2} md={2} >SO Number</Grid>
              <Grid item xs={12} sm={8} md={3} >Customer</Grid>
              <Grid item xs={3} sm={2} md={1} >Total</Grid>
              <Grid item xs={3} sm={2} md={1} style={{ textAlign: "center" }}>Quantity</Grid>
              <Grid item xs={3} sm={2} md={1} style={{ textAlign: "center" }}>Status</Grid>
              {!phoneMode && <Grid item xs={12} sm={2} md={1} style={{ textAlign: "center" }}>Details</Grid>}
            </Grid>
            <List
              style={{ overflow: "visible" }}
              ref={virtualizedListRef}
              autoHeight
              width={width}
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              rowHeight={(props) => calcRowHeight(props.index, phoneMode)}
              rowRenderer={(itemProps) => renderRow(itemProps.index, itemProps.key, itemProps.style, phoneMode)}
              noRowsRenderer={renderPlaceholder}
              rowCount={visibleSales.length}
              containerStyle={{ backgroundColor: "transparent", overflow: "visible", paddingTop: 10 }}
              overscanRowCount={3}
            />
          </Grid>)
        }}
      </WindowScroller>;
    }
  };

  return (
    <div className="inventory-results" style={{ overflow: "hidden", width: "100%" }}>
      {internalUser && (
        <div style={{ overflow: "hidden" }}>
          {buildFilters()}
          {buildSummary()}
          <div className="search-results-container" style={{ overflow: "hidden" }}>{renderResults()}</div>
        </div>
      )}
      {!internalUser && (
        <h3>You don't have permission to view this.</h3>
      )}
    </div>
  );
};

export default ProductSalesHistory;
