import React, { useRef } from "react";
import _ from "lodash";
import { Button, Checkbox, Grid, List, ListItem, ListItemText, useTheme, Zoom } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";
import { PDProjectInterface, PDProjectMediaInterface } from "interfaces/ProductDevelopment";
import PDProjectMediaUpload from "./MediaUpload";
import { useLocation } from "react-router-dom";
import PDProjectMediaListItem from "./MediaListItem";

interface PDProjectMediaListProps {
    project: PDProjectInterface,
    refreshData: () => void,
    showErrorText: (errorText: string) => void
}

interface MediaCategoryList {
    name: string,
    list: JSX.Element[]
}

const PDProjectMediaList = ({ project, refreshData }: PDProjectMediaListProps) => {

    const [allMedia, setAllMedia] = React.useState<PDProjectMediaInterface[]>(project.media);
    const [selectedMedia, setSelectedMedia] = React.useState<PDProjectMediaInterface[]>([]);
    const theme = useTheme();

    const scrollRef = useRef(null);
    const { hash } = useLocation();

    React.useEffect(() => {
        setTimeout(() => {
            if (hash && allMedia.length && scrollRef && scrollRef.current) {
                scrollRef.current.scrollIntoView();
                history.pushState("", document.title, window.location.pathname + window.location.search);
            }
        }, 100);
    }, [allMedia]);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    React.useEffect(() => {
        setAllMedia(project.media);
    }, [project]);

    const handleSelectMedia = (documentId: number) => {
        if (selectedMedia.some((i) => i.id == documentId)) {
            setSelectedMedia(selectedMedia.filter((i) => i.id != documentId));
        } else {
            setSelectedMedia([...selectedMedia, allMedia.find((i) => i.id == documentId)]);
        }
    };

    const handleSelectAllDownloads = () => {
        if (selectedMedia.length == allMedia.length) {
            setSelectedMedia([]);
        } else {
            setSelectedMedia([...allMedia]);
        }
    };

    const handleBulkDownload = async () => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = "pd-project-documents.zip";

        selectedMedia.forEach((file) => {

            console.log(file);
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }

                zip.file(file.firebaseName, data, { binary: true });
                count++;
                if (count == selectedMedia.length) {
                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        FileSaver.saveAs(content, zipFilename);
                        setSelectedMedia([]);
                    });
                }
            });
        });
    };

    const renderListItem = (document: PDProjectMediaInterface) => {
        return (
            <PDProjectMediaListItem
                mediaItem={document}
                project={project}
                refreshData={refreshData}
                selected={selectedMedia.includes(document)}
                selectCallback={handleSelectMedia}
            />
        );
    }

    const renderDocumentList = () => {
        let mediaCategoryList: MediaCategoryList[] = [];

        for (let di = 0; di < allMedia.length; di++) {
            const media = allMedia[di];
            let existingCategory = mediaCategoryList.find((m) => m.name == media.category);
            if (existingCategory) {
                existingCategory.list.push(renderListItem(media));
            } else {
                mediaCategoryList.push({
                    name: media.category,
                    list: [renderListItem(media)]
                });
            }
        }

        mediaCategoryList.sort((a, b) => a.name.localeCompare(b.name));

        return (
            <List>
                {mediaCategoryList.map((mc) => {
                    return (
                        <div
                            ref={(hash && hash.toLocaleLowerCase() === "#" + mc.name.toLocaleLowerCase()) ? scrollRef : null}
                            key={mc.name + "category-list"}
                            style={{ scrollMarginTop: 60 }}
                        >
                            <h5>{mc.name}</h5>
                            <List>
                                {mc.list}
                            </List>
                        </div>
                    );
                })}

                <ListItem key="bulkzone" alignItems="flex-start" style={{ height: 50 }}>
                    <ListItemText primary={" "} />

                    <Zoom
                        in={selectedMedia.length > 0}
                        timeout={transitionDuration}
                        style={{
                            transitionDelay: `${selectedMedia.length > 0 ? transitionDuration.exit : 0}ms`,
                        }}
                        unmountOnExit
                    >

                        <Button
                            type="submit"
                            variant="contained"
                            color={"primary"}
                            className="btn"
                            fullWidth={true}
                            onClick={() => {
                                handleBulkDownload();
                            }}
                            endIcon={
                                <GetApp />
                            }
                            style={{ marginLeft: 15 }}
                        >
                            {"Zip and Download " + selectedMedia.length + " file" + (selectedMedia.length > 1 ? "s" : "")}
                        </Button>
                    </Zoom>

                    <Checkbox
                        checked={selectedMedia.length == allMedia.length}
                        onChange={handleSelectAllDownloads}
                    />
                </ListItem>
            </List>
        );
    };

    const renderBody = () => {
        if (allMedia.length > 0) {
            return (
                <Grid item xs={12}>
                    {renderDocumentList()}
                </Grid>
            );
        } else {
            return <p className="body-message">No Files Found</p>;
        }
    };

    return (
        <>
            <Grid container alignItems="center" >
                <Grid item xs={12} sm={9}>
                    <h2>Files</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <PDProjectMediaUpload
                        project={project}
                        creationCallback={refreshData}
                    />
                </Grid>
                {renderBody()}
            </Grid>
        </>
    );
};

export default PDProjectMediaList;