import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: "relative",
    margin: "10px",
    zIndex: 10,
  },
}));

const ModalBasic = (props) => {
  const { children, close, customClass, open, hideCloseIcon } = props;
  const classes = useStyles();

  return (
    <div className={customClass}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => close(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {!hideCloseIcon && (
              <div onClick={close} className="close">
                ✕
              </div>
            )}
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalBasic;
