import React from "react";

import JotformEmbed from "react-jotform-embed";

const JotformPage = ({ jotformId }) => {
  return (
    <div className="">
      <JotformEmbed src={`https://form.jotform.com/${jotformId}`} />
    </div>
  );
};

export default JotformPage;
