
export interface DocumentInterface {
    id: number,
    createdAt: Date,
    companyId?: number,
    createdBy: {
        id: string,
        displayName: string,
        companyId?: number
    }
    accountLevelId: number,
    companyTypeId: number,
    url: string,
    name: string,
    firebaseName: string,
    category: string
}

export const buildCompanyDocument = (rawData): DocumentInterface => {
    return {
        id: rawData.id,
        createdAt: new Date(rawData.created_at),
        companyId: rawData.company_id,
        createdBy: {
            id: rawData.created_by.id,
            displayName: rawData.created_by.name,
            companyId: rawData.created_by.associated_company_id
        },
        accountLevelId: rawData.account_level_id,
        companyTypeId: rawData.company_type_id,
        url: rawData.url,
        name: rawData.name,
        firebaseName: rawData.firebase_name,
        category: rawData.category ?? "Other"
    };
};

export const buildCompanyDocumentList = (rawData): DocumentInterface[] => {
    return rawData.map((document) => buildCompanyDocument(document));
};