export const colors = [
    "Red",
    "Green",
    "Blue",
    "Amber",
    "White",
    "Warm White",
    "UV",
    "Cyan",
    "Magenta",
    "Yellow",
    "Lime",
];

export const channelOptions = [
    {
        name: "Dimmer",
    },
    {
        name: "Shutter/Strobe",
    },
    {
        name: "Pan/Tilt",
        options: ["Pan", "Tilt"],
    },
    {
        name: "Color",
        type: "Pixel Mapping",
        options: colors,
    },
    {
        name: "Gobo",
        options: ["Gobo Rotate", "Gobo Static"],
    },
    {
        name: "Iris",
        options: ["Iris Rotate", "Iris Static"],
    },
    {
        name: "Focus",
    },
    {
        name: "Zoom",
    },
    {
        name: "Prism",
        options: ["Prism", "Prism Rotate"],
    },
    {
        name: "Macro",
        options: ["Macro", "Macro Speed"],
    },
];
