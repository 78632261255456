import { Button, CircularProgress, Grid, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { db } from "base.js";
import _ from "lodash";
import { createMarkup, makeAxiosCall } from "utils";
import moment from "moment";

const Status = () => {
    // const [statuses, setStatuses] = useState([]);
    const [draftEdits, setDraftEdits] = useState([]);
    const [docusignErrors, setDocusignErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [draftOrders, setDraftOrders] = useState([]);
    const [viewLog, setViewLog] = useState(false);
    const [fetchingPriceList, setFetchingPriceList] = useState(false);
    const [fetchingCompanies, setFetchingCompanies] = useState(false);

    let isMounted = true;

    React.useEffect(() => {
        getAdminData();
        return () => {
            isMounted = false;
        };
    }, []);

    const getDraftEdits = async () => {
        const pendingFishbowlImports = await db.collection("draftEdits").get();

        if (isMounted) {
            setDraftEdits(pendingFishbowlImports.docs.map((doc) => doc.data()));
        }
    };

    const getAdminData = async () => {
        // const statusFromFishbowl = await db.collection("status").get();
        const pendingFishbowlOrders = await db.collection("draftOrders").get();
        const docusignFailures = await db.collection("docusignErrors").get();
        getDraftEdits();

        if (isMounted) {
            // setStatuses(statusFromFishbowl.docs.map((doc) => doc.data()));
            setDraftOrders(pendingFishbowlOrders.docs.map((doc) => doc.data()));
            setDocusignErrors(docusignFailures.docs.map((doc) => doc.data()));
        }
    };

    const apiControl = async () => {
        setLoading(true);
        await makeAxiosCall(
            "get",
            "batch-update-custom-field"
        )
            .then(() => getDraftEdits())
            .catch((error) => console.log(error));
        setLoading(false);
    };

    const fetchPriceList = async () => {
        setFetchingPriceList(true);
        const pl = await makeAxiosCall("get", "reseller/pricelist");
        setFetchingPriceList(false);
    };

    const fetchCompanies = async () => {
        setFetchingCompanies(true);
        const pl = await makeAxiosCall("get", "fishbowl/sync-companies");
        setFetchingCompanies(false);
    };

    const renderFishbowlImports = () => {
        if (draftEdits.length > 0) {
            return _.map(draftEdits, (drafts, i) => {
                return _.map(drafts.fishbowlData, (o) => {
                    return (
                        <div className="line-item-no-flex" key={i}>
                            <h2>{o.recordData}</h2>
                            <h3>{o.fieldName}</h3>
                            <hr />
                            <p
                                style={{ fontSize: "1.2em", margin: 0 }}
                                dangerouslySetInnerHTML={createMarkup(o.data)}
                            />
                        </div>
                    );
                });
            });
        } else {
            return <h3>No Imports Pending</h3>;
        }
    };

    const renderFishbowlOrderImports = () => {
        if (draftOrders.length > 0) {
            return _.map(draftOrders, (drafts, i) => {
                const log = JSON.stringify(drafts, null, "\t");

                return (
                    <div className="line-item-no-flex" key={i}>
                        <h2 style={{ padding: 0, margin: 0 }}>{drafts.email}</h2>

                        <h4 style={{ paddingBottom: 20, margin: 0 }}>
                            Submitted at{" "}
                            {moment(drafts.submittedAt).format("MM/DD/YYYY hh:mm a")}
                        </h4>
                        <h3>Customer Name: {drafts.customerName}</h3>
                        {_.map(drafts.lineItems, (item) => {
                            return (
                                <p>
                                    {item.qty}x {item.productNum}
                                </p>
                            );
                        })}

                        <p
                            style={{ fontWeight: "bold", cursor: "pointer" }}
                            onClick={() => setViewLog(!viewLog)}
                        >
                            {viewLog ? "Hide Log" : "View Log"}
                        </p>
                        {viewLog && <p>{log}</p>}
                    </div>
                );
            });
        } else {
            return <h3>No Imports Pending</h3>;
        }
    };

    const renderDocusignErrors = () => {
        if (docusignErrors.length > 0) {
            return _.map(docusignErrors, (error, i) => {
                return (
                    <div className="line-item-no-flex" key={error.envelopeId + i}>
                        <h2 style={{ padding: 0, margin: 0 }}>Envelope: {error.envelopeId ? error.envelopeId : "Unknown"}</h2>

                        <h4 style={{ margin: 0 }}>
                            Company Id: {error.companyId ? error.companyId : "Unknown"}
                        </h4>
                        <h4 style={{ margin: 0 }}>
                            Date: {error.date ? new Date(error.date).toString() : "Unknown"}
                        </h4>
                        <p>Error: <span style={{ color: "tomato" }}>{error.error}</span></p>
                        <p>Checkpoint: {error.checkpoint ? error.checkpoint : "None"}</p>
                    </div>
                );
            });
        } else {
            return <h3>No DocuSign Errors</h3>;
        }
    };

    return (
        <div className="view">
            <Grid container>
                <Grid item xs={12}>
                    <h1 className="reveal-text">Portal Status Dashboard</h1>
                </Grid>

                <Grid item container xs={12}>
                    <Grid item>
                        <Tooltip
                            title="Use this button after updating the price list in Google Drive"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="btn"
                                onClick={() => fetchPriceList()}
                                endIcon={
                                    fetchingPriceList && <CircularProgress color="inherit" size={25} />
                                }
                                style={{ marginLeft: 15 }}
                            >
                                Sync Price List
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            title="This button refreshes company data in the portal with the current Fishbowl data"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="btn"
                                onClick={() => fetchCompanies()}
                                endIcon={
                                    fetchingCompanies && <CircularProgress color="inherit" size={25} />
                                }
                                style={{ marginLeft: 15 }}
                            >
                                Sync Companies
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid item container xs={12} style={{ marginTop: 50 }}>
                    <Grid item container alignItems="center" spacing={3} xs={12}>
                        <Grid item ><h2>Fishbowl Data Imports</h2></Grid>
                        <Grid item >
                            <Tooltip
                                title="This button attempts to push product edits made in the portal to Fishbowl"
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    onClick={() => apiControl()}
                                    endIcon={
                                        isLoading && <CircularProgress color="inherit" size={25} />
                                    }
                                >
                                    Sync Queue
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        {renderFishbowlImports()}
                    </Grid>
                </Grid>

                {/* <Grid item container xs={12} style={{ marginTop: 50 }}>
          <Grid item container alignItems="center" spacing={3} xs={12}>
            <Grid item ><h2>Fishbowl Order Imports</h2></Grid>
          </Grid>
          <Grid item container xs={12}>
            {renderFishbowlOrderImports()}
          </Grid>
        </Grid> */}

                <Grid item container xs={12} style={{ marginTop: 50 }}>
                    <Grid item container alignItems="center" spacing={3} xs={12}>
                        <Grid item ><h2>DocuSign Agreement Imports</h2></Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        {renderDocusignErrors()}
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
};

export default Status;
