import React from "react";
import { Button } from "@material-ui/core";
import { app } from "../base";
import axios from "axios";
import { API_URL } from "../config";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false};
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    if(typeof error == "object") {
      try {
        error = error.toString();  
      } catch (e) {
        error = "Unable to parse error";
      }
    }

    info = info.componentStack;

    if(window.location.href.indexOf('localhost') == -1) {
      axios({
        method: 'post',
        url: API_URL + 'log-error',
        data: {
          error: error,
          errorInfo: info
        },
        timeout: 10000,
        withCredentials: true
      });
    }

    if(window.location.href.indexOf('reload') == -1) {
      window.location.href = window.location.href + "?reload=true"
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div className="container">
            <i
              style={{ fontSize: "3em", color: "#282bf6" }}
              className="fal fa-skull-crossbones"
            ></i>
            <h2>Ooops! Something went wrong.</h2>
            <Button
              onClick={() => (window.location.href = "/")}
              type="submit"
              style={{ width: "48%", background: "#282bf6" }}
              variant="contained"
              color="primary"
              className="btn"
            >
              Return
            </Button>
            <h2></h2>
            <Button
              onClick={() => {
                localStorage.clear();
                axios.defaults.headers.common['Authorization'] = null;
                app.auth().signOut().then(success => {
                  window.location.href = "/";
                });
              }}
              style={{ width: "48%" }}
              color="primary"
              className="btn"
            >
              Log Out
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
