import { AddressInterface } from "interfaces/Address";

const DefaultState: {
  loading: boolean,
  all: AddressInterface[],
  errorMsg: string,
} = {
  loading: false,
  all: [],
  errorMsg: "",
};

export const addressReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case "GET_ADDRESSES_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_ADDRESSES_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Eror Loading ADDRESSES",
      };
    case "GET_ADDRESSES_SUCCESS":
      return {
        ...state,
        loading: false,
        all: action.payload,
        errMsg: "",
      };
    default:
      return state;
  }
};
