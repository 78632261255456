import _ from "lodash";

export const findShipStatus = (id) => {
  return _.find(shipStatus, (o) => {
    return o.id === id;
  });
};

export const dateFormat = (time) => {
  const removedTime = time.split(" ")[0];
  var date = new Date(removedTime);
  if (!isNaN(date.getTime())) {
    // Months use 0 index.
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }
};

export const validateEmail = (email) => {
  //eslint-disable-next-line
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let testEmail = re.test(String(email).toLowerCase());
  return testEmail;
};

export const shipStatus = [
  {
    id: 10,
    name: "Estimate",
  },
  {
    id: 20,
    name: "Issued",
  },
  {
    id: 25,
    name: "In Progress",
  },
  {
    id: 60,
    name: "Fulfilled",
  },
  {
    id: 70,
    name: "Closed Short",
  },
  {
    id: 80,
    name: "Void",
  },
  {
    id: 85,
    name: "Cancelled",
  },
  {
    id: 90,
    name: "Expired",
  },
];
export const typeId = [
  {
    id: 20,
    name: "Bill To",
  },
  {
    id: 40,
    name: "Home",
  },
  {
    id: 50,
    name: "Main",
  },
  {
    id: 30,
    name: "Remit To",
  },
  {
    id: 10,
    name: "Ship To",
  },
];

type State = {
  id: number,
  code: string,
  countryConstID: number,
  name: string,
}

export const states: State[] = [
  {
    id: 1,
    code: "ZZ",
    countryConstID: 1,
    name: "Unknown",
  },
  {
    id: 2,
    code: "AL",
    countryConstID: 2,
    name: "Alabama",
  },
  {
    id: 3,
    code: "AK",
    countryConstID: 2,
    name: "Alaska",
  },
  {
    id: 4,
    code: "AZ",
    countryConstID: 2,
    name: "Arizona",
  },
  {
    id: 5,
    code: "AR",
    countryConstID: 2,
    name: "Arkansas",
  },
  {
    id: 6,
    code: "CA",
    countryConstID: 2,
    name: "California",
  },
  {
    id: 7,
    code: "CO",
    countryConstID: 2,
    name: "Colorado",
  },
  {
    id: 8,
    code: "CT",
    countryConstID: 2,
    name: "Connecticut",
  },
  {
    id: 9,
    code: "DE",
    countryConstID: 2,
    name: "Delaware",
  },
  {
    id: 10,
    code: "DC",
    countryConstID: 2,
    name: "District of Columbia",
  },
  {
    id: 11,
    code: "FL",
    countryConstID: 2,
    name: "Florida",
  },
  {
    id: 12,
    code: "GA",
    countryConstID: 2,
    name: "Georgia",
  },
  {
    id: 13,
    code: "GU",
    countryConstID: 2,
    name: "Guam",
  },
  {
    id: 14,
    code: "HI",
    countryConstID: 2,
    name: "Hawaii",
  },
  {
    id: 15,
    code: "ID",
    countryConstID: 2,
    name: "Idaho",
  },
  {
    id: 16,
    code: "IL",
    countryConstID: 2,
    name: "Illinois",
  },
  {
    id: 17,
    code: "IN",
    countryConstID: 2,
    name: "Indiana",
  },
  {
    id: 18,
    code: "IA",
    countryConstID: 2,
    name: "Iowa",
  },
  {
    id: 19,
    code: "KS",
    countryConstID: 2,
    name: "Kansas",
  },
  {
    id: 20,
    code: "KY",
    countryConstID: 2,
    name: "Kentucky",
  },
  {
    id: 21,
    code: "LA",
    countryConstID: 2,
    name: "Louisiana",
  },
  {
    id: 22,
    code: "ME",
    countryConstID: 2,
    name: "Maine",
  },
  {
    id: 23,
    code: "MD",
    countryConstID: 2,
    name: "Maryland",
  },
  {
    id: 24,
    code: "MA",
    countryConstID: 2,
    name: "Massachusetts",
  },
  {
    id: 25,
    code: "MI",
    countryConstID: 2,
    name: "Michigan",
  },
  {
    id: 26,
    code: "MN",
    countryConstID: 2,
    name: "Minnesota",
  },
  {
    id: 27,
    code: "MS",
    countryConstID: 2,
    name: "Mississippi",
  },
  {
    id: 28,
    code: "MO",
    countryConstID: 2,
    name: "Missouri",
  },
  {
    id: 29,
    code: "MT",
    countryConstID: 2,
    name: "Montana",
  },
  {
    id: 30,
    code: "NE",
    countryConstID: 2,
    name: "Nebraska",
  },
  {
    id: 31,
    code: "NV",
    countryConstID: 2,
    name: "Nevada",
  },
  {
    id: 32,
    code: "NH",
    countryConstID: 2,
    name: "New Hampshire",
  },
  {
    id: 33,
    code: "NJ",
    countryConstID: 2,
    name: "New Jersey",
  },
  {
    id: 34,
    code: "NM",
    countryConstID: 2,
    name: "New Mexico",
  },
  {
    id: 35,
    code: "NY",
    countryConstID: 2,
    name: "New York",
  },
  {
    id: 36,
    code: "NC",
    countryConstID: 2,
    name: "North Carolina",
  },
  {
    id: 37,
    code: "ND",
    countryConstID: 2,
    name: "North Dakota",
  },
  {
    id: 38,
    code: "MP",
    countryConstID: 2,
    name: "Northern Mariana Islands",
  },
  {
    id: 39,
    code: "OH",
    countryConstID: 2,
    name: "Ohio",
  },
  {
    id: 40,
    code: "OK",
    countryConstID: 2,
    name: "Oklahoma",
  },
  {
    id: 41,
    code: "OR",
    countryConstID: 2,
    name: "Oregon",
  },
  {
    id: 42,
    code: "PA",
    countryConstID: 2,
    name: "Pennsylvania",
  },
  {
    id: 43,
    code: "RI",
    countryConstID: 2,
    name: "Rhode Island",
  },
  {
    id: 44,
    code: "SC",
    countryConstID: 2,
    name: "South Carolina",
  },
  {
    id: 45,
    code: "SD",
    countryConstID: 2,
    name: "South Dakota",
  },
  {
    id: 46,
    code: "TN",
    countryConstID: 2,
    name: "Tennessee",
  },
  {
    id: 47,
    code: "TX",
    countryConstID: 2,
    name: "Texas",
  },
  {
    id: 48,
    code: "UT",
    countryConstID: 2,
    name: "Utah",
  },
  {
    id: 49,
    code: "VT",
    countryConstID: 2,
    name: "Vermont",
  },
  {
    id: 50,
    code: "VA",
    countryConstID: 2,
    name: "Virginia",
  },
  {
    id: 51,
    code: "VI",
    countryConstID: 2,
    name: "Virgin Islands, U.S.",
  },
  {
    id: 52,
    code: "WA",
    countryConstID: 2,
    name: "Washington",
  },
  {
    id: 53,
    code: "WV",
    countryConstID: 2,
    name: "West Virginia",
  },
  {
    id: 54,
    code: "WI",
    countryConstID: 2,
    name: "Wisconsin",
  },
  {
    id: 55,
    code: "WY",
    countryConstID: 2,
    name: "Wyoming",
  },
  {
    id: 56,
    code: "XX",
    countryConstID: 2,
    name: "Unknown State",
  },
  {
    id: 57,
    code: "AB",
    countryConstID: 3,
    name: "Alberta",
  },
  {
    id: 58,
    code: "BC",
    countryConstID: 3,
    name: "British Columbia",
  },
  {
    id: 59,
    code: "MB",
    countryConstID: 3,
    name: "Manitoba",
  },
  {
    id: 60,
    code: "NB",
    countryConstID: 3,
    name: "New Brunswick",
  },
  {
    id: 61,
    code: "NL",
    countryConstID: 3,
    name: "Newfoundland",
  },
  {
    id: 62,
    code: "NT",
    countryConstID: 3,
    name: "Northwest Territories",
  },
  {
    id: 63,
    code: "NS",
    countryConstID: 3,
    name: "Nova Scotia",
  },
  {
    id: 64,
    code: "NU",
    countryConstID: 3,
    name: "Nunavut",
  },
  {
    id: 65,
    code: "ON",
    countryConstID: 3,
    name: "Ontario",
  },
  {
    id: 66,
    code: "PE",
    countryConstID: 3,
    name: "Prince Edward Island",
  },
  {
    id: 67,
    code: "QC",
    countryConstID: 3,
    name: "Quebec",
  },
  {
    id: 68,
    code: "SK",
    countryConstID: 3,
    name: "Saskatchewan",
  },
  {
    id: 69,
    code: "YT",
    countryConstID: 3,
    name: "Yukon",
  },
  {
    id: 70,
    code: "ACT",
    countryConstID: 16,
    name: "Australian Capital Territory",
  },
  {
    id: 71,
    code: "NSW",
    countryConstID: 16,
    name: "New South Wales",
  },
  {
    id: 72,
    code: "NT",
    countryConstID: 16,
    name: "Northern Territory",
  },
  {
    id: 73,
    code: "QLD",
    countryConstID: 16,
    name: "Queensland",
  },
  {
    id: 74,
    code: "SA",
    countryConstID: 16,
    name: "South Australia",
  },
  {
    id: 75,
    code: "TAS",
    countryConstID: 16,
    name: "Tasmania",
  },
  {
    id: 76,
    code: "VIC",
    countryConstID: 16,
    name: "Victoria",
  },
  {
    id: 77,
    code: "WA",
    countryConstID: 16,
    name: "Western Australia",
  },
  {
    id: 81,
    code: "",
    countryConstID: 76,
    name: "Guayas",
  },
  {
    id: 82,
    code: "BCS",
    countryConstID: 10,
    name: "Baja California Sur",
  },
  {
    id: 83,
    code: "AA",
    countryConstID: 2,
    name: "U.S. Armed Forces - Americas",
  },
  {
    id: 84,
    code: "AE",
    countryConstID: 2,
    name: "U.S. Armed Forces - Europe",
  },
  {
    id: 85,
    code: "AP",
    countryConstID: 2,
    name: "U.S. Armed Forces - Pacific",
  },
  {
    id: 89,
    code: "QR",
    countryConstID: 10,
    name: "QR",
  },
  {
    id: 90,
    code: "SEL",
    countryConstID: 139,
    name: "Selangor",
  },
];

export type Country = {
  id: number,
  abbreviation: string,
  name: string,
  ups: string,
}

export const countries: Country[] = [
  {
    id: 1,
    abbreviation: "Unknown",
    name: "Unknown",
    ups: "false",
  },
  {
    id: 2,
    abbreviation: "US",
    name: "UNITED STATES",
    ups: "true",
  },
  {
    id: 3,
    abbreviation: "CA",
    name: "CANADA",
    ups: "true",
  },
  {
    id: 4,
    abbreviation: "GB",
    name: "UNITED KINGDOM",
    ups: "true",
  },
  {
    id: 5,
    abbreviation: "RU",
    name: "RUSSIAN FEDERATION",
    ups: "true",
  },
  {
    id: 6,
    abbreviation: "CN",
    name: "CHINA",
    ups: "true",
  },
  {
    id: 7,
    abbreviation: "JP",
    name: "JAPAN",
    ups: "true",
  },
  {
    id: 8,
    abbreviation: "KR",
    name: "KOREA, REPUBLIC OF",
    ups: "true",
  },
  {
    id: 9,
    abbreviation: "KP",
    name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    ups: "true",
  },
  {
    id: 10,
    abbreviation: "MX",
    name: "MEXICO",
    ups: "true",
  },
  {
    id: 11,
    abbreviation: "DE",
    name: "GERMANY",
    ups: "true",
  },
  {
    id: 12,
    abbreviation: "ES",
    name: "SPAIN",
    ups: "true",
  },
  {
    id: 13,
    abbreviation: "FR",
    name: "FRANCE",
    ups: "true",
  },
  {
    id: 14,
    abbreviation: "FI",
    name: "FINLAND",
    ups: "true",
  },
  {
    id: 15,
    abbreviation: "IS",
    name: "ICELAND",
    ups: "true",
  },
  {
    id: 16,
    abbreviation: "AU",
    name: "AUSTRALIA",
    ups: "true",
  },
  {
    id: 17,
    abbreviation: "AF",
    name: "AFGHANISTAN",
    ups: "true",
  },
  {
    id: 18,
    abbreviation: "AX",
    name: "ALAND ISLANDS",
    ups: "true",
  },
  {
    id: 19,
    abbreviation: "AL",
    name: "ALBANIA",
    ups: "true",
  },
  {
    id: 20,
    abbreviation: "DZ",
    name: "ALGERIA",
    ups: "true",
  },
  {
    id: 21,
    abbreviation: "AS",
    name: "AMERICAN SAMOA",
    ups: "true",
  },
  {
    id: 22,
    abbreviation: "AD",
    name: "ANDORRA",
    ups: "true",
  },
  {
    id: 23,
    abbreviation: "AO",
    name: "ANGOLA",
    ups: "true",
  },
  {
    id: 24,
    abbreviation: "AI",
    name: "ANGUILLA",
    ups: "true",
  },
  {
    id: 25,
    abbreviation: "AQ",
    name: "ANTARCTICA",
    ups: "true",
  },
  {
    id: 26,
    abbreviation: "AG",
    name: "ANTIGUA AND BARBUDA",
    ups: "true",
  },
  {
    id: 27,
    abbreviation: "AR",
    name: "ARGENTINA",
    ups: "true",
  },
  {
    id: 28,
    abbreviation: "AM",
    name: "ARMENIA",
    ups: "true",
  },
  {
    id: 29,
    abbreviation: "AW",
    name: "ARUBA",
    ups: "true",
  },
  {
    id: 30,
    abbreviation: "AT",
    name: "AUSTRIA",
    ups: "true",
  },
  {
    id: 31,
    abbreviation: "AZ",
    name: "AZERBAIJAN",
    ups: "true",
  },
  {
    id: 32,
    abbreviation: "BS",
    name: "BAHAMAS",
    ups: "true",
  },
  {
    id: 33,
    abbreviation: "BH",
    name: "BAHRAIN",
    ups: "true",
  },
  {
    id: 34,
    abbreviation: "BD",
    name: "BANGLADESH",
    ups: "true",
  },
  {
    id: 35,
    abbreviation: "BB",
    name: "BARBADOS",
    ups: "true",
  },
  {
    id: 36,
    abbreviation: "BY",
    name: "BELARUS",
    ups: "true",
  },
  {
    id: 37,
    abbreviation: "BE",
    name: "BELGIUM",
    ups: "true",
  },
  {
    id: 38,
    abbreviation: "BZ",
    name: "BELIZE",
    ups: "true",
  },
  {
    id: 39,
    abbreviation: "BJ",
    name: "BENIN",
    ups: "true",
  },
  {
    id: 40,
    abbreviation: "BM",
    name: "BERMUDA",
    ups: "true",
  },
  {
    id: 41,
    abbreviation: "BT",
    name: "BHUTAN",
    ups: "true",
  },
  {
    id: 42,
    abbreviation: "BO",
    name: "BOLIVIA",
    ups: "true",
  },
  {
    id: 43,
    abbreviation: "BA",
    name: "BOSNIA AND HERZEGOVINA",
    ups: "true",
  },
  {
    id: 44,
    abbreviation: "BW",
    name: "BOTSWANA",
    ups: "true",
  },
  {
    id: 45,
    abbreviation: "BV",
    name: "BOUVET ISLAND",
    ups: "true",
  },
  {
    id: 46,
    abbreviation: "BR",
    name: "BRAZIL",
    ups: "true",
  },
  {
    id: 47,
    abbreviation: "IO",
    name: "BRITISH INDIAN OCEAN TERRITORY",
    ups: "true",
  },
  {
    id: 48,
    abbreviation: "BN",
    name: "BRUNEI DARUSSALAM",
    ups: "true",
  },
  {
    id: 49,
    abbreviation: "BG",
    name: "BULGARIA",
    ups: "true",
  },
  {
    id: 50,
    abbreviation: "BF",
    name: "BURKINA FASO",
    ups: "true",
  },
  {
    id: 51,
    abbreviation: "BI",
    name: "BURUNDI",
    ups: "true",
  },
  {
    id: 52,
    abbreviation: "KH",
    name: "CAMBODIA",
    ups: "true",
  },
  {
    id: 53,
    abbreviation: "CM",
    name: "CAMEROON",
    ups: "true",
  },
  {
    id: 54,
    abbreviation: "CV",
    name: "CAPE VERDE",
    ups: "true",
  },
  {
    id: 55,
    abbreviation: "KY",
    name: "CAYMAN ISLANDS",
    ups: "true",
  },
  {
    id: 56,
    abbreviation: "CF",
    name: "CENTRAL AFRICAN REPUBLIC",
    ups: "true",
  },
  {
    id: 57,
    abbreviation: "TD",
    name: "CHAD",
    ups: "true",
  },
  {
    id: 58,
    abbreviation: "CL",
    name: "CHILE",
    ups: "true",
  },
  {
    id: 59,
    abbreviation: "CX",
    name: "CHRISTMAS ISLAND",
    ups: "true",
  },
  {
    id: 60,
    abbreviation: "CC",
    name: "COCOS (KEELING) ISLANDS",
    ups: "true",
  },
  {
    id: 61,
    abbreviation: "CO",
    name: "COLOMBIA",
    ups: "true",
  },
  {
    id: 62,
    abbreviation: "KM",
    name: "COMOROS",
    ups: "true",
  },
  {
    id: 63,
    abbreviation: "CG",
    name: "CONGO",
    ups: "true",
  },
  {
    id: 64,
    abbreviation: "CD",
    name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    ups: "true",
  },
  {
    id: 65,
    abbreviation: "CK",
    name: "COOK ISLANDS",
    ups: "true",
  },
  {
    id: 66,
    abbreviation: "CR",
    name: "COSTA RICA",
    ups: "true",
  },
  {
    id: 67,
    abbreviation: "CI",
    name: "COTE D'IVOIRE",
    ups: "true",
  },
  {
    id: 68,
    abbreviation: "HR",
    name: "CROATIA",
    ups: "true",
  },
  {
    id: 69,
    abbreviation: "CU",
    name: "CUBA",
    ups: "true",
  },
  {
    id: 70,
    abbreviation: "CY",
    name: "CYPRUS",
    ups: "true",
  },
  {
    id: 71,
    abbreviation: "CZ",
    name: "CZECH REPUBLIC",
    ups: "true",
  },
  {
    id: 72,
    abbreviation: "DK",
    name: "DENMARK",
    ups: "true",
  },
  {
    id: 73,
    abbreviation: "DJ",
    name: "DJIBOUTI",
    ups: "true",
  },
  {
    id: 74,
    abbreviation: "DM",
    name: "DOMINICA",
    ups: "true",
  },
  {
    id: 75,
    abbreviation: "DO",
    name: "DOMINICAN REPUBLIC",
    ups: "true",
  },
  {
    id: 76,
    abbreviation: "EC",
    name: "ECUADOR",
    ups: "true",
  },
  {
    id: 77,
    abbreviation: "EG",
    name: "EGYPT",
    ups: "true",
  },
  {
    id: 78,
    abbreviation: "SV",
    name: "EL SALVADOR",
    ups: "true",
  },
  {
    id: 79,
    abbreviation: "GQ",
    name: "EQUATORIAL GUINEA",
    ups: "true",
  },
  {
    id: 80,
    abbreviation: "ER",
    name: "ERITREA",
    ups: "true",
  },
  {
    id: 81,
    abbreviation: "EE",
    name: "ESTONIA",
    ups: "true",
  },
  {
    id: 82,
    abbreviation: "ET",
    name: "ETHIOPIA",
    ups: "true",
  },
  {
    id: 83,
    abbreviation: "FK",
    name: "FALKLAND ISLANDS (MALVINAS)",
    ups: "true",
  },
  {
    id: 84,
    abbreviation: "FO",
    name: "FAROE ISLANDS",
    ups: "true",
  },
  {
    id: 85,
    abbreviation: "FJ",
    name: "FIJI",
    ups: "true",
  },
  {
    id: 86,
    abbreviation: "GF",
    name: "FRENCH GUIANA",
    ups: "true",
  },
  {
    id: 87,
    abbreviation: "PF",
    name: "FRENCH POLYNESIA",
    ups: "true",
  },
  {
    id: 88,
    abbreviation: "TF",
    name: "FRENCH SOUTHERN TERRITORIES",
    ups: "true",
  },
  {
    id: 89,
    abbreviation: "GA",
    name: "GABON",
    ups: "true",
  },
  {
    id: 90,
    abbreviation: "GM",
    name: "GAMBIA",
    ups: "true",
  },
  {
    id: 91,
    abbreviation: "GE",
    name: "GEORGIA",
    ups: "true",
  },
  {
    id: 92,
    abbreviation: "GH",
    name: "GHANA",
    ups: "true",
  },
  {
    id: 93,
    abbreviation: "GI",
    name: "GIBRALTAR",
    ups: "true",
  },
  {
    id: 94,
    abbreviation: "GR",
    name: "GREECE",
    ups: "true",
  },
  {
    id: 95,
    abbreviation: "GL",
    name: "GREENLAND",
    ups: "true",
  },
  {
    id: 96,
    abbreviation: "GD",
    name: "GRENADA",
    ups: "true",
  },
  {
    id: 97,
    abbreviation: "GP",
    name: "GUADELOUPE",
    ups: "true",
  },
  {
    id: 98,
    abbreviation: "GU",
    name: "GUAM",
    ups: "true",
  },
  {
    id: 99,
    abbreviation: "GT",
    name: "GUATEMALA",
    ups: "true",
  },
  {
    id: 100,
    abbreviation: "GG",
    name: "GUERNSEY",
    ups: "true",
  },
  {
    id: 101,
    abbreviation: "GN",
    name: "GUINEA",
    ups: "true",
  },
  {
    id: 102,
    abbreviation: "GW",
    name: "GUINEA-BISSAU",
    ups: "true",
  },
  {
    id: 103,
    abbreviation: "GY",
    name: "GUYANA",
    ups: "true",
  },
  {
    id: 104,
    abbreviation: "HT",
    name: "HAITI",
    ups: "true",
  },
  {
    id: 105,
    abbreviation: "HM",
    name: "HEARD ISLAND AND MCDONALD ISLANDS",
    ups: "true",
  },
  {
    id: 106,
    abbreviation: "VA",
    name: "HOLY SEE (VATICAN CITY STATE)",
    ups: "true",
  },
  {
    id: 107,
    abbreviation: "HN",
    name: "HONDURAS",
    ups: "true",
  },
  {
    id: 108,
    abbreviation: "HK",
    name: "HONG KONG",
    ups: "true",
  },
  {
    id: 109,
    abbreviation: "HU",
    name: "HUNGARY",
    ups: "true",
  },
  {
    id: 110,
    abbreviation: "IN",
    name: "INDIA",
    ups: "true",
  },
  {
    id: 111,
    abbreviation: "ID",
    name: "INDONESIA",
    ups: "true",
  },
  {
    id: 112,
    abbreviation: "IR",
    name: "IRAN, ISLAMIC REPUBLIC OF",
    ups: "true",
  },
  {
    id: 113,
    abbreviation: "IQ",
    name: "IRAQ",
    ups: "true",
  },
  {
    id: 114,
    abbreviation: "IE",
    name: "IRELAND",
    ups: "true",
  },
  {
    id: 115,
    abbreviation: "IM",
    name: "ISLE OF MAN",
    ups: "true",
  },
  {
    id: 116,
    abbreviation: "IL",
    name: "ISRAEL",
    ups: "true",
  },
  {
    id: 117,
    abbreviation: "IT",
    name: "ITALY",
    ups: "true",
  },
  {
    id: 118,
    abbreviation: "JM",
    name: "JAMAICA",
    ups: "true",
  },
  {
    id: 119,
    abbreviation: "JE",
    name: "JERSEY",
    ups: "true",
  },
  {
    id: 120,
    abbreviation: "JO",
    name: "JORDAN",
    ups: "true",
  },
  {
    id: 121,
    abbreviation: "KZ",
    name: "KAZAKHSTAN",
    ups: "true",
  },
  {
    id: 122,
    abbreviation: "KE",
    name: "KENYA",
    ups: "true",
  },
  {
    id: 123,
    abbreviation: "KI",
    name: "KIRIBATI",
    ups: "true",
  },
  {
    id: 124,
    abbreviation: "KW",
    name: "KUWAIT",
    ups: "true",
  },
  {
    id: 125,
    abbreviation: "KG",
    name: "KYRGYZSTAN",
    ups: "true",
  },
  {
    id: 126,
    abbreviation: "LA",
    name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    ups: "true",
  },
  {
    id: 127,
    abbreviation: "LV",
    name: "LATVIA",
    ups: "true",
  },
  {
    id: 128,
    abbreviation: "LB",
    name: "LEBANON",
    ups: "true",
  },
  {
    id: 129,
    abbreviation: "LS",
    name: "LESOTHO",
    ups: "true",
  },
  {
    id: 130,
    abbreviation: "LR",
    name: "LIBERIA",
    ups: "true",
  },
  {
    id: 131,
    abbreviation: "LY",
    name: "LIBYAN ARAB JAMAHIRIYA",
    ups: "true",
  },
  {
    id: 132,
    abbreviation: "LI",
    name: "LIECHTENSTEIN",
    ups: "true",
  },
  {
    id: 133,
    abbreviation: "LT",
    name: "LITHUANIA",
    ups: "true",
  },
  {
    id: 134,
    abbreviation: "LU",
    name: "LUXEMBOURG",
    ups: "true",
  },
  {
    id: 135,
    abbreviation: "MO",
    name: "MACAO",
    ups: "true",
  },
  {
    id: 136,
    abbreviation: "MK",
    name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    ups: "true",
  },
  {
    id: 137,
    abbreviation: "MG",
    name: "MADAGASCAR",
    ups: "true",
  },
  {
    id: 138,
    abbreviation: "MW",
    name: "MALAWI",
    ups: "true",
  },
  {
    id: 139,
    abbreviation: "MY",
    name: "MALAYSIA",
    ups: "true",
  },
  {
    id: 140,
    abbreviation: "MV",
    name: "MALDIVES",
    ups: "true",
  },
  {
    id: 141,
    abbreviation: "ML",
    name: "MALI",
    ups: "true",
  },
  {
    id: 142,
    abbreviation: "MT",
    name: "MALTA",
    ups: "true",
  },
  {
    id: 143,
    abbreviation: "MH",
    name: "MARSHALL ISLANDS",
    ups: "true",
  },
  {
    id: 144,
    abbreviation: "MQ",
    name: "MARTINIQUE",
    ups: "true",
  },
  {
    id: 145,
    abbreviation: "MR",
    name: "MAURITANIA",
    ups: "true",
  },
  {
    id: 146,
    abbreviation: "MU",
    name: "MAURITIUS",
    ups: "true",
  },
  {
    id: 147,
    abbreviation: "YT",
    name: "MAYOTTE",
    ups: "true",
  },
  {
    id: 148,
    abbreviation: "FM",
    name: "MICRONESIA, FEDERATED STATES OF",
    ups: "true",
  },
  {
    id: 149,
    abbreviation: "MD",
    name: "MOLDOVA, REPUBLIC OF",
    ups: "true",
  },
  {
    id: 150,
    abbreviation: "MC",
    name: "MONACO",
    ups: "true",
  },
  {
    id: 151,
    abbreviation: "MN",
    name: "MONGOLIA",
    ups: "true",
  },
  {
    id: 152,
    abbreviation: "MS",
    name: "MONTSERRAT",
    ups: "true",
  },
  {
    id: 153,
    abbreviation: "MA",
    name: "MOROCCO",
    ups: "true",
  },
  {
    id: 154,
    abbreviation: "MZ",
    name: "MOZAMBIQUE",
    ups: "true",
  },
  {
    id: 155,
    abbreviation: "MM",
    name: "MYANMAR",
    ups: "true",
  },
  {
    id: 156,
    abbreviation: "NA",
    name: "NAMIBIA",
    ups: "true",
  },
  {
    id: 157,
    abbreviation: "NR",
    name: "NAURU",
    ups: "true",
  },
  {
    id: 158,
    abbreviation: "NP",
    name: "NEPAL",
    ups: "true",
  },
  {
    id: 159,
    abbreviation: "NL",
    name: "NETHERLANDS",
    ups: "true",
  },
  {
    id: 160,
    abbreviation: "AN",
    name: "NETHERLANDS ANTILLES",
    ups: "true",
  },
  {
    id: 161,
    abbreviation: "NC",
    name: "NEW CALEDONIA",
    ups: "true",
  },
  {
    id: 162,
    abbreviation: "NZ",
    name: "NEW ZEALAND",
    ups: "true",
  },
  {
    id: 163,
    abbreviation: "NI",
    name: "NICARAGUA",
    ups: "true",
  },
  {
    id: 164,
    abbreviation: "NE",
    name: "NIGER",
    ups: "true",
  },
  {
    id: 165,
    abbreviation: "NG",
    name: "NIGERIA",
    ups: "true",
  },
  {
    id: 166,
    abbreviation: "NU",
    name: "NIUE",
    ups: "true",
  },
  {
    id: 167,
    abbreviation: "NF",
    name: "NORFOLK ISLAND",
    ups: "true",
  },
  {
    id: 168,
    abbreviation: "MP",
    name: "NORTHERN MARIANA ISLANDS",
    ups: "true",
  },
  {
    id: 169,
    abbreviation: "NO",
    name: "NORWAY",
    ups: "true",
  },
  {
    id: 170,
    abbreviation: "OM",
    name: "OMAN",
    ups: "true",
  },
  {
    id: 171,
    abbreviation: "PK",
    name: "PAKISTAN",
    ups: "true",
  },
  {
    id: 172,
    abbreviation: "PW",
    name: "PALAU",
    ups: "true",
  },
  {
    id: 173,
    abbreviation: "PS",
    name: "PALESTINIAN TERRITORY, OCCUPIED",
    ups: "true",
  },
  {
    id: 174,
    abbreviation: "PA",
    name: "PANAMA",
    ups: "true",
  },
  {
    id: 175,
    abbreviation: "PG",
    name: "PAPUA NEW GUINEA",
    ups: "true",
  },
  {
    id: 176,
    abbreviation: "PY",
    name: "PARAGUAY",
    ups: "true",
  },
  {
    id: 177,
    abbreviation: "PE",
    name: "PERU",
    ups: "true",
  },
  {
    id: 178,
    abbreviation: "PH",
    name: "PHILIPPINES",
    ups: "true",
  },
  {
    id: 179,
    abbreviation: "PN",
    name: "PITCAIRN",
    ups: "true",
  },
  {
    id: 180,
    abbreviation: "PL",
    name: "POLAND",
    ups: "true",
  },
  {
    id: 181,
    abbreviation: "PT",
    name: "PORTUGAL",
    ups: "true",
  },
  {
    id: 182,
    abbreviation: "PR",
    name: "PUERTO RICO",
    ups: "true",
  },
  {
    id: 183,
    abbreviation: "QA",
    name: "QATAR",
    ups: "true",
  },
  {
    id: 184,
    abbreviation: "RE",
    name: "REUNION",
    ups: "true",
  },
  {
    id: 185,
    abbreviation: "RO",
    name: "ROMANIA",
    ups: "true",
  },
  {
    id: 186,
    abbreviation: "RW",
    name: "RWANDA",
    ups: "true",
  },
  {
    id: 187,
    abbreviation: "SH",
    name: "SAINT HELENA",
    ups: "true",
  },
  {
    id: 188,
    abbreviation: "KN",
    name: "SAINT KITTS AND NEVIS",
    ups: "true",
  },
  {
    id: 189,
    abbreviation: "LC",
    name: "SAINT LUCIA",
    ups: "true",
  },
  {
    id: 190,
    abbreviation: "PM",
    name: "SAINT PIERRE AND MIQUELON",
    ups: "true",
  },
  {
    id: 191,
    abbreviation: "VC",
    name: "SAINT VINCENT AND THE GRENADINES",
    ups: "true",
  },
  {
    id: 192,
    abbreviation: "WS",
    name: "SAMOA",
    ups: "true",
  },
  {
    id: 193,
    abbreviation: "SM",
    name: "SAN MARINO",
    ups: "true",
  },
  {
    id: 194,
    abbreviation: "ST",
    name: "SAO TOME AND PRINCIPE",
    ups: "true",
  },
  {
    id: 195,
    abbreviation: "SA",
    name: "SAUDI ARABIA",
    ups: "true",
  },
  {
    id: 196,
    abbreviation: "SN",
    name: "SENEGAL",
    ups: "true",
  },
  {
    id: 197,
    abbreviation: "RS",
    name: "SERBIA AND MONTENEGRO",
    ups: "true",
  },
  {
    id: 198,
    abbreviation: "SC",
    name: "SEYCHELLES",
    ups: "true",
  },
  {
    id: 199,
    abbreviation: "SL",
    name: "SIERRA LEONE",
    ups: "true",
  },
  {
    id: 200,
    abbreviation: "SG",
    name: "SINGAPORE",
    ups: "true",
  },
  {
    id: 201,
    abbreviation: "SK",
    name: "SLOVAKIA",
    ups: "true",
  },
  {
    id: 202,
    abbreviation: "SI",
    name: "SLOVENIA",
    ups: "true",
  },
  {
    id: 203,
    abbreviation: "SB",
    name: "SOLOMON ISLANDS",
    ups: "true",
  },
  {
    id: 204,
    abbreviation: "SO",
    name: "SOMALIA",
    ups: "true",
  },
  {
    id: 205,
    abbreviation: "ZA",
    name: "SOUTH AFRICA",
    ups: "true",
  },
  {
    id: 206,
    abbreviation: "GS",
    name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    ups: "true",
  },
  {
    id: 207,
    abbreviation: "LK",
    name: "SRI LANKA",
    ups: "true",
  },
  {
    id: 208,
    abbreviation: "SD",
    name: "SUDAN",
    ups: "true",
  },
  {
    id: 209,
    abbreviation: "SR",
    name: "SURINAME",
    ups: "true",
  },
  {
    id: 210,
    abbreviation: "SJ",
    name: "SVALBARD AND JAN MAYEN",
    ups: "true",
  },
  {
    id: 211,
    abbreviation: "SZ",
    name: "SWAZILAND",
    ups: "true",
  },
  {
    id: 212,
    abbreviation: "SE",
    name: "SWEDEN",
    ups: "true",
  },
  {
    id: 213,
    abbreviation: "CH",
    name: "SWITZERLAND",
    ups: "true",
  },
  {
    id: 214,
    abbreviation: "SY",
    name: "SYRIAN ARAB REPUBLIC",
    ups: "true",
  },
  {
    id: 215,
    abbreviation: "TW",
    name: "TAIWAN, PROVINCE OF CHINA",
    ups: "true",
  },
  {
    id: 216,
    abbreviation: "TJ",
    name: "TAJIKISTAN",
    ups: "true",
  },
  {
    id: 217,
    abbreviation: "TZ",
    name: "TANZANIA, UNITED REPUBLIC OF",
    ups: "true",
  },
  {
    id: 218,
    abbreviation: "TH",
    name: "THAILAND",
    ups: "true",
  },
  {
    id: 219,
    abbreviation: "TL",
    name: "TIMOR-LESTE",
    ups: "true",
  },
  {
    id: 220,
    abbreviation: "TG",
    name: "TOGO",
    ups: "true",
  },
  {
    id: 221,
    abbreviation: "TK",
    name: "TOKELAU",
    ups: "true",
  },
  {
    id: 222,
    abbreviation: "TO",
    name: "TONGA",
    ups: "true",
  },
  {
    id: 223,
    abbreviation: "TT",
    name: "TRINIDAD AND TOBAGO",
    ups: "true",
  },
  {
    id: 224,
    abbreviation: "TN",
    name: "TUNISIA",
    ups: "true",
  },
  {
    id: 225,
    abbreviation: "TR",
    name: "TURKEY",
    ups: "true",
  },
  {
    id: 226,
    abbreviation: "TM",
    name: "TURKMENISTAN",
    ups: "true",
  },
  {
    id: 227,
    abbreviation: "TC",
    name: "TURKS AND CAICOS ISLANDS",
    ups: "true",
  },
  {
    id: 228,
    abbreviation: "TV",
    name: "TUVALU",
    ups: "true",
  },
  {
    id: 229,
    abbreviation: "UG",
    name: "UGANDA",
    ups: "true",
  },
  {
    id: 230,
    abbreviation: "UA",
    name: "UKRAINE",
    ups: "true",
  },
  {
    id: 231,
    abbreviation: "AE",
    name: "UNITED ARAB EMIRATES",
    ups: "true",
  },
  {
    id: 232,
    abbreviation: "UM",
    name: "UNITED STATES MINOR OUTLYING ISLANDS",
    ups: "true",
  },
  {
    id: 233,
    abbreviation: "UY",
    name: "URUGUAY",
    ups: "true",
  },
  {
    id: 234,
    abbreviation: "UZ",
    name: "UZBEKISTAN",
    ups: "true",
  },
  {
    id: 235,
    abbreviation: "VU",
    name: "VANUATU",
    ups: "true",
  },
  {
    id: 236,
    abbreviation: "VE",
    name: "VENEZUELA",
    ups: "true",
  },
  {
    id: 237,
    abbreviation: "VN",
    name: "VIET NAM",
    ups: "true",
  },
  {
    id: 238,
    abbreviation: "VG",
    name: "VIRGIN ISLANDS, BRITISH",
    ups: "true",
  },
  {
    id: 239,
    abbreviation: "VI",
    name: "VIRGIN ISLANDS, U.S.",
    ups: "true",
  },
  {
    id: 240,
    abbreviation: "WF",
    name: "WALLIS AND FUTUNA",
    ups: "true",
  },
  {
    id: 241,
    abbreviation: "EH",
    name: "WESTERN SAHARA",
    ups: "true",
  },
  {
    id: 242,
    abbreviation: "YE",
    name: "YEMEN",
    ups: "true",
  },
  {
    id: 243,
    abbreviation: "ZM",
    name: "ZAMBIA",
    ups: "true",
  },
  {
    id: 244,
    abbreviation: "ZW",
    name: "ZIMBABWE",
    ups: "true",
  },
];
