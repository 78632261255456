import React, { useContext } from "react";
import { UserContext } from "context/User";
import RecentOrders from "components/Home/RecentOrders";
import PriceList from "components/Home/PriceList";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import repContactBg from "assets/images/reseller-contact-bg.png";
import { makeAxiosCall } from "utils";
import { NewsfeedPostInterface, buildPostList } from "interfaces/Newsfeed";
import Loading from "components/utils/Loading";
import { useNavigate } from "react-router-dom";
import RepToolbox from "./RepToolbox";
import AwaitingApprovals from "./AwaitingApprovals";
import DemoFeedbackOverview from "./DemoFeedbackOverview";
import { logFileDownload } from "utils";
import axios from "axios";
import { useWindowWidth } from "hooks/useWindowSize";

interface PriceListConfig {
    paths: string[],
    id: string,
    modifiedTime: string,
    title: string,
}


const Overview = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [latestPost, setLatestPost] = React.useState<NewsfeedPostInterface>();
    const [priceList, setPriceList] = React.useState<PriceListConfig>();
    const windowWidth = useWindowWidth();

    const fetchPriceList = async () => {
        const pl = await axios.get("https://api.blizzardpro.com/public/Price%20List/config.json");
        setPriceList(pl.data);
    };

    React.useEffect(() => {
        fetchPriceList();
    }, []);

    let isMounted = true;

    React.useEffect(() => {
        fetchPosts();
        return () => {
            isMounted = false;
        };
    }, []);

    const expirationDate: string = React.useMemo(() => {
        if (currentUser && currentUser.company && currentUser.company.approvalStatus && currentUser.company.approvalStatus.expirationDate) {
            return new Date (currentUser.company.approvalStatus.expirationDate).toLocaleDateString();
        } else {
            return "";
        }
    }, [currentUser]);

    const fetchPosts = async () => {
        const response = await makeAxiosCall(
            "get",
            "newsfeed-posts?limit=1",
        ).catch(error => {
            console.error(error);
        });

        if (isMounted) {
            if (response && response.data) {
                setLatestPost(buildPostList(response.data)[0]);
            }
        }
    };

    const adminUser = React.useMemo(() => {
        return currentUser?.accountLevel?.canManageMembers;
    }, [currentUser]);

    const goToWizard = () => {
        window.scrollTo(0, 0);
        navigate("/approval");
    };

    const irisQuoteCard = () => {
        return (
            <Grid item xs={12} md={4} className="overview-card" key={"iris-card"}>
                <div
                    className="card"
                    style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/splash.3b35e912.jpg?alt=media&token=1a70fd1b-e01b-4cf4-8701-1b4aafefc3d8')" }}
                >
                    <div className="overlay"></div>
                    <div className="top-info">
                        <h2>IRiS Quote Builder</h2>
                        <div className="bottom-info">
                            Explore and create IRiS LED video walls
                        </div>

                        <Grid container className="download-box">
                            <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    onClick={() => {
                                        window.open("https://www.irisprovideo.com/tools/quote", "_blank");
                                    }}
                                >
                                    Create a Quote
                                </Button>
                        </Grid>
                    </div>
                </div>
            </Grid>
        );
    };


    const logosCard = () => {
        return (
            <Grid item xs={12} md={4} className="overview-card" key={"logo-card"}>
                <div
                    className="card"
                    style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/logo-bg.jpg?alt=media&token=685a2f24-2094-4b5f-9f83-bff2f3f9fc17')" }}
                >
                    <div className="overlay"></div>
                    <div className="top-info">
                        <h2>Brand Guidelines</h2>
                        <div className="bottom-info">
                            Download Blizzard logos & brand guidelines
                        </div>

                        <Grid container className="download-box">
                            <Grid item className="item">
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    onClick={() => {
                                        window.open("https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/BlizzardLighting_BrandKit.zip?alt=media", "_blank");
                                    }}
                                >
                                    Download Brand Kit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>

                </div>
            </Grid>
        );
    };


    const profilesCard = () => {
        return (
            <Grid item xs={12} md={4} className="overview-card" key={"profiles-card"}>
                <div
                    className="card"
                    style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/fixture-profiles.png?alt=media&token=35b780f7-ca4d-403a-8d45-ebe638bd80a5')" }}
                >
                    <div className="overlay"></div>
                    <div className="top-info">
                        <h2>Fixture Profiles</h2>
                        <div className="bottom-info">
                            Explore our fixture profile library
                        </div>

                        <Grid container className="download-box">
                            <Grid item className="item">
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    component={Link}
                                    to={"/fixtureprofiles"}
                                >
                                    View Profile Files
                                </Button>
                            </Grid>

                            <Grid item className="item">
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    component={Link}
                                    to={"/profile-editor"}
                                >
                                    Create a Profile for At Full
                                </Button>
                            </Grid>

                            <Grid item className="item">
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    onClick={() => {
                                        window.open("https://www.blizzardpro.com/@full", "_blank");
                                    }}
                                >
                                    Download At Full
                                </Button>
                            </Grid>
                        </Grid>
                    </div>

                </div>
            </Grid>
        );
    };

    const quickLinksCard = () => {
        return (
            <Grid item xs={12} className="overview-card" key={"profiles-card"}>
                <div
                    className="card"
                    style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/fixture-profiles.png?alt=media&token=35b780f7-ca4d-403a-8d45-ebe638bd80a5')" }}
                >
                    <div className="overlay"></div>
                    <div className="top-info">
                        <h2>Quick Links</h2>

                        <Grid container className="download-box"  spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        logFileDownload("Price List", priceList.paths[0]);
                                        window.open(priceList.paths[0]);
                                    }}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                >
                                    Download Price List<br />(Effective: {priceList ? priceList.title : "...loading"})
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    onClick={() => {
                                        window.open("https://www.irisprovideo.com/tools/quote", "_blank");
                                    }}
                                >
                                    Create an IRiS Quote
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    onClick={() => {
                                        window.open("https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/BlizzardLighting_BrandKit.zip?alt=media", "_blank");
                                    }}
                                >
                                    Download Brand Kit
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    component={Link}
                                    to={"/fixtureprofiles"}
                                >
                                    View Profile Files
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    component={Link}
                                    to={"/profile-editor"}
                                >
                                    Create a Profile for At Full
                                </Button>
                            </Grid>
                        </Grid>
                    </div>

                </div>
            </Grid>
        );
    };

    const buildIFrame = (post) => {
        let videoUrl = post.videoUrl;

        if (videoUrl.includes("youtube")) {
            let videoId = videoUrl.split("watch?v=")[1];
            if (videoId) {
                if (videoId.includes("&")) {
                    videoId = videoId.split("&")[0];
                }
                videoUrl = `https://youtube.com/embed/${videoId}`;
            }
        } else if (videoUrl.includes("youtu.be")) {
            const videoId = videoUrl.split(".be/")[1];
            if (videoId) {
                videoUrl = `https://youtube.com/embed/${videoId}`;
            }
        } else if (videoUrl.includes("vimeo")) {
            const videoId = videoUrl.split(".com/")[1];
            if (videoId) {
                videoUrl = `https://player.vimeo.com/video/${videoId}`;
            }
        }

        return (
            <div style={{ textAlign: "center", padding: 8 }}>
                <iframe
                    width="460"
                    height="259"
                    src={videoUrl}
                    frameBorder="0"
                    allowFullScreen
                />
            </div>
        );
    };

    const renderNewsfeedCard = () => {
        return (
            <Paper style={{ padding: 15, borderRadius: 10 }} >
                <Grid container alignItems="center">
                    <Grid item xs={6} lg={9}>
                        <h3 className="block-title reveal-text" style={{ padding: "5px" }}>Latest News</h3>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <Link to="newsfeed">
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                className="btn blue"
                            >
                                All News
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                {latestPost ? (
                    <Grid container style={{ overflow: "scroll", maxHeight: 611 }}>
                        {latestPost.title && latestPost.title.length > 0 && (
                            <Grid item xs={12} style={{ fontSize: "2em", fontWeight: "bold" }}>{latestPost.title}</Grid>
                        )}
                        {latestPost.topText && (
                            <Grid item xs={12}>
                                <p
                                    className="content"
                                    dangerouslySetInnerHTML={{ __html: latestPost.topText }}
                                />
                            </Grid>
                        )}
                        {latestPost.imageUrl && latestPost.imageUrl.length > 0 && (
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <img src={latestPost.imageUrl} style={{ maxWidth: "100%" }}></img>
                            </Grid>
                        )}
                        {latestPost.videoUrl && latestPost.videoUrl.length > 0 && (
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                {buildIFrame(latestPost)}
                            </Grid>
                        )}
                        {latestPost.bottomText && (
                            <Grid item xs={12}>
                                <p
                                    className="content"
                                    dangerouslySetInnerHTML={{ __html: latestPost.bottomText }}
                                />
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Loading height="40vh" position="relative" title="Fetching Latest News Post" />
                )}
            </Paper>
        );
    };

    const renderRepCard = () => {
        let rep: any = {
            id: 0,
            name: "Blizzard Lighting",
            contact: {
                email: "orders@blizzardpro.com",
                phoneNumber: "(414) 395-8365",
            }
        };

        if (currentUser.company && currentUser.company.rep) {
            rep = currentUser.company.rep;
        }

        return (
            <Grid item className="overview-card">
                <div
                    className="card"
                    style={{ minHeight: 250, backgroundImage: "url(" + repContactBg + ")" }}
                >
                    <div className="overlay"></div>
                    <div className="top-info">
                        <h2 style={{ textAlign: "center" }}>{rep.contact ? rep.contact.firstName : rep.name}<br /><span style={{ fontSize: ".7em" }}>Representative Contact</span></h2>
                        <div className="bottom-info">
                            <Grid container spacing={3}>
                                <Grid item container justifyContent="center" alignItems="center" style={{ textAlign: "center" }} xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <i className="fa-regular fa-envelope" style={{ fontSize: "1.25em", position: "unset" }}></i>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{!rep.contact.email || rep.contact.email.length === 0 ? "None" : <a href={"mailto:" + rep.contact.email} style={{ color: "white", borderBottom: "none" }}>{rep.contact.email}</a>}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent="center" alignItems="center" style={{ textAlign: "center" }} xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <i className="fa-solid fa-phone" style={{ fontSize: "1.25em", position: "unset" }}></i>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{!rep.contact.phoneNumber || rep.contact.phoneNumber.length === 0 ? "None" : rep.contact.phoneNumber}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Grid>
        );
    };

    if (currentUser) {
        return (
            <Grid container style={{ padding: "10px", margin: "50px auto" }}>
                    {currentUser.company?.approvalStatus?.expirationDate && (
                        <Grid item xs={12} style={{textAlign: "center", display: "flex", alignItems: "center"}}>
                            <Paper style={{ width: "100%", padding: 15, backgroundColor: "orangered", color: "white" }}>
                                <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
                                    <Grid item md={3}>
                                        <Typography variant="h5" style={{ float: "left" }}>
                                            <i className="far fa-circle-exclamation"></i> Action Required
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} style={{ padding: "0 25px" }}>
                                        {adminUser ? (
                                            <p>
                                                Your company is missing required documentation. Please complete the necessary items
                                                by {expirationDate} to avoid losing access
                                                to the reseller portal.
                                            </p>
                                        ) : (
                                            <p>
                                                Your company is missing required documentation. Please have an admin complete the necessary items
                                                by {expirationDate} to avoid losing access
                                                to the reseller portal.
                                            </p>
                                        )}
                                    </Grid>
                                    <Grid item md={2}>
                                        {adminUser && (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={goToWizard}
                                            >
                                                Complete Documents
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} container style={{ padding: "10px" }} spacing={2}>
                        {windowWidth < 700  && <Grid item xs={12}>
                            {quickLinksCard()}
                        </Grid>}
                        {
                            currentUser.company?.companyType == "Reseller" &&
                            <Grid item xs={12}>
                                {renderRepCard()}
                            </Grid>
                        }
                        {
                            currentUser.company?.companyType == "Rep" &&
                            <Grid item xs={12}>
                                <RepToolbox /> 
                            </Grid>
                        }
                        {
                            currentUser.company?.companyType == "Internal" &&
                            <Grid item xs={12}>
                                <AwaitingApprovals />
                            </Grid>
                        }
                        {
                            (currentUser.company?.companyType == "Rep" || currentUser.company?.companyType == "Internal") &&
                            <Grid item xs={12}>
                                <DemoFeedbackOverview />
                            </Grid>
                        }
                        {
                            currentUser.company?.companyType == "Reseller" &&
                            <Grid item xs={12}>
                                {renderNewsfeedCard()}
                            </Grid>
                        }
                    </Grid>

                    <Grid item xs={12} md={6} container style={{ padding: "10px" }} spacing={2}>
                        {windowWidth > 700  && <Grid item xs={12}>
                            {quickLinksCard()}
                        </Grid>}
                        {
                            currentUser.company?.companyType == "Reseller" &&
                            <Grid item xs={12}>
                                <RecentOrders />
                            </Grid>
                        }

                        {
                            (currentUser.company?.companyType == "Rep" || currentUser.company?.companyType == "Internal") &&
                            <Grid item xs={12}>
                                {renderNewsfeedCard()}
                            </Grid>
                        }

                    </Grid>
                </Grid>
        );
    } else return false;
};

export default Overview;
