import { AddressInterface, buildAddress } from "./Address";
import { buildShippingPreference, ShippingPreferenceInterface } from "./ShippingPreference";
import { BuildWebCustomerObject, WebCustomerInterface } from "./WebCustomer";

export interface WebOrderInterface {
    id: number,
    createdAt: Date,
    updatedAt: Date,
    customer?: WebCustomerInterface,
    anonymousCheckoutEmail?: string,
    billingAddress: AddressInterface,
    shippingAddress: AddressInterface,
    financialStatus?: string,
    paidAt: Date,
    fulfillmentStatus?: string,
    fulfilledAt?: Date,
    soNumber?: string,
    subtotal: number,
    shipping: number,
    taxes: number,
    total: number,
    discountCode?: string,
    discountAmount?: number,
    discountNotes?: string,
    discountId?: number,
    paymentMethod: string,
    paymentReferences?: string,
    paymentId?: string,
    refundedAmount?: number,
    taxDetails: Array<TaxDetailsInterface>,
    items: Array<WebOrderItemInterface>,
    shippingPreference?: ShippingPreferenceInterface,
    orderNotes?: string,
    refunds?: WebOrderRefundInterface[]
}

export interface WebOrderItemInterface {
    id: number,
    productId: number,
    quantity: number,
    price: number,
    fulfillmentStatus: string,
    productName?: string,
    productSku?: string
}

export const BuildWebOrderItemObject = (rawData: any): WebOrderItemInterface => {
    return {
        id: rawData.id,
        productId: rawData.product ? rawData.product.id : null,
        quantity: rawData.quantity,
        price: rawData.price,
        fulfillmentStatus: rawData.fulfillment_status,
        productName: rawData.product ? rawData.product.name : rawData.product_name,
        productSku: rawData.product ? rawData.product.sku : rawData.product_sku
    };
};



export const BuildWebOrderObject = (rawOrderData: any, customer?: WebCustomerInterface): WebOrderInterface => {
    const orderItems = [];
    if(rawOrderData.items) {
        for (let ori = 0; ori < rawOrderData.items.length; ori++) {
            const orderItemData = rawOrderData.items[ori];
            const orderItem = BuildWebOrderItemObject(orderItemData);
    
            orderItems.push(orderItem);
        }
    }
    


    // This seems unnes
    const taxDetails = [];

    if(rawOrderData.tax_details) {
        for (let tdi = 0; tdi < rawOrderData.tax_details.length; tdi++) {
            const detail = rawOrderData.tax_details[tdi];
            taxDetails.push(detail); 
        }
    }
    

    return {
        id: rawOrderData.id,
        createdAt: new Date(rawOrderData.created_at),
        updatedAt: new Date(rawOrderData.updated_at),
        customer: customer ? customer : BuildWebCustomerObject(rawOrderData.customer),
        soNumber: rawOrderData.so_number,
        billingAddress: buildAddress(rawOrderData.billing_address),
        shippingAddress: buildAddress(rawOrderData.shipping_address),
        financialStatus: rawOrderData.financial_status,
        paidAt: rawOrderData.paid_at ? new Date(rawOrderData.paid_at) : null,
        fulfillmentStatus: rawOrderData.fulfillment_status,
        fulfilledAt: rawOrderData.fulfilled_at ? new Date(rawOrderData.fulfilled_at) : null,
        subtotal: rawOrderData.subtotal,
        shipping: rawOrderData.shipping,
        taxes: rawOrderData.taxes,
        total: rawOrderData.total,
        discountCode: rawOrderData.discount_code,
        discountAmount: rawOrderData.discount_amount,
        discountNotes: rawOrderData.discount_usages && rawOrderData.discount_usages.length ? rawOrderData.discount_usages[0].discount.notes : "No details provided",
        discountId: rawOrderData.discount_usages && rawOrderData.discount_usages.length ? rawOrderData.discount_usages[0].discount.id : null,
        paymentMethod: rawOrderData.payment_method,
        paymentReferences: rawOrderData.payment_references,
        paymentId: rawOrderData.payment_id,
        refundedAmount: rawOrderData.refunded_amount,
        taxDetails: taxDetails,
        items: orderItems,
        shippingPreference: rawOrderData.shipping_preference ? buildShippingPreference(rawOrderData.shipping_preference) : null,
        orderNotes: rawOrderData.order_notes,
        anonymousCheckoutEmail: rawOrderData.anonymous_checkout_email,
        refunds: BuildWebOrderRefundList(rawOrderData.refunds)
    };
};

export const BuildWebOrdersList = (rawOrderData: Array<any>): Array<WebOrderInterface> => {

    const orderList: Array<WebOrderInterface> = [];

    for (let rodi = 0; rodi < rawOrderData.length; rodi++) {
        const orderData = rawOrderData[rodi];
        orderList.push(BuildWebOrderObject(orderData));
        
    }

    return orderList;
};



export interface TaxDetailsInterface {
    name: string,
    value: string
}

export const WebOrderStatusOptions = [
    "paid",
    "partially_refunded",
    "refunded",
    "fulfilled",
    "unfulfilled",
    "partial",
    "restocked"
]

export interface WebOrderRefundInterface {
    refundType: string,
    amount: string,
    notes: string,
    recalculateTax: boolean,
    keepDiscount?: boolean,
    itemChanges?: WebOrderItemRefundInterface[],
    createdAt?: Date,
    updatedAt?: Date,
    createdBy?: string,
    itemLevelNotes?: string,
    totalRefundAmount?: number,
    newTaxAmount?: number
}

export interface WebOrderItemRefundInterface {
    itemId: number,
    newQuantity: number,
    newPrice: number,
    newPriceString: string,
    originalItem: WebOrderItemInterface
}

const BuildWebOrderRefund = (rawRefundData: any): WebOrderRefundInterface => {
    return {
        refundType: rawRefundData.refund_type,
        amount: rawRefundData.adjustment_amount,
        notes: rawRefundData.notes,
        recalculateTax: rawRefundData.recalculate_tax,
        newTaxAmount: rawRefundData.new_tax_amount,
        createdAt: new Date(rawRefundData.created_at),
        createdBy: rawRefundData.created_by ? rawRefundData.created_by.first_name + " " + rawRefundData.created_by.last_name : "Unknown",
        itemLevelNotes: rawRefundData.item_level_notes,
        totalRefundAmount: rawRefundData.total_refund_amount
    }
}

const BuildWebOrderRefundList = (rawRefundData: Array<any>): WebOrderRefundInterface[] => {
    let refunds = [];
    if(rawRefundData && rawRefundData.length) {
        for (let ri = 0; ri < rawRefundData.length; ri++) {
            const refundData = rawRefundData[ri];
            refunds.push(BuildWebOrderRefund(refundData));
        }
    }
    return refunds;
}

export const EmptyWebOrderRefund: WebOrderRefundInterface = {
    refundType: "order_level_adjustment",
    amount: "0",
    notes: "",
    recalculateTax: true,
    keepDiscount: true,
    itemChanges: []
}

export const WebOrderRefundTypeOptions = [
    {id: "tax_refund", name: "Tax Refund"},
    {id: "shipping_refund", name: "Shipping Refund"},
    {id: "item_level_adjustment", name: "Item Level Adjustment"},
    {id: "order_level_adjustment", name: "Order Level Adjustment"}
]

export interface WebOrderTransactionInterface {
    id: number,
    createdAt: Date,
    anonymousId?: string,
    userId?: string,
    userIpAddress: string, 
    authorizeTransactionId: string,
    authorizeResultCode: number,
    maskedAccountNumber: string,
    errorMessage: string,
    message: string,
    soNumber: string
}

export const BuildWebOrderTransactionObjects = (rawTransactionData: any): WebOrderTransactionInterface[] => {
    if(!rawTransactionData || !rawTransactionData.length) {
        return [];
    }

    let builtTransactionList: WebOrderTransactionInterface[] = []

    for (let tdi = 0; tdi < rawTransactionData.length; tdi++) {
        const singleTransactionData = rawTransactionData[tdi];

        builtTransactionList.push({
            id: singleTransactionData.id,
            createdAt: new Date(singleTransactionData.created_at),
            anonymousId: singleTransactionData.anonymous_user_id,
            userId: singleTransactionData.user_id,
            userIpAddress: singleTransactionData.user_ip_address, 
            authorizeTransactionId: singleTransactionData.authorize_transaction_id,
            authorizeResultCode: singleTransactionData.authorize_result_code,
            maskedAccountNumber: singleTransactionData.authorize_masked_account_number,
            errorMessage: singleTransactionData.authorize_error,
            message: singleTransactionData.authorize_message,
            soNumber: singleTransactionData.so_number
        });
    }

    return builtTransactionList;
}