import React from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import { dateFormat } from "temp";
import { stringToMoney } from "utils";
import { FishbowlSalesOrderInterface, FishbowlSalesOrderItemInterface } from "interfaces/FishbowlSalesOrder";
import { useWindowWidth } from "hooks/useWindowSize";

interface OrderSummaryProps {
  sois?: FishbowlSalesOrderItemInterface[]
}

const OrderSummary = ({ sois: so }: OrderSummaryProps) => {
  const [soItems, setSoItems] = React.useState<FishbowlSalesOrderItemInterface[]>([]);
  const windowWidth = useWindowWidth();

  React.useEffect(() => {
    let items = [];
    _.orderBy(so, ["soLineItem"], ["asc"]).forEach((item) => {
      if (!items.some((stored) => stored.soLineItem == item.soLineItem)) {
        items.push(item);
      }
    });
    setSoItems(items);
  }, [so]);

  const renderOrders = () => {
    if(windowWidth < 700) {
      return renderOrdersForMobile();
    }
    if (so.length > 0) {
      return _.map(
        _.orderBy(soItems, ["soLineItem"], ["asc"]),
        (o, i) => {
          return (
            <Grid container className="line-item" key={`${o.soLineItem}`} style={{padding: "0 20px"}}>
              <Grid item sm={1}>
                <p>{o.type}</p>
              </Grid>
              <Grid item sm={2}>
                <p style={{ fontWeight: "bold" }}>{o.productNum}</p>
              </Grid>
              <Grid item sm={4}>
                <p>{o.description}</p>
              </Grid>
              <Grid item sm={1}>
                <p>{stringToMoney(o.unitPrice)}</p>
              </Grid>
              <Grid item sm={1}>
                <p>{parseInt(o.qtyOrdered, 10)}</p>
              </Grid>
              <Grid item sm={2}>
                <p style={{ fontWeight: "bold" }}>
                  {stringToMoney(o.itemTotalPrice)}
                </p>
              </Grid>
            </Grid>
          );
        }
      );
    }
  };


  const renderOrdersForMobile = () => {
    if (so.length > 0) {
      return _.map(
        _.orderBy(soItems, ["soLineItem"], ["asc"]),
        (o, i) => {
          return (
            <Grid container className="line-item" key={`${o.soLineItem}`} style={{padding: "0 20px"}}>
              <Grid item xs={10}>
                <p style={{ fontWeight: "bold" }}>{o.productNum}</p>
              </Grid>
              <Grid item xs={2}>
                <p>{o.type}</p>
              </Grid>
              <Grid item xs={6}>
                <p>{stringToMoney(o.unitPrice)} x {parseInt(o.qtyOrdered, 10)} = </p>
              </Grid>
              <Grid item sm={6}>
                <p style={{ fontWeight: "bold" }}>
                  {stringToMoney(o.itemTotalPrice)}
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>{o.description}</p>
              </Grid>
            </Grid>
          );
        }
      );
    }
  };

  if (so) {
    return (
      <div
        style={{ backgroundColor: "#dddddd", padding: 40, borderRadius: 10, marginTop: 20 }}
      >
        <Grid container={true} className="order-meta flex">
          <Grid item={true} xs={12} md={10} className="col" style={{ marginBottom: 30 }}>
            <h3
              style={{
                borderBottom: "1px solid gray",
                margin: 0,
                marginBottom: 10,
                display: "table",
              }}
            >
              PO #
            </h3>
            <h1 style={{ fontWeight: 900, margin: 0 }}>{so[0].customerPO ?? so[0].num}</h1>
          </Grid>
          <Grid item xs={12} md={2} className="col" style={{ marginBottom: 30 }}>
            <h3
              style={{
                borderBottom: "1px solid gray",
                margin: 0,
                marginBottom: 10,
                display: "table",
              }}
            >
              Date Created
            </h3>
            <h3 style={{ fontWeight: 900, margin: 0 }}>
              {dateFormat(so[0].dateCreated)}
            </h3>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 30 }} spacing={2}>
          <Grid
            item
            md={4}
            xs={12}
            style={{
              background: "white",
              padding: 30,
              borderRadius: 8,
              margin: 10,
            }}
          >
            <h4 style={{ margin: 0 }}>Bill To</h4>
            <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
              <li>{so[0].customerContact}</li>
              <li>{so[0].billToName}</li>
              <li>{so[0].billToAddress}</li>
              <li>
                {so[0].billToCity}, {so[0].billStateCode} {so[0].billToZip}
              </li>
            </ul>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            style={{ background: "white", padding: 30, borderRadius: 8, margin: 10 }}
          >
            <h4 style={{ margin: 0 }}>Ship To</h4>
            <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
              <li>{so[0].customerContact}</li>
              <li>{so[0].shipToName}</li>
              <li>{so[0].shipToAddress}</li>
              <li>
                {so[0].shipToCity}, {so[0].shipStateCode} {so[0].shipToZip}
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6} md={2} className="so-box">
            <p
              style={{
                background: "#383838",
                color: "white",
                padding: 10,
                borderRadius: 5,
                marginRight: 10,
                fontWeight: "bold",
              }}
            >
              Sales Rep
            </p>
            <h4 style={{ margin: 0, paddingLeft: 10 }}>{so[0].salesman}</h4>
          </Grid>
          <Grid item xs={6} md={2} className="so-box">
            <p
              style={{
                background: "#383838",
                color: "white",
                padding: 10,
                borderRadius: 5,
                marginRight: 10,
                fontWeight: "bold",
              }}
            >
              Payment Terms
            </p>
            <h4>{so[0].paymentTerms}</h4>
          </Grid>
          <Grid item xs={6} md={2} className="so-box">
            <p
              style={{
                background: "#383838",
                color: "white",
                padding: 10,
                borderRadius: 5,
                marginRight: 10,
                fontWeight: "bold",
              }}
            >
              FOB Point
            </p>
            <h4>{so[0].fobpoint}</h4>
          </Grid>
          <Grid item xs={6} md={2} className="so-box">
            <p
              style={{
                background: "#383838",
                color: "white",
                padding: 10,
                borderRadius: 5,
                marginRight: 10,
                fontWeight: "bold",
              }}
            >
              Carrier
            </p>
            <h4>{so[0].carrier}</h4>
          </Grid>
          <Grid item xs={6} md={2} className="so-box">
            <p
              style={{
                background: "#383838",
                color: "white",
                padding: 10,
                borderRadius: 5,
                marginRight: 10,
                fontWeight: "bold",
              }}
            >
              Ship Service
            </p>
            <h4>{so[0].carrierservice ? so[0].carrierservice : "N/A"}</h4>
          </Grid>
          <Grid item xs={6} md={2} className="so-box">
            <p
              style={{
                background: "#383838",
                color: "white",
                padding: 10,
                borderRadius: 5,
                marginRight: 10,
                fontWeight: "bold",
              }}
            >
              Date Scheduled
            </p>
            <h4>{dateFormat(so[0].dateScheduledFulfillment)}</h4>
          </Grid>
        </Grid>

        {windowWidth > 700 && (<Grid container className="row-heads">
          <Grid item sm={1}>
            <p>Type</p>
          </Grid>
          <Grid item sm={2}>
            <p>Name</p>
          </Grid>
          <Grid item sm={4}>
            <p>Description</p>
          </Grid>
          <Grid item sm={1}>
            <p>Unit Price</p>
          </Grid>
          <Grid item sm={1}>
            <p>Qty</p>
          </Grid>
          <Grid item sm={2}>
            <p>Total</p>
          </Grid>
        </Grid>)}

        {renderOrders()}

        <Grid container justifyContent="flex-end">
          <Grid
            item
            xs={12}
            md={4}
            style={{ background: "white", padding: 30, borderRadius: 8 }}
          >
            <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
              <li
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <strong>Subtotal</strong> {stringToMoney(so[0].subTotal)}
              </li>
              <li
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <strong>Tax</strong> {stringToMoney(so[0].totalTax)}
              </li>
              <li
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <strong>Total</strong>{" "}
                {stringToMoney(
                  parseFloat(so[0].totalTax) + parseFloat(so[0].subTotal)
                )}
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <h3>No order info</h3>
      </div>
    );
  }
};

export default OrderSummary;
