import React, { useState } from "react";
import Login from "components/Auth/Login";
import SignUp from "components/Auth/SignUp";
import ForgotPassword from "components/Auth/ForgotPassword";

import snowflake from "assets/images/logo-blue.svg";
import splash from "assets/images/splash.jpg";
import firebase from "firebase/app";
import { makeAxiosCall } from "utils";

const AuthPortal = () => {
    const [activeAuth, setActiveAuth] = useState("login");

    React.useEffect(() => {
        makeAxiosCall('get', 'bear').then((response) => {
            if(!response || !response.data || !response.data.bear) {
                console.log('no bear');
                return;
            } else {
                const bear = response.data.bear;
            
                firebase.auth().signInWithCustomToken(bear).catch((e) => {
                    console.log(e);
                });
            }
        });
        
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get("type");
        if (type) {
            setActiveAuth(type);
        }
    }, []);

    function renderAuthPanel() {
        if (activeAuth === "login") {
            return <Login setActiveAuth={setActiveAuth} />;
        } else if (activeAuth === "signup") {
            return <SignUp setActiveAuth={setActiveAuth} />;
        } else if (activeAuth === "forgot") {
            return <ForgotPassword setActiveAuth={setActiveAuth} />;
        }
    }

    return (
        <div className="auth-portal">
            <div className="auth">
                <div
                    className="badge"
                    style={{
                        width: "340px",
                        display: "flex",
                        justifyContent: "align-center",
                    }}
                >
                    <img style={{ width: 50 }} src={snowflake} alt="Blizzard Lighting" />
                    <h1>Reseller Portal</h1>
                </div>
                {renderAuthPanel()}
            </div>
            <div
                className="splash"
                style={{
                    backgroundImage: "url(" + splash + ")",
                    backgroundSize: "cover",
                }}
            ></div>
        </div>
    );
};

export default AuthPortal;
