import React, { useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import Loading from "components/utils/Loading";
import { UserContext } from "context/User";
import { List, WindowScroller } from "react-virtualized";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, Tooltip } from "@material-ui/core";
import { makeAxiosCall } from "utils";
import PendingOrderResult from "./PendingOrderResult";
import { BuildOrdersList, OrderInterface, OrderStatusInterface } from "interfaces/Order";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { buildCompanyInfo, CompanyInterface } from "interfaces/Company";
import 'scss/components/Orders/PendingOrders.scss';
import { CartContext } from "context/Cart";
import { Link } from "react-router-dom";


const PendingOrders = () => {
  const { currentUser, internalUser } = useContext(UserContext);
  const { orderStatusOptions } = React.useContext(CartContext);
  const [input, setInput] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState<string>(null);
  const [orders, setOrders] = useState<OrderInterface[]>();
  const [loading, setLoading] = React.useState(true);
  const [showDemoList, setShowDemoList] = React.useState(false);
  const [showDemoListButton, setShowDemoListButton] = React.useState(false);
  const [showHelpRequestList, setShowHelpRequestList] = React.useState(false);
  const [showHelpRequestListButton, setShowHelpRequestListButton] = React.useState(false);
  const [orderHelpRequestedList, setOrderHelpRequestedList] = React.useState<Array<OrderInterface>>([]);
  const [potentialCompanyNames, setPotentialCompanyNames] = React.useState<Array<string>>([]);
  // const [potentialCompanies, setPotentialCompanies] = React.useState<Array<{name: string, id: number}>>([]);
  const [orderStatusFilters, setOrderStatusFilters] = React.useState<string[]>([]);
  const [filteredList, setFilteredList] = React.useState<OrderInterface[]>([]);
  const [showFilters, setShowFilters] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [sortMethods, setSortMethods] = React.useState<string[]>([]);

  const windowScrollerRef = React.useRef<WindowScroller>();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 800,
        width: 250,
      },
    },
  };

  const sortOptions = [
    {
      label: "PO Number (A - Z)",
      value: 'customerPONum',
      order: 'asc',
    },
    {
      label: "PO Number (Z - A)",
      value: 'customerPONum',
      order: 'desc',
    },
    {
      label: "Newest",
      value: 'createdAt',
      order: 'desc',
    },
    {
      label: "Oldest",
      value: 'createdAt',
      order: 'asc',
    },
  ];

  let isMounted = true;

  React.useEffect(() => {
    if(firstLoad) {

      let isInternalUser = currentUser && currentUser.company && currentUser.company.companyType == "Internal";
      const urlParams = new URLSearchParams(window.location.search);
      const showHelp = urlParams.get("help");
      if (showHelp) {
        setShowHelpRequestList(true);
      }

      if (currentUser && isInternalUser) {
        fetchAllCompanies();
        setShowHelpRequestListButton(true);
        setShowDemoListButton(true);
        
      } else if (currentUser && currentUser.company.companyType === "Rep") {
        setShowHelpRequestListButton(true);
        setShowDemoListButton(true);
        // setPotentialCompanies(
        //     currentUser.company.resellers.map((r) => {
        //         return {name: r.name, id: r.id};
        //     })
        // );

        let myCompanies: string[] = currentUser.company.resellers.map((r) => {
            return r.name;
        });
        myCompanies.push(currentUser.company.name);


        const lastCompanySearched = localStorage.getItem("lastCompanyOrders");
        if(lastCompanySearched) {
          setSelectedCustomer(lastCompanySearched);
        }

        setPotentialCompanyNames(myCompanies);
      } else {
        setSelectedCustomer(currentUser.company.name);
      }

      if (currentUser && currentUser.company) {
        getPortalOrders(isInternalUser);
      }

      if (currentUser.company.companyType === "Rep" || isInternalUser) {
        getHelpOrders();
      }
    }

    return () => {
      isMounted = false;
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (orders) {
      filterOrders();
    }
  }, [orders, orderStatusFilters, showDemoList]);

  React.useEffect(() => {
    if (orders) {
      filterOrders();
    } else {
      getPortalOrders(internalUser);
    }
  }, [selectedCustomer]);

  React.useLayoutEffect(() => {
    // Adjust the scroll view when filters are opened or closed
    if (windowScrollerRef && windowScrollerRef.current) {
      windowScrollerRef.current.updatePosition();
    }
  }, [showFilters])

  const getHelpOrders = async () => {
    const res = await makeAxiosCall(
      "get",
      "orders-requested-help",
    );
    if (res.data) {
      const helpList = [];
      for (let hri = 0; hri < res.data.length; hri++) {
        const orderData = res.data[hri];
        helpList.push(
          {
            ...orderData,
            createdAt: new Date(orderData.created_at),
            orderStatus: orderData.order_status,
            customerPONum: orderData.customer_po_num,
            fishbowlSONum: orderData.fishbowl_so_num
          }
        );
        
      }
      setOrderHelpRequestedList(helpList);
    }
  };

  const fetchAllCompanies = () => {
    makeAxiosCall(
      "get",
      "companies/simple"
    ).then(async res => {
      const companyList = [];
      const companyNameList = [];
      for (let ci = 0; ci < res.data.length; ci++) {
        const company = res.data[ci];
        companyList.push({id: company.id, name: company.name});
        companyNameList.push(company.name);
      }
      setPotentialCompanyNames(companyNameList);
      // setPotentialCompanies(companyList);

      const lastCompanySearched = localStorage.getItem("lastCompanyOrders");
      if(lastCompanySearched) {
        selectCustomer(lastCompanySearched);
      }
    })
  }
  
  const getAllPortalOrders = async () => {
    const result = await makeAxiosCall(
      "get",
      `orders`,
    ).catch(error => {
      console.error("Caught error getting pending orders:");
      console.error(error);
    });

    if (result.data) {
      const orderData = BuildOrdersList(result.data, []);
      setOrders(orderData);
    }
    setLoading(false);
  }

  const getPortalOrders = async (isInternalUser: boolean) => {
    setFirstLoad(false);
    if (!isMounted) return;
    if(!selectedCustomer) {
      if(isInternalUser) {
        setFirstLoad(false);
        getAllPortalOrders();
      }
      if(currentUser.company && currentUser.company.companyType == "Rep") {
        // Do anything else here?
      } else {
        return;
      }
    }

    setFirstLoad(false);
    const result = await makeAxiosCall(
      "get",
      `orders/${currentUser.company.id}`,
    ).catch(error => {
      console.error("Caught error getting pending orders:");
      console.error(error);
    });

    if (result.data) {
      let orderData: OrderInterface[];
      if(currentUser.company && currentUser.company.companyType == "Rep") {
        orderData = BuildOrdersList(result.data, [...currentUser.company.resellers, currentUser.company]);
      } else {
        orderData = BuildOrdersList(result.data, [currentUser.company]);
      }
      
      setOrders(orderData);
    }
    setLoading(false);
  }

  const selectCustomer = (companyName) => {
    if(companyName) localStorage.setItem("lastCompanyOrders", companyName);
    else localStorage.removeItem("lastCompanyOrders");
    

    setSelectedCustomer(companyName);
  };

  const sortOrders = (searchResults: OrderInterface[]) => {
    let returnable: OrderInterface[];

    if (sortMethods.length > 0) {
      returnable = _.orderBy(
        searchResults, 
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).value),
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
      );
    } else {
      returnable = _.orderBy(searchResults, (order: OrderInterface) => order.createdAt, ['desc']);
    }

    return returnable;
  }

  const renderSortOptions = () => {
    return (
      <FormControl style={{width: "100%"}}>
        <Select
          key="sortMethods"
          multiple
          value={sortMethods}
          renderValue={(_) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {sortMethods.map((method) => (
                <Chip key={method} label={method} style={{marginRight: 5}} />
              ))}
            </Box>
          )}
          onChange={(e: React.ChangeEvent<{
            name?: string;
            value: string[];
          }>) => {
            setSortMethods(e.target.value);
          }}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
        >
          {sortOptions.map(
            (option) => {
              let index = sortMethods.findIndex((n) => n == option.label);
              return (
                <MenuItem
                  key={option.label}
                  value={option.label}
                >
                  <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                  {option.label}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    );
  }

  const searchResults: OrderInterface[] = React.useMemo(() => {
    const searchInput = input.toLowerCase();

    let results: OrderInterface[] = filteredList;
    if (searchInput.length > 0) {
      results = _.filter(filteredList, (o: OrderInterface) => {
          return o.customerPONum && _.includes(o.customerPONum.toLowerCase(), searchInput);
      });
    }

    return sortOrders(results);
  }, [input, orders, filteredList, sortMethods]);

  const filterOrders = () => {
    // Only apply filters once one is selected
    const applyFilters = orderStatusFilters.length > 0 || showDemoList || selectedCustomer;

    if (applyFilters) {
      let results = [];

      orders.forEach((order) => {
        if (
          (!orderStatusFilters.length || orderStatusFilters.some((status) => status == order.orderStatus.name)) &&
          (!showDemoList || order.demoOrder) &&
          (!selectedCustomer || selectedCustomer == order.company.name)
        ) {
          results.push(order);
        }
      });
      setFilteredList(results);
    } else {
      setFilteredList(orders);
    }

    if (loading) setLoading(false);
  }

  const renderRow = ({ index, key, style }, phoneMode) => {
    let order: OrderInterface = searchResults[index];
    return (
      <div style={style} key={key} >
        <div style={{padding: 5}}>
          <PendingOrderResult order={order} phoneMode={phoneMode} />
        </div>
      </div>
    );
  }
  
  const renderHelpRow = ({ index, key, style }, phoneMode) => {
    let order: OrderInterface = orderHelpRequestedList[index];
    return (
      <div style={style} key={key} >
        <div style={{padding: 5}}>
          <PendingOrderResult order={order} phoneMode={phoneMode} />
        </div>
      </div>
    );
  }

  const renderPlaceholder = () => {
    return (
      <h4 style={{ textAlign: "center", fontSize: 23, fontWeight: "normal" }}>
        {showHelpRequestList ? "No help requests found" : "No pending orders found"}
      </h4>
    );
  }

  const renderOrderStatusFilterOption = (option: OrderStatusInterface) => {
    return (
      <Grid key={option.id} item xs={12} md={4}>
        <Tooltip title={option.name} placement="top" arrow>
          <FormControlLabel
            control={
              <Checkbox 
              checked={orderStatusFilters.some((status) => status === option.name)}
              onChange={(e) => {
                if (orderStatusFilters.some((type) => type === option.name)) {
                  setOrderStatusFilters(orderStatusFilters.filter((type) => type !== option.name));
                } else {
                  setOrderStatusFilters([...orderStatusFilters, option.name]);
                }
              }}
              color="primary"
              size="small"
              />
            }
            label={option.name}
            classes={{label: "search-filter-option"}}
          />
        </Tooltip>
      </Grid>
    );
  }

  const renderOrderStatusFilters = () => {
    return orderStatusOptions.map((type) => {
      return renderOrderStatusFilterOption(type);
    });
  }

  return (
    <div className="admin view">
      <div className="grid-search">
        <Grid container alignItems="center" style={{padding: "10px 0px"}}>

          <Grid item xs={12}>
            <h1 className="reveal-text">{showDemoList ? "Demo Orders" : (showHelpRequestList ? "Help Requests": "Pending Orders")} </h1>
          </Grid>

          <Grid item xs={12} md={4} lg={3} style={{ padding: 10 }}>
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              className="btn"
              component={Link}
              to="/order-history"
            >
              {currentUser && internalUser ? "Fishbowl Orders" : "Accepted Orders"}
            </Button>
          </Grid>

          {showHelpRequestListButton ?
            <Grid item xs={12} md={4} lg={3} style={{ padding: 10 }}>
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                className="btn"
                onClick={() => setShowHelpRequestList(!showHelpRequestList)}
              >
                {showHelpRequestList ? "Show My Pending Orders": "Show Help Request Orders (" + orderHelpRequestedList.length + ")"} 
              </Button>
            </Grid>
          : null}


          {showDemoListButton && !showHelpRequestList ?
            <Grid item xs={12} md={4} lg={3} style={{ padding: 10 }}>
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                className="btn"
                onClick={() => {
                  setLoading(true);
                  setShowDemoList(!showDemoList);
                }}
              >
                {showDemoList ? "Back to Pending Orders": "Show Demo Orders"} 
              </Button>
            </Grid>
          : null}
        </Grid>
        
          
        {showHelpRequestList ? null : <>
          <div className="search-field">
            <TextField
              className="prod-search"
              label="Search for previous orders"
              margin="normal"
              variant="outlined"
              onChange={(e) => setInput(e.target.value)}
              value={input}
              style={{marginRight: 0, width: "100%"}}
            />
          </div>
          <div className="inventory-search" style={{margin: "10px 0"}}>
            {currentUser && internalUser || (currentUser.company && currentUser.company.resellers && currentUser.company.resellers.length > 0) ? (
              <Autocomplete
                id="company-select"
                options={potentialCompanyNames}
                value={selectedCustomer ? selectedCustomer : "All"}
                onChange={(event: any, newValue: string | null) => {
                  if(newValue) {
                    selectCustomer(newValue);
                  } else {
                    selectCustomer(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select Company" />}
              />
            ) : <></>}
          </div>
          <Grid container className="container-spacing">
            <Grid item container alignItems="center" xs={12}>
              <Grid item xs={9}><h3>Filter and Sort {`(${filteredList ? filteredList.length : ""})`}</h3></Grid>
              <Grid item xs={3}>
                <Button
                  variant={showFilters ? "contained" : "outlined"}
                  color={showFilters ? "primary" : "secondary"}
                  onClick={() => setShowFilters(!showFilters)}
                  fullWidth
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
            </Grid>
            {showFilters && (
              <>
                <Grid item container xs={9}>
                  <Grid item container alignContent="flex-start" xs={12}>
                    <Grid item xs={12}>Order Status</Grid>
                    {renderOrderStatusFilters()}
                  </Grid>
                </Grid>
                <Grid item alignContent="flex-start" container xs={3}>
                  <Grid item xs={12}>Sort By</Grid>
                  <Grid item xs={12}>
                    {renderSortOptions()}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </>}

          {loading ? 
            <Loading height="40vh" title="Connecting to Inventory System" position="relative" /> :  
             
            <WindowScroller ref={windowScrollerRef}>
              {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                const phoneMode = width < 500;
                return(
                  <Grid container className="results">
                    <Grid container item xs={12} className="center-text grid-search-headers">
                      <Grid xs={1} item className="result-name">
                        PO
                      </Grid>
                      <Grid item xs={3}>
                        Date Created
                      </Grid>
                      <Grid item xs={4} className="result-name">
                        Customer
                      </Grid>
                      <Grid item xs={4} md={2}>
                        Status
                      </Grid>
                      {!phoneMode && <Grid item xs={2}>
                        View
                      </Grid>}
                    </Grid>
                    <List
                      autoHeight
                      width={width}
                      height={height}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      rowHeight={80}
                      rowRenderer={(props) => showHelpRequestList ? renderHelpRow(props, phoneMode) : renderRow(props, phoneMode)}
                      noRowsRenderer={renderPlaceholder}
                      rowCount={showHelpRequestList ? orderHelpRequestedList.length : searchResults.length}
                      overscanRowCount={3}
                    />
                    </Grid>
                )
              }}
            </WindowScroller>
          }
      </div>
    </div>
  );
}

export default PendingOrders;