import {
    Grid,
    TextField,
    Button,
    InputAdornment,
    CircularProgress,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Snackbar,
    Popper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalBasic from "components/utils/ModalBasic";
import _ from "lodash";
import React from "react";
import ChannelOption from "components/ProfileEditor/ChannelOption";
import { CSSTransition } from "react-transition-group";
import DraggableLists from "components/ProfileEditor/DraggableLists";
import { UserContext } from "context/User";
import { channelOptions, colors } from "components/ProfileEditor/defaultChannelOptions";
import SegmentList from "components/ProfileEditor/SegmentList";
import { generateKey } from "utils";
import DeletePrompt from "components/ProfileEditor/DeletePrompt";
import { DebounceInput } from "react-debounce-input";
import { db } from "base.js";
import axios from "axios";
import { Alert, Color } from "@material-ui/lab";
import { SegmentInterface, ChannelInterface, ProfileInterface, FixtureInterface } from "interfaces/ProfileEditor";

const enum ContinueActions {
    none,
    switch,
    newProfile,
    newFixture,
}

const ProfileEditor = () => {

    const AT_FULL_API_URL = "https://atfullapi.blizzard.lighting";
    // const AT_FULL_API_URL = "http://localhost:4000";

    const IMPORT_MODAL_PROFILE = "profile";
    const IMPORT_MODAL_SEGMENTS = "segments";

    const initialState = {
        brand: "Unknown",
        name: "",
        profiles: [
            {
                name: "",
                channels: [],
            },
        ],
        shared: true,
    };

    const startingFixtureValidation = {
        fixture: "",
        profile: "",
    };

    const initialSnackbarState: {
        isOpen: boolean,
        message: string,
        severity: Color,
    } = {
        isOpen: false,
        message: "",
        severity: "success",
    };

    const bottomBarRef = React.useRef<HTMLDivElement>();

    const [newFixtureModal, setnewFixtureModal] = React.useState(true);
    const [showNewProfile, setShowNewProfile] = React.useState(false);
    const [newProfileName, setNewProfileName] = React.useState("");
    const [openProfileDeletePrompt, setProfileDeletePrompt] = React.useState(false);
    const [openFixtureDeletePrompt, setFixtureDeletePrompt] = React.useState(false);
    const [activeSegment, setNewSegment] = React.useState<number>();
    const [fixture, setFixture] = React.useState(initialState);
    const [sharedFixture, setSharedFixture] = React.useState(true);
    const [colorIndex, setColorIndex] = React.useState(0);
    const { userToken, currentUser, internalUser } = React.useContext(UserContext);
    const [fixtures, setfixtures] = React.useState<FixtureInterface[]>([]);
    const [brands, setBrands] = React.useState<string[]>([]);
    const [availableChannels, setAvailableChannels] = React.useState<string[]>([]);
    const [activeFixture, setActiveFixture] = React.useState<FixtureInterface>();
    const [activeProfile, setActiveProfile] = React.useState<ProfileInterface>();
    const [isWritingToDb, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [errMsg, setError] = React.useState(false);
    const [fixtureValidation, setFixtureValidation] = React.useState(startingFixtureValidation);
    const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
    const [importModal, setImportModal] = React.useState(false);
    const [importModalType, setImportModalType] = React.useState("");
    const [importData, setImportData] = React.useState("");
    const [bottomBarHeight, setBottomBarHeight] = React.useState<number>();
    const [profileChanged, setProfileChanged] = React.useState<boolean>(false);
    const [showSaveWarning, setShowSaveWarning] = React.useState<boolean>(false);
    const [ignoreSaveWarning, setIgnoreSaveWarning] = React.useState<boolean>(
        localStorage.getItem("profile-editor-ignore-warning") ? 
                localStorage.getItem("profile-editor-ignore-warning").length > 0 : 
                false
    );
    const [nextProfile, setNextProfile] = React.useState<ProfileInterface>();
    const [continueAction, setContinueAction] = React.useState<ContinueActions>(ContinueActions.none);

    let headers: object;

    if (currentUser && userToken) {
        headers = {
            headers: { Authorization: `Bearer ${userToken}` },
        };
    }

    React.useEffect(() => {
        axios.get(`${AT_FULL_API_URL}/brands`).then((res) => {
            setBrands(res.data);
        });
        axios
            .get(
                `${AT_FULL_API_URL}/profiles/channel-names`,
                headers
            )
            .then((res) => {
                setAvailableChannels(res.data);
            });
        window.addEventListener("resize", calcBottomMargin);

        return window.removeEventListener("resize", calcBottomMargin);
    }, []);

    React.useEffect(() => {
        if (activeProfile?.id && activeFixture) {
            const savedProfile = activeFixture.profiles.find((p) => p.id == activeProfile.id);
            if (savedProfile) {
                if (savedProfile !== activeProfile) {
                    setProfileChanged(true);
                }
            }
        }
    }, [activeProfile]);

    const close = () => {
        setnewFixtureModal(false);
    };

    const calcBottomMargin = () => {
        try {
            if (bottomBarRef && bottomBarRef.current) {
                setBottomBarHeight(bottomBarRef.current.clientHeight);
            }
        } catch (err) {
            console.error("Caught error in margin");
        }
    };

    const handleSuccessfulSave = () => {
        setSaving(false);
        setError(false);
        setSaved(true);
        setProfileChanged(false);
        setTimeout(() => {
            setSaved(false);
        }, 2000);
    };

    const handleNewProfile = () => {
        const newProfile = {
            name: newProfileName,
            channels: [],
        };

        saveProfileInHasura(newProfile, () => setShowNewProfile(false));
        setNewProfileName("");
        setColorIndex(0);
    };

    const handleProfileChange = (e: React.ChangeEvent, v: ProfileInterface) => {
        if (ignoreSaveWarning) {
            setActiveProfile(v);
            setProfileChanged(false);
        } else if (profileChanged) {
            setNextProfile(v);
            setShowSaveWarning(true);
        } else {
            setActiveProfile(v);
        }
    }

    const closeSaveModal = () => {
        if (ignoreSaveWarning) localStorage.setItem("profile-editor-ignore-warning", "true");
        setShowSaveWarning(false);
        setProfileChanged(false);
        if (continueAction == ContinueActions.newProfile) {
            handleNewProfile();
        } else if (continueAction == ContinueActions.newFixture) {
            setnewFixtureModal(true);
        } else {
            setActiveProfile(nextProfile);
            setNextProfile(null);
        }
        setContinueAction(ContinueActions.none);
    }

    const handleSaveAndContinue = () => {
        saveProfileInHasura(activeProfile, closeSaveModal);
    }

    const handleDeleteProfile = (profile: ProfileInterface) => {
        console.log("deleting profile");
        if (profile && profile.id) {
            axios
                .delete(
                    `${AT_FULL_API_URL}/profiles/${profile.id}`,
                    headers
                )
                .then((res) => {
                    const removeActiveProfile: ProfileInterface[] = _.filter(
                        activeFixture.profiles,
                        (p: ProfileInterface) => {
                            return p.id !== profile.id;
                        }
                    );

                    const currentFixture = { ...activeFixture, profiles: removeActiveProfile };
                    setActiveFixture(currentFixture);
                    setActiveProfile(currentFixture.profiles[0]);
                })
                .catch((err) => console.log(err));
        }
    };

    const saveProfileInFirebase = async (profile, err) => {
        const saveBackupProfile = await db.collection("savedProfiles").doc().set({
            profile: profile,
            error: err,
        });

        setError(true);
    };

    const changeFixtureName = (value: string) => {
        const currentFixture = { ...activeFixture };
        currentFixture.name = value;
        saveFixtureInHasura(currentFixture);
    };

    const calculateColorMode = (profile: ProfileInterface): String => {
      let result = "";
      colors.forEach((color) => {
        if (profile.channels.some((channel) => 
            channel.name.trim().toLowerCase()
            .match("^(cell(\\s)?\\d(\\s)?)?" 
            + color.toLowerCase() 
            + "((\\s)?((cell(\\s)?\\d(\\s)?)|level|color|led|\\d))?\$"))) 
        {
          if (color == "Warm White") {
            result += "ww";
          } else {
            result += color.toLowerCase().at(0);
          }
        }
      });

      return result;
    }

    const saveProfileInHasura = async (profile: ProfileInterface, callback?: () => void) => {
        const parentFixtureId = profile.fixtureId ? profile.fixtureId : activeFixture.id;
        const colorMode = calculateColorMode(profile);

        if (profile.id) {
            // console.log("Updating existing profile");
            axios
                .post(
                    `${AT_FULL_API_URL}/profiles/${profile.id}`,
                    {
                        ...profile,
                        fixtureId: parentFixtureId,
                        colorMode: colorMode,
                    },
                    headers
                )
                .then((res) => {
                    let profiles = [...activeFixture.profiles];
                    const index: number = _.findIndex(profiles, { id: profile.id });
                    profiles[index] = res.data;

                    setActiveProfile(res.data);
                    setActiveFixture({
                        ...activeFixture,
                        profiles: profiles
                    });
                    handleSuccessfulSave();
                    if (callback) {
                        callback();
                    }
                })
                .catch((err) => {
                    console.log("ERROR UPDATING PROFILE");
                    console.error(err);
                    saveProfileInFirebase(profile, err);
                    // setError(err);
                    setSaving(false);
                });
        } else {
            let verified = false;
            if (currentUser && internalUser) {
              verified = true;
            }
            axios
                .post(
                    `${AT_FULL_API_URL}/profiles`,
                    {
                        ...profile,
                        fixtureId: parentFixtureId,
                        verified,
                        colorMode: colorMode,
                    },
                    headers
                )
                .then((res) => {
                    setActiveProfile(res.data);
                    setActiveFixture(af => {
                        return {...af, profiles: [...af.profiles, res.data]}
                    });
                    handleSuccessfulSave();
                    if (callback) {
                        callback();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // setError(err);
                    setSaving(false);
                });
        }
    };

    const saveFixtureInHasura = (fixture: FixtureInterface) => {
        if (fixture.id) {
            console.log("saving existing fixture");

            axios
                .post(
                    `${AT_FULL_API_URL}/fixtures`,
                    fixture,
                    headers
                )
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
        } else {
            let verified = false;
            if (currentUser && internalUser) {
              verified = true;
            }
            axios
                .post(
                    `${AT_FULL_API_URL}/fixtures`,
                    {
                        ...fixture,
                        profileName: fixture.profiles[0].name,
                        verified,
                    },
                    headers
                )
                .then((res) => {
                    setActiveFixture(res.data);
                    setActiveProfile(res.data.profiles[0]);
                    setFixture(initialState);
                })
                .catch((err) => console.log(err));
        }
    };

    const deleteFixture = (fixture: FixtureInterface) => {
        if (fixture && fixture.id) {
            axios
                .delete(
                    `${AT_FULL_API_URL}/fixtures/${fixture.id}`,
                    headers
                )
                .then((res) => {
                    searchFixtures("");
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const searchFixtures = async (search: string) => {
        if (search.length <= 0) {
            setfixtures([]);
            setLoading(false);
        } else {
            try {
                axios
                    .get(
                        `${AT_FULL_API_URL}/fixtures?search=${search}`,
                        headers
                    )
                    .then((res) => {
                        setfixtures(res.data);
                        setLoading(false);
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.log(err);
                    });
            } catch (err) {
                setLoading(false);
                console.log(err);
            }
        }
    };

    const addSegmentToProfile = () => {
        let highestSegment = -1;
        let numberOfNewSegments = 1;

        const hasSegments =
            activeProfile.channels[activeSegment].segments !== undefined;

        if (hasSegments) {
            const findHighestSegment = _.map(
                activeProfile.channels[activeSegment].segments,
                (segment: SegmentInterface) => {
                    if (segment.name.includes("New Segment")) {
                        numberOfNewSegments++;
                    }
                    if (segment.end > highestSegment) {
                        highestSegment = segment.end;
                    }
                }
            );
        }

        const newSegment: SegmentInterface = {
            name: `New Segment ${numberOfNewSegments}`,
            start: highestSegment + 1,
            end: 255,
            key: generateKey(),
        };

        const segments = {
            ...activeProfile.channels[activeSegment],
            segments: hasSegments
                ? [...activeProfile.channels[activeSegment].segments, newSegment]
                : [newSegment],
        };

        const filterChannel = _.filter(activeProfile.channels, (r, index) => {
            return index !== activeSegment;
        });

        filterChannel.splice(activeSegment, 0, segments);
        console.log(filterChannel);
        setActiveProfile({
            ...activeProfile,
            channels: [...filterChannel],
        });
        let segmentBottom = document.getElementById("segment-list");
        segmentBottom.scroll({ top: segmentBottom.scrollHeight, behavior: "smooth" });
    };

    const changeSegmentValues = (value: string, type: string, order: number) => {
        const channels = [...activeProfile.channels];
        const currentSegments = [...activeProfile.channels[activeSegment].segments];

        currentSegments[order][type] = parseFloat(value);
        channels[activeSegment].segments = [...currentSegments];

        setActiveProfile({
            ...activeProfile,
            channels: channels,
        });
    };

    const editSegmentName = (name: string, index: number) => {
        const channels = [...activeProfile.channels];
        const currentSegments = [...activeProfile.channels[activeSegment].segments];

        currentSegments[index].name = name;
        channels[activeSegment].segments = [...currentSegments];

        setActiveProfile({
            ...activeProfile,
            channels: channels,
        });
    };

    const removeChannelFromFixture = (deleteKey: number, type: string) => {
        if (type === "channels") {
            const removeChannel: ChannelInterface[] = _.filter(activeProfile.channels, (channel: ChannelInterface) => {
                return channel.key !== deleteKey;
            });
            setActiveProfile({ ...activeProfile, channels: [...removeChannel] });
        } else if (type === "segments") {
            // console.log(activeProfile.channels);
            const channelCopy = [...activeProfile.channels];

            const segmentIndex = _.findIndex(channelCopy[activeSegment].segments, {
                key: deleteKey,
            });

            const removeSegment: SegmentInterface[] = _.filter(
                channelCopy[activeSegment].segments,
                (segment: SegmentInterface) => {
                    return segment.key !== deleteKey;
                }
            );

            channelCopy[activeSegment].segments = removeSegment;
            console.log(channelCopy);

            setActiveProfile({
                ...activeProfile,
                channels: channelCopy,
            });
        }
    };

    const appendChannelToFixture = (obj, index) => {
        const currentChannels = [...activeProfile.channels];
        currentChannels.splice(index + 1, 0, obj);
        setActiveProfile({
            ...activeProfile,
            channels: [...currentChannels],
        });
    };

    const editChannelName = (name: string, index: number) => {
        const newChannelName = [].concat(activeProfile.channels);
        newChannelName[index] = { ...newChannelName[index], name: name };

        setActiveProfile({
            ...activeProfile,
            channels: newChannelName,
        });
    };

    const addChannelsToFixture = (obj, type?) => {
        const channelsToAdd: ChannelInterface[] = [];
        const currentChannels = [...activeProfile.channels];

        if (type === "Pixel Mapping") {
            obj.map((channel) => {
                if (channel.checked) {
                    channelsToAdd.push({
                        name: `${channel.label} ${colorIndex > 0 ? colorIndex + 1 : ""}`,
                        key: generateKey(),
                        segments: [],
                    });
                }
            });
            if (colorIndex > 0) {
                const previousColors = [];

                const nonColorFields = _.filter(currentChannels, (channel) => {
                    return !colors.includes(channel.name.replace(/[0-9]/g, "").trim());
                });

                if (colorIndex === 1) {
                    const modifyColorFields = _.map(currentChannels, (channel) => {
                        if (colors.includes(channel.name.trim())) {
                            previousColors.push({
                                name: `${channel.name} 1`,
                                key: generateKey(),
                                segments: channel.segments
                            });
                        }
                    });

                    setActiveProfile({
                        ...activeProfile,
                        channels: [...nonColorFields, ...previousColors, ...channelsToAdd],
                    });
                } else {
                    setActiveProfile({
                        ...activeProfile,
                        channels: [...activeProfile.channels, ...channelsToAdd],
                    });
                }
            } else {
                setActiveProfile({
                    ...activeProfile,
                    channels: [...activeProfile.channels, ...channelsToAdd],
                });
            }
            setColorIndex(colorIndex + 1);
        } else {
            obj.map((channel) => {
                if (channel.checked) {
                    channelsToAdd.push({ name: channel.label, key: generateKey(), segments: [] });
                }
            });

            setActiveProfile({
                ...activeProfile,
                channels: [...activeProfile.channels, ...channelsToAdd],
            });
        }
    };

    const handleActiveFixture = async (id: number, closeModal: boolean) => {
        axios
            .get(`${AT_FULL_API_URL}/fixtures/${id}`, headers)
            .then((res) => {
                const selectedFixture = res.data;
                const defaultActiveProfile = [];
                const addIdsToProfile = _.map(selectedFixture.profiles, (profile) => {
                    console.log(profile);
                    const defaultChannels = [];
                    if (profile.channels.length > 0) {
                        _.map(profile.channels, (channel) => {
                            channel.key = generateKey();
                            _.map(channel.segments, (segment) => {
                                segment.key = generateKey();
                            });
                        });
                    } else {
                        selectedFixture.channels = [];
                    }
                });
                setActiveFixture(selectedFixture);
                setActiveProfile(selectedFixture.profiles[0]);
                if (closeModal) {
                    setnewFixtureModal(false);
                    window.scrollTo(0, 0);
                }
                calcBottomMargin();
            });
    };

    const renderProfileSearch = () => {
        if (!loading) {
            return _.map(fixtures, (result) => {
                return (
                    <div key={result.id}>
                        <DeletePrompt
                            type="Fixture"
                            obj={activeFixture}
                            open={openFixtureDeletePrompt}
                            close={setFixtureDeletePrompt}
                            callback={deleteFixture}
                        />
                        <Grid container className="line-item" key={result.id}>
                            <Grid
                                item
                                style={{ cursor: "pointer" }}
                                onClick={() => handleActiveFixture(result.id, true)}
                            >
                                {result.name}
                                <div
                                    style={{
                                        fontSize: 14,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {result.brand && <div>by {result.brand}</div>}
                                    {result.verified && result.brand && (
                                        <i
                                            className="fas fa-check-circle"
                                            style={{ color: "#282bf6", paddingLeft: 5 }}
                                        ></i>
                                    )}
                                </div>
                            </Grid>
                            <Grid>
                                <i
                                    onClick={() => {
                                        handleActiveFixture(result.id, false);
                                        setFixtureDeletePrompt(true);
                                    }}
                                    className="far fa-trash-alt"
                                ></i>
                                <i
                                    className="far fa-edit"
                                    onClick={() => handleActiveFixture(result.id, true)}
                                ></i>
                            </Grid>
                        </Grid>
                    </div>
                );
            });
        } else {
            return (
                <div
                    style={{
                        margin: "2em 0",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            );
        }
    };

    const onDragEnd = (result) => {
        const reorder = (list: ChannelInterface[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items: ChannelInterface[] = reorder(
            activeProfile.channels,
            result.source.index,
            result.destination.index
        );

        setActiveProfile({ ...activeProfile, channels: [...items] });
        setNewSegment(result.destination.index);
    };

    const renderChannelOptions = () => {
        return _.map(channelOptions, (option, index) => {
            return (
                <ChannelOption
                    key={option.name}
                    order={index}
                    handleChange={addChannelsToFixture}
                    name={option.name}
                    type={option.type}
                    options={option.options}
                />
            );
        });
    };

    const renderSaveColor = () => {
        if (saved) {
            return "#2b902b";
        } else if (errMsg) {
            alert(
                `Save Error! A Backup of this profile was saved. Email devon@blizzardpro.com for assistance. Error code: ${errMsg}`
            );
            setError(false);
            return "#ff4d4d";
        } else if (profileChanged) {
            return "blueviolet";
        }
        return;
    };

    const validateFixture = () => {
        const errors: {
            fixture: string,
            profile: string,
        } = {
            fixture: "",
            profile: "",
        };

        errors.fixture = fixture.name.length === 0 ? "Fixture name is required" : "";
        errors.profile = fixture.profiles[0].name.length === 0 ? "Profile name is required" : "";

        setFixtureValidation({ ...errors });
        return Object.values(errors).every(x => x === "");
    };

    const exportProfile = () => {
        const returnable = _.map(activeProfile.channels, (channel) => {
            return {
                name: channel.name.trim(),
                segments: _.map(channel.segments, (segment) => {
                    return {
                        ...segment,
                    };
                }),
            };
        });

        navigator.clipboard.writeText(JSON.stringify(returnable));

        setSnackbar({
            isOpen: true,
            message: "Copied profile to clipboard.",
            severity: "success",
        });
    };

    const importProfile = () => {
        if (importData) {
            try {
                const data = _.map(JSON.parse(importData), (channel) => {
                    return {
                        id: generateKey(),
                        name: channel.name ?? "Unnamed Channel",
                        segments: channel.segments ?? [],
                    };
                });

                setActiveProfile({ ...activeProfile, channels: data });

            } catch (err) {
                setSnackbar({
                    isOpen: true,
                    message: "Data import failed.",
                    severity: "error",
                });
                console.error(err);
            }
        }

        setImportData("");
        setImportModal(false);
    };

    const exportSegments = () => {
        navigator.clipboard.writeText(JSON.stringify(activeProfile.channels[activeSegment].segments));

        setSnackbar({
            isOpen: true,
            message: "Copied segments to clipboard",
            severity: "success",
        });
    };

    const importSegments = () => {
        if (importData) {
            try {
                const data = _.map(JSON.parse(importData), (segment) => {
                    return {
                        id: generateKey(),
                        name: segment.name ?? "Unnamed Segment",
                        start: segment.start ?? 0,
                        end: segment.end ?? 255,
                    };
                });

                const newChannelData = { ...activeProfile.channels[activeSegment], segments: data };

                const newChannels = [...activeProfile.channels];
                newChannels.splice(activeSegment, 1, newChannelData);

                setActiveProfile({
                    ...activeProfile,
                    channels: newChannels
                });

            } catch (err) {
                setSnackbar({
                    isOpen: true,
                    message: "Data import failed.",
                    severity: "error",
                });
                console.error(err);
            }
        }

        setImportData("");
        setImportModal(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbar({ ...snackbar, isOpen: false });
    };

    return (
        <div className="profile-editor">
            <ModalBasic open={newFixtureModal} close={close}>
                <div className="profile-adder" style={{maxHeight: "90vh"}}>
                    <h2 className="trip-text">Profile-o-matic 3000</h2>

                    <DebounceInput
                        minLength={2}
                        style={{ width: "100%" }}
                        id="outlined-basic"
                        label="Fixture Name"
                        className="debounce-input"
                        debounceTimeout={750}
                        onChange={(e) => {
                            setFixture({ ...fixture, name: e.target.value });
                            setLoading(true);
                            searchFixtures(e.target.value);
                        }}
                        element={TextField}
                        margin="normal"
                        variant="outlined"
                        forceNotifyOnBlur={true}
                        error={fixtureValidation.fixture.length !== 0}
                        helperText={fixtureValidation.fixture}
                    />

                    <Autocomplete
                        freeSolo
                        options={brands}
                        onChange={(e, v) => {
                            setFixture({ ...fixture, brand: v });
                        }}
                        onInputChange={(e, v) => {
                            setFixture({ ...fixture, brand: v });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Brand" variant="filled" />
                        )}
                    />

                    <TextField
                        style={{ width: "100%" }}
                        id="outlined-basic"
                        label="Profile Name"
                        margin="normal"
                        variant="outlined"
                        onChange={(e) =>
                            setFixture({
                                ...fixture,
                                profiles: [{ name: e.target.value, channels: [] }],
                            })
                        }
                        error={fixtureValidation.profile.length !== 0}
                        helperText={fixtureValidation.profile}
                    />

                    <FormGroup style={{ alignItems: "center" }}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={!sharedFixture}
                                onChange={() => {
                                    setFixture({
                                        ...fixture,
                                        shared: !sharedFixture
                                    });
                                    setSharedFixture(!sharedFixture);
                                }}
                                color="primary"
                                inputProps={{ "aria-label": "Private Fixture" }}
                            />
                        } label="Make this fixture private" />
                    </FormGroup>

                    <Button
                        type="submit"
                        style={{
                            width: "100%",
                            padding: "16px 0px 15px 0 !important",
                            marginBottom: "2em",
                        }}
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={() => {
                            if (validateFixture()) {
                                setActiveFixture(fixture);
                                setActiveProfile(fixture.profiles[0]);
                                saveFixtureInHasura(fixture);
                                close();
                            }
                        }}
                    >
                        Create New
                    </Button>
                    <hr />
                    <h3 style={{ textAlign: "center", paddingTop: 10 }}>
                        ...or edit an existing fixture:
                    </h3>
                    <div className="fixture-search">
                        <DebounceInput
                            minLength={2}
                            style={{ marginBottom: 30, width: "100%" }}
                            className="debounce-input"
                            debounceTimeout={750}
                            onChange={(e) => {
                                setLoading(true);
                                searchFixtures(e.target.value);
                            }}
                            element={TextField}
                            margin="normal"
                            variant="outlined"
                            forceNotifyOnBlur={true}
                        />
                        <div className="search-results">{renderProfileSearch()}</div>
                    </div>
                </div>
            </ModalBasic>
            <ModalBasic open={importModal} close={() => setImportModal(false)}>
                <div className="profile-adder">
                    <h2>Import {importModalType === IMPORT_MODAL_PROFILE ? ("Profile") : ("Segments")}</h2>
                    <p>
                        Paste data copied with the <span style={{ fontWeight: "bolder" }}>Export</span> button
                        into the box below to overwrite the current {importModalType === IMPORT_MODAL_PROFILE ? ("profile") : ("segment list")}.
                    </p>
                    <TextField
                        label={importModalType === IMPORT_MODAL_PROFILE ? ("Profile Data") : ("Segment Data")}
                        multiline
                        rows={10}
                        variant="filled"
                        onChange={event => setImportData(event.target.value.trim())}
                        style={{ width: "100%" }}
                    />
                    <Button
                        type="submit"
                        style={{
                            width: "100%",
                            padding: "16px 0px 15px 0 !important",
                            margin: "1em 0 2em 0",
                        }}
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={importModalType === IMPORT_MODAL_PROFILE ? importProfile : importSegments}
                    >
                        Import {importModalType === IMPORT_MODAL_PROFILE ? ("Profile") : ("Segment")} Data
                    </Button>
                </div>
            </ModalBasic>
            <ModalBasic open={showSaveWarning} close={() => setShowSaveWarning(false)}>
                <div className="profile-adder">
                    <h2>Hold up!</h2>
                    <p>
                        It looks like you have unsaved changes for this profile. Would you like to save before continuing?
                    </p>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ignoreSaveWarning}
                                        onChange={() => setIgnoreSaveWarning(!ignoreSaveWarning)}
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label={"Don't show this again"}
                                style={{ margin: "0 auto" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                type="submit"
                                style={{
                                    width: "100%",
                                    padding: "16px 0px 15px 0 !important",
                                    margin: "1em 0 2em 0",
                                }}
                                variant="contained"
                                color="primary"
                                className="btn red"
                                onClick={closeSaveModal}
                            >
                                No Thanks
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                type="submit"
                                style={{
                                    width: "100%",
                                    padding: "16px 0px 15px 0 !important",
                                    margin: "1em 0 2em 0",
                                }}
                                variant="contained"
                                color="primary"
                                className="btn green"
                                onClick={handleSaveAndContinue}
                            >
                                Save and Continue
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </ModalBasic>
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {activeFixture && !newFixtureModal && (
                <div className="profile-nav">
                    <div className="flex-container"/*  style={{ justifyContent: "space-around"}} */ ref={bottomBarRef}>
                        <div className="nav-item" /* style={{ textAlign: "center", margin: "auto"}} */>
                            <h5>Fixture</h5>
                            <DebounceInput
                                value={activeFixture.name}
                                minLength={2}
                                className="bare-text-input"
                                debounceTimeout={1500}
                                onChange={(e) => changeFixtureName(e.target.value)}
                            />
                        </div>

                        <div className="nav-item">
                            <Autocomplete
                                disableClearable={true}
                                value={activeProfile}
                                style={{ width: 300, marginLeft: 10 }}
                                options={activeFixture && activeFixture.profiles ? activeFixture.profiles : []}
                                onChange={handleProfileChange}
                                getOptionLabel={(profile) => profile.name || ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Existing Profiles"
                                        variant="filled"
                                    />
                                )}
                                PopperComponent={({ style, ...props }) => (
                                    <Popper
                                        {...props}
                                        style={{ ...style, zIndex: "1000000" }}
                                        placement="top"
                                    />
                                )}
                            />
                        </div>

                        <div className="nav-item">
                            {!showNewProfile && (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className="btn"
                                    style={{ width: 130 }}
                                    onClick={() => setShowNewProfile(!showNewProfile)}
                                >
                                    New Profile +
                                </Button>
                            )}

                            {showNewProfile && (
                                <>
                                    <TextField
                                        style={{ marginLeft: 10, marginTop: 0, width: 250 }}
                                        id="outlined-basic new-profile-name"
                                        label="Enter New Profile Name"
                                        margin="normal"
                                        value={newProfileName}
                                        onChange={(e) => setNewProfileName(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13 || e.code === "Enter") {
                                                if (profileChanged) {
                                                    setContinueAction(ContinueActions.newProfile);
                                                    setShowSaveWarning(true);
                                                } else {
                                                    handleNewProfile();
                                                }
                                            }
                                        }}
                                        variant="outlined"
                                    />
                                    <Button
                                        style={{ marginLeft: 14 }}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn"
                                        onClick={() => {
                                            if (profileChanged) {
                                                setContinueAction(ContinueActions.newProfile);
                                                setShowSaveWarning(true);
                                            } else {
                                                handleNewProfile();
                                            }
                                        }}
                                    >
                                        +
                                    </Button>
                                </>
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className="btn"
                                style={{ width: 200, marginLeft: 20 }}
                                onClick={() => {
                                    if (profileChanged) {
                                        setContinueAction(ContinueActions.newFixture);
                                        setShowSaveWarning(true);
                                    } else {
                                        setnewFixtureModal(true);
                                    }
                                }}
                            >
                                Change Fixture
                            </Button>
                        </div>

                        <div className="nav-item">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className="btn"
                                style={{ width: 100 }}
                                onClick={() => exportProfile()}
                            >
                                Export
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className="btn"
                                style={{ width: 100, marginLeft: 20 }}
                                onClick={() => {
                                    setImportModalType(IMPORT_MODAL_PROFILE);
                                    setImportModal(true);
                                }}
                            >
                                Import
                            </Button>
                        </div>

                        <div className="nav-item">
                            <DeletePrompt
                                type="Profile"
                                obj={activeProfile}
                                open={openProfileDeletePrompt}
                                close={setProfileDeletePrompt}
                                callback={handleDeleteProfile}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => setProfileDeletePrompt(true)}
                                className="btn"
                                style={{ marginRight: 20, background: "#ff4d4d" }}
                            >
                                <i
                                    style={{ padding: "5px 0" }}
                                    className="far fa-trash-alt"
                                ></i>
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ background: renderSaveColor() }}
                                color="primary"
                                onClick={() => {
                                    setSaving(true);
                                    saveProfileInHasura(activeProfile);
                                }}
                                className="btn"
                            >
                                {saved ? "Saved!" : (profileChanged ? "Save Changes" : "Save")}
                                {isWritingToDb && (
                                    <CircularProgress
                                        style={{ marginLeft: 10 }}
                                        color="secondary"
                                        size={20}
                                    />
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <Grid container style={{ marginBottom: bottomBarHeight || "50px" }} >
                <Grid
                    className={
                        activeFixture ? "profile-col active-col pc-1" : "profile-col pc-1"
                    }
                    item
                    sm={4}
                >
                    <h3 style={{ background: "#010407" }} className="fixed-col-head">
                        Add a Channel
                    </h3>

                    <Autocomplete
                        id="available-channels"
                        freeSolo
                        style={{
                            background: "#09151e",
                            width: "95%",
                            margin: "0 auto",
                            paddingLeft: "1em",
                            marginBottom: 30,
                            borderRadius: 50,
                        }}
                        options={availableChannels}
                        onChange={(e, v) => {
                            setActiveProfile({
                                ...activeProfile,
                                channels: [
                                    ...activeProfile.channels,
                                    { name: v, key: generateKey(), segments: [] },
                                ],
                            });
                        }}
                        getOptionLabel={(channel) => channel || ""}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    label="Channel Options"
                                    variant="filled"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <i
                                                    style={{
                                                        color: "white",
                                                        marginBottom: 35,
                                                        marginRight: 0,
                                                    }}
                                                    className="fal fa-level-up"
                                                ></i>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />

                    {renderChannelOptions()}
                </Grid>
                <Grid
                    className={
                        activeFixture ? "profile-col active-col pc-2" : "profile-col pc-2"
                    }
                    item
                    sm={4}
                >
                    <h3 style={{ background: "#03070b" }} className="fixed-col-head">
                        Fixture Channels
                    </h3>

                    <DraggableLists
                        activeSegment={activeSegment}
                        setNewSegment={setNewSegment}
                        onDragEnd={onDragEnd}
                        editChannelName={editChannelName}
                        appendChannelToFixture={appendChannelToFixture}
                        removeChannelFromFixture={removeChannelFromFixture}
                        channels={activeProfile ? activeProfile.channels : []}
                    />

                    <Grid container className="segment-adder">
                        <Button
                            type="submit"
                            style={{
                                width: "48%",
                                margin: "0 auto",
                                marginBottom: 50,
                                marginTop: 25,
                            }}
                            variant="contained"
                            color="primary"
                            className="btn"
                            onClick={() => {
                                setActiveProfile({
                                    ...activeProfile,
                                    channels: [
                                        ...activeProfile.channels,
                                        { name: "New Channel", key: generateKey(), segments: [] },
                                    ],
                                });
                            }}
                        >
                            Add New Channel{" "}
                            <i style={{ marginLeft: 10 }} className="far fa-plus-circle"></i>
                        </Button>
                    </Grid>
                </Grid>

                <Grid
                    className={
                        activeFixture ? "profile-col active-col pc-3" : "profile-col pc-3"
                    }
                    item
                    id="segment-list"
                    sm={4}
                >
                    <Grid container style={{ alignItems: "center" }}>
                        <Grid sm={11} item style={{ marginBottom: (bottomBarHeight ?? 50) + 10 }}>
                            <h3 style={{ background: "#03070b" }} className="fixed-col-head">
                                Channel Segments
                            </h3>

                            <SegmentList
                                editSegmentName={editSegmentName}
                                changeSegmentValues={changeSegmentValues}
                                removeChannelFromFixture={removeChannelFromFixture}
                                activeProfile={activeProfile}
                                activeSegment={activeSegment}
                            />

                            <CSSTransition
                                in={activeSegment >= 0}
                                unmountOnExit
                                timeout={200}
                                classNames="dialog"
                            >
                                <>
                                    <Grid container className="segment-adder">
                                        <Button
                                            type="submit"
                                            style={{
                                                width: "48%",
                                                margin: "0 auto",
                                                marginTop: 25,
                                            }}
                                            variant="contained"
                                            color="primary"
                                            className="btn"
                                            onClick={() => addSegmentToProfile()}
                                        >
                                            Add New Segment{" "}
                                            <i
                                                style={{ marginLeft: 10 }}
                                                className="far fa-plus-circle"
                                            ></i>
                                        </Button>
                                    </Grid>
                                    <Grid container className="segment-exports" alignItems="center" justifyContent="center">
                                        <Button
                                            type="submit"
                                            style={{
                                                width: "30%",
                                                marginBottom: 50,
                                                marginTop: 15,
                                                marginRight: 10
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            className="btn"
                                            onClick={exportSegments}
                                        >
                                            Export
                                        </Button>
                                        <Button
                                            type="submit"
                                            style={{
                                                width: "30%",
                                                marginBottom: 50,
                                                marginTop: 15,
                                                marginRight: 10
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            className="btn"
                                            onClick={() => {
                                                setImportModalType(IMPORT_MODAL_SEGMENTS);
                                                setImportModal(true);
                                            }}
                                        >
                                            Import
                                        </Button>
                                    </Grid>
                                </>
                            </CSSTransition>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProfileEditor;
