import React from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { LocationInterface } from "interfaces/Location";
import LocationForm from "./LocationForm";

interface LocationCardProps {
    companyId: number,
    location: LocationInterface,
    canEdit?: boolean,
    updateCallback?: (navigation: LocationInterface, id: number) => void,
    deleteCallback?: (locationId: number) => void,
}

const LocationCard = ({ companyId, location, canEdit = true, updateCallback, deleteCallback }: LocationCardProps) => {
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        setEditing(false);
    }, [location]);

    return (
        <Paper elevation={2} className="contact-card">
            <Grid container spacing={2}>
                <Grid item container alignItems="center" xs={12} md={10}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{location.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{location.phoneNumber}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><a href={location.website}>{location.website}</a></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{location.address.streetAddress + (location.address.streetAddressTwo ? (" " + location.address.streetAddressTwo) : "") + " " + location.address.city + " " + location.address.state}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={2} spacing={2} style={{ textAlign: "center" }}>
                    <Grid item xs={12}>
                        {canEdit && (
                            <Button
                                variant={editing ? "contained" : "outlined"}
                                color={editing ? "secondary" : "secondary"}
                                onClick={() => setEditing(!editing)}
                                fullWidth
                            >
                                {editing ? "Cancel" : "Edit"}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {editing && <LocationForm companyId={companyId} location={location} updateCallback={updateCallback} deleteCallback={deleteCallback} />}
            </Grid>

        </Paper>
    );
};

export default LocationCard;