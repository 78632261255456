import React from "react";
import { UserContext } from "context/User";
import { Button, CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { makeAxiosCall } from "utils";
import { buildInvite, InviteInterface } from "interfaces/Invite";

interface InviteCardProps {
    invite: InviteInterface,
    canEdit: boolean,
    removeCallback: () => void,
    updateCallback: (newInviteData: InviteInterface) => void
}

const InviteCard = ({ invite, canEdit, removeCallback, updateCallback }: InviteCardProps) => {
    const { currentUser } = React.useContext(UserContext);
    const [revoking, setRevoking] = React.useState(false);
    const [resending, setResending] = React.useState(false);

    const revokeInvite = () => {
        setRevoking(true);

        makeAxiosCall(
            "delete",
            `invite/${invite.company.id}/${invite.id}`
        ).then(res => {
            if (res.data.id) {
                removeCallback();
            }
        }).catch(error => {
            setRevoking(false);
            console.log(error);
        });
    };

    const resendInvite = () => {
        setResending(true);

        makeAxiosCall(
            "post",
            `invite/${invite.id}`,
            {
                id: invite.id,
                accountLevelId: invite.accountLevel.id,
                companyId: invite.company.id,
                email: invite.email,
                resendInvite: true
            }
        ).then(res => {
            setResending(false);
            if (res.data.id) {
                updateCallback(buildInvite(res.data));
            }
        }).catch(error => {
            setResending(false);
            console.log(error);
        });
    };

    return (
        <Paper key={invite.id} elevation={2} className="grid-search-result">
            <Grid container item xs={12} className="center-text" alignItems="center">
                <Grid item xs={5} className="result-name">
                    <Typography noWrap variant="body2">
                        {invite.email}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {invite.lastUpdate.toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})}
                </Grid>
                <Grid item xs={2}>
                    {invite.accountLevel.name}
                </Grid>
                <Grid item xs={1}>
                    {canEdit && currentUser.accountLevel.canManageMembers && (
                        <Button
                            onClick={resendInvite}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="btn blue"
                            style={{ color: "white" }}
                        >
                            {resending ?
                                <CircularProgress
                                    color="inherit"
                                    size={14}
                                /> :
                                <i className="far fa-refresh"></i>
                            }
                        </Button>
                    )}
                </Grid>
                <Grid item xs={1}>
                    {canEdit && currentUser.accountLevel.canManageMembers && (
                        <Button
                            onClick={revokeInvite}
                            type="submit"
                            variant="outlined"
                            color="inherit"
                            className="btn red"
                            style={{ color: "white" }}
                        >
                            {revoking ?
                                <CircularProgress
                                    color="inherit"
                                    size={14}
                                /> :
                                <i className="far fa-trash-alt"></i>
                            }
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default InviteCard;