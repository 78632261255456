import React from "react";
import {
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Tooltip,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import ChipArray from "components/utils/ChipArray";
import Autocomplete from "@material-ui/lab/Autocomplete";
import JoditEditor from "jodit-react";
import { NewsfeedPostInterface } from "interfaces/Newsfeed";
import Post from "./Post";
import { DropzoneArea } from "material-ui-dropzone";
import { generateUID } from "utils";
import { storage } from "base";

interface NewsfeedPostFormProps {
  savePost: Function,
  setFormActive: Function,
  existingPost?: NewsfeedPostInterface,
}

const NewsfeedPostForm = ({ savePost, setFormActive, existingPost }: NewsfeedPostFormProps) => {

  const initialNewPostData: NewsfeedPostInterface = {
    title: "",
    audience: 1,
    topText: "",
    bottomText: "",
    imageUrl: "",
    videoUrl: "",
  }

  const tagOptions = [
    {
      label: "Video",
      id: 0,
    },
    {
      label: "News",
      id: 1,
    },
    {
      label: "Product",
      id: 2,
    },
    {
      label: "Important",
      id: 3,
    },
    {
      label: "Press",
      id: 4,
    },
    {
      label: "Internal",
      id: 5,
    },
  ];

  let existingTags = existingPost ?
    existingPost.tags.map((tag) => tagOptions.find(option => option.label == tag)) 
    : []

  const [newPostData, setNewPostData] = React.useState<NewsfeedPostInterface>(existingPost ? existingPost : initialNewPostData);
  const [chipData, setChipData] = React.useState<{label: string, id: number}[]>(existingTags);
  const [previewPost, setPreviewPost] = React.useState(false);
  const topTextEditor = React.useRef(null);
  const bottomTextEditor = React.useRef(null);

  const handleFormChange = (name: string) => (e) => {
    setNewPostData({
      ...newPostData,
      [name]: e.target.value,
    });
  }

  const uploadPostImage = (files: File[]) => {
    if (files.length > 0) {
      for (let fi = 0; fi < files.length; fi++) {
        const file = files[fi];
        let uid = generateUID();
        storage.ref(`/portal_newsfeed_images/${uid + file.name}`).put(file).then(async (storageData) => {
          setNewPostData({...newPostData, imageUrl: await storageData.ref.getDownloadURL()});
        }).catch((err) => {
          console.error(err);
        })
      }
    }
  }

  const submitPost = () => {
    savePost({...newPostData, tags: chipData.map(chip => chip.label)})
  }

  return (
    <div className="new-post" style={{width: "100%"}}>
      <div className="container">
        <Dialog
          open={previewPost}
          onClose={() => setPreviewPost(false)}
          scroll="body"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <Post post={{...newPostData, tags: chipData.map(chip => chip.label), createdAt: existingPost && existingPost.createdAt ? existingPost.createdAt : new Date()}} canDelete={false}/>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item xs={3}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  className="btn"
                  onClick={() => setPreviewPost(false)}
                  style={{ width: "100%" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn"
                  onClick={submitPost}
                  style={{ width: "100%" }}
                >
                  Post
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <h3>New Post</h3>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={newPostData.title}
          onChange={handleFormChange("title")}
        />

        <Autocomplete
          id="combo-box-demo"
          options={tagOptions}
          onChange={(e, v: {label: string, id: number}) => {
            if (!chipData.some((chip) => chip.id === v.id)) {
              setChipData([...chipData, { label: v.label, id: v.id }])
            }
          }}
          getOptionLabel={(tagOptions) => tagOptions.label}
          style={{ flex: 2, margin: "10px 0" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Tags"
              variant="outlined"
              fullWidth
            />
          )}
        />

        <CSSTransition
          in={chipData.length > 0}
          unmountOnExit
          timeout={200}
          classNames="alert"
        >
          <div>
            <ChipArray chipData={chipData} setChipData={setChipData} />
          </div>
        </CSSTransition>

        <h3
          style={{ padding: 0, marginTop: 20 }}
        >
          Top Text
        </h3>
        <JoditEditor
          ref={topTextEditor}
          value={newPostData.topText}
          onChange={(newContent) => setNewPostData({...newPostData, topText: newContent})}
        />

        <h3
          style={{ padding: 0, marginTop: 20 }}
        >
          Image 
          <Tooltip
            title="Upload an image or paste a URL"
            style={{ marginLeft: 6 }}
          >
            <i className="fa-solid fa-circle-question" />
          </Tooltip>
        </h3>
        <DropzoneArea
          maxFileSize={100000000}
          filesLimit={1}
          dropzoneClass="file-uploader"
          acceptedFiles={["image/*"]}
          onChange={uploadPostImage}
          initialFiles={[]}
          onDropRejected={(file) => console.log(file)}
        />
        <TextField
          label="Image Link"
          variant="outlined"
          fullWidth
          value={newPostData.imageUrl}
          onChange={handleFormChange("imageUrl")}
          style={{ margin: "10px 0"}}
        />

        <h3
          style={{ padding: 0, marginTop: 20 }}
        >
          Video 
          <Tooltip
            title="Paste the URL of a Youtube or Vimeo video here."
            style={{ marginLeft: 6 }}
          >
            <i className="fa-solid fa-circle-question" />
          </Tooltip>
        </h3>
        <TextField
          label="Video Link"
          variant="outlined"
          fullWidth
          value={newPostData.videoUrl}
          onChange={handleFormChange("videoUrl")}
        />
        <p style={{ fontStyle: "italic" }}>
          e.g. https://vimeo.com/382645744 or https://www.youtube.com/watch?v=3S1yI1-t-eY
        </p>

        <h3
          style={{ padding: 0, marginTop: 20 }}
        >
          Bottom Text
        </h3>
        <JoditEditor
          ref={bottomTextEditor}
          value={newPostData.bottomText}
          onChange={(newContent) => setNewPostData({...newPostData, bottomText: newContent})}
        />

        <h3
          style={{ margin: 0, padding: 0, marginTop: 20 }}
        >
          Visibility
        </h3>
        <FormControl
          component="fieldset"
          style={{ width: "100%", marginBottom: 20, textAlign: "left" }}
        >
          <RadioGroup
            aria-label="Populate from previous forms"
            name="Viewiable"
            row
            value={newPostData.audience}
            onChange={(e, v) => setNewPostData({...newPostData, audience: Number(v)})}
          >
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="Basic"
            />

            <FormControlLabel
              value={2}
              control={<Radio color="primary" />}
              label="Resellers"
            />
            <FormControlLabel
              value={3}
              control={<Radio color="primary" />}
              label="Rep Firms"
            />
            <FormControlLabel
              value={4}
              control={<Radio color="primary" />}
              label="Internal"
            />
          </RadioGroup>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              className="btn"
              onClick={() => setFormActive(false)}
              style={{ width: "100%" }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="btn"
              onClick={() => setPreviewPost(true)}
              style={{ width: "100%" }}
            >
              Preview
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NewsfeedPostForm;
