import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { generateKey } from "utils";

interface ChannelProps {
    name: string,
    order: number,
    editChannelName: (name: string, index: number) => void,
    removeChannelFromFixture: (deleteKey: number, type: string) => void,
    appendChannelToFixture: (obj: any, index: any) => void,
    setNewSegment: React.Dispatch<React.SetStateAction<number>>,
    activeSegment: number,
    id: number,
}

const Channel = ({
    name,
    order,
    editChannelName,
    removeChannelFromFixture,
    appendChannelToFixture,
    setNewSegment,
    activeSegment,
    id,
}: ChannelProps) => {
    return (
        <Grid
            onClick={() => setNewSegment(order)}
            className={
                activeSegment === order ? "profile-channel active" : "profile-channel"
            }
            style={{ alignItems: "baseline" }}
            container
        >
            <Grid sm={1} className="profile-order" item>
                {order + 1}
            </Grid>
            <Grid sm={10} item>
                <Grid container alignItems="center">
                    <Grid sm={8} className="channel-name" item>
                        <TextField
                            style={{ width: "100%" }}
                            id="channel-name-edit"
                            defaultValue={name}
                            onChange={(e) => editChannelName(e.target.value, order)}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid sm={3} item>
                        {name && !name.includes("Fine") && (
                            <Button
                                type="submit"
                                style={{ color: "#53626c" }}
                                color="primary"
                                className="btn"
                                onClick={() =>
                                    appendChannelToFixture(
                                        { name: `${name} Fine`, id: generateKey() },
                                        order
                                    )
                                }
                            >
                                16-bit +
                            </Button>
                        )}
                    </Grid>
                    <Grid
                        sm={1}
                        item
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            type="submit"
                            onClick={() => removeChannelFromFixture(id, "channels")}
                            className="btn"
                        >
                            <i style={{ color: "#90293a" }} className="far fa-trash-alt"></i>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Channel;
