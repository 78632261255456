import React, { Component } from "react";
import styles from "components/Quote Builder/assets/Styles";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import OverviewTab from "components/Quote Builder/components/OverviewTab";
import HandlingTab from "components/Quote Builder/components/HandlingTab";
import QuoteTab from "components/Quote Builder/components/QuoteTab";
import VideoTab from "components/Quote Builder/components/VideoTab";
import panel from "components/Quote Builder/assets/panel.svg";
import panel_panorama from "components/Quote Builder/assets/panel_panorama.svg";
import panel_insite from "components/Quote Builder/assets/panel_insite.svg";
import xlPanel from "components/Quote Builder/assets/panel-xl.svg";
import _ from "lodash";
import { MapInteractionCSS } from "react-map-interaction";

class CustomerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        scale: 0,
        translation: {
          x: 0,
          y: 0,
        },
      },
      panelRow: null,
      panelInfo: null,
    };
  }

  componentDidMount() {
    const view = document.querySelector(".view");
    const wall = document.querySelector("#view-render");

    const viewBounds = view.getBoundingClientRect();
    const wallBounds = wall.getBoundingClientRect();

    let yCenter = viewBounds.height / 4;
    let xCenter = viewBounds.left;

    this.setState({
      value: {
        scale: 1,
        translation: {
          x: xCenter,
          y: yCenter,
        },
      },
      panelRow: null,
      panelInfo: null,
    });
  }

  renderVideo = () => {
    if (this.props.videoEnabled) {
      return (
        <div className="yt-wrapper">
          <div className="yt-render">
            <div className="videoWrapper">
              <iframe
                title="Blizzard Preview"
                src="https://player.vimeo.com/video/95444423?autoplay=1&?background=1&background=1&loop=1&title=0&byline=0&portrait=0"
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      );
    }
  };

  renderOverview = (props) => {
    const {
      config,
      openedTab,
      addons,
      admin,
      distributorStatus,
      handleQuote,
      updateTotalPricing,
    } = this.props;
    if (openedTab === "overviewTab") {
      return (
        <OverviewTab
          distributorStatus={distributorStatus}
          admin={admin}
          config={config}
        />
      );
    } else if (openedTab === "videoTab") {
      return (
        <VideoTab
          distributorStatus={distributorStatus}
          admin={admin}
          config={config}
        />
      );
    } else if (openedTab === "handlingTab") {
      return (
        <HandlingTab
          distributorStatus={distributorStatus}
          admin={admin}
          config={config}
        />
      );
    } else if (openedTab === "quoteTab") {
      return (
        <QuoteTab
          updateTotalPricing={updateTotalPricing}
          addons={addons}
          handleQuote={handleQuote}
          distributorStatus={distributorStatus}
          admin={admin}
          config={config}
        />
      );
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.panelRow !== nextProps.config.panelHeightNeeded) {
      return {
        panelRow: nextProps.config.panelHeightNeeded,
      };
    }
    return null;
  }

  renderActualDiag() {
    const config = this.props.config,
      height = config.panelHeightNeeded,
      width = config.panelWidthNeeded,
      prod = config.currentProd,
      actualHeight = (height * prod.height) / 25.3 / 12,
      actualWidth = (width * prod.width) / 25.3 / 12,
      diag = Math.sqrt(Math.pow(actualWidth, 2) + Math.pow(actualHeight, 2)),
      diagIn = diag * 12,
      diagCm = diag * 30.48,
      diagMm = diag * 304.8;

    if (config.format === "In.") {
      return diagIn.toFixed(2);
    } else if (config.format === "cm") {
      return diagCm.toFixed(2);
    } else if (config.format === "mm") {
      return diagMm.toFixed(2);
    } else return diag.toFixed(2);
  }

  renderActualHeight() {
    const config = this.props.config,
      prod = config.currentProd,
      height = config.panelHeightNeeded,
      actualHeight = (height * prod.height) / 25.3 / 12,
      heightIn = actualHeight * 12,
      heightCm = actualHeight * 30.48,
      heightMm = actualHeight * 304.8;

    if (config.format === "In.") {
      return heightIn.toFixed(2);
    } else if (config.format === "cm") {
      return heightCm.toFixed(2);
    } else if (config.format === "mm") {
      return heightMm.toFixed(2);
    } else return actualHeight.toFixed(2);
  }

  renderActualWidth() {
    const config = this.props.config,
      prod = config.currentProd,
      width = config.panelWidthNeeded,
      actualWidth = (width * prod.width) / 25.3 / 12,
      widthIn = actualWidth * 12,
      widthCm = actualWidth * 30.48,
      widthMm = actualWidth * 304.8;

    if (config.format === "In.") {
      return widthIn.toFixed(2);
    } else if (config.format === "cm") {
      return widthCm.toFixed(2);
    } else if (config.format === "mm") {
      return widthMm.toFixed(2);
    } else return actualWidth.toFixed(2);
  }

  renderGridlines() {
    const config = this.props.config;
    if (config.panelWidthNeeded > 0) {
      return (
        <div className="gridlines">
          <div className="height-tag">
            {this.renderActualHeight()} {config.format}
          </div>
          <div className="width-tag">
            {this.renderActualWidth()} {config.format}
          </div>
        </div>
      );
    }
  }

  determinePanelType = (w, h) => {
    console.log(w);
    console.log(h);
    if (w === 500 && h === 1000) {
      return xlPanel;
    } else if (w === 1200 && h === 300) {
      return panel_panorama;
    } else if (w === 600 && h === 337.5) {
      return panel_insite;
    } else {
      return panel;
    }
  };

  renderPanelRows = () => {
    const { config } = this.props,
      rowWidth = config.panelWidthNeeded * 50,
      rowHeight = config.panelHeightNeeded * 50,
      { currentProd } = config,
      panelType = parseFloat(currentProd.height) > 500 ? xlPanel : panel;

    const renderCol = () => {
      let panelCol = [];
      for (let i = 0; i < this.props.config.panelWidthNeeded; i++) {
        panelCol.push(
          <div className="panel-col-item" key={i}>
            <img
              alt="Panel"
              src={this.determinePanelType(
                parseFloat(currentProd.width),
                parseFloat(currentProd.height)
              )}
              className="panel-product"
            />
          </div>
        );
      }

      return panelCol;
    };

    const panelRowItems = [];
    for (let i = 0; i < this.props.config.panelHeightNeeded; i++) {
      panelRowItems.push(
        <div className="panel-row-item" key={i}>
          {renderCol()}
        </div>
      );
    }

    return panelRowItems;
  };

  renderPanelRowItems() {
    const panelRowItems = [];
    for (let i = 0; i < this.props.config.panelWidthNeeded; i++) {
      panelRowItems.push(
        <div className="panel-row-item" key={i}>
          <img alt="Panel" src={panel} className="panel-product" />
        </div>
      );
    }
    return panelRowItems;
  }

  render() {
    const { config, openedTab, viewBar } = this.props,
      { scale, translation } = this.state,
      widthAndHeight = config.widthSelected && config.heightSelected && viewBar;
    return (
      <div
        style={{
          height: "100%",
          overflow: "hidden",
        }}
      >
        <section className={widthAndHeight ? "view-info" : "view-info active"}>
          <div
            className={
              parseFloat(config.widthSelected) > 0 ? "dimBox active" : "dimBox"
            }
          >
            <h3> Actual Dimensions </h3>
            <div>
              width: {this.renderActualWidth()} {config.format}
            </div>
            <div>
              height: {this.renderActualHeight()} {config.format}
            </div>
            <div>
              diagonal: {this.renderActualDiag()} {config.format}
            </div>
            <div> ratio: {this.props.config.ratio}: 1 </div>
          </div>
          <div className="panel-selector">
            <Tooltip placement="top" title="Overview">
              <i
                onClick={this.props.handleTab("overviewTab")}
                className={
                  openedTab === "overviewTab"
                    ? "far fa-box active"
                    : "far fa-box"
                }
              />
            </Tooltip>
            <Tooltip placement="top" title="Video/Electrical">
              <i
                onClick={this.props.handleTab("videoTab")}
                className={
                  openedTab === "videoTab"
                    ? "far fa-bolt active"
                    : "far fa-bolt"
                }
              />
            </Tooltip>
            <Tooltip placement="top" title="Rigging/Handling">
              <i
                onClick={this.props.handleTab("handlingTab")}
                className={
                  openedTab === "handlingTab"
                    ? "far fa-hand-spock active"
                    : "far fa-hand-spock"
                }
              />
            </Tooltip>
            <Tooltip placement="top" title="Your Quote">
              <i
                onClick={this.props.handleTab("quoteTab")}
                className={
                  openedTab === "quoteTab"
                    ? "far fa-file-invoice-dollar active"
                    : "far fa-file-invoice-dollar"
                }
              />
            </Tooltip>
          </div>
          {this.renderOverview()}
        </section>
        <div className="zoom-controls">
          <i
            onClick={() =>
              this.setState({
                value: {
                  ...this.state.value,
                  scale: this.state.value.scale + 0.25,
                },
              })
            }
            className="far fa-search-plus"
          />
          <i
            onClick={() =>
              this.setState({
                value: {
                  ...this.state.value,
                  scale: this.state.value.scale - 0.25,
                },
              })
            }
            className="far fa-search-minus"
          />
        </div>
        <div className="view-body">
          <MapInteractionCSS
            value={this.state.value}
            translation={translation}
            onChange={(value) => this.setState({ value })}
          >
            <section id="view-render">
              {this.renderVideo()} {this.renderGridlines()}
              {this.renderPanelRows()}
            </section>
          </MapInteractionCSS>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CustomerView);
