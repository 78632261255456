import React from "react";
import { Button, Chip, Grid, Paper, Typography } from "@material-ui/core";
import { ContactInterface } from "interfaces/Contact";
import ContactEditForm from "./ContactEditForm";

interface ContactCardProps {
    companyId: number,
    contact: ContactInterface,
    canEdit?: boolean,
    updateCallback?: (contactData: ContactInterface, id: number) => void,
    deleteCallback?: (contactId: number) => void,
    autoOpenContact?: ContactInterface
}

const ContactCard = ({ companyId, contact, canEdit = true, updateCallback, deleteCallback, autoOpenContact }: ContactCardProps) => {

    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        if(autoOpenContact && autoOpenContact.id == contact.id) {
            setEditing(true);
        }
    }, [contact, autoOpenContact]);

    const updateCallbackWithClose = (contactData: ContactInterface, id: number) => {
        updateCallback(contactData, id);
        setEditing(false);
    }

    const deleteCallbackWithClose = (contactId: number) => {
        deleteCallback(contactId)
        setEditing(false);
    }


    const contactTypes = React.useMemo(() => {
        return contact.contactTypes.sort((a, b) => a.id - b.id).map(type => type.name).join("  |  ");
    }, [contact]);

    return (
        <Paper elevation={2} className="contact-card">
            <Grid container spacing={2}>
                <Grid item xs={12} md={5} container spacing={2} style={{ textAlign: "center" }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {contact.firstName + " " + contact.lastName}
                            {(contact.title ? <span style={{ fontSize: 15, fontStyle: "italic" }}> - {contact.title}</span> : "")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Chip
                            label={contactTypes}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {canEdit && (
                            <Button
                                variant={editing ? "contained" : "outlined"}
                                color={editing ? "secondary" : "secondary"}
                                onClick={() => setEditing(!editing)}
                                fullWidth
                            >
                                {editing ? "Cancel" : "Edit"}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} container spacing={2}>
                    <Grid item container justifyContent="center" alignItems="center" className="center-text" xs={12} md={6}>
                        <Grid item xs={3} md={12}>
                            <i className="fa-regular fa-envelope"></i>
                        </Grid>
                        <Grid item xs={9} md={12}>
                            <Typography variant="body1" style={{ textAlign: "center" }}>{!contact.email || contact.email.length === 0 ? "None" : <a href={"mailto:" + contact.email}>{contact.email}</a>}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" className="center-text" xs={12} md={6}>
                        <Grid item xs={3} md={12}>
                            <i className="fa-solid fa-phone"></i>
                        </Grid>
                        <Grid item xs={9} md={12}>
                            <Typography variant="body1" style={{ textAlign: "center" }}>{!contact.phoneNumber || contact.phoneNumber.length === 0 ? "None" : contact.phoneNumber}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {editing && <ContactEditForm companyId={companyId} contact={contact} updateCallback={updateCallbackWithClose} deleteCallback={deleteCallbackWithClose} />}
            </Grid>
        </Paper>
    );
};

export default ContactCard;