import React from "react";
import _ from "lodash";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@material-ui/core";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import { useWindowWidth } from "hooks/useWindowSize";
import { BuildWebOrdersList, WebOrderInterface, WebOrderStatusOptions } from "interfaces/WebOrder";
import WebOrderSearchResult from "./WebOrderSearchResult";
import { makeAxiosCall } from "utils";
import { BuildWebCustomerList, SimpleWebCustomerInterface, WebCustomerInterface } from "interfaces/WebCustomer";
import WebCustomerSearchResult from "../Customer/WebCustomerSearchResult";
import { DateRange, Delete } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import moment, { Moment } from "moment";

const PortalOrderSearch = () => {
    const [input, setInput] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [showCustomerList, setShowCustomerList] = React.useState(false);
    const [orderStatusFilters, setOrderStatusFilters] = React.useState<string[]>([]);
    const [filteredList, setFilteredList] = React.useState<WebOrderInterface[]>([]);
    const [sortMethods, setSortMethods] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [totalOrdered, setTotalOrdered] = React.useState(0);
    const [totalFulfilled, setTotalFulfilled] = React.useState(0);
    const [filterStartDate, setFilterStartDate] = React.useState<Moment>();
    const [filterEndDate, setFilterEndDate] = React.useState<Moment>();
    const [totalPaid, setTotalPaid] = React.useState(0);
    const windowWidth = useWindowWidth();
    const [allOrders, setAllOrders] = React.useState<WebOrderInterface[]>();
    const [allCustomers, setAllCustomers] = React.useState<SimpleWebCustomerInterface[]>();

    const windowScrollerRef = React.useRef<WindowScroller>();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 800,
                width: 250,
            },
        },
    };

    const sortOptions = [
        {
            label: "SO Num (A - Z)",
            value: "soNumber",
            order: "asc",
        },
        {
            label: "SO Num (Z - A)",
            value: "soNumber",
            order: "desc",
        },
        {
            label: "Newest",
            value: "createdAt",
            order: "desc",
        },
        {
            label: "Oldest",
            value: "createdAt",
            order: "asc",
        },
        {
            label: "Customer First (A - Z)",
            value: "customer.first_name",
            order: "asc",
        },
        {
            label: "Customer First (Z - A)",
            value: "company.first_name",
            order: "desc",
        },
        {
            label: "Customer Last (A - Z)",
            value: "customer.last_name",
            order: "asc",
        },
        {
            label: "Customer First (Z - A)",
            value: "customer.last_name",
            order: "desc",
        },
    ];

    React.useEffect(() => {
        let oneWeekAgo = moment();
        oneWeekAgo.add(-1, "month");
        setFilterStartDate(oneWeekAgo);
        setFilterEndDate(moment());
        fetchWebOrders();
        fetchWebCustomers();
    }, []);

    React.useEffect(() => {
        calculateTotals();
    }, [filteredList]);

    React.useEffect(() => {
        if (allOrders) {
            filterOrders();
            setLoading(false);
        }
    }, [allOrders, orderStatusFilters, filterStartDate, filterEndDate ]);

    React.useLayoutEffect(() => {
        // Adjust the scroll view when filters are opened or closed
        if (windowScrollerRef && windowScrollerRef.current) {
            windowScrollerRef.current.updatePosition();
        }
    }, [showFilters]);

    const fetchWebOrders = async () => {
        setLoading(true);
        const response = await makeAxiosCall(
            "get",
            "web-orders",
        );

        if (response.data) {
            const orderList = BuildWebOrdersList(response.data);
            setLoading(false);
            if(orderList && orderList.length) {
                setAllOrders(orderList);
            }
        }
    };


    const fetchWebCustomers = async () => {
        const response = await makeAxiosCall(
            "get",
            "all-customer-info",
        );

        if (response.data) {
            const customerList = BuildWebCustomerList(response.data);
            if(customerList && customerList.length) {
                setAllCustomers(customerList);
            }
        }
    };

    const filterOrders = () => {
        // Only apply filters once one is selected
        const applyFilters = orderStatusFilters.length > 0;
        let results: WebOrderInterface[] = [];

        if (applyFilters) {
            const orderStatusResults = [];

            allOrders.forEach((order) => {
                if (orderStatusFilters.some((status) => status == order.financialStatus || status == order.fulfillmentStatus)) {
                    orderStatusResults.push(order);
                }
            });
            results = orderStatusResults;
        } else {
            results = [...allOrders];
        }

        if(filterStartDate || filterEndDate) {
            let filterStartDatetime = null;
            if(filterStartDate) {
                filterStartDatetime = filterStartDate.hour(0).minute(0).toDate().getTime();
            }
            let filterEndDatetime = null;
            if(filterEndDate) {
                filterEndDatetime = filterEndDate.hour(23).minute(59).toDate().getTime();
            }
            let filteredResultsByDate = [];
            for (let woi = 0; woi < results.length; woi++) {
                const wo = results[woi];
                let createdDate = wo.createdAt;
                createdDate.setHours(0);
                
                if(filterStartDatetime && (createdDate.getTime() <= filterStartDatetime)) {
                    continue;
                }


                if(filterEndDatetime && (createdDate.getTime() >= filterEndDatetime)) {
                    continue;
                }

                filteredResultsByDate.push(wo);
            }

            results = filteredResultsByDate;
        }


        setFilteredList(results);
    };

    const sortOrders = (searchResults: WebOrderInterface[]) => {
        let returnable: WebOrderInterface[];

        if (sortMethods.length > 0) {
            returnable = _.orderBy(
                searchResults,
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).value),
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
            );
        } else {
            returnable = _.orderBy(searchResults, (order: WebOrderInterface) => order.createdAt, ["desc"]);
        }

        return returnable;
    };


    const calculateTotals = () => {
        let newTotalOrdered = 0;
        let newTotalPaid = 0;
        let newTotalFullfilled = 0;

        for (let woi = 0; woi < filteredList.length; woi++) {
            const webOrder = filteredList[woi];

            newTotalOrdered += webOrder.total;

            if(webOrder.financialStatus == "paid") {
                newTotalPaid += webOrder.total;
            } else if(webOrder.financialStatus == "partially_refunded") {
                newTotalPaid += (webOrder.total - (webOrder.refundedAmount ?? 0));
            }

            if(webOrder.fulfillmentStatus == "fulfilled") {
                newTotalFullfilled += webOrder.total;
            }
        }

        setTotalFulfilled(newTotalFullfilled);
        setTotalOrdered(newTotalOrdered);
        setTotalPaid(newTotalPaid);
    };

    const renderOrderStatusFilterOption = (option: string) => {
        let prettyOption = option.charAt(0).toUpperCase() + option.slice(1);
        prettyOption = prettyOption.replace("_", " ");
        
        return (
            <Grid key={option + "-filter-options"} item xs={12} md={4}>
                <Tooltip title={prettyOption} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={orderStatusFilters.some((status) => status === option)}
                                onChange={(e) => {
                                    if (orderStatusFilters.some((type) => type === option)) {
                                        setOrderStatusFilters(orderStatusFilters.filter((type) => type !== option));
                                    } else {
                                        setOrderStatusFilters([...orderStatusFilters, option]);
                                    }
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={prettyOption}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderOrderStatusFilters = () => {
        return WebOrderStatusOptions.map((type) => {
            return renderOrderStatusFilterOption(type);
        });
    };

    const renderSortOptions = () => {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    key="sortMethods"
                    multiple
                    value={sortMethods}
                    renderValue={(_) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {sortMethods.map((method) => (
                                <Chip key={method} label={method} style={{ marginRight: 5 }} />
                            ))}
                        </Box>
                    )}
                    onChange={(e: React.ChangeEvent<{
                        name?: string;
                        value: string[];
                    }>) => {
                        setSortMethods(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    {sortOptions.map(
                        (option) => {
                            const index = sortMethods.findIndex((n) => n == option.label);
                            return (
                                <MenuItem
                                    key={option.label}
                                    value={option.label}
                                >
                                    <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </FormControl>
        );
    };

    const searchResults = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        const hits = _.filter(filteredList, (order: WebOrderInterface) => {
            return _.includes(order.customer ? order.customer.firstName.toLowerCase() : "", searchInput) || _.includes(order.customer ? order.customer.lastName.toLowerCase() : "", searchInput) || (order.soNumber && _.includes(order.soNumber.toLowerCase(), searchInput));
        });

        return sortOrders(hits);
    }, [filteredList, input, sortMethods]);

    const customerSearchResults = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        const hits = _.filter(allCustomers, (customer: WebCustomerInterface) => {
            return (
                _.includes(customer.firstName.toLowerCase(), searchInput) || 
                _.includes(customer.lastName.toLowerCase(), searchInput) || 
                _.includes(customer.email.toLowerCase(), searchInput)
            );
        });

        return hits;
    }, [allCustomers, input]);

    const renderRow = ({ index, key, style }) => {
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <WebOrderSearchResult order={searchResults[index]} mobileMode={windowWidth < 700} />
                </div>
            </div>
        );
    };

    const renderCustomerRow = ({ index, key, style }) => {
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <WebCustomerSearchResult customer={customerSearchResults[index]} mobileMode={windowWidth < 700} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return <p className="body-message center-text">No {showCustomerList ? "Customers" : "Orders"} Found</p>;
    };

    const renderTableHeader = (show: boolean) => {
        if(!show) return;
        return (showCustomerList ? <Grid container item xs={12} className="center-text grid-search-headers">
            <Grid item xs={3}  className="result-name">
                Name
            </Grid>
            <Grid item xs={4}>
                Email
            </Grid>
            <Grid item xs={2}>
                Rewards
            </Grid>
            <Grid item xs={2}>
                Exempt
            </Grid>
            <Grid item xs={1}>
                View
            </Grid>
        </Grid> : 
        <Grid container item xs={12} className="center-text grid-search-headers">
            <Grid item container xs={3}>
                <Grid item xs={5} className="result-name">
                    SO
                </Grid>
                <Grid item xs={2}>
                    Date
                </Grid>
            </Grid>
            <Grid item xs={3}>
                Customer
            </Grid>
            <Grid item xs={1}>
                Total
            </Grid>
            <Grid item xs={2}>
                Financial
            </Grid>
            <Grid item xs={2}>
                Fulfillment
            </Grid>
            <Grid item xs={1}>
                View
            </Grid>
        </Grid>);
    };

    const renderTotals = () => {
        if(showCustomerList) return;
        return (<Grid container item xs={12} className="center-text" 
            style={{
                fontWeight: "bold",
                marginTop: 16,
                marginBottom: 24
            }}>
            <Grid item xs={4}>
                Total Ordered: {
                    new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                    }).format(totalOrdered)
                }
            </Grid>
            <Grid item xs={4}>
                Total Paid: {
                    new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                    }).format(totalPaid)
                }
            </Grid>
            <Grid item xs={4}>
                Total Fulfilled: {
                    new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                    }).format(totalFulfilled)
                }
            </Grid>
        </Grid>);
    };

    return allOrders || (searchResults && searchResults.length) ? (
        <div className="grid-search">
            <h3 className="title">Web Orders</h3>
            <Grid container className="container-spacing">
            <Grid item container xs={12} md={6}>
                    <Grid item xs={6}>
                        <p style={{marginTop: 0}}>Start Date</p>
                        <DatePicker 
                            disabled={showCustomerList}
                            value={filterStartDate}
                            onChange={function (date: Moment): void {
                                setFilterStartDate(date);
                            }}
                            format={"MM/DD/yy"}
                            style={{width: "80%"}}
                        />
                        <Delete onClick={() => setFilterStartDate(null)} />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <p style={{marginTop: 0}}>End Date</p>
                        <DatePicker 
                            disabled={showCustomerList}
                            disableFuture={true}
                            value={filterEndDate}
                            onChange={function (date: Moment): void {
                                setFilterEndDate(date);
                            }}
                            format={"MM/DD/yy"}
                            style={{width: "80%"}}
                        />
                        <Delete onClick={() => setFilterEndDate(moment())} />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="search-field">
                    <TextField
                        label="Order Search"
                        variant="outlined"
                        onChange={(e) => setInput(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item container alignItems="center" xs={12} spacing={2} style={{marginTop: 12}}>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={6} md={3}>
                        <Button
                            variant={showCustomerList ? "contained" : "outlined"}
                            color={showCustomerList ? "primary" : "secondary"}
                            onClick={() => setShowCustomerList(!showCustomerList)}
                            fullWidth
                        >
                            {showCustomerList ? "Show Orders" : "Show Customers"}
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Button
                            variant={showFilters ? "contained" : "outlined"}
                            color={showFilters ? "primary" : "secondary"}
                            onClick={() => setShowFilters(!showFilters)}
                            fullWidth
                        >
                            {showFilters ? "Hide Filters " : "Show Filters "} {showCustomerList ? `(${customerSearchResults ? customerSearchResults.length : ""})` : `(${searchResults ? searchResults.length : ""})`}
                        </Button>
                    </Grid>
                </Grid>
                {showFilters && (
                    <>
                        <Grid item container xs={9}>
                            <Grid item container alignContent="flex-start" xs={12}>
                                <Grid item xs={12}>Order Status</Grid>
                                {renderOrderStatusFilters()}
                            </Grid>
                            {/* <Grid item container alignContent="flex-start" xs={6}>
                <Grid item xs={12}>Different Filter</Grid>
                <p>product?</p>
              </Grid> */}
                        </Grid>
                        <Grid item alignContent="flex-start" container xs={3}>
                            <Grid item xs={12}>Sort By</Grid>
                            <Grid item xs={12}>
                                {renderSortOptions()}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container className="results">
                {renderTotals()}
                {renderTableHeader(windowWidth > 700)}
                {loading ?
                    (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) :
                    (<WindowScroller ref={windowScrollerRef}>
                        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                            return showCustomerList ? <List
                                autoHeight
                                width={width}
                                height={height}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowHeight={70}
                                rowRenderer={renderCustomerRow}
                                noRowsRenderer={renderPlaceholder}
                                rowCount={customerSearchResults.length}
                                overscanRowCount={3}
                            /> : <List
                                autoHeight
                                width={width}
                                height={height}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowHeight={70}
                                rowRenderer={renderRow}
                                noRowsRenderer={renderPlaceholder}
                                rowCount={searchResults.length}
                                overscanRowCount={3}
                            />;
                            
                        }}
                    </WindowScroller>)
                }
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default PortalOrderSearch;
