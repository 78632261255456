import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { AddressInterface } from "interfaces/Address";
import { Autocomplete } from "@material-ui/lab";
import { InputLabel, TextField } from "@material-ui/core";

type AddressSelectProps = {
    setNewFormActive: (active: boolean) => void,
    addresses: AddressInterface[],
    title?: string,
    currentSelection?: AddressInterface,
    onSelect?: (address: AddressInterface) => void,
    hasError?: boolean,
    label?: string
}

const AddressSelect = ({ setNewFormActive, title, addresses, currentSelection, onSelect, hasError, label }: AddressSelectProps) => {
    const [error, setError] = React.useState(hasError);
    const [selectedAddress, setSelectedAddress] = React.useState<AddressInterface>(currentSelection);
    const [inputValue, setInputValue] = React.useState<string>("");
    const inputLabel = React.useRef(null);

    React.useEffect(() => {
        if (currentSelection != selectedAddress) {
            handleChange(currentSelection);
        }
    }, [currentSelection]);

    React.useEffect(() => {
        if (error != hasError) {
            setError(hasError);
        }
    }, [hasError]);

    const handleChange = (newAddress: AddressInterface) => {
        setSelectedAddress(newAddress);
        if (inputValue != buildOptionText(newAddress)) {
            setInputValue(buildOptionText(newAddress));
        }
        if (onSelect) {
            onSelect(newAddress);
        }
        setNewFormActive(false);
    };

    const buildOptionText = (option: AddressInterface) => `${option.contactName} (${option.streetAddress}, ${option.city}, ${option.state} ${option.zip})`;

    return (
        <div className="address-select">
            <FormControl
                error={error}
                style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                variant="outlined"
            >
                {title && <h3>{title}</h3>}
                {label ? <InputLabel ref={inputLabel} id="address-select-outlined-label">
                    {label}
                </InputLabel> : null}

                <Autocomplete
                    options={addresses}
                    value={selectedAddress}
                    onChange={(event, newValue: AddressInterface) => {
                        if (newValue) {
                            handleChange(newValue);
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newValue) => {
                        setInputValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    renderOption={option => buildOptionText(option)}
                    getOptionLabel={option => buildOptionText(option)}
                />

            </FormControl>
        </div>
    );
};

export default AddressSelect;
