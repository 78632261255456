import React from "react";
import Loading from "components/utils/Loading";
import _ from "lodash";
import { CompanyInterface } from "interfaces/Company";
import { Grid } from "@material-ui/core";
import { UserContext } from "context/User";
import { makeAxiosCall } from "utils";
import { DocumentInterface, buildCompanyDocumentList } from "interfaces/Documents";
import { CompanyContext } from "context/Company";
import CompanyDocumentList from "components/MyCompany/CompanyDocumentList";

interface CompanyDocumentsProps {
  company: CompanyInterface,
}

const CompanyDocuments = ({ company }: CompanyDocumentsProps) => {

    const { currentUser, internalUser } = React.useContext(UserContext);
    const { companyTypes } = React.useContext(CompanyContext);
    const [companyDocuments, setCompanyDocuments] = React.useState<DocumentInterface[]>();

    let isMounted = true;
    React.useEffect(() => {
        if (company) {
            fetchCompanyDocuments();
        }
        return () => {
            isMounted = false;
        };
    }, [company]);

    const fetchCompanyDocuments = async () => {
        const companyTypeId = companyTypes.find((type) => type.name == company.companyType).id ?? 1;
        const response = await makeAxiosCall(
            "get",
            `company-documents/${companyTypeId}/${company.id}`
        ).catch(error => {
            console.error("Caught error getting company documents:");
            console.error(error);
        });

        if (response.data) {
            const documentList = buildCompanyDocumentList(response.data);

            if (isMounted) {
                setCompanyDocuments(documentList);
            }
        }
    };

    const visibleDocuments: DocumentInterface[] = React.useMemo(() => {
        if (companyDocuments) {
            return _.orderBy(
                companyDocuments.filter(document => document.companyId && document.accountLevelId <= currentUser.accountLevel.id),
                ["createdAt"],
                ["desc"],
            );
        } else {
            return null;
        }
    }, [companyDocuments, currentUser]); 

    const genericDocuments: DocumentInterface[] = React.useMemo(() => {
        if (companyDocuments) {
            return _.orderBy(
                companyDocuments.filter(document => !document.companyId && document.accountLevelId <= currentUser.accountLevel.id),
                ["createdAt"],
                ["desc"]
            );
        } else {
            return null;
        }
    }, [companyDocuments, currentUser]);

    return company ? (
        <>
            <Grid container alignItems="center" >
                <>
                    {(internalUser || (visibleDocuments != null && visibleDocuments.length)) ? <CompanyDocumentList 
                        company={company}
                        documents={visibleDocuments}
                        title={"Company Documents"}
                        refreshDocuments={fetchCompanyDocuments}
                    /> : <></>}
                    {(genericDocuments != null && genericDocuments.length) ? <CompanyDocumentList 
                        company={company}
                        documents={genericDocuments}
                        title={"General Documents"}
                        refreshDocuments={fetchCompanyDocuments}
                        generic={true}
                    /> : <></>}
                </>
            </Grid>
        </>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyDocuments;