import SuccessAni from "components/utils/SuccessAni";

const ThankYou = () => {

  return (
    <div className="view" style={{ textAlign: "center" }}>
      <>
        <SuccessAni title="Order Submitted Successfully" marginTop={100} />
        <p style={{ fontSize: "2em" }}>
          If you have questions about your order feel free to reach out to
          <a style={{ paddingLeft: 10 }} href="mailto:orders@blizzardpro.com">
            orders@blizzardpro.com
          </a>
        </p>
      </>
    </div>
  );
};

export default ThankYou;
