import React from "react";
import { Button, Chip, Grid, Paper, Typography } from "@material-ui/core";
import { AddressInterface } from "interfaces/Address";
import AddressForm from "components/Account/Address/AddressForm";
import AdditionalShippingDetails from "components/utils/AdditionalShippingDetails";

interface AddressCardProps {
    companyId: number,
    address: AddressInterface,
    canEdit?: boolean,
    updateCallback?: (address: AddressInterface, id: number) => void,
    deleteCallback?: (addressId: number) => void,
}

const AddressCard = ({ companyId, address, canEdit = true, updateCallback, deleteCallback }: AddressCardProps) => {
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        
    }, [address]);

    const addressTypes = React.useMemo(() => {
        return address.addressTypes.sort((a, b) => a.id - b.id).map(type => type.name).join("  |  ");
    }, [address]);

    const updateCallbackWithClose = (address: AddressInterface, id: number) => {
        updateCallback(address, id);
        setEditing(false);
    }

    const deleteCallbackWithClose = (addressId: number) => {
        deleteCallback(addressId)
        setEditing(false);
    }

    return (
        <Paper elevation={2} className="contact-card">
            <Grid container spacing={2}>
                <Grid item container xs={12} md={4} spacing={2} style={{ textAlign: "center" }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{address.contactName}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Chip
                            label={addressTypes}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {canEdit && (
                            <Button
                                variant={editing ? "contained" : "outlined"}
                                color={editing ? "secondary" : "secondary"}
                                onClick={() => {
                                    setEditing(!editing);
                                    console.log("click");
                                    
                                }}
                                fullWidth
                            >
                                {editing ? "Cancel" : "Edit"}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" xs={12} md={6}>
                    <Grid item xs={12}>
                        <Typography variant="body1">{address.streetAddress}</Typography>
                    </Grid>
                    {address.streetAddressTwo && address.streetAddressTwo.trim().length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="body1">{address.streetAddressTwo}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="body1">{`${address.city}, ${address.state} ${address.zip}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{address.country}</Typography>
                    </Grid>
                </Grid>
                {/* <AdditionalShippingDetails address={address} /> */}
                {editing && <AddressForm companyId={companyId} address={address} updateCallback={updateCallbackWithClose} deleteCallback={deleteCallbackWithClose} />}
            </Grid>

        </Paper>
    );
};

export default AddressCard;