import React from "react";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Button, Checkbox, Grid, List, ListItem, ListItemText, TextField, Tooltip, Typography, useTheme, Zoom } from "@material-ui/core";
import { PDProjectInterface, PDProjectMediaInterface, PDProjectStatusInterface, PDProjectTaskInterface, PDProjectTaskStatuses, PDProjectTaskTypes } from "interfaces/ProductDevelopment";
import { Assessment, AttachMoney, Build, CheckCircle, Code, Edit, ExpandMore, FileCopy, GetApp, Hotel, LastPage, Launch, MenuBook, Movie, Print, Redeem, Save } from "@material-ui/icons";
import DraftEditor from "components/utils/DraftEditor";
import PDProjectMediaUpload from "./MediaUpload";
import PDProjectMediaListItem from "./MediaListItem";

interface ProjectProgressTaskProps {
    task: PDProjectTaskInterface,
    project: PDProjectInterface,
    saveTask: (task: PDProjectTaskInterface, refresh?: boolean) => void,
    refreshData: () => void,
    defaultMediaCategory?: string
}

const ProjectProgressTask = ({ task, saveTask, project, refreshData, defaultMediaCategory }: ProjectProgressTaskProps) => {
    const [editingTask, setEditingTask] = React.useState<boolean>(false);
    const [newNotes, setNewNotes] = React.useState<string>(task.notes ?? "");
    const [newData, setNewData] = React.useState<string>(task.data ?? "");

    const [selectedMedia, setSelectedMedia] = React.useState<PDProjectMediaInterface[]>([]);
    const theme = useTheme();

    const associatedMedia: PDProjectMediaInterface[] = React.useMemo(() => {
        if (task.type == PDProjectTaskTypes.Media && task.data) {
            let idList = [];
            try {
                idList = JSON.parse(task.data);
            } catch (error) {
                console.error(error);
            }
            return project.media.filter((item) => idList.includes(item.id));
        } else {
            return [];
        }
    }, [project, task.data]);

    const hasNotes: boolean = React.useMemo(() => {
      return task.notes != null && task.notes.length > 0 && task.notes != "<p></p>";
    }, [project, task.data]);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const buildLeadingIcon = () => {
        if (task.data != null) {
            switch (task.type) {
                case PDProjectTaskTypes.Media:
                    // Avatar
                    if (associatedMedia.length) {
                        return (
                            <span
                                style={{ margin: 9 }}
                            >
                                {renderMediaAvatar(associatedMedia[0])}
                            </span>
                        );
                    }
                    break;
                case PDProjectTaskTypes.Link:
                    // Icon
                    return (
                        <span
                            onClick={(event) => {
                                event.stopPropagation();
                                if (task.data != null) {
                                    // Make sure link actually goes to external site
                                    let sanitizedLink = task.data;
                                    if (!task.data.startsWith("http")) {
                                        sanitizedLink = "https://" + task.data;
                                    }
                                    window.open(sanitizedLink, "_blank", "noopener,noreferrer");
                                }
                            }}
                            style={{ lineHeight: 0 }}
                        >
                            <Launch
                                style={{
                                    margin: 9,
                                    color: task.data == null ? "rgba(0, 0, 0, 0.27)" : "inherit",
                                    cursor: task.data == null ? "default" : "pointer"
                                }}
                            />
                        </span>
                    );
                    break;
                case PDProjectTaskTypes.Number:
                    // Icon
                    return (
                        <AttachMoney
                            style={{
                                margin: 9,
                                color: task.data == null ? "rgba(0, 0, 0, 0.27)" : "inherit",
                                cursor: task.data == null ? "default" : "pointer"
                            }}
                        />
                    );
                    break;
                case PDProjectTaskTypes.Checkbox:
                default:
                    break;
            }
        }

        // Checkbox
        return (
            <Checkbox
                checked={[3, 4].includes(task.taskStatus.id)}
                disabled={true}
                onChange={(event) => {
                    event.stopPropagation();    // Prevent accordion from changing
                }}
            />
        );
    }

    const buildTrailingData = () => {
        if (task.data != null) {
            switch (task.type) {
                case PDProjectTaskTypes.Number:
                    return (
                        <Typography style={{ textAlign: "right" }}>
                            ${task.data}
                        </Typography>
                    );
                    break;
                case PDProjectTaskTypes.Media:
                case PDProjectTaskTypes.Link:
                case PDProjectTaskTypes.Checkbox:
                default:
                    return (<></>);
                    break;
            }
        }
    }

    const buildStatusButton = (status: PDProjectStatusInterface) => {
        let currentActive = task.taskStatus.id == status.id;

        let icon;
        switch (status.name) {
            case "Not Started":
                icon = <Hotel />
                break;
            case "In Progress":
                icon = <Build />
                break;
            case "Done":
                icon = <CheckCircle />
                break;
            case "Skipped":
                icon = <LastPage />
                break;
            default:
                icon = <Code />
                break;
        }

        return (
            <Grid item xs={12} md={1} key={"status-button-" + status.id + task.id + task.name}>
                <Button
                    type="submit"
                    fullWidth={true}
                    variant={currentActive ? "contained" : "outlined"}
                    color={currentActive ? "primary" : "secondary"}
                    onClick={(event) => {
                        event.stopPropagation();    // Prevent accordion from changing
                        if (currentActive) return null;
                        else {
                            let updatedTask = { ...task };
                            updatedTask.taskStatus = status;

                            saveTask(updatedTask);
                        }
                    }}
                    className="btn"
                >
                    <Tooltip
                        title={status.name}
                    >
                        {icon}
                    </Tooltip>
                </Button>
            </Grid>
        );
    }

    const buildStatusButtons = () => {
        const statusButtons = [];

        for (let sbi = 0; sbi < PDProjectTaskStatuses.length; sbi++) {
            const status = PDProjectTaskStatuses[sbi];
            statusButtons.push(buildStatusButton(status));
        }

        return statusButtons;
    }

    const buildDataEntry = () => {
        switch (task.type) {
            case PDProjectTaskTypes.Media:
                return (
                    <Grid item container xs={12}>
                        <Grid item xs={12} md={8}></Grid>
                        <Grid item xs={12} md={4}>
                            <PDProjectMediaUpload
                                project={project}
                                defaultCategory={defaultMediaCategory}
                                creationCallback={(response: any) => {
                                    let existingMedia = associatedMedia.map((item) => item.id);
                                    existingMedia.push(response.data.id);
                                    let updatedTask = { ...task };
                                    updatedTask.data = JSON.stringify(existingMedia);

                                    setNewData(updatedTask.data);

                                    saveTask(updatedTask, true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                {associatedMedia.map((mediaItem) => {
                                    return (
                                        <PDProjectMediaListItem
                                            key={mediaItem.id + "-media-item"}
                                            mediaItem={mediaItem}
                                            project={project}
                                            refreshData={refreshData}
                                            selected={selectedMedia.includes(mediaItem)}
                                            selectCallback={handleSelectMedia}
                                            deleteCallback={handleDeleteMedia}
                                        />
                                    );
                                })}
                                {associatedMedia.length > 0 && (
                                    <ListItem key="bulkzone" alignItems="flex-start" style={{ height: 50 }}>
                                        <ListItemText primary={" "} />
                                        <Zoom
                                            in={selectedMedia.length > 0}
                                            timeout={transitionDuration}
                                            style={{
                                                transitionDelay: `${selectedMedia.length > 0 ? transitionDuration.exit : 0}ms`,
                                            }}
                                            unmountOnExit
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color={"primary"}
                                                className="btn"
                                                fullWidth={true}
                                                onClick={() => {
                                                    handleBulkDownload();
                                                }}
                                                endIcon={
                                                    <GetApp />
                                                }
                                                style={{ marginLeft: 15 }}
                                            >
                                                {"Zip and Download " + selectedMedia.length + " file" + (selectedMedia.length > 1 ? "s" : "")}
                                            </Button>
                                        </Zoom>

                                        <Checkbox
                                            checked={selectedMedia.length == associatedMedia.length}
                                            onChange={handleSelectAllDownloads}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                );
                break;
            case PDProjectTaskTypes.Link:
                return (
                    <TextField
                        aria-label="project-link"
                        label={"URL"}
                        variant="outlined"
                        type={"url"}
                        fullWidth={true}
                        placeholder="https://blizzardlightingllc.freshdesk.com"
                        value={newData}
                        disabled={!editingTask}
                        onChange={(e) => {
                            setNewData(e.target.value);
                        }}
                    />
                );
                break;
            case PDProjectTaskTypes.Number:
                return (
                    <TextField
                        aria-label="project-price"
                        label={"Price"}
                        variant="outlined"
                        type={"number"}
                        fullWidth={true}
                        placeholder="129.99"
                        value={newData}
                        disabled={!editingTask}
                        onChange={(e) => {
                            setNewData(e.target.value);
                        }}
                    />
                );
            case PDProjectTaskTypes.Checkbox:
            default:
                break;
        }
    }

    const handleSelectMedia = (documentId: number) => {
        if (selectedMedia.some((i) => i.id == documentId)) {
            setSelectedMedia(selectedMedia.filter((i) => i.id != documentId));
        } else {
            setSelectedMedia([...selectedMedia, associatedMedia.find((i) => i.id == documentId)]);
        }
    };

    const handleSelectAllDownloads = () => {
        if (selectedMedia.length == associatedMedia.length) {
            setSelectedMedia([]);
        } else {
            setSelectedMedia([...associatedMedia]);
        }
    };

    const handleDeleteMedia = (documentId: number) => {
        let updatedTask = { ...task };
        updatedTask.data = JSON.stringify(associatedMedia.filter((item) => item.id != documentId).map((item) => item.id));

        saveTask(updatedTask);
    }

    const handleBulkDownload = async () => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = "pd-project-documents.zip";

        selectedMedia.forEach((file) => {

            console.log(file);
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }

                zip.file(file.firebaseName, data, { binary: true });
                count++;
                if (count == selectedMedia.length) {
                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        FileSaver.saveAs(content, zipFilename);
                        setSelectedMedia([]);
                    });
                }
            });
        });
    };

    const renderMediaAvatar = (document: PDProjectMediaInterface) => {
        let index = document.firebaseName.lastIndexOf(".");
        let filetype = document.firebaseName.substring(index + 1).trim().toLowerCase();
        switch (filetype) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return <Avatar
                    alt={document.name}
                    src={document.url}
                    onClick={() => handleSelectMedia(document.id)}
                    style={{ display: "inline-block" }}
                />;
            case "pdf":
            case "doc":
            case "docx":
            case "txt":
                return <MenuBook onClick={() => handleSelectMedia(document.id)} />
            case "dxf":
                return <Redeem onClick={() => handleSelectMedia(document.id)} />
            case "xls":
            case "xlsx":
                return <Assessment onClick={() => handleSelectMedia(document.id)} />
            case "svg":
            case "dwg":
                return <Print onClick={() => handleSelectMedia(document.id)} />
            case "mov":
            case "mp4":
            case "avi":
            case "wmv":
                return <Movie onClick={() => handleSelectMedia(document.id)} />
            default:
                return <FileCopy onClick={() => handleSelectMedia(document.id)} />;
        }
    }

    const buildNotes = () => {
        return editingTask ? (
            <Grid item xs={12} id={task.id + "task-notes-editor"}>
                <DraftEditor
                    onChange={(newHTML: string) => {
                        setNewNotes(newHTML);
                    }}
                    startingHTML={task.notes}
                    style={{ width: "100%" }}
                    fieldName={"Notes"}
                    alwaysShowToolbar={true}
                />
            </Grid>
        ) : (
            <Grid item xs={12}>
                <p style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: task.notes }} />
            </Grid>
        );
    }

    const buildActions = () => {
        return (
            <>
                {editingTask && (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => { setEditingTask(!editingTask) }}
                        className="btn red"
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (editingTask) {
                            let updatedTask = { ...task };
                            updatedTask.notes = newNotes;
                            updatedTask.data = newData;

                            saveTask(updatedTask);
                        }

                        setEditingTask(!editingTask);
                    }}
                    className="btn"
                >
                    {editingTask ? <Save /> : (
                        !hasNotes && (task.type == PDProjectTaskTypes.Checkbox || task.type == PDProjectTaskTypes.Media) ? "Add Note" : <Edit />
                    )}
                </Button>
            </>
        );
    }

    const buildListItem = () => {
        return (
            <Accordion
                key={task.id + task.name + '-task'}
                elevation={2}
                style={{
                    margin: 8
                }}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMore 
                            style={{ margin: 8 }}
                        />
                    }
                    style={{
                        fontWeight: "bold",
                        boxShadow: "none",
                        alignItems: "center",
                    }}
                    className="line-item"
                >
                    {buildLeadingIcon()}
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Typography>
                                {task.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} md={4}>
                            {buildTrailingData()}
                        </Grid>
                        {buildStatusButtons()}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        borderTop: "1px solid lightgrey",
                        paddingTop: 24,
                        marginTop: 12
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {buildDataEntry()}
                        </Grid>
                        {hasNotes && (<Grid item xs={12} style={{ borderBottom: "thin solid lightgray" }}>
                            <Typography
                                style={{ fontWeight: "bold" }}
                            >
                                Notes
                            </Typography>
                        </Grid>)}
                        {buildNotes()}
                    </Grid>
                </AccordionDetails>
                <AccordionActions>
                    {buildActions()}
                </AccordionActions>
            </Accordion>
        );
    }

    return buildListItem();
};

export default ProjectProgressTask;