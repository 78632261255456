import React, { createContext } from "react";
import { BuildPDProjectList, PDProjectInterface } from "interfaces/ProductDevelopment";
import { makeAxiosCall } from "utils";
import { UserContext } from "./User";

interface ProductDevelopmentContextInterface {
  projects: Array<PDProjectInterface>,
  fetchAllProjects: () => {},
}

export const ProductDevelopmentContext = createContext<ProductDevelopmentContextInterface>(null);

interface ProductDevelopmentProviderProps {
  children: any,
}

export const ProductDevelopmentProvider = ({ children }: ProductDevelopmentProviderProps) => {
  const [projects, setProjects] = React.useState<PDProjectInterface[]>([]);
  const { currentUser, internalUser } = React.useContext(UserContext);

  const fetchAllProjects = async () => {
    const response = await makeAxiosCall(
      "get",
      "pd/projects",
    );

    if (response.data) {
      const projectList = BuildPDProjectList(response.data);
      setProjects(projectList);
    }
  };

  React.useEffect(() => {
    if (currentUser && internalUser && projects.length == 0) {
      fetchAllProjects();
    }
  }, [currentUser, internalUser]);

  return (
    <ProductDevelopmentContext.Provider
      value={{
        projects,
        fetchAllProjects,
      }}
    >
      {children}
    </ProductDevelopmentContext.Provider>
  )
}