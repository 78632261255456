import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Channel from "components/ProfileEditor/Channel";
import _ from "lodash";
import { ChannelInterface } from "interfaces/ProfileEditor";
// fake data generato

type ProfileProps = {
  channels: ChannelInterface[],
  removeChannelFromFixture: (deleteKey: number, type: string) => void,
  appendChannelToFixture: (obj: any, index: any) => void,
  onDragEnd: (result: any) => void,
  setNewSegment: React.Dispatch<React.SetStateAction<number>>,
  editChannelName: (name: string, index: number) => void,
  activeSegment: number,
}

export default class DraggableLists extends Component<ProfileProps> {
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        const {
            channels,
            removeChannelFromFixture,
            appendChannelToFixture,
            onDragEnd,
            setNewSegment,
            editChannelName,
            activeSegment,
        } = this.props;

        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: "none",

            // change background colour if dragging
            background: isDragging ? "#282bf6" : "",

            // styles we need to apply on draggables
            ...draggableStyle,
        });

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {_.map(channels, (item: ChannelInterface, index: number) => {
                                return (
                                    <Draggable
                                        key={`${item.key}`}
                                        draggableId={`${item.key}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className="draggable-channel"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Channel
                                                    activeSegment={activeSegment}
                                                    setNewSegment={setNewSegment}
                                                    editChannelName={editChannelName}
                                                    appendChannelToFixture={appendChannelToFixture}
                                                    removeChannelFromFixture={removeChannelFromFixture}
                                                    name={item.name}
                                                    id={item.key}
                                                    order={index}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}
