import React from "react";
import Loading from "components/utils/Loading";
import { CompanyInterface } from "interfaces/Company";
import { makeAxiosCall } from "utils";
import { CircularProgress, Grid, Paper, Snackbar, Switch, Typography } from "@material-ui/core";
import CompanyApprovalCard from "./CompanyApprovalCard";
import { Alert, Color } from "@material-ui/lab";
import { UserInterface } from "interfaces/User";
import { UserContext } from "context/User";
import ApprovalExpirationCard from "./ApprovalExpirationCard";

export const enum ApprovalCardTypes {
    application,
    infoForm,
    agreement,
    certificate
}

interface CompanyApprovalProps {
    company: CompanyInterface,
    refresh?: () => void,
    canEdit?: boolean,
    members?: UserInterface[],
}

const CompanyApproval = ({ company, refresh, canEdit = false, members }: CompanyApprovalProps) => {

    const initialSnackbarState: {
        isOpen: boolean,
        message: string,
        severity: Color,
    } = {
        isOpen: false,
        message: "",
        severity: "success",
    };

    const { accountLevels } = React.useContext(UserContext);
    const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
    const [approving, setApproving] = React.useState(false);
    const [companyPromoted, setCompanyPromoted] = React.useState(false);


    React.useEffect(() => {
        if (company && canEdit) {
            makeAxiosCall(
                "get",
                `reseller-promotion-status/${company.id}`,
                { headers: { "Content-Type": "application/json", } },
            ).then(response => {
                if (response && response.data)
                    setCompanyPromoted(response.data.promoted);
            });
        }
    }, [company]);


    const togglePromoteReseller = () => {
        makeAxiosCall(
            "post",
            `reseller-promotion-status/${company.id}`,
            {
                promote: !companyPromoted,
            }
        );
        setCompanyPromoted(!companyPromoted);
    };

    const updateCompanyApproval = async (updateData) => {
        if (updateData.companyApproved != undefined) {
            setApproving(true);
        }

        const response = await makeAxiosCall(
            "post",
            `reseller-approval-status/${company.id}`,
            {
                approvalId: company.approvalStatus.id,
                ...updateData
            },
            { headers: { "Content-Type": "application/json", } },
        ).catch(error => {
            setSnackbar({
                isOpen: true,
                message: "Failed to update company approval.",
                severity: "error",
            });
            console.log(error);
            setApproving(false);
            return false;
        });


        if (response) {
            if (response.data.message) {
                refresh();
                setApproving(false);

                if (updateData.companyApproved) {
                    const adminLevelId = accountLevels.find((level) => level.name == "Admin").id;
                    const admins = members.filter((member) => member.accountLevel.id == adminLevelId);

                    if (admins.length > 0) {
                        admins.forEach((admin) => {
                            makeAxiosCall(
                                "post",
                                "email/welcome",
                                {
                                    to: {
                                        id: admin.uid,
                                        email: admin.email,
                                        companyId: company.id,
                                    }
                                }
                            );
                        });
                    } else {
                        console.log("Error: no admins in company");
                    }
                }
                return true;
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Failed to update company approval.",
                    severity: "error",
                });
                console.error(response.data);
                setApproving(false);
                return false;
            }
        }
    };

    const sendDenyEmail = (denialReason: string) => {
        console.log("In sendDenyEmail");
        const adminLevelId = accountLevels.find((level) => level.name == "Admin").id;
        const admins = members.filter((member) => member.accountLevel.id == adminLevelId);

        if (admins.length > 0) {
            admins.forEach((admin) => {
                makeAxiosCall(
                    "post",
                    "email/wizard-denied",
                    {
                        to: {
                            id: admin.uid,
                            email: admin.email,
                            companyId: company.id,
                        },
                        denialReason: denialReason,
                    }
                );
            });
        } else {
            console.log("Error: no admins in company");
        }
    };

    const renderBody = () => {
        if (company && company.approvalStatus) {
            return (
                <>
                    {/* 
                        <CompanyApprovalCard 
                            title="Application" 
                            complete={company.approvalStatus.applicationComplete}
                            approved={company.approvalStatus.applicationApproved}
                            denialReason={company.approvalStatus.applicationDenialReason}
                            lastUpdated={company.approvalStatus.lastUpdated}
                            update={updateCompanyApproval}
                            cardType={ApprovalCardTypes.application}
                            canEdit={canEdit}
                        /> 
                    */}
                    <CompanyApprovalCard
                        title="Info Form"
                        complete={company.approvalStatus.infoFormComplete}
                        approved={company.approvalStatus.infoFormApproved}
                        denialReason={company.approvalStatus.infoFormDenialReason}
                        lastUpdated={company.approvalStatus.lastUpdated}
                        update={updateCompanyApproval}
                        cardType={ApprovalCardTypes.infoForm}
                        infoFormId={company.resellerInfo ? company.resellerInfo.id : null}
                        canEdit={canEdit}
                        sendDenyEmail={sendDenyEmail}
                        refresh={refresh}
                    />
                    <CompanyApprovalCard
                        title="Reseller Agreement"
                        complete={company.approvalStatus.agreementComplete}
                        approved={company.approvalStatus.agreementApproved}
                        denialReason={company.approvalStatus.agreementDenialReason}
                        lastUpdated={company.approvalStatus.lastUpdated}
                        update={updateCompanyApproval}
                        cardType={ApprovalCardTypes.agreement}
                        companyId={company.id}
                        approvalId={company.approvalStatus.id}
                        canEdit={canEdit}
                        sendDenyEmail={sendDenyEmail}
                        refresh={refresh}
                    />
                    <CompanyApprovalCard
                        title="Certificate"
                        complete={company.approvalStatus.certificateUploaded}
                        approved={company.approvalStatus.certificateApproved}
                        denialReason={company.approvalStatus.certificateDenialReason}
                        lastUpdated={company.approvalStatus.lastUpdated}
                        update={updateCompanyApproval}
                        cardType={ApprovalCardTypes.certificate}
                        companyId={company.id}
                        approvalId={company.approvalStatus.id}
                        canEdit={canEdit}
                        sendDenyEmail={sendDenyEmail}
                        certExpiration={company.approvalStatus.certExpirationDate}
                        certExpires={company.approvalStatus.certNeverExpires}
                        refresh={refresh}
                    />

                    {/* Final Approval */}
                    {canEdit && (
                        <Paper elevation={2} className="contact-card">
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h5">Final Company Approval</Typography>
                                    <p>Only check toggle this switch after they have completed all necessary steps first.</p>
                                    {company.approved ?
                                        <p>Currently the company is approved, and is able to access the portal normally.</p>
                                        : <p>Currently the company is NOT approved and will be redirected to the onboarding wizard.</p>}

                                </Grid>
                                <Grid item container xs={4} justifyContent="center" alignItems="center">
                                    <Grid item xs={6} style={{ textAlign: "right" }}>
                                        <Switch color="primary" checked={company.approved} onChange={() => {
                                            updateCompanyApproval({ companyApproved: !company.approved });
                                        }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {approving && (
                                            <CircularProgress
                                                color="inherit"
                                                size={24}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    {/* Approval Expiration */}
                    {canEdit && (
                        <ApprovalExpirationCard
                            company={company}
                            update={updateCompanyApproval}
                            refresh={refresh}
                        />
                    )}

                    {/* Reseller Promotion Zone */}
                    {canEdit && (
                        <Paper elevation={2} className="contact-card">
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h5">Promote Reseller</Typography>
                                    <p>Promoted resellers are displayed differently on the public site&apos;s map.</p>
                                </Grid>
                                <Grid item container xs={4} justifyContent="center" alignItems="center">
                                    <Grid item xs={6} style={{ textAlign: "right" }}>
                                        <Switch color="primary" checked={companyPromoted} onChange={() => {
                                            togglePromoteReseller();
                                        }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {approving && (
                                            <CircularProgress
                                                color="inherit"
                                                size={24}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                </>
            );
        } else {
            return <p className="body-message">No Approval Data</p>;
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbar({ ...snackbar, isOpen: false });
    };

    return company ? (
        <>
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={9}>
                    <h2>Approval</h2>
                </Grid>
            </Grid>
            {renderBody()}
        </>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyApproval;