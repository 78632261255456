import { Grid } from "@material-ui/core";
import React, { Component } from "react";

export default class QuoteRow extends Component {
  render() {
    const { qty, title, pricePer, total, note } = this.props;
    return (
      <Grid container>
        <Grid item sm={3}>
          {qty}
        </Grid>
        <Grid item sm={3}>
          {title}
          {note && <h4 style={{ marginTop: 0, fontSize: ".75em" }}>{note}</h4>}
        </Grid>
        <Grid item sm={3}>
          {pricePer}
        </Grid>
        <Grid item sm={3}>
          {total}
        </Grid>
      </Grid>
    );
  }
}
