import React from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { UserContext } from "context/User";
import SuccessAni from "components/utils/SuccessAni";
import { CSSTransition } from "react-transition-group";
import { makeAxiosCall } from "utils/";
import { buildCompanyInfo, CompanyInterface } from "interfaces/Company";
import { LocationInterface } from "interfaces/Location";
import { AddressInterface } from "interfaces/Address";
import AddressBook from "components/Account/Address/AddressBook";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDbTZomAlwZV_uPONr4nlIu8gApcll5eGA");


interface LocationFormValidation {
    address: string,
    phoneNumber: string,
    lat: string,
    lng: string,
    website: string,
    name: string,
}

interface LocationFormProps {
    companyId: number,
    location?: LocationInterface,
    addCallback?: (newLocation: LocationInterface) => void,
    updateCallback?: (navigation: LocationInterface, id: number) => void,
    deleteCallback?: (locationId: number) => void
}



const LocationForm = ({ companyId, location, addCallback, updateCallback, deleteCallback }: LocationFormProps) => {
    const { currentUser } = React.useContext(UserContext);

    const startingValidation: LocationFormValidation = {
        address: "",
        phoneNumber: "",
        lat: "",
        lng: "",
        website: "",
        name: "",
    };

    const INITIAL_NEW_LOCATION: LocationInterface = {
        address: currentUser.company.defaultBillingAddress,
        name: currentUser.company.name,
        website: currentUser.company.resellerInfo ? currentUser.company.resellerInfo.website : "",
        phoneNumber: currentUser.company.defaultBillingContact ? currentUser.company.defaultBillingContact.phoneNumber : "",
        lat: 0,
        lng: 0
    };

    const [form, setForm] = React.useState<LocationInterface>(INITIAL_NEW_LOCATION);
    const [company, setCompany] = React.useState<CompanyInterface>();
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [failureAlert, setFailureAlert] = React.useState(false);
    const [currentProcess, setCurrentProcess] = React.useState<string>("");
    const [validation, setValidation] = React.useState(startingValidation);

    React.useEffect(() => {
        if (!companyId || companyId == currentUser.company.id) {
            setCompany(currentUser.company);
        } else {
            fetchCompany(companyId);
        }
    }, []);

    React.useEffect(() => {
        if (location) {
            setForm(location);
        } else {
            setForm(INITIAL_NEW_LOCATION);
        }
    }, [location]);


    const fetchCompany = async (companyId: number) => {
        const response = await makeAxiosCall(
            "get",
            `company/${companyId}`
        ).catch(error => {
            console.error("Caught error getting company info:");
            console.error(error);
        });

        if (response.data) {
            const companyData: CompanyInterface = buildCompanyInfo(response.data);
            setCompany(companyData);
            if(!location) {
                setForm({...form, address: companyData.defaultBillingAddress ?? companyData.addresses[0]})
            }
        }
    };


    const getGeolocation = async (address: AddressInterface) => {
        if (!address) return false;

        return await Geocode.fromAddress(
            address.streetAddress + " " +
            address.streetAddressTwo + " " +
            address.city + " " +
            address.state + " " +
            address.country
        ).then(
            (response) => {
                if (!response.results || response.results.length < 1) {
                    return false;
                }

                const { lat, lng } = response.results[0].geometry.location;
                form.lat = lat;
                form.lng = lng;
                setForm({ ...form, lat: lat, lng: lng });
                return true;
            },
            (error) => {
                console.error(error);
                return false;
            }
        );
    };


    const handleSave = async () => {

        // clean up the phone number
        let phone = form.phoneNumber;

        const cleaned = ("" + form.phoneNumber).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            phone = "(" + match[1] + ") " + match[2] + "-" + match[3];
        }

        const response = await makeAxiosCall(
            "post",
            "reseller-location/" + (location ? location.id : ""),
            {
                companyId: company.id,
                addressId: form.address.id,
                name: form.name,
                website: form.website,
                phoneNumber: phone,
                lat: form.lat,
                lng: form.lng
            }
        ).catch((error) => {
            console.error(error);
        });

        // const response = location ? await updateLocation(form, company.id) : await addNewLocation(form, company.id, locationType);

        setCurrentProcess("saving");
        if (response && response.data.id) {
            const newLocationObject: LocationInterface = {
                id: response.data.id,
                address: form.address,
                name: form.name,
                website: form.website,
                phoneNumber: phone,
                lat: form.lat,
                lng: form.lng
            };

            if (!location) {
                if (addCallback) {
                    addCallback(newLocationObject);
                } else {
                    setCurrentProcess("");
                }
            } else {
                if (updateCallback) {
                    updateCallback(newLocationObject, response.data.id);
                } else {
                    setCurrentProcess("");
                }
            }
        } else {
            console.log("failure?");

            setFailureAlert(true);
            setCurrentProcess("");
        }
    };

    const handleDelete = async () => {
        setCurrentProcess("delete");

        const response = await makeAxiosCall(
            "delete",
            "reseller-location/" + location.id
        ).catch((error) => {
            console.error(error);
        });

        if (response && response.data.id) {
            if (deleteCallback) deleteCallback(response.data.id);
        } else {
            setFailureAlert(true);
            setCurrentProcess("");
        }
    };

    const handleLocationChange = (name: string) => (e) => {
        setForm({
            ...form,
            [name]: e.target.value,
        });

        setValidation({ ...validation, [name]: "" });
    };


    const validate = async () => {
        const errors: LocationFormValidation = {
            address: "",
            phoneNumber: "",
            lat: "",
            lng: "",
            website: "",
            name: "",
        };

        const finalForm = await getGeolocation(form.address);

        errors.address = !form.address ? "Required" : "";
        errors.phoneNumber = form.phoneNumber.length === 0 ? "Required" : "";
        errors.lat = !finalForm ? "Required" : "";
        errors.lng = !finalForm ? "Required" : "";
        errors.website = form.website.length === 0 ? "Required" : "";
        errors.name = form.name.length === 0 ? "Required" : "";


        setValidation({ ...errors });

        return Object.values(errors).every(x => x === "");
    };

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label="Name"
                    margin="normal"
                    value={form.name ?? ""}
                    variant="outlined"
                    onChange={handleLocationChange("name")}
                    placeholder={currentUser.company.name}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label="Phone Number"
                    margin="normal"
                    className="phone"
                    variant="outlined"
                    onChange={handleLocationChange("phoneNumber")}
                    value={form.phoneNumber ?? ""}
                    error={validation.phoneNumber.length !== 0}
                    helperText={validation.phoneNumber}
                    autoComplete="phone"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label="Website"
                    margin="normal"
                    className="website"
                    variant="outlined"
                    onChange={handleLocationChange("website")}
                    value={form.website ?? ""}
                    inputProps={{ maxLength: 90 }}
                    autoComplete="website"
                />
            </Grid>
            <Grid item xs={12}>
                <AddressBook
                    title="Address"
                    currentReseller={company}
                    onSelect={(address: AddressInterface) => {
                        setForm({
                            ...form,
                            address: address
                        });
                    }}
                    currentSelection={form.address ?? company.defaultBillingAddress}
                />

                {validation.address.length > 0 && (
                    <p style={{ color: "red" }}>
                        Required
                    </p>
                )}
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" spacing={2} xs={12}>
                {location && location.id && (
                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={handleDelete}
                            className="btn red"
                        >
                            {currentProcess === "delete" ?
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                /> :
                                "Delete"
                            }
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            setSuccessAlert(false);
                            setFailureAlert(false);
                            if (await validate()) {
                                setCurrentProcess("saving");
                                handleSave();
                            }
                        }}
                        className="btn"
                    >
                        {currentProcess === "saving" ?
                            <CircularProgress
                                color="inherit"
                                size={24}
                            /> :
                            "Save Location"
                        }
                    </Button>
                </Grid>
            </Grid>
            <CSSTransition
                in={successAlert}
                unmountOnExit
                timeout={{ appear: 200, enter: 0, exit: 0 }}
                classNames="dialog"
            >
                <Grid container justifyContent="center">
                    <SuccessAni marginTop={20} title="Saved Location"></SuccessAni>
                </Grid>
            </CSSTransition>
            <CSSTransition
                in={failureAlert}
                unmountOnExit
                timeout={{ appear: 200, enter: 0, exit: 0 }}
                classNames="dialog"
            >
                <Grid container justifyContent="center" direction="column" style={{ color: "#d06079", textAlign: "center", marginTop: 20 }}>
                    <i className="fa-light fa-triangle-exclamation" style={{ fontSize: "3em" }}></i>
                    <p style={{ fontSize: "1.25em" }}>Failed to Save Location</p>
                    <p>Please try again. If the issue persists, contact our support.</p>
                </Grid>
            </CSSTransition>
        </>
    );
};

export default LocationForm;
