import React, { useContext } from "react";
import { Grid, Paper, Snackbar, Typography} from "@material-ui/core";
import { Link } from "react-router-dom";
import _ from "lodash";
import { makeAxiosCall, stringToMoney } from "utils";
import Loading from "components/utils/Loading";
import { Alert, Color } from "@material-ui/lab";
import 'scss/components/Orders/PendingOrderSummary.scss';
import { useNavigate } from "react-router-dom";
import { promotionFromHasuraObject, PromotionInterface } from "interfaces/Promotion";
import { ProductContext } from "context/Product";
import PromotionForm from "./PromotionForm";
import { List, WindowScroller } from "react-virtualized";
import { useWindowWidth } from "hooks/useWindowSize";

interface PromotionDetailsProps {
  promotionBasic?: PromotionInterface
}

const PromotionDetails = ({ promotionBasic }: PromotionDetailsProps) => {
  const promotionId = window.location.pathname.split("/promotion/")[1];
  const [promotion, setPromotion] = React.useState<PromotionInterface>(promotionBasic);
  const windowWidth = useWindowWidth();
  const { products } = useContext(ProductContext);
  const windowScrollerRef = React.useRef<WindowScroller>();

  const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };
  
  const [snackbar, setSnackbar] = React.useState(initialSnackbarState);

  let isMounted = true;

  let navigate = useNavigate();

  React.useEffect(() => {
    if(!promotion || promotion.uses === null) {
        fetchPromotionDetails();
    }
  }, []);

  React.useEffect(() => {
    if(!promotion || promotion.uses === null) {
        fetchPromotionDetails();
    }
  }, [products]);


  const fetchPromotionDetails = async () => {

    let response = await makeAxiosCall(
      "get",
      `promotions/${promotionId}`, // Get web orders from fishbowl
    ).catch((error) => {
      console.error(error);
    });

    if (isMounted) {
      if (response && response.data) {
        setPromotion(promotionFromHasuraObject(response.data, products));
      } else {
        setSnackbar({
          isOpen: true,
          message: "Failed to fetch promotion.",
          severity: "error",
        });
      }
    }
  }


    const renderRow = ({ index, key, style }) => {
        const usage = promotion.uses[index];

        return (
            <div style={style} key={key} >
                <Paper key={usage.id} elevation={2} className="grid-search-result">
                    <Grid container item xs={12} className={"center-text"}>
                        <Grid item xs={5} className="result-name">
                            { usage.customerId ? <Link to={"/web-customer/" + usage.customerId}>
                                {usage.customerEmail}
                            </Link> : usage.customerEmail }
                        </Grid>
                        <Grid item xs={2}>
                            {stringToMoney(usage.amount)}
                        </Grid>
                        <Grid item xs={4}>
                            {usage.createdAt.toLocaleString("en-US", {dateStyle: "short"})}
                        </Grid>
                        <Grid item xs={1}>
                            <Link to={"/web-orders/" + usage.orderId} className="no-underline">
                                <i className="fas fa-eye blue"></i>
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return <p className="body-message center-text">Promotion has not been used yet</p>;
    };



    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, isOpen: false });
    };


  if (promotion) {
    return (
    <Grid container className="admin view" style={{paddingLeft: 60, paddingRight: 60}}>
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <PromotionForm existingPromotion={promotion} />


        <div className="grid-search" style={{display: "contents"}}>
            <h3>Usages</h3>
            <Grid container className="results">
                {windowWidth > 700 && <Grid container item xs={12} className="center-text grid-search-headers">
                    <Grid xs={5} item className="result-name">
                        Used By
                    </Grid>
                    <Grid item xs={2}>
                        Amount Discounted
                    </Grid>
                    <Grid item xs={4}>
                        Used On
                    </Grid>
                    <Grid item xs={1}>
                        Order
                    </Grid>
                </Grid>}
                
                <WindowScroller ref={windowScrollerRef}>
                    {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                        return <List
                            autoHeight
                            width={width}
                            height={height}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            scrollTop={scrollTop}
                            rowHeight={60}
                            rowRenderer={renderRow}
                            noRowsRenderer={renderPlaceholder}
                            rowCount={promotion.uses ? promotion.uses.length : 0}
                            overscanRowCount={3}
                        />;
                    }}
                </WindowScroller>

            </Grid>
        </div>
    </Grid>
    );
  } else {
    return (
      <Loading position={"relative"} title={""} height={"40vh"} />
    );
  }
};

export default PromotionDetails;
