import { AddressInterface, buildAddress } from "./Address";
import { BuildOrderObject, OrderInterface } from "./Order";
import { BuildWebOrderObject, WebOrderInterface } from "./WebOrder";

export interface WebCustomerInterface {
    id: string;
    firstName: string,
    lastName: string,
    email: string,
    addresses: Array<AddressInterface>,
    orders: Array<WebOrderInterface>,
    phone?: string,
    createdAt: Date,
    rewardsBalance: number,
    taxExempt: boolean
}

export interface SimpleWebCustomerInterface {
    id: string;
    firstName: string,
    lastName: string,
    email: string,
    createdAt: Date,
    rewardsBalance: number,
    taxExempt: boolean
}

export const BuildSimpleWebCustomerObject = (rawCustomerData: any): SimpleWebCustomerInterface => {
    return {
        id: rawCustomerData.id,
        firstName: rawCustomerData.first_name ?? "",
        lastName: rawCustomerData.last_name ?? "",
        email: rawCustomerData.email,
        createdAt: new Date(rawCustomerData.created_at),
        rewardsBalance: rawCustomerData.blizzard_rewards_balance,
        taxExempt: rawCustomerData.tax_exempt
    }
}

export const BuildWebCustomerObject = (rawCustomerData: any): WebCustomerInterface => {
    if(!rawCustomerData) return null;
    let addresses = [];
    if(rawCustomerData.addresses) {
        for (let cai = 0; cai < rawCustomerData.addresses.length; cai++) {
            const addressData = rawCustomerData.addresses[cai];
            addresses.push(buildAddress(addressData));
        }
    }

    let customer = {
        id: rawCustomerData.id,
        firstName: rawCustomerData.first_name ?? "",
        lastName: rawCustomerData.last_name ?? "",
        email: rawCustomerData.email,
        addresses: addresses,
        orders: [],
        phone: rawCustomerData.phone,
        createdAt: new Date(rawCustomerData.created_at),
        rewardsBalance: rawCustomerData.blizzard_rewards_balance,
        taxExempt: rawCustomerData.tax_exempt
    }

    // adding orders after to reference self
    let orders = [];
    if(rawCustomerData.orders) {
        for (let coi = 0; coi < rawCustomerData.orders.length; coi++) {
            const orderData = rawCustomerData.orders[coi];
            orders.push(BuildWebOrderObject(orderData, customer));
        }

        orders.sort((a: OrderInterface,b: OrderInterface) => b.createdAt.getTime() - a.createdAt.getTime())
    }
    

    customer.orders = orders;

    return customer;
}

export const BuildWebCustomerList = (rawCustomerData: any): SimpleWebCustomerInterface[] => {
    let customers: SimpleWebCustomerInterface[] = [];

    if(rawCustomerData) {
        for (let wcrdi = 0; wcrdi < rawCustomerData.length; wcrdi++) {
            customers.push(BuildSimpleWebCustomerObject(rawCustomerData[wcrdi]));
        }
    }

    return customers;
}