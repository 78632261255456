import React from "react";
import { Link } from "react-router-dom";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { updateHubspotContact } from "utils";

const Infobox = ({ title, paragraph, buttonTitle }) => {
  const [email, setEmail] = React.useState();
  const [isWritingToDb, setWritingToDb] = React.useState(false);
  const [isSent, setSent] = React.useState(false);
  const buttonSubmit = () => {
    setWritingToDb(true);
    const obj = {
      email: email,
      invite_code: "requested",
    };
    updateHubspotContact(obj);
    setTimeout(() => {
      setWritingToDb(false);
      setSent(true);
    }, 500);
  };
  return (
    <div className="infobox">
      <h3>{title}</h3>
      <p>{paragraph}</p>
      <TextField
        style={{ width: "100%", background: "white" }}
        id="outlined-basic"
        label="Business Email"
        margin="normal"
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="btn"
        style={{ background: isSent ? "green" : "" }}
        onClick={() => buttonSubmit()}
      >
        {isSent ? "Request Sent" : buttonTitle}
        {isWritingToDb ? (
          <CircularProgress
            style={{ marginLeft: 10 }}
            color="inherit"
            size={20}
          />
        ) : null}
      </Button>
    </div>
  );
};

export default Infobox;
