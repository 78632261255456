import React, { useContext } from "react";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";
import { UserContext } from "context/User";
import NewsfeedPostForm from "./NewsfeedPostForm";
import Post from "./Post";
import { makeAxiosCall } from "utils";
import { buildPostList, NewsfeedPostInterface } from "interfaces/Newsfeed";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/utils/Loading";

const Newsfeed = () => {
  const { currentUser, internalUser } = useContext(UserContext);
  const [posts, setPosts] = React.useState<NewsfeedPostInterface[]>([]);
  const [newPostActive, setNewPostActive] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);

  let isMounted = true;

  React.useEffect(() => {
    fetchPosts();
    return () => {
      isMounted = false;
    }
  }, []);

  const tagOptions = [
    "Video",
    "News",
    "Product",
    "Important",
    "Press",
  ];

  if (internalUser) tagOptions.push("Internal");
  
  const addPost = async (newPost: NewsfeedPostInterface) => {
    let response = await makeAxiosCall(
      "post",
      `newsfeed-create`,
      newPost,
    ).catch(error => {
      console.error(error);
    });

    if (isMounted && response && response.data.id) {
      fetchPosts();
      setNewPostActive(false);
    }
  };

  const fetchPosts = async () => {
    let response = await makeAxiosCall(
      "get",
      `newsfeed-posts`,
    ).catch(error => {
      console.error(error);
    });

    if (isMounted) {
      if (response && response.data) {
        setPosts(buildPostList(response.data));
        setLoading(false);
      }
    }
  };

  const deletePost = async (id) => {
    let response = await makeAxiosCall(
      "delete",
      `newsfeed-delete/${id}`
    ).catch(error => {
      console.error(error);
    });

    if (isMounted && response && response.data.id) {
      fetchPosts();
    }
  }

  const filteredPosts = React.useMemo(() => {
    const applyFilters = selectedTags.length > 0;

    if (applyFilters) {
      return posts.filter((post) => post.tags.some((tag) => selectedTags.includes(tag)));
    } else {
      return posts;
    }
  }, [posts, selectedTags]);

  const renderTagFilters = () => {
    return (
      <Autocomplete 
        multiple
        options={tagOptions}
        onChange={(e, v) => {
          setSelectedTags(v);
        }}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Tags"
            variant="outlined"
            fullWidth
          />
        )}
      />
    );
  }

  const renderPosts = () => {
    if (loading) {
      return (<Loading height="40vh" title="" position="relative" />);
    } else {
      if (filteredPosts.length == 0) {
        return <p className="body-message center-text">No Posts Found</p>;
      } else {
        return _.map(_.orderBy(filteredPosts, "posted", "desc"), (post, i) => {
          return (
            <Post post={post} canDelete={currentUser && internalUser} removePost={deletePost} refresh={fetchPosts} key={'post-item-' + i} />
          );
        });
      }
    }
  }

  return currentUser ? (
    <Grid container className="flex-content view">
      <Grid item container xs={12} alignItems="center">
        <Grid item md={2} xs={12}><h1>News Feed</h1></Grid>
        <Grid item md={3} xs={12}>
          {internalUser && (
            <CSSTransition
              in={!newPostActive}
              unmountOnExit
              timeout={200}
              classNames="dialog"
            >
              <Button
                type="submit"
                style={{ width: "100%" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setNewPostActive(true);
                }}
              >
                New Post
              </Button>
            </CSSTransition>
          )}
        </Grid>
        <Grid item md={3} xs={4} style={{ textAlign: "right", padding: 5 }}><h3>Filter Tags:</h3></Grid>
        <Grid item md={4} xs={8}>{renderTagFilters()}</Grid>
      </Grid>
      <Grid item xs={12}>
        {internalUser && (
          <CSSTransition
            in={newPostActive}
            unmountOnExit
            timeout={200}
            classNames="dialog"
          >
            <div style={{width: "100%"}}>
              <NewsfeedPostForm
                savePost={addPost}
                setFormActive={setNewPostActive}
              />
            </div>
          </CSSTransition>
        )}
      </Grid>

      {renderPosts()}
    </Grid>
  ) : false;
}

export default Newsfeed;