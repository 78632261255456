import React from "react";
import _ from "lodash";
import { Grid, List } from "@material-ui/core";
import { PDGenericTaskList, PDProjectChecklistInterface, PDProjectInterface, PDProjectStatusInterface, PDProjectTaskInterface } from "interfaces/ProductDevelopment";
import { makeAxiosCall } from "utils";
import ProjectProgressTask from "./ProjectProgressTask";

interface PDProjectProgressProps {
    checklist: PDProjectChecklistInterface,
    project: PDProjectInterface,
    setProject: (project: PDProjectInterface) => void,
    refreshData: () => void,
    defaultMediaCategory?: string
}

const PDProjectChecklist = ({ checklist, project, setProject, refreshData, defaultMediaCategory }: PDProjectProgressProps) => {
    const [tempProject, setTempProject] = React.useState<PDProjectInterface>(project);

    const projectStatuses = React.useMemo(() => {
        return [...new Set(checklist.tasks.map((task) => task.projectStatus))].sort((a, b) => a.id - b.id);
    }, [project]);

    React.useEffect(() => {
      setTempProject(project);
      
    }, [project]);

    // React.useEffect(() => {

    //   _checkForNewTasks();
      
    // }, []);

    const _checkForNewTasks = async () => {
        if(project && checklist && checklist.tasks && checklist.tasks.length) {
            for (let gti = 0; gti < PDGenericTaskList.length; gti++) {
                const genericTask = {...PDGenericTaskList[gti]};
                
                let matchingTask = checklist.tasks.find((rt) => {
                    return rt.name == genericTask.name;
                });

                if(!matchingTask) {
                    await saveTask(genericTask);
                    console.log('added task ' + genericTask.name);
                }
                
            }
        }
    }

    const saveTask = async (task: PDProjectTaskInterface, refresh: boolean = false) => {
        console.log("hello from saveTask");
        console.log(task);
        console.log(refresh);
        
        if(task.id) {
            makeAxiosCall(
                "post",
                "pd/task/" + task.id,
                task
            ).then((result) => {
                console.log(result);
                if (refresh) {
                  refreshData();
                }
            });
        } else {
            await makeAxiosCall(
                "post",
                "pd/task",
                {
                    name: task.name,
                    projectId: project.id,
                    projectStatusId: task.projectStatus.id,
                    type: task.type,
                    taskStatusId: task.taskStatus.id,
                    checklistId: checklist.id
                }
            ).then((result) => {
                if(result && result.data && result.data.id) {
                    task.id = result.data.id;
                }
                // console.log("created new task");
            });
        }

        let updatedProject = {...tempProject};
        let newTaskList = [];
        for (let ti = 0; ti < checklist.tasks.length; ti++) {
            const existingTaskData = checklist.tasks[ti];
            if(existingTaskData.id == task.id || existingTaskData.name == task.name) {
                newTaskList.push(task);
            } else {
                newTaskList.push(existingTaskData);
            }
        }

        updatedProject.checklists.find((list) => list.id == checklist.id).tasks = newTaskList;

        setProject(updatedProject);
        setTempProject(updatedProject);
    }

    const buildList = (status: PDProjectStatusInterface) => {
        let listItems = [];

        for (let lti = 0; lti < checklist.tasks.length; lti++) {
            const task = checklist.tasks[lti];

            if(task.projectStatus.id == status.id) {
                listItems.push(<ProjectProgressTask 
                  key={task.id + "-task-item"} 
                  task={task} 
                  saveTask={saveTask} 
                  project={tempProject}
                  refreshData={refreshData}
                  defaultMediaCategory={defaultMediaCategory}
                />);
            }
        }

        if(!listItems.length) return;

        return (

            <List key={status.id + "-list"} style={{width: "100%"}}>
                <h3>{status.name}</h3>
                {
                    listItems
                }
            </List>
            
        )
    };

    const buildLists = () => {
        let lists = [];

        for (let lti = 0; lti < projectStatuses.length; lti++) {
            const status = projectStatuses[lti];
            
            lists.push(buildList(status));
        }

        return (
            <Grid item xs={12} container>
                {lists}    
            </Grid>
        )
    };

    return buildLists();
};

export default PDProjectChecklist;
