import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeAxiosCall } from "utils";
import { FishbowlSalesOrderItemInterface } from "interfaces/FishbowlSalesOrder";
import OrderSummary from "./OrderSummary";
import OrderInvoices from "./OrderInvoices";
import { BuildOrderObject, OrderInterface } from "interfaces/Order";
import PendingOrderSummary from "./PendingOrderSummary";

const SO = () => {
  const [so, setSo] = useState<Array<FishbowlSalesOrderItemInterface>>();
  const [portalOrder, setPortalOrder] = useState<OrderInterface>();
  const [activeMenu, setActiveMenu] = useState("Summary");

  let isMounted = true;
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchSO();
  }, []);

  const fetchSO = () => {
    const soId = window.location.pathname.split("/order-history/")[1];
    if (soId) {
      makeAxiosCall(
        "get",
        `fishbowl-order/${soId}`,
        null,
        {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        if (res.data.length > 0 && isMounted) {
          let returnedSO = res.data;
          if(returnedSO[0].portalOrderData) {
            let portalOrder = await BuildOrderObject(returnedSO[0].portalOrderData);
            setPortalOrder(portalOrder);
          } 

          setSo(returnedSO);
          // checkPortalOrders(res.data[0].num);
        }
      })
      .catch((err) => console.log(err));
    }
  };

  const _goBack = () => {
    navigate(-1);
  };

  // const checkPortalOrders = async (soNum: string) => {
  //   const result = await makeAxiosCall(
  //     "get",
  //     `orders`,
  //   ).catch(error => {
  //     console.error("Caught error getting pending orders:");
  //     console.error(error);
  //   });

  //   if (result.data) {
  //     const orderData = result.data.find((order) => order.fishbowl_so_num == soNum);
  //     if (orderData && isMounted) {
  //       const response = await makeAxiosCall(
  //         "get",
  //         `order/${orderData.company_id}/${orderData.id}`
  //       ).catch(err => {
  //         console.error(err);
  //       });
  
  //       if (response.data && isMounted) {
  //         let order = await BuildOrderObject(response.data);
  //         setPortalOrder(order);
  //       }
  //     }
  //   }
  // }

  const renderView = () => {
    if (activeMenu === "Invoices") {
      return <OrderInvoices so={so} />
    } else if (activeMenu === "PO") {
      return <PendingOrderSummary existingOrder={portalOrder} />
    } else {
      return <OrderSummary sois={so} />
    }
  };

  return (
    <div className="admin view">
      <div>
        <Button
          type="submit"
          style={{ marginBottom: 20 }}
          variant="contained"
          color="primary"
          className="btn"
          onClick={_goBack}
        >
          ‹ Back
        </Button>
      </div>
      <h1 className="reveal-text">
        Order {window.location.pathname.split("/order-history/")[1]}
      </h1>
      <div className="settings-bar">
        <div
          onClick={() => setActiveMenu("Summary")}
          className={activeMenu === "Summary" ? "item active" : "item"}
        >
          Summary
        </div>
        <div
          onClick={() => setActiveMenu("Invoices")}
          className={activeMenu === "Invoices" ? "item active" : "item"}
        >
          Invoices
        </div>
        {portalOrder && (
          <div
            onClick={() => setActiveMenu("PO")}
            className={activeMenu === "PO" ? "item active" : "item"}
          >
            PO
          </div>
        )}
      </div>
      {renderView()}
    </div>
  );
};

export default SO;
