import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { OrderInterface } from "interfaces/Order";
import { Link } from "react-router-dom";
import { WebOrderInterface } from "interfaces/WebOrder";
import { stringToMoney } from "utils";
import { SimpleWebCustomerInterface } from "interfaces/WebCustomer";
import { CheckCircle } from "@material-ui/icons";

interface WebCustomerSearchResultProps {
    customer: SimpleWebCustomerInterface,
    mobileMode: boolean
}

const WebCustomerSearchResult = ({ customer, mobileMode }: WebCustomerSearchResultProps) => {
    return (
        <Paper key={customer.id} elevation={2} className="grid-search-result">
            <Link to={"/web-customer/" + customer.id} className="no-underline">
                <Grid container item xs={12} className="center-text" alignItems="center">
                    <Grid item md={3} xs={3} className="result-name">
                        <Typography noWrap variant="body2">
                            {customer.firstName + " " + customer.lastName}
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={3}>
                        {customer.email}
                    </Grid>
                    <Grid item md={2} xs={2}>
                        <Typography noWrap variant="body2">
                            {customer.rewardsBalance}
                        </Typography>
                    </Grid>
                    {!mobileMode && <Grid item md={2}>
                        {customer.taxExempt ? "Yes" : "No"}
                        
                    </Grid>}
                    {!mobileMode && <Grid item md={1}>
                        <i className="fas fa-eye blue"></i>
                    </Grid>}
                </Grid>
            </Link>
        </Paper>
    );
};

export default WebCustomerSearchResult;