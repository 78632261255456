import React, { Component } from "react";
import convert from "convert-units";
import { db } from "base.js";
import { connect } from "react-redux";
import { UserContext } from "context/User";
import {
  getIrisPanels,
  getIrisProcessors,
  getIrisAddons,
} from "actions/getProducts";

import Home from "components/Quote Builder/components/Home";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import _ from "lodash";
import { generateUID, makeAxiosCall } from "utils";
import { getHubspotProductId } from "components/Quote Builder/hubspot_product_ids";

class QuoteBuilder extends Component {
  static contextType = UserContext;

  constructor() {
    const uniqueId = +new Date();
    super();
    this.state = {
      helper: true,
      isWritingToDb: false,
      authUser: null,
      admin: false,
      allSaves: {},
      saves: [],
      videoEnabled: false,
      openedTab: "overviewTab",
      uid: "",
      item: [],
      snackbar: false,
      saveDialog: false,
      linkDialog: false,
      isSaveModalOpen: false,
      dimMode: true,

      config: {
        totalPrice: 0,
        projectTitle: `New Project`,
        addons: {
          0: {
            name: "None",
            sku: "None",
            value: "None",
            qty: 1,
            price: 0,
            dealerPrice: 0,
            id: uniqueId,
          },
        },
        processors: {
          0: {
            name: "None",
            sku: "None",
            price: 0,
            dealerPrice: 0,
            qty: 1,
            id: uniqueId + 1,
            value: 0,
          },
        },
        addonProd: [],
        completePanels: 0,
        caseNeeded: 0,
        pixelsPerPanel: 0,
        currentPrice: 0,
        novaStar: 0,
        recCards: 0,
        dualBumpers: 0,
        singleBumpers: 0,
        singleFly: 0,
        doubleFly: 0,
        ataSpec: 0,
        saveLoad: false,
        saveId: null,
        linkId: null,
        dimSelect: "Width & Height",
        saveName: null,
        productSelected: "Select a Product",
        currentProd: {
          productIcon: {
            url: "https://firebasestorage.googleapis.com/v0/b/blizz-quote.appspot.com/o/flamelink%2Fmedia%2F1541094451536_test_product2.svg?alt=media&token=e85db290-97d8-4b3d-956a-f1ecfc286b25",
          },
        },
        dimTotal: 0,
        heightChecked: false,
        widthChecked: false,
        diagChecked: false,
        pixelHeight: 0,
        pixelWidth: 0,
        powerConsumption: 0,
        natResWidth: 0,
        natResHeight: 0,
        hd: "",
        cirNeeded: 0,
        conNeeded: 0,
        ratioChecked: false,
        widthSelected: null,
        heightSelected: null,
        diagSelected: 0,
        ratio: 0,
        totalWeight: 0,

        custName: "",
        yourName: "",
        discount: 0,
        price: 0,
        panelWidthNeeded: 0,
        panelHeightNeeded: 0,
        totalPanelsNeeded: 0,
      },
    };
  }

  //Creates new select on click
  handleSelect = (name) => (e) => {
    const { products } = this.props;

    let panelData;
    let addons;
    let processors;
    let casePak = 0;
    let selectedProd = _.find(products, { num: e.target.value });

    const metafields = selectedProd.customFields;

    const findPanelData = _.find(metafields, (r) => {
      return r.name === "IRiS Panel Data" && r.value;
    });
    const findProcessors = _.find(metafields, (r) => {
      return r.name === "IRiS Processors" && r.value;
    });

    if (findPanelData) {
      const panelMetafields = JSON.parse(
        findPanelData.value.replace(/,/g, '","')
      );

      console.log(panelMetafields);

      if (panelMetafields.panelsPerCase) {
        casePak = parseFloat(panelMetafields.panelsPerCase);
      } else {
        if (e.target.value.includes("XL")) {
          casePak = 8;
        } else if (e.target.value.includes("ICON")) {
          casePak = 5;
        } else {
        }
      }

      const panelData = {
        ...panelMetafields,
        sku: selectedProd.sku,
        productName: selectedProd.num,
        price: selectedProd.price,
        casePak: casePak,
      };

      if (typeof panelData === "object") {
        this.setState(
          {
            ...this.state,
            dimMode: true,
            config: {
              ...this.state.config,
              format: "Ft.",
              currentProd: panelData,
              [name]: e.target.value,
              currentPrice: panelData.price,
            },
          },
          () => {
            this.calcTotals();
          }
        );
      }
    }
  };

  appendInput = (type, i) => {
    const newInput = {
        name: "None",
        value: "None",
        qty: 1,
        price: 0,
      },
      index = parseFloat(i, 10) + 1;

    this.setState((prevState) => ({
      ...this.state,
      config: {
        ...this.state.config,
        [type]: {
          [index]: newInput,
          ...this.state.config[type],
        },
      },
    }));
  };

  closeDialog = (name) => (event) => {
    this.setState({
      [name]: false,
    });
  };

  //Triggers when add-on select changes

  handleAddonSelect = (type, name, i) => (e) => {
    const { addons } = this.props;

    const addon = _.find(addons, {
      num: e.target.value,
    });

    if (type === "addons") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          [type]: {
            ...this.state.config[type],
            [i]: {
              ...this.state.config[type][i],
              [name]: e.target.value,
              sku: e.target.value,
              qty: 1,
              value: e.target.value,
              price: addon.price,
            },
          },
        },
      });
    } else if (type === "processors") {
      const processor = this.parseProcessorData(e.target.value);
      let price = parseFloat(processor.price);
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          [type]: {
            ...this.state.config[type],
            [i]: {
              ...this.state.config[type][i],
              [name]: e.target.value,
              sku: e.target.value,
              qty: 1,
              maxPixels: processor.maxPixels,
              price: price,
            },
          },
        },
      });
    }
  };

  parseProcessorData = (num) => {
    const { processors } = this.props;
    const processor = _.find(processors, {
      num: num,
    });
    const findProcessor = _.find(this.props.processors, {
      num: num,
    });
    const customFields = findProcessor.customFields;
    const processorData = _.find(customFields, {
      name: "IRiS Processor Data",
    });
    const maxPixels = JSON.parse(
      processorData.value.replace(/,/g, '","')
    ).maxPixels;

    return { ...processor, maxPixels: maxPixels };
  };

  //Remove add-on on click

  removeAddon = (type, index) => (e) => {
    const { config } = this.state,
      removed = _.filter(config[type], (x, i) => i !== index);

    this.setState({
      ...this.state,
      config: {
        ...this.state.config,
        [type]: {
          ...removed,
        },
      },
    });
  };

  //Handle format radios

  handleRadio = (name) => (e) => {
    const targetUnit = e.target.value,
      { config } = this.state,
      { heightSelected, widthSelected, format } = config;

    if (targetUnit === "In." && format === "Ft.") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("ft")
            .to("in")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("ft").to("in").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "Ft." && format === "In.") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("in")
            .to("ft")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("in").to("ft").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "cm" && format === "Ft.") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("ft")
            .to("cm")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("ft").to("cm").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "Ft." && format === "cm") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("cm")
            .to("ft")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("cm").to("ft").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "In." && format === "cm") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("cm")
            .to("in")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("cm").to("in").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "cm" && format === "In.") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("in")
            .to("cm")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("in").to("cm").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "cm" && format === "mm") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("mm")
            .to("cm")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("mm").to("cm").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "mm" && format === "cm") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("cm")
            .to("mm")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("cm").to("mm").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "Ft." && format === "mm") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("mm")
            .to("ft")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("mm").to("ft").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "mm" && format === "Ft.") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("ft")
            .to("mm")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("ft").to("mm").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "mm" && format === "In.") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("in")
            .to("mm")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("in").to("mm").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
    if (targetUnit === "In." && format === "mm") {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          heightSelected: convert(heightSelected)
            .from("mm")
            .to("in")
            .toFixed(2),
          widthSelected: convert(widthSelected).from("mm").to("in").toFixed(2),
          [name]: e.target.value,
        },
      });
    }
  };

  //Triggers panel background video on click

  enableVideo = () => (event) => {
    if (this.state.videoEnabled) {
      this.setState({
        videoEnabled: false,
      });
    } else {
      this.setState({
        videoEnabled: true,
      });
    }
  };

  //Trigger snackbar callback w/ delay

  delayClose = () => {
    setTimeout(
      function () {
        this.setState({ snackbar: false });
      }.bind(this),
      3000
    );
  };

  //Reset form

  clearState = (name) => (event) => {
    this.setState({
      ...this.state,
      dimMode: false,
      config: {
        ...this.state.config,
        [name]: "",
      },
    });
  };

  //Generic dialog functions

  openDialog = () => {
    this.setState({ saveDialog: true });
  };

  //Gets data from Firebase for read-only links

  //Resets form when clicked // Should refactor with INIT STATE

  resetForm = () => {
    window.history.pushState("object or string", "Title", "/editor/");
    this.setState({
      allSaves: {},
      saves: [],
      videoEnabled: false,
      openedTab: "overviewTab",
      snackbar: false,
      uid: this.state.uid,
      saveDialog: false,
      dimMode: true,
      config: {
        addons: {
          0: {
            name: "None",
            sku: "None",
            value: "None",
            qty: 1,
            price: 0,
            dealerPrice: 0,
          },
        },
        processors: {
          0: {
            name: "None",
            sku: "None",
            price: 0,
            qty: 1,
            value: 0,
            dealerPrice: 0,
          },
        },
        completePanels: 0,
        caseNeeded: 0,
        pixelsPerPanel: 0,
        currentPrice: 0,
        novaStar: 0,
        recCards: 0,
        singleBumpers: 0,
        dualBumpers: 0,
        singleFly: 0,
        doubleFly: 0,
        ataSpec: 0,
        saveLoad: false,
        saveId: null,
        linkId: null,
        dimSelect: "Width & Height",
        saveName: null,
        productSelected: "Select a Product",
        currentProd: {
          productIcon: {
            url: "https://firebasestorage.googleapis.com/v0/b/blizz-quote.appspot.com/o/flamelink%2Fmedia%2F1541094451536_test_product2.svg?alt=media&token=e85db290-97d8-4b3d-956a-f1ecfc286b25",
          },
        },
        dimTotal: 0,
        heightChecked: false,
        widthChecked: false,
        diagChecked: false,
        pixelHeight: 0,
        pixelWidth: 0,
        powerConsumption: 0,
        natResWidth: 0,
        natResHeight: 0,
        hd: "",
        cirNeeded: 0,
        conNeeded: 0,
        ratioChecked: false,
        widthSelected: 0,
        heightSelected: 0,
        diagSelected: 0,
        ratio: 0,
        ratioSelected_1: 0,
        ratioSelected_2: 0,
        totalWeight: 0,

        custName: "",
        yourName: "",
        discount: 0,
        price: 0,
        panelWidthNeeded: 0,
        panelHeightNeeded: 0,
        totalPanelsNeeded: 0,
        totalPrice: 0,
      },
    });
  };

  //Sets dimMode false

  handleDimSelect = (name) => (event) => {
    this.setState(
      {
        ...this.state,
        dimMode: false,
        config: {
          ...this.state.config,
          [name]: event.target.value,
        },
      },
      () => this.handleCalc()
    );
  };

  //Adjusts pricing on number inputs

  changePrice = (name, data) => (event) => {
    this.setState({
      config: {
        ...this.state.config,
        [name]: {
          ...this.state.config[name],
          adjustedPrice: event.target.value,
        },
      },
    });
  };

  handleQty = (name) => (event) => {
    this.setState({
      config: {
        ...this.state.config,
        [name]: {
          ...this.state.config[name],
          qty: event.target.value,
        },
      },
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };

  handleChecked = (name) => (event) => {
    const target = event.target.checked;

    this.setState({
      config: {
        ...this.state.config,
        [name]: target,
      },
    });
  };

  saveCallback = () => {
    this.setState(
      {
        isSaveModalOpen: false,
        snackbar: true,
        config: {
          ...this.state.config,
          saveLoad: true,
        },
      },
      () => this.delayClose()
    );
  };

  saveModal = (name) => (e) => {
    this.setState({
      isSaveModalOpen: true,
    });
  };

  updateTotalPricing = (price) => () => {
    this.setState({
      ...this.state,
      config: {
        ...this.state.config,
        totalPrice: price,
      },
    });
  };

  saveForm = (type) => () => {
    this.setState({ isWritingToDb: true });
    const userUid = this.context.user.uid;
    const data = this.state.config,
      uniqueId = generateUID(),
      url = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const existingProject = urlParams.get("project");
    const date = new Date().toUTCString();
    let projectId = existingProject ? existingProject : uniqueId;

    const saveData = {
      lastSaved: date,
      projectTitle: data.projectTitle,
      owner: userUid,
      userAccess: [userUid], //uid of users with access,
      uid: projectId,
      format: data.format,
      addons: data.addons,
      processors: data.processors,
      saveLoad: true,
      dimSelect: data.dimSelect,
      caseNeeded: data.caseNeeded,
      cirNeeded: data.cirNeeded,
      conNeeded: data.conNeeded,
      natResWidth: data.natResWidth,
      natResHeight: data.natResHeight,
      hd: data.hd,
      productSelected: data.productSelected,
      id: projectId,
      totalWeight: data.totalWeight,
      currentPrice: data.currentPrice,
      pixelsPerPanel: data.pixelsPerPanel,
      currentProd: data.currentProd,
      saveName: data.saveName,
      pixelHeight: data.pixelHeight,
      pixelWidth: data.pixelWidth,
      powerConsumption: data.powerConsumption,
      widthSelected: data.widthSelected,
      heightSelected: data.heightSelected,
      diagSelected: data.diagSelected,
      ratio: data.ratio,
      ratioSelected_1: data.ratioSelected_1,
      ratioSelected_2: data.ratioSelected_2,
      completePanels: data.completePanels,
      novaStar: data.novaStar,
      recCards: data.recCards,
      singleBumpers: data.singleBumpers,
      dualBumpers: data.dualBumpers,
      singleFly: data.singleFly,
      doubleFly: data.doubleFly,
      ataSpec: data.ataSpec,
      custName: data.custName,
      yourName: data.yourName,
      discount: data.discount,
      price: data.price,
      panelWidthNeeded: data.panelWidthNeeded,
      panelHeightNeeded: data.panelHeightNeeded,
      totalPanelsNeeded: data.totalPanelsNeeded,
    };

    const saveToFb = (res) => {
      let dealId = null;
      if (res) {
        dealId = res.data;
      }
      db.collection("savedWallQuotes")
        .doc(projectId)
        .set({ ...saveData, dealId: dealId })
        .then(() => {
          setTimeout(() => {
            this.setState({ isWritingToDb: false, config: saveData });
          }, 500);
          if (!existingProject) {
            window.history.pushState(
              {},
              null,
              `/iris-quote-builder?project=${projectId}`
            );
          }
        });
    };

    if (type === "hubspot") {
      saveQuoteToHubspotAndFb();
    } else {
      saveToFb();
    }

    //Used to reference hubspot ids

    const saveQuoteToHubspotAndFb = async () => {
      const width = data.panelWidthNeeded,
        height = data.panelHeightNeeded,
        actualWidth = (width * 500) / 25.3 / 12,
        actualHeight = (height * 500) / 25.3 / 12,
        screenArea = (actualHeight * actualWidth).toFixed(2);
      let productIds = [];

      const fetchIds = (data) => {
        _.map(data, (o) => {
          let fetchedId;
          if (o.productName) {
            fetchedId = getHubspotProductId(o.productName);
          } else {
            fetchedId = getHubspotProductId(o.value);
          }
          if (fetchedId) {
            productIds.push(fetchedId);
          }
        });
      };

      //Get addon ids
      async function getAllIds() {
        await fetchIds(data.currentProd);

        await fetchIds(data.addons);
        await fetchIds(data.processors);
      }

      getAllIds();

      //Get processor re

      const hsData = {
        amount: data.price,
        dealName: data.projectTitle,
        companyId: 796290530,
        ownerId: 39757152,
        lineItems: productIds,
        customProperties: [
          {
            value: parseFloat(data.panelWidthNeeded),
            name: "panels_wide",
          },
          {
            value: parseFloat(data.panelHeightNeeded),
            name: "panels_high",
          },
          {
            value: data.caseNeeded,
            name: "cases_needed",
          },
          {
            value: data.cirNeeded,
            name: "circuits_needed",
          },
          {
            value: data.conNeeded,
            name: "controllers_needed",
          },
          {
            value: data.singleBumpers,
            name: "single_bumpers_needed",
          },
          {
            value: data.dualBumpers,
            name: "dual_bumpers_needed",
          },
          {
            value: data.hd === "Yes" ? true : false,
            name: "native_hd",
          },
          {
            value: data.natResWidth + " x " + data.natResHeight,
            name: "native_resolution",
          },
          {
            value: data.powerConsumption,
            name: "power_consumption",
          },
          {
            value: `${data.ratio}: 1`,
            name: "ratio",
          },
          {
            value: screenArea,
            name: "screen_area",
          },
          {
            value: data.totalPanelsNeeded,
            name: "total_panels",
          },
          {
            value: data.natResWidth,
            name: "total_pixel_width",
          },
          {
            value: data.natResHeight,
            name: "total_pixel_height",
          },
          {
            value: `${data.totalWeight} lbs.`,
            name: "total_weight",
          },
          {
            value: data.pixelsPerPanel,
            name: "total_pixels",
          },
        ],
      };

      makeAxiosCall(
        "post",
        "hubspot/deals",
        hsData
      )
      .then((res) => {
        saveToFb(res);
      })
      .catch((err) => console.log(err));
    };

    saveQuoteToHubspotAndFb();
  };

  removeSave = (uid) => () => {
    this.setState({ isWritingToDb: true });
    db.collection("savedWallQuotes")
      .doc(uid)
      .delete()
      .then(() => {
        setTimeout(() => {
          this.setState({
            isWritingToDb: false,
          });
          window.location = "/iris-quote-builder";
        }, 500);
      })
      .catch((err) => console.log(err));
  };

  handleTab = (name) => (event) => {
    this.setState({
      openedTab: name,
    });
  };

  handleSaveProduct = (data, type) => (event) => {
    //  db.
    //     .ref("/" + type)
    //     .set(data)
    //     .then(() =>
    //       this.setState(
    //         {
    //           snackbar: true,
    //         },
    //         () => this.delayClose()
    //       )
    //     );
  };
  handleSaveAddons = (type) => (event) => {
    //  db.
    //     .ref("/" + type)
    //     .set(this.state.addons)
    //     .then(() =>
    //       this.setState(
    //         {
    //           snackbar: true,
    //         },
    //         () => this.delayClose()
    //       )
    //     );
  };

  handleInputChange = (name, type) => (event) => {
    if (event.target.value >= 0) {
      this.setState(
        {
          ...this.state,
          dimMode: false,
          config: {
            ...this.state.config,
            [name]: event.target.value,
          },
        },
        () => this.handleCalc(type)
      );
    } else {
      this.setState({
        ...this.state,
        config: {
          ...this.state.config,
          [name]: event.target.value,
        },
      });
    }
  };

  //Generic debounce w/ type

  //Calculate X & Y from Diag + Ratio
  calcX = (d, n, m) => {
    return (d * m) / Math.sqrt(m * m + n * n);
  };

  calcY = (d, n, m) => {
    return (d * n) / Math.sqrt(m * m + n * n);
  };

  //Generic input handler ** causes errors

  changeInputValue = (name, i, type) => (e) => {
    this.setState({
      ...this.state,
      config: {
        ...this.state.config,
        [type]: {
          ...this.state.config[type],
          [i]: {
            ...this.state.config[type][i],
            [name]: e.target.value,
          },
        },
      },
    });
  };

  //handles all calculations

  handleCalc = (type) => {
    const state = this.state.config;
    let heightSelected = state.heightSelected,
      widthSelected = state.widthSelected,
      diagSelected = state.diagSelected,
      ratio1 = state.ratioSelected_1,
      ratio2 = state.ratioSelected_2,
      ratioSelected = ratio1 / ratio2,
      prod = this.state.config.currentProd;
    function solveForLeg(leg, hypotenuse) {
      return Math.sqrt(Math.pow(hypotenuse, 2) - Math.pow(leg, 2));
    }

    function solveForHypotenuse(leg1, leg2) {
      return Math.sqrt(Math.pow(leg1, 2) + Math.pow(leg2, 2));
    }
    function solveForDiagonal(w, r) {
      const widthSqr = w / r;
      return Math.sqrt(Math.pow(widthSqr, 2) + Math.pow(w, 2));
    }

    //Calculates height and aspect ratio
    if (diagSelected > 0 && widthSelected > 0 && type === "Width & Diagonal") {
      let height = solveForLeg(widthSelected, diagSelected);

      if (parseFloat(diagSelected) < parseFloat(widthSelected)) {
        this.setState({
          config: {
            ...this.state.config,
            dimError: "The diagonal length must be greater than the width",
            panelHeightNeeded: Math.ceil(
              (heightSelected * 12 * 25.3) / prod.height
            ),
            heightSelected: Math.max(Math.ceil(height * 100) / 100),
          },
        });
      }
      if (parseFloat(diagSelected) > parseFloat(widthSelected)) {
        this.setState(
          {
            config: {
              ...this.state.config,
              dimError: null,
              panelHeightNeeded: Math.ceil(
                (heightSelected * 12 * 25.3) / prod.height
              ),
              heightSelected: Math.max(Math.ceil(height * 100) / 100),
            },
          },
          () => this.calcTotals()
        );
      }
    } else if (
      diagSelected > 0 &&
      heightSelected > 0 &&
      type === "Height & Diagonal"
    ) {
      let width = solveForLeg(heightSelected, diagSelected);
      if (parseFloat(diagSelected) < parseFloat(heightSelected)) {
        this.setState(
          {
            config: {
              ...this.state.config,
              dimError: "The diagonal length must be greater than the height",
              panelWidthNeeded: Math.ceil(
                (widthSelected * 12 * 25.3) / prod.width
              ),
              widthSelected: Math.max(Math.ceil(width * 100) / 100),
            },
          },
          () => this.calcTotals()
        );
      } else if (parseFloat(diagSelected) > parseFloat(heightSelected)) {
        this.setState(
          {
            config: {
              ...this.state.config,
              dimError: null,
              panelWidthNeeded: Math.ceil(
                (widthSelected * 12 * 25.3) / prod.width
              ),
              widthSelected: Math.max(Math.ceil(width * 100) / 100),
            },
          },
          () => this.calcTotals()
        );
      }
    } else if (
      heightSelected > 0 &&
      widthSelected > 0 &&
      type === "Width & Height"
    ) {
      let hypotenuse = solveForHypotenuse(heightSelected, widthSelected);

      this.setState(
        {
          config: {
            ...this.state.config,
            diagSelected: Math.max(Math.ceil(hypotenuse * 100) / 100),
            ratioSelected_1: widthSelected / heightSelected,
            ratioSelected_2: 1,
          },
        },
        () => this.calcTotals()
      );
    } else if (
      widthSelected > 0 &&
      ratio1 > 0 &&
      ratio2 > 0 &&
      type === "Ratio & Width"
    ) {
      const ratio = widthSelected / ratio1; //0.545
      let height = ratio * ratio2;

      const diagonal = solveForDiagonal(widthSelected, ratioSelected);
      this.setState(
        {
          config: {
            ...this.state.config,
            diagSelected: diagonal.toFixed(2),
            heightSelected: height.toFixed(2),
          },
        },
        () => this.calcTotals()
      );
    } else if (
      diagSelected > 0 &&
      //m
      ratio1 > 0 &&
      //n
      ratio2 > 0 &&
      type === "Diagonal & Ratio"
    ) {
      const x = this.calcX(diagSelected, ratio2, ratio1),
        y = this.calcY(diagSelected, ratio2, ratio1),
        w = x.toFixed(2),
        h = y.toFixed(2);
      this.setState(
        {
          config: {
            ...this.state.config,
            widthSelected: w,
            heightSelected: h,
          },
        },
        () => this.calcTotals()
      );
    } else if (
      heightSelected > 0 &&
      ratioSelected > 0 &&
      type === "Ratio & Height"
    ) {
      const ratio = heightSelected / ratio2;
      const width = ratio * ratio1;

      let hypotenuse = solveForHypotenuse(heightSelected, widthSelected);
      this.setState(
        {
          config: {
            ...this.state.config,
            diagSelected: hypotenuse.toFixed(2),
            widthSelected: width.toFixed(2),
          },
        },
        () => this.calcTotals()
      );
    }
  };

  //Main calculator function
  calcTotals = () => {
    const { processors } = this.props;
    const firstProcessorInObj = this.parseProcessorData(processors[0].num);
    const defaultProcessorPrice = parseFloat(firstProcessorInObj.price);
    const defaultProcessor = {
      ...firstProcessorInObj,
      price: defaultProcessorPrice,
    };

    const { config } = this.state,
      prod = config.currentProd;

    //total panels
    let panelsWide = Math.ceil(
        (config.widthSelected * 25.3) / parseFloat(prod.width)
      ),
      panelsHigh = Math.ceil(
        (config.heightSelected * 25.3) / parseFloat(prod.height)
      );

    if (config.format === "Ft.") {
      panelsWide = Math.ceil(
        (config.widthSelected * 12 * 25.3) / parseFloat(prod.width)
      );
      panelsHigh = Math.ceil(
        (config.heightSelected * 12 * 25.3) / parseFloat(prod.height)
      );
    } else if (config.format === "cm") {
      panelsWide = Math.ceil(
        (config.widthSelected * 0.393 * 25.3) / parseFloat(prod.width)
      );
      panelsHigh = Math.ceil(
        (config.heightSelected * 0.393 * 25.3) / parseFloat(prod.height)
      );
    } else if (config.format === "mm") {
      panelsWide = Math.ceil(
        (config.widthSelected * 0.0393 * 25.3) / parseFloat(prod.width)
      );
      panelsHigh = Math.ceil(
        (config.heightSelected * 0.0393 * 25.3) / parseFloat(prod.height)
      );
    }

    //Bumpers
    const dualBumpers = panelsWide / 2;
    const { addons } = this.props;
    let singleBumpers = !Number.isInteger(dualBumpers) ? 1 : 0;

    let flyBar1Weight = _.filter(addons, (addon) => {
        return addon.num.includes("FLY1");
      }),
      flyBar2Weight = _.filter(addons, (addon) => {
        return addon.num.includes("FLY2");
      }),
      flyBar1TotalWeight = flyBar1Weight[0].weight * config.singleBumpers,
      flyBar2TotalWeight = flyBar2Weight[0].weight * config.dualBumpers;

    let totalPanels = panelsWide * panelsHigh,
      //weight

      weight =
        totalPanels * prod.weight +
        parseFloat(flyBar2TotalWeight) +
        parseFloat(flyBar1TotalWeight),
      totalWeight = weight.toFixed(1),
      //pixels per panel
      pixels = prod.pixelsPerPanel * totalPanels,
      //power Consumption
      //divided BY 4
      power = (prod.powerConsumption / 4) * totalPanels,
      //native resolution
      natResWidth = prod.pixelWidth * panelsWide,
      natResHeight = prod.pixelHeight * panelsHigh,
      //circuits needed
      cirNeeded = Math.ceil(power / 1800),
      //controllers needed
      conNeeded = Math.ceil(pixels / 1200000);
    //native hd check
    let hd = null;
    //input 2048x1664
    if (natResWidth < 1280 || natResHeight < 720) {
      hd = "No";
    } else if (
      natResWidth >= 1280 &&
      natResWidth < 1920 &&
      natResHeight >= 720 &&
      natResHeight < 1080
    ) {
      hd = "720p"; // 1280x720
    } else if (
      natResWidth >= 1920 &&
      natResWidth < 2560 &&
      natResHeight >= 1080 &&
      natResHeight < 1440
    ) {
      hd = "1080p"; // 1920x1080
    } else if (
      natResWidth >= 2560 &&
      natResWidth < 3840 &&
      natResHeight >= 1440 &&
      natResHeight < 2160
    ) {
      hd = "QHD"; // 2560x1440
    } else if (natResWidth >= 3840 && natResHeight >= 2160) {
      hd = "4K"; // 3840x2160
    } else {
      hd = "Yes";
    }
    //ratio

    let ratio = panelsWide / panelsHigh;

    //cases needed
    const caseNeeded = Math.ceil(totalPanels / prod.casePak),
      optimalProcessors = {
        ...defaultProcessor,
        qty: conNeeded,
      };

    this.setState(
      {
        config: {
          ...this.state.config,
          processors: { 0: { ...optimalProcessors } },
          totalWeight: totalWeight,
          pixelsPerPanel: pixels,
          dualBumpers: Math.floor(dualBumpers),
          singleBumpers: singleBumpers,
          caseNeeded: caseNeeded,
          cirNeeded: cirNeeded,
          conNeeded: conNeeded,
          natResWidth: natResWidth,
          natResHeight: natResHeight,
          hd: hd,
          panelWidthNeeded: panelsWide,
          panelHeightNeeded: panelsHigh,
          totalPanelsNeeded: totalPanels,
          ratio: ratio.toFixed(2),
          pixelHeight: prod.pixelHeight,
          pixelWidth: prod.pixelWidth,
          powerConsumption: power,
        },
      },
      () => this.calcPrice()
    );
  };

  renderStart = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("project");
    const el = document.querySelector("#start01");

    if (el) {
      var offsets = el.getBoundingClientRect();
      var top = offsets.top;

      if (type === null && this.state.helper) {
        if (!this.state.config.currentProd.productName) {
          return (
            <div
              className="start-mode dialog"
              style={{
                top: top,
              }}
            >
              <i
                style={{ float: "right", cursor: "pointer" }}
                className="fas fa-times-circle"
                onClick={() => {
                  this.setState({
                    helper: false,
                  });
                }}
              ></i>
              <h3>Start Here</h3>
              <p>Start by selecting a product</p>
            </div>
          );
        } else if (!this.state.config.panelHeightNeeded) {
          return (
            <div
              className="start-mode dialog"
              style={{
                top: 350,
              }}
            >
              <i
                style={{ float: "right", cursor: "pointer" }}
                className="fas fa-times-circle"
                onClick={() => {
                  this.setState({
                    helper: false,
                  });
                }}
              ></i>
              <h3>Enter Dimensions</h3>
              <p>Select your desired wall dimensions</p>
            </div>
          );
        }
      }
    }
  };

  calcPrice = () => {
    const config = this.state.config,
      price = config.currentProd.price;
    this.setState({
      config: {
        ...this.state.config,
        price: parseFloat(price) * config.totalPanelsNeeded,
      },
    });
  };

  setProjectTitle = (e) => {
    this.setState({
      config: {
        ...this.state.config,
        projectTitle: e.target.value,
      },
    });
  };

  getExistingProject(uid) {
    const urlParams = new URLSearchParams(window.location.search);
    const existingProject = urlParams.get("project");

    if (existingProject) {
      db.collection("savedWallQuotes")
        .doc(existingProject)
        .get()
        .then((res) => {
          const data = res.data();
          if (
            data.userAccess.includes(this.context.user.uid) ||
            this.context.user.company === "Blizzard"
          ) {
            this.setState({
              config: data,
            });
          } else {
            alert("You are not shared on this project");
          }
        })
        .catch((err) => console.log(err));
    }
  }

  componentDidMount() {
    const { getIrisPanels, getIrisProcessors, getIrisAddons } = this.props;
    getIrisPanels();
    getIrisProcessors();
    getIrisAddons();

    this.getExistingProject();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getExistingProject();
    }
  }

  render() {
    const { isSaveModalOpen, distributorStatus } = this.state;
    const { products, processors, addons } = this.props;
    return (
      <div className="iris-quote-builder">
        {this.renderStart()}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.snackbar}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Everything Saved</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <Home
          helper={this.state.helper}
          updateTotalPricing={this.updateTotalPricing}
          removeSave={this.removeSave}
          isWritingToDb={this.state.isWritingToDb}
          processors={processors}
          products={products}
          addons={addons}
          handleQuote={this.handleQuote}
          distributorStatus={distributorStatus}
          isSaveModalOpen={isSaveModalOpen}
          setProjectTitle={this.setProjectTitle}
          saveModal={this.saveModal}
          removeAddon={this.removeAddon}
          handleRadio={this.handleRadio}
          handleAddonSelect={this.handleAddonSelect}
          appendInput={this.appendInput}
          changeInputValue={this.changeInputValue}
          dimMode={this.state.dimMode}
          handleQty={this.handleQty}
          changePrice={this.changePrice}
          loadLocalStorage={this.loadLocalStorage}
          handleTab={this.handleTab}
          openedTab={this.state.openedTab}
          saveDialog={this.state.saveDialog}
          openDialog={this.openDialog}
          closeDialog={this.closeDialog}
          handleClose={this.handleClose}
          snackbar={this.state.snackbar}
          item={this.state.item}
          config={this.state.config}
          enableVideo={this.enableVideo}
          videoEnabled={this.state.videoEnabled}
          handleChecked={this.handleChecked}
          clearState={this.clearState}
          handleInputChange={this.handleInputChange}
          handleDimSelect={this.handleDimSelect}
          handleSelect={this.handleSelect}
          newCalc={this.newCalc}
          saveForm={this.saveForm}
          resetForm={this.resetForm}
          uid={this.state.uid}
          user={this.context.user}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.irisPanels,
    processors: state.products.irisProcessors,
    addons: state.products.irisAddons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIrisPanels,
    getIrisProcessors,
    getIrisAddons,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(QuoteBuilder);
