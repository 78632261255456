import React from "react";
import { FormControlLabel, Checkbox, Button } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import _ from "lodash";

interface ChannelOptionProps {
    name: string,
    options?: string[],
    handleChange: (obj: any, type?: any) => void,
    type?: string,
    order: number,
}

const ChannelOption = ({ name, options, handleChange, type, order }: ChannelOptionProps) => {
    const [showCheckboxes, setshowCheckboxes] = React.useState(false);
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    //TODO on Add append next number by color
    React.useEffect(() => {
        if (options && options.length > 0) {
            const checkboxes = [];
            options.map((o) => {
                checkboxes.push({ label: o, checked: false });
            });
            setSelectedOptions(checkboxes);
        }
    }, [options]);

    const handleCheckbox = (i) => {
        const currentOptions = selectedOptions.slice();
        currentOptions[i] = {
            label: currentOptions[i].label,
            checked: !currentOptions[i].checked,
        };

        setSelectedOptions(currentOptions);
    };

    const determineDisable = () => {
        const checkedBoxes = _.filter(selectedOptions, { checked: true });
        if (checkedBoxes.length > 0) {
            return false;
        }
        return true;
    };

    return (
        <div className="profile-option">
            <div
                className="profile-button"
                onClick={() =>
                    options && options.length > 0
                        ? setshowCheckboxes(!showCheckboxes)
                        : handleChange([{ label: name, checked: true }])
                }
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {name}
                    <span className={showCheckboxes ? "arrow active" : "arrow"}>
                        {options ? (
                            <i className="fas fa-chevron-right"></i>
                        ) : (
                            <i className="fas fa-plus"></i>
                        )}
                    </span>
                </div>
            </div>

            <CSSTransition
                in={showCheckboxes}
                unmountOnExit
                timeout={200}
                classNames="dialog"
            >
                <>
                    {_.map(selectedOptions, (option, i) => {
                        return (
                            <FormControlLabel
                                className="profile-checkboxes"
                                control={
                                    <Checkbox
                                        checked={option.checked}
                                        onChange={() => handleCheckbox(i)}
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                    />
                                }
                                label={option.label}
                                key={option.label}
                            />
                        );
                    })}
                    <Button
                        type="submit"
                        style={{ marginLeft: "1em", marginTop: "1em", display: "block" }}
                        variant="contained"
                        color="primary"
                        disabled={determineDisable()}
                        className="btn"
                        onClick={() => handleChange(selectedOptions, type ? type : null)}
                    >
                        Add
                        <i style={{ marginLeft: 10 }} className="far fa-plus-circle"></i>
                    </Button>
                </>
            </CSSTransition>
        </div>
    );
};

export default ChannelOption;
