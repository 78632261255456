import React from "react";
import { ProductInterface, ProductMediaInterface } from "interfaces/Product";
import { Button, Checkbox, Grid, Paper } from "@material-ui/core";
import JSZip from "jszip";
import FileSaver from "file-saver";
import JSZipUtils from "jszip-utils";
import { logFileDownload, makeAxiosCall } from "utils";

interface ProductImagesProps {
  product: ProductInterface,
  writeAccess: boolean,
}

const ProductImages = ({ product, writeAccess }: ProductImagesProps) => {

  const [selectedImages, setSelectedImages] = React.useState<ProductMediaInterface[]>([]);

  const images = React.useMemo(() => {
    if (product.media) {
      return product.media.filter((item) => item.type.name == "image");
    } else {
      return [];
    }
  }, [product]);

  React.useEffect(() => {
    console.log(images);
  }, [images]);

  const featureImage = async (imageId) => {
    console.log("Featuring image " + imageId);
  }

  const deleteImage = async (imageId) => {
    console.log("DELETING IMAGE " + imageId);
    // Disable for now
    // const response = await makeAxiosCall(
    //   "delete",
    //   `product-media/${imageId}`,
    // ).catch((error) => {
    //   console.error(error);
    // });
  }

  const handleSelectImage = (imageId) => {
    if (selectedImages.some((i) => i.id == imageId)) {
      setSelectedImages(selectedImages.filter((i) => i.id != imageId));
    } else {
      setSelectedImages([...selectedImages, images.find((i) => i.id == imageId)]);
    }
  }

  const handleSelectAll = () => {
    if (selectedImages.length == images.length) {
      setSelectedImages([]);
    } else {
      setSelectedImages([...images])
    }
  }

  const handleBulkDownload = async () => {
    let zip = new JSZip();
    let count = 0;
    let zipFilename = `${product.name}-images.zip`;

    selectedImages.forEach((image, i) => {
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(image.url, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        logFileDownload(image.name, image.url, image.id);
        zip.file(image.name, data, { binary: true });
        count++;
        if (count == selectedImages.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, zipFilename);
            setSelectedImages([]);
          });
        }
      });
    });
  };

  const renderImageItem = (image: ProductMediaInterface) => {
    return (
      <Paper key={image.id} elevation={2} className="grid-search-result">
        <Grid container item xs={12} className="center-text" alignItems="center">
          <Grid item xs={1}>
            <Checkbox
              checked={selectedImages.some((i) => i.id == image.id)}
              onChange={() => handleSelectImage(image.id)}
            />
          </Grid>
          <Grid item xs={2}>
            <img src={image.url} alt={""} style={{ maxHeight: "100%", maxWidth: "100%", padding: 15 }} />
          </Grid>
          <Grid item xs={2}>
            {image.name.split(".")[1].toUpperCase()}
          </Grid>
          <Grid item xs={2}>
            <i 
              className="fa-solid fa-arrow-up-right-from-square"
              onClick={() => {
                logFileDownload(image.name, image.url, image.id);
                window.open(image.url, "_blank");
              }}
            />
          </Grid>
          {writeAccess && (
            <>
              <Grid item xs={2}>
                <i
                  className="fa-regular fa-star"
                  style={{ color: "blue" }}
                  onClick={() => featureImage(image.id)}
                />
              </Grid>
              <Grid item xs={2}>
                <i 
                  className="fa-solid fa-trash"
                  style={{ color: "tomato" }}
                  onClick={() => deleteImage(image.id)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    );
  }

  const renderImageList = () => {
    return (
      images.map((image) => renderImageItem(image))
    );
  }

  return images.length > 0 ? (
    <Grid item xs={12} className="grid-search">
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleBulkDownload}
          disabled={selectedImages.length == 0}
        >
          Download {selectedImages.length} Images
        </Button>
      </Grid>

      <Grid container className="results">
        <Grid container item xs={12} className="center-text grid-search-headers" alignItems="center">
          <Grid xs={1} item>
            <Checkbox
              checked={selectedImages.length == images.length}
              onChange={handleSelectAll}
            />
          </Grid>
          <Grid xs={2} item>
          </Grid>
          <Grid item xs={2}>
            File Type
          </Grid>
          <Grid item xs={2}>
            View
          </Grid>
          {writeAccess && (
            <>
              <Grid item xs={2}>
                Feature
              </Grid>
              <Grid item xs={2}>
                Delete
              </Grid>
            </>
          )}
        </Grid>
        {renderImageList()}
      </Grid>
    </Grid>
  ) : (
    <h4>No images found</h4>
  );
}

export default ProductImages;