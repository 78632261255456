import React from "react";
import { CartContext } from "context/Cart";
import OrderItem from "components/Checkout/OrderItem";
import _ from "lodash";
import OrderOnBehalfOf from "./OrderOnBehalfOf";
import { OrderItemInterface } from "interfaces/Order";
import { UserContext } from "context/User";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@material-ui/core";
import { Info, Warning } from "@material-ui/icons";
import DemoOrderFlag from "components/utils/DemoOrderFlag";

interface OrderProps {
    title?: string,
}

const Order = ({ title }: OrderProps) => {
    const { currentOrder } = React.useContext(CartContext);
    const { currentUser } = React.useContext(UserContext);
    const [ showBstock, setShowBstock ] = React.useState<boolean>(false);
    const [ bstockInfoOpen, setBstockInfoOpen] = React.useState<boolean>(false);

    const calculateTotal = () => {
        let total = 0;
        currentOrder.orderItems.forEach(function (cartItem, index, arry) {
            total += cartItem.product.price * cartItem.quantity;
        });
        return total;
    };
  
    React.useEffect(() => {
        if(currentOrder.orderItems && currentOrder.orderItems.find((i) => i.product.bstock > 0)) {
            setShowBstock(true);
        }
    }, [currentOrder.orderItems]);

    const showBstockInfo = () => {
        setBstockInfoOpen(true);
    };

    const hideBstockInfo = () => {
        setBstockInfoOpen(false);
    };

    const bstockInfoDialog = () => {
        return (
            <Dialog
                open={bstockInfoOpen}
                onClose={hideBstockInfo}
            >
                <DialogTitle>
          What is B-Stock?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
            B-Stock are our used products. <br /><br />
            Sometimes these fixtures are exactly like new, and sometimes they have cosmetic differences. <br /><br />
            If you&apos;re interested in one of these products, check this box, and a member of our team will reach out personally to provide more details on the individual product and discount.<br /><br />
            If you were referred by a Blizzard member already, be sure to make note of who you talked to in your order notes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={hideBstockInfo} autoFocus>
            Got it
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const buildUnderStockWarning = () => {
        let underStockItems: String[] = [];

        for (let ii = 0; ii < currentOrder.orderItems.length; ii++) {
            const item = currentOrder.orderItems[ii];

            if(item.quantity > item.product.inventory) {
                underStockItems.push(item.product.name);
            }
        }

        if(underStockItems.length) {
            return (<Paper style={{ 
                        width: "75%", 
                        textAlign: "center", 
                        margin: "15px auto 0 auto", 
                        padding: 15, 
                        backgroundColor: "#ffedc7"
                    }}>
                    <Warning />
                    <p>
                        There are items in your cart <strong>({underStockItems.join(', ')})</strong> that are either not in stock, or you're asking for more than we have in stock. 
                        This could cause delays, please reach out if you have any questions
                    </p>
            </Paper>)
        } else {
            return <></>;
        }

    }

    return (
        <div className="order-contents">
            { bstockInfoDialog() }
            { (currentUser.company.companyType === "Rep" || currentUser.company.companyType === "Internal" && !currentOrder.demoOrder) ? <OrderOnBehalfOf /> : null }
            <DemoOrderFlag />
            <h3 className="centered">{title}</h3>

            <div className="row-heads ch">
                <div className="num">Product</div>
                {showBstock ? <div className="bstock" onClick={showBstockInfo}>
          B-Stock
                    <Info style={{paddingLeft: 4}} fontSize="small" />
                </div> : null}
                <div className="price-per">Unit Price</div>
                <div className="price">Price</div>
                <div className="qty">Qty</div>
                <div className="notes"></div>
                <div className="delete"></div>
            </div>
            {_.map(currentOrder.orderItems, (o: OrderItemInterface) => {
                return <OrderItem key={o.product.id} cartItem={o} />;
            })}
            <div className="subtotal">
                <div className="container">
                    <h4>Subtotal</h4>
                    <p>
                        {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                        }).format(calculateTotal())}
                    </p>
                </div>
            </div>
            { buildUnderStockWarning() }
            <div style={{ width: "75%", textAlign: "center", margin: "15px auto 0 auto"}}>
                    After an order is submitted, one of our staff will review your order and
                    apply shipping costs and any applicable discounts. Your account will not be 
                    charged until the order is processed and fulfilled. For questions about
                    placing a product order, please call <a href="tel:4143958365">(414) 395-8365</a> or email <a href="mailto:orders@blizzardpro.com"
                >orders@blizzardpro.com</a>.
            </div>
        </div>
    );
};

export default Order;
