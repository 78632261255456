import React, { useState, useContext } from "react";
import _ from "lodash";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, ButtonGroup, Box, Chip, OutlinedInput } from "@material-ui/core";
import { ProductContext } from "context/Product";
import { UserContext } from "context/User";
import { useNavigate } from "react-router-dom";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import { makeAxiosCall } from "utils";
import { buildSalesOrderList, SalesOrderInterface } from "interfaces/SalesOrder";
import { DatePicker } from "@material-ui/pickers";
import { CSVLink } from "react-csv";

interface ProductSalesListing {
  id?: number,
  name: string,
  quantity: number,
  returns: number,
  total: number,
  productType?: string,
  category?: string,
  sku?: string,
  
};

const AllProductSales = () => {
  const { products } = useContext(ProductContext);
  const { internalUser } = useContext(UserContext);

  const [salesOrders, setSalesOrders] = React.useState<SalesOrderInterface[]>();
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [productCategories, setProductCategories] = useState<string[]>([]);

  const [visibleProducts, setVisibleProducts] = React.useState<ProductSalesListing[]>();
  const [productListings, setProductListings] = React.useState<ProductSalesListing[]>([]);

  const [input, setInput] = useState<string>("");
  const [productTypeFilter, setProductTypeFilter] = useState<string>("");
  const [productCategoryFilter, setProductCategoryFilter] = useState<string>("");
  const [filterStartDate, setFilterStartDate] = React.useState<Date>(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [filterEndDate, setFilterEndDate] = React.useState<Date>(new Date());
  const [sortMethods, setSortMethods] = React.useState<string[]>([]);

  const [loading, setLoading] = React.useState(true);

  const windowScrollerRef = React.useRef<WindowScroller>();
  const virtualizedListRef = React.useRef<List>();

  const navigate = useNavigate();

  const sortOptions = [
    {
      label: "Name (A - Z)",
      value: "name",
      order: "asc",
    },
    {
      label: "Name (Z - A)",
      value: "name",
      order: "desc",
    },
    {
      label: "Quantity (Low - High)",
      value: "quantity",
      order: "asc",
    },
    {
      label: "Quantity (High - Low)",
      value: "quantity",
      order: "desc",
    },
    {
      label: "Total (Low - High)",
      value: "total",
      order: "asc",
    },
    {
      label: "Total (High - Low)",
      value: "total",
      order: "desc",
    },
    {
      label: "Returns (Low - High)",
      value: "returns",
      order: "asc",
    },
    {
      label: "Returns (High - Low)",
      value: "returns",
      order: "desc",
    },
  ];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 800,
        width: 250,
      },
    },
  };

  React.useEffect(() => {
    if (productListings.length == 0) {
      loadSalesOrders();
    }

    if (productTypes.length == 0) {
      loadProductTypes();
    }

    if (productCategories.length == 0) {
      loadProductCategories();
    }

  }, []);

  React.useEffect(() => {
    filterProducts();
  }, [productListings, input, productTypeFilter, productCategoryFilter, sortMethods]);

  React.useEffect(() => {
    loadProductTypes();
    loadProductCategories();
    filterProducts();
  }, [products]);

  React.useEffect(() => {
    if (salesOrders) {
      buildListings();
    }
  }, [salesOrders]);

  const today = new Date();

  const isToday = (date?: Date) => {
    return date ? (
      date.toDateString() == today.toDateString()
    ) : false;
  }

  const thirtyDays = React.useMemo(() => {
    return new Date(new Date(new Date().setDate(new Date().getDate() - 30)).toDateString());
  }, []);
  
  const isThirtyDays = React.useMemo(() => {
    return filterStartDate ? (
      filterStartDate.toDateString() == thirtyDays.toDateString() &&
      isToday(filterEndDate)
    ) : false;
  }, [filterStartDate, filterEndDate]);

  const oneYear = React.useMemo(() => {
    return new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toDateString());
  }, []);

  const isOneYear = React.useMemo(() => {
    return filterStartDate ? (
      filterStartDate.toDateString() == oneYear.toDateString() &&
      isToday(filterEndDate)
    ) : false;
  }, [filterStartDate, filterEndDate]);

  const startOfYear = React.useMemo(() => {
    let startOfYear = new Date();
    startOfYear.setDate(1);
    startOfYear.setMonth(0);
    return new Date(startOfYear.toDateString());
  }, []);

  const isYTD = React.useMemo(() => {
    return filterStartDate ? (
      filterStartDate.toDateString() == startOfYear.toDateString() &&
      isToday(filterEndDate)
    ) : false;
  }, [filterStartDate, filterEndDate]);

  const loadProductTypes = () => {
    let productTypes = [];
    for (let pi = 0; pi < products.length; pi++) {
      const product = products[pi];
      if (product.productType && !productTypes.includes(product.productType)) {
        productTypes.push(product.productType);
      }
    }
    setProductTypes(productTypes);
  }

  const loadProductCategories = () => {
    let productCats = [];
    for (let pi = 0; pi < products.length; pi++) {
      const product = products[pi];

      
      if(product.category && !productCats.includes(product.category)) {
        productCats.push(product.category);
      }
    }
    productCats.sort();
    console.log(productCats);
    setProductCategories(productCats);
  }

  const loadSalesOrders = async (startDate?: Date, endDate?: Date) => {
    setLoading(true);
    
    // Remove time from dates
    let validStart = startDate ? startDate : filterStartDate;
    validStart = new Date(validStart.toDateString());
    let validEnd = endDate ? endDate : filterEndDate;
    validEnd = new Date(validEnd.toDateString());

    const response = await makeAxiosCall(
      "POST",
      "sales-order-history",
      {
        startDate: validStart,
        endDate: validEnd,
      }
    );

    if (response?.status == 200) {
      setSalesOrders(buildSalesOrderList(response.data));
    }
  }

  const buildListings = () => {
    const salesListings: ProductSalesListing[] = [];

    // Make sure all portal products at least show up
    products.forEach((product) => {
      salesListings.push({
        id: product.id,
        name: product.name,
        quantity: 0,
        returns: 0,
        total: 0,
        productType: product.productType,
        category: product.category,
        sku: product.sku,
      });
    });

    // Add the real data
    salesOrders.forEach((so) => {
      let isDemo: boolean = so.soNumber.toLowerCase().includes("demo");
      so.items.forEach((item) => {
        let existingItem: ProductSalesListing;
        if (item.productId) existingItem = salesListings.find((listing) => listing.id == item.productId);

        if (existingItem && !isDemo && item.status.name == "Fulfilled") {
          if (item.totalPrice < 0) {
            existingItem.returns += item.quantityOrdered;
          } else {
            existingItem.quantity += item.quantityOrdered;
          }
          existingItem.total += item.totalPrice;
        }
      })
    });

    setProductListings(salesListings);
  }

  const goToSingleProduct = async (id: number) => {
    navigate(
      `/products/${id}`,
      {
        state: {
          tab: "Sales",
          startDate: filterStartDate,
          endDate: filterEndDate
        }
      }
    );
  };

  const filterProducts = () => {
    let searchResults: ProductSalesListing[] = productListings;

    // Search input filtering
    if (input.length > 0) {
      const searchInput = input.toLowerCase(),
        inputIgnoredChar = searchInput;

      searchResults = _.filter(searchResults, (p: ProductSalesListing) => {
        let prodNum = "";
        let prodSku = "";
        if (p.name !== null) {
          prodNum = p.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");
        }
        if (p.sku !== null) {
          prodSku = p.sku;
        }

        const arr = [prodNum, prodSku];
        const regexQuery = RegExp(inputIgnoredChar.replaceAll(" ", ".*").replaceAll("-", ".*"));
        for (let ati = 0; ati < arr.length; ati++) {
          const element = arr[ati];

          if (regexQuery.test(element)) return true;
        }
        return false;
      });
    }

    // Product type filtering
    if (productTypeFilter.length > 0) {
      searchResults = _.filter(searchResults, (p: ProductSalesListing) => p.productType == productTypeFilter);
    }

    // Product category filtering
    if (productCategoryFilter.length > 0) {
      searchResults = _.filter(searchResults, (p: ProductSalesListing) => p.category == productCategoryFilter);
    }

    // Sorting
    if (sortMethods.length > 0) {
      searchResults = _.orderBy(
        searchResults,
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).value),
        sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
      );
    } else {
      searchResults = _.orderBy(searchResults, ["quantity", "name"], ["desc", "asc"]);
    }

    setVisibleProducts(searchResults);

    if (loading && searchResults?.length > 0) setLoading(false);
  }

  const renderPrice = (prod: ProductSalesListing) => {
    let price = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format((prod.total));
    return (
      <Grid item xs={3} sm={3} md={1} style={{ fontWeight: "bold", color: prod.total >= 0 ? "black" : "red" }}>
        {price}
      </Grid>
    );
  };

  const buildFilters = () => {
    return (
      <div className="product-filters-container" style={{ overflow: "hidden" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} md={3}>
            <DatePicker
              style={{ width: "100%" }}
              margin="normal"
              inputVariant="outlined"
              format={"MM/DD/yy"}
              label="Start Date"
              views={["date"]}
              disableFuture={true}
              value={filterStartDate}
              onChange={(date: moment.Moment) => {
                setFilterStartDate(date.toDate())
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DatePicker
              style={{ width: "100%" }}
              margin="normal"
              inputVariant="outlined"
              format={"MM/DD/yy"}
              label="End Date"
              views={["date"]}
              disableFuture={true}
              value={filterEndDate}
              onChange={(date: moment.Moment) => {
                setFilterEndDate(date.toDate())
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <ButtonGroup 
                fullWidth={true} 
                color="primary" 
                aria-label="outlined primary button group" 
                style={{ 
                  height: 56,
                  marginTop: 8
                }}
            >
              <Button
                className={
                    isThirtyDays ?
                    "active-filter-button" :
                    "inactive-filter-button"
                }
                onClick={() => {
                  let startDate = thirtyDays;
                  let endDate = today;
                  setFilterStartDate(startDate);
                  setFilterEndDate(endDate);
                  loadSalesOrders(startDate, endDate);
                }}
              >
                30 Days
              </Button>
              <Button
                className={
                    isOneYear ?
                    "active-filter-button" :
                    "inactive-filter-button"
                }
                onClick={() => {
                  let startDate = oneYear;
                  let endDate = today;
                  setFilterStartDate(startDate);
                  setFilterEndDate(endDate);
                  loadSalesOrders(startDate, endDate);
                }}
              >
                1 Year
              </Button>
              <Button
                className={
                    isYTD ? 
                    "active-filter-button" :
                    "inactive-filter-button"
                }
                onClick={() => {
                  setFilterStartDate(startOfYear);
                  setFilterEndDate(today);
                  loadSalesOrders(startOfYear, today);
                }}
              >
                YTD
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={6} md={1}>
            <Button
              type="submit"
              style={{ height: 56, marginTop: 8 }}
              variant="contained"
              color="primary"
              className="btn"
              onClick={() => loadSalesOrders()}
              fullWidth
            >
              <i className="far fa-refresh"></i>
            </Button>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              style={{ height: 56, marginTop: 8 }}
              variant="contained"
              color="primary"
              className="btn"
              fullWidth
              disabled={!visibleProducts || visibleProducts.length == 0}
            >
              <CSVLink
                data={visibleProducts ? visibleProducts : ""}
                headers={[
                  { label: "Product ID", key: "id" },
                  { label: "Product Name", key: "name" },
                  { label: "SKU", key: "sku" },
                  { label: "Quantity", key: "quantity" },
                  { label: "Total", key: "total" },
                  { label: "Returns", key: "returns" }
                ]}
                filename={`Sales-${filterStartDate.toDateString()}-${filterEndDate.toDateString()}.csv`}
                style={{ color: "white", border: "none" }}
                target="_blank"
              >
                Download CSV &nbsp;<i className="far fa-download"></i>
              </CSVLink>
            </Button>
          </Grid>
          
        </Grid>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} md={6}>
            <div className="inventory-search">
              <TextField
                label="Product Search"
                variant="outlined"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="product-category-select-label">Category</InputLabel>
              <Select
                labelId="product-category-select-label"
                id="select-product-category"
                value={productCategoryFilter}
                label="Category"
                onChange={(event) => {
                  setProductCategoryFilter(event.target.value + "");
                }}
              >
                <MenuItem value={""}>Any</MenuItem>
                {_.map(
                  productCategories,
                  (cat: string, i: number) => {
                    return <MenuItem key={"cat-" + i} value={cat}>{cat}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="product-type-select-label">Type</InputLabel>
              <Select
                labelId="product-type-select-label"
                id="select-product-type"
                value={productTypeFilter}
                label="Type"
                onChange={(event) => {
                  setProductTypeFilter(event.target.value + "");
                }}
              >
                <MenuItem value={""}>Any</MenuItem>
                {_.map(
                  productTypes,
                  (type: string, i: number) => {
                    return <MenuItem key={"type-" + i} value={type}>{type}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item container alignContent="flex-start" xs={6} md={3}>
            <Grid item xs={12}>Sort By</Grid>
            <Grid item xs={12}>
              <FormControl style={{ width: "100%" }}>
                <Select
                  key="sortMethods"
                  multiple
                  value={sortMethods}
                  renderValue={() => (
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {sortMethods.map((method) => (
                        <Chip key={method} label={method} style={{ marginRight: 5 }} />
                      ))}
                    </Box>
                  )}
                  onChange={(e: React.ChangeEvent<{
                    name?: string;
                    value: string[];
                  }>) => {
                    setSortMethods(e.target.value);
                  }}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                >
                  {sortOptions.map(
                    (option) => {
                      const index = sortMethods.findIndex((n) => n == option.label);
                      return (
                        <MenuItem
                          key={option.label}
                          value={option.label}
                        >
                          <span 
                              className={index > -1 ? "green" : "grey"} 
                              style={{ 
                                margin: 5, 
                                marginRight: 10, 
                                fontSize: "1.2em", 
                                color: index > -1 ? "green" : "grey" 
                              }}
                          >
                            {index > -1 ? index + 1 : " "}
                          </span>
                          {option.label}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  const renderProductItem = (prod: ProductSalesListing, key, subProduct: boolean, phoneMode: boolean) => {
    return (
      <Grid 
          style={{ 
            marginBottom: (subProduct ? 10 : 0), 
            minHeight: 80, 
            paddingBottom: 10, 
            width: "100%", 
            border: (subProduct ? "1px solid rgba(0, 0, 0, 0.12)" : "") 
          }} 
          container 
          spacing={1} 
          className="line-item" 
          key={key}
      >
        <Grid item container xs={12} sm={8} md={7}
          style={{ cursor: "pointer", fontWeight: "bold" }}
          className="num"
          onClick={() => {
            goToSingleProduct(prod.id);
          }}
        >
          {prod.name}
        </Grid>
        <Grid item container xs={12} sm={4} md={1}
          style={{ cursor: "pointer", fontWeight: "bold" }}
          className="num"
        >
          {prod.sku}
        </Grid>
        {renderPrice(prod)}
        <Grid item xs={3} sm={3} md={1} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 70,
              margin: "0 auto"
            }}
          >
            {prod.quantity}
          </div>
        </Grid>
        <Grid item xs={3} sm={3} md={1} style={{ textAlign: "center" }}>
          <div
            style={{
              width: 70,
              margin: "0 auto"
            }}
          >
            {prod.returns}
          </div>
        </Grid>
        {!phoneMode &&
          <Grid item xs={12} sm={3} md={1}
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              goToSingleProduct(prod.id);
            }}
          >
            <i className={prod.id ? "fas fa-eye" : ""} style={{ color: "blue" }}></i>
          </Grid>
        }
      </Grid>
    );
  }

  const calcRowHeight = (index, phoneMode: boolean): number => {
    return 91 + (phoneMode ? 40 : 0);
  }

  const renderRow = (index, key, style, phoneMode: boolean) => {
    const prod: ProductSalesListing = visibleProducts[index];

    return (
      <div key={key} style={style} className={"single-product-line"}>
        {renderProductItem(prod, key, false, phoneMode)}
      </div>
    );
  }

  const renderPlaceholder = () => {
    return <h3 className="body-message center-text">No products meet your search parameters.</h3>;
  }

  const renderResults = () => {
    if (loading) {
      return <Loading height="100vh" title={"Loading Products"} position={"center"} />;
    } else {
      return <WindowScroller ref={windowScrollerRef} style={{ overflow: "hidden" }}>
        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
          const phoneMode = width < 500;

          return (<Grid container className="results" style={{ overflow: "hidden" }}>
            <Grid container item spacing={1} className="row-heads io" style={{ overflow: "hidden" }}>
              <Grid item xs={12} sm={8} md={7} >Product Name</Grid>
              <Grid item xs={12} sm={4} md={1} >SKU</Grid>
              <Grid item xs={3} sm={3} md={1} >Total</Grid>
              <Grid item xs={3} sm={3} md={1} style={{ textAlign: "center" }}>Quantity</Grid>
              <Grid item xs={3} sm={3} md={1} style={{ textAlign: "center" }}>Returns</Grid>
              {!phoneMode && <Grid item xs={12} sm={3} md={1} style={{ textAlign: "center" }}>Details</Grid>}
            </Grid>
            <List
              style={{ overflow: "visible" }}
              ref={virtualizedListRef}
              autoHeight
              width={width}
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              rowHeight={(props) => calcRowHeight(props.index, phoneMode)}
              rowRenderer={(itemProps) => renderRow(itemProps.index, itemProps.key, itemProps.style, phoneMode)}
              noRowsRenderer={renderPlaceholder}
              rowCount={visibleProducts.length}
              containerStyle={{ backgroundColor: "transparent", overflow: "visible", paddingTop: 10 }}
              overscanRowCount={3}
            />
          </Grid>)
        }}
      </WindowScroller>;
    }
  };

  return (
    <div className="admin view">
      <div className="inventory-results" style={{ overflow: "hidden" }}>
        <h1 className="reveal-text">Product Sales</h1>

        {internalUser && (
          <div style={{ overflow: "hidden" }}>
            {buildFilters()}
            <div className="search-results-container" style={{ overflow: "hidden" }}>{renderResults()}</div>
          </div>
        )}
        {!internalUser && (
          <h3>You don't have permission to view this.</h3>
        )}
      </div>
    </div>
  );
};

export default AllProductSales;
