import React from "react";
import { Button, CircularProgress, Dialog, Grid, Paper, Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Loading from "components/utils/Loading";
import { UserContext } from "context/User";
import firebase from "firebase/app";
import ModalBasic from "components/utils/ModalBasic";
import { UserInterface } from "interfaces/User";
import { makeAxiosCall } from "utils";

const Account = () => {
    const { user } = React.useContext(UserContext);
    const [userData, setUserData] = React.useState<UserInterface>();
    const [existingPassword, setExistingPassword] = React.useState<string>("");
    const [newPasswordOne, setNewPasswordOne] = React.useState<string>("");
    const [newPasswordTwo, setNewPasswordTwo] = React.useState<string>("");
    const [modal, setModal] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [errors, setErrors] = React.useState({
        existing: "",
        new: ""
    });
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });


    React.useEffect(() => {
        setUserData(user);
    }, [user]);

    const validate = (): boolean => {
        const errors = {
            existing: "",
            new: ""
        };

        if (existingPassword.length < 1) {
            errors.existing = "Required";
        }

        if (newPasswordOne.length < 6 || newPasswordTwo.length < 6) {
            errors.new = "Required";
        }

        if (newPasswordOne !== newPasswordTwo) {
            errors.new = "Passwords do not match";
        }

        setErrors(errors);

        return Object.values(errors).every(x => x === "");
    };

    const handlePasswordUpdate = () => {

        const currentUser = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, existingPassword);
        currentUser.reauthenticateWithCredential(credential).then(() => {
            console.log("REAUTH SUCCESSFUL");
            currentUser.updatePassword(newPasswordOne).then(() => {
                closeModal();
                setSnackbar({
                    isOpen: true,
                    message: "Password updated",
                    severity: "success"
                });
            }).catch((error) => {
                console.log(error);
                setSaving(false);
                setSnackbar({
                    isOpen: true,
                    message: "Something went wrong. Please try again.",
                    severity: "error"
                });
            });
        }).catch((error) => {
            console.log(error);
            if (error?.code.includes("wrong-password")) {
                setErrors({ ...errors, existing: "Incorrect password" });
                setSaving(false);
            } else {
                setSaving(false);
                setSnackbar({
                    isOpen: true,
                    message: "Something went wrong. Please try again.",
                    severity: "error"
                });
            }
        });
    };

    const closeModal = () => {
        setModal(false);
        setSaving(false);
        setErrors({
            existing: "",
            new: ""
        });
        setExistingPassword("");
        setNewPasswordOne("");
        setNewPasswordTwo("");
    };

    return (
        <div className="settings view">
            {userData && userData.uid ? (
                <div>
                    <h1>Your Account</h1>

                    <Paper
                        elevation={2}
                        style={{ margin: "15px 0", padding: 20 }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <h2>Account Info</h2>
                            </Grid>
                            <Grid item md={4} style={{ marginBottom: 10 }}>
                                <span style={{ fontWeight: "bold", fontSize: 16, marginRight: 5 }}>Email: </span> {user.email}
                            </Grid>
                            <Grid item container md={4} style={{ marginBottom: 10 }}>
                                <span style={{ fontWeight: "bold", fontSize: 16, marginRight: 5 }}>Account Created: </span> {user.createdAt ? user.createdAt.toLocaleDateString() : "Unknown"}
                            </Grid>
                            <Grid item container md={4} style={{ marginBottom: 10 }}>
                                <span style={{ fontWeight: "bold", fontSize: 16, marginRight: 5 }}>Company: </span> {user.company ? user.company.name : "None"}
                            </Grid>


                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    style={{ width: "100%", margin: "1.5em 0" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setModal(true);
                                    }}
                                    className="btn"
                                >
                                    Change Password
                                </Button>
                            </Grid>

                        </Grid>
                    </Paper>


                    <Paper
                        elevation={2}
                        style={{ margin: "15px 0", padding: 20 }}
                    >
                        <Grid item xs={12}>
                            <h2>Name</h2>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    label="First Name"
                                    margin="normal"
                                    value={userData.firstName}
                                    variant="outlined"
                                    onChange={(e) => setUserData({...userData, firstName: e.target.value})}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    label="Last Name"
                                    margin="normal"
                                    value={userData.lastName}
                                    variant="outlined"
                                    onChange={(e) => setUserData({...userData, lastName: e.target.value})}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    style={{ width: "100%", margin: "1.5em 0" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={async () => {

                                        if(
                                            !userData.firstName || 
                                            !userData.firstName.length || 
                                            !userData.lastName || 
                                            !userData.lastName.length
                                        ) {
                                            setSnackbar({
                                                isOpen: true,
                                                message: "First and last name are required.",
                                                severity: "error"
                                            }); 
                                            return;
                                        }

                                        const response = await makeAxiosCall("post", `my-account`, {
                                            firstName: userData.firstName,
                                            lastName: userData.lastName
                                        })

                                        if (response.status == 200) {
                                            setSnackbar({
                                                isOpen: true,
                                                message: "Account updated.",
                                                severity: "success"
                                            });
                                        } else {
                                            setSnackbar({
                                                isOpen: true,
                                                message: "Something went wrong. Please try again.",
                                                severity: "error"
                                            });
                                        }
                                        
                                    }}
                                    className="btn"
                                >
                                    Save Name
                                </Button>
                            </Grid>

                        </Grid>
                    </Paper>
                    
                </div>
            ) : <Loading height="40vh" title="" position="relative" />}

            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={3000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>

            <ModalBasic
                open={modal}
                close={closeModal}
                unmountOnExit
            >
                <Grid container style={{ padding: 30 }} spacing={3}>
                    <Grid item xs={12}>
                        <h2>Update your password:</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Current Password"
                            value={existingPassword}
                            onChange={(e) => { setExistingPassword(e.target.value); }}
                            variant="outlined"
                            type={"password"}
                            error={errors.existing.length > 0}
                            helperText={errors.existing}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="New Password"
                            value={newPasswordOne}
                            onChange={(e) => { setNewPasswordOne(e.target.value); }}
                            variant="outlined"
                            type={"password"}
                            error={errors.new.length > 0}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Confirm New Password"
                            value={newPasswordTwo}
                            onChange={(e) => { setNewPasswordTwo(e.target.value); }}
                            variant="outlined"
                            type={"password"}
                            error={errors.new.length > 0}
                            helperText={errors.new}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <p>Passwords must be at least 6 characters long</p>
                        <Button
                            type="submit"
                            style={{ margin: "1.5em 0" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (validate()) {
                                    setSaving(true);
                                    handlePasswordUpdate();
                                }
                            }}
                            className="btn"
                        >
                            {saving ?
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                /> :
                                "Update Password"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </ModalBasic>
        </div>
    );
};

export default Account;