import React, { useRef } from "react";
import { storage } from "base.js";
import Loading from "components/utils/Loading";
import { CompanyInterface } from "interfaces/Company";
import { Button, Checkbox, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, useTheme, Zoom } from "@material-ui/core";
import { UserContext } from "context/User";
import DocumentUpload from "components/Admin/DocumentUpload";
import { logFileDownload, makeAxiosCall } from "utils";
import { DocumentInterface } from "interfaces/Documents";
import { FileCopy, DeleteForever, GetApp, Edit, Save } from "@material-ui/icons";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";
import { CompanyContext } from "context/Company";
import ModalBasic from "components/utils/ModalBasic";
import { Autocomplete } from "@material-ui/lab";

import { useLocation } from "react-router-dom";

interface CompanyDocumentListProps {
    company: CompanyInterface,
    documents?: DocumentInterface[],
    title: string,
    refreshDocuments: () => void,
    generic?: boolean
}

interface CompanyDocumentCategoryList {
    name: string,
    list: JSX.Element[]
}

const CompanyDocumentList = ({ company, documents, title, refreshDocuments, generic = false }: CompanyDocumentListProps) => {

    const { internalUser, currentUser, accountLevels } = React.useContext(UserContext);
    const { companyTypes } = React.useContext(CompanyContext);
    const [selectedDocument, setSelectedDocument] = React.useState<DocumentInterface[]>([]);
    const [showEditDocumentModal, setShowEditDocumentModal] = React.useState<boolean>(false);
    const [documentBeingEdited, setDocumentBeingEdited] = React.useState<DocumentInterface>(null);
    const [documentsBeingDeleted, setDocumentsBeingDeleted] = React.useState<DocumentInterface[]>([]);
    const theme = useTheme();


    const scrollRef = useRef(null);
    const { hash } = useLocation();


    React.useEffect(() => {
        setTimeout(() => {
            if(hash && documents.length && scrollRef && scrollRef.current) {
                scrollRef.current.scrollIntoView();
                history.pushState("", document.title, window.location.pathname + window.location.search);
            }
        }, 100);
    }, [documents]);


    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const handleSelectMedia = (documentId: number) => {
        if (selectedDocument.some((i) => i.id == documentId)) {
            setSelectedDocument(selectedDocument.filter((i) => i.id != documentId));
        } else {
            setSelectedDocument([...selectedDocument, documents.find((i) => i.id == documentId)]);
        }
    };

    const handleSelectAllDownloads = () => {
        if (selectedDocument.length == documents.length) {
            setSelectedDocument([]);
        } else {
            setSelectedDocument([...documents]);
        }
    };

    const downloadDocument = (file: DocumentInterface) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function () {
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = file.name; // Name the file anything you'd like.
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
        };
        xhr.open("GET", file.url);
        xhr.send();
        
        logFileDownload(file.name, file.url, null, file.id);
    };

    const handleBulkDownload = async () => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = `${generic ? "blizzard-lighting" : company.name}-documents.zip`;

        selectedDocument.forEach((file, i) => {
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }
                logFileDownload(file.name, file.url, null, file.id);
                zip.file(file.firebaseName, data, { binary: true });
                count++;
                if (count == selectedDocument.length) {
                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        FileSaver.saveAs(content, zipFilename);
                        setSelectedDocument([]);
                    });
                }
            });
        });
    };

    const deleteDocument = (document: DocumentInterface) => {
        setDocumentsBeingDeleted([...documentsBeingDeleted, document]);

        const companyId = generic ? "all" : company?.id;

        storage
            .ref(`/portal_company_documents/${companyId}/other_documents/${document.firebaseName}`)
            .delete()
            .then(async () => {
                const response = await makeAxiosCall(
                    "delete",
                    `company-documents/${document.id}`
                ).catch((error) => {
                    console.error("Error deleting document:");
                    console.error(error);
                });

                if (response?.data?.id) {
                    refreshDocuments();
                } else {
                    setDocumentsBeingDeleted(documentsBeingDeleted.filter((d) => d.id != document.id));
                }
            })
            .catch((error) => {
                console.error("Error deleting document:");
                console.error(error);
                setDocumentsBeingDeleted(documentsBeingDeleted.filter((d) => d.id != document.id));
            });
    };

    const editDocument = (document: DocumentInterface) => {
        setShowEditDocumentModal(true);
        setDocumentBeingEdited(document);
    };

    const renderEditDocumentModal = () => {
        if(documentBeingEdited == null) return <></>;
        return (<ModalBasic
            open={showEditDocumentModal}
            close={() => setShowEditDocumentModal(false)}
            unmountOnExit
        >
            <div style={{ padding: 25, margin: 10, maxWidth: 1000 }}>
                <Grid container className="address-line" style={{ textAlign: "center" }} alignItems="center" spacing={2}>
                    <h3>Edit Document</h3>
                    <Grid item xs={12}>
                        <TextField
                            style={{ width: "100%" }}
                            label="Document Name"
                            value={documentBeingEdited.name}
                            variant="outlined"
                            onChange={(e) => {
                                setDocumentBeingEdited({...documentBeingEdited, name: e.target.value});
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <Autocomplete
                            value={documentBeingEdited.category}
                            fullWidth={true}
                            options={["Sales Reports", "Commission Reports", "Earnings", "Tools", "Other"]}
                            freeSolo={true}
                            onInputChange={(event, newValue) => {
                                setDocumentBeingEdited({...documentBeingEdited, category: newValue});
                            }}
                            getOptionLabel={(cat) => cat}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Document Category"}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={async () =>  {
                                // save
                                const response = await makeAxiosCall(
                                    "post",
                                    "company-documents/" + documentBeingEdited.id,
                                    {
                                        name: documentBeingEdited.name,
                                        category: documentBeingEdited.category
                                    }
                                );
        
                                if (response.status == 200) {
                                    setShowEditDocumentModal(false);
                                    refreshDocuments();
                                } else {
                                    console.log(response);
                                }
                            }}
                            className="btn blue"
                        >
                            Save <Save />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                deleteDocument(documentBeingEdited);
                                setShowEditDocumentModal(false);
                            }}
                            className="btn red"
                        >
                            Delete Document <DeleteForever />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </ModalBasic>);
    }

    const renderListItem = (document: DocumentInterface) => {
        return (
            <div key={document.id + "-list-item"}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <FileCopy onClick={() => handleSelectMedia(document.id)} />
                    </ListItemAvatar>

                    <ListItemText
                        primary={document.name}
                        secondary={
                            <React.Fragment>
                                Uploaded {document.createdAt.toLocaleDateString()}
                            </React.Fragment>
                        }
                        onClick={() => window.open(document.url, "_blank")}
                        style={{ cursor: "pointer" }}
                    />

                    <div>
                        {internalUser && currentUser?.accountLevel?.canManageMembers ?
                            <>
                                <span
                                    style={{
                                        marginRight: 30
                                    }}
                                >
                                    Visible to {accountLevels.find((l) => l.id == document.accountLevelId).name + " "}
                                    users {generic ? "of " + companyTypes.find((t) => t.id == document.companyTypeId).name + " companies" : ""}
                                </span>

                                <IconButton
                                    style={{
                                        position: "relative",
                                        right: 20,
                                    }}
                                    onClick={() => editDocument(document)}
                                >
                                    {documentsBeingDeleted.some(d => d.id == document.id) ? (
                                        <CircularProgress
                                            size={24}
                                            color="inherit"
                                        />
                                    ) : (
                                        <Edit
                                            style={{ color: "#828282" }}
                                        />
                                    )}
                                </IconButton>
                            </>
                            : null}


                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={() => downloadDocument(document)}
                        >
                            <GetApp color="primary" />
                        </IconButton>
                    </div>

                    <Checkbox
                        checked={selectedDocument.some((i) => i.id == document.id)}
                        onChange={() => handleSelectMedia(document.id)}
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </div>
        );
    };

    const renderDocumentList = () => {
        const documentList: CompanyDocumentCategoryList[] = [];

        for (let di = 0; di < documents.length; di++) {
            const document = documents[di];
            let existingCategory = documentList.find((c) => c.name == document.category);
            if(existingCategory) {
                existingCategory.list.push(renderListItem(document));
            } else {
                documentList.push({
                    name: document.category,
                    list: [renderListItem(document)]
                });
            }
        }

        documentList.sort((a, b) => a.name.localeCompare(b.name));

        return (
            <List>
                {documentList.map((cl) => {
                    return (
                        <div 
                            ref={(hash && hash.toLocaleLowerCase() === "#"+ cl.name.toLocaleLowerCase()) ? scrollRef : null} 
                            key={cl.name + "category-list"}
                            style={{scrollMarginTop: 60}}
                        >
                            <h5>{cl.name}</h5>
                            <List>
                                {cl.list}
                            </List>
                        </div>
                    );
                })}
                <ListItem key="bulkzone" alignItems="flex-start" style={{ height: 50 }}>
                <ListItemText primary={" "} />

                <Zoom
                    in={selectedDocument.length > 0}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${selectedDocument.length > 0 ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >

                    <Button
                        type="submit"
                        variant="contained"
                        color={"primary"}
                        className="btn"
                        fullWidth={true}
                        onClick={() => {
                            handleBulkDownload();
                        }}
                        endIcon={
                            <GetApp />
                        }
                        style={{ marginLeft: 15 }}
                    >
                        {"Zip and Download " + selectedDocument.length + " file" + (selectedDocument.length > 1 ? "s" : "")}
                    </Button>
                </Zoom>

                <Checkbox
                    checked={selectedDocument.length == documents.length}
                    onChange={handleSelectAllDownloads}
                />
            </ListItem>
            </List>
        );
    };

    const renderBody = () => {
        if (documents) {
            if (documents.length > 0) {
                return (
                    <Grid item xs={12}>
                        {renderDocumentList()}
                    </Grid>
                );
            } else {
                return <p className="body-message">No Documents Found</p>;
            }
        } else {
            return <Loading height="40vh" title="" position="relative" />;
        }
    };

    return (
        <>
            {renderEditDocumentModal()}
            <Grid container alignItems="center" >
                <Grid item xs={12} sm={9}>
                    <h2>{title}</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {!generic && internalUser && currentUser?.accountLevel?.canManageMembers && (
                        <DocumentUpload
                            company={company}
                            creationCallback={refreshDocuments}
                        />
                    )}
                </Grid>
                {renderBody()}
            </Grid>
        </>
    );
};

export default CompanyDocumentList;