import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { UserContext } from "context/User";

const PermissionTable = () => {
  const { accountLevels } = React.useContext(UserContext);

  const levels = React.useMemo(() => {
    return accountLevels
        .filter((level) => level.name !== "Super Admin")
        .sort((a, b) => a.id - b.id);
  }, [accountLevels]);

  const allowed = () => {
    return (
      <i className="far fa-check green" style={{ fontSize: "2em", color: "green" }} />
    );
  }

  const denied = () => {
    return (
      <i className="far fa-close tomato" style={{ fontSize: "2em", color: "tomato" }} />
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {levels.map((level) => <TableCell align="center" style={{ fontWeight: "bold" }}>{level.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>View Products</TableCell>
          {levels.map((level) => <TableCell align="center">{level.viewProducts ? allowed() : denied()}</TableCell>)}
        </TableRow>
        <TableRow>
          <TableCell>View Inventory</TableCell>
          {levels.map((level) => <TableCell align="center">{level.viewInventory ? allowed() : denied()}</TableCell>)}
        </TableRow>
        <TableRow>
          <TableCell>View Orders</TableCell>
          {levels.map((level) => <TableCell align="center">{level.viewOrders ? allowed() : denied()}</TableCell>)}
        </TableRow>
        <TableRow>
          <TableCell>Edit Addresses / Contacts</TableCell>
          {levels.map((level) => <TableCell align="center">{level.canEditInfo ? allowed() : denied()}</TableCell>)}
        </TableRow>
        <TableRow>
          <TableCell>Place Orders</TableCell>
          {levels.map((level) => <TableCell align="center">{level.canOrder ? allowed() : denied()}</TableCell>)}
        </TableRow>
        <TableRow>
          <TableCell>Edit Payment Methods</TableCell>
          {levels.map((level) => <TableCell align="center">{level.canEditPaymentMethods ? allowed() : denied()}</TableCell>)}
        </TableRow>
        <TableRow>
          <TableCell>Manage Members</TableCell>
          {levels.map((level) => <TableCell align="center">{level.canManageMembers ? allowed() : denied()}</TableCell>)}
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default PermissionTable;