import React, { useEffect, createContext } from "react";
import { UserContext } from "./User";
import { makeAxiosCall } from "utils";
import { BaseProductInterface, buildBaseProductList, buildProductsList, ProductCustomFieldInterface, ProductInterface } from "interfaces/Product";

interface ProductContextInterface {
    products: Array<ProductInterface>,
    baseProducts: Array<BaseProductInterface>,
    loadProducts: () => Promise<ProductInterface[]>,
    setSingleProductDetails: (product: ProductInterface) => void,
    customFields: ProductCustomFieldInterface[],
    findCustomFieldTemplate: (fieldName: string) => ProductCustomFieldInterface,
}

export const ProductContext = createContext<ProductContextInterface>(null);

interface ProductProviderProps {
    children: any,
}

export const ProductProvider = ({ children }: ProductProviderProps) => {
    const [products, setProducts] = React.useState<ProductInterface[]>([]);
    const [baseProducts, setBaseProducts] = React.useState<BaseProductInterface[]>([]);
    const [customFields, setCustomFields] = React.useState<ProductCustomFieldInterface[]>([]);
    const { currentUser, internalUser } = React.useContext(UserContext);

    const loadProducts = async () => {
        if (!currentUser || !currentUser.accountLevel.viewInventory || !currentUser.company || !currentUser.company.approved) return;

        const hasuraProductData = await makeAxiosCall(
            "get",
            "products",
        )
            .catch((error) => {
                console.log(error);
                return [];
            });

        const hasuraProducts = buildProductsList(hasuraProductData.data);

        setProducts(hasuraProducts);
        localStorage.setItem("products", JSON.stringify(hasuraProducts));
        return hasuraProducts;
    };

    const loadBaseProducts = async () => {
        if (!currentUser || !currentUser.accountLevel.viewInventory || !currentUser.company || !currentUser.company.approved) return;

        const response = await makeAxiosCall(
            "get",
            "base-products",
        ).catch((error) => {
            console.error(error);
        });

        if (response?.status == 200) {
            setBaseProducts(buildBaseProductList(response.data));
        }
    };

    const loadCustomFields = async () => {
        if (!internalUser) return;

        const response = await makeAxiosCall(
            "get",
            "customFields"
        ).catch((error) => {
            console.log(error);
            return [];
        });

        const fieldData: ProductCustomFieldInterface[] = response.data.map((field) => {
            return {
                fieldId: field.id,
                name: field.name,
                fieldType: field.field_type,
                fishbowlId: field.fishbowl_id,
                shopifyKey: field.shopify_field_key,
                shopifyNamespace: field.shopify_field_namespace,
            };
        });

        setCustomFields(fieldData);
        // console.log(fieldData);
    };

    const findCustomFieldTemplate = (fieldName: string) => {
        return customFields.find((field) => field.name == fieldName);
    };

    const setSingleProductDetails = (product: ProductInterface) => {
        const existingProductIndex = products.findIndex((p) => p.id == product.id);

        if (existingProductIndex > -1) {
            const newList = [...products];
            newList[existingProductIndex] = product;
            setProducts(newList);
        }
    };

    useEffect(() => {
        if (currentUser) {
            const productsRaw = localStorage.getItem("products");
            if (productsRaw) {
                setProducts(JSON.parse(productsRaw));
            }
            loadProducts();
            loadBaseProducts();
            loadCustomFields();
        }
    }, [currentUser]);

    return (
        <ProductContext.Provider
            value={{
                loadProducts,
                products,
                baseProducts,
                customFields,
                findCustomFieldTemplate,
                setSingleProductDetails
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};
