import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Paper } from "@material-ui/core";

interface DraftEditorProps {
    startingHTML?: string,
    style?: React.CSSProperties,
    onChange: Function,
    fieldName: string,
    alwaysShowToolbar?: boolean
}

const DraftEditor = ({ startingHTML, style, onChange, fieldName, alwaysShowToolbar }: DraftEditorProps) => {
    const [focused, setFocused] = React.useState<boolean>(alwaysShowToolbar ?? false);
    const [showEditorCode, setShowEditorCode] = React.useState<boolean>(false);

    const initialEditorState: EditorState = startingHTML ? 
            EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(startingHTML).contentBlocks)) :
            EditorState.createEmpty();

    const [editorState, setEditorState] = React.useState<EditorState>(initialEditorState);

    const editorHTML: string = React.useMemo(() => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }, [editorState]);


  const onEditEditorHTML = e => {
    const editorHTML = e.target.value;
    let editor;
    const contentBlock = htmlToDraft(editorHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editor = EditorState.createWithContent(contentState);
    } else {
      editor = EditorState.createEmpty();
    }
    onChange && onChange({
      html: editorHTML
    });
    setEditorState(editor);
    onChange(editorHTML);
  }

  const toggleEditorCode = () => {
    setShowEditorCode(!showEditorCode)
  }


    const ShowEditorCode = () => (
      <div className="rdw-option-wrapper"
        onClick={toggleEditorCode}>
        {showEditorCode ? 'Hide' : 'Show'} Code
      </div>)

    return (
        <div style={style}>
            <Editor
                editorState={editorState}
                onEditorStateChange={(es) => {
                    setEditorState(es);
                    onChange(editorHTML);
                }}
                toolbarOnFocus={!alwaysShowToolbar}
                onFocus={() => alwaysShowToolbar ? null : setFocused(true)}
                onBlur={() => alwaysShowToolbar ? null : setFocused(false)}
                placeholder={fieldName}
                toolbar={{
                  options: ['inline', 'blockType', 'fontFamily', 'list', 'textAlign', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                    bold: { className: 'bordered-option-classname' },
                    italic: { className: 'bordered-option-classname' },
                    underline: { className: 'bordered-option-classname' },
                    strikethrough: { className: 'bordered-option-classname' },
                    code: { className: 'bordered-option-classname' },
                  },
                  blockType: {
                    className: 'bordered-option-classname',
                  },
                  fontSize: {
                    className: 'bordered-option-classname',
                  },
                  fontFamily: {
                    className: 'bordered-option-classname',
                  },
                }}
                wrapperStyle={{ borderRadius: 4, border: "1px solid rgba(0, 0, 0, 0.2)" }}
                editorStyle={{ padding: "0 10px", minHeight: 200 }}
                toolbarStyle={focused ? {borderLeft: 0, borderRight: 0, borderTop: 0 } : {borderLeft: 0, borderRight: 0, borderTop: 0, height: 0, margin: 0, padding: 0 }}
                // toolbarCustomButtons={[<ShowEditorCode />]}
            />
            { showEditorCode && <Paper style={{marginTop: 32, backgroundColor: "#272727", borderRadius: 12}}><textarea style={{
              width:'100%', 
              color: "white", 
              backgroundColor: "#272727", 
              border: "none", 
              padding: 16,
              fontFamily: "monospace"
            }}
              value={editorHTML}
              onChange={onEditEditorHTML}
            /></Paper> }
        </div>
    );
}
 
export default DraftEditor;