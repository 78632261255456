import React from "react";
import _ from "lodash";
import { PDCenterItemInterface } from "interfaces/PDItem";

interface PDPhasePropsInterface {
  title: string,
  products: PDCenterItemInterface[],
  index: number,
}

const PDPhase = ({ title, products, index }: PDPhasePropsInterface) => {

  const getBgColor = (type) => {
    if (type === "panel") {
      const colors = ["#151c23", "#0f151b", "#090c0f", "#06080b"];
      return colors[index];
    } else if (type === "accent") {
      const colors = ["#f0756d", "#4aaf8b", "#ffbb6c", "#00b3f7"];
      return colors[index];
    }
  };

  const renderProductCard = () => {
    if (products.length > 0) {
      return _.map(products, (prod, i) => {
        return (
          <div
            className="pd-product"
            style={{ borderColor: getBgColor("accent") }}
            key={i}
          >
            <div>
              {prod.image ? <img src={prod.image} /> : null}
              <h3>{prod.name}</h3>
            </div>
            <div>
              {prod &&
                (prod.subPhase ||
                  prod.daysToProduction ||
                  prod.daysToLaunch ||
                  prod.notes) && (
                  <ul>
                    {prod.subPhase && (
                      <li>
                        <p>Step </p> {prod.subPhase}
                      </li>
                    )}
                    {/* {prod.daysToProduction && (
                      <li>
                        <p>Est. Days to Production</p> {prod.daysToProduction}
                      </li>
                    )}
                    {prod.daysToLaunch && (
                      <li>
                        <p>Est. Days to Launch</p> {prod.daysToLaunch}
                      </li>
                    )} */}
                    {prod.notes && (
                      <li>
                        <p>Notes</p> {prod.notes}
                      </li>
                    )}
                  </ul>
                )}
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div
      className={`pd-stage`}
      style={{ backgroundColor: getBgColor("panel") }}
    >
      <div className="pd-phase-header">
        <h2>{title}</h2>
        <div
          className="number-bubble"
          style={{ backgroundColor: getBgColor("accent") }}
        >
          {products.length ? products.length : 0}
        </div>
      </div>

      <div className="pd-products">{renderProductCard()}</div>
    </div>
  );
};

export default PDPhase;
