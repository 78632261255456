import React from "react";
import { UserContext } from "context/User";
import Grid from "@material-ui/core/Grid";
import { UserAccountPermissionsInterface } from "interfaces/User";

interface PermissionDisplayProps {
  levelName: string,
}

const PermissionDisplay = ({levelName}: PermissionDisplayProps) => {

  const { accountLevels } = React.useContext(UserContext);
  const [selectedLevel, setSelectedLevel] = React.useState<UserAccountPermissionsInterface>(
    {
      viewInventory: true,
      viewOrders: true,
      viewProducts: true,
      canOrder: true,
      canManageMembers: false,
      canEditInfo: true,
      canEditPaymentMethods: false,
      name: "Member",
      id: 3,
    }
  );

  React.useEffect(() => {
    setSelectedLevel(accountLevels.find(level => level.name === levelName));
  }, [levelName]);

  const renderIndividualPermission = (allowed, title) => {
    const classInfo = allowed ? "far fa-check green" : "far fa-close tomato";
    return (
      <Grid item>
        <Grid item container direction="column">
          <Grid item>
            <i className={classInfo} style={{ fontSize: "2em", color: allowed ? "green" : "tomato" }}></i>
          </Grid>
          <Grid item>
            <p>{title}</p>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container spacing={2} justifyContent="space-around" alignItems="center">
      {renderIndividualPermission(selectedLevel.viewProducts, "View Products")}
      {renderIndividualPermission(selectedLevel.viewInventory, "View Inventory")}
      {renderIndividualPermission(selectedLevel.viewOrders, "View Orders")}
      {renderIndividualPermission(selectedLevel.canEditInfo, "Edit Addresses / Contacts")}
      {renderIndividualPermission(selectedLevel.canOrder, "Place Orders")}
      {renderIndividualPermission(selectedLevel.canEditPaymentMethods, "Edit Payments")}
      {renderIndividualPermission(selectedLevel.canManageMembers, "Manage Members")}
    </Grid>
  )
}

export default PermissionDisplay;