import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, FormControlLabel, Checkbox, Button } from "@material-ui/core";
import { buildResellerApplicationInterface, ResellerApplicationInterface } from "interfaces/ResellerApplication";
import { makeAxiosCall } from "utils";
import Loading from "components/utils/Loading";

interface ViewApplicationProps {
    userUid: string,
    open: boolean,
    showDeny: boolean
    close: () => void,
}

const ViewApplication = ({ userUid, open, showDeny, close }: ViewApplicationProps) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [application, setApplication] = React.useState<ResellerApplicationInterface>();

    React.useEffect(() => {
        if (userUid) {
            fetchResellerApplication();
        }
    }, [userUid]);

    const fetchResellerApplication = async () => {
        const response = await makeAxiosCall(
            "get",
            `application/${userUid}`
        ).catch((error) => {
            console.error(error);
        });

        if (response) {
            setApplication(buildResellerApplicationInterface(response.data));
        }

        setLoading(false);
    };

    const denyApplication = () => {

        // Send an email?
        // Set status to 'declined'

        makeAxiosCall(
            "post",
            `deny-application/${application.id}`,
            { userId: userUid }
        ).then(() => close()).catch((error) => {
            console.error(error);
        });
    };

    const renderApplicationData = () => {
        if (application) {
            return (
                <Grid container>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Your Name"}
                                margin="normal"
                                variant="outlined"
                                value={application.name}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Phone Number"}
                                margin="normal"
                                variant="outlined"
                                value={application.phone}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Email"}
                                margin="normal"
                                variant="outlined"
                                value={application.email}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={8}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Legal Entity Name"}
                                margin="normal"
                                variant="outlined"
                                value={application.legalEntityName}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Website URL"}
                                margin="normal"
                                variant="outlined"
                                value={application.website}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"DBA"}
                                margin="normal"
                                variant="outlined"
                                value={application.dba}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                style={{ width: "100%", marginTop: 30 }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={application.hasEIN}
                                    />
                                }
                                label={"Do you have a Federal Employer Identification Number?"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"# of Years in Business"}
                                margin="normal"
                                variant="outlined"
                                value={application.yearsInBusiness}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Business Type"}
                                margin="normal"
                                variant="outlined"
                                value={application.businessType}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Primary Business Description"}
                                margin="normal"
                                variant="outlined"
                                value={application.businessDescription}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                style={{ width: "100%", marginTop: 30 }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={application.hasRetail}
                                    />
                                }
                                label={"Do you have a retail store?"}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                style={{ width: "100%", marginTop: 30 }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={application.hasShowroom}
                                    />
                                }
                                label={"A Showroom?"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Professional Accreditations/Licensures"}
                                margin="normal"
                                variant="outlined"
                                value={application.accreditations}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label={"Which Sound/Lighting Manufacturers/Distributors do you currently buy from?"}
                                margin="normal"
                                variant="outlined"
                                value={application.otherManufacturers}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ height: 30 }}></Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <h3>Primary Address</h3>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Address"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.primaryAddressAddress}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"City"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.primaryAddressCity}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"State"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.primaryAddressState}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"ZIP Code"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.primaryAddressZip}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ height: 30 }}></Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <h3>Shipping Address</h3>

                            {/* <FormControlLabel
                                style={{ width: "100%" }}
                                control={
                                    <Checkbox
                                    color="primary"
                                    value={application.}
                                    
                                    />
                                }
                                label={"Same as Primary"}
                            /> */}
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Address"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.shippingAddressAddress}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"City"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.shippingAddressCity}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"State"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.shippingAddressState}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"ZIP Code"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.shippingAddressZip}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ height: 30 }}></Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <h3>Trade References: <span style={{ fontWeight: "lighter" }}>(Current Vendors in Pro Light/Sound Industry)</span></h3>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>

                            <Grid item xs={1} style={{ marginTop: 16, marginBottom: 8 }}>
                                <h3 style={{ textAlign: "right" }}>1.</h3>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Name"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference1Name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Email"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference1Email}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Phone Number"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference1Phone}
                                />
                            </Grid>
                        </Grid>


                        <Grid container item xs={12} spacing={2}>

                            <Grid item xs={1} style={{ marginTop: 16, marginBottom: 8 }}>
                                <h3 style={{ textAlign: "right" }}>2.</h3>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Name"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference2Name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Email"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference2Email}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Phone Number"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference2Phone}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={1} style={{ marginTop: 16, marginBottom: 8 }}>
                                <h3 style={{ textAlign: "right" }}>3.</h3>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Name"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference3Name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Email"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference3Email}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Phone Number"}
                                    margin="normal"
                                    variant="outlined"
                                    value={application.tradeReference3Phone}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <h2 style={{ margin: "10px auto" }}>No Application Found</h2>
            );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={close}
            maxWidth={"lg"}
        >
            <DialogTitle style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 30px 0px" }}>
                Reseller Application Details
            </DialogTitle>
            <DialogContent style={{ backgroundColor: "whitesmoke" }}>
                {loading ? (
                    <Loading height={"40vh"} position={"absolute"} title={"Loading Application"} />
                ) : (
                    renderApplicationData()
                )}
            </DialogContent>
            <DialogActions style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 30px 0px" }}>
                <div style={{ width: "54%", margin: 20, display: "block", textAlign: "center" }}>
                    <h4>To approve an application, associate the user with their company.</h4>
                </div>

                <Button
                    onClick={() => {
                        close();
                    }}
                    variant="outlined"
                    style={{ width: "20%", margin: 20, display: "block" }}
                    color="primary"
                    className="btn"
                >
                    Close
                </Button>
                {showDeny && <Button
                    onClick={() => {
                        denyApplication();
                    }}
                    variant="contained"
                    style={{ width: "20%", margin: 20, display: "block", backgroundColor: "red" }}
                    color="primary"
                    className="btn"
                >
                    Deny
                </Button>}
                
            </DialogActions>
        </Dialog>
    );
};

export default ViewApplication;