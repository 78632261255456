import React, { useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import UserSearch from "components/Admin/UserSearch";
import CompanySearch from "components/Admin/CompanySearch";
import InviteSearch from "components/Admin/InviteSearch";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import WarrantyReview from "components/Admin/WarrantyReview";
import { makeAxiosCall } from "utils";
import { buildNewResellerInviteList, NewResellerInviteInterface } from "interfaces/Invite";
import { UserInterface } from "interfaces/User";
import ApprovalReview from "./Approval/ApprovalReview";
import { BuildOrdersList, OrderInterface } from "interfaces/Order";
import { buildCompanyInfo, CompanyInterface } from "interfaces/Company";
import { UserListContext } from "context/UserList";
import PortalOrderSearch from "./PortalOrderSearch";
import { useWindowWidth } from "hooks/useWindowSize";
import GenericDocuments from "./GenericDocuments";
import WebOrderSearch from "./Web/Order/WebOrderSearch";
import PromotionSearch from "./Promotions/PromotionSearch";
import BaseProductSearch from "./BaseProducts/BaseProductSearch";

const Admin = () => {
    // Allow routing to specific tab
    const navigation = useLocation();
    let startingPage = "Users";
    if (navigation && navigation.state && navigation.state.startingPage) {
        startingPage = navigation.state.startingPage;
    }

    const [allUsers, setAllUsers] = React.useState<UserInterface[]>([]);
    const [allCompanies, setAllCompanies] = React.useState<CompanyInterface[]>();
    const [allResellerInvites, setAllResellerInvites] = React.useState<NewResellerInviteInterface[]>();
    const [activeMenu, setActiveMenu] = React.useState(startingPage);
    const [allOrders, setAllOrders] = React.useState<OrderInterface[]>();
    const [ordersLoaded, setOrdersLoaded] = React.useState<boolean>(false);
    const { users, loadUsers } = React.useContext(UserListContext);
    const windowWidth = useWindowWidth();

    let isMounted = true;

    useEffect(() => {
        fetchAllCompanies();
        fetchAllResellerInvites();
        fetchAllUsers();

        const lastTab = localStorage.getItem("last-admin-tab");
        if (lastTab) {
            setActiveMenu(lastTab);
        }

        return () => {
            isMounted = false;
        };
    }, []);


    useEffect(() => {
        if (!ordersLoaded && allCompanies && allCompanies.length > 0) {
            fetchAllOrders();
        }
    }, [allCompanies]);


    useEffect(() => {
        if (users.length > 0) {
            setAllUsers(users);
        }
    }, [users]);

    const saveActiveMenu = (tabName: string) => {
        localStorage.setItem("last-admin-tab", tabName);
        setActiveMenu(tabName);
    };

    const fetchAllOrders = async () => {
        const response = await makeAxiosCall(
            "get",
            "orders",
        );

        if (response.data) {
            const orderList = BuildOrdersList(response.data, allCompanies);
            setOrdersLoaded(true);
            if (isMounted) setAllOrders(orderList);
        }
    };

    const fetchAllUsers = () => {

        const usersRaw = localStorage.getItem("users");
        if (usersRaw) {
            setAllUsers(JSON.parse(usersRaw));
        }

        loadUsers();
    };

    const fetchAllCompanies = () => {
        // company list is too big :(
        // const previousCompanies = localStorage.getItem("companies");

        // if(previousCompanies) {
        //     if (isMounted){
        //         setAllCompanies(JSON.parse(previousCompanies));
        //     } 
        // }

        makeAxiosCall(
            "get",
            "companies"
        ).then(res => {
            const companyList = _.map(
                res.data,
                (company) => {
                    return buildCompanyInfo(company);
                }
            );

            if (isMounted) {
                // localStorage.setItem("companies", JSON.stringify(companyList))
                setAllCompanies(companyList);
            } 
        });
    };

    const fetchAllResellerInvites = () => {
        makeAxiosCall(
            "get",
            "reseller-invites"
        ).then(async res => {
            const invitesList = buildNewResellerInviteList(res.data);
            if (isMounted) {
                setAllResellerInvites(invitesList);
            }
        });
    };

    const renderView = () => {
        if (activeMenu === "Users") {
            return <UserSearch allUsers={allUsers} />;
        } else if (activeMenu === "Companies") {
            return <CompanySearch allCompanies={allCompanies} />;
        } else if (activeMenu === "Invite") {
            return <InviteSearch allInvites={allResellerInvites} refresh={fetchAllResellerInvites} />;
        } else if (activeMenu === "Promos") {
            return <PromotionSearch />;
        } else if (activeMenu === "Warranty") {
            return <WarrantyReview />;
        } else if (activeMenu === "Approval") {
            return <ApprovalReview allCompanies={allCompanies} />;
        } else if (activeMenu === "Orders") {
            return <PortalOrderSearch allOrders={allOrders} />;
        } else if (activeMenu === "Web Orders") {
            return <WebOrderSearch />;
        } else if (activeMenu === "Documents") {
            return <GenericDocuments />;
        } else if (activeMenu === "Base Products") {
            return <BaseProductSearch />;
        }
    };

    return (
        <div className="admin view">
            <Grid container style={{ marginBottom: 10 }}>
                <Grid item>
                    <h1 className="reveal-text">Admin</h1>
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        style={{ marginLeft: 20, marginTop: 15 }}
                        variant="contained"
                        color="primary"
                        className="btn"
                        component={Link}
                        to="/status"
                    >
                        View Portal Status
                    </Button>
                </Grid>
            </Grid>
            <div className="settings-bar">
                <div
                    onClick={() => saveActiveMenu("Users")}
                    className={activeMenu === "Users" ? "item active" : "item"}
                >
                    Users
                </div>
                <div
                    onClick={() => saveActiveMenu("Companies")}
                    className={activeMenu === "Companies" ? "item active" : "item"}
                >
                    Companies
                </div>
                <div
                    onClick={() => saveActiveMenu("Orders")}
                    className={activeMenu === "Orders" ? "item active" : "item"}
                >
                    Portal Orders
                </div>
                <div
                    onClick={() => saveActiveMenu("Web Orders")}
                    className={activeMenu === "Web Orders" ? "item active" : "item"}
                >
                    Web Orders
                </div>
                <div
                    onClick={() => saveActiveMenu("Invite")}
                    className={activeMenu === "Invite" ? "item active" : "item"}
                >
                    Reseller Invites
                </div>
                {windowWidth > 700 && <div
                    onClick={() => saveActiveMenu("Promos")}
                    className={activeMenu === "Promos" ? "item active" : "item"}
                >
                    Promos
                </div>}
                {windowWidth > 700 && <div
                    onClick={() => saveActiveMenu("Warranty")}
                    className={activeMenu === "Warranty" ? "item active" : "item"}
                >
                    Warranty Review
                </div>}
                <div
                    onClick={() => saveActiveMenu("Documents")}
                    className={activeMenu === "Documents" ? "item active" : "item"}
                >
                    Documents
                </div>
                {windowWidth > 700 && <div
                    onClick={() => saveActiveMenu("Base Products")}
                    className={activeMenu === "Base Products" ? "item active" : "item"}
                >
                    Base Products
                </div>}
                {/* <div
          onClick={() => saveActiveMenu("Approval")}
          className={activeMenu === "Approval" ? "item active" : "item"}
        >
          Approval Review
        </div> */}
            </div>
            {renderView()}
        </div>
    );
};

export default Admin;
