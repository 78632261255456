import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { CSSTransition } from "react-transition-group";

import { TextField, CircularProgress } from "@material-ui/core";
import ChipArray from "components/utils/ChipArray";

const Metadata = (props) => {
  const { chipData, setChipData, product, setProductTag, uploadType } = props;

  const renderMetaFields = () => {
    if (uploadType === "profiles") {
      return (
        <React.Fragment>
          <TextField
            style={{ width: "100%" }}
            id="outlined-basic"
            label="Folder Name"
            margin="normal"
            onChange={(e) => setProductTag(e.target.value)}
            variant="outlined"
          />
        </React.Fragment>
      );
    } else if (product.length > 0) {
      return (
        <React.Fragment>
          <CSSTransition
            in={chipData.length > 0}
            unmountOnExit
            timeout={200}
            classNames="alert"
          >
            <div>
              <ChipArray chipData={chipData} setChipData={setChipData} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={product.length > 0}
            unmountOnExit
            timeout={200}
            classNames="alert"
          >
            <>
              <Autocomplete
                id="combo-box-demo"
                options={product}
                onChange={(e, v) =>
                  setChipData([...chipData, { label: v.num, id: v.id }])
                }
                getOptionLabel={(product) => product.num}
                style={{ flex: 2 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </>
          </CSSTransition>
        </React.Fragment>
      );
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <div className="metadata-container">
      <h2>Metadata</h2>
      <div className="container">{renderMetaFields()}</div>
    </div>
  );
};

export default Metadata;
