import React, { Component } from "react";
// import { Link } from "react-router-dom";
import _ from "lodash";
import {
  FormControlLabel,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Select,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "components/Quote Builder/assets/Styles";
import { convertFishbowlJSON } from "utils";

import WidthInput from "components/Quote Builder/components/WidthInput";
import HeightInput from "components/Quote Builder/components/HeightInput";
import RatioInput from "components/Quote Builder/components/RatioInput";
import DiagonalInput from "components/Quote Builder/components/DiagonalInput";
import CustomerDetails from "components/Quote Builder/components/CustomerDetails";
import { CircularProgress } from "@material-ui/core";

class Calculator extends Component {
  constructor() {
    super();
    this.state = {
      userPixels: 0,
    };
  }

  renderProductSelect() {
    const onlyIRIS = _.filter(this.props.products, (p) => {
      const outString = convertFishbowlJSON(p.num);
      return outString.includes("iris");
    });
    let activeIrisPanels = [];

    _.map(onlyIRIS, (p) => {
      const customFields = p.customFields;
      const panel = _.filter(customFields, {
        name: "IRiS Panels",
        value: "true",
      });
      if (panel.length > 0) {
        activeIrisPanels.push(p);
      }
    });

    return _.map(activeIrisPanels, (item, i) => {
      return (
        <MenuItem key={i} value={item.num}>
          {item.num}
        </MenuItem>
      );
    });
  }

  renderSaveButton() {
    return (
      <>
        <Button
          style={{
            marginTop: 20,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
          variant="contained"
          color="primary"
          className="btn"
          onClick={this.props.saveForm}
        >
          Save Draft
          {this.props.isWritingToDb ? (
            <CircularProgress
              style={{ marginLeft: 10 }}
              color="inherit"
              size={20}
            />
          ) : null}
        </Button>
        {/* {(this.props.user.company === "Blizzard" ||
          this.props.user.accountType === "Rep") && (
          <Button
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginTop: 10,
              background: "#d44929",
            }}
            variant="contained"
            color="primary"
            className="btn"
            onClick={() => this.props.saveForm("hubspot")}
          >
            Save to Hubspot Deal
            {this.props.isWritingToDb ? (
              <CircularProgress
                style={{ marginLeft: 10 }}
                color="inherit"
                size={20}
              />
            ) : null}
          </Button>
        )} */}
      </>
    );
  }

  renderDeleteButton() {
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get("project");

    if (uid) {
      return (
        <>
          <Button
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              background: "transparent",
              border: "1px solid #ff4d4d",
              color: "lightgray",
            }}
            variant="contained"
            color="inherit"
            className="btn"
            onClick={this.props.removeSave(uid)}
          >
            Delete
            {this.props.isWritingToDb ? (
              <CircularProgress
                style={{ marginLeft: 10 }}
                color="inherit"
                size={20}
              />
            ) : null}
          </Button>
        </>
      );
    }
  }

  renderSaveTime() {
    const saveTime = this.props.config.lastSaved;
    if (saveTime) {
      return <p style={{ opacity: 0.6 }}>Last saved {saveTime}</p>;
    }
  }

  renderOptions = (type) => {
    return _.map(this.props[type], (item, i) => {
      return (
        <option key={i} value={item.num}>
          {item.num}
        </option>
      );
    });
  };

  renderValue = (val) => {
    const addon = _.filter(this.props.addons, {
      id: val,
    });
    return addon[0].sku;
  };

  //This renders the price selection with disabled options depending on account type

  renderPrice = (input, type, i) => {
    return Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(parseFloat(input.price));
  };

  renderRemove = (i, length, type) => {
    const index = parseInt(i, 10);
    if (index !== 0) {
      return (
        <div
          style={{ padding: "0 8px", width: "0px" }}
          className="btn"
          onClick={this.props.removeAddon(type, i)}
        >
          -
        </div>
      );
    }
  };

  renderAdd = (i, length, type) => {
    const index = parseInt(i, 10);
    if (index === length) {
      return (
        <Button
          type="submit"
          style={{ width: "100%", marginTop: 5 }}
          variant="contained"
          color="primary"
          className="btn"
          onClick={() => this.props.appendInput(type, i)}
        >
          +
        </Button>
      );
    }
  };

  renderProductSelects = (type, title) => {
    const { config, processors, addons, handleAddonSelect } = this.props,
      length = Object.keys(config[type]).length - 1;
    return (
      <div className="form-section addon-selectors">
        <h3>Select {title}</h3>
        <div className="label-flex">
          <div style={{ width: "130px", marginRight: "10px" }}>Product</div>
          <div style={{ width: "35px", marginRight: "10px" }}>Qty.</div>
          <div style={{ width: "70px" }}>Price(ea)</div>
          <div />
        </div>
        {_.map(config[type], (input, i) => {
          return (
            <div key={input.id} className="add-container">
              <div style={{ display: "table", marginRight: "10px" }}>
                <select
                  style={{ width: "130px" }}
                  value={input.num}
                  onChange={handleAddonSelect(type, "value", i)}
                >
                  <option value="Select Addon">Select {title}</option>
                  {this.renderOptions(type)}
                </select>
              </div>
              <div style={{ width: "35px", marginRight: "10px" }}>
                <TextField
                  id="standard-number"
                  style={{ margin: "0px" }}
                  autoComplete="off"
                  type="text"
                  margin="normal"
                  value={input.qty}
                  onChange={this.props.changeInputValue("qty", i, type)}
                />
              </div>
              <div style={{ width: "70px" }}>{this.renderPrice(input)}</div>

              {this.renderRemove(i, length, type)}
              {this.renderAdd(i, length, type)}
            </div>
          );
        })}
      </div>
    );
  };

  renderDimensions() {
    const config = this.props.config;
    const { classes } = this.props;

    if (config.currentProd.sku) {
      return (
        <FormControl>
          <h3>Select Dimensions</h3>

          <Select
            autoWidth={false}
            className={classes.select}
            value={config.dimSelect}
            onChange={this.props.handleDimSelect("dimSelect")}
          >
            <MenuItem value="Width & Height">Width & Height</MenuItem>
            <MenuItem value="Width & Diagonal">Width & Diagonal</MenuItem>
            <MenuItem value="Height & Diagonal">Height & Diagonal</MenuItem>
            <MenuItem value="Diagonal & Ratio">Diagonal & Ratio</MenuItem>
            <MenuItem value="Ratio & Width">Ratio & Width</MenuItem>
            <MenuItem value="Ratio & Height">Ratio & Height</MenuItem>
          </Select>
        </FormControl>
      );
    }
  }
  renderFormat() {
    const config = this.props.config;

    if (config.currentProd.sku) {
      return (
        <div className="radio-input-group">
          <FormControl>
            <h3>Measurement Unit</h3>
            <RadioGroup
              aria-label="Gender"
              name="gender1"
              className="radio-group"
              value={config.format}
              onChange={this.props.handleRadio("format")}
            >
              <FormControlLabel value="Ft." control={<Radio />} label="Ft." />

              <FormControlLabel value="In." control={<Radio />} label="In." />

              <FormControlLabel value="cm" control={<Radio />} label="cm" />
              <FormControlLabel value="mm" control={<Radio />} label="mm" />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  }

  renderNumberInputs() {
    const config = this.props.config;
    if (config.currentProd.sku) {
      return (
        <div className="input-styles">
          <div className="input-halves">
            <TextField
              autoComplete="off"
              id="standard-number"
              type="number"
              label="Complete Panels"
              margin="normal"
              value={config.completePanels}
              onChange={this.props.handleInputChange("completePanels")}
            />
            <TextField
              autoComplete="off"
              id="standard-number"
              type="number"
              label="NovaStar® VX4S Processors"
              margin="normal"
              value={parseInt(config.novaStar, 10)}
              onChange={this.props.handleInputChange("novaStar")}
            />
          </div>

          <div className="input-halves">
            <TextField
              autoComplete="off"
              id="standard-number"
              type="number"
              label="Receiving Cards"
              margin="normal"
              value={config.recCards}
              onChange={this.props.handleInputChange("recCards")}
            />
            <TextField
              autoComplete="off"
              id="standard-number"
              type="number"
              label="Single Bumper / Flybars"
              margin="normal"
              value={config.singleFly}
              onChange={this.props.handleInputChange("singleFly")}
            />
          </div>
          <div className="input-halves">
            <TextField
              autoComplete="off"
              id="standard-number"
              type="number"
              label="Double Bumper / Flybars"
              margin="normal"
              value={config.doubleFly}
              onChange={this.props.handleInputChange("doubleFly")}
            />
            <TextField
              autoComplete="off"
              id="standard-number"
              type="number"
              label="Flight Case"
              margin="normal"
              value={config.ataSpec}
              onChange={this.props.handleInputChange("ataSpec")}
            />
          </div>
        </div>
      );
    }
  }

  renderInputs() {
    const config = this.props.config;
    if (config.dimSelect === "Width & Height") {
      return (
        <div>
          <WidthInput
            dimSelect={config.dimSelect}
            config={config}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          <HeightInput
            dimSelect={config.dimSelect}
            config={config}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          {config.dimError && (
            <div className="dim-error">{config.dimError}</div>
          )}
        </div>
      );
    } else if (config.dimSelect === "Width & Diagonal") {
      return (
        <div>
          <WidthInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          <DiagonalInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />

          {config.dimError && (
            <div className="dim-error">{config.dimError}</div>
          )}
        </div>
      );
    } else if (config.dimSelect === "Height & Diagonal") {
      return (
        <div>
          <HeightInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          <DiagonalInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          {config.dimError && (
            <div className="dim-error">{config.dimError}</div>
          )}
        </div>
      );
    } else if (config.dimSelect === "Diagonal & Ratio") {
      return (
        <div>
          <RatioInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          <DiagonalInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
        </div>
      );
    } else if (config.dimSelect === "Ratio & Height") {
      return (
        <div>
          <RatioInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          <HeightInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
        </div>
      );
    } else if (config.dimSelect === "Ratio & Width") {
      return (
        <div>
          <RatioInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
          <WidthInput
            config={config}
            dimSelect={config.dimSelect}
            clearState={this.props.clearState}
            handleInputChange={this.props.handleInputChange}
          />
        </div>
      );
    }
  }

  numberWithCommas = (x) => {
    if (x !== undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  renderSuccess = (pixelTest) => {
    if (pixelTest) {
      return (
        <i
          style={{ paddingLeft: "10px" }}
          className="fas fa-check-circle success"
        />
      );
    } else {
      return (
        <i
          style={{ paddingLeft: "10px" }}
          className="far fa-exclamation-circle warning"
        />
      );
    }
  };

  renderProjectInfo() {
    const { setProjectTitle } = this.props;
    return (
      <TextField
        onChange={(e) => setProjectTitle(e)}
        style={{ width: "100%", background: "white" }}
        label="Project Title"
        margin="normal"
        value={this.props.config.projectTitle}
        variant="filled"
      />
    );
  }

  renderProcessing() {
    const { config } = this.props;
    if (config.currentProd.sku) {
      let userPixels = 0;
      //eslint-disable-next-line
      const pixels = _.map(config.processors, (o, i) => {
        if (o.num !== "None") {
          // const maxPixels = _.find(JSON.parse(o.customFields));
          const currentProcessorPixels = o.qty * o.maxPixels;
          userPixels = +currentProcessorPixels + +userPixels;
        }
      });

      const pixelTest = userPixels >= config.pixelsPerPanel;
      return (
        <div className="processing">
          <div>
            Total Pixels Needed:
            <span>
              {Intl.NumberFormat("en-us").format(config.pixelsPerPanel)}
              {this.renderSuccess(pixelTest)}
            </span>
          </div>
          <div>
            Current Processing Power:
            <span>{this.numberWithCommas(userPixels)}</span>
          </div>
        </div>
      );
    }
  }

  render() {
    const { handleTab, config, classes, products } = this.props;

    return (
      <div className="inner">
        <form>
          <div id="start01" className="form-section">
            <h3>Select a Product</h3>
            <FormControl>
              <Select
                autoWidth={false}
                // renderValue={() => this.renderValue(config.currentProd)}
                className={classes.select}
                value={config.productSelected}
                onChange={this.props.handleSelect("productSelected")}
              >
                <MenuItem value="Select a Product">No Item Selected</MenuItem>
                {this.renderProductSelect()}
              </Select>
            </FormControl>
          </div>
          <div className="form-section">
            {this.renderDimensions()}

            <div className="input-styles">
              {config.currentProd.sku && (
                <div>
                  {this.renderFormat()}
                  <h3>Requested Dimensions</h3>
                  {this.renderInputs()}
                </div>
              )}
            </div>
            {config.currentProd.sku && (
              <>
                {this.renderProductSelects("addons", "Add-Ons")}
                {this.renderProductSelects("processors", "Processors")}
              </>
            )}

            {_.map(config.addons, (addon) => {})}
            {this.renderProcessing()}
          </div>

          <CustomerDetails
            admin={this.props.admin}
            classes={classes}
            config={this.props.config}
            handleInputChange={this.props.handleInputChange}
          />
          <div className="view-controls">
            {this.props.config.widthSelected && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "15px",
                  marginRight: "0px",
                }}
                className="view-quote"
                onClick={handleTab("quoteTab")}
              >
                View Quote
              </div>
            )}

            {this.props.config.totalPanelsNeeded > 0 && (
              <div style={{ width: "100%" }}>
                {this.renderProjectInfo()}
                {this.renderSaveButton()}
              </div>
            )}
            {this.renderSaveTime()}
            {this.renderDeleteButton()}

            {/* <Button
              type="submit"
              style={{ width: "48%", marginLeft: 5 }}
              variant="fi"
              color="primary"
              onClick={this.props.openDialog}
              className="btn"
            >
              New
            </Button> */}
          </div>
        </form>
      </div>
    );
  }
}
export default withStyles(styles)(Calculator);
