import React, { useEffect } from "react";
import _ from "lodash";
import { PDProjectInterface } from "interfaces/ProductDevelopment";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, ListItem, ListItemAvatar, ListItemText, MenuItem, OutlinedInput, Paper, Select, Switch, TextField, Typography } from "@material-ui/core";
import { AccessTime, Build, Hotel } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import Loading from "components/utils/Loading";
import { UserContext } from "context/User";
import { ProductDevelopmentContext } from "context/ProductDevelopment";
import firstBase from "assets/icons/bases/bases_first_base.svg";
import secondBase from "assets/icons/bases/bases_second_base.svg";
import thirdBase from "assets/icons/bases/bases_third_base.svg";
import atBatBase from "assets/icons/bases/bases_at_base.svg";
import homeStretch from "assets/icons/bases/bases_home_stretch.svg";
import 'scss/components/ProductDevelopment.scss';

const ProductDevelopmentDashboard = () => {
    const LocalStorageSortKey = "last-pd-project-sort-value";
    const LocalStorageSearchKey = "last-pd-project-search-value";

    const [projectsLoaded, setProjectsLoaded] = React.useState<boolean>(false);
    const [showStatusHelp, setShowStatusHelp] = React.useState<boolean>(false);
    const { currentUser } = React.useContext(UserContext);
    const { projects, fetchAllProjects } = React.useContext(ProductDevelopmentContext);
    const [searchInput, setSearchInput] = React.useState(localStorage.getItem(LocalStorageSearchKey) ?? "");
    const [selectedSortValue, setSelectedSortValue] = React.useState<string>(localStorage.getItem(LocalStorageSortKey) ?? "oldestUpdate");
    const [showDugout, setShowDugout] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const rightNow = new Date();

    const WorkingIcon = <Build />;
    const WaitingIcon = <AccessTime />;

    const DugoutIcon = <Hotel />;
    const AtBatIcon = <img className="baseball-icon" src={atBatBase} alt="At Bat" />;
    const FirstBaseIcon = <img className="baseball-icon" src={firstBase} alt="First Base" />;
    const SecondBaseIcon = <img className="baseball-icon" src={secondBase} alt="Second Base" />;
    const ThirdBaseIcon = <img className="baseball-icon" src={thirdBase} alt="Third Base" />;
    const HomeStretchIcon = <img className="baseball-icon" src={homeStretch} alt="Home Stretch" />;

    const sortOptions = [
        {
            label: "Last Updated (Old - New)",
            value: "oldestUpdate"
        },
        {
            label: "Last Updated (New - Old)",
            value: "newestUpdate"
        },
        {
            label: "Name (A - Z)",
            value: "name"
        },
        {
            label: "Status Stage",
            value: "status"
        },
        {
            label: "Active Work",
            value: "isWaiting"
        }
    ];

    let isMounted = true;

    useEffect(() => {
        fetchAllProjects();

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
      if (projects.length > 0) {
        setProjectsLoaded(true);
      } else {
        setProjectsLoaded(false);
      }
    }, [projects]);

    const sortProjects = (searchResults) => {
        switch(selectedSortValue) {
            case "newestUpdate":
                searchResults.sort((a, b) => b.lastUpdate.getTime() - a.lastUpdate.getTime());
                break;
            case "name":
                searchResults.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case "status":
                searchResults.sort((a, b) => b.statusValue - a.statusValue);
                break;
            case "isWaiting":
                searchResults.sort((a, b) => a.isWaiting - b.isWaiting);
                break;
            default:
                searchResults.sort((a, b) => a.lastUpdate.getTime() - b.lastUpdate.getTime());
        }
        

        return searchResults;
    };

    const searchResults = React.useMemo(() => {
        const searchInputLow = searchInput.toLowerCase();

        if(selectedSortValue) {
            localStorage.setItem(LocalStorageSortKey, selectedSortValue);
        }

        let newResults = [];

        for (let api = 0; api < projects.length; api++) {
            const p = projects[api];
            if(
                p.name.toLowerCase().indexOf(searchInputLow) > -1 ||
                p.factory.toLowerCase().indexOf(searchInputLow) > -1 ||
                (p.baubles.length && p.baubles[0].name.toLowerCase().indexOf(searchInputLow) > -1) ||
                (p.baubles.length && p.baubles[0].vendorPartName.toLowerCase().indexOf(searchInputLow) > -1)
            ) {
                newResults.push(p);
            }
        }

        console.log(newResults);

        return sortProjects(newResults);
    }, [projects, searchInput, selectedSortValue]);

    const renderStatusIcon = (status) => {
        switch(status.id) {
            case 1: 
                return AtBatIcon;
            case 2: 
                return FirstBaseIcon;
            case 3: 
                return SecondBaseIcon;
            case 4: 
                return ThirdBaseIcon;
            case 5: 
                return HomeStretchIcon;
            default:
                return DugoutIcon;
        }
    }


    const renderListItem = (project: PDProjectInterface) => {
        let title = project.name;
        let subTitle = "";

        if(project.baubles.length) {
            // title = (project.baubles[project.baubles.length - 1].vendorPartName ?? project.baubles[project.baubles.length - 1].name);
            // subTitle = project.name;

            subTitle = (project.baubles[project.baubles.length - 1].vendorPartName ?? project.baubles[project.baubles.length - 1].name);
            title = project.name;
        }

        return (
            <Grid item xs={12} key={"project-li-" + project.id}>
                <Paper>
                    <ListItem
                        alignItems="flex-start"
                        onClick={() => navigate("" + project.id, {state: project})}
                        
                    >
                        <ListItemAvatar style={{ marginTop: 4 }}>
                            <Avatar variant="rounded" alt={project.name} src={project.mainImage} style={{height: 60, width: 60}} />
                        </ListItemAvatar>

                        <ListItemText
                            secondary={
                                <span style={{display: "block", width: "90%", fontWeight: "bold"}}>{subTitle}<span style={{fontSize: ".8em"}}> ({project.factory})</span></span>                
                            }
                            style={{ paddingLeft: 30 }}
                        >
                            <Grid container>
                                <Grid item xs={6} md={9}>
                                    <span style={{fontSize: "1.3em"}}>{title}</span>
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <span style={{fontSize: "1.1em", textAlign: "center", display: "block"}}>{(project.lastUpdate.getMonth() + 1) + " / " + project.lastUpdate.getDate()}</span>
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <div style={{margin: "auto", textAlign: "center"}}>
                                        {project.isWaiting ? WaitingIcon : WorkingIcon}
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <div style={{margin: "auto", textAlign: "center"}}>
                                        {renderStatusIcon(project.status)}
                                    </div>
                                </Grid>
                            </Grid>
                            
                        </ListItemText>
                    </ListItem>
                </Paper>
            </Grid>
        );
    }


    const renderStatusHelpDialog = () => {
        return (
            <Dialog
                open={showStatusHelp}
                onClose={() => setShowStatusHelp(false)}
            >
                <DialogTitle>
                    Play Ball!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are two different statuses for a project <br />
                        <h3>1. Waiting vs Working</h3>
                        <p>Are we waiting on something or could we make progress here?</p>
                        <h3>2. Project Stage</h3>
                        <strong>At Bat</strong><br />
                        - In this stage we are identifying, researching, acquiring documents, ordering the first sample, making first contact with the factory.<br />
                        - This stage ends with us getting a first look at the sample and deciding if we want to move forward with it, or send it to the minor leagues.<br />
                        <br /><strong>First Base</strong><br />
                        - Here we are doing a deep dive on the product. Have tech look at it it, review the menu/profiles if applicable, etc.<br />
                        <br /><strong>Second Base</strong><br />
                        - Now that we have a full list of changes, work with the factory to actually bring the vision to reality.<br />
                        - This can be a black hole stage if we're not careful. <br />
                        <br /><strong>Third Base</strong><br />
                        - Now that the product is looking good functionally, it's time to beautify.<br />
                        - Work with marketing/design to prepare silk screens, logos, packaging, and/or whatever the product needs.<br />
                        <br /><strong>Home Stretch</strong><br />
                        - At this point the product should be ready for mass production.<br />
                        - Here we'll be issuing a PO, checking over the master pack, and do a final handoff.<br />
                        <br /><strong>The Dugout</strong><br />
                        - If we're not working on a project because it's done, or dead, send it to the dugout.<br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setShowStatusHelp(false)} autoFocus>
                        K
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderList = () => {
        let listItems = [];
        let myListItems = [];

        for (let pi = 0; pi < searchResults.length; pi++) {
            const project: PDProjectInterface = searchResults[pi];
            if(showDugout || project.statusValue > -1) {
                // if(project.lead.id == currentUser.uid) {
                //     myListItems.push(renderListItem(project));
                // } else {
                //     listItems.push(renderListItem(project))
                // }
                listItems.push(renderListItem(project));
            }
        }


        if(!projectsLoaded) return <Loading height="40vh" title="Loading Projects" position="relative" />;

        return(
            
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <div className="search-field">
                            <TextField
                                label="Search by Name or Factory"
                                variant="outlined"
                                value={searchInput}
                                onChange={(e) => {
                                    setSearchInput(e.target.value)
                                    localStorage.setItem(LocalStorageSearchKey, e.target.value);
                                }}
                                fullWidth
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            key={"project-status"}
                            value={selectedSortValue}
                            onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                setSelectedSortValue(e.target.value);
                            }}
                            input={<OutlinedInput />}
                            style={{ width: "100%", textAlign: "left" }}
                        >
                            {sortOptions.map(
                                (stat) => {
                                    return (
                                        <MenuItem
                                            key={stat.value + "-dropdown-status-option"}
                                            value={stat.value}
                                        >
                                            {stat.label}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            onClick={() => {
                                navigate(`/pd/new`);
                            }}
                            variant="contained"
                            fullWidth={true}
                            color="primary"
                            style={{ height: "100%" }}
                            className="btn"
                        >
                            New Project
                        </Button>
                    </Grid>

                    {(myListItems.length > 0) && <Grid container style={{fontWeight:"bolder", borderBottom: "1px solid lightgrey", marginBottom: 8, padding: "0 8px", marginTop: 32}}>
                        <Grid item xs={6} md={9}>
                            <span style={{fontSize: "1.3em", fontWeight: "bold"}}>My Projects</span>
                        </Grid>
                        <Grid item xs={2} md={1} style={{textAlign: 'center'}}>Last Update</Grid>
                        <Grid item xs={4} md={2} style={{textAlign: 'center', paddingRight: 8, color: "#282bf6"}} onClick={() => setShowStatusHelp(true)}>Status?</Grid>
                    </Grid>}

                    {myListItems}

                    {(listItems.length > 0) && <Grid container style={{fontWeight:"bolder", borderBottom: "1px solid lightgrey", marginBottom: 8, padding: "0 8px", marginTop: 32}}>
                        <Grid item xs={6} md={9}>
                            {(myListItems.length > 0) && <span style={{fontSize: "1.3em", fontWeight: "bold"}}>Other Projects</span>}
                        </Grid>
                        <Grid item xs={2} md={1} style={{textAlign: 'center'}}>Last Update</Grid>
                        <Grid item xs={4} md={2} style={{textAlign: 'center', paddingRight: 8, color: "#282bf6"}} onClick={() => setShowStatusHelp(true)}>Status?</Grid>
                    </Grid>}

                    {listItems}

                    {(listItems.length == 0 && myListItems.length == 0) && (
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography variant="h4" style={{ fontWeight: "bold" }}>No Projects Found</Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} style={{ textAlign: "right" }}>
                        <FormControlLabel
                            label="Show Dugout Projects"
                            labelPlacement="start"
                            control={<Switch color="primary" checked={showDugout} onChange={() => {
                                setShowDugout(!showDugout);
                            }} />}
                        />
                    </Grid>
                </Grid>
        );
    }
    

    return (
        <div className="admin view">
            {renderStatusHelpDialog()}
            {renderList()}
        </div>
    );
};

export default ProductDevelopmentDashboard;
