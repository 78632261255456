import { AddressInterface } from "./Address";
import { ContactInterface } from "./Contact";

export interface CCDataInterface {
    card: CCInterface,
    address: AddressInterface,
    contact: ContactInterface
}

export const buildCCDataListFromAuthorize = (authorizeData: any, addresses: AddressInterface[], contacts: ContactInterface[]): CCDataInterface[] => {
    const ccInfoList: CCDataInterface[] = <Array<CCDataInterface>>[];
    if (authorizeData && authorizeData.paymentProfiles && authorizeData.paymentProfiles.length > 0) {
        for (let adi = 0; adi < authorizeData.paymentProfiles.length; adi++) {
            const paymentProfile = authorizeData.paymentProfiles[adi];
            let matchingAddress: AddressInterface;
            let matchingContact: ContactInterface;

            // First try to match the address and contact to billTo data
            if (paymentProfile.billTo) {
                if (addresses && addresses.length) {
                    matchingAddress = matchBillToToAddress(paymentProfile.billTo, addresses);
                }
                if (contacts && contacts.length) {
                    matchingContact = matchBillToToContact(paymentProfile.billTo, contacts);
                }
            }

            // If that address doesn't work, just use the companies billing address
            if (!matchingAddress && addresses && addresses.length) {
                matchingAddress = addresses.find((a) => a.addressTypes.findIndex((t) => t.name == "Billing") > -1);
            }

            // This shouldn't happen, but if that fails make some shit up. Companies should all have billing addresses
            if (!matchingAddress) {
                matchingAddress = {
                    contactName: paymentProfile.billTo.firstName + " " + paymentProfile.billTo.firstName,
                    streetAddress: paymentProfile.billTo.address,
                    city: paymentProfile.billTo.city,
                    state: paymentProfile.billTo.state,
                    zip: paymentProfile.billTo.zip,
                    country: paymentProfile.billTo.country,
                    addressTypes: [{ id: 2, name: "Billing" }]
                };
            }

            // If that contact doesn't work, just use the companies billing address
            if (!matchingContact && contacts && contacts.length) {
                matchingContact = contacts.find((a) => a.contactTypes.findIndex((t) => t.name == "Billing") > -1);
            }

            // This shouldn't happen, but if that fails make some shit up. Companies should all have billing contact
            if (!matchingContact) {
                matchingContact = {
                    firstName: paymentProfile.billTo.firstName ?? "",
                    lastName: paymentProfile.billTo.lastName ?? "",
                    email: "",
                    phoneNumber: paymentProfile.billTo.phoneNumber,
                    title: "",
                    contactTypes: [{ id: 2, name: "Billing" }]
                };
            }


            const ccInfo: CCDataInterface = {
                card: {
                    authorizeCustomerPaymentProfileId: paymentProfile.customerPaymentProfileId,
                    cardNumber: paymentProfile.payment.creditCard.cardNumber,
                    expirationDate: paymentProfile.payment.creditCard.expirationDate,
                    cardType: paymentProfile.payment.creditCard.cardType,
                    issuerNumber: paymentProfile.payment.creditCard.issuerNumber
                },
                address: matchingAddress,
                contact: matchingContact
            };
            ccInfoList.push(ccInfo);
        }
    }

    return ccInfoList;
};

const matchBillToToAddress = (billTo: any, addresses: AddressInterface[]): AddressInterface => {
    // Check billing addresses first
    addresses = addresses.sort((a, b) => (a.addressTypes.findIndex((t) => t.name == "Billing") > b.addressTypes.findIndex((t) => t.name == "Billing") ? -1 : 1));

    let bestMatch: AddressInterface;
    for (let ai = 0; ai < addresses.length; ai++) {
        const address = addresses[ai];

        if (billTo.zip && billTo.zip == address.zip) {
            // At least matches the zip, should be good
            bestMatch = address;
        }

        if (
            (!billTo.address || billTo.address == address.streetAddress) &&
            (!billTo.city || billTo.city == address.city) &&
            (!billTo.state || billTo.state == address.state) &&
            (!billTo.country || billTo.country == address.country)
        ) {
            // Perfect Match
            return address;
        }

    }
    return bestMatch;
};

const matchBillToToContact = (billTo: any, contacts: ContactInterface[]): ContactInterface => {
    // Check billing contacts first
    contacts = contacts.sort((a, b) => (a.contactTypes.findIndex((t) => t.name == "Billing") > b.contactTypes.findIndex((t) => t.name == "Billing") ? -1 : 1));

    for (let ai = 0; ai < contacts.length; ai++) {
        const contact = contacts[ai];

        if (
            (!billTo.phoneNumber || billTo.phoneNumber == contact.phoneNumber) &&
            (
                (!billTo.firstName || !billTo.lastName) ||
                (billTo.firstName == contact.firstName && billTo.lastName == contact.lastName)
            )
        ) {
            // Perfect Match
            return contact;
        }
    }
};

export const DefaultCCDataInterface: CCDataInterface = {
    card: {
        cardNumber: "",
        expirationDate: "",
        cardType: "Visa"
    },
    address: null,
    contact: null
};

export interface CCInterface {
    authorizeCustomerPaymentProfileId?: string,
    cardNumber: string,
    expirationDate: string,
    cardType: string,
    issuerNumber?: string
}

export interface CCInfoInterface {
    profile: AuthorizeProfileInterface,
    messages: {
        resultCode: string,
        message: Array<{ code: string, text: string }>
    }
}

export interface AuthorizeProfileInterface {
    paymentProfiles: Array<AuthorizePaymentProfileInterface>
    profileType: string,
    customerProfileId: string,
    merchantCustomerId: string,
    description?: string,
    email?: string
}

export interface AuthorizePaymentProfileInterface {
    customerPaymentProfileId: string,
    payment: {
        creditCard: {
            cardNumber: string,
            expirationDate: string,
            cardType: string,
            issuerNumber?: string
        }
    },
}

export const buildAuthorizeProfile = (rawData): AuthorizeProfileInterface => {

    const paymentProfiles = [];
    for (let ppi = 0; ppi < rawData.paymentProfiles.length; ppi++) {
        paymentProfiles.push(rawData.paymentProfiles[ppi]);
    }

    return {
        paymentProfiles: paymentProfiles,
        profileType: rawData.profileType,
        customerProfileId: rawData.customerProfileId,
        merchantCustomerId: rawData.merchantCustomerId,
        description: rawData.description
    };
};


export const buildAuthorizePaymentProfile = (rawData): AuthorizePaymentProfileInterface => {

    return {
        customerPaymentProfileId: rawData.customerPaymentProfileId,
        payment: {
            creditCard: {
                cardNumber: rawData.payment.creditCard.cardNumber,
                expirationDate: rawData.payment.creditCard.expirationDate,
                cardType: rawData.payment.creditCard.cartType
            }
        },
    };
};