import { ProductInterface } from "interfaces/Product";
import React from "react";

interface ProductEditorNavProps {
  product: ProductInterface,
  activeMenu: string,
  setActiveMenu: Function,
  writeAccess: boolean,
}

const ProductEditorNav = ({ product, activeMenu, setActiveMenu, writeAccess }: ProductEditorNavProps) => {
  const [menuItems, setMenuItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    let adminItems = [
      "General",
      "Dimensions",
      "Media",
      "Admin",
      "Sales",
    ];

    const resellerItems = [
      "General",
      "Dimensions",
      "Media"
    ];

    if (writeAccess) {
      setMenuItems(adminItems);
    } else {
      setMenuItems(resellerItems);
    }
  }, [writeAccess, product]);

  const renderNav = () => {
    return menuItems.map((item) => {
      return (
        <div
          key={item}
          onClick={() => setActiveMenu(item)}
          className={activeMenu === item ? "item active" : "item"}
        >
          {item}
        </div>
      );
    });
  };

  return <div className="settings-bar" style={{ marginBottom: 20 }}>{renderNav()}</div>;
};

export default ProductEditorNav;
