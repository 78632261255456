import React from "react";
import _ from "lodash";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@material-ui/core";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import UserSearchResult from "./UserSearchResult";
import { UserContext } from "context/User";
import { CompanyContext } from "context/Company";
import { UserInterface } from "interfaces/User";
import { useWindowWidth } from "hooks/useWindowSize";

interface UserSearchProps {
    allUsers: UserInterface[],
}

const UserSearch = ({ allUsers }: UserSearchProps) => {
    const { accountLevels } = React.useContext(UserContext);
    const { companyTypes } = React.useContext(CompanyContext);
    const [input, setInput] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [companyTypeFilters, setCompanyTypeFilters] = React.useState<string[]>([]);
    const [accountLevelFilters, setAccountLevelFilters] = React.useState<string[]>([]);
    const [accountStatusFilters, setAccountStatusFilters] = React.useState<string[]>([]);
    const [filteredList, setFilteredList] = React.useState<Array<UserInterface>>([]);
    const [sortMethods, setSortMethods] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);
    const windowWidth = useWindowWidth();

    const windowScrollerRef = React.useRef<WindowScroller>();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 800,
                width: 250,
            },
        },
    };

    const sortOptions = [
        {
            label: "Name (A - Z)",
            value: "lastName",
            order: "asc",
        },
        {
            label: "Name (Z - A)",
            value: "lastName",
            order: "desc",
        },
        {
            label: "Email (A - Z)",
            value: "email",
            order: "asc",
        },
        {
            label: "Email (Z - A)",
            value: "email",
            order: "desc",
        },
        {
            label: "Company (A - Z)",
            value: "company.name",
            order: "asc",
        },
        {
            label: "Company (Z - A)",
            value: "company.name",
            order: "desc",
        },
        {
            label: "Last Activity (New)",
            value: "lastActivity",
            order: "desc",
        },
        {
            label: "Last Activity (Old)",
            value: "lastActivity",
            order: "asc",
        },
    ];

    React.useEffect(() => {
        if (allUsers) {
            filterUsers();
            setLoading(false);
        }
    }, [allUsers]);

    React.useEffect(() => {
        filterUsers();
    }, [companyTypeFilters, accountLevelFilters, accountStatusFilters]);

    React.useLayoutEffect(() => {
        // Adjust the scroll view when filters are opened or closed
        if (windowScrollerRef && windowScrollerRef.current) {
            windowScrollerRef.current.updatePosition();
        }
    }, [showFilters]);

    const filterUsers = () => {
        // Only apply filters once one is selected
        const applyFilters = companyTypeFilters.length > 0
            || accountLevelFilters.length > 0
            || accountStatusFilters.length > 0;

        if (applyFilters) {
            let companyTypeResults: UserInterface[] = [];
            let accountLevelResults: UserInterface[] = [];
            const accountStatusResults: UserInterface[] = [];
            let results = [];

            allUsers.forEach((user) => {
                if (companyTypeFilters.some((type) => user.company && type == user.company.companyType)) {
                    companyTypeResults.push(user);
                }
            });

            if (companyTypeFilters.length === 0) {
                companyTypeResults = allUsers;
            }

            companyTypeResults.forEach((user) => {
                if (accountLevelFilters.some((level) => level == user.accountLevel.name)) {
                    accountLevelResults.push(user);
                }
            });

            if (accountLevelFilters.length === 0) {
                accountLevelResults = companyTypeResults;
            }

            accountLevelResults.forEach((user) => {
                if (accountStatusFilters.some((status) => status == user.accountSignupStatus)) {
                    accountStatusResults.push(user);
                }
            });

            results = accountStatusFilters.length > 0 ? accountStatusResults : accountLevelResults;

            setFilteredList(results);
        } else {
            setFilteredList(allUsers);
        }
    };

    const sortUsers = (searchResults) => {
        let returnable;

        if (sortMethods.length > 0) {
            const nameSorter = user => user.lastName.toLowerCase();
            returnable = _.orderBy(
                searchResults,
                sortMethods.map((method) => method.substring(0, 4) == "Name" ? nameSorter : sortOptions.find((option) => option.label == method).value),
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
            );
        } else {
            // If no sort method selected, put pending users at the top
            const pendingUsers = searchResults.filter((result) => result.accountSignupStatus == "needs review" || result.accountSignupStatus == "applied");
            const otherUsers = searchResults.filter((result) => result.accountSignupStatus != "needs review" && result.accountSignupStatus != "applied");
            returnable = [
                ..._.orderBy(pendingUsers, (result) => result.lastName.toLowerCase(), ["asc"]),
                ..._.orderBy(otherUsers, (result) => result.lastName.toLowerCase(), ["asc"]),
            ];
        }

        return returnable;
    };

    const renderTypeFilterOption = (option) => {
        return (
            <Grid key={option.id} item xs={12} md={6}>
                <Tooltip title={option.name} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={companyTypeFilters.some((type) => type === option.name)}
                                onChange={() => {
                                    if (companyTypeFilters.some((type) => type === option.name)) {
                                        setCompanyTypeFilters(companyTypeFilters.filter((type) => type !== option.name));
                                    } else {
                                        setCompanyTypeFilters([...companyTypeFilters, option.name]);
                                    }
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={option.name}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderCompanyTypeFilters = () => {
        return companyTypes.map((type) => {
            return renderTypeFilterOption(type);
        });
    };

    const renderAccountFilterOption = (option) => {
        return (
            <Grid key={option.id} item xs={12} md={6}>
                <Tooltip title={option.name} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={accountLevelFilters.some((filter) => filter === option.name)}
                                onChange={() => {
                                    if (accountLevelFilters.some((filter) => filter === option.name)) {
                                        setAccountLevelFilters(accountLevelFilters.filter((selected) => selected != option.name));
                                    } else {
                                        setAccountLevelFilters([...accountLevelFilters, option.name]);
                                    }
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={option.name}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderAccountLevelFilters = () => {
        return accountLevels.map((level) => {
            return renderAccountFilterOption(level);
        });
    };

    const renderStatusFilterOption = (option, title, tooltip) => {
        return (
            <Grid key={option} item xs={12} md={6}>
                <Tooltip title={tooltip} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={accountStatusFilters.some((filter) => filter === option)}
                                onChange={() => {
                                    if (accountStatusFilters.some((filter) => filter === option)) {
                                        setAccountStatusFilters(accountStatusFilters.filter((selected) => selected != option));
                                    } else {
                                        setAccountStatusFilters([...accountStatusFilters, option]);
                                    }
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={title}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderStatusFilters = () => {
        return (
            <>
                {renderStatusFilterOption("active", "Active", "Active Account")}
                {renderStatusFilterOption("applied", "Pending Application", "Applied to become a reseller")}
                {renderStatusFilterOption("needs review", "Needs Review", "Has requested to be added to an existing company")}
                {renderStatusFilterOption("declined", "Declined", "Requested to be added to a company that doesn't exist yet")}
            </>
        );
    };

    const renderSortOptions = () => {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    key="sortMethods"
                    multiple
                    value={sortMethods}
                    renderValue={() => (
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {sortMethods.map((method) => (
                                <Chip key={method} label={method} style={{ marginRight: 5 }} />
                            ))}
                        </Box>
                    )}
                    onChange={(e: React.ChangeEvent<{
                        name?: string;
                        value: string[];
                    }>) => {
                        setSortMethods(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    {sortOptions.map(
                        (option) => {
                            const index = sortMethods.findIndex((n) => n == option.label);
                            return (
                                <MenuItem
                                    key={option.label}
                                    value={option.label}
                                >
                                    <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </FormControl>
        );
    };

    const searchResults = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        const hits = _.filter(filteredList, (user: UserInterface) => {
            return _.includes(user.displayName.toLowerCase(), searchInput) || _.includes(user.email.toLowerCase(), searchInput);
        });

        return sortUsers(hits);
    }, [filteredList, input, sortMethods]);

    const renderRow = ({ index, key, style }) => {
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <UserSearchResult user={searchResults[index]} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return <p className="body-message center-text">No Users Found</p>;
    };

    return allUsers ? (
        <div className="grid-search">
            <h3 className="title">Users</h3>
            <div className="search-field">
                <TextField
                    label="User Search"
                    variant="outlined"
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                />
            </div>
            <Grid container className="container-spacing">
                <Grid item container alignItems="center" xs={12}>
                    <Grid item md={9} xs={7}><h3>Filter and Sort {`(${filteredList ? filteredList.length : ""})`}</h3></Grid>
                    <Grid item md={3} xs={5}>
                        <Button
                            variant={showFilters ? "contained" : "outlined"}
                            color={showFilters ? "primary" : "secondary"}
                            onClick={() => setShowFilters(!showFilters)}
                            fullWidth
                        >
                            {showFilters ? "Hide Filters" : "Show Filters"}
                        </Button>
                    </Grid>
                </Grid>
                {showFilters && (
                    <>
                        <Grid item container xs={9}>
                            <Grid item container alignContent="flex-start" xs={4}>
                                <Grid item xs={12}>Company Type</Grid>
                                {renderCompanyTypeFilters()}
                            </Grid>
                            <Grid item container alignContent="flex-start" xs={4}>
                                <Grid item xs={12}>Account Level</Grid>
                                {renderAccountLevelFilters()}
                            </Grid>
                            <Grid item container alignContent="flex-start" xs={4}>
                                <Grid item xs={12}>Account Status</Grid>
                                {renderStatusFilters()}
                            </Grid>
                        </Grid>
                        <Grid item alignContent="flex-start" container xs={3}>
                            <Grid item xs={12}>Sort By</Grid>
                            <Grid item xs={12}>
                                {renderSortOptions()}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container className="results">
                {windowWidth > 700 && <Grid container item xs={12} className="grid-search-headers">
                    <Grid xs={3} item className="result-name">
                        Name
                    </Grid>
                    <Grid item xs={3}>
                        Email
                    </Grid>
                    <Grid item xs={3}>
                        Company
                    </Grid>
                    <Grid item container xs={3} className="center-text">
                        <Grid item xs={6}>
                            Last Activity
                        </Grid>
                        <Grid item xs={3}>
                            Status
                        </Grid>
                        <Grid item xs={3}>
                            View
                        </Grid>
                    </Grid>
                </Grid>}
                {loading ?
                    (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) :
                    (<WindowScroller ref={windowScrollerRef}>
                        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                            return <List
                                autoHeight
                                width={width}
                                height={height}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowHeight={windowWidth > 700 ? 60 : 100}
                                rowRenderer={renderRow}
                                noRowsRenderer={renderPlaceholder}
                                rowCount={searchResults.length}
                                overscanRowCount={3}
                            />;
                        }}
                    </WindowScroller>)
                }
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default UserSearch;
