import { AddressInterface } from "./Address";

export interface LocationInterface {
    id?: number,
    name: string,
    website: string,
    address: AddressInterface,
    phoneNumber: string,
    lat: number,
    lng: number
}

export const buildLocation = (addresses: AddressInterface[], rawLocation): LocationInterface => {
    const matchingAddress = addresses.find((a) => a.id == rawLocation.address_id);
    if(!matchingAddress) {
        return null;
    }
    
    return {
        id: rawLocation.id,
        name: rawLocation.company_name,
        website: rawLocation.website,
        address: matchingAddress,
        phoneNumber: rawLocation.phone,
        lat: rawLocation.lat,
        lng: rawLocation.lng
    };
};

export const buildLocations = (addresses: AddressInterface[], rawLocationList): LocationInterface[] => {
    const locations: LocationInterface[] = [];
    if (!rawLocationList) return locations;
    for (let rali = 0; rali < rawLocationList.length; rali++) {
        const rawLocation = rawLocationList[rali];
        const builtLocation = buildLocation(addresses, rawLocation);
        if(builtLocation != null) {
            locations.push(builtLocation);
        }
        
    }

    return locations;
};
