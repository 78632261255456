import React from "react";
import { UserContext } from "context/User";

const Greeting = () => {
  const { currentUser, tempUser: tempUser, clearTempUser } = React.useContext(UserContext);

  const d = new Date(),
    hour = d.getHours();

  function renderGreeting() {
    if (hour > 4 && hour < 12) {
      return "Good Morning";
    } else if (hour >= 12 && hour < 17) {
      return "Good Afternoon";
    } else return "Good Evening";
  }

  return (
    <div className="greeting">
      {tempUser && (
        <div className="user-as">
          {tempUser ? "Operating as " + tempUser.displayName : " "}
          {tempUser && (
            <i
              onClick={() => clearTempUser()}
              className="far fa-times-circle"
            ></i>
          )}
        </div>
      )}
      <h1 className="reveal-text" style={{ textTransform: "capitalize" }}>
        {renderGreeting()},
        <br /> {tempUser ? tempUser.displayName : currentUser.displayName}
      </h1>
    </div>
  );
};

export default Greeting;
