import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { NewResellerInviteInterface } from "interfaces/Invite";
import { makeAxiosCall } from "utils";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";

interface InviteSearchResultProps {
    invite: NewResellerInviteInterface,
    refresh: () => void,
}

const InviteSearchResult = ({ invite, refresh }: InviteSearchResultProps) => {
    const [revoking, setRevoking] = React.useState(false);

    const revokeInvite = () => {
        setRevoking(true);

        makeAxiosCall(
            "delete",
            `reseller-invites/${invite.id}`
        ).then(res => {
            if (res.data.id) {
                refresh();
            }
            setRevoking(false);
        }).catch(error => {
            setRevoking(false);
            console.log(error);
        });
    };

    const renderStatus = () => {
        return (
            <div 
                className={invite.newUser?.id ? (invite.newUser?.company?.id ? "status highlight green" : "status highlight red") : "status highlight blue"} 
                style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis" }}
            >
                {invite.newUser?.id ? (invite.newUser?.company?.id ? "Accepted" : "Needs Company") : "Pending"}
            </div>
        );
    };

    return (
        <Paper key={invite.id} elevation={2} className="grid-search-result">
            <Grid container item xs={12} alignItems="center" className="center-text">
                <Grid item xs={3} className="result-name">
                    <Typography noWrap variant="body2" style={{ fontWeight: "bold" }}>
                        {invite.companyName}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {invite.email}
                </Grid>
                <Grid item xs={3}>
                    {invite.createdBy.company && (
                        <Link
                            to={`/edit-company/${invite.createdBy.company.id}`}
                        >
                            <Typography noWrap variant="body2">
                                {invite.createdBy.company.name}
                            </Typography>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={2}>
                    {renderStatus()}
                </Grid>
                <Grid item xs={1} style={{ color: "blue" }}>
                    {revoking ?
                        <CircularProgress
                            color="inherit"
                            size={14}
                        /> :
                        !invite.newUser?.id ? 
                            <i
                                onClick={revokeInvite}
                                className="far fa-trash-alt"
                                style={{ cursor: "pointer" }}
                            >
                            </i> : 
                            <Link to={"/edit-user/" + invite.newUser.id} className="no-underline">
                                <i className="fas fa-eye blue"></i>
                            </Link>
                    }
                </Grid>
            </Grid>
        </Paper>
    );
};

export default InviteSearchResult;