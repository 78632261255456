import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import _ from "lodash";
import { makeAxiosCall } from "utils";
import { UserContext } from "context/User";
import { BuildOrderObject, OrderInterface, OrderItemInterface, OrderNotesInterface, OrderStatusInterface } from "interfaces/Order";
import Loading from "components/utils/Loading";
import { CartContext } from "context/Cart";
import { useNavigate } from "react-router-dom";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import RefreshIcon from '@material-ui/icons/Refresh';
import 'scss/components/Orders/PendingOrderSummary.scss';
import { buildCCDataListFromAuthorize, CCInterface } from "interfaces/Payment";
import OrderButton from "components/Checkout/OrderButton";
import AdditionalShippingDetails from "components/utils/AdditionalShippingDetails";
import { Info } from "@material-ui/icons";
import { SideDrawerTab, ViewContext } from "context/View";
import DemoOrderFlag from "components/utils/DemoOrderFlag";

const enum SummaryPageProcesses {
  askingQuestion,
  answeringQuestion,
  savingForLater,
  cancelling,
  savingProcessing,
  refreshingSOs,
  none
}

interface PendingOrderSummaryProps {
  existingOrder?: OrderInterface,
  setExistingOrder?: Function,
}

const PendingOrderSummary = ({ existingOrder, setExistingOrder }: PendingOrderSummaryProps) => {
  const { internalUser, currentUser } = React.useContext(UserContext);
  const { loadPreviousOrder, orderStatusOptions, currentOrder, orderingOnBehalfOf, clearCart, saveCurrentOrder, saveCurrentActiveStep } = React.useContext(CartContext);
  const [linkedSONum, setLinkedSONum] = React.useState<string>("");
  const [potentialSOs, setPotentialSOs] = React.useState<string[]>([]);
  const [status, setStatus] = React.useState<OrderStatusInterface>({id: 0, name: "Unknown"});
  const [order, setOrder] = React.useState<OrderInterface>();
  const [creditCard, setCreditCard] = React.useState<CCInterface>();
  const [newNote, setNewNote] = React.useState<string>("");
  const [addingNote, setAddingNote] = React.useState(false);
  const [newQuestion, setNewQuestion] = React.useState<string>("");
  const [requestingReview, setRequestingReview] = React.useState(false);
  const [showEditButton, setShowEditButton] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [newResponse, setNewResponse] = React.useState<string>("");
  const [currentProcess, setCurrentProcess] = React.useState<SummaryPageProcesses>(SummaryPageProcesses.none);
  const [internalGuide, setInternalGuide] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState(false);
  const { saveCurrentPanel } = React.useContext(ViewContext);

  const navigate = useNavigate();

  const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };
  
  const [snackbar, setSnackbar] = React.useState(initialSnackbarState);

  let isMounted = true;


  React.useEffect(() => {
    if (!existingOrder || !existingOrder.id) {
      setOrder(currentOrder);
    } else {
      setOrder(existingOrder);
    }
  }, [existingOrder]);

  React.useEffect(() => {
    if (order) {
      if (internalUser) {
        fetchCompanySOs();
        if (order.fishbowlSONum) setLinkedSONum(order.fishbowlSONum);
      }
      if (order.orderStatus) {
        setStatus(orderStatusOptions.find((option) => option.id == order.orderStatus.id));
      }
      fetchCC();
      // if(!order.id || order.orderStatus.name != "Accepted")
    }
  }, [order]);

  const fetchCompanySOs = async () => {
    setCurrentProcess(SummaryPageProcesses.refreshingSOs);

    let response = await makeAxiosCall(
      "get",
      `fishbowl-orders/${order.company.fishbowlCustomerId}`,
    ).catch((error) => {
      console.error(error);
    });

    if (isMounted) {
      if (response && response.data) {
        setPotentialSOs(_.map(
          _.orderBy(response.data, ['dateCreated'], ['desc']),
          (so) => so.num
        ));
      } else {
        setSnackbar({
          isOpen: true,
          message: "Failed to connect to Fishbowl",
          severity: "error",
        });
      }
      setCurrentProcess(SummaryPageProcesses.none);
    }
  }

  const fetchCC = () => {
    if (order.company.gatewayProfileId) {
      makeAxiosCall(
        "get",
        ("cc/" + order.company.gatewayProfileId)
      ).then((res) => {
        if(res.data.profile) {
          let loadedInfo = buildCCDataListFromAuthorize(res.data.profile, order.company.addresses, order.company.contacts);
          
          let card = loadedInfo.find(info => info.card.authorizeCustomerPaymentProfileId == order.authorizePaymentProfileId);
          if (card) setCreditCard(card.card);
        }
      }).catch((error) => console.log("Error:" + error));
    }
  }

  const resumeOrder = (checkoutStep: number) => {
    if (order) {
      loadPreviousOrder(order);

      saveCurrentActiveStep(checkoutStep);
      
      if (!window.location.pathname.includes("checkout")) {
        navigate("/checkout");
      }
    }
  }

  const reloadOrder = async (updatedOrderId: number) => {
    if(!updatedOrderId) return;

    const response = await makeAxiosCall(
      "get",
      `order/${currentUser.company.id}/${updatedOrderId}`
    ).catch(err => {
      console.error(err);
    });

    if (response.data) {
      let newOrderData = await BuildOrderObject(response.data);
      
      if (isMounted) {
        console.log("hello");
        console.log(currentOrder);

        if(currentOrder.id == newOrderData.id) {
          saveCurrentOrder(newOrderData);
        }

        setOrder(newOrderData);
      }
    }
  }

  const createOrder = async (orderStatusId): Promise<number> => {
    let demoStatus = orderStatusOptions.find((s)=> s.name == "Demo Requested");
    const response = await makeAxiosCall(
      "post",
      `order`,
      {
        ordered_by_id: currentUser.uid,
        company_id: orderingOnBehalfOf ? orderingOnBehalfOf.id : currentUser.company.id,
        billing_address_id: currentOrder.billingAddress.id,
        shipping_address_id: currentOrder.shippingAddress.id,
        billing_contact_id: currentOrder.billingContact.id,
        shipping_contact_id: currentOrder.shippingContact.id,
        customer_po_num: currentOrder.customerPONum,
        customer_po_upload_url: currentOrder.customerPOURL,
        authorize_payment_profile_id: currentOrder.authorizePaymentProfileId,
        order_status_id: currentOrder.demoOrder ? (demoStatus ? demoStatus.id : 9) : orderStatusId,
        drop_ship: currentOrder.isDropShip,
        preferred_packing_list_upload_url: currentOrder.preferredPackingListURL,
        shipping_preference: {
          account_number: currentOrder.shippingPreference.accountNumber,
          carrier: currentOrder.shippingPreference.carrier,
          service: currentOrder.shippingPreference.service
        },
        order_items: currentOrder.orderItems,
        demo_order: currentOrder.demoOrder
      },
      { headers: {"Content-Type": "application/json"} },
    ).catch(error => {
      console.log(error);
      return 0;
    });
    
    return response.data.id;
  }

  const updateOrder = async () => {
    const result = await makeAxiosCall(
      "post",
      `order/${order.id}`,
      {
        id: order.id,
        company_id: order.company.id,
        ordered_by_id: order.orderedBy.id,
        billing_address_id: order.billingAddress.id,
        shipping_address_id: order.shippingAddress.id,
        billing_contact_id: currentOrder.billingContact.id,
        shipping_contact_id: currentOrder.shippingContact.id,
        order_items: order.orderItems,
        order_status_id: status.id,
        fishbowl_so_num: linkedSONum,
        demo_order: order.demoOrder
      }
    ).catch((error) => {
      console.error(error);
    });

    if (result && result.data && result.data.id) {
      setOrder({...order, orderStatus: status});
      setSnackbar({
        isOpen: true,
        message: "Updated order successfully",
        severity: "success"
      });
    } else {
      setSnackbar({
        isOpen: true,
        message: "Failed to update order",
        severity: "error"
      });
    }
  }

  const updateStatus = async (newStatus: OrderStatusInterface) => {
    setSubmitting(true);
    const result = await makeAxiosCall(
      "post",
      `order/${order.id}`,
      {
        id: order.id,
        company_id: order.company.id,
        ordered_by_id: order.orderedBy.id,
        billing_address_id: order.billingAddress.id,
        shipping_address_id: order.shippingAddress.id,
        billing_contact_id: order.billingContact.id,
        shipping_contact_id: order.shippingContact.id,
        order_items: order.orderItems,
        order_status_id: newStatus.id,
      }
    ).catch((error) => {
      console.error(error);
    });
    setSubmitting(false);
    if (result && result.data && result.data.id) {
      setOrder({...order, orderStatus: newStatus});
      setSnackbar({
        isOpen: true,
        message: "Updated order successfully",
        severity: "success"
      });
      return true;
    } else {
      setSnackbar({
        isOpen: true,
        message: "Failed to update order",
        severity: "error"
      });
      return false;
    }
  }

  const requestDemo = async () => {
    setSubmitting(true);
    let demoStatus = orderStatusOptions.find((s)=> s.name == "Demo Requested");
    let orderId = order.id;
    if(orderId) {
      await updateStatus(demoStatus);
    } else {
      orderId = await createOrder(demoStatus ? demoStatus.id : 9);
    }
    
    if(orderId) {
      clearCart();
      saveCurrentPanel(SideDrawerTab.home);
      navigate(`/checkout/thank-you`);
      // sendEmail(orderId);
    } else {
      // ORDER NOT CREATED
      setSubmitting(false);
    }
  }

  const renderOrderDemoButtons = () => {
    if(!order || !order.demoOrder) return null;
    let acceptedStatus = orderStatusOptions.find((s)=> s.name == "Accepted");
    let reviewStatus = orderStatusOptions.find((s)=> s.name == "Review Requested");

    if(order.orderStatus.name == "Accepted") {
      return (<Grid item xs={6}>
        <h4>This demo order has been approved. {internalUser ? "" : " Check your Order History for updates."}</h4>
      </Grid>);
    }


    if(order.orderStatus.name == "Canceled") {
      return (<Grid item xs={6}>
        <h4>This demo order has been canceled.</h4>
      </Grid>);
    }


    if(internalUser) {
      if(order.orderStatus.name == "Demo Requested") {
        return (
          <Grid item xs={6} container spacing={2} style={{ textAlign: "right" }}>
            <Grid item xs={6}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => {
                  updateStatus(reviewStatus);
                  // TODO: EMAIL REP WITH NOTIFICATION
                }}
                disabled={submitting}
                className="btn"
                fullWidth={true}
              >
                Notify Rep of Changes
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button 
                variant="contained" 
                color="primary" 
                disabled={submitting}
                onClick={() => {
                  updateStatus(acceptedStatus);
                }}
                className="btn"
                fullWidth={true}
              >
                Approve Demo Order
              </Button>
          </Grid>
        </Grid>);
      } else return (
        <Grid item xs={6} container spacing={2} style={{ textAlign: "right" }}>
          <Grid item xs={6}>
            <h4>Awaiting Rep Approval</h4>
          </Grid>
          <Grid item xs={6}>
            <Button 
              variant="contained" 
              color="primary" 
              disabled={submitting}
              onClick={() => {
                updateStatus(acceptedStatus);
              }}
              className="btn"
              fullWidth={true}
            >
              Approve Anyway
            </Button>
          </Grid>
          
        </Grid>
      )
    } else {
      if(order.orderStatus.name == "Demo Requested") {
        return (
          <Grid item xs={6}>
            <h4 style={{textAlign: "right"}}>This demo order is submitted and pending approval.</h4>
          </Grid>
        )
      } else {
        return (
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <Button
              variant="contained" 
              color="primary" 
              onClick={requestDemo}
              className="btn"
              disabled={submitting}
              fullWidth={true}
            >
              {submitting ? (
                <CircularProgress 
                  color="inherit"
                  size={24}
                />
              ) : (
                order.orderStatus.name == "Review Requested" ? "Confirm Changes" : "Request Demo"
              )}
            </Button>
          </Grid>);
      }
    }

  }

  const addNoteToOrder = async (content: string, noteTypeId: number, newStatus: OrderStatusInterface): Promise<number> => {
    let tempOrder = {...order};
    let orderId = order.id;
    if(!orderId) {
      // Need to save the order first
      let savedStatus = orderStatusOptions.find((s)=> s.name == "Saved");
      orderId = await createOrder(savedStatus.id);
      tempOrder = {...tempOrder, id: orderId, orderStatus: savedStatus};
      saveCurrentOrder(tempOrder);
      setOrder(tempOrder);
    }
    
    const result = await makeAxiosCall(
      "post",
      `orders/create-note`,
      {
        content: content.trim(),
        typeId: noteTypeId,
        orderStatusId: newStatus.id ? newStatus.id : 5,
        orderId: orderId,
      },
      { headers: {"Content-Type": "application/json"} }
    ).catch((error) => {
      console.error(error);
    });

    if (result && result.data && result.data.id) {
      if(!order.notes) order.notes = [];
      
      let newNote: OrderNotesInterface = {
        id: result.data.id,
        createdAt: new Date(result.data.created_at),
        creator: {
          id: result.data.creator.id,
          name: result.data.creator.first_name + " " + result.data.creator.last_name
        },
        type: result.data.type,
        content: result.data.content
      }
      tempOrder = {...tempOrder, orderStatus: newStatus, notes: [...tempOrder.notes, newNote]};
      setOrder(tempOrder);
      if(tempOrder.id == currentOrder.id) {
        saveCurrentOrder(tempOrder)
      }
      setSnackbar({
        isOpen: true,
        message: "Note Added",
        severity: "success"
      });
    } else {
      setSnackbar({
        isOpen: true,
        message: "An error occurred while adding a note.",
        severity: "error"
      });
    }

    return orderId;
  }

  const renderItems = () => {
    if (order) {
      return _.map(
        order.orderItems,
        (o: OrderItemInterface, i) => {
          return (
            <Grid container className="line-item" key={`${o.id}-${i}`} style={{padding: "0 20px"}}>
              <Grid item sm={6}>
                <p style={{ fontWeight: "bold" }}>{o.product.name}</p>
              </Grid>
              <Grid item sm={2} style={{textAlign: "center"}}>
                <p>{o.product.sku}</p>
              </Grid>
              <Grid item sm={2}>
                <p>{o.fillWithBstock ? "Yes" : "No"}</p>
              </Grid>
              <Grid item sm={2} style={{textAlign: "center"}}>
                <p>{o.quantity}</p>
              </Grid>
              { (o.notes && o.notes.length > 0) || o.fillWithBstock ?  <Grid item sm={12}>
                <h4>Notes:</h4>
                <div className="orderItemNotes">
                  <p>{(o.fillWithBstock ? "Interested in B-Stock for this item. " : "") + (o.notes ? o.notes : "")}</p>
                </div>
              </Grid> : null }
            </Grid>
          );
        }
      );
    }
  };

  const renderNotes = () => {
    if (order) {
      if (order.notes && order.notes.length > 0) {
        return (
          <>
            <Grid container className="row-heads">
              <Grid item sm={3}>
                <p>Date</p>
              </Grid>
              <Grid item sm={3}>
                <p>User</p>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item sm={3}>
                <p>Note Type</p>
              </Grid>
            </Grid>

            {_.map(
              _.orderBy(order.notes, ['createdAt'], ['asc']),
              (o: OrderNotesInterface, i) => {
                return (
                  <Grid container className="line-item" key={`${o.id}`} style={{padding: "0 20px"}}>
                    <Grid item sm={3}>
                      <p>{new Date(o.createdAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})}</p>
                    </Grid>
                    <Grid item sm={3}>
                      <p style={{ fontWeight: "bold" }}>{o.creator.name}</p>
                    </Grid>
                    <Grid item sm={3}></Grid>
                    <Grid item sm={3}>
                      <p>{o.type.name}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p>{o.content}</p>
                    </Grid>
                  </Grid>
                );
              }
            )}
          </>
        );
      } else {
        return (
          <Grid container>No notes</Grid>
        )
      }
    }
  }

  const renderStatus = (status) => {
    if (status === "Submitted" || status === "Demo Requested") {
      return "status highlight blue";
    } else if (status === "Accepted") {
      return "status highlight green";
    } else if (status === "Canceled") {
      return "status highlight red";
    } else if (status === "Rep Responded") {
      return "status highlight purple";
    } else {
      return "status highlight subtle";
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, isOpen: false });
  };

  const internalGuideDialog = () => {
    return (
      <Dialog
        open={internalGuide}
        onClose={() => setInternalGuide(false)}
      >
        <DialogTitle>
          Order Processing
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This section is only visible to internal users. Use this section to
            update the status of an order as needed.<br /><br />
            For submitted orders, enter the PO into Fishbowl. Type the new SO
            number into the SO field, change the status to "Accepted", and then
            hit save.<br /><br />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  if (order) {
    return (
      <div style={{ marginTop: 10 }}>
        {internalGuideDialog()}
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        {order.demoOrder && (
          <Grid container alignItems="center" style={{backgroundColor: "#dddddd", padding: "10px 15px", borderRadius: 5, marginBottom: 30}}>
            <Grid item xs={12}>
              <h4>This is an order for demo product(s)</h4>
            </Grid>
            
            <Grid item xs={12}>
              {order.returningDemo ? "This demo order will be returned" : ""}
            </Grid>
            
          </Grid>
        )}

        {internalUser && (
          <Grid container alignItems="center" style={{backgroundColor: "#dddddd", padding: "10px 15px", borderRadius: 5}} spacing={2}>
            <Grid item xs={12}>
              <h3>Order Processing <Info style={{paddingLeft: 4, cursor: "pointer"}} fontSize="small" onClick={() => setInternalGuide(true)}/></h3>
            </Grid>
            <Grid item xs={10} md={4}>
              <Autocomplete
                id="linked-so-select"
                options={potentialSOs}
                onChange={(e, v) => {
                  setLinkedSONum(v);
                }}
                onInputChange={(e, v) => {
                  setLinkedSONum(v);
                }}
                value={linkedSONum}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select SO Number" />}
              />
            </Grid>
            <Grid item xs={2} md={1} style={{textAlign: "center"}}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="btn"
                onClick={fetchCompanySOs}
              >
                {currentProcess == SummaryPageProcesses.refreshingSOs ? 
                  (
                    <CircularProgress 
                      color="inherit"
                      size={24}
                    />
                  ) : (<RefreshIcon />)
                }
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                style={{ width: "100%"}}
                variant="outlined"
              >
                <InputLabel id="order-status-select-label" style={{backgroundColor: "#dddddd"}}>Order Status</InputLabel>
                <Select
                  onChange={(e: any) => setStatus(e.target.value)}
                  labelId="order-status-select-label"
                  id="order-status-select"
                  value={status}
                >
                  {_.map(orderStatusOptions, (option) => {
                    return (
                      <MenuItem key={option.id} value={option}>
                        {option.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                className="btn green"
                onClick={async () => {
                  setCurrentProcess(SummaryPageProcesses.savingProcessing);
                  await updateOrder();
                  setCurrentProcess(SummaryPageProcesses.none);
                }}
              >
                {currentProcess == SummaryPageProcesses.savingProcessing ?
                  <CircularProgress 
                  color="inherit"
                  size={24}
                  /> :
                  "Save"
                }
              </Button>
            </Grid>
          </Grid>
        )}

        {requestingReview && (
          <Grid container alignItems="center" style={{backgroundColor: "#dddddd", padding: "10px 15px", borderRadius: 5, margin: "5px 0"}}>
            <Grid item xs={12}>
              <h3>Ask a question about this order</h3>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Question"
                margin="normal"
                variant="outlined"
                onChange={(e) => setNewQuestion(e.target.value)}
                value={newQuestion}
                multiline
                style={{marginRight: 0, width: "100%" }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                type="submit"
                style={{ width: "100%", margin: "0 5px" }}
                variant="contained"
                color="primary"
                className="btn red"
                onClick={() => setRequestingReview(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <Button
                type="submit"
                style={{ width: "100%", margin: "0 5px" }}
                variant="contained"
                color="primary"
                className="btn green"
                onClick={async () => {
                  setCurrentProcess(SummaryPageProcesses.askingQuestion);
                  let orderId = await addNoteToOrder(newQuestion, 2, orderStatusOptions.find((option) => option.name == "Review Requested"));
                  await reloadOrder(orderId);
                  let email = currentUser.company.rep && currentUser.company.rep.contact && currentUser.company.rep.contact.email ? currentUser.company.rep.contact.email : "orders@blizzardpro.com";
                  let companyId = currentUser.company.rep ? currentUser.company.rep.id : 512;
                  await makeAxiosCall(
                    "post",
                    "email/help-request",
                    {
                      orderId: orderId,
                      to: {
                        id: currentUser.uid,
                        email: email,
                        companyId: companyId,
                      },
                      question: newQuestion,
                    }
                  );
                  
                  setCurrentProcess(SummaryPageProcesses.none);
                  setRequestingReview(false);
                }}
                disabled={newQuestion.trim().length == 0}
              >
                {currentProcess == SummaryPageProcesses.askingQuestion ?
                  <CircularProgress 
                  color="inherit"
                  size={24}
                  /> :
                  "Submit"
                }
              </Button>
            </Grid>
          </Grid>
        )}
        {order.orderStatus.name == "Review Requested" && currentUser.company.id != order.company.id && (
          <Grid container alignItems="center" style={{backgroundColor: "#dddddd", padding: "10px 15px", borderRadius: 5, margin: "5px 0"}}>
            <Grid item xs={12}>
              <h3>Review Order</h3>
            </Grid>
            <Grid item xs={12}>
              <p>
                A user has requested help with this order. Please review the 
                order and respond with any feedback or changes.
              </p>
            </Grid>
            {order.notes.filter((note) => note.type.name == "Question") && (
              <Grid container>
                <Grid item xs={12}>
                  <h3>Customer Questions:</h3>
                </Grid>
                {order.notes.filter((note) => note.type.name == "Question").map(note => {
                  return (
                    <Grid item xs={12} key={note.id}>
                      <p>
                        {`${new Date(note.createdAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})} - ${note.creator.name} - ${note.content}`}
                      </p>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Feedback"
                margin="normal"
                variant="outlined"
                onChange={(e) => setNewResponse(e.target.value)}
                value={newResponse}
                multiline
                style={{marginRight: 0, width: "100%" }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                type="submit"
                style={{ width: "100%", margin: "0 5px" }}
                variant="contained"
                color="primary"
                className="btn green"
                onClick={async () => {
                  setCurrentProcess(SummaryPageProcesses.answeringQuestion);
                  let orderId = await addNoteToOrder(newResponse, 3, orderStatusOptions.find((option) => option.name == "Rep Responded"));
                  setCurrentProcess(SummaryPageProcesses.none);
                  makeAxiosCall(
                    "post",
                    "email/help-response",
                    {
                      to: {
                        id: order.orderedBy.id,
                        email: order.orderedBy.email,
                        companyId: order.orderedBy.companyId,
                      },
                      orderId: orderId,
                    }
                  );
                }}
                disabled={newResponse.trim().length == 0}
              >
                {currentProcess == SummaryPageProcesses.answeringQuestion ?
                  <CircularProgress 
                  color="inherit"
                  size={24}
                  /> :
                  "Submit"
                }
              </Button>
            </Grid>
          </Grid>
        )}
        <div
          style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }}
        >
          <Grid container>
            <Grid item container xs={12} alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <h2>Summary</h2>
              </Grid>
              <Grid item sm={2}>
                {(!["Canceled", "Submitted", "Accepted", "Review Requested"].includes(order.orderStatus.name)) && (
                  <Button
                    type="submit"
                    // style={{ marginBottom: 20 }}
                    variant="contained"
                    color="primary"
                    className="btn"
                    fullWidth={true}
                    onClick={() => setRequestingReview(true)}
                    disabled={!order}
                  >
                    Request Review
                  </Button>
                )}
              </Grid>
              <Grid item sm={2}>
                {(!["Canceled", "Submitted", "Accepted"].includes(order.orderStatus.name)) ? 
                  (order.id === currentOrder.id ? (<Button
                    type="submit"
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    className="btn"
                    onClick={async () => {
                      setCurrentProcess(SummaryPageProcesses.savingForLater);
                      let savedStatus = orderStatusOptions.find((s)=> s.name == "Saved");
                      if(!order.id) {
                        await createOrder(savedStatus.id);
                      } else {
                        await updateOrder();
                      }
                      clearCart();
                      setCurrentProcess(SummaryPageProcesses.none);
                      return navigate("/");
                    }}
                    disabled={!order}
                  >
                    {currentProcess == SummaryPageProcesses.savingForLater ?
                      <CircularProgress 
                      color="inherit"
                      size={24}
                      /> :
                      "Save for Later"
                    }
                  </Button>) : (
                     (
                      <Button
                      type="submit"
                      variant="contained"
                      fullWidth={true}
                      color="primary"
                      className="btn"
                      onClick={() => resumeOrder(0)}
                      disabled={!order}
                    >
                      Resume Order
                    </Button>
                    )
                  )) : null
                }
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item container xs={12} style={{marginBottom: 20}} >
              <Grid container item xs={4} alignItems="center">
                <Grid item xs={12}>
                  <h3 style={{ borderBottom: "1px solid gray", margin: 0, marginBottom: 10, display: "table" }}>
                    Company
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h3 style={{ fontWeight: 900, margin: 0 }}>{order.company.name}</h3>
                </Grid>
              </Grid>
              <Grid item container xs={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <h3 style={{ borderBottom: "1px solid gray", margin: 0, marginBottom: 10, display: "table" }}>
                    PO
                  </h3>
                  <h3 style={{ fontWeight: 900, margin: 0 }}>{order.customerPONum ? order.customerPONum : "N/A"}</h3>
                </Grid>
                <Grid item xs={12} md={6}>
                  {order.customerPOURL && (
                    <Button
                      type="submit"
                      // style={{ marginBottom: 20 }}
                      variant="contained"
                      color="primary"
                      className="btn"
                      onClick={() => {
                        window.open(order.customerPOURL, "_blank");
                      }}
                    >
                      View PO File
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <h3 style={{ borderBottom: "1px solid gray", margin: 0, marginBottom: 10, display: "table" }}>
                  Status
                </h3>
                <h3 style={{ fontWeight: 900, margin: 0 }} className={order.orderStatus ? renderStatus(order.orderStatus.name) : ""}>
                  {order.orderStatus.name}
                </h3>
              </Grid>
              <Grid item xs={12} md={6}>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li>Date: {new Date(order.createdAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})}</li>
                  <li>Ordered By: {order.orderedBy.displayName}</li>
                  {order.fishbowlSONum && (<li>Order Number: {order.fishbowlSONum}</li>)}
                </ul>
              </Grid>
            </Grid>

            <Grid item container xs={12} alignItems="center" >
              <Grid item xs={10}>
                <h2>Shipping</h2>
              </Grid>
              <Grid item xs={2}>
                {!["Canceled", "Submitted", "Accepted"].includes(order.orderStatus.name) && (
                  <Button
                    type="submit"
                    style={{ width: "100%", margin: "0 5px" }}
                    variant="outlined"
                    color="primary"
                    // className="btn blue"
                    onClick={() => resumeOrder(2)}
                  >
                    Edit Shipping
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item container xs={12} spacing={2} style={{marginTop: 10, marginBottom: 10}}>
              <Grid item container md={4} xs={12}>
                  <Paper className={"bordered-container"}>
                      <Grid item xs={12}>
                          <h3 className="center">Shipping Address</h3>  
                      </Grid>
                      <Grid item xs={12}>
                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                          <li>{order.shippingAddress.contactName}</li>
                          <li>{order.shippingAddress.streetAddress}</li>
                          {order.shippingAddress.streetAddressTwo && (
                            <li>{order.shippingAddress.streetAddressTwo}</li>
                          )}
                          <li>
                            {order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.zip}
                          </li>
                          <li>{order.shippingAddress.country}</li>
                        </ul>
                      </Grid>
                  </Paper>
              </Grid>
              <Grid item container md={4} xs={12}>
                  <Paper className={"bordered-container"}>
                      <Grid item xs={12}>
                          <h3 className="center">Shipping Contact</h3>  
                      </Grid>
                      <Grid item xs={12}>
                        {order.shippingContact ? 
                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                          <li>{order.shippingContact.firstName + " " + order.shippingContact.lastName}</li>
                          <li style={{ overflow: "clip", textOverflow: "ellipsis" }}>{order.shippingContact.email}</li>
                          <li>
                            {order.shippingContact.phoneNumber}
                          </li>
                        </ul>: <p>Not Set</p>}
                      </Grid>
                  </Paper>
              </Grid>
              <Grid item container xs={4}>
                <div style={{padding: 15}}>
                  <h3 style={{ margin: 0 }}>Is This a Drop Shipment?</h3>
                  <Grid item xs={12}>
                    <h3 style={{ fontWeight: 900, margin: 0 }}>{order.isDropShip ? "Yes" : "No"}</h3>
                  </Grid>
                  <Grid item xs={12}>
                    {order.preferredPackingListURL ? (
                      <Button
                        type="submit"
                        // style={{ marginBottom: 20 }}
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={() => {
                          window.open(order.preferredPackingListURL, "_blank");
                        }}
                      >
                        View Packing List
                      </Button>
                    ) : <p>No Packing List Provided</p>}
                  </Grid>

                  <Grid item xs={12}>
                      <h3 className="center">Carrier Requests</h3>  
                  </Grid>
                  <Grid item xs={12}>
                    {order.shippingPreference ? (
                      <ul style={{  margin: 0, padding: 0, listStyle: "none"}}>
                        {order.shippingPreference.carrier && (<li>Carrier: {order.shippingPreference.carrier.name}</li>)}
                        {order.shippingPreference.service && (<li>Service: {order.shippingPreference.service.name}</li>)}
                        {order.shippingPreference.accountNumber && (<li>Account: {order.shippingPreference.accountNumber}</li>)}
                      </ul>
                    ) : (
                      <p>None</p>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <AdditionalShippingDetails address={order.shippingAddress} />

            <Grid item container xs={12} alignItems="center" style={{marginTop: 20}} >
              <Grid item xs={10}>
                <h2>Billing</h2>
              </Grid>
              <Grid item xs={2}>
                {!["Canceled", "Submitted", "Accepted"].includes(order.orderStatus.name) && order.company.defaultPaymentTermsName == "Prepaid" && (
                  <Button
                    type="submit"
                    style={{ width: "100%", margin: "0 5px" }}
                    variant="outlined"
                    color="primary"
                    // className="btn blue"
                    onClick={() => resumeOrder(3)}
                  >
                    Edit Billing
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item container xs={12} style={{marginBottom: 20}} spacing={2}>
                <Grid item container md={4} xs={12}>
                  <Paper className={"bordered-container"}>
                      <Grid item xs={12}>
                          <h3 className="center">Billing Address</h3>  
                      </Grid>
                      <Grid item xs={12}>
                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                          <li>{order.billingAddress.contactName}</li>
                          <li>{order.billingAddress.streetAddress}</li>
                          {order.billingAddress.streetAddressTwo && (
                            <li>{order.billingAddress.streetAddressTwo}</li>
                          )}
                          <li>
                            {order.billingAddress.city}, {order.billingAddress.state} {order.billingAddress.zip}
                          </li>
                          <li>{order.billingAddress.country}</li>
                        </ul>
                      </Grid>
                  </Paper>
              </Grid>

              <Grid item container md={4} xs={12}>
                  <Paper className={"bordered-container"}>
                      <Grid item xs={12}>
                          <h3 className="center">Billing Contact</h3>  
                      </Grid>
                      <Grid item xs={12}>
                        {order.billingContact ? 
                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                          <li>{order.billingContact.firstName + " " + order.billingContact.lastName}</li>
                          <li>{order.billingContact.email}</li>
                          <li>
                            {order.billingContact.phoneNumber}
                          </li>
                        </ul> : <p>Not Set</p>}
                      </Grid>
                  </Paper>
              </Grid>

              <Grid item container md={4} xs={12} style={{padding: 15}} direction="column" justifyContent="space-between">
                  <Grid item>
                    <h3 style={{ borderBottom: "1px solid gray", margin: 0, marginBottom: 10, display: "table" }}>
                      Payment Terms
                    </h3>
                    <h3 style={{ fontWeight: 900, margin: 0 }}>{order.company.defaultPaymentTermsName}</h3>
                  </Grid>
                  {order.company.defaultPaymentTermsName == "Prepaid" && (
                    <Grid item>
                      <h3 style={{ borderBottom: "1px solid gray", margin: 0, marginBottom: 10, display: "table" }}>
                        Card
                      </h3>
                      <h3 style={{ fontWeight: 900, margin: 0 }}>{creditCard ? `${creditCard.cardType} - ${creditCard.cardNumber}` : `None Provided`}</h3>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} alignItems="center" >
            <Grid item xs={10}>
              <h2>Items</h2>
            </Grid>
            <Grid item xs={2}>
              {!["Canceled", "Submitted", "Accepted"].includes(order.orderStatus.name) && (
                <Button
                  type="submit"
                  style={{ width: "100%", margin: "0 5px" }}
                  variant="outlined"
                  color="primary"
                  // className="btn blue"
                  onClick={() => resumeOrder(0)}
                >
                  Edit Items
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid container className="row-heads">
            <Grid item sm={6}>
              <p>Name</p>
            </Grid>
            <Grid item sm={2} style={{textAlign: "center"}}>
              <p>SKU</p>
            </Grid>
            <Grid item sm={2}>
              <p>Fill With B Stock</p>
            </Grid>
            <Grid item sm={2} style={{textAlign: "center"}}>
              <p>Qty</p>
            </Grid>
          </Grid>

          {renderItems()}

          <Grid container alignItems="center">
            <Grid item style={{paddingRight: 24}}>
              <h2>Notes</h2>
            </Grid>
            <Grid item sm={2}>
              {order && !["Canceled", "Accepted"].includes(order.orderStatus.name) && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn"
                  onClick={() => setAddingNote(true)}
                  disabled={!order}
                  style={{ width: "100%", margin: "0 5px" }}
                >
                  Add Note
                </Button>
              )}
            </Grid>
            {addingNote && (
              <>
                <Grid item xs={12}>
                  <h3>Add a Note</h3>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Note"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setNewNote(e.target.value)}
                    value={newNote}
                    multiline
                    style={{marginRight: 0, width: "100%" }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    type="submit"
                    style={{ width: "100%", margin: "0 5px" }}
                    variant="contained"
                    color="primary"
                    className="btn red"
                    onClick={() => setAddingNote(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={3}>
                  <Button
                    type="submit"
                    style={{ width: "100%", margin: "0 5px" }}
                    variant="contained"
                    color="primary"
                    className="btn green"
                    onClick={async () => {
                      if(!loading) {
                        setLoading(true);
                        let orderId = await addNoteToOrder(newNote, 1, order.orderStatus);
                        reloadOrder(orderId);
                        setLoading(false);
                        setAddingNote(false);
                        setNewNote("");
                      }
                    }}
                    disabled={newNote.trim().length == 0}
                  >
                    {loading ? "Saving" : "Submit"}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12}><hr /></Grid>
          </Grid>

          {renderNotes()}
          
          
            <Grid container style={{ marginTop: 24 }} spacing={2} justifyContent="space-between">
              {/* <Grid item xs={(order && (order.orderStatus.name != "Accepted")) ? 8 : 10}></Grid> */}
              {order && !["Canceled", "Submitted", "Accepted"].includes(order.orderStatus.name) && (
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    className="btn red"
                    onClick={async () => {
                      setCurrentProcess(SummaryPageProcesses.cancelling);
                      if(order.id) {
                        let newStatus = orderStatusOptions.find((option) => option.name == "Canceled");
                        await updateStatus(newStatus);
                        setCurrentProcess(SummaryPageProcesses.none);
                      } else {
                        clearCart();
                        return navigate("/");
                      }

                      if(order.id == currentOrder.id) {
                        clearCart();
                        return navigate("/");
                      }
                    }}
                    disabled={!order}
                  >
                    {currentProcess == SummaryPageProcesses.cancelling ?
                      <CircularProgress 
                      color="inherit"
                      size={24}
                      /> :
                      "Cancel PO"
                    }
                  </Button>
                </Grid>
              )}

            { renderOrderDemoButtons() }

            {order && !order.demoOrder && !["Canceled", "Submitted", "Accepted", "Demo Requested"].includes(order.orderStatus.name) && (
              <Grid item xs={2} style={{ textAlign: "right" }}>
                <OrderButton existingOrder={order}/>
              </Grid>
            )}
            </Grid>
        </div>
      </div>
    );
  } else {
    return (
      <Loading position={"relative"} title={""} height={"40vh"} />
    );
  }
};

export default PendingOrderSummary;
