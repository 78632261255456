import React from "react";
import { ProductInterface, ProductMediaInterface } from "interfaces/Product";
import { Grid, Paper } from "@material-ui/core";
import { logFileDownload, makeAxiosCall } from "utils";

interface ProductDownloadsProps {
  product: ProductInterface,
  writeAccess: boolean,
}

const ProductDownloads = ({ product, writeAccess }: ProductDownloadsProps) => {

  const productFiles = React.useMemo(() => {
    if (product.media) {
      return product.media.filter((item) => item.type.name == "manual");
    } else {
      return [];
    }
  }, [product]);

  React.useEffect(() => {
    console.log(productFiles);
  }, [productFiles]);

  const deleteFile = async (mediaId) => {
    console.log("DELETING FILE " + mediaId);
    // Disable for now
    // const response = await makeAxiosCall(
    //   "delete",
    //   `product-media/${mediaId}`,
    // ).catch((error) => {
    //   console.error(error);
    // });
  }

  const renderFileItem = (file: ProductMediaInterface) => {
    return (
      <Paper key={file.id} elevation={2} className="grid-search-result">
        <Grid container item xs={12} className="center-text" alignItems="center">
          <Grid item xs={6} className="result-name">
            {file.name}
          </Grid>
          <Grid item xs={2}>
            {file.name.split(".")[1].toUpperCase()}
          </Grid>
          <Grid item xs={2}>
            <i 
              className="fa-solid fa-arrow-up-right-from-square"
              onClick={() => {
                  logFileDownload(file.name, file.url, file.id);
                  window.open(file.url, "_blank")
                }
              }
            />
          </Grid>
          {writeAccess && (
            <>
              <Grid item xs={2}>
                <i 
                  className="fa-solid fa-trash"
                  style={{ color: "tomato" }}
                  onClick={() => deleteFile(file.id)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    );
  }

  const renderFileList = () => {
    return (
      productFiles.map((file) => renderFileItem(file))
    );
  }

  return productFiles.length > 0 ? (
    <Grid item xs={12} className="grid-search">

      <Grid container className="results">
        <Grid container item xs={12} className="center-text grid-search-headers" alignItems="center">
          <Grid xs={6} item className="result-name">
            Filename
          </Grid>
          <Grid item xs={2}>
            File Type
          </Grid>
          <Grid item xs={2}>
            View
          </Grid>
          {writeAccess && (
            <>
              <Grid item xs={2}>
                Delete
              </Grid>
            </>
          )}
        </Grid>
        {renderFileList()}
      </Grid>
    </Grid>
  ) : (
    <h4>No downloads found</h4>
  );
}

export default ProductDownloads;