
type State = {
    id: number,
    code: string,
    countryConstID: number,
    name: string,
}

export const states: State[] = [
    {
        id: 1,
        code: "ZZ",
        countryConstID: 1,
        name: "Unknown",
    },
    {
        id: 2,
        code: "AL",
        countryConstID: 2,
        name: "Alabama",
    },
    {
        id: 3,
        code: "AK",
        countryConstID: 2,
        name: "Alaska",
    },
    {
        id: 4,
        code: "AZ",
        countryConstID: 2,
        name: "Arizona",
    },
    {
        id: 5,
        code: "AR",
        countryConstID: 2,
        name: "Arkansas",
    },
    {
        id: 6,
        code: "CA",
        countryConstID: 2,
        name: "California",
    },
    {
        id: 7,
        code: "CO",
        countryConstID: 2,
        name: "Colorado",
    },
    {
        id: 8,
        code: "CT",
        countryConstID: 2,
        name: "Connecticut",
    },
    {
        id: 9,
        code: "DE",
        countryConstID: 2,
        name: "Delaware",
    },
    {
        id: 10,
        code: "DC",
        countryConstID: 2,
        name: "District of Columbia",
    },
    {
        id: 11,
        code: "FL",
        countryConstID: 2,
        name: "Florida",
    },
    {
        id: 12,
        code: "GA",
        countryConstID: 2,
        name: "Georgia",
    },
    {
        id: 13,
        code: "GU",
        countryConstID: 2,
        name: "Guam",
    },
    {
        id: 14,
        code: "HI",
        countryConstID: 2,
        name: "Hawaii",
    },
    {
        id: 15,
        code: "ID",
        countryConstID: 2,
        name: "Idaho",
    },
    {
        id: 16,
        code: "IL",
        countryConstID: 2,
        name: "Illinois",
    },
    {
        id: 17,
        code: "IN",
        countryConstID: 2,
        name: "Indiana",
    },
    {
        id: 18,
        code: "IA",
        countryConstID: 2,
        name: "Iowa",
    },
    {
        id: 19,
        code: "KS",
        countryConstID: 2,
        name: "Kansas",
    },
    {
        id: 20,
        code: "KY",
        countryConstID: 2,
        name: "Kentucky",
    },
    {
        id: 21,
        code: "LA",
        countryConstID: 2,
        name: "Louisiana",
    },
    {
        id: 22,
        code: "ME",
        countryConstID: 2,
        name: "Maine",
    },
    {
        id: 23,
        code: "MD",
        countryConstID: 2,
        name: "Maryland",
    },
    {
        id: 24,
        code: "MA",
        countryConstID: 2,
        name: "Massachusetts",
    },
    {
        id: 25,
        code: "MI",
        countryConstID: 2,
        name: "Michigan",
    },
    {
        id: 26,
        code: "MN",
        countryConstID: 2,
        name: "Minnesota",
    },
    {
        id: 27,
        code: "MS",
        countryConstID: 2,
        name: "Mississippi",
    },
    {
        id: 28,
        code: "MO",
        countryConstID: 2,
        name: "Missouri",
    },
    {
        id: 29,
        code: "MT",
        countryConstID: 2,
        name: "Montana",
    },
    {
        id: 30,
        code: "NE",
        countryConstID: 2,
        name: "Nebraska",
    },
    {
        id: 31,
        code: "NV",
        countryConstID: 2,
        name: "Nevada",
    },
    {
        id: 32,
        code: "NH",
        countryConstID: 2,
        name: "New Hampshire",
    },
    {
        id: 33,
        code: "NJ",
        countryConstID: 2,
        name: "New Jersey",
    },
    {
        id: 34,
        code: "NM",
        countryConstID: 2,
        name: "New Mexico",
    },
    {
        id: 35,
        code: "NY",
        countryConstID: 2,
        name: "New York",
    },
    {
        id: 36,
        code: "NC",
        countryConstID: 2,
        name: "North Carolina",
    },
    {
        id: 37,
        code: "ND",
        countryConstID: 2,
        name: "North Dakota",
    },
    {
        id: 38,
        code: "MP",
        countryConstID: 2,
        name: "Northern Mariana Islands",
    },
    {
        id: 39,
        code: "OH",
        countryConstID: 2,
        name: "Ohio",
    },
    {
        id: 40,
        code: "OK",
        countryConstID: 2,
        name: "Oklahoma",
    },
    {
        id: 41,
        code: "OR",
        countryConstID: 2,
        name: "Oregon",
    },
    {
        id: 42,
        code: "PA",
        countryConstID: 2,
        name: "Pennsylvania",
    },
    {
        id: 43,
        code: "RI",
        countryConstID: 2,
        name: "Rhode Island",
    },
    {
        id: 44,
        code: "SC",
        countryConstID: 2,
        name: "South Carolina",
    },
    {
        id: 45,
        code: "SD",
        countryConstID: 2,
        name: "South Dakota",
    },
    {
        id: 46,
        code: "TN",
        countryConstID: 2,
        name: "Tennessee",
    },
    {
        id: 47,
        code: "TX",
        countryConstID: 2,
        name: "Texas",
    },
    {
        id: 48,
        code: "UT",
        countryConstID: 2,
        name: "Utah",
    },
    {
        id: 49,
        code: "VT",
        countryConstID: 2,
        name: "Vermont",
    },
    {
        id: 50,
        code: "VA",
        countryConstID: 2,
        name: "Virginia",
    },
    {
        id: 51,
        code: "VI",
        countryConstID: 2,
        name: "Virgin Islands, U.S.",
    },
    {
        id: 52,
        code: "WA",
        countryConstID: 2,
        name: "Washington",
    },
    {
        id: 53,
        code: "WV",
        countryConstID: 2,
        name: "West Virginia",
    },
    {
        id: 54,
        code: "WI",
        countryConstID: 2,
        name: "Wisconsin",
    },
    {
        id: 55,
        code: "WY",
        countryConstID: 2,
        name: "Wyoming",
    },
    {
        id: 56,
        code: "XX",
        countryConstID: 2,
        name: "Unknown State",
    },
    {
        id: 57,
        code: "AB",
        countryConstID: 3,
        name: "Alberta",
    },
    {
        id: 58,
        code: "BC",
        countryConstID: 3,
        name: "British Columbia",
    },
    {
        id: 59,
        code: "MB",
        countryConstID: 3,
        name: "Manitoba",
    },
    {
        id: 60,
        code: "NB",
        countryConstID: 3,
        name: "New Brunswick",
    },
    {
        id: 61,
        code: "NL",
        countryConstID: 3,
        name: "Newfoundland",
    },
    {
        id: 62,
        code: "NT",
        countryConstID: 3,
        name: "Northwest Territories",
    },
    {
        id: 63,
        code: "NS",
        countryConstID: 3,
        name: "Nova Scotia",
    },
    {
        id: 64,
        code: "NU",
        countryConstID: 3,
        name: "Nunavut",
    },
    {
        id: 65,
        code: "ON",
        countryConstID: 3,
        name: "Ontario",
    },
    {
        id: 66,
        code: "PE",
        countryConstID: 3,
        name: "Prince Edward Island",
    },
    {
        id: 67,
        code: "QC",
        countryConstID: 3,
        name: "Quebec",
    },
    {
        id: 68,
        code: "SK",
        countryConstID: 3,
        name: "Saskatchewan",
    },
    {
        id: 69,
        code: "YT",
        countryConstID: 3,
        name: "Yukon",
    },
    {
        id: 70,
        code: "ACT",
        countryConstID: 16,
        name: "Australian Capital Territory",
    },
    {
        id: 71,
        code: "NSW",
        countryConstID: 16,
        name: "New South Wales",
    },
    {
        id: 72,
        code: "NT",
        countryConstID: 16,
        name: "Northern Territory",
    },
    {
        id: 73,
        code: "QLD",
        countryConstID: 16,
        name: "Queensland",
    },
    {
        id: 74,
        code: "SA",
        countryConstID: 16,
        name: "South Australia",
    },
    {
        id: 75,
        code: "TAS",
        countryConstID: 16,
        name: "Tasmania",
    },
    {
        id: 76,
        code: "VIC",
        countryConstID: 16,
        name: "Victoria",
    },
    {
        id: 77,
        code: "WA",
        countryConstID: 16,
        name: "Western Australia",
    },
    {
        id: 81,
        code: "",
        countryConstID: 76,
        name: "Guayas",
    },
    {
        id: 82,
        code: "BCS",
        countryConstID: 10,
        name: "Baja California Sur",
    },
    {
        id: 83,
        code: "AA",
        countryConstID: 2,
        name: "U.S. Armed Forces - Americas",
    },
    {
        id: 84,
        code: "AE",
        countryConstID: 2,
        name: "U.S. Armed Forces - Europe",
    },
    {
        id: 85,
        code: "AP",
        countryConstID: 2,
        name: "U.S. Armed Forces - Pacific",
    },
    {
        id: 89,
        code: "QR",
        countryConstID: 10,
        name: "QR",
    },
    {
        id: 90,
        code: "SEL",
        countryConstID: 139,
        name: "Selangor",
    },
];