import { Grid, Paper, Typography } from "@material-ui/core";
import { dateFormat } from "temp";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const OrderSearchResult = ({ order, phoneMode, companyMember }) => {
  let navigate = useNavigate();

  const renderStatus = (status) => {
    if (status === "Fulfilled") {
      return "status highlight green";
    } else if (status === "Issued") {
      return "status highlight blue";
    } else if (
      status === "Voided" ||
      status === "Expired" ||
      status === "Cancelled"
    ) {
      return "status highlight red";
    } else if (status === "In Progress") {
      return "status highlight purple";
    } else if (status === "Closed Short") {
      return "status highlight red";
    } else if (status === "Estimate") {
      return "status highlight blue";
    }
  };

  const renderOrderNumber = () => {
    if(phoneMode && companyMember) {
      return null;
    }

    return (
      <Grid item xs={2} md={1} className="result-name">
        <Typography noWrap variant="body2">
          {order.num}
        </Typography>
      </Grid>
    )
  }
  
  const renderCompanyName = () => {
    if(phoneMode && companyMember) {
      return null;
    }

    return (
      <Grid item xs={4} className="result-name">
        <Typography noWrap>
          {order.customerName}
        </Typography>
      </Grid>
    )
  }
  
  const renderDate = () => {
    if(phoneMode && !companyMember) {
      return null;
    }

    return (
      <Grid item xs={6} md={2}>
        {order.dateCreated ? dateFormat(order.dateCreated) : ""}
      </Grid>
    )
  }

  return (
    <Paper key={order.id} elevation={2} className="grid-search-result line-item" onClick={() => navigate(`/order-history/${order.num}`)}>
      <Grid container item xs={12} className="center-text" alignItems="center">
        {renderOrderNumber()}
        <Grid item md={1} xs={3}>
          <Typography noWrap variant="body2">
            {order.customerPO ? order.customerPO : ""}
          </Typography>
        </Grid>
        {renderDate()}
        {renderCompanyName()}
        <Grid item xs={3} md={2}>
          <div className={order.status ? renderStatus(order.status) : ""}>
              {order.status ? order.status : ""}
          </div>
        </Grid>
        {!phoneMode && <Grid item xs={2}>
          <div className="add-to-po" >
            <p className="hide-mobile" style={{color: "black", margin: 0}}>View</p>
            <i className="fas fa-chevron-right"></i>
          </div>
        </Grid>}
      </Grid>
    </Paper>
  );
}

export default OrderSearchResult;