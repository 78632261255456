const DefaultState = {
  loading: false,
  contacts: [],
  errorMsg: "",
};

export const contactReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case "GET_CONTACTS_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_CONTACTS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Eror Loading CONTACTS",
      };
    case "GET_CONTACTS_SUCCESS":
      return {
        ...state,
        loading: false,
        contacts: action.payload,
        errMsg: "",
      };
    default:
      return state;
  }
};

