import React from "react";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { makeAxiosCall } from "utils";
import { BuildOrderObject, OrderInterface } from "interfaces/Order";
import { UserContext } from "context/User";
import PendingOrderSummary from "./PendingOrderSummary";

const PO = () => {
  const { currentUser } = React.useContext(UserContext);
  const [order, setOrder] = React.useState<OrderInterface>();

  let isMounted = true;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentUser) {
      fetchOrder();
    }
    return () => {
      isMounted = false;
    }
  }, []);


  const fetchOrder = async () => {
    const orderId = window.location.pathname.split("/pending-orders/")[1];
    if (orderId) {
      const response = await makeAxiosCall(
        "get",
        `order/${currentUser.company.id}/${orderId}`
      ).catch(err => {
        console.error(err);
      });

      if (response.data) {
        let orderData = await BuildOrderObject(response.data);
        if (isMounted) {
          setOrder(orderData);
        }
      }
    }
  }

  return (
    <div className="admin view">
      <div>
        <Button
          type="submit"
          style={{ marginBottom: 20 }}
          variant="contained"
          color="primary"
          className="btn"
          onClick={() => navigate(-1)}
        >
          ‹ Back
        </Button>
      </div>
      <h1 className="reveal-text">
        PO - {order && order.customerPONum ? order.customerPONum : "N/A"}
      </h1>
      <PendingOrderSummary existingOrder={order} setExistingOrder={setOrder}/>
    </div>
  );
};

export default PO;
