import React, { useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeAxiosCall } from "utils";
import { BuildOrdersList, OrderInterface } from "interfaces/Order";
import { useWindowWidth } from "hooks/useWindowSize";
import DemoFeedbackList from "./DemoFeedbackList";
import DemoOrderList from "./DemoOrderList";
import { UserContext } from "context/User";
import { buildFeedbackListsFromRaw, DemoFeedbackInterface } from "interfaces/DemoFeedback";
import { BasicCompanyInterface } from "interfaces/Company";
import { BasicUserInterface, buildBasicUserInterfaceList } from "interfaces/User";
import Loading from "components/utils/Loading";
import NewDemoButton from "components/utils/NewDemoButton";

const Demos = () => {
    // Allow routing to specific tab
    const navigation = useLocation();
    let startingPage = "Feedback";
    if (navigation && navigation.state && navigation.state.startingPage) {
        startingPage = navigation.state.startingPage;
    }

    const { currentUser, internalUser } = React.useContext(UserContext);
    const [feedbackList, setFeedbackList] = React.useState<DemoFeedbackInterface[]>([]);
    const [allReps, setAllReps] = React.useState<BasicCompanyInterface[]>([]);
    const [allSalesTeamMembers, setAllSalesTeamMembers] = React.useState<BasicUserInterface[]>([]);
    const [activeMenu, setActiveMenu] = React.useState(startingPage);
    const [allOrders, setAllOrders] = React.useState<OrderInterface[]>();
    const [ordersLoaded, setOrdersLoaded] = React.useState<boolean>(false);
    const windowWidth = useWindowWidth();

    let isMounted = true;

    useEffect(() => {
        fetchDemoFeedback();
        fetchDemoOrders();

        const lastTab = localStorage.getItem("last-demo-tab");
        if (lastTab) {
            setActiveMenu(lastTab);
        }

        return () => {
            isMounted = false;
        };
    }, []);


    const saveActiveMenu = (tabName: string) => {
        localStorage.setItem("last-demo-tab", tabName);
        setActiveMenu(tabName);
    };

    const fetchDemoFeedback = async () => {
        let result;

        if(internalUser || (currentUser && currentUser.company && currentUser.company.companyType == "Internal")) {
            result = await makeAxiosCall(
                "get",
                `demo-feedback`
            ).catch(error => {
                console.error(error);
            });
        } else {
            result = await makeAxiosCall(
                "get",
                `demo-feedback/` + currentUser.company.id
            ).catch(error => {
                console.error(error);
            });
        }

        if(result && result.data) {
            let fullFeedbackList = buildFeedbackListsFromRaw(result.data.portal_demo_feedback);
            setFeedbackList(fullFeedbackList);
            setAllReps(result.data.reps);
            setAllSalesTeamMembers(buildBasicUserInterfaceList(result.data.users));
        }
    };

    const fetchDemoOrders = async () => {
        const response = await makeAxiosCall(
            "get",
            "demo-orders",
        );

        if (response.data) {
            const orderList = BuildOrdersList(response.data, []);
            setOrdersLoaded(true);
            if (isMounted) setAllOrders(orderList);
        }
    };

    const renderView = () => {
        if (activeMenu === "Orders") {
            return <DemoOrderList allOrders={allOrders} />;
        } else if (activeMenu === "Feedback") {
            return <DemoFeedbackList allFeedback={feedbackList} allReps={allReps} allSalesTeamMembers={allSalesTeamMembers} reloadFeedback={fetchDemoFeedback} />;
        }
    };

    return (
        <div className="admin view">
            <Grid container style={{ marginBottom: 10 }}>
                <Grid item md={10} xs={6}>
                    <h1 className="reveal-text">Demos</h1>
                </Grid>
                <Grid item md={2} xs={6}>
                    {internalUser || activeMenu === "Feedback" ? <Button
                        type="submit"
                        style={{ marginLeft: 20, marginTop: 15, width: "100%" }}
                        variant="contained"
                        color="primary"
                        className="btn"
                        component={Link}
                        to="/demo-form"
                    >
                        New Demo Feedback
                    </Button> : <NewDemoButton />}
                </Grid>
            </Grid>
            <div className="settings-bar" style={{paddingBottom: 24}}>
                <div
                    onClick={() => saveActiveMenu("Feedback")}
                    className={activeMenu === "Feedback" ? "item active" : "item"}
                >
                    Feedback
                </div>
                <div
                    onClick={() => saveActiveMenu("Orders")}
                    className={activeMenu === "Orders" ? "item active" : "item"}
                >
                    Orders
                </div>
            </div>
            {renderView()}
        </div>
    );
};

export default Demos;
