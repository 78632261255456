import React from "react";
import _ from "lodash";
import { Button, CircularProgress, Grid, GridSize, Paper, Typography } from "@material-ui/core";
import { makeAxiosCall } from "utils";
import { buildResellerInfo, ResellerApprovalFormInterface } from "interfaces/ResellerApprovalForm";
import "scss/components/Admin/ViewResellerInfoForm.scss";
import { AddressInterface } from "interfaces/Address";
import { ContactInterface } from "interfaces/Contact";
import { ArrowBack } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";


const ViewResellerInfoForm = () => {
    const formId = window.location.pathname.split("/view-reseller-info-form/")[1];
    const [formData, setFormData] = React.useState<ResellerApprovalFormInterface>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [errorMessage, setError] = React.useState<string | null>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        makeAxiosCall(
            "get",
            "reseller-info-form/" + formId
        ).then((response) => {
            if (response.data) {
                const newFormData = buildResellerInfo(response.data);
                console.log(newFormData);

                setFormData(newFormData);
                setLoading(false);
            } else {
                setLoading(false);
                setError("Failed to load reseller info form.");
            }
        });
    }, []);

    const renderFormField = (key: string, value: string, size: GridSize) => {
        return (
            <Grid item xs={12} md={size}>
                <div className="form-field">
                    <div className="form-data-label">{key}</div>
                    <div className="form-data-value">{value}</div>
                </div>
            </Grid>
        );
    };

    const renderBody = () => {
        return (
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="contained"
                        color="primary"
                        className="btn"
                    >
                        <ArrowBack />
                    </Button>
                </Grid>
                <Grid container className="form-block" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">Reseller Info Form</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2} className="form-paper">
                            <Grid container className="form-block" spacing={2}>
                                <Grid item xs={12}><Typography variant="h5" className="info-form-section-title">General</Typography></Grid>
                                {renderFormField("Legal Entity Name:", formData.legalEntityName, 12)}
                                {renderFormField("DBA(s):", formData.dbas, 12)}
                                {renderFormField("Business Type:", formData.businessType, 12)}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2} className="form-paper">
                            <Grid container className="form-block" spacing={2} direction="column">
                                <Grid item xs={12}><Typography variant="h5" className="info-form-section-title">Addresses</Typography></Grid>
                                <Grid item xs={12}>
                                    {_.map(formData.addresses, (address: AddressInterface) => {
                                        let typeString = "";
                                        for (let ati = 0; ati < address.addressTypes.length; ati++) {
                                            const type = address.addressTypes[ati];
                                            typeString += type.name + (ati < address.addressTypes.length - 1 ? " | " : "");
                                        }
                                        return (
                                            <Grid key={address.id} container className="address-block" spacing={2}>
                                                <Grid item xs={12}><Typography variant="h6">{typeString}</Typography></Grid>
                                                {renderFormField("Addressee:", address.contactName, 12)}
                                                {renderFormField("Street Address:", address.streetAddress, 12)}
                                                {renderFormField("Street Address cont.", address.streetAddressTwo, 12)}
                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        {renderFormField("City:", address.city, 3)}
                                                        {renderFormField("State:", address.state, 3)}
                                                        {renderFormField("Zip:", address.zip, 3)}
                                                        {renderFormField("Country:", address.country, 3)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2} className="form-paper">
                            <Grid container className="form-block" spacing={2} direction="column">
                                <Grid item xs={12}><Typography variant="h5" className="info-form-section-title">Contacts</Typography></Grid>
                                <Grid item xs={12}>
                                    {_.map(formData.contacts, (contact: ContactInterface) => {
                                        let typeString = "";
                                        for (let ati = 0; ati < contact.contactTypes.length; ati++) {
                                            const type = contact.contactTypes[ati];
                                            typeString += type.name + (ati < contact.contactTypes.length - 1 ? " | " : "");
                                        }
                                        return (
                                            <Grid key={contact.id} container className="contact-block" spacing={2}>
                                                <Grid item xs={12}><Typography variant="h6">{typeString}</Typography></Grid>
                                                {renderFormField("Title:", contact.title, 12)}
                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        {renderFormField("First Name:", contact.firstName, 6)}
                                                        {renderFormField("last Name:", contact.lastName, 6)}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={4}>
                                                        {renderFormField("Phone Number:", contact.phoneNumber, 6)}
                                                        {renderFormField("Email:", contact.email, 6)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2} className="form-paper">
                            <Grid container className="form-block" spacing={2} direction="column">
                                <Grid item xs={12}><Typography variant="h5" className="info-form-section-title">Marketing Info</Typography></Grid>
                                {renderFormField("What ways do you connect with customers?", formData.connections.join(", "), 12)}
                                {renderFormField("Do you utilize any third-part ad platforms?", formData.thirdPartyMarketing.join(", "), 12)}
                                {renderFormField("Primary Line of Business:", formData.primaryLineOfBusiness, 12)}
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        {renderFormField("Do you have a retail store?", formData.retailStore ? "Yes" : "No", 6)}
                                        {renderFormField("Do you have a showroom?", formData.showRoom ? "Yes" : "No", 6)}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}><Typography variant="h6">What percentage of your business is based in...</Typography></Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        {renderFormField("Production:", formData.percentProduction, 4)}
                                        {renderFormField("Theatrical:", formData.percentTheatrical, 4)}
                                        {renderFormField("Worship:", formData.percentWorship, 4)}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        {renderFormField("DJ:", formData.percentDJ, 4)}
                                        {renderFormField("Architectural:", formData.percentArchitectural, 4)}
                                        {renderFormField("Other:", formData.percentOther, 4)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        );
    };


    return (
        <Grid container spacing={1} alignItems="center" id="view-reseller-info-form-container">

            {loading ? (
                <CircularProgress
                    style={{ marginLeft: 20 }}
                    color="primary"
                    size={20}
                />
            ) : null}
            {errorMessage ? (
                <CircularProgress
                    style={{ marginLeft: 20 }}
                    color="primary"
                    size={20}
                />
            ) : null
            }
            {!loading && !errorMessage ? renderBody() : null}
        </Grid>
    );
};

export default ViewResellerInfoForm;