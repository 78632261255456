import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "@firebase/storage";
import "firebase/database";

const appConfig = {
  apiKey: "AIzaSyCDeTXQN8Zbqnw_m-mRCiUjmf9R3xJA0D8",
  authDomain: "dealerportal-2fb18.firebaseapp.com",
  databaseURL: "https://dealerportal-2fb18.firebaseio.com",
  projectId: "dealerportal-2fb18",
  storageBucket: "dealerportal-2fb18.appspot.com",
  messagingSenderId: "148462471446",
  appId: "1:148462471446:web:2bbb01249996edaa662837",
  measurementId: "G-TLB5SZ3EZD",
};

const atFullConfig = {
  apiKey: "AIzaSyAqoNGzv-Y2J7jC9tDyfThlQ44FTJX8SeQ",
  authDomain: "bcaa-54dfb.firebaseapp.com",
  databaseURL: "https://bcaa-54dfb.firebaseio.com",
  projectId: "bcaa-54dfb",
  storageBucket: "bcaa-54dfb.appspot.com",
  messagingSenderId: "405355586930",
  appId: "1:405355586930:web:db4b48e293565f5e",
};

const calcConfig = {
  apiKey: "AIzaSyApYoJGukNxGZyn3UBprNUIxxNg6EDhm5k",
  authDomain: "iriscalc.firebaseapp.com",
  databaseURL: "https://iriscalc.firebaseio.com",
  projectId: "iriscalc",
  storageBucket: "iriscalc.appspot.com",
  messagingSenderId: "535527900321",
  appId: "1:535527900321:web:c27c44a844821cd6",
};

export const atFullApp = firebase.initializeApp(atFullConfig, "secondary");
export const calcApp = firebase.initializeApp(calcConfig, "calc");
export const app = firebase.initializeApp(appConfig);

export const db = app.firestore();
export const storage = firebase.storage();
export const storageRef = storage.ref();
