import { buildContact, ContactInterface } from "./Contact";

export interface AddressInterface {
    contactName: string,
    streetAddress: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    addressTypes: AddressTypeInterface[],
    id?: number,
    streetAddressTwo?: string,
    additionalShippingDetails?: AdditionalShippingAddressDetailsInterface,
    stateId?: string | number, // Used in the form
    countryId?: string | number // Used in the form
    companyName?: string // Used in static addresses
}

export interface AdditionalShippingAddressDetailsInterface {
    id: number,
    hasTruckHeightLoadingDock: boolean,
    liftGateRequired: boolean,
    deliveryHoursMorning: boolean,
    deliveryHoursAfternoon: boolean,
    contact: ContactInterface,
    isCommercialLocation: boolean,
    isJobsiteLocation: boolean,
    locationNotes: string
}

export const DEFAULT_ADDITIONAL_SHIPPING_OPTIONS: AdditionalShippingAddressDetailsInterface = {
    id: 0,
    hasTruckHeightLoadingDock: false,
    liftGateRequired: false,
    deliveryHoursMorning: true,
    deliveryHoursAfternoon: true,
    contact: null,
    isCommercialLocation: true,
    isJobsiteLocation: false,
    locationNotes: ""
};

export interface AddressTypeInterface {
    id: number,
    name: string,
}

export const buildAddress = (rawAddress): AddressInterface => {
    if(!rawAddress) return null;
    let addressTypes = [];
    if(rawAddress.address_types) {
        addressTypes = rawAddress.address_types.map((type) => { return { id: type.type.id, name: type.type.name }; })
    } else {
        // static?
    }

    return {
        id: rawAddress.id,
        city: rawAddress.city,
        state: rawAddress.state,
        streetAddress: rawAddress.street_address,
        streetAddressTwo: rawAddress.street_address_2,
        zip: rawAddress.zip,
        country: rawAddress.country,
        contactName: rawAddress.addressee,
        addressTypes: addressTypes,
        additionalShippingDetails: buildAdditionalShippingDetails(rawAddress.additional_shipping_details),
        companyName: rawAddress.company_name
    };
};

export const buildAdditionalShippingDetails = (rawData): AdditionalShippingAddressDetailsInterface => {
    if (!rawData) return null;
    else {
        return {
            id: rawData.id,
            hasTruckHeightLoadingDock: rawData.has_truck_height_loading_dock,
            liftGateRequired: rawData.lift_gate_required,
            deliveryHoursMorning: rawData.delivery_hours_morning,
            deliveryHoursAfternoon: rawData.delivery_hours_afternoon,
            contact: buildContact(rawData.contact),
            isCommercialLocation: rawData.commercial_location,
            isJobsiteLocation: rawData.jobsite_location,
            locationNotes: rawData.location_notes
        };
    }
};

export const buildAddresses = (rawAddressList): AddressInterface[] => {
    const addresses: AddressInterface[] = [];
    if (!rawAddressList) return addresses;
    for (let rali = 0; rali < rawAddressList.length; rali++) {
        const rawAddress = rawAddressList[rali];
        addresses.push(buildAddress(rawAddress));
    }

    return addresses;
};

export const createEmptyAddress = (): AddressInterface => {
    return {
        contactName: "",
        streetAddress: "",
        streetAddressTwo: "",
        city: "",
        state: "",
        zip: "",
        country: "UNITED STATES",
        addressTypes: [],
        countryId: 2,
        stateId: 2
    };
};
