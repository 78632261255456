import React from "react";
import InventorySearch from "components/Products/InventorySearch";
import ModalBasic from "components/utils/ModalBasic";
import { Button, TextField } from "@material-ui/core";
import SuccessAni from "components/utils/SuccessAni";
import { CartContext } from "context/Cart";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

const CreatePO = () => {
  const [open, setOpen] = React.useState(false);
  const [prod, setProd] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [qty, setQty] = React.useState(1);
  const { addCartItem } = React.useContext(CartContext);

  const close = () => {
    setOpen(false);
    setSuccess(false);
  };

  const handleQty = (type) => {
    if (type === "dec" && qty !== 1) {
      setQty(qty - 1);
    } else if (type === "inc") {
      setQty(qty + 1);
    }
  };

  const handleAddToOrder = () => {

    addCartItem({
      quantity: qty,
      product: prod
    });

    setSuccess(true);
    setQty(1);
  };

  return (
    <div className="inventory view" style={{overflowY: "hidden"}}>
      <ModalBasic open={open} close={close}>
        <div className="add-prompt">
          <CSSTransition
            in={!success}
            unmountOnExit
            timeout={200}
            classNames="alert"
          >
            <React.Fragment>
              <h3>How Many?</h3>
              <div className="qty">
                <div className="dec" onClick={() => handleQty("dec")}>
                  -
                </div>
                <TextField
                  style={{ flex: 3 }}
                  label=""
                  margin="normal"
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 10000 } }}
                  onChange={(e) => {
                    let num = parseInt(e.target.value);
                    if (!isNaN(num)) {
                      setQty(num);
                    }
                  }}
                  onBlur={() => {
                    if (isNaN(qty) || qty < 1) {
                      setQty(1);
                    } else if (qty != Math.round(qty)) {
                      setQty(Math.round(qty));
                    }
                  }}
                  value={qty}
                />
                <div className="inc" onClick={() => handleQty("inc")}>
                  +
                </div>
              </div>
              <Button
                type="submit"
                style={{ width: "100%", marginTop: 20 }}
                variant="contained"
                color="primary"
                className="btn"
                onClick={() => handleAddToOrder()}
              >
                Add To Order
              </Button>
            </React.Fragment>
          </CSSTransition>

          <CSSTransition
            in={success}
            unmountOnExit
            timeout={200}
            classNames="alert"
          >
            <React.Fragment>
              <SuccessAni title="Added to Order"></SuccessAni>
              <div className="btn-container">
                <Button
                  type="submit"
                  style={{ width: "48%" }}
                  onClick={() => {
                    close();
                    setSuccess(false);
                  }}
                  variant="contained"
                  color="primary"
                  className="btn otl"
                >
                  Continue Shopping
                </Button>

                <Button
                  style={{ width: "48%" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn blue"
                  component={Link}
                  to="/checkout"
                >
                  Checkout
                </Button>
              </div>
            </React.Fragment>
          </CSSTransition>
        </div>
      </ModalBasic>
      <InventorySearch
        setOpenAddToCartModal={setOpen}
        addProductToCart={setProd}
        searchType="inventory-results"
      />
    </div>
  );
};

export default CreatePO;
