import React from "react";
import _ from "lodash";
import { CompanyInterface } from "interfaces/Company";
import { Grid } from "@material-ui/core";

interface CompanyResellersProps {
    company: CompanyInterface,
}

const CompanyResellers = ({ company }: CompanyResellersProps) => {

    const renderResellers = () => {
        return _.map(
            _.orderBy(company.resellers, ["name"], ["asc"]),
            (reseller) => {
                return (
                    <Grid key={reseller.id} container className="line-item" style={{ padding: "20px" }}>
                        <Grid item xs={12}>{reseller.name}</Grid>
                    </Grid>
                );
            }
        );
    };

    return (
        <>
            <div className="user-search">
                <h3>Associated Resellers</h3>
                <Grid container className="row-heads io">
                    <Grid item xs={12}>Name</Grid>
                </Grid>
                {renderResellers()}
            </div>
        </>
    );
};

export default CompanyResellers;