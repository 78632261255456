import React from "react";
import { storage } from "base.js";
import { Grid, InputLabel, MenuItem, FormControl, Select, TextField, Button, CircularProgress, Snackbar } from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import _ from "lodash";
import { makeAxiosCall } from "utils";
import { UserContext } from "context/User";
import { CompanyInterface } from "interfaces/Company";
import ModalBasic from "components/utils/ModalBasic";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { CompanyContext } from "context/Company";

interface DocumentUploadProps {
    company?: CompanyInterface,
    creationCallback?: () => void,
}

const DocumentUpload = ({ company, creationCallback }: DocumentUploadProps) => {
    const { currentUser, accountLevels } = React.useContext(UserContext);
    const { companyTypes } = React.useContext(CompanyContext);
    const [filesToUpload, setFilesToUpload] = React.useState<FileObject[]>([]);
    const [documentName, setDocumentName] = React.useState<string>("");
    const [category, setCategory] = React.useState<string>("Sales Reports");
    const [level, setLevel] = React.useState(accountLevels[3]);
    const [companyType, setCompanyType] = React.useState(companyTypes[2]);
    const [uploading, setUploading] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState<{ text: string, severity: Color }>({ text: "", severity: "error" });
    const [modal, setModal] = React.useState(false);

    const availableAccountLevels = React.useMemo(() => {
        return accountLevels.filter((level) => level.name !== "Super Admin");
    }, [accountLevels]);

    const uploadFile = async () => {

        if (filesToUpload.length > 0) {
            const file = filesToUpload[0];
            const str = file.file.name.substring(file.file.name.lastIndexOf(".") + 1);

            const filename = file.file.name.split(".")[0];
            const firebaseName = `${filename}-${Date.now()}.${str}`;

            const companyId = company?.id ?? "all";

            storage
                .ref(`/portal_company_documents/${companyId}/other_documents/${firebaseName}`)
                .put(file.file)
                .then((storageData) => {
                    storageData.ref.getDownloadURL().then(async (url) => {
                        const response = await makeAxiosCall(
                            "post",
                            "company-documents",
                            {
                                companyId: company?.id,
                                createdById: currentUser.uid,
                                accountLevelId: level.id,
                                companyTypeId: company ? null : companyType.id,
                                url: url,
                                name: documentName.length > 0 ? documentName : filename,
                                firebaseName: firebaseName,
                                category: category && category.length ? category : "Other"
                            }
                        );

                        if (response.status == 200) {
                            if (creationCallback) {
                                creationCallback();
                            }
                            closeModal();
                        }
                    });
                }, (error) => {
                    console.error("ERROR UPLOAD");
                    console.error(error);
                    setUploading(false);
                })
                .catch(error => {
                    console.error("ERROR UPLOADING");
                    console.error(error);
                    setUploading(false);
                    return;
                });
        } else {
            setUploading(false);
        }
    };

    const addFile = (files: FileObject[]) => {
        setFilesToUpload(files);
        setDocumentName(files[0].file.name);
    };

    const clearFile = () => {
        setFilesToUpload([]);
        setDocumentName("");
    };

    const closeModal = () => {
        setDocumentName("");
        setFilesToUpload([]);
        setLevel(accountLevels[3]);
        setUploading(false);
        setModal(false);
    };

    const _buildCategoryField = () => {
        return(<Autocomplete
            value={category}
            fullWidth={true}
            options={["Sales Reports", "Commission Reports", "Earnings", "Tools", "Other"]}
            freeSolo={true}
            onInputChange={(event, newValue) => {
                setCategory(newValue);
            }}
            getOptionLabel={(cat) => cat}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={"Document Category"}
                    variant="outlined"
                    fullWidth
                />
            )}
        />)
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        style={{ width: "100%", color: "white" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        Upload File
                    </Button>
                </Grid>
            </Grid>

            <ModalBasic
                open={modal}
                close={closeModal}
                unmountOnExit
            >
                <div style={{ padding: 25, margin: 10, maxWidth: 1000 }}>
                    <h3>Upload a Document</h3>
                    <Grid container className="address-line" style={{ textAlign: "center" }} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            {filesToUpload.length > 0 ? (
                                <div className="po-upload-container bordered-uploader">
                                    <p>{filesToUpload[0].file.name}</p>
                                    <Button
                                        onClick={clearFile}
                                    >
                                        Clear
                                    </Button>
                                </div>
                            ) : (
                                <div className="po-upload-container bordered-uploader">
                                    <DropzoneAreaBase
                                        maxFileSize={1000000000}
                                        filesLimit={1}
                                        dropzoneClass="file-uploader"
                                        fileObjects={filesToUpload}
                                        showPreviews={false}
                                        onAdd={addFile}
                                        onDropRejected={() => setSnackbar({ text: "There was an issue with that file.", severity: "error" })}
                                    />
                                </div>
                            )}

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Document Name"
                                value={documentName}
                                variant="outlined"
                                onChange={(e) => {
                                    setDocumentName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {_buildCategoryField()}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl
                                style={{ width: "100%" }}
                                className="state"
                                variant="outlined"
                            >
                                <InputLabel id="demo-simple-select-outlined-label" style={{ backgroundColor: "white" }}>
                                    Visible to
                                </InputLabel>
                                <Select
                                    onChange={(e: any) => setLevel(e.target.value)}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={level}
                                >
                                    {_.map(availableAccountLevels, (level) => {
                                        return (
                                            <MenuItem key={level.id} value={level}>
                                                {level.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        {!company &&
                            (<Grid item xs={12}>
                                <FormControl
                                    style={{ width: "100%" }}
                                    className="state"
                                    variant="outlined"
                                >
                                    <InputLabel id="demo-simple-select-outlined-label" style={{ backgroundColor: "white" }}>
                                        Company Type:
                                    </InputLabel>
                                    <Select
                                        onChange={(e: any) => setCompanyType(e.target.value)}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={companyType}
                                    >
                                        {_.map(companyTypes, (type) => {
                                            return (
                                                <MenuItem key={type.id} value={type}>
                                                    {type.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>)
                        }
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSnackbar({ ...snackbar, text: "" });
                                    setUploading(true);
                                    uploadFile();
                                }}
                                className="btn blue"
                            >
                                {uploading ?
                                    <CircularProgress
                                        color="inherit"
                                        size={25}
                                    /> :
                                    "Upload"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </ModalBasic>
            <Snackbar
                open={snackbar.text.length > 0}
                autoHideDuration={5000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }
                    setSnackbar({ ...snackbar, text: "" });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity}
                    style={{ textAlign: "center" }}
                >
                    {snackbar.text}
                </Alert>
            </Snackbar>
        </>
    );
};

export default DocumentUpload;