import React from "react";
import { Button, Grid, Paper, Snackbar, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, DialogActions } from "@material-ui/core";
import _ from "lodash";
import { makeAxiosCall, stringToMoney } from "utils";
import Loading from "components/utils/Loading";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import 'scss/components/Orders/PendingOrderSummary.scss';
import AdditionalShippingDetails from "components/utils/AdditionalShippingDetails";
import { Add, Help, More, Save, Visibility } from "@material-ui/icons";
import { BuildWebOrderObject, BuildWebOrderTransactionObjects, TaxDetailsInterface, WebOrderInterface, WebOrderItemInterface, WebOrderRefundInterface, WebOrderRefundTypeOptions, WebOrderTransactionInterface } from "interfaces/WebOrder";
import { useNavigate } from "react-router-dom";

interface WebOrderDetailsProps {
  orderBasic?: WebOrderInterface
}

const WebOrderDetails = ({ orderBasic }: WebOrderDetailsProps) => {
  const orderId = window.location.pathname.split("/web-orders/")[1];
  const [order, setOrder] = React.useState<WebOrderInterface>();
  const [activeRefund, setActiveRefund] = React.useState<WebOrderRefundInterface>(null);
  const [showRefundDetails, setShowRefundInfo] = React.useState(false);
  const [showDiscountInfo, setShowDiscountInfo] = React.useState(false);
  const [showTaxDetails, setShowTaxDetails] = React.useState(false);
  const [potentialSOs, setPotentialSOs] = React.useState<string[]>([]);
  const [linkedSONum, setLinkedSONum] = React.useState<string>("");
  const [newPaymentReferences, setNewPaymentReferences] = React.useState<string>("");
  const [transactionAttempts, setTransactionAttempts] = React.useState<WebOrderTransactionInterface[]>([]);
  const [showTransactionDetails, setShowTransactionDetails] = React.useState(false);

  const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };
  
  const [snackbar, setSnackbar] = React.useState(initialSnackbarState);

  let isMounted = true;

  let navigate = useNavigate();

  React.useEffect(() => {
    fetchOrderDetails();
    /* DISABLING THIS FOR NOW SO THAT THE SO NUMBER DOESN'T CHANGE IN CASE OF REFUNDS */
    // fetchCompanySOs();
  }, []);


  const fetchCompanySOs = async () => {

    let response = await makeAxiosCall(
      "get",
      `fishbowl-orders/1262`, // Get web orders from fishbowl
    ).catch((error) => {
      console.error(error);
    });

    if (isMounted) {
      if (response && response.data) {
        setPotentialSOs(_.map(
          _.orderBy(response.data, ['dateCreated'], ['desc']),
          (so) => so.num
        ));
      } else {
        setSnackbar({
          isOpen: true,
          message: "Failed to connect to Fishbowl",
          severity: "error",
        });
      }
    }
  }

    const fetchOrderDetails = async () => {
        let response = await makeAxiosCall(
            "get",
            `web-order/${orderId}`
        ).catch((error) => {
            console.error(error);
        });

        if (isMounted) {
            if (response && response.data) {
                let newOrderData = BuildWebOrderObject(response.data);
                setOrder(newOrderData);
                if(newOrderData.paymentReferences) setNewPaymentReferences(newOrderData.paymentReferences);
                if (newOrderData.soNumber) setLinkedSONum(newOrderData.soNumber);
                fetchTransactionAttempts(newOrderData.soNumber);
            } else {
                setSnackbar({
                isOpen: true,
                message: "Failed to load to web order",
                severity: "error",
                });
            }
        }
    }


    const fetchTransactionAttempts = async (soNumber: String) => {
        let response = await makeAxiosCall(
            "get",
            `web-order-transactions/${soNumber}`
        ).catch((error) => {
            console.error(error);
        });

        if (isMounted) {
            if (response && response.data) {
                let newTransactionData = BuildWebOrderTransactionObjects(response.data);
                setTransactionAttempts(newTransactionData);
            }
        }
    }

    const issueRefund = () => {
        navigate(`/create-refund/${orderId}`);
    }

    const toggleTaxDetails = () => {
        setShowTaxDetails(!showTaxDetails);
    }

    const saveWebOrder = () => {
        if(linkedSONum == order.soNumber && newPaymentReferences == order.paymentReferences) {
            setSnackbar({
                isOpen: true,
                message: "You didn't change anything",
                severity: "error"
            });
            return; // no change
        }

        makeAxiosCall(
            "post",
            ("web-order/" + order.id),
            { 
                soNumber: linkedSONum,
                paymentReferences: newPaymentReferences
            }
        ).then((res) => {
            if(res.data) {
                setOrder({...order, soNumber: linkedSONum, paymentReferences: newPaymentReferences});
            }
        }).catch((error) => console.log("Error:" + error));
    }


    const renderItems = () => {
        if (order) {
        return _.map(
            order.items,
            (o: WebOrderItemInterface, i) => {
            return (
                <Grid container item xs={12} className="line-item" key={`${o.id}-${i}`} style={{marginBottom: 8}}>
                    <Grid item sm={1} style={{textAlign: "center"}}>
                        <p>{o.quantity}</p>
                    </Grid>
                    <Grid item sm={5}>
                        <p style={{ fontWeight: "bold" }}>{o.productName}</p>
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        <p>{o.productSku}</p>
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        <p>{stringToMoney(o.price)}</p>
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        <p>{o.fulfillmentStatus}</p>
                    </Grid>
                </Grid>
            );
            }
        );
        }
    };

    const goToCustomer = () => {
        navigate(`/web-customer/${order.customer.id}`);
    }

    const renderDiscount = () => {
        if(order.discountCode && order.discountAmount) {
            return (<p><IconButton onClick={() => toggleDiscountDetails()}><Help /></IconButton><strong>Discount ({order.discountCode}): -{stringToMoney(order.discountAmount)}</strong></p>)
        }
    }

    const renderFinancialStatus = (status) => {
        if (status === "paid") {
            return "status highlight green";
        } else if (status === "refunded") {
            return "status highlight red";
        } else if (status === "partially_refunded") {
            return "status highlight purple";
        } else {
            return "status highlight subtle";
        }
    };

    const renderFulfillmentStatus = (status) => {
        if (status === "restocked") {
            return "status highlight blue";
        } else if (status === "fulfilled") {
            return "status highlight green";
        } else if (status === "unfulfilled") {
            return "status highlight red";
        } else if (status === "partial") {
            return "status highlight purple";
        } else {
            return "status highlight subtle";
        }
    };

    const toggleRefundDetails = (refund) => {
        setShowRefundInfo(!showRefundDetails);
        if(refund) setActiveRefund(refund);
    }

    const toggleDiscountDetails = () => {
        setShowDiscountInfo(!showDiscountInfo);
    }

    const renderRefunds = () => {
        if (order && order.refunds) {
        return _.map(
            order.refunds,
            (r: WebOrderRefundInterface, i) => {
            return (
                <p key={`${r.createdAt}-${i}`}><strong>
                    {(order.taxDetails.length > 0) && <IconButton onClick={() => toggleRefundDetails(r)}><Help /></IconButton>}
                    Refund: -{stringToMoney(r.totalRefundAmount)}
                </strong></p>
            );
            }
        );
        }
    };

    const renderTransactionDetailsButton = () => {
        if(transactionAttempts.length == 0) {
            return null;
        } else {
            return (<Button
                type="submit"
                style={{ width: 100, marginLeft: 6, color:  (transactionAttempts.length > 2 ? "white" : "black"), backgroundColor: (transactionAttempts.length > 2 ? "red" : "")}}
                variant={transactionAttempts.length > 2 ? "contained" : "outlined"}
                color="primary"
                onClick={() => {
                    setShowTransactionDetails(true);
                }}
            >
                Details
            </Button>)
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, isOpen: false });
    };

    const renderRefundDialog = () => {
        return (
            <Dialog
                open={showRefundDetails}
                onClose={() => toggleRefundDetails(null)}
            >
                <DialogTitle>
                    Refund Details
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    { activeRefund ?
                    <Grid container style={{color: "black"}}>
                        <Grid item xs={12}>
                            <p><strong>Issued by:</strong> {activeRefund.createdBy}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><strong>Issued on:</strong> {activeRefund.createdAt.toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p><strong>Refund type:</strong> {WebOrderRefundTypeOptions.find((t) => t.id == activeRefund.refundType).name}</p>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                (parseFloat(activeRefund.amount) > 0) ?
                                <p><strong>Refund Amount:</strong> {stringToMoney(activeRefund.amount)}</p> :
                                <p><h4>Item changes: </h4>{activeRefund.itemLevelNotes}</p>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {
                                activeRefund.recalculateTax ? 
                                <p>Tax was recalculated to be {stringToMoney(activeRefund.newTaxAmount)}</p> :
                                <p>The original tax amount was kept.</p>
                            }
                        </Grid>
    
                        <Grid item xs={12}>
                            <h4>Notes: </h4>
                            <p>{activeRefund.notes}</p>
                        </Grid>
    
                        <Grid container item xs={12} style={{borderTop: "1px solid lightgrey", marginTop: 12, paddingTop: 12, marginBottom: 12}}>
                            <Grid item xs={8} style={{textAlign: "right"}}>Total adjustment: </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>{stringToMoney(activeRefund.totalRefundAmount)}</Grid>
                        </Grid>
                    </Grid> : <p>You're not supposed to be here.</p>}
                </DialogContentText>
                </DialogContent>
            </Dialog>);
    }

    const renderTaxDialog = () => {
        return (
            <Dialog
                open={showTaxDetails}
                onClose={toggleTaxDetails}
            >
                <DialogTitle>
                    Tax Details
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    <Grid container style={{color: "black"}}>
                        <Grid container item xs={12} style={{borderBottom: "1px solid lightgrey", marginBottom: 12}}>
                            <Grid item xs={8}>Description</Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>Amount</Grid>
                        </Grid>
                        {_.map(
                            order.taxDetails,
                            (d: TaxDetailsInterface, i) => {
                                return (<Grid container item xs={12} key={'tax-details-' + i}>
                                    <Grid item xs={8}>{d.name}</Grid>
                                    <Grid item xs={4} style={{textAlign: "right"}}>{stringToMoney(d.value)}</Grid>
                                </Grid>)
                            }
                        )}
    
                        {order.customer && order.customer.taxExempt && <h3>Customer is marked as Tax Exempt</h3>}
    
                        <Grid container item xs={12} style={{borderTop: "1px solid lightgrey", marginTop: 12, paddingTop: 12, marginBottom: 12}}>
                            <Grid item xs={8} style={{textAlign: "right"}}>Total: </Grid>
                            <Grid item xs={4} style={{textAlign: "right"}}>{stringToMoney(order.taxes)}</Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
                </DialogContent>
            </Dialog>);
    }


    const renderTransactionDetailsDialog = () => {
        return (
            <Dialog
                open={showTransactionDetails}
                onClose={() => setShowTransactionDetails(false)}
            >
                <DialogTitle>
                    Transaction Details
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container style={{color: "black"}}>
                            <Grid container item xs={12} style={{borderBottom: "1px solid lightgrey", marginBottom: 12}}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={6}>Message</Grid>
                                <Grid item xs={3}>Date</Grid>
                                <Grid item xs={2} style={{textAlign: "right"}}>Auth.net ID</Grid>
                            </Grid>
                            {_.map(
                                transactionAttempts,
                                (d: WebOrderTransactionInterface, i) => {
                                    return (<Grid container item xs={12} key={'web-order-details-' + i}>
                                        <Grid item xs={1}>{d.authorizeResultCode == 1 ? <i className="far fa-check green"></i> : <i style={{ fontSize: "1em", color: "tomato" }} className="fa-duotone fa-do-not-enter"></i>}</Grid>
                                        <Grid item xs={6} style={{fontSize: 10}}>{d.errorMessage.length ? d.errorMessage : d.message}</Grid>
                                        <Grid item xs={3} style={{fontSize: 12}}>{d.createdAt.getMonth() + 1 + "/" + d.createdAt.getDate() + "/" + d.createdAt.getFullYear() + " " + d.createdAt.getHours() + ":" + d.createdAt.getMinutes() + ":" + d.createdAt.getSeconds() }</Grid>
                                        <Grid item xs={2} style={{textAlign: "right", fontSize: 12}}>{d.authorizeTransactionId}</Grid>
                                    </Grid>)
                                }
                            )}
                            {transactionAttempts.length > 0 ?
                            <><Grid container item xs={12} style={{borderTop: "1px solid lightgrey", marginTop: 12, paddingTop: 12, marginBottom: 12}}>
                                <Grid item xs={2} style={{textAlign: "right"}}>IP Address: </Grid>
                                <Grid item xs={10} style={{textAlign: "right"}}>{transactionAttempts[0].userIpAddress}</Grid>
                            </Grid>
                            <Grid container item xs={12} >
                                <Grid item xs={2} style={{textAlign: "right"}}>User ID: </Grid>
                                <Grid item xs={10} style={{textAlign: "right"}}>{transactionAttempts[0].userId ?? transactionAttempts[0].anonymousId}</Grid>
                            </Grid></>
                            : null}                      
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>);
    }

    const renderDiscountDialog = () => {
        return (
            <Dialog
                open={showDiscountInfo}
                onClose={toggleDiscountDetails}
            >
                <DialogTitle>
                    Discount: {order.discountCode}
                </DialogTitle>
                <DialogContent style={{minWidth: 500}}>
                    <DialogContentText>
                        <Grid container style={{color: "black"}}>
                            {order.discountNotes}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate(`/promotion/${order.discountId}`)} autoFocus>
                        Full Details
                    </Button>
                    <Button onClick={toggleDiscountDetails} autoFocus>
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>);
    }
    const calculateSubtotal = () => {
        let refundTotals = 0;
        if(order.refunds && order.refunds.length > 0) {
            for (let ri = 0; ri < order.refunds.length; ri++) {
                const refund = order.refunds[ri];
                refundTotals += parseFloat(refund.amount);
            }
        }
        return order.subtotal + order.discountAmount + refundTotals;
    }

  if (order) {
    return (
    <Grid container className="admin view" style={{paddingLeft: 60, paddingRight: 60}}>
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>


        {renderRefundDialog()}
        {renderDiscountDialog()}
        {renderTaxDialog()}
        {renderTransactionDetailsDialog()}

        <Grid item xs={12}>
            <h1 style={{fontSize: 34}}>Web Order <span style={{fontSize: 14}}>({new Date(order.createdAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})})</span></h1>
        </Grid>

        <Grid container style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
            <Grid container item xs={6} md={4} spacing={2}  style={{textAlign: "center"}}>
                <Grid item xs={12}>
                    <h3>Fishbowl</h3>
                </Grid>
                <Grid item md={12} xs={12}>

                    <TextField
                        fullWidth={true}
                        variant="outlined" 
                        label="SO Number"
                        style={{color: "black"}}
                        value={linkedSONum}
                        onChange={() => console.log('no')}
                    /> {/* DISABLING THIS FOR NOW SO THAT THE SO NUMBER DOESN'T CHANGE IN CASE OF REFUNDS */}
                    {/* <Autocomplete
                        fullWidth={true}
                        options={potentialSOs}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Select SO Number" />}

                        onChange={(e, v) => {
                            setLinkedSONum(v);
                        }}
                        onInputChange={(e, v) => {
                            setLinkedSONum(v);
                        }}
                        value={linkedSONum}
                    /> */}
                </Grid>
                {/* DISABLING THIS FOR NOW SO THAT THE SO NUMBER DOESN'T CHANGE IN CASE OF REFUNDS */}
                {/* <Grid item md={12} xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="btn"
                        fullWidth={true}
                        onClick={saveWebOrder}
                    >
                        <Save />
                    </Button>
                </Grid> */}
            </Grid>
            <Grid container item md={4} xs={3} style={{textAlign: "center"}} spacing={2} alignItems="center" >
                <Grid item xs={12}>
                    <h3 style={{textAlign: "center"}}>Financial</h3>
                </Grid>
                <Grid item xs={12}  className={order.financialStatus ? renderFinancialStatus(order.financialStatus) : ""} style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis", maxWidth: 200, margin: "auto" }}>
                    {order.financialStatus ? order.financialStatus.replace("_", " ") : "Unknown Status"}
                </Grid>
                <Grid item xs={12}>
                    <p>Paid: {order.paidAt ? new Date(order.paidAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"}) : "N/A"}</p>
                    {order.refundedAmount ? (<p>Refund Amount: {stringToMoney(order.refundedAmount)}</p>) : ""}
                </Grid>
            </Grid>
            <Grid container item md={4} xs={3} style={{textAlign: "center"}} spacing={2}>
                <Grid item xs={12}>
                    <h3>Fulfillment</h3>
                </Grid>
                <Grid item xs={12} className={order.fulfillmentStatus ? renderFulfillmentStatus(order.fulfillmentStatus) : ""} style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis", maxWidth: 200, margin: "auto" }}>
                    {order.fulfillmentStatus ? order.fulfillmentStatus.replace("_", " ") : "Unknown Status"}
                </Grid>
                <Grid item xs={12}>
                    <p>Fulfilled: {order.fulfilledAt ? new Date(order.fulfilledAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"}) : "N/A"}</p>
                </Grid>
            </Grid>
        </Grid>

        <Grid container alignItems="center" style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
            <Grid item container xs={12} alignItems="center">
                {order.orderNotes && <Grid item xs={12}>
                    <h3 style={{fontWeight: "bold"}}>
                        Order notes:
                    </h3>
                    
                    <TextField
                        label="Notes From Customer"
                        value={order.orderNotes}
                        onChange={(e) => null}
                        color={"primary"}
                        variant="outlined"
                        multiline={true}
                        fullWidth
                        
                    />
                </Grid>}
                {order.customer ? <Grid item xs={12}>
                    <h3 style={{fontWeight: "bold"}} onClick={goToCustomer}>
                        Ordered By: <span style={{fontWeight: "lighter"}}>{order.customer.firstName + " " + order.customer.lastName + " (" + order.customer.email + ")"}</span>
                        <IconButton onClick={goToCustomer}><Visibility /></IconButton>
                    </h3>
                    <h3 style={{fontWeight: "bold"}}>Ordered On: <span style={{fontWeight: "lighter"}}>{new Date(order.createdAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})}</span></h3>
                </Grid> : <Grid item xs={12}>
                    <h3 style={{fontWeight: "bold"}}>
                        Anonymous Order: <span style={{fontWeight: "lighter"}}>{order.anonymousCheckoutEmail}</span>
                    </h3>
                    <h3 style={{fontWeight: "bold"}}>Ordered On: <span style={{fontWeight: "lighter"}}>{new Date(order.createdAt).toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"})}</span></h3>
                </Grid>}

                <Grid item xs={12}>
                    <h3 style={{fontWeight: "bold"}}>
                        Checkout Attempts: <span style={{fontWeight: "lighter"}}>{transactionAttempts && transactionAttempts.length > 0 ? transactionAttempts.length : "N/A"}</span>
                        {renderTransactionDetailsButton()}
                    </h3>
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item container xs={12} spacing={2} style={{marginTop: 10, marginBottom: 10}}>
                    <Grid item container md={4} xs={12}>
                        <Paper className={"bordered-container"}>
                            <Grid item xs={12}>
                                <h3 className="center">Shipping Address</h3>  
                            </Grid>
                            <Grid item xs={12}>
                            <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                {order.shippingAddress.companyName && <li>{order.shippingAddress.companyName}</li>}
                                <li>{order.shippingAddress.contactName}</li>
                                <li>{order.shippingAddress.streetAddress}</li>
                                {order.shippingAddress.streetAddressTwo && (
                                    <li>{order.shippingAddress.streetAddressTwo}</li>
                                )}
                                <li>
                                    {order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.zip}
                                </li>
                                <li>{order.shippingAddress.country}</li>
                            </ul>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container xs={8}>
                        <div style={{padding: 15}}>
                            <Grid item xs={12}>
                                <h3 className="center">Carrier Requests</h3>  
                            </Grid>
                            <Grid item xs={12}>
                            {order.shippingPreference ? (
                                <ul style={{  margin: 0, padding: 0, listStyle: "none"}}>
                                    {order.shippingPreference.carrier && (<li>Carrier: {order.shippingPreference.carrier.name}</li>)}
                                    {order.shippingPreference.service && (<li>Service: {order.shippingPreference.service.name}</li>)}
                                    {order.shippingPreference.accountNumber && (<li>Account: {order.shippingPreference.accountNumber}</li>)}
                                </ul>
                            ) : (
                                <p>None</p>
                            )}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                <AdditionalShippingDetails address={order.shippingAddress} />

                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item container xs={12} style={{marginBottom: 20}} spacing={2}>
                    <Grid item container md={4} xs={12}>
                        <Paper className={"bordered-container"}>
                            <Grid item xs={12}>
                                <h3 className="center">Billing Address</h3>  
                            </Grid>
                            <Grid item xs={12}>
                            <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                {order.billingAddress.companyName && <li>{order.billingAddress.companyName}</li>}
                                <li>{order.billingAddress.contactName}</li>
                                <li>{order.billingAddress.streetAddress}</li>
                                {order.billingAddress.streetAddressTwo && (
                                    <li>{order.billingAddress.streetAddressTwo}</li>
                                )}
                                <li>
                                    {order.billingAddress.city}, {order.billingAddress.state} {order.billingAddress.zip}
                                </li>
                                <li>{order.billingAddress.country}</li>
                            </ul>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item container md={8} xs={12} style={{padding: 15}} justifyContent="space-between">
                        <Grid item xs={12}>
                            <h3 style={{ borderBottom: "1px solid gray", margin: 0, marginBottom: 10, display: "table" }}>
                                {order.paymentMethod}
                            </h3>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={10}>
                                <TextField 
                                    label={"Payment References"}
                                    value={newPaymentReferences}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="btn"
                                    fullWidth={true}
                                    onClick={saveWebOrder}
                                >
                                    <Save />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                label={"Payment ID"}
                                value={order.paymentId}
                                fullWidth={true}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <hr />
            </Grid>
            <Grid item container xs={12} className="row-heads">
                <Grid item sm={1} style={{textAlign: "center"}}>
                    <p>Qty</p>
                </Grid>
                <Grid item sm={5}>
                    <p>Name</p>
                </Grid>
                <Grid item sm={2} style={{textAlign: "center"}}>
                    <p>SKU</p>
                </Grid>
                <Grid item sm={2} style={{textAlign: "center"}}>
                    <p>Price</p>
                </Grid>
                <Grid item sm={2} style={{textAlign: "center"}}>
                    <p>Status</p>
                </Grid>
            </Grid>

            {renderItems()}

            <Grid container item xs={12}>
                <Grid item xs={12} style={{textAlign: "right"}}>
                    <strong>Subtotal: {stringToMoney(calculateSubtotal())}</strong>
                    {renderDiscount()}
                    {renderRefunds()}
                    <p>
                        {(order.taxDetails.length > 0) && <IconButton onClick={toggleTaxDetails}><Help /></IconButton>}
                        Tax: {stringToMoney(order.taxes)} {order.taxes > 0 ? "(" + (Math.round((order.taxes / (order.subtotal + order.shipping)) * 10000) / 100) + "%)" : ""}
                    </p>
                    <p>Shipping: {stringToMoney(order.shipping)}</p>
                    <h3>Total: {stringToMoney(order.total)}</h3>
                </Grid>
                <Grid item md={6} xs={12}>
                    
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={issueRefund}
                    >
                        Issue Refund
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    );
  } else {
    return (
      <Loading position={"relative"} title={""} height={"40vh"} />
    );
  }
};

export default WebOrderDetails;
