import React, { useContext } from "react";
import _ from "lodash";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@material-ui/core";
import Loading from "components/utils/Loading";
import PromotionSearchResult from "./PromotionSearchResult";
import { List, WindowScroller } from "react-virtualized";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "hooks/useWindowSize";
import "react-virtualized/styles.css";
import { buildPromotionsList, PromotionInterface } from "interfaces/Promotion";
import { makeAxiosCall } from "utils";
import { ProductContext } from "context/Product";
import { a } from "@react-spring/three";

interface PromotionSearchFiltersInterface {
    active: boolean,
    expired: boolean,
    upcoming: boolean,
    any: boolean,
    by_products: boolean,
    by_types: boolean
}

const PromotionSearch = () => {
    const [input, setInput] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [selectedFilters, setSelectedFilters] = React.useState<PromotionSearchFiltersInterface>(
        localStorage.getItem("promotion-filters") ? 
            JSON.parse(localStorage.getItem("promotion-filters")) : 
            {
                active: false,
                expired: false,
                upcoming: false,
                any: false,
                by_products: false,
                by_types: false
            }
    );
    const [filteredList, setFilteredList] = React.useState<PromotionInterface[]>([]);
    const [allPromotions, setAllPromotions] = React.useState<PromotionInterface[]>([]);
    const [sortMethods, setSortMethods] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);
    const windowWidth = useWindowWidth();
    const { products } = useContext(ProductContext);
    let navigate = useNavigate();

    const windowScrollerRef = React.useRef<WindowScroller>();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 800,
                width: 250,
            },
        },
    };

    const sortOptions = [
        {
            label: "Code (A - Z)",
            value: "discountCode",
            order: "asc",
        },
        {
            label: "Code (Z - A)",
            value: "discountCode",
            order: "desc",
        },
        {
            label: "Use (Low - High)",
            value: "useCount",
            order: "asc",
        },
        {
            label: "Use (High - Low)",
            value: "useCount",
            order: "desc",
        },
        {
            label: "Start Date (Old - New)",
            value: "startDate",
            order: "asc",
        },
        {
            label: "Start Date (New - Old)",
            value: "startDate",
            order: "desc",
        },
    ];

    React.useEffect(() => {
        if (allPromotions && allPromotions.length) {
            filterPromotions();
            setLoading(false);
        } else if(products && products.length) {
            fetchAllPromotions();
        }
    }, [allPromotions]);

    React.useEffect(() => {
        if (products && products.length && (!allPromotions || !allPromotions.length)) {
            fetchAllPromotions();
        }
    }, [products]);

    const fetchAllPromotions = () => {
        makeAxiosCall(
            "get",
            "promotions"
        ).then(async res => {
            const promotionList = buildPromotionsList(res.data, products);
            setAllPromotions(promotionList);
            setLoading(false);
        });
    }

    React.useEffect(() => {
        filterPromotions();
    }, [selectedFilters]);

    React.useLayoutEffect(() => {
        // Adjust the scroll view when filters are opened or closed
        if (windowScrollerRef && windowScrollerRef.current) {
            windowScrollerRef.current.updatePosition();
        }
    }, [showFilters]);

    const filterPromotions = () => {
        // Only apply filters once one is selected
        const applyFilters = _.some(selectedFilters, Boolean);

        if (applyFilters) {
            let active = [];
            let expired = [];
            let upcoming = [];
            let any = [];
            let byId = [];
            let byType = [];

            const today = new Date();

            if (selectedFilters.active) {
                active = allPromotions.filter((promotion) => promotion.endDate > today && promotion.startDate < today);
            }

            if (selectedFilters.expired) {
                expired = allPromotions.filter((promotion) => promotion.endDate < today);
            }

            if (selectedFilters.upcoming) {
                upcoming = allPromotions.filter((promotion) => promotion.startDate > today);
            }

            if (selectedFilters.any) {
                any = allPromotions.filter((promotion) => promotion.type == "any");
            }

            if (selectedFilters.by_products) {
                byId = allPromotions.filter((promotion) => promotion.type == "by_products");
            }

            if (selectedFilters.by_types) {
                byType = allPromotions.filter((promotion) => promotion.type == "by_types");
            }

            const returnable = _.union(active, expired, upcoming, any, byId, byType);

            setFilteredList(returnable);
        } else {
            setFilteredList(allPromotions);
        }
    };

    const sortPromotions = (searchResults: PromotionInterface[]) => {
        let returnable;

        if (sortMethods.length > 0) {
            const dateSorter = promotion => new Date(promotion.approvalStatus.lastUpdated);
            returnable = _.orderBy(
                searchResults,
                sortMethods.map((method) => method.substring(0, 4) == "Last" ?
                    dateSorter :
                    sortOptions.find((option) => option.label == method).value),
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
            );
        } else {
            returnable = _.orderBy(searchResults, ["discountCode"], ["asc"]);
        }

        return returnable;
    };

    const renderFilterOption = (option, label, tooltip) => {
        return (
            <Grid item xs={12} >
                <Tooltip title={tooltip} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedFilters[option]}
                                onChange={() => {
                                    const newFilters = {
                                        ...selectedFilters,
                                        [option]: !selectedFilters[option]
                                    };

                                    localStorage.setItem("promotion-filters", JSON.stringify(newFilters));
                                    setSelectedFilters(newFilters);
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={label}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderSortOptions = () => {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    key="sortMethods"
                    multiple
                    value={sortMethods}
                    renderValue={() => (
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {sortMethods.map((method) => (
                                <Chip key={method} label={method} style={{ marginRight: 5 }} />
                            ))}
                        </Box>
                    )}
                    onChange={(e: React.ChangeEvent<{
                        name?: string;
                        value: string[];
                    }>) => {
                        setSortMethods(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    {sortOptions.map(
                        (option) => {
                            const index = sortMethods.findIndex((n) => n == option.label);
                            return (
                                <MenuItem
                                    key={option.label}
                                    value={option.label}
                                >
                                    <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </FormControl>
        );
    };

    const searchResults = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        const hits = _.filter(filteredList, (promotion: PromotionInterface) => {
            return _.includes(promotion.discountCode.toLowerCase(), searchInput);
        });

        return sortPromotions(hits);
    }, [filteredList, input, sortMethods]);

    const renderRow = ({ index, key, style }) => {
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <PromotionSearchResult promotion={searchResults[index]} mobileMode={windowWidth < 700} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return <p className="body-message center-text">No Promotions Found</p>;
    };

    return allPromotions ? (
        <div className="grid-search">
            <Grid container>
                <Grid item xs={10}>
                    <h2 className="title">Promotions</h2>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={() => navigate('/new-promotion')}
                        variant="contained"
                        color="primary"
                    >
                        New Promotion
                    </Button>
                </Grid>
            </Grid>
            
            <div className="search-field">
                <TextField
                    label="Promotion Search"
                    variant="outlined"
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                />
            </div>
            <Grid container className="container-spacing">
                <Grid item container alignItems="center" xs={12}>
                    <Grid item md={9} xs={7}><h3>Filter and Sort {`(${searchResults.length})`}</h3></Grid>
                    <Grid item md={3} xs={5}>
                        <Button
                            variant={showFilters ? "contained" : "outlined"}
                            color={showFilters ? "primary" : "secondary"}
                            onClick={() => setShowFilters(!showFilters)}
                            fullWidth
                        >
                            {showFilters ? "Hide Filters" : "Show Filters"}
                        </Button>
                    </Grid>
                </Grid>
                {showFilters && (
                    <>
                        <Grid item container xs={9}>
                            <Grid item container alignContent="flex-start" xs={6}>
                                <Grid item xs={12}>Promotion Type</Grid>
                                {renderFilterOption("any", "Order", "Any Products")}
                                {renderFilterOption("by_products", "By Products", "Reseller Promotions")}
                                {renderFilterOption("by_types", "By Product Types", "By Product Types")}
                            </Grid>
                            <Grid item container alignContent="flex-start" xs={6}>
                                <Grid item xs={12}>Status</Grid>
                                {renderFilterOption("active", "Active", "Currently active promotion")}
                                {renderFilterOption("expired", "Expired", "The promotion is over")}
                                {renderFilterOption("upcoming", "Upcoming", "The promotion hasn't started yet")}
                            </Grid>
                        </Grid>
                        <Grid item container alignContent="flex-start" xs={3}>
                            <Grid item xs={12}>Sort By</Grid>
                            <Grid item xs={12}>
                                {renderSortOptions()}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container className="results">
                {windowWidth > 700 && <Grid container item xs={12} className="center-text grid-search-headers">
                    <Grid xs={3} item className="result-name">
                        Code
                    </Grid>
                    <Grid item xs={1}>
                        Uses
                    </Grid>
                    <Grid item xs={2}>
                        Disc. Type
                    </Grid>
                    <Grid item xs={2}>
                        Promo. Type
                    </Grid>
                    <Grid item xs={1}>
                        Starts
                    </Grid>
                    <Grid item xs={1}>
                        Ends
                    </Grid>
                    <Grid item xs={1}>
                        Active
                    </Grid>
                    <Grid item xs={1}>
                        View
                    </Grid>
                </Grid>}
                {loading ?
                    (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) :
                    (<WindowScroller ref={windowScrollerRef}>
                        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                            return <List
                                autoHeight
                                width={width}
                                height={height}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowHeight={windowWidth < 700 ? 90 : 60}
                                rowRenderer={renderRow}
                                noRowsRenderer={renderPlaceholder}
                                rowCount={searchResults.length}
                                overscanRowCount={3}
                            />;
                        }}
                    </WindowScroller>)
                }
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default PromotionSearch;
