import React from "react";
import _ from "lodash";

const SettingsBar = (props) => {
  const { menu } = props;
  if (menu) {
    return (
      <div className="settings-bar">
        {_.map(menu, (item, i) => {
          return (
            <div
              key={i}
              onClick={() => props.setActiveMenu(item.name)}
              className={
                props.activeMenu === item.name ? "item active" : "item"
              }
            >
              {item.title}
            </div>
          );
        })}
      </div>
    );
  } else return false;
};

export default SettingsBar;
