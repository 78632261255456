import React, { Component } from "react";

export default class OverviewTab extends Component {
  numberWithCommas = (x) => {
    if (x !== undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  renderPricing() {
    const { config, admin, distributorStatus } = this.props;
    let price = 0;
    if (admin || (distributorStatus && distributorStatus !== "pending")) {
      price = config.currentProd.price;
    } else {
      price = config.currentProd.price;
    }

    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  }
  renderScreenArea() {
    const { config } = this.props,
      width = config.panelWidthNeeded,
      height = config.panelHeightNeeded,
      actualWidth = (width * 500) / 25.3 / 12,
      actualHeight = (height * 500) / 25.3 / 12,
      screenArea = (actualHeight * actualWidth).toFixed(2),
      screenAreaInches = (screenArea * 12).toFixed(2),
      screenAreaCm = (screenArea * 30.48).toFixed(2),
      screenAreaMm = (screenArea * 304.8).toFixed(2);

    if (config.format === "In.") {
      return screenAreaInches + " " + config.format;
    } else if (config.format === "Ft.") {
      return screenArea + " " + config.format;
    } else if (config.format === "cm") {
      return screenAreaCm + " " + config.format;
    } else if (config.format === "mm") {
      return screenAreaMm + " " + config.format;
    }
  }
  render() {
    const { config } = this.props,
      { currentProd } = config;
    // console.log(config);
    // let flyBar1Weight = _.filter(this.state.addons, (addon) => {
    //   return addon.sku === "IRIS-FLYBAR-1";
    // }),
    // flyBar2Weight = _.filter(this.state.addons, (addon) => {
    //   return addon.sku === "IRIS-FLYBAR-2";
    // }),
    // flyBar1TotalWeight = flyBar1Weight[0].weight * config.singleBumpers,
    // flyBar2TotalWeight = flyBar2Weight[0].weight * config.dualBumpers;

    return (
      <div className="columns">
        <div className="column">
          <p>
            <span className="info-item"># of Panels Wide:</span>
            {config.panelWidthNeeded}
          </p>
          <p>
            <span className="info-item"># of Panels High:</span>
            {config.panelHeightNeeded}
          </p>
          <p>
            <span className="info-item">Total Panels Needed:</span>
            {config.totalPanelsNeeded}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Total Weight:</span>
            {this.numberWithCommas(config.totalWeight)} lbs.
          </p>
          <p>
            <span className="info-item">Price Per Panel:</span>
            {this.renderPricing()}
          </p>
          <p>
            <span className="info-item">Screen Area:</span>
            {this.renderScreenArea()}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Total Pixels:</span>
            {this.numberWithCommas(config.pixelsPerPanel)}
          </p>
          <p>
            <span className="info-item">Total Pixel Height:</span>
            {config.natResHeight}
          </p>
          <p>
            <span className="info-item">Total Pixel Width:</span>
            {config.natResWidth}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Circuits Needed(15A):</span>
            {config.cirNeeded}
          </p>
          <p>
            <span className="info-item">Power Consumption(Max W):</span>
            {this.numberWithCommas(config.powerConsumption)}
          </p>
          <p>
            <span className="info-item">Controllers Needed:</span>
            {config.conNeeded}
          </p>
        </div>
      </div>
    );
  }
}
