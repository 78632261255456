import React from "react";
import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, OutlinedInput, Paper, Select, Snackbar, Switch, Tab, Tabs, TextField } from "@material-ui/core";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "scss/components/Account/Wizard/ResellerInfo.scss";
import { makeAxiosCall } from "utils";
import { Alert, Autocomplete, Color, createFilterOptions, TabContext, TabPanel } from "@material-ui/lab";
import { BuildSinglePDProject, DefaultPDProgressChecklist, emptyPDProjectInterface, HardCodedFactoryList, HardCodedPDPeople, PDProductBaubleInterface, PDProgressProjectStatuses, PDProjectChecklistTypes, PDProjectInterface, PDProjectUpdateInterface } from "interfaces/ProductDevelopment";
import PDProjectBaubleList from "./FormComponents/SampleList";
import PDProjectCommunicationsList from "./FormComponents/ProjectCommunicationsList";
import PDProjectMediaList from "./FormComponents/MediaList";
import { ArrowBack, HelpOutline } from "@material-ui/icons";
import PDProjectChecklist from "./FormComponents/ProjectChecklist";
import { BasicUserInterface } from "interfaces/User";
import PDProductDimensions from "./FormComponents/ProjectProductDimensions";
import { ProductContext } from "context/Product";
import { ProductInterface } from "interfaces/Product";
import SingleProductDimensions from "components/Products/SingleProductDimensions";
import TechArea from "./FormComponents/TechArea";

const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };

const SinglePDProject = () => {
    const [project, setProject] = React.useState<PDProjectInterface>(emptyPDProjectInterface());
    const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
    const [loading, setLoading] = React.useState(true);
    const [editProjectInfo, setEditProjectInfo] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState<string>("1");
    const [showStatusHelp, setShowStatusHelp] = React.useState<boolean>(false);
    const [linkedProduct, setLinkedProduct] = React.useState<ProductInterface>();
    const [linkedProductInput, setLinkedProductInput] = React.useState<string>("");
    const [editLinkedProduct, setEditLinkedProduct] = React.useState<boolean>(false);
    const [savingLinkedProduct, setSavingLinkedProduct] = React.useState<boolean>(false);

    const { products } = React.useContext(ProductContext);

    const projectId = window.location.pathname.split("/pd/")[1];
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if(location && location.state && location.state.id) {
            setProject(location.state);
        }

        _fetchProject();
    }, []);

    React.useEffect(() => {
        if (project && project.product) {
            let foundProduct = products.find((prod) => prod.id == project.product.id);
            setLinkedProduct(foundProduct);
        }
    }, [project]);

    const _saveBauble = (bauble: PDProductBaubleInterface) => {
        let result;
        if(bauble.id == 0) {
            result = makeAxiosCall(
                "post",
                "pd/baubles/",
                {...bauble, projectId: projectId}
            );
        } else {
            result = makeAxiosCall(
                "post",
                "pd/baubles/" + bauble.id,
                bauble
            );
        }

        result.then(res => {
            setSnackbar({
                isOpen: true,
                message: bauble.name + " saved!",
                severity: "success"
            });

            _fetchProject()
        }).catch((e) => {
            console.log(e);
            setSnackbar({
                isOpen: true,
                message: "Something went wrong, check the logs",
                severity: "error"
            });
        });
    }

    const _fetchProject = () => {
        makeAxiosCall(
            "get",
            "pd/projects/" + projectId
        ).then(res => {
            setProject(BuildSinglePDProject(res.data));
            console.log(res.data);
        }).catch((e) => {
            console.log(e);
        });
    }

    const _showErrorText = (errorText: string) => {
        setSnackbar({
            isOpen: true,
            message: errorText,
            severity: "error"
        });
    }

  const formatPrice = (price: number) => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format((price));
    };

    const renderStatusHelpDialog = () => {
        return (
            <Dialog
                open={showStatusHelp}
                onClose={() => setShowStatusHelp(false)}
            >
                <DialogTitle>
                    Play Ball!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are two different statuses for a project <br />
                        <h3>1. Waiting vs Working</h3>
                        <p>Are we waiting on something or could we make progress here?</p>
                        <h3>2. Project Stage</h3>
                        <strong>At Bat</strong><br />
                        - In this stage we are identifying, researching, acquiring documents, ordering the first sample, making first contact with the factory.<br />
                        - This stage ends with us getting a first look at the sample and deciding if we want to move forward with it, or send it to the minor leagues.<br />
                        <br /><strong>First Base</strong><br />
                        - Here we are doing a deep dive on the product. Have tech look at it it, review the menu/profiles if applicable, etc.<br />
                        <br /><strong>Second Base</strong><br />
                        - Now that we have a full list of changes, work with the factory to actually bring the vision to reality.<br />
                        - This can be a black hole stage if we're not careful. <br />
                        <br /><strong>Third Base</strong><br />
                        - Now that the product is looking good functionally, it's time to beautify.<br />
                        - Work with marketing/design to prepare silk screens, logos, packaging, and/or whatever the product needs.<br />
                        <br /><strong>Home Stretch</strong><br />
                        - At this point the product should be ready for mass production.<br />
                        - Here we'll be issuing a PO, checking over the master pack, and do a final handoff.<br />
                        <br /><strong>The Dugout</strong><br />
                        - If we're not working on a project because it's done, or dead, send it to the dugout.<br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setShowStatusHelp(false)} autoFocus>
                        K
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const saveStatusChanges = () => {
        makeAxiosCall(
            "post",
            `pd/projects/` + project.id, 
            {
                isWaiting: project.isWaiting,
                status: project.status
            }
        ).catch((error) => {
            _showErrorText("Issue updating waiting status");
        });
    };

    const saveProjectChanges = () => {
        makeAxiosCall(
            "post",
            `pd/projects/` + project.id, 
            {...project, leadId: project.lead.id}
        ).catch((error) => {
            _showErrorText("Issue updating project info");
        });
    };

    const saveLinkedProduct = () => {
        setSavingLinkedProduct(true);
        makeAxiosCall(
            "post",
            `pd/projects/` + project.id,
            {
                ...project,
                productId: linkedProduct ? linkedProduct.id : -1,
            }
        ).catch((error) => {
            _showErrorText("Issue linking product");
            setSavingLinkedProduct(false);
        }).then((res) => {
            _fetchProject();
            setSavingLinkedProduct(false);
            setEditLinkedProduct(false);
        });
    }

    const _buildEditProjectInfo = () => {

        return (
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        aria-label="project-name"
                        label={"Project Name"}
                        variant="outlined"
                        fullWidth={true}
                        placeholder=""
                        value={project.name}
                        onChange={(e) => {
                            setProject({...project, name: e.target.value});
                            setEditProjectInfo(true);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    
                    <Autocomplete
                        value={project.factory}
                        fullWidth={true}
                        options={HardCodedFactoryList}
                        freeSolo={true}
                        onChange={(event, newValue) => {
                            setProject({...project, factory: newValue});
                            setEditProjectInfo(true);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Factory"}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        aria-label="project-price"
                        label={"Price per unit"}
                        variant="outlined"
                        type={"number"}
                        fullWidth={true}
                        placeholder="0"
                        value={project.price}
                        onChange={(e) => {
                            let newPrice = parseFloat(e.target.value);
                            if(!newPrice || newPrice < 0) {
                                newPrice = 0;
                            }
                            setProject({...project, price: newPrice});
                            setEditProjectInfo(true);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <h3>Est. Reseller: {formatPrice((project.price * 3.22))}</h3>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        value={project.lead}
                        fullWidth={true}
                        options={HardCodedPDPeople}
                        onChange={(event, newValue: BasicUserInterface) => {
                            setProject({ ...project, lead: HardCodedPDPeople.find((peep) => peep == newValue)});
                            setEditProjectInfo(true);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Project Lead"}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        renderOption={option => option.name}
                        getOptionLabel={option => option.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    
                    <TextField
                        aria-label="project-description"
                        label={"Description"}
                        variant="outlined"
                        fullWidth={true}
                        multiline={true}
                        placeholder="Enter any project specifics here...,
                        e.g., how did we hear about this, is this a direct replacement to an existing product, ."
                        value={project.description}
                        onChange={(e) => {
                            setProject({...project, description: e.target.value});
                            setEditProjectInfo(true);
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={8}>
                    
                </Grid>

                <Grid item xs={12} md={4}>
                    
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        style={{height: "100%"}}
                        disabled={!editProjectInfo}
                        onClick={() => {
                            saveProjectChanges();
                        }}
                    >
                        Save All Changes
                    </Button>
                </Grid>
        </Grid>);
        
    }

    const productFilter = createFilterOptions({
        stringify: (option: ProductInterface) => option.name.replace(/[^a-zA-Z\d\s\.\:]/g, ""),
    })

    const _buildAssociatedProductInfo = () => {
        return (
            <Grid container spacing={2} >
                <Grid item xs={12} md={9}>
                    <Autocomplete
                        value={linkedProduct}
                        fullWidth={true}
                        options={products}
                        onChange={(event, newValue: ProductInterface) => {
                            setLinkedProduct(newValue);
                            setEditLinkedProduct(true);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Linked Product"}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        renderOption={option => option.name}
                        getOptionLabel={option => option.name}
                        filterOptions={productFilter}
                        inputValue={linkedProductInput}
                        onInputChange={(event, newValue: string) => {
                            setLinkedProductInput(newValue.replace(/[^a-zA-Z\d\s\.\:]/g, ""));
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        style={{ height: "100%" }}
                        disabled={!editLinkedProduct}
                        onClick={() => {
                            saveLinkedProduct();
                        }}
                    >
                        {savingLinkedProduct ?
                            <CircularProgress
                                style={{ color: "white" }}
                            /> :
                            project.product != null ? "Update" : "Link Product"}
                    </Button>
                </Grid>

                {project.product && (
                    <Grid item container xs={12}>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12}>
                                <h3>Product Information</h3>
                            </Grid>
                            <Grid item xs={12}>
                                SKU: {project.product.sku}
                            </Grid>
                            <Grid item xs={12}>
                                UPC: {project.product.upc}
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12}>
                                <h3>Pricing</h3>
                            </Grid>
                            <Grid item xs={12}>
                                Reseller: {project.product.price ? formatPrice(project.product.price) : " N/A "}
                            </Grid>
                            <Grid item xs={12}>
                                MAP: {project.product.mapPrice ? formatPrice(project.product.mapPrice) : " N/A "}
                            </Grid>
                            <Grid item xs={12}>
                                MSRP: {project.product.msrpPrice ? formatPrice(project.product.msrpPrice) : " N/A "}
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <h3>Description</h3>
                        </Grid>
                        <Grid item xs={12}>
                            {project.product.description}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }

    const _buildGeneralTab = () => {
        return (
            <Grid container spacing={4}>
                {renderStatusHelpDialog()}
                <Grid item xs={12}>
                    <Paper style={{padding: 18}}>

                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <h2>Status</h2>
                            </Grid>
                            <Grid item xs={3} style={{margin: "auto", textAlign: "right"}}>
                                <Switch
                                    checked={project.isWaiting}
                                    onChange={(event) => setProject({...project, isWaiting: event.target.checked})}
                                    name="statusW"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />

                                <span style={{fontSize: "1.2em"}}>
                                    {project.isWaiting ? "Waiting" : "Working"}
                                </span>
                            </Grid>
                            <Grid item xs={5}>
                                <Select
                                    key={"project-status"}
                                    value={project.status.id}
                                    onChange={(e) => {
                                        setProject({...project, status: PDProgressProjectStatuses.find((s) => s.id == e.target.value)});
                                    }}
                                    input={<OutlinedInput />}
                                    style={{ width: "100%", textAlign: "left", height: "100%" }}
                                >
                                    {PDProgressProjectStatuses.map(
                                        (stat) => {
                                            return (
                                                <MenuItem
                                                    key={stat.id + "-dropdown-status-option"}
                                                    value={stat.id}
                                                >
                                                    {stat.name}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth={true}
                                    style={{height: "100%"}}
                                    onClick={() => {
                                        setShowStatusHelp(true);
                                    }}
                                >
                                    <HelpOutline />
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    style={{height: "100%"}}
                                    onClick={() => {
                                        saveStatusChanges();
                                    }}
                                >
                                    Save Status
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper style={{padding: 18}}>
                        <Grid container>
                            <Grid item container xs={12}>
                                <h2>Project Info</h2>
                            </Grid>

                            {_buildEditProjectInfo()}
                            
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper style={{ padding: 18 }}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={9}>
                                <h2>Associated Product</h2>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                {project.product && <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    style={{ marginBottom: 15 }}
                                    component={Link}
                                    to={`/products/${project.product.id}`}
                                    target="_blank"
                                >
                                    View Product
                                </Button>}
                            </Grid>
                        </Grid>

                        {_buildAssociatedProductInfo()}
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper style={{ padding: 18 }}>
                        {!project.product ? (
                            <Grid container>
                                <Grid item xs={12}>
                                    <h2>Product Dimensions:</h2>
                                </Grid>

                                <PDProductDimensions
                                    project={project}
                                    setProject={setProject}
                                    showErrorText={_showErrorText}
                                />
                            </Grid>) : (
                            <Grid container>
                                <SingleProductDimensions product={project.product} writeAccess={true} />
                            </Grid>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    const _buildBaublesTab = () => {
        return (<PDProjectBaubleList 
            project={project}
            onUpdate={(newSampleList: PDProductBaubleInterface[]) => setProject({...project, baubles: newSampleList})}
            newProjectForm={false}
            showErrorText={_showErrorText}
            saveBauble={_saveBauble}
        />);
    }

    const _buildCommunicationsTab = () => {
        return (<PDProjectCommunicationsList 
            project={project}
            addUpdate={(newUpdate: PDProjectUpdateInterface) => {
                makeAxiosCall(
                    "post",
                    "pd/updates/",
                    {...newUpdate, projectId: projectId} 
                ).then(res => {
                    setSnackbar({
                        isOpen: true,
                        message: "Project updated, thanks!",
                        severity: "success"
                    });
                    _fetchProject();
                }).catch((e) => {
                    _showErrorText("Failed to save update. Check logs and/or try again.")
                });
            }}
            deleteUpdate={(deadUpdate: PDProjectUpdateInterface) => {
                makeAxiosCall(
                    "delete",
                    "pd/updates/" + deadUpdate.id
                ).then(res => {
                    setSnackbar({
                        isOpen: true,
                        message: "Update deleted, thanks!",
                        severity: "success"
                    });
                    _fetchProject();
                }).catch((e) => {
                    _showErrorText("Failed to delete update. Check logs and/or try again.")
                });

                let newComsList = [];

                for (let cui = 0; cui < project.updates.length; cui++) {
                    const comUp = project.updates[cui];
                    if(comUp.id != deadUpdate.id) {
                        newComsList.push(comUp);
                    }
                }

                setProject({...project, updates: newComsList})
            }}
            showErrorText={_showErrorText}
        />);
    }


    const _buildMediaTab = () => {
        return (<Paper style={{padding: 16}}>
            <PDProjectMediaList 
                project={project}
                refreshData={_fetchProject}
                showErrorText={_showErrorText}
            />
        </Paper>);
    }

    const _buildProgressTab = () => {
        let progressChecklist = project.checklists.find((list) => list.type == PDProjectChecklistTypes.Progress);
        if (!progressChecklist) progressChecklist = DefaultPDProgressChecklist;
        return (<Paper style={{padding: 16}}>
            <PDProjectChecklist 
                checklist={progressChecklist}
                project={project}
                setProject={setProject}
                refreshData={_fetchProject}
            />
        </Paper>);
    }

    const _buildTechTab = () => {
        return (
          <Paper style={{padding: 16}}>
            <TechArea
                project={project}
                setProject={setProject}
                refreshData={_fetchProject}
            />
          </Paper>
        );
    }

    function tabProps(index: any) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        value: ""+index
      };
    }

    return (
        <div className="view">
            <Grid item xs={12}>
                <h1 style={{width: "100%"}}>
                    <Button
                        type="submit"
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            navigate('/pd');
                        }}
                        className="btn"
                        style={{marginRight: 20}}
                    >
                        <ArrowBack />
                    </Button>
                    
                    {project.name}
                </h1>
            </Grid>


            <TabContext value={currentTab}>
                <AppBar position="static">
                    <Tabs value={currentTab} onChange={(e, newTab) => {setCurrentTab(newTab); console.log(newTab);
                    }} aria-label="simple tabs example">
                        <Tab label="Updates" {...tabProps(1)} />
                        <Tab label="General" {...tabProps(0)} />
                        <Tab label="Samples" {...tabProps(2)} />
                        <Tab label="Progress" {...tabProps(3)} />
                        <Tab label="Tech" {...tabProps(4)} />
                        <Tab label="Media" {...tabProps(5)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={"0"}>
                    {_buildGeneralTab()}
                </TabPanel>
                <TabPanel value={"1"}>
                    {_buildCommunicationsTab()}
                </TabPanel>
                <TabPanel value={"2"}>
                    {_buildBaublesTab()}
                </TabPanel>
                <TabPanel value={"3"}>
                    {_buildProgressTab()}
                </TabPanel>
                <TabPanel value={"4"}>
                    {_buildTechTab()}
                </TabPanel>
                <TabPanel value={"5"}>
                    {_buildMediaTab()}
                </TabPanel>
            </TabContext>
            
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={30000}
                onClose={() =>  setSnackbar({ ...snackbar, isOpen: false })}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SinglePDProject;
