import React from "react";
import { db } from "base.js";
import _ from "lodash";

const Importer = () => {
  const [yo, setyo] = React.useState([]);

  React.useEffect(() => {
    db.collection("user")
      .get()
      .then((snapshot) => {
        let users = [];
        snapshot.forEach((doc) => {
          users.push(doc.data());
        });

        setyo(users);
      });
  }, []);

  const importIt = async () => {
    _.map(yo, (o, i) => {
      if (o.canOrder) {
        db.collection("user")
          .doc(o.uid)
          .update({ viewOrders: true })
          .then(() => console.log(`updated ${o.email}`))
          .catch((err) => console.log(err));
      }
    });
  };

  console.log(yo);
  return (
    <div className="">
      <button onClick={() => importIt()}>Import</button>
    </div>
  );
};

export default Importer;
