import React from "react";
import { AccordionDetails, AccordionSummary, Box, Button, Grid, Link, Typography } from "@material-ui/core";
import { UserContext } from "context/User";
import { Accordion } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import _ from "lodash";
import 'scss/components/Help/FAQ.scss';

interface FAQInterface {
    id: string,
    question: string,
    answer: string,
    details?: string,
    link?: string,
    linkText?: string,
    showRepInfo?: boolean
}

const FAQPage = () => {
    const { currentUser } = React.useContext(UserContext);
    let rep;

    if (currentUser.company && currentUser.company.rep) {
        rep = currentUser.company.rep;
    }

    const internalFAQs: Array<FAQInterface> = [
        // INFO FOUND HERE: https://docs.google.com/document/d/1fVlw9uDFi8JbIBXNyDmmZYgIadMwLl5Q AND https://docs.google.com/document/d/1ZtYt2Ab7u1jNzFrq1mUS9I0DoCDBJmLk/edit
        {
            id: "demo-internal",
            question: "How do I get a fixture to demo/show to a potential customer?",
            answer: "For internal use: Sales, Marketing, PD, Tech, etc.",
            details: `
1. Send an email to **orders@blizzardpro.com**
   * Include:
      * Product(s)
      * Quantity
      * Location (internal i.e. : Showroom)
      * Need by date
      * Return date (if applicable)
      * Name of requester (Accountable for:)
         * Product(s) condition
         * Accessories
         * Packaging
         * Reboxing
         * Return
2. Respect the product(s), a future customer will be spending their hard earned money to own it
3. Rebox the product(s) 
   * In their original box
   * With all of the original box contents
4. Send an email to **orders@blizzardpro.com**
   * If you provided a return date and reboxed the fixtures, the fixtures will be picked up and put away`
        },
        {
            id: "order-on-behalf-internal",
            question: "How do I place an order on behalf of a reseller?",
            answer: "From the Order page.",
            details: `
* Click the **Start an Order** button at the top right of the screen
* Select the company to order for from the dropdown, then click **Browse Products**
* **When ordering for another company, a PO file is required at the time the order is submitted!**`,
            link: "https://portal.blizzardpro.com/checkout",
            linkText: "Start An Order"
        }
    ];

    const generalFAQs: Array<FAQInterface> = [
        {
            id: "issues",
            question: "What if I have an issue with my product, order, shipment, etc.?",
            answer: "Submit a support ticket and our heroes in Tech Support will come to your aid.",
            link: "https://blizzardlightingllc.freshdesk.com/support/tickets/new",
            linkText: "Open a Ticket"
        },
        {
            id: "inventory",
            question: "How up to date are the inventory levels?",
            answer: "Inventory levels are refreshed every 15 minutes."
        },
        {
            id: "missing-order",
            question: "I just placed an order, but it doesn't show up in Order History.",
            answer: "The order history screen is separated into two sections: issued orders and pending orders.",
            details: `
* When you click on **Order History**, *only SOs in our inventory management software are shown*. An order placed on the portal will only show up here after the SO has been processed to that point.
* At the top of the **Order History** page there is a button to show pending and saved orders. All orders placed in the portal will show up here.
* New orders will only show up in the pending list until they are reviewed and entered into the system. After that, they will show up in both lists.`,
            link: "https://portal.blizzardpro.com/pending-orders",
            linkText: "Pending Orders"
        },
        {
            id: "blind-drop-ship",
            question: "How do I make a blind drop shipment?",
            answer: "During the checkout process",
            details: `
* During checkout, select **yes** when asked if the order is a **drop shipment**.
* A box will then appear where you can upload a packing list to be included in the order.`
        },
        {
            id: "b-stock",
            question: "What is B-Stock?",
            answer: "They are our used products, of which we have two types:",
            details: `
**Like New**\n
All fixtures are fully functional and have gone through our rigorous QC process.  They work and look like new, potentially having minor cosmetic damage.  Boxes may be generic.  Backed by Blizzard's full 2 year warranty.\n
**Factory Refurbished**\n
All fixtures are fully functional and have gone through our rigorous QC process.  They work like new with potential signs of wear and tear caused by use in demo, promotional content, or prior sale.  Boxes may be generic. Backed by Blizzard's full 2 year warranty.`,
            link: "https://portal.blizzardpro.com/checkout",
            linkText: "Start An Order"
        }
    ];

    const adminFAQs: Array<FAQInterface> = [
        {
            id: "invite-users",
            question: "How do I add people to my company?",
            answer: "From the My Company page.",
            details: `
* Navigate to the **Invite** screen by clicking on **My Company** in the sidebar, then clicking the **Invites** tab at the top of the screen.
* Click the blue **Invite User** button and enter the email address of the user you want to add
* Select the account level for the new user, then click **Invite**. An email to sign up will be sent to the email address entered. When they sign up, they will automatically be added to the company with the access chosen.`,
            link: "https://portal.blizzardpro.com/company",
            linkText: "My Company"
        },
        {
            id: "update-user-access",
            question: "How do I change a user's access level?",
            answer: "From the My Company page.",
            details: `
* Navigate to the **Members** screen by clicking on **My Company** in the sidebar.
* Click the blue **Edit User** button for the member you wish to edit.
* Select the new access level from the drop down, then click **Save**.`,
            link: "https://portal.blizzardpro.com/company",
            linkText: "My Company"
        },
        {
            id: "no-cards",
            question: "How do I add or update a card?",
            answer: "Under the Billing section of My Company",
            details: `
* If this is your first card, unfortunately at this time we require that you reach out to us to get into our payment processing system.
* Once you've been added, you can add or edit cards from the **Billing** tab under **My Company**`
        }
    ];

    const repFAQs: Array<FAQInterface> = [
        {
            id: "demo-rep",
            question: "How do I get a fixture to demo/show to a potential customer?",
            answer: "Indicate in your PO notes which item(s) are for demo",
            details: `
* Receive 40% discount off of reseller price
* MAX of 90 days, then must be returned or paid
* Shipping costs are added if the demo is being returned
* Blizzard will pay for shipping if:
   * You are keeping the demo
   * You sell the demo to a customer`
        },
        {
            id: "order-on-behalf",
            question: "How do I place an order for one of my resellers?",
            answer: "From the Order page.",
            details: `
* Click the **Start an Order** button at the top right of the screen
* Select the company to order for from the dropdown, then click **Browse Products**
* **When ordering for another company, a PO file is required at the time the order is submitted!**`,
            link: "https://portal.blizzardpro.com/checkout",
            linkText: "Start An Order"
        }
    ];

    const resellerFAQs: Array<FAQInterface> = [
        {
            id: "demo-admin",
            question: "How do I get a fixture to demo/show to a potential customer?",
            answer: "Reach out to your Rep for more details:",
            showRepInfo: true
        }
    ];


    const renderRepCard = () => {
        let rep: any = {
            id: 0,
            name: "Blizzard Lighting",
            contact: {
                email: "orders@blizzardpro.com",
                phoneNumber: "(414) 395-8365",
            }
        };

        if (currentUser.company && currentUser.company.rep) {
            rep = currentUser.company.rep;
        }

        return (
            <Grid item className="overview-card">
                <div
                    className="card"
                    style={{ minHeight: 250, width: 500, backgroundColor: "blue", margin: "10px auto"}}
                >
                    <div className="overlay"></div>
                    <div className="top-info">
                        <h2 style={{ textAlign: "center" }}>{rep.contact ? rep.contact.firstName : rep.name}<br /><span style={{ fontSize: ".7em" }}>Representative Contact</span></h2>
                        <div className="bottom-info">
                            <Grid container spacing={3}>
                                <Grid item container justifyContent="center" alignItems="center" style={{ textAlign: "center" }} xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <i className="fa-regular fa-envelope" style={{ fontSize: "1.25em", position: "unset" }}></i>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{!rep.contact.email || rep.contact.email.length === 0 ? "None" : <a href={"mailto:" + rep.contact.email} style={{ color: "white", borderBottom: "none" }}>{rep.contact.email}</a>}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container justifyContent="center" alignItems="center" style={{ textAlign: "center" }} xs={12} md={6}>
                                    <Grid item xs={12}>
                                        <i className="fa-solid fa-phone" style={{ fontSize: "1.25em", position: "unset" }}></i>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{!rep.contact.phoneNumber || rep.contact.phoneNumber.length === 0 ? "None" : rep.contact.phoneNumber}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Grid>
        );
    };

    const buildFAQLine = (faq: FAQInterface) => {

        return (<Accordion id={faq.id}>
            <AccordionSummary
                expandIcon={<i className="far fa-arrow-down"></i>}
                aria-controls={faq.id + "-content"}
                id={faq.id + "-sum"}
            >
                <h3>{faq.question}</h3>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container style={{borderTop: "1px solid lightgrey", paddingTop: 20, paddingBottom: 20}}>
                    <Grid item xs={12}>
                        <Typography>{faq.answer}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ReactMarkdown>{faq.details}</ReactMarkdown>
                    </Grid>
                </Grid>
            </AccordionDetails>
            {faq.link ? <Box textAlign='center' style={{paddingBottom: 20}}><Button
                    onClick={() => window.open(faq.link, "_blank")}
                    variant={"contained"}
                    color="primary"
                    className="btn"
                >
                {faq.linkText ?? "Link" }
                </Button></Box> : null}
            {faq.showRepInfo ? renderRepCard() : null}
        </Accordion>)
    }


    const buildInternalFAQs = () => {
        return (
        <Grid container className="faq-section">
            <Grid item xs={12}>
                <h2>Blizzard Internal</h2>
            </Grid>
            <Grid item xs={12}>
                {_.map(internalFAQs, buildFAQLine)}
            </Grid>
        </Grid>);
    }

    const buildRepFAQs = () => {
        return (
        <Grid container className="faq-section">
            <Grid item xs={12}>
                <h2>Blizzard Rep</h2>
            </Grid>
            <Grid item xs={12}>
                {_.map(repFAQs, buildFAQLine)}
            </Grid>
        </Grid>);
    }

    const buildResellerFAQs = () => {
        return (
        <Grid container className="faq-section">
            <Grid item xs={12}>
                <h2>Blizzard Reseller</h2>
            </Grid>
            <Grid item xs={12}>
                {_.map(resellerFAQs, buildFAQLine)}
            </Grid>
        </Grid>);
    }

    const buildAdminFAQs = () => {
        return (
        <Grid container className="faq-section">
            <Grid item xs={12}>
                <h2>Company Admin</h2>
            </Grid>
            <Grid item xs={12}>
                {_.map(adminFAQs, buildFAQLine)}
            </Grid>
        </Grid>);
    }

    const buildGeneralFAQs = () => {
        return (
        <Grid container className="faq-section">
            <Grid item xs={12}>
                <h2>General</h2>
            </Grid>
            <Grid item xs={12}>
                {_.map(generalFAQs, buildFAQLine)}
            </Grid>
        </Grid>);
    }
    

  
    return (
        <div className="view">
        <h1 className="reveal-text">Frequently Asked Questions</h1>
            { buildGeneralFAQs() }
            {currentUser.company.companyType == "Internal" ? buildInternalFAQs() : null}
            {currentUser.company.companyType == "Rep" || currentUser.company.companyType == "Internal" ? buildRepFAQs() : null}
            {currentUser.company.companyType == "Reseller" || currentUser.company.companyType == "Internal" ? buildResellerFAQs() : null}
            {currentUser.accountLevel.name.indexOf("Admin") > -1 ? buildAdminFAQs() : null}
        </div>
    );
    };

export default FAQPage;
