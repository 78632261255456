import React from "react";
import PaymentCard from "components/Account/PaymentCard";
import CCForm from "components/Account/CCForm";
import { CartContext } from "context/Cart";
import _ from "lodash";
import { UserContext } from "context/User";
import { makeAxiosCall } from "utils";
import { CompanyInterface } from "interfaces/Company";
import { buildCCDataListFromAuthorize, CCDataInterface, DefaultCCDataInterface } from "interfaces/Payment";
import Loading from "components/utils/Loading";
import "scss/components/Account/PaymentBook.scss";
import { Paper } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

interface PaymentBookPropsInterface {
    cart: boolean,
    title?: string,
    company?: CompanyInterface
}

const PaymentBook = ({ cart, title, company }: PaymentBookPropsInterface) => {
    const [showNewCCForm, setShowNewCCForm] = React.useState(false);
    const [showEditCCForm, setShowEditCCForm] = React.useState(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [currentCC, setCurrentCC] = React.useState<CCDataInterface>();
    const { currentUser } = React.useContext(UserContext);
    const [ccInfo, setCCInfo] = React.useState<Array<CCDataInterface>>();

    const [currentCompany, setCurrentCompany] = React.useState<CompanyInterface>(company ? company : currentUser.company);
    const { savePaymentOption } = React.useContext(CartContext);

    React.useEffect(() => {
        if (!company) {
            setCurrentCompany(currentUser.company);
        } else {
            setCurrentCompany(company);
        }

        fetchCC();
    }, [currentUser]);

    React.useEffect(() => {
        savePaymentOption(currentCC);
    }, [currentCC]);

    const fetchCC = () => {
        // Not getting CC info for now if they're not in the company.
        // if(currentCompany.id != currentUser.company.id) return;

        if (currentCompany.gatewayProfileId) {
            makeAxiosCall(
                "get",
                ("cc/" + currentCompany.gatewayProfileId)
            ).then((res) => {
                setLoading(false);
                if (res.data.profile) {
                    const loadedInfo = buildCCDataListFromAuthorize(res.data.profile, currentCompany.addresses, currentCompany.contacts);
                    console.log(loadedInfo);

                    setCCInfo(loadedInfo);
                    setCurrentCC(loadedInfo[0]);
                }

            }).catch((error) => console.log("Error:" + error));
        } else {
            setLoading(false);
        }
    };

    const handleCCSelect = (data) => {
        setCurrentCC(data);
    };

    const handleCCEdit = (data) => {
        if (currentCompany.id != currentUser.company.id) return;
        setCurrentCC(data);
        setShowEditCCForm(true);
    };

    const handleNewCard = () => {
        if (currentCompany.id != currentUser.company.id) return;
        setCurrentCC(null);
        setShowNewCCForm(true);
    };

    // if(currentCompany && currentCompany.id != currentUser.company.id) {
    //   return (
    //     <div className="payment-book">
    //         <div className="highlight green">
    //           <h3 style={{ marginBottom: 0 }} className="centered">
    //             It looks like you're making an order on behalf of {currentCompany.name}.
    //           </h3>
    //           <p>They will be charged according to their payment terms. If there are any payment issues or questions, we will reach out them.</p>
    //         </div>
    //     </div>);
    // }

    if (!loading && currentCompany && !currentCompany.gatewayProfileId) {
        return (
            <div className="payment-book">
                <Paper style={{
                    padding: 15,
                    backgroundColor: "#ffedc7"
                }}>
                    <h2><Warning /> You have no cards on file</h2>
                    
                    <p>
                        You have not yet set up a billing account. Any orders placed will not be fulfilled until a payment option is on file.
                        Once set up, you'll be able to add and edit your payment options here.<br /><br />
                        Choose one of the following methods to set up your billing account:
                        <ul>
                            <li><a href="https://form.jotform.com/blizzardlightingllc/credit-card-authorization-form" target="_blank">Fill out our secure form</a> <strong>(preferred)</strong></li>
                            <li>Email <a href="mailto:orders@blizzardpro.com">orders@blizzardpro.com</a></li>
                            <li>Call <a href="tel:(414) 395-8365">(414) 395-8365</a></li>
                        </ul>
                    </p>
                </Paper>
            </div>
        );
    }

    return (
        <React.Fragment>
            <h3 style={{ marginBottom: 20 }} className="centered">
                {title}
            </h3>

            {showNewCCForm ?
                <CCForm
                    company={currentCompany}
                    newCC={true}
                    selectedCC={DefaultCCDataInterface}
                    setActiveCC={handleCCSelect}
                    closeForm={() => {
                        setShowNewCCForm(false);
                        setShowEditCCForm(false);
                    }}
                /> :
                showEditCCForm ?
                    <CCForm
                        company={currentCompany}
                        newCC={false}
                        selectedCC={currentCC}
                        setActiveCC={handleCCSelect}
                        closeForm={() => {
                            setShowNewCCForm(false);
                            setShowEditCCForm(false);
                        }}
                    /> :

                    <div className="payment-book">
                        {loading ? <Loading height="40vh" title="" position="relative" /> :
                            <>
                                {cart ? (<div className="highlight green">
                                    <i className="far fa-bell"></i>
                                    {
                                        currentCompany.defaultPaymentTermsName === "Prepaid"
                                            ? "We will charge the card you select once the order is processed."
                                            : `Your account's payment terms is ${currentCompany.defaultPaymentTermsName} so no card is required at this time.`
                                    }
                                </div>) : <div></div>}
                                {!ccInfo ? (
                                    <div className="highlight neutral normal">
                                        <h2>You have no card on file</h2>
                                        <p>
                                            You can still submit this order but you must email <a href="mailto:orders@blizzardpro.com">orders@blizzardpro.com</a> or call <a href="tel:(414) 395-8365">(414) 395-8365</a> to set
                                            up your billing account with us before we are able to fulfill
                                            this order.
                                        </p>
                                    </div>
                                ) : (
                                    _.map(ccInfo, (cc: CCDataInterface) => {
                                        return (
                                            <PaymentCard
                                                active={
                                                    cart && currentCC && cc.card.authorizeCustomerPaymentProfileId === currentCC.card.authorizeCustomerPaymentProfileId
                                                }
                                                key={cc.card.authorizeCustomerPaymentProfileId}
                                                handleSelect={cart ? handleCCSelect : handleCCEdit}
                                                ccData={cc}
                                            />
                                        );
                                    })
                                )
                                }

                                {currentCompany.id == currentUser.company.id ? (
                                    <div onClick={() => handleNewCard()} className="card dotted">
                                        <i className="far fa-credit-card-front"></i>
                                        <p>{"Add Payment Method"}</p>
                                    </div>
                                ) : null}

                            </>}

                    </div>}
        </React.Fragment>
    );
};

export default PaymentBook;
