import React from "react";
import { Button, Grid, Snackbar, TextField, FormControl, Select, Box, OutlinedInput, MenuItem, Tooltip, FormControlLabel, Checkbox } from "@material-ui/core";
import _ from "lodash";
import { makeAxiosCall, stringToMoney } from "utils";
import Loading from "components/utils/Loading";
import { Alert, Color } from "@material-ui/lab";
import 'scss/components/Orders/PendingOrderSummary.scss';
import { BuildWebOrderObject, EmptyWebOrderRefund, WebOrderRefundTypeOptions, WebOrderInterface, WebOrderRefundInterface, WebOrderItemRefundInterface } from "interfaces/WebOrder";
import { useNavigate } from "react-router-dom";

const WebOrderCreateRefund = () => {
  const orderId = window.location.pathname.split("/create-refund/")[1];
  const [order, setOrder] = React.useState<WebOrderInterface>();
  const [refund, setRefund] = React.useState<WebOrderRefundInterface>(EmptyWebOrderRefund);

  const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };
  
  const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
  const [loading, setLoading] = React.useState(false);

  let isMounted = true;

  let navigate = useNavigate();

  React.useEffect(() => {
    fetchOrderDetails();
  }, []);

    const fetchOrderDetails = async () => {
        let response = await makeAxiosCall(
            "get",
            `web-order/${orderId}`
        ).catch((error) => {
            console.error(error);
        });

        if (isMounted) {
            if (response && response.data) {
                let newOrderData = BuildWebOrderObject(response.data);
                setOrder(newOrderData);
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Failed to load to web order",
                    severity: "error",
                });
            }
        }
    }

    const getNewTotal = () => {
        const newTax = getNewTax();
        const newShipping = getNewShipping();
        const newSubtotal = getNewSubtotal();

        let newTotal = newSubtotal + newShipping + newTax;

        return Math.round(newTotal * 100) / 100;
    }

    const getNewTax = () => {
        if(refund.amount && refund.refundType == "tax_refund") {
            let newAmount = parseFloat(refund.amount);
            if(!newAmount) {
                newAmount = 0;
            }

            return Math.round((order.taxes - newAmount) * 100) / 100;
        } else if(refund.recalculateTax && (order.taxes > 0)) {
            let currentRate = order.taxes / (order.subtotal + order.shipping);
            return Math.round((getNewSubtotal() + getNewShipping()) * currentRate * 100) / 100;
        } else {
            return order.taxes;
        }
    }


    const getNewShipping = () => {
        if(refund.amount && refund.refundType == "shipping_refund") {
            let newAmount = parseFloat(refund.amount);
            if(!newAmount) {
                newAmount = 0;
            }

            newAmount = Math.round((order.shipping - newAmount) * 100) / 100;
            return newAmount;
        } else if(refund.amount && refund.refundType == "order_level_adjustment") {
            let refundAmount = parseFloat(refund.amount);
            let remainder = 0;
            if(order.subtotal < refundAmount) {
                remainder = refundAmount - order.subtotal;
                if(remainder > 0) {
                    if(remainder >= order.shipping) {
                        return 0;
                    } else {
                        return order.shipping - remainder;
                    }
                }
            }
            return order.shipping;
        } else {
            return order.shipping;
        }
    }

    const getNewSubtotal = () => {
        if(refund.amount && refund.refundType == "order_level_adjustment") {
            let newAmount = parseFloat(refund.amount);
            if(!newAmount) {
                newAmount = 0;
            }

            newAmount = order.subtotal - newAmount;

            if(!refund.keepDiscount) newAmount = newAmount + order.discountAmount;

            newAmount = Math.round(newAmount * 100) / 100;
            if(newAmount < 0) return 0;
            return newAmount;
        } else if(refund.itemChanges.length > 0 && refund.refundType == "item_level_adjustment") {
            let newSubtotal = 0;
            for (let ii = 0; ii < refund.itemChanges.length; ii++) {
                const itemChange = refund.itemChanges[ii];
                newSubtotal += itemChange.newQuantity * itemChange.newPrice;
            }

            if(refund.keepDiscount) newSubtotal = newSubtotal - order.discountAmount;

            if(newSubtotal < 0) return 0;
            return Math.round(newSubtotal * 100) / 100;
        } else {
            if(!refund.keepDiscount) return Math.round((order.subtotal + order.discountAmount) * 100) / 100;
            return order.subtotal;
        }
    }

    const issueRefund = async () =>{
        if(loading) {
            setSnackbar({
                isOpen: true,
                message: "Already processing this refund.",
                severity: "error",
            });
            return;
        }
        if(!refund.refundType) {
            setSnackbar({
                isOpen: true,
                message: "What type of refund?",
                severity: "error",
            });
        } else {
            let newTotal = getNewTotal();
            console.log(newTotal);
            console.log(order.total);
            
            
            if(newTotal > order.total) {
                setSnackbar({
                    isOpen: true,
                    message: "Cannot charge more from a refund.",
                    severity: "error",
                }); 
            } else if(newTotal == order.total) {
                setSnackbar({
                    isOpen: true,
                    message: "No change in total.",
                    severity: "error",
                });
            }  else if(!refund.notes || !refund.notes.length) {
                setSnackbar({
                    isOpen: true,
                    message: "Please add notes in regards to this refund.",
                    severity: "error",
                });
            } else {
                // dewit
                setLoading(true);

                let newTotal = getNewTotal();

                makeAxiosCall(
                    "post",
                    "refund-web-order/" + order.id,
                    {
                        ...refund,
                        newTax: getNewTax(),
                        newShipping: getNewShipping(),
                        newSubtotal: getNewSubtotal(),
                        newTotal: newTotal,
                        totalRefund: Math.round((order.total - newTotal) * 100) / 100,
                        discountExisted: order.discountAmount > 0,
                        keepDiscount: refund.keepDiscount,
                        newDiscountAmount: refund.keepDiscount ? order.discountAmount : 0,
                        existingPaymentReferences: order.paymentReferences,
                        existingRefundedAmount: order.refundedAmount,
                        transactionId: order.paymentId,
                        soNumber: order.soNumber
                    },
                    { headers: { "Content-Type": "application/json" } }
                ).then((res) => {
                    setLoading(false);
                    if (res.status == 200) {
                        let message = "Refund Created";
                        if(res && res.data && res.data.message) {
                            message = res.data.message;
                        }
                        setSnackbar({
                            isOpen: true,
                            message: message,
                            severity: "success",
                        });
                        navigate(-1);
                    } else {
                        console.log(res);

                        let errorMessage = "Issue creating refund, check the logs";
                        if(res && res.data && res.data.error) {
                            errorMessage = res.data.error;
                        }
                        
                        setSnackbar({
                            isOpen: true,
                            message: errorMessage,
                            severity: "error",
                        });
                    }
                    console.log(res);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);

                    if(error && error.data && error.data.error) {
                        setSnackbar({
                            isOpen: true,
                            message: error.data.error,
                            severity: "error",
                        });
                    } else {
                        setSnackbar({
                            isOpen: true,
                            message: "Issue creating refund, check the logs",
                            severity: "error",
                        });
                    }
                });
            }
        }
    }


    const renderItems = () => {
        if (order) {
        return _.map(
            refund.itemChanges,
            (o: WebOrderItemRefundInterface, i) => {
            return (
                <Grid container item xs={12} className="line-item" key={`${o.originalItem.id}-${i}`} style={{marginBottom: 16}} spacing={2}>
                    <Grid item sm={5}>
                        <p style={{ fontWeight: "bold" }}>{o.originalItem.productName} ({o.originalItem.quantity} @ {stringToMoney(o.originalItem.price)})</p>
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        <TextField
                            key={o.originalItem.id + "newQty"}
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            value={o.newQuantity}
                            label={"New Qty"}
                            onBlur={(e) => {
                                let newItems: WebOrderItemRefundInterface[] = [];
                                let newAmount = parseFloat(e.target.value);

                                for (let rii = 0; rii < refund.itemChanges.length; rii++) {
                                    const item = refund.itemChanges[rii];
                                    if(item.itemId != o.itemId) {
                                        newItems.push(item);
                                    } else {
                                        if(!newAmount) newAmount = 0;

                                        if(newAmount > item.originalItem.quantity) newAmount = item.originalItem.quantity;

                                        newItems.push({...item, newQuantity: newAmount});
                                    }
                                }

                                setRefund({...refund, itemChanges: newItems});
                            }}
                            onChange={(e) => {
                                let newAmount = parseFloat(e.target.value);
                                if(!newAmount) {
                                    newAmount = 0;
                                }

                                let newItems = [];

                                for (let rii = 0; rii < refund.itemChanges.length; rii++) {
                                    const item = refund.itemChanges[rii];
                                    if(item.itemId != o.itemId) {
                                        newItems.push(item);
                                    } else {
                                        newItems.push({...item, newQuantity: newAmount})
                                    }
                                    
                                }

                                setRefund({...refund, itemChanges: newItems})
                            }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={3} style={{textAlign: "center"}}>
                        <TextField
                            key={o.originalItem.id + "newPrice"}
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            value={o.newPriceString}
                            label={"New Price"}
                            onBlur={(e) => {
                                let newItems: WebOrderItemRefundInterface[] = [];

                                for (let rii = 0; rii < refund.itemChanges.length; rii++) {
                                    const item = refund.itemChanges[rii];
                                    if(item.itemId != o.itemId) {
                                        newItems.push(item);
                                    } else {
                                        let newPrice = parseFloat(e.target.value);
                                        if(!newPrice) newPrice = 0;

                                        if(newPrice > item.originalItem.price)  newPrice = item.originalItem.price;

                                        newPrice = Math.round(newPrice * 100) / 100;

                                        newItems.push({...item, newPrice: newPrice, newPriceString: "" + newPrice});
                                    }
                                }

                                setRefund({...refund, itemChanges: newItems});
                            }}
                            onChange={
                                (e) => {
                                    let newItems: WebOrderItemRefundInterface[] = [];

                                    for (let rii = 0; rii < refund.itemChanges.length; rii++) {
                                        const item = refund.itemChanges[rii];
                                        if(item.itemId != o.itemId) {
                                            newItems.push(item);
                                        } else {
                                            newItems.push({...item, newPriceString: e.target.value})
                                        }
                                        
                                    }

                                    setRefund({...refund, itemChanges: newItems});
                                }
                            }
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            );
            }
        );
        }
    };

    const renderDiscount = () => {
        if(order.discountCode && order.discountAmount) {
            return (<p>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={refund.keepDiscount && (order.discountAmount > 0)}
                            onChange={() => {
                                setRefund({
                                    ...refund,
                                    keepDiscount: !refund.keepDiscount
                                });
                            }}
                            disabled={!order.taxes}
                            color="primary"
                            size="small"
                        />
                    }
                    label={"Keep Flat Discount?"}
                />
                <strong>Current Discount ({order.discountCode}): -{stringToMoney(order.discountAmount)}</strong>
            </p>);
        }
    }

    const renderDetails = () => {
        switch(refund.refundType) {
            case "tax_refund":
                return renderTaxRefundDetails();
            case "shipping_refund":
                return renderShippingRefundDetails();
            case "item_level_adjustment":
                return renderItemLevelAdjustmentDetails();
            case "order_level_adjustment":
            default:
                return renderOrderLevelAdjustmentDetails();
        }
    };

    const renderTaxRefundDetails = () => {
        if(order.taxes == 0) {
            return <h3>This order already doesn't have taxes</h3>
        }
        return (
            <Grid item xs={12}>
                <TextField
                    key="taxRefundAmount"
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    value={refund.amount}
                    label={"Tax Refund Amount"}
                    onBlur={(e) => {
                        let newAmount = parseFloat(e.target.value);
                        if(!newAmount) {
                            newAmount = 0;
                        }

                        newAmount = Math.round(newAmount * 100) / 100;

                        if(newAmount > order.taxes) newAmount = order.taxes;

                        setRefund(
                            {
                                ...refund,
                                amount: "" + newAmount
                            }
                        )
                    }}
                    onChange={
                        (e) => {
                            setRefund(
                                {
                                    ...refund,
                                    amount: e.target.value
                                }
                            )
                        }
                    }
                    margin="normal"
                    variant="outlined"
                />
            </Grid>
        );
    }

    const renderShippingRefundDetails = () => {
        if(order.shipping == 0) {
            return <h3>This order already doesn't have shipping</h3>
        }
        return (
            <Grid item xs={12}>
                <TextField
                    key="shippingRefundAmount"
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    value={refund.amount}
                    label={"Shipping Refund Amount"}
                    onBlur={(e) => {
                        let newAmount = parseFloat(e.target.value);
                        if(!newAmount) {
                            newAmount = 0;
                        }

                        newAmount = Math.round(newAmount * 100) / 100;

                        if(newAmount > order.shipping) newAmount = order.shipping;

                        setRefund(
                            {
                                ...refund,
                                amount: "" + newAmount
                            }
                        )
                    }}
                    onChange={
                        (e) => {
                            setRefund(
                                {
                                    ...refund,
                                    amount: e.target.value
                                }
                            )
                        }
                    }
                    margin="normal"
                    variant="outlined"
                />
            </Grid>
        );
    }

    const renderItemLevelAdjustmentDetails = () => {
        return (
            <Grid item xs={12}>
                {renderItems()}
            </Grid>
        );
    }

    const renderOrderLevelAdjustmentDetails = () => {
        return (
            <Grid item xs={12}>
                <TextField
                    key="refundAmount"
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    value={refund.amount}
                    label={"Refund Amount"}
                    onBlur={(e) => {
                        let newAmount = parseFloat(e.target.value);
                        if(!newAmount) {
                            newAmount = 0;
                        }

                        newAmount = Math.round(newAmount * 100) / 100;

                        if(newAmount > (order.subtotal + order.shipping)) newAmount = (order.subtotal + order.shipping);
                        newAmount = Math.round(newAmount * 100) / 100;

                        setRefund(
                            {
                                ...refund,
                                amount: "" + newAmount
                            }
                        )
                    }}
                    onChange={
                        (e) => {
                            setRefund(
                                {
                                    ...refund,
                                    amount: e.target.value
                                }
                            )
                        }
                    }
                    margin="normal"
                    variant="outlined"
                />
            </Grid>
        );
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, isOpen: false });
    };

  if (order) {
    return (
    <Grid container className="admin view" style={{paddingLeft: 60, paddingRight: 60}}>
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={30000}
          onClose={handleSnackbarClose}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Grid item xs={12}>
            <h1 style={{fontSize: 34}}>Refunding Web Order <span style={{fontSize: 14}}>({order.soNumber})</span></h1>
        </Grid>


        <Grid container style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
            <Grid container item xs={12} spacing={2} alignItems="center" >
                <Grid item xs={12}>
                    <h3>Refund Type</h3>
                </Grid>
                <Grid item xs={12}>
                    
                    <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 8 }}
                    >
                        <Select
                            style={{ width: "100%" }}
                            key="business-type"
                            label="Business Type"
                            value={refund.refundType}
                            renderValue={() => (
                                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                    {WebOrderRefundTypeOptions.find((t) => t.id == refund.refundType).name}
                                </Box>
                            )}
                            onChange={(e: React.ChangeEvent<{
                                name?: string;
                                value: string;
                            }>) => {
                                let newAmount = refund.amount;

                                let newItemChanges: WebOrderItemRefundInterface[] = [];

                                if(e.target.value == "tax_refund") {
                                    newAmount = "" + order.taxes;
                                } else if(e.target.value == "shipping_refund") {
                                    newAmount = "" + order.shipping;
                                } else if(e.target.value == "item_level_adjustment") {
                                    for (let oii = 0; oii < order.items.length; oii++) {
                                        const item = order.items[oii];
                                        newItemChanges.push({
                                            itemId: item.id,
                                            newPrice: item.price,
                                            newPriceString: "" + item.price,
                                            newQuantity: item.quantity,
                                            originalItem: item
                                        });
                                    }
                                }
                                

                                setRefund({...refund, refundType: e.target.value, amount: newAmount, itemChanges: newItemChanges});
                            }}
                            input={<OutlinedInput />}
                        >
                            {WebOrderRefundTypeOptions.map(
                                (t) => {
                                    return (
                                        <MenuItem
                                            key={t.id}
                                            value={t.id}
                                        >
                                            <i className={t.id == refund.refundType ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: t.id == refund.refundType ? "green" : "grey" }}></i>
                                            {t.name}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        <Grid container style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>

            <Grid container item xs={12} spacing={2} alignItems="center" >
                <Grid item xs={12}>
                    <h3>Details</h3>
                </Grid>

                {renderDetails()}

                <TextField
                    key="notes"
                    style={{ width: "100%", margin: 8 }}
                    multiline={true}
                    id="outlined-basic"
                    value={refund.notes}
                    label={"Notes"}
                    onChange={
                        (e) => {
                            setRefund(
                                {
                                    ...refund,
                                    notes: e.target.value
                                }
                            )
                        }
                    }
                    margin="normal"
                    variant="outlined"
                />
                <Grid item xs={12} style={{textAlign: "right"}}>
                    <p>Current Subtotal: {stringToMoney(order.subtotal + order.discountAmount)}</p>
                    {renderDiscount()}
                    <strong>New Subtotal: {stringToMoney(getNewSubtotal())}</strong>
                    <p>
                        Current Tax: {stringToMoney(order.taxes)} {order.taxes > 0 ? "(" + (Math.round((order.taxes / (order.subtotal + order.shipping)) * 10000) / 100) + "%)" : ""}
                    </p>

                    {refund.refundType != "tax_refund" && <Tooltip title={order.taxes > 0 ? "Recalculate taxes after this adjustment?" : "There are no taxes for this order"} placement="top" arrow>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={refund.recalculateTax && (order.taxes > 0)}
                                    onChange={() => {
                                        setRefund({
                                            ...refund,
                                            recalculateTax: !refund.recalculateTax
                                        });
                                    }}
                                    disabled={!order.taxes}
                                    color="primary"
                                    size="small"
                                />
                            }
                            label={"Recalculate Tax?"}
                        />
                    </Tooltip>}
                    {order.taxes > 0 && <strong>New Tax: {stringToMoney(getNewTax()) + (refund.recalculateTax && order.taxes > 0 ? "*" : "") }</strong>}
                    <p>Shipping: {stringToMoney(order.shipping)}</p>
                    {order.shipping > 0 && refund.refundType == "shipping_refund" && <strong>New Shipping: {stringToMoney(getNewShipping())}</strong>}
                    <h3 style={{fontWeight: "lighter"}}>Current Total: {stringToMoney(order.total)}</h3>
                    <h3>New Total: {stringToMoney(getNewTotal())}</h3>
                    <h2>Final Adjustment: {stringToMoney(order.total - getNewTotal())}</h2>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={issueRefund}
                    >
                        Issue Refund
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    );
  } else {
    return (
      <Loading position={"relative"} title={""} height={"40vh"} />
    );
  }
};

export default WebOrderCreateRefund;
