import React from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { SideDrawerTab, ViewContext } from "context/View";
import { UserContext } from "context/User";
import { getSO } from "actions/getSO";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { RootState } from "reducers";
import Loading from "components/utils/Loading";

const RecentOrders = () => {
    const { saveCurrentPanel } = React.useContext(ViewContext);
    const dispatch = useDispatch();
    const so = useSelector((state: RootState) => state.so);
    const { currentUser } = React.useContext(UserContext);
    const [orders, setOrders] = React.useState<any>();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (currentUser.company.companyType === "Rep") {
            dispatch(getSO(currentUser.company.fishbowlCustomerId, "rep"));
        } else {
            dispatch(getSO(currentUser.company.fishbowlCustomerId, "reseller"));
        }
    }, [currentUser]);

    React.useEffect(() => {
        setOrders(so.orders);
    }, [so]);

    React.useEffect(() => {
        if (orders) {
            setLoading(false);
        }
    }, [orders]);

    return (
        <Paper style={{ padding: 15, height: "100%", minHeight: 300, overflow: "scroll", borderRadius: 10 }} >
            <Grid container alignItems="center" >
                <Grid item xs={6} xl={9}>
                    <h3 className="block-title reveal-text" style={{ padding: "5px" }}>Recent Orders</h3>
                </Grid>
                <Grid item xs={6} xl={3}>
                    <Link to="order-history">
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            className="btn blue"
                            onClick={() => saveCurrentPanel(SideDrawerTab.orders)}
                        >
                            All Orders
                        </Button>
                    </Link>
                </Grid>
                <Grid item container xs={12} style={{ padding: "0 10px", fontWeight: "bold" }}>
                    <Grid item xs={4}>Order #</Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>Total</Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>Status</Grid>
                </Grid>
                <Grid item container xs={12} >
                    {!loading ? (orders && orders.length > 0 ? _.map(
                        _.orderBy(orders, [(result) => result.num], ["desc"]),
                        (o, i) => {
                            if (i < 5) {
                                return (
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        key={i}
                                        className="line-item home-so"
                                        style={{ margin: "6px 0" }}
                                        component={Link}
                                        to={`/order-history/${o.num}`}
                                    >
                                        <Grid item xs={4} >{o.num}</Grid>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            }).format(o.totalPrice)}
                                        </Grid>
                                        <Grid item xs={4} style={{ textAlign: "center" }}>{o.status && o.status}</Grid>
                                    </Grid>
                                );
                            }
                        }
                    ) : <h2 style={{ margin: "20px auto" }}>No Orders Found</h2>) : <Loading height="20vh" position="relative" title="" />}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RecentOrders;
