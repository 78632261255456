import React from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { ProductInterface } from "interfaces/Product";

interface SingleProductDimensionsProps {
  product: ProductInterface,
  writeAccess: boolean,
}

const SingleProductDimensions = ({ product, writeAccess }: SingleProductDimensionsProps) => {

  const [metric, setMetric] = React.useState(false);

  const renderCard = (title: string, length: number, width: number, height: number, weight: number) => {
    return (
      <Grid item container xs={12} md={4}>
        <Grid item xs={12}>
          <h3 style={{ 
            marginBottom: 0,
            paddingBottom: 12,
            marginRight: 15,
            marginLeft: 15,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
          }}>
            {title}
          </h3>
        </Grid>
        {renderDimensions(length, width, height, weight)}
      </Grid>
    );
  }

  const renderDimensions = (length: number, width: number, height: number, weight: number) => {

    const metricLength = length ? (length * 2.54).toFixed(1) : 0;
    const metricWidth = width ? (width * 2.54).toFixed(1) : 0;
    const metricHeight = height ? (height * 2.54).toFixed(1) : 0;
    const metricWeight = weight ? (weight / 2.205).toFixed(1) : 0;

    if(!length && !width && !height && !weight) return (
      <Grid item xs={12} style={{textAlign: "center"}}>
        N/A
      </Grid>
    );
    
    return metric ? (
      <Grid item xs={12} style={{paddingLeft: 16, paddingTop: 6}}>
          <p
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {metricLength ? (
              `Length: ${metricLength} cm.`
            ) : (
              "N / A"
            )}
          </p>
          <p 
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {metricWidth ? (
              `Width: ${metricWidth} cm.`
            ) : (
              "N / A"
            )}
          </p>
          <p 
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {height ? (
              `Height: ${metricHeight} cm.`
            ) : (
              "N / A"
            )}
          </p>
          {metricWeight ? <p 
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            Weight: {metricWeight} kg.
          </p> : null}
      </Grid> 
    ) : (
      <Grid item xs={12} style={{paddingLeft: 16, paddingTop: 6}}>
          <p
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {length ? (
              `Length: ${length} in.`
            ) : (
              "N / A"
            )}
          </p>
          <p 
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {width ? (
              `Width: ${width} in.`
            ) : (
              "N / A"
            )}
          </p>
          <p 
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {height ? (
              `Height: ${height} in.`
            ) : (
              "N / A"
            )}
          </p>
          {weight ? <p 
            style={{
              fontSize: 18,
              marginTop: 10,
            }}
          >
            Weight: {weight} lbs.
          </p> : null}
      </Grid> 
    );
  }

  const handleChange = () => {
    setMetric(!metric);
  }

  return (
    <>
      <Grid item xs={12} container alignItems="center">
        <Grid item xs={12} md={8}>
          <h2>Product Dimensions</h2>
        </Grid>

        <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
          <FormControlLabel 
            control={
              <Switch
                color="primary"
                checked={metric}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'view metric' }} 
              />
            } 
            label={"View in metric"}
          />
        </Grid>
      </Grid>

      {renderCard(
        "Assembled", 
        product.dimensions?.assembledLength, 
        product.dimensions?.assembledWidth, 
        product.dimensions?.assembledHeight, 
        product.dimensions?.assembledWeight
      )}

      {renderCard(
        "Single Pack", 
        product.dimensions?.length, 
        product.dimensions?.width, 
        product.dimensions?.height, 
        product.dimensions?.weight
      )}

      {renderCard(
        `Master Pack${product.dimensions?.masterPackQuantity ? ` - ${product.dimensions.masterPackQuantity} Units` : ""}`,
        product.dimensions?.masterPackLength,
        product.dimensions?.masterPackWidth,
        product.dimensions?.masterPackHeight,
        product.dimensions?.masterPackWeight,
      )}

      {writeAccess && (
        <Grid item xs={12} style={{ textAlign: "center", fontStyle: "italic" }}>
          <p>Dimensions should be updated in Fishbowl</p>
        </Grid>
      )}
    </>
  )
}

export default SingleProductDimensions;