
// agreement: hsCompanyData.properties.reseller_agreement_portal_ != undefined && hsCompanyData.properties.reseller_agreement_portal_.value === 'true',
// cert: hsCompanyData.properties.reseller_certificate_submitted_portal_ != undefined  && hsCompanyData.properties.reseller_certificate_submitted_portal_.value === 'true',
// form: hsCompanyData.properties.reseller_main_info_portal_ != undefined && hsCompanyData.properties.reseller_main_info_portal_.value === 'true'

import { AddressInterface, buildAddress, buildAddresses } from "interfaces/Address";
import { buildContact, buildContacts, ContactInterface } from "interfaces/Contact";
import { buildResellerInfo, ResellerApprovalFormInterface } from "./ResellerApprovalForm";
import { buildShippingPreference, ShippingPreferenceInterface } from "./ShippingPreference";

export interface CompanyInterface {
    id: number
    fishbowlCustomerId?: number;
    name?: string;
    gatewayProfileId?: number;
    defaultPaymentTermsName?: string;
    approved: boolean,
    addresses: Array<AddressInterface>,
    contacts: Array<ContactInterface>,
    approvalStatus?: CompanyResellerApprovalInterface,
    resellerInfo?: ResellerApprovalFormInterface,
    companyType: string,
    defaultShippingPreference?: ShippingPreferenceInterface,
    defaultBillingAddress?: AddressInterface,
    defaultBillingContact?: ContactInterface,
    memberCount?: number,
    inviteCount?: number,
    rep?: {
        id: number,
        name: string,
        contact?: ContactInterface,
    }
    resellers?: Array<CompanyInterface>,
    lastSO?: Date
}

export interface CompanyResellerApprovalInterface {
    id: number,
    lastUpdated: string,
    agreementComplete: boolean,
    agreementApproved: boolean,
    agreementDenialReason: string,
    certificateUploaded: boolean,
    certificateApproved: boolean,
    certificateDenialReason: string,
    infoFormComplete: boolean,
    infoFormApproved: boolean,
    infoFormDenialReason: string,
    applicationComplete: boolean,
    applicationApproved: boolean,
    applicationDenialReason: string,
    completionEmailSent?: boolean,
    expirationDate?: Date,
    expirationReason?: string,
    certExpirationDate?: Date,
    certNeverExpires?: boolean,
}

export const buildCompanyInfo = (companyData): CompanyInterface => {
    let company: CompanyInterface;

    let lastSO;

    if(companyData.last_so && companyData.last_so.length) {
        lastSO = new Date(companyData.last_so[0].created_at);
    }

    if (companyData.fishbowl_id) {
        const addresses = buildAddresses(companyData.addresses);
        const contacts = buildContacts(companyData.contacts);


        company = {
            id: companyData.id,
            name: companyData.name,
            fishbowlCustomerId: companyData.fishbowl_id,
            gatewayProfileId: companyData.gateway_payment_id,
            defaultPaymentTermsName: companyData.default_payment_terms_name,
            approved: companyData.approved,
            addresses: addresses,
            contacts: contacts,
            companyType: companyData.company_type.name,
            defaultBillingAddress: companyData.default_billing_address ? buildAddress(companyData.default_billing_address) : null,
            defaultBillingContact: companyData.default_billing_contact ? buildContact(companyData.default_billing_contact) : null,
            defaultShippingPreference: buildShippingPreference(companyData.default_shipping_preference),
            lastSO: lastSO
        };
    } else {
        const addresses = buildAddresses(companyData.addresses);
        const contacts = buildContacts(companyData.contacts);

        company = {
            id: companyData.id,
            name: companyData.name,
            approved: companyData.approved,
            addresses: addresses,
            contacts: contacts,
            companyType: companyData.company_type.name,
            lastSO: lastSO
        };
    }
    company.memberCount = (companyData.members_aggregate && companyData.members_aggregate.aggregate) ? companyData.members_aggregate.aggregate.count : 0;
    company.inviteCount = (companyData.invites_aggregate && companyData.invites_aggregate.aggregate) ? companyData.invites_aggregate.aggregate.count : 0;

    if (companyData.reseller_approval_status) {
        let upDate;
        if (companyData.reseller_approval_status.last_updated_by_user_date) {
            upDate = companyData.reseller_approval_status.last_updated_by_user_date;
        } else if(companyData.reseller_approval_status.updated_at) {
            upDate = companyData.reseller_approval_status.updated_at;
        } else if(companyData.updated_at) {
            upDate = companyData.updated_at;
        }

        if(upDate) {
            upDate = new Date(upDate);
        }
        

        let expirationDate: Date = null;

        if(companyData.reseller_approval_status.cert_expiration_date) {
            expirationDate = new Date(companyData.reseller_approval_status.cert_expiration_date);
            expirationDate.setDate(expirationDate.getDate());
        }


        company.approvalStatus = {
            id: companyData.reseller_approval_status.id,
            lastUpdated: upDate ? `${(upDate.getMonth() + 1)}-${upDate.getDate()}-${upDate.getFullYear()}` : "",
            agreementComplete: companyData.reseller_approval_status.agreement_complete,
            agreementApproved: companyData.reseller_approval_status.agreement_approved,
            agreementDenialReason: companyData.reseller_approval_status.agreement_denial_reason,
            certificateUploaded: companyData.reseller_approval_status.certificate_uploaded,
            certificateApproved: companyData.reseller_approval_status.certificate_approved,
            certificateDenialReason: companyData.reseller_approval_status.certificate_denial_reason,
            infoFormComplete: companyData.reseller_approval_status.info_form_complete,
            infoFormApproved: companyData.reseller_approval_status.info_form_approved,
            infoFormDenialReason: companyData.reseller_approval_status.info_form_denial_reason,
            applicationComplete: companyData.reseller_approval_status.application_complete,
            applicationApproved: companyData.reseller_approval_status.application_approved,
            applicationDenialReason: companyData.reseller_approval_status.application_denial_reason,
            expirationDate: companyData.reseller_approval_status.expiration_date ? new Date(companyData.reseller_approval_status.expiration_date) : null,
            expirationReason: companyData.reseller_approval_status.expiration_reason,
            certExpirationDate: expirationDate,
            certNeverExpires: companyData.reseller_approval_status.cert_never_expires,
        };
    }

    if (companyData.reseller_info) {
        company.resellerInfo = buildResellerInfo(companyData.reseller_info, company.addresses, company.contacts);
    }

    if (companyData.resellers && companyData.resellers.length > 0) {
        company.resellers = companyData.resellers.map((reseller) => {
            return {
                id: reseller.id,
                name: reseller.name,
                companyType: reseller.company_type,
                fishbowlId: reseller.fishbowl_id,
            };
        });
        company.resellers.sort((a, b) => a.name.localeCompare(b.name));
    } else {
        company.resellers = [];
    }

    if (companyData.rep) {
        company.rep = {
            id: companyData.rep.id,
            name: companyData.rep.name,
        };

        if (companyData.rep.rep_info) {
            company.rep.contact = {
                id: 0,
                firstName: companyData.rep.rep_info.name ?? companyData.rep.name,
                lastName: "",
                email: companyData.rep.rep_info.email,
                phoneNumber: formatPhoneNumber(companyData.rep.rep_info.phone),
                title: "Contact Info",
                contactTypes: [],
            };
        }


        // THE BETTER WAY TO DO THIS
        // if (companyData.rep.contacts && companyData.rep.contacts.length > 0) {
        //     company.rep.contact = {
        //         id: companyData.rep.contacts[0].id,
        //         firstName: companyData.rep.contacts[0].first_name,
        //         lastName: companyData.rep.contacts[0].last_name,
        //         email: companyData.rep.contacts[0].email,
        //         phoneNumber: companyData.rep.contacts[0].phone_number,
        //         title: companyData.rep.contacts[0].title,
        //         contactTypes: companyData.rep.contacts[0].contact_types.map(type => {return {id: type.type.id, name: type.type.name}}),
        //     };
        // }
    }

    return company;
};

function formatPhoneNumber(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
}

export interface BasicCompanyInterface {
    id: number,
    name: string
}