import React, { useState, useContext } from "react";
import _ from "lodash";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Accordion, AccordionSummary, AccordionDetails, ButtonGroup } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ProductContext } from "context/Product";
import { UserContext } from "context/User";
import { CartContext } from "context/Cart";
import { useNavigate } from "react-router-dom";
import { BaseProductListingInterface, getSortOrder, ProductInterface } from "interfaces/Product";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import { ExpandMore, FiberNew, NewReleasesOutlined, Star, StarOutline } from "@material-ui/icons";
import DemoOrderFlag from "components/utils/DemoOrderFlag";

type ProductListingInterface = ProductInterface | BaseProductListingInterface;

const isProductInterface = (object: any): object is ProductInterface => {
  return object && 'fishbowlId' in object;
}

const InventorySearch = (props) => {
  const navigate = useNavigate();
  const { products, baseProducts } = useContext(ProductContext);
  const { currentUser, isAdmin } = useContext(UserContext);
  const [bstockDiscount] = React.useState(null);
  const [input, setInput] = useState<string>(localStorage.getItem("last-search") ?? "");
  const [productTypeFilter, setProductTypeFilter] = useState<string>("");
  const [productCategoryFilter, setProductCategoryFilter] = useState<string>("");
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [productCategories, setProductCategories] = useState<string[]>([]);
  const { cart, setOpenAddToCartModal, addProductToCart } = props;
  const [filterOutOfStock, setFilterOutOfStock] = React.useState(false);
  const [filterOnlyBStock, setFilterOnlyBStock] = React.useState(false);
  const [visibleProducts, setVisibleProducts] = React.useState<ProductListingInterface[]>();
  const [productListings, setProductListings] = React.useState<ProductListingInterface[]>([]);
  const [expandedProducts, setExpandedProducts] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const { orderingOnBehalfOf } = React.useContext(CartContext);
  // const [products, setProducts] = React.useState([]);

  const windowScrollerRef = React.useRef<WindowScroller>();
  const virtualizedListRef = React.useRef<List>();

  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  React.useEffect(() => {
    if(productListings.length == 0) {
      loadProductListings();
    }

    if(productTypes.length == 0) {
      loadProductTypes();
    }

    if(productCategories.length == 0) {
      loadProductCategories();
    }
  }, []);

  React.useEffect(() => {
    filterProducts();
  }, [productListings]);

  // React.useEffect(() => {
  //   console.log(orderingOnBehalfOf);
  // }, [orderingOnBehalfOf]);

  React.useEffect(() => {
    loadProductTypes();
    loadProductCategories();
    loadProductListings();
    filterProducts();
  }, [products]);

  React.useEffect(() => {
    filterProducts();
  }, [filterOutOfStock, input, productTypeFilter, filterOnlyBStock, productCategoryFilter]);

  const loadProductTypes = () => {
    let productTypes = [];
    for (let pi = 0; pi < products.length; pi++) {
      const product = products[pi];
      if(product.productType && !productTypes.includes(product.productType)) {
        productTypes.push(product.productType);
      }
    }
    setProductTypes(productTypes);
  }

  const loadProductCategories = () => {
    let productCats = [];
    for (let pi = 0; pi < products.length; pi++) {
      const product = products[pi];

      
      if(product.category && !productCats.includes(product.category)) {
        productCats.push(product.category);
      }
    }
    productCats.sort();
    console.log(productCats);
    setProductCategories(productCats);
  }

  const loadProductListings = () => {
    const baseProductListings: BaseProductListingInterface[] = baseProducts.map((bp) => {
      const bpp =  products.filter((prod) => prod.baseProduct?.id == bp.id);
      if(!bpp.length) return;
      // bpp.sort((a, b) => b.productLaunchDate.getTime() - a.productLaunchDate.getTime());
      return {
        ...bp,
        status: {
          visiblePortal: bpp.every((p) => p.status.visiblePortal),
          visiblePublic: bpp.every((p) => p.status.visiblePublic),
          discontinued: bpp.every((p) => p.status.discontinued),
        },
        featured: bpp.findIndex((p) => p.featured) > -1,
        products: bpp,
        productTypeSortOrder: bpp.length > 0 ? getSortOrder(bpp[0].productType) : 15,
        productLaunchDate: bpp[0].productLaunchDate
      }
    });

    const uniqueProducts: ProductInterface[] = products.filter((prod) => !prod.baseProduct);
    
    setProductListings([...baseProductListings, ...uniqueProducts]);
  }

  const goToSingleProduct = async (id) => {
    localStorage.setItem("last-search", input);
    navigate(`/products/${id}`);
  };

  // let searchResults: Array<ProductInterface> = [];

  // const renderWarrantyInfo = (product: ProductInterface) => {
  //   if (product.warranty === "Like New") {
  //     return (
  //       <div>
  //         <h3>Like New</h3>
  //         <p>
  //           Products are fully functional and have gone through our rigorous
  //           quality control testing process, but may have minor cosmetic damage.
  //           Their packaging may be generic. These fixtures are backed by
  //           Blizzard’s 90-day warranty.
  //         </p>
  //       </div>
  //     );
  //   } else if (product.warranty === "Refurbished") {
  //     return (
  //       <div>
  //         <h3>Refurbished</h3>
  //         <p>
  //           These fixtures are fully functional and have gone through our
  //           rigorous quality control testing process. They work like new with
  //           potential signs of wear and tear caused by use in sales
  //           demonstrations, promotional content, or a prior sale. Their
  //           packaging may be generic. These fixtures are backed by Blizzard’s
  //           90-day warranty.
  //         </p>
  //       </div>
  //     );
  //   } else if (product.warranty === "Discontinued") {
  //     return (
  //       <div>
  //         <h3>Discontinued</h3>
  //         <p>
  //           These products are brand new, fully functional and have gone through
  //           our QC test process. They are backed by Blizzard’s 90-day warranty.
  //         </p>
  //       </div>
  //     );
  //   }
  // };

  // const warrantyInfo = (product) => {
  //   return (
  //     <div className="warranty-info">
  //       <h2>Quality Definition & Warranty Info</h2>

  //       <div className="container">{renderWarrantyInfo(product)}</div>
  //     </div>
  //   );
  // };

  const matchesSearch = (p: ProductListingInterface) => {

    if(!isProductInterface(p)) {
      const baseHasAMatch = p.products.find(matchesSearch);
      return baseHasAMatch != null;
    }


    const searchInput = input.toLowerCase(),
    inputIgnoredChar = searchInput.replace(/[\|&;\$%@"<>\(\)\+,\\\[]/g, "");
    
    let prodName = "";
    let prodFishName = "";
    let prodDesc = "";
    let prodSku = "";
    if (p.name) {
      prodName = p.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");
    }

    if (p.sku) {
      prodSku = p.sku;
    }

    if (p.fishbowlName) {
      prodFishName = p.fishbowlName.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");
    }

    const arr = [prodName, prodDesc, prodSku, prodFishName];
    const regexQuery = RegExp(inputIgnoredChar.replaceAll("+", ".*").replaceAll(" ", ".*").replaceAll("-", ".*"));
    for (let ati = 0; ati < arr.length; ati++) {
      const element = arr[ati];

      if(regexQuery.test(element)) return true;
    }

    return false;
  }

  const filterProducts = () => {
    let searchResults: ProductListingInterface[] = [];
    if(input.length > 0) {

      searchResults = _.filter(productListings, matchesSearch);
    } else {
      searchResults = productListings;
    }

    if(productTypeFilter.length > 0) {
      searchResults = _.filter(searchResults, (p) => {
        if (isProductInterface(p)) {
          return p.productType == productTypeFilter;
        } else {
          return p.products.some((x) => x.productType == productTypeFilter);
        }
      });
    }

    if(productCategoryFilter.length > 0) {
      searchResults = _.filter(searchResults, (p: ProductListingInterface) => {
        if (isProductInterface(p)) {
          return p.category == productCategoryFilter;
        } else {
          return p.products.some((x: ProductInterface) => x.category == productCategoryFilter);
        }
      });
    }

    if(filterOutOfStock) {
      searchResults = _.filter(searchResults, (p) => {
        if (isProductInterface(p)) {
          return p.inventory - (p.inventoryNotAvailable ?? 0) > 0;
        } else {
          return p.products.some((x) => x.inventory - (x.inventoryNotAvailable ?? 0) > 0);
        }
      });
    }

    if(filterOnlyBStock) {
      searchResults = _.filter(searchResults, (p) => {
        if (isProductInterface(p)) {
          return p.bstock > 0;
        } else {
          return p.products.some((x: ProductInterface) => x.bstock > 0);
        }
      });
    }

    if(!currentUser || !currentUser.company || currentUser.company.companyType != "Internal") {
      searchResults = searchResults.filter((p) => p.status.visiblePortal);
    }
    
    searchResults = _.orderBy(searchResults, ["productLaunchDate", "featured", "productTypeSortOrder", "name"], ["desc", "desc", "asc", "asc"]);

    setVisibleProducts(searchResults);

    if (loading && searchResults?.length > 0) setLoading(false);
  }

  const renderPrice = (prod) => {
    let price = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format((prod.price));
    return <Grid item xs={3} sm={2} lg={2} style={{ fontWeight: "bold" }}>{price}</Grid>;
  };

  const buildFilters = () => {
    return (
      <div className="product-filters-container" style={{overflow:"hidden"}}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <div className="inventory-search">
                <TextField
                  label="Product Search"
                  variant="outlined"
                  value={input}
                  onChange={(e) => {
                    setInput(e.target.value);
                    // filterProducts();
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="product-category-select-label">Category</InputLabel>
                <Select
                  labelId="product-category-select-label"
                  id="select-product-category"
                  value={productCategoryFilter}
                  label="Category"
                  onChange={(event) => {
                    setProductCategoryFilter(event.target.value + "");
                    // filterProducts();
                  }}
                >
                  <MenuItem value={""}>Any</MenuItem>
                  {_.map(
                    productCategories,
                    (cat: string, i: number) => {
                      return <MenuItem key={"type-"+i} value={cat}>{cat}</MenuItem>;
                    }
                  )}
                </Select>
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel id="product-type-select-label">Type</InputLabel>
                <Select
                  labelId="product-type-select-label"
                  id="select-product-type"
                  value={productTypeFilter}
                  label="Type"
                  onChange={(event) => {
                    setProductTypeFilter(event.target.value + "");
                    // filterProducts();
                  }}
                >
                  <MenuItem value={""}>Any</MenuItem>
                  {_.map(
                    productTypes,
                    (type: string, i: number) => {
                      return <MenuItem key={"type-"+i} value={type}>{type}</MenuItem>;
                    }
                  )}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={6} md={4}>
            <ButtonGroup fullWidth={true} color="primary" aria-label="outlined primary button group" style={{height: "100%"}}>
              <Button 
                className={!filterOnlyBStock && !filterOutOfStock ? "active-filter-button" : "inactive-filter-button" }
                onClick={() => {
                  setFilterOnlyBStock(false);
                  setFilterOutOfStock(false);
                }}
              >
                All
              </Button>
              <Button
                className={filterOutOfStock ? "active-filter-button" : "inactive-filter-button" }
                onClick={() => {
                  setFilterOnlyBStock(false);
                  setFilterOutOfStock(true);
                }}
              >
                In-Stock
              </Button>
              <Button
                className={filterOnlyBStock ? "active-filter-button" : "inactive-filter-button" }
                onClick={() => {
                  setFilterOnlyBStock(true);
                  setFilterOutOfStock(false);
                }}
              >
                B-Stock
              </Button>
            </ButtonGroup>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  const renderProductItem = (prod: ProductInterface, key, subProduct: boolean, phoneMode: boolean) => {
    const hasInventory = prod.inventory - (prod.inventoryNotAvailable ?? 0) > 0;
    const hasStatus = (!prod.status.visiblePortal || prod.status.discontinued);

    // backgroundColor: prod.productLaunchDate && prod.productLaunchDate > sixMonthsAgo ? "#fffbe1" : "white",
    return (
      <Grid style={{ marginBottom: (subProduct ? 10 : 0), minHeight: 80, paddingBottom: 10, width: "100%", border: (subProduct ? "1px solid rgba(0, 0, 0, 0.12)" : "") }} container spacing={1} className="line-item" key={key}>
          <Grid item container xs={6} sm={4} lg={7}
            style={{ cursor: "pointer", fontWeight: "bold"}}
            className="num"
            onClick={() => {
              goToSingleProduct(prod.id);
            }}
          >
            {subProduct && prod.variantLabel ? prod.variantLabel : prod.name}
            {
              prod.productLaunchDate && prod.productLaunchDate > sixMonthsAgo ? 
              <FiberNew color={"primary"} style={{fontSize: 30, marginLeft: 4}} /> : 
              null
            }
            {/* {!phoneMode && (prod.status.name == "Draft") && <Grid item xs={4}><VisibilityOff style={{paddingRight: 4}} /></Grid>} */}
            {!phoneMode && hasStatus && 
              <Grid item md={2} xs={4}><div
              className={
                "qty highlight"
              }
              style={{
                margin: "0 5",
                backgroundColor: "lightgrey",
                fontSize: 10
              }}
            >
              {!prod.status.visiblePortal ? "Draft" : "Discontinued"}
            </div></Grid>}
          </Grid>
        {renderPrice(prod)}
        <Grid item xs={3} sm={2} lg={1} style={{ textAlign: "center" }}>
          <div
            className={
              hasInventory
                ? "qty highlight green"
                : "qty highlight red"
            }
            style={{
              width: 70,
              margin: "0 auto"
            }}
          >
            {filterOnlyBStock ? prod.bstock : (hasInventory ? prod.inventory - (prod.inventoryNotAvailable ?? 0) : "0")}
          </div>
        </Grid>
        {!phoneMode &&
        <Grid item xs={4} sm={2} lg={1}
          style={{ textAlign: "center" }}
          onClick={() => {
            goToSingleProduct(prod.id);
          }}
        >
          <i className={isAdmin ? "fas fa-edit active" : "fas fa-eye active"}></i>
        </Grid>}
        <Grid item xs={12} sm={2} lg={1}>
          <Grid container spacing={2}>
          {currentUser
            ? currentUser.accountLevel.canOrder && (
              <Grid item xs={12} sm={12} lg={12}>
                <div className="add-to-po" style={{ textAlign: "center",  margin: "3px 0 3px 0" }}>
                  <Button
                    onClick={() => {
                      addProductToCart({
                        ...prod,
                        bstockDiscount: bstockDiscount
                          ? bstockDiscount
                          : null,
                      });
                      setOpenAddToCartModal(true);
                    }}
                  >
                    <i className={"fas fa-cart-plus"}></i>
                  </Button>
                </div>
                </Grid>
              )
            : ""}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const toggleBaseProductChange = (index, prod, isExpanded) => {
    if(isExpanded) {
      setExpandedProducts({...expandedProducts, [index]: prod});
    } else {
      setExpandedProducts({...expandedProducts, [index]: null});
    }
  };

  const renderBaseProduct = (prod: BaseProductListingInterface, index, phoneMode: boolean) => {
    const visibleSubproducts = filterOutOfStock ? prod.products.filter((x) => x.inventory - (x.inventoryNotAvailable ?? 0) > 0) : prod.products;

    return (
        <Accordion
          style={{boxShadow: "rgb(0 0 0 / 10%) 0px 20px 30px 0px"}}
          elevation={0}
          expanded={expandedProducts[index] ? true : false}
          onChange={(e, isExpanded) => {   
            toggleBaseProductChange(index, prod, isExpanded);
            virtualizedListRef.current.recomputeRowHeights(index);
            virtualizedListRef.current.forceUpdate();
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none"}} className="line-item">
            {prod.name}
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: "column" }}>
            {_.orderBy(visibleSubproducts, ['price'], ['asc']).map((p) => renderProductItem(p, p.id, true, phoneMode))}
          </AccordionDetails>
        </Accordion>
    );
  }

  const calcRowHeight = (index, phoneMode: boolean): number => {
    if(expandedProducts[index]) {
      let subProductCount = expandedProducts[index].products.length;

      if(filterOutOfStock) {
        subProductCount = expandedProducts[index].products.filter((p) => p.inventory > 0).length;
      }
      return 115 + (subProductCount * (98 + (phoneMode ? 45 : 0)));
    } else {
      if(isProductInterface(visibleProducts[index])) {
        return 91 + (phoneMode ? 40 : 0);
      } else {
        return 90;
      }
      
    }  
  }

  const renderRow = (index, key, style, phoneMode: boolean) => {
    const prod: ProductListingInterface = visibleProducts[index];

    if (isProductInterface(prod)) {
      return (
        <div key={key} style={style} className={"single-product-line"}>
          {renderProductItem(prod, key, false, phoneMode)}
        </div>
      )
    } else {
      // , paddingRight: 4, borderRadius: 5
      return (
        <div key={key} style={style} className={"base-product-line"}>
          {renderBaseProduct(prod, index, phoneMode)}
        </div>
      )
    }
  }

  const renderPlaceholder = () => {
    return <h3 className="body-message center-text">No products meet your search parameters.</h3>;
  }

  const renderResults = () => {
    if(loading) {
      return <Loading height="100vh" title={"Loading Products"} position={"center"} />;
    } else {
      return <WindowScroller ref={windowScrollerRef} style={{overflow: "hidden"}}>
        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
          const phoneMode = width < 500;
          
          
          // if(phoneMode) width = width - 50;

          return (<Grid container className="results" style={{overflow: "hidden"}}>
          <Grid container item spacing={1} className="row-heads io" style={{overflow: "hidden"}}>
            <Grid item xs={6} sm={4} lg={7} >Product Name</Grid>
            <Grid item xs={3} sm={2} lg={2}>Price*</Grid>
            <Grid item xs={3} sm={2} lg={1} style={{ textAlign: "center" }}>{filterOnlyBStock ? "B-Stock" : "In-Stock"}</Grid>
            {!phoneMode && <Grid item xs={4} sm={2} lg={1} style={{ textAlign: "center" }}>Details</Grid>}
            {!phoneMode && <Grid item xs={12} sm={2} lg={1} style={{ textAlign: "center" }}>Add to Cart</Grid>}
          </Grid>
          <List
            style={{overflow: "visible"}}
            ref={virtualizedListRef}
            autoHeight
            width={width}
            height={height}
            isScrolling={isScrolling}
            onScroll={onChildScroll}
            scrollTop={scrollTop}
            rowHeight={(props) => calcRowHeight(props.index, phoneMode)}
            rowRenderer={(itemProps) => renderRow(itemProps.index, itemProps.key, itemProps.style, phoneMode)}
            noRowsRenderer={renderPlaceholder}
            rowCount={visibleProducts.length}
            containerStyle={{backgroundColor: "transparent", overflow: "visible", paddingTop: 10}}
            overscanRowCount={3}
          />
        </Grid>)
        }}
      </WindowScroller>;
    }
  };

  return (
    <div className="inventory-results" style={{overflow: "hidden"}}>
      <h1 className="reveal-text">Product Search</h1>
      {/* {currentUser.email == "devon@blizzardpro.com" ? <Button
        onClick={() => {
          saveAllVisibleProducts()
        }}
      >
        SAVE ALL VISIBLE PRODUCTS TO WEBFLOW
        </Button> : null} */}
      <DemoOrderFlag />
      {orderingOnBehalfOf && currentUser.company.id != orderingOnBehalfOf.id && (
        <div>
          <h3 style={{display: "inline"}}>Ordering on behalf of {orderingOnBehalfOf.name}</h3> <Link to="/checkout"><i style={{display: "inline"}} className={"fas fa-edit active"}></i></Link>
        </div>
      )}
      {currentUser.accountLevel.viewProducts && (
        <div style={{overflow: "hidden"}}>
          {buildFilters()}
          <div className="search-results-container" style={{overflow: "hidden"}}>{renderResults()}</div>
        </div>
      )}
      {!currentUser.accountLevel.viewProducts && (
        <h3>You don't have permissions to view products</h3>
      )}
    </div>
  );
};

export default InventorySearch;
