import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBar = ({
  message,
  snackOpen,
  setSnackOpen,
  severity,
  anchorOrigin,
  duration = 2000
}) => {
  const handleClose = (event, reason) => {
    setSnackOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ horizontal: anchorOrigin, vertical: "top" }}
        open={snackOpen}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBar;
