import React from "react";
import { CompanyInterface } from "interfaces/Company";
import { Button, Grid } from "@material-ui/core";
import AddressForm from "components/Account/Address/AddressForm";
import AddressCard from "components/MyCompany/AddressCard";
import Loading from "components/utils/Loading";
import { UserContext } from "context/User";
import { makeAxiosCall } from "utils";
import { AddressInterface, buildAddresses } from "interfaces/Address";
import { ResellerApprovalFormInterface } from "interfaces/ResellerApprovalForm";
import CompanyLocations from "./Locations/CompanyLocations";

interface CompanyAddressesProps {
    company: CompanyInterface,
    resellerInfoForm?: boolean,
    resellerFormData?: ResellerApprovalFormInterface,
    setResellerFormData?: (formData: ResellerApprovalFormInterface) => void,
    autoShowForm?: boolean
}

const CompanyAddresses = ({ company, resellerInfoForm = false, resellerFormData, setResellerFormData, autoShowForm = false }: CompanyAddressesProps) => {
    const { currentUser, updateCurrentUser } = React.useContext(UserContext);
    const [addresses, setAddresses] = React.useState<AddressInterface[]>();
    const [loading, setLoading] = React.useState(true);
    const [newForm, setNewForm] = React.useState(autoShowForm);

    let isMounted = true;

    React.useEffect(() => {
        if (!resellerInfoForm) {
            if (company) {
                fetchAddresses();
            }
        } else {
            if (company?.approved) {
                fetchAddresses();
            } else {
                setAddresses(resellerFormData.addresses);
                setLoading(false);
            }
        }
        return () => {
            isMounted = false;
        };
    }, [company]);


    React.useEffect(() => {
        if (resellerInfoForm) {
            setResellerFormData({ ...resellerFormData, addresses: addresses });
        }

        if(company && currentUser && currentUser.company && currentUser.company.id == company.id) {
            updateCurrentUser({...currentUser, company: {...currentUser.company, addresses: addresses}});
        }
    }, [addresses]);

    const fetchAddresses = async () => {
        const response = await makeAxiosCall(
            "get",
            `addresses/${company.id}`
        ).catch(error => {
            console.error("Caught error getting company addresses:");
            console.error(error);
        });

        if (response.data) {
            const addressData = buildAddresses(response.data);
            if (isMounted) {
                setAddresses(addressData);
                setLoading(false);
            }
        }
    };

    const addAddress = (address: AddressInterface, newId: number) => {
        setAddresses([...addresses, { ...address, id: newId }]);
        setNewForm(false);
    };

    const updateAddress = (addressData: AddressInterface, id: number) => {
        const unchanged = addresses.filter((address) => address.id !== id);
        setAddresses([...unchanged, { ...addressData, id: id }]);
    };

    const deleteAddress = (addressId: number) => {
        const newAddressList = addresses.filter((address) => address.id != addressId);
        setAddresses(newAddressList);
    };

    const renderBody = () => {
        if (loading) {
            return <Loading height="40vh" title="" position="relative" />;
        } else {
            if (addresses && addresses.length > 0) {
                return addresses.map(address => {
                    const canEdit = address.addressTypes.some((type) => type.name == "Billing") ? currentUser.accountLevel.canEditPaymentMethods : currentUser.accountLevel.canEditInfo;
                    return <AddressCard key={address.id} companyId={company.id} address={address} canEdit={canEdit} updateCallback={updateAddress} deleteCallback={deleteAddress} />;
                });
            } else {
                return <p className="body-message">No Addresses Found</p>;
            }
        }
    };

    return company ? (
        <>
            <Grid container alignItems="center" >
                {resellerInfoForm ? null :
                    <Grid item xs={12}>
                        <CompanyLocations company={company} />
                    </Grid>
                }
                <Grid item xs={12} sm={9}>
                    <h2>Addresses</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setNewForm(!newForm);
                        }}
                    >
                        {newForm ? "Cancel" : "New Address"}
                    </Button>
                </Grid>

                {newForm ?
                    <Grid container spacing={1} className="new-form">
                        <h3>New Address</h3>
                        <AddressForm
                            companyId={company.id}
                            addCallback={(newAddress) => {
                                addAddress(newAddress, newAddress.id);
                            }}
                        />
                    </Grid>
                    : <Grid item xs={12}>{renderBody()}</Grid>}
            </Grid>
        </>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyAddresses;