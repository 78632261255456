import React, { useContext } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { storage, db } from "base.js";
import _ from "lodash";
import { Button, Grid } from "@material-ui/core";
import { UserContext } from "context/User";
import { CSSTransition } from "react-transition-group";
import { ProductContext } from "context/Product";
import Metadata from "components/utils/Metadata.js";
import { generateUID, determineFileType, makeAxiosCall } from "utils";
import { uploadMedia } from "utils/storage.js";
import 'scss/components/Utils/Upload.scss';

export const enum UploadType {
  profiles,
  cert,
  media,
  agreement,
}

interface UploadComponentInterfaceProps {
  uploadType: UploadType,
  successMessage?: string,
  successParagraph?: string,
  maxFileSize?: number,
  description?: string,
  metadata?,
  uploadPOFile?: Function,
  title?: string,
  companyId?: number,
  approvalId?: number,
  onFinished?: Function
}

const Upload = (props: UploadComponentInterfaceProps) => {
  const {
    uploadType,
    successMessage,
    successParagraph,
    maxFileSize,
    description,
    metadata,
    uploadPOFile,
    title,
    companyId,
    approvalId,
    onFinished
  } = props;
  const [files, setFiles] = React.useState([]);
  const [fileHasUploaded, setFileStatus] = React.useState(false);
  const { currentUser } = React.useContext(UserContext);
  const { products } = useContext(ProductContext);
  const profileRef = db.collection("profiles");
  const [chipData, setChipData] = React.useState([]);
  const [productTag, setProductTag] = React.useState([]);
  const [mediaType, setMediaType] = React.useState("image");
  const [formUrl, setFormUrl] = React.useState();


  // React.useEffect(() => {
    // register the handler
    // document.addEventListener("keyup", uploadHotkey, false);
    // document.addEventListener("keyup", resetHotkey, false);
  // }, []);

  React.useEffect(() => {
    if (props.uploadType == UploadType.cert) {
      fetchCertForm();
    }
  }, [props.uploadType]);

  const fetchCertForm = async () => {
    const formFiles = await storage.ref().child('/reseller_forms').listAll();
    const formUrl = await formFiles.items[0].getDownloadURL();
    setFormUrl(formUrl);
  }

  //eslint-disable-next-line
  const handleChange = (newFiles) => {
    // newFiles.map((file) => {
    //   determineFileType(file.type);
    //   return null;
    // });
    setFiles(newFiles);

    if(newFiles.length > 0) {
      uploadFiles(newFiles);
    }
  };

  const uploadFiles = async (newFiles) => {

    console.log("uploading");
    console.log(newFiles);
    
    const time = Date.now();

    newFiles.map((file, index) => {
      let str = file.type;
      //eslint-disable-next-line
      let tags = _.map(chipData, (c) => {
        return parseFloat(c.id);
      });
      str = str.substring(str.indexOf("/") + 1);

      const altType = file.path.split(".").pop();

      if (uploadType === UploadType.profiles) {
        let uid = generateUID();

        let fileMeta = {};
        fileMeta = {
          customMetadata: {
            uid: uid,
            tags: tags.join(", "),
          },
        };

        storage
          .ref(`profiles/${file.name}`)
          .put(file, fileMeta)
          .then((storageData) => {
            storageData.ref.getDownloadURL().then((url) => {
              profileRef.doc(uid+"").set({
                filename: file.name,
                src: url,
                uid: uid,
                tags: productTag,
                type: altType,
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (uploadType === UploadType.cert) {
        let companyUid = companyId ?? currentUser.company.id;
        storage
          .ref(
            `/portal_company_documents/${companyUid}/reseller_certificates/resale_certificate-${time}.${str}`
          )
          .put(file, metadata)
          .then((url) => {
            makeAxiosCall(
              "post",
              `reseller-approval-status/${companyUid}`,
              {
                approvalId: approvalId ?? currentUser.company.approvalStatus.id,
                certificateUploaded: true,
                certificateDenialReason: ""
              },
              { headers: {"Content-Type": "application/json",} },
            ).then((response) => {
              console.log(response);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (uploadType === UploadType.media) {
        uploadMedia(productTag);
      } else if (uploadType === UploadType.agreement) {
        let companyUid = companyId ?? currentUser.company.id;
        storage
          .ref(
            `/portal_company_documents/${companyUid}/reseller_agreement/resellerAgreement-${time}.${str}`
          )
          .put(file, metadata)
          .then((url) => {
            makeAxiosCall(
              "post",
              `reseller-approval-status/${companyUid}`,
              {
                approvalId: approvalId ?? currentUser.company.approvalStatus.id,
                agreementComplete: true,
                agreementApproved: true,
                agreementDenialReason: ""
              },
              { headers: {"Content-Type": "application/json",} },
            ).then((response) => {
              console.log(response);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (index === newFiles.length - 1) {
        setFileStatus(true);
      }

      return null;
    });
  };

  // define a handler
  // function uploadHotkey(e) {
  //   if (e.shiftKey && e.keyCode === 13) {
  //     uploadFiles();
  //   }
  // }

  // function resetHotkey(e) {
  //   if (e.keyCode === 32) {
  //     setFileStatus(false);
  //     setChipData([]);
  //   }
  // }

  return (
    <div className="upload-wrapper">
      <div className="upload-container">
        <CSSTransition
          in={!fileHasUploaded}
          unmountOnExit
          timeout={200}
          classNames="dialog"
        >
          <React.Fragment>
            <i className="far fa-folder-upload"></i>
            <div>
              <h1 className="reveal-text">{title ? title : "Upload"}</h1>
              <p>{description}</p>
            </div>
            {formUrl && (
              <div style={{ marginTop: 0, marginBottom: 15 }}>
                <a href={formUrl} target="_blank" rel="noreferrer">
                  Uniform Sales & Use Tax Resale Certificate - Multijurisdiction&nbsp;
                  <i className="fa-solid fa-arrow-up-right-from-square" style={{ fontSize: 12 }} />
                </a>
              </div>
            )}
            <div className="drag">
              <DropzoneArea
                filesLimit={1000}
                dropzoneClass="file-uploader"
                maxFileSize={maxFileSize}
                acceptedFiles={["application/*", "", "image/*", "video/*"]}
                onChange={handleChange}
                useChipsForPreview={true}
                onDropRejected={(file) => console.log(file)}
              />
            </div>

            <Button
              onClick={uploadFiles}
              disabled={files.length == 0}
              type="submit"
              style={{ width: 300, marginTop: 40 }}
              variant="contained"
              color="primary"
              className="btn"
            >
              Upload
            </Button>
          </React.Fragment>
        </CSSTransition>
        <CSSTransition
          in={fileHasUploaded}
          unmountOnExit
          timeout={200}
          classNames="dialog"
        >
          <div>
            <Grid container direction="column" alignItems="center" className="success">
              <Grid item>
                <i className="fad fa-check"></i>
              </Grid>
              <Grid item>
                <h2>Success!</h2>
              </Grid>
              <Grid item style={{display: "contents"}}>
                <p> {successMessage}</p>
              </Grid>
            </Grid>
            <div className="flex-container">
              <p>{successParagraph}</p>

              {onFinished && (
                  <Button
                    type="submit"
                    style={{ width: "100%", marginBottom: 12 }}
                    variant="contained"
                    color="primary"
                    className="btn"
                    onClick={() => {
                      if(onFinished) onFinished();
                    }}
                  >
                    Finished
                  </Button>
              )}

              <Button
                type="submit"
                style={{ width: "100%" }}
                onClick={() => setFileStatus(false)}
                variant="contained"
                color="secondary"
                className="btn"
              >
                Upload More
              </Button>
              {/*   Removing for now - doesn't work well if company is approved, 
                    and there's another button already on the page
              */}
            </div>
          </div>
        </CSSTransition>
      </div>
      {metadata && (
        <Metadata
          mediaType={mediaType}
          setMediaType={setMediaType}
          product={products}
          uploadType={uploadType}
          productTag={productTag}
          setProductTag={setProductTag}
          chipData={chipData}
          setChipData={setChipData}
        />
      )}
    </div>
  );
};

export default Upload;
