import { storage, db } from "../base.js";
import { generateUID } from "../utils";
import _ from "lodash";
export const uploadMedia = (files, tag, callBack) => {
  let allFiles = [];
  _.map(files, (file, index) => {
    console.log(files.length);
    let uid = generateUID();
    const altType = file.path.split(".").pop();
    const mediaRef = db.collection("media");

    let fileMeta = {};
    fileMeta = {
      customMetadata: {
        uid: uid,
        tags: [parseFloat(tag)],
      },
    };

    storage
      .ref(
        `${tag.includes("Fixture Profile") ? "profiles" : "media"}/${file.name}`
      )
      .put(file, fileMeta)
      .then((storageData) => {
        storageData.ref.getDownloadURL().then((url) => {
          let dbMeta = {
            filename: file.name,
            src: url,
            uid: uid,
            tags: [parseFloat(tag)],
            type: altType,
          };

          mediaRef
            .doc(uid)
            .set(dbMeta)
            .then(() => allFiles.push(dbMeta));
        });
      })
      .catch((err) => {
        console.log(err);
      });

    if (index === files.length - 1) {
      setTimeout(() => callBack(allFiles), 3000);
    } else {
      console.log("nope");
    }
  });
};
