import axios from "axios";
import _ from "lodash";
import { UserInterface, buildUser, UserAccountPermissionsInterface, buildAccountPermissions } from "interfaces/User";
import { CartContext } from "./Cart";
import React, { useEffect, useState, createContext, ErrorInfo } from "react";
import { makeAxiosCall } from "utils/index";
import { app } from "../base";


interface UserContextInterface {
    user: UserInterface,
    currentUser: UserInterface,
    accountLevels: UserAccountPermissionsInterface[],
    internalUser: boolean,
    isAdmin: boolean,
    isLoading: boolean,
    clearTempUser: () => void,
    userToken: string | null,
    fetchUser: (userUid: string) => Promise<void>,
    fetchTempUser: (userUid: string) => Promise<void>,
    fetchAuthedUser: () => Promise<void>,
    fetchCurrentUser: () => Promise<void>,
    loadFullUserData: (userUid: string) => Promise<UserInterface | null>,
    updateUser: (userData: any, callback: any) => void,
    signOut: () => void,
    logError: (error: Error, errorInfo: ErrorInfo) => void,
    updateCurrentUser: (newUserData: UserInterface) => void
}

export const UserContext = createContext<UserContextInterface>(null);


interface UserProviderProps {
    children: any,
}

export const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState<UserInterface | null>();    // The user logged in through Firebase Auth
    const [userToken, setUserToken] = useState<string | undefined>();
    const [currentUser, setCurrentUser] = useState<UserInterface | null>();    // The acting user: If ghosting, the ghosted user otherwise the authed user
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [accountLevels, setAccountLevels] = useState<UserAccountPermissionsInterface[]>();
    const [internalUser, setInternalUser] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    
    

    useEffect(() => {
        fetchPermissions();

        watchAuthedUser();
    }, []);

    useEffect(() => {
        if (currentUser && currentUser.company && currentUser.company.companyType === "Internal") {
            setInternalUser(true);
        } else {
            setInternalUser(false);
        }

        if (currentUser && currentUser.accountLevel.name === "Super Admin") {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [currentUser]);

    const fetchUser = async (userUid: string) => {
        setUser(await loadFullUserData(userUid));
    };

    const fetchAuthedUser = async () => {
        try {
            const { currentUser } = app.auth();
            // console.log(currentUser);

            if (currentUser && currentUser.uid) {
                const freshUserData = await loadFullUserData(currentUser.uid);
                // console.log("setting current user");
                // console.log(freshUserData);

                setUser(freshUserData);
                const localUser = localStorage.getItem("tempUser");
                if (localUser) {
                    setCurrentUser({ ...JSON.parse(localUser) });
                } else {
                    setCurrentUser(freshUserData);
                }
            }
            setIsLoading(false);
        } catch (e) {
            console.error("Error fetching authed user:");
            console.error(e);
            window.location.reload();
        }
    };

    const watchAuthedUser = () => {
        const portalListener = app.auth().onIdTokenChanged((authedUser) => {
            if (authedUser) {
                app.auth().currentUser.getIdToken(true)
                    .then((idToken) => {
                        setUserToken(idToken);
                        axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;

                        if (!user || authedUser.uid != user.uid) {
                            fetchAuthedUser();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                setIsLoading(false);
            }
        });
        window.addEventListener('storage', (event) => {
            if (event.key == "logout-event") {
                signOut();
            }
        });
        return portalListener;
    };

    const fetchCurrentUser = async () => {
        try {
            if (user !== currentUser) {
                fetchTempUser(currentUser.uid);
            } else {
                fetchAuthedUser();
            }

        } catch (e) {
            console.error("Error fetching current user:");
            console.error(e);
        }
    };

    const signOut = () => {
        window.stop();
        makeAxiosCall('get', 'logout').then((r) => {
            app.auth().signOut().then(success => {
                localStorage.clear();
                axios.defaults.headers.common["Authorization"] = null;
    
                setUser(null);
                setCurrentUser(null);
            });
        });
    };

    const logError = (error: Error, errorInfo: ErrorInfo) => {
        makeAxiosCall('post', 'log-error', {
            error: error,
            errorInfo: errorInfo,
            currentUser: currentUser
        });
    };

    const fetchTempUser = async (uid) => {
        const userData = await loadFullUserData(uid);

        if (userData) {
            setCurrentUser(userData);
            localStorage.clear();
            localStorage.setItem("tempUser", JSON.stringify(userData));
        } else {
            console.log("No such user!");
        }
    };

    const clearTempUser = () => {
        setCurrentUser(user);
        localStorage.removeItem("tempUser");
        
        window.location.href = "/";
    };

    const updateUser = (newUserData, callback) => {
        makeAxiosCall(
            "post",
            "account/" + newUserData.uid,
            newUserData
        )
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((err) => {
                console.error("Error updating user:");
                console.error(err);
            });
    };

    const loadFullUserData: (userUid: string) => Promise<UserInterface | null> = async (userUid: string) => {
        const response = await makeAxiosCall(
            "get",
            "account/" + userUid
        )
            .catch((err) => {
                if (currentUser) {
                    signOut();
                }
                console.log(err);
            });

        if (response.data) {
            return buildUser(response.data);
        } else {
            console.log("No such user!");
            return null;
        }
    };

    const fetchPermissions = () => {
        makeAxiosCall(
            "get",
            "account-levels"
        ).then(res => {
            const permissionsList = _.map(
                res.data,
                (level) => buildAccountPermissions(level)
            );
            setAccountLevels(_.orderBy(permissionsList, ["id"], ["asc"]));
        });
    };

    const updateCurrentUser = (newUserData: UserInterface) => {
        setCurrentUser(newUserData);
        if(user.uid == currentUser.uid) {
            setUser(newUserData);
        }
    };

    return (
        <UserContext.Provider
            value={{
                user,
                currentUser,
                accountLevels,
                internalUser,
                isAdmin,
                isLoading,
                clearTempUser,
                userToken,
                fetchUser,
                fetchTempUser,
                fetchAuthedUser,
                fetchCurrentUser,
                loadFullUserData,
                updateUser,
                signOut,
                logError,
                updateCurrentUser
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
