import React from "react";
import { CompanyInterface } from "interfaces/Company";
import { Grid, Paper, Typography } from "@material-ui/core";
import Loading from "components/utils/Loading";

interface CompanyRepProps {
    company: CompanyInterface,
}

const CompanyRep = ({ company }: CompanyRepProps) => {

    let rep: any = {
        id: 0,
        name: "Blizzard Lighting",
        contact: {
            email: "orders@blizzardpro.com",
            phoneNumber: "(414) 395-8365",
        }
    };

    if (company && company.rep) {
        rep = company.rep;
    }

    return company ? (
        <Paper elevation={2} className="contact-card">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">{rep.name}</Typography>
                </Grid>
                {rep.contact ? (
                    <>
                        <Grid item container justifyContent="center" alignItems="center" className="center-text" xs={12} md={6}>
                            <Grid item xs={3} md={12}>
                                <i className="fa-regular fa-envelope"></i>
                            </Grid>
                            <Grid item xs={9} md={12}>
                                <Typography variant="body1">{!rep.contact.email || rep.contact.email.length === 0 ? "None" : <a href={"mailto:" + rep.contact.email}>{rep.contact.email}</a>}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="center" alignItems="center" className="center-text" xs={12} md={6}>
                            <Grid item xs={3} md={12}>
                                <i className="fa-solid fa-phone"></i>
                            </Grid>
                            <Grid item xs={9} md={12}>
                                <Typography variant="body1">{!rep.contact.phoneNumber || rep.contact.phoneNumber.length === 0 ? "None" : rep.contact.phoneNumber}</Typography>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid xs={12}><Typography variant="h6" style={{ textAlign: "center", marginTop: 25 }}>No contact found</Typography></Grid>
                )}
            </Grid>
        </Paper>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyRep;