import { makeAxiosCall } from "utils";

// const localUrl = "http://localhost:8080/";

export const getSO = (fbId, type, userToken) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SO_LOADING",
    });

    const res = await makeAxiosCall(
      "get",
      `fishbowl-orders/${fbId}?type=${type}`,
      null,
      {
        method: "GET",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => {
      console.log(error);
    });

    dispatch({
      type: "GET_SO_SUCCESS",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: "GET_SO_FAILED",
      payload: e,
    });
  }
};
