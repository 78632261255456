import { Grid, Paper } from "@material-ui/core";
import { AddressInterface } from "interfaces/Address";
import 'scss/components/Utils/AdditionalShippingDetails.scss';

interface AdditionalShippingDetailsPropsInterface {
    address: AddressInterface
}

const additionalShippingDetails = (props: AdditionalShippingDetailsPropsInterface) => {
    const {address} = props;

    if(address.additionalShippingDetails) {
        return (
            <Grid container item xs={12} spacing={2}>
                <Grid item container md={4} xs={12}>
                    <Paper className={"bordered-container"}>
                        <Grid item xs={12}>
                            <h3 className="center">Loading Dock</h3>  
                        </Grid>
                        <Grid item xs={12}>
                            {address.additionalShippingDetails.hasTruckHeightLoadingDock ? "Dock is truck height" : 
                            (address.additionalShippingDetails.liftGateRequired ? "Dock requires a lift gate" : 
                            "Dock is not truck height, but does not require a lift gate")}
                        </Grid>
                        <Grid item xs={12}>
                            {address.additionalShippingDetails.deliveryHoursMorning && address.additionalShippingDetails.deliveryHoursAfternoon ? "Delivery Hours: 8AM - 4PM" : 
                            (address.additionalShippingDetails.deliveryHoursMorning ? "Delivery Hours: 8AM - Noon" : 
                            (address.additionalShippingDetails.deliveryHoursAfternoon ? "Delivery Hours: Noon - 4PM" : 
                            ("Delivery hours not set")))}
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item container md={4} xs={12}>
                    <Paper className={"bordered-container"}>
                    <Grid item xs={12}>
                        <h3 className="center">Location Details</h3>  
                    </Grid>
                    <Grid item xs={12}>
                        {address.additionalShippingDetails.isCommercialLocation ? "This is a commercial location" : "This is a residential location"}
                    </Grid>
                    <Grid item xs={12}>
                        {address.additionalShippingDetails.isJobsiteLocation ? "This is a job site" : "This is NOT a job site"}
                    </Grid>
                    { address.additionalShippingDetails.locationNotes ? 
                    <Grid item xs={12}>
                        <h4>Notes:</h4>  
                    </Grid>: null}
                    { address.additionalShippingDetails.locationNotes ? 
                        <Grid item xs={12}>
                            {address.additionalShippingDetails.locationNotes}
                        </Grid> : null}
                    </Paper>
                </Grid>

                <Grid item container md={4} xs={12}>
                    <Paper className={"bordered-container"}>
                    <Grid item xs={12}>
                        <h3 className="center">Recieving Contact</h3>  
                    </Grid>
                    <Grid item xs={12}>
                        {address.additionalShippingDetails.contact.firstName + " " + address.additionalShippingDetails.contact.lastName}
                    </Grid>
                    <Grid item xs={12}>
                        {address.additionalShippingDetails.contact.phoneNumber}
                    </Grid>
                    <Grid item xs={12}>
                        {address.additionalShippingDetails.contact.email}
                    </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    } else {
        return <></>;
    }
}

export default additionalShippingDetails;