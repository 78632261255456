import React from "react";
import { ListItem, ListItemAvatar, ListItemText, IconButton, CircularProgress, Checkbox, Divider, Avatar, Button, Grid, TextField } from "@material-ui/core";
import { Star, StarOutline, DeleteForever, Edit, GetApp, Assessment, Code, FileCopy, Keyboard, MenuBook, Movie, Print, Redeem, Save } from "@material-ui/icons";
import { PDProjectInterface, PDProjectMediaInterface } from "interfaces/ProductDevelopment"
import { storage } from "base";
import { makeAxiosCall } from "utils";
import { Autocomplete } from "@material-ui/lab";
import ModalBasic from "components/utils/ModalBasic";

interface PDProjectMediaListItemProps {
    mediaItem: PDProjectMediaInterface,
    project: PDProjectInterface,
    refreshData: () => void,
    selected: boolean,
    selectCallback: (id: number) => void,
    deleteCallback?: (id: number) => void,
}

const PDProjectMediaListItem = ({ mediaItem, project, refreshData, selected, selectCallback, deleteCallback }: PDProjectMediaListItemProps) => {

    const [deleting, setDeleting] = React.useState<boolean>(false);
    const [showEditMediaModal, setShowEditMediaModal] = React.useState<boolean>(false);
    const [newMediaData, setNewMediaData] = React.useState<PDProjectMediaInterface>(mediaItem);

    const editMedia = (media: PDProjectMediaInterface) => {
        setShowEditMediaModal(true);
    }

    const closeEditModal = () => {
        setShowEditMediaModal(false);
    }

    const renderEditMediaModal = () => {
        return (
            <ModalBasic
                open={showEditMediaModal}
                close={closeEditModal}
                unmountOnExit
            >
                <div style={{ padding: 25, margin: 10, maxWidth: 1000 }}>
                    <Grid container className="address-line" style={{ textAlign: "center" }} alignItems="center" spacing={2}>
                        <h3>Edit File</h3>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="File Name"
                                value={newMediaData.name}
                                variant="outlined"
                                onChange={(e) => {
                                    setNewMediaData({ ...newMediaData, name: e.target.value });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Autocomplete
                                value={newMediaData.category}
                                fullWidth={true}
                                options={["Images", "Tech", "Manuals", "Menu", "Packaging", "Firmware", "Data/Graphs", "Silk Screens", "Videos", "Other"]}
                                freeSolo={true}
                                onInputChange={(event, newValue) => {
                                    setNewMediaData({ ...newMediaData, category: newValue });
                                }}
                                getOptionLabel={(cat) => cat}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Category"}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                    // save
                                    const response = await makeAxiosCall(
                                        "post",
                                        "pd/media/" + newMediaData.id,
                                        {
                                            name: newMediaData.name,
                                            category: newMediaData.category
                                        }
                                    );

                                    if (response.status == 200) {
                                        refreshData();
                                        closeEditModal();
                                    } else {
                                        console.log(response);
                                    }
                                }}
                                className="btn blue"
                            >
                                Save <Save />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </ModalBasic>
        );
    }

    const _setMainProjectImage = (file: PDProjectMediaInterface) => {
        makeAxiosCall(
            "post",
            `pd/projects/` + project.id,
            {
                mainImageUrl: file.url
            }
        ).then((res) => {
            refreshData();
        }).catch((error) => {
            console.error("Error deleting media:");
            console.error(error);
        });
    };

    const downloadDocument = (file: PDProjectMediaInterface) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function () {
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = file.name; // Name the file anything you'd like.
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
        };
        xhr.open("GET", file.url);
        xhr.send();
    };

    const deleteDocument = (media: PDProjectMediaInterface) => {
        setDeleting(true);

        storage.refFromURL(media.url).delete().then(async () => {
            const response = await makeAxiosCall(
                "delete",
                `pd/media/${media.id}`
            ).catch((error) => {
                console.error("Error deleting media:");
                console.error(error);
            });

            if (response.status == 200) {
                if (deleteCallback) deleteCallback(media.id);
                refreshData();
            }
        }).catch((error) => {
            console.error("Error deleting document:");
            console.error(error);
            setDeleting(false);
        });
    };

    const renderItemAvatar = (document: PDProjectMediaInterface) => {
        let index = document.firebaseName.lastIndexOf(".");
        let filetype = document.firebaseName.substring(index + 1).trim().toLowerCase();
        switch (filetype) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
                return <Avatar
                    alt={document.name}
                    src={document.url}
                    onClick={() => selectCallback(document.id)}
                    style={{ display: "inline-block" }}
                />;
            case "pdf":
            case "doc":
            case "docx":
            case "txt":
                return <MenuBook onClick={() => selectCallback(document.id)} />
            case "dxf":
                return <Redeem onClick={() => selectCallback(document.id)} />
            case "xls":
            case "xlsx":
                return <Assessment onClick={() => selectCallback(document.id)} />
            case "svg":
            case "dwg":
                return <Print onClick={() => selectCallback(document.id)} />
            case "mov":
            case "mp4":
            case "avi":
            case "wmv":
                return <Movie onClick={() => selectCallback(document.id)} />
            default:
                return <FileCopy onClick={() => selectCallback(document.id)} />;
        }
    }

    return (
        <div key={mediaItem.id + "-list-item"}>
            {renderEditMediaModal()}
            <ListItem alignItems="flex-start">
                <ListItemAvatar style={{ textAlign: "center", margin: "auto" }}>
                    {renderItemAvatar(mediaItem)}
                </ListItemAvatar>

                <ListItemText
                    primary={mediaItem.name}
                    secondary={
                        <React.Fragment>
                            Uploaded {mediaItem.createdAt.toLocaleDateString()}
                        </React.Fragment>
                    }
                    onClick={() => window.open(mediaItem.url, "_blank")}
                    style={{ cursor: "pointer" }}
                />

                <div>
                    {mediaItem.category == "Images" && (
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={() => _setMainProjectImage(mediaItem)}
                        >
                            {mediaItem.url == project.mainImage ? (
                                <Star />
                            ) : (
                                <StarOutline />
                            )}
                        </IconButton>
                    )}

                    <IconButton
                        style={{
                            position: "relative",
                            right: 20,
                        }}
                        onClick={() => deleteDocument(mediaItem)}
                    >
                        {deleting ? (
                            <CircularProgress
                                size={24}
                                color="inherit"
                            />
                        ) : (
                            <DeleteForever
                                style={{ color: "#828282" }}
                            />
                        )}
                    </IconButton>

                    <IconButton
                        style={{
                            position: "relative",
                            right: 20,
                        }}
                        onClick={() => editMedia(mediaItem)}
                    >
                        {deleting ? (
                            <CircularProgress
                                size={24}
                                color="inherit"
                            />
                        ) : (
                            <Edit
                                style={{ color: "#828282" }}
                            />
                        )}
                    </IconButton>

                    <IconButton
                        style={{
                            position: "relative",
                            right: 20,
                        }}
                        onClick={() => downloadDocument(mediaItem)}
                    >
                        <GetApp color="primary" />
                    </IconButton>
                </div>

                <Checkbox
                    checked={selected}
                    onChange={() => selectCallback(mediaItem.id)}
                />
            </ListItem>
            <Divider variant="inset" component="li" />
        </div>
    );
}

export default PDProjectMediaListItem;