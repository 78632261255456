import React from "react";
import { storage } from "base.js";
import Loading from "components/utils/Loading";
import _ from "lodash";
import { Button, Checkbox, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, useTheme, Zoom } from "@material-ui/core";
import { UserContext } from "context/User";
import DocumentUpload from "components/Admin/DocumentUpload";
import { logFileDownload, makeAxiosCall } from "utils";
import { DocumentInterface, buildCompanyDocumentList } from "interfaces/Documents";
import { FileCopy, DeleteForever, GetApp } from "@material-ui/icons";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";
import { CompanyContext } from "context/Company";

const GenericDocuments = () => {

    const { internalUser, currentUser, accountLevels } = React.useContext(UserContext);
    const { companyTypes } = React.useContext(CompanyContext);
    const [companyDocuments, setCompanyDocuments] = React.useState<DocumentInterface[]>();
    const [selectedMedia, setSelectedMedia] = React.useState<DocumentInterface[]>([]);
    const [mediaBeingDeleted, setMediaBeingDeleted] = React.useState<DocumentInterface[]>([]);
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    let isMounted = true;

    React.useEffect(() => {
        fetchGenericDocuments();
        return () => {
            isMounted = false;
        };
    }, []);

    const fetchGenericDocuments = async () => {
        const response = await makeAxiosCall(
            "get",
            "company-documents/4/0"
        ).catch(error => {
            console.error("Caught error getting company documents:");
            console.error(error);
        });

        if (response.data) {
            const documentList = buildCompanyDocumentList(response.data);

            if (isMounted) {
                setCompanyDocuments(documentList);
            }
        }
    };

    const visibleDocuments = React.useMemo(() => {
        if (companyDocuments) {
            return _.orderBy(
                companyDocuments.filter(document => document.accountLevelId <= currentUser.accountLevel.id),
                ["createdAt"],
                ["desc"],
            );
        } else {
            return [];
        }
    }, [companyDocuments, currentUser]);

    const handleSelectMedia = (documentId: number) => {
        if (selectedMedia.some((i) => i.id == documentId)) {
            setSelectedMedia(selectedMedia.filter((i) => i.id != documentId));
        } else {
            setSelectedMedia([...selectedMedia, visibleDocuments.find((i) => i.id == documentId)]);
        }
    };

    const handleSelectAllDownloads = () => {
        if (selectedMedia.length == visibleDocuments.length) {
            setSelectedMedia([]);
        } else {
            setSelectedMedia([...visibleDocuments]);
        }
    };

    const downloadDocument = (file: DocumentInterface) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function () {
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = file.name; // Name the file anything you'd like.
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
        };
        xhr.open("GET", file.url);
        xhr.send();
        
        logFileDownload(file.name, file.url, null, file.id);
    };

    const handleBulkDownload = async () => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = "blizzard-lighting-documents.zip";

        selectedMedia.forEach((file) => {
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }
                logFileDownload(file.name, file.url, null, file.id);
                zip.file(file.firebaseName, data, { binary: true });
                count++;
                if (count == selectedMedia.length) {
                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        FileSaver.saveAs(content, zipFilename);
                        setSelectedMedia([]);
                    });
                }
            });
        });
    };

    const deleteDocument = (document: DocumentInterface) => {
        setMediaBeingDeleted([...mediaBeingDeleted, document]);

        storage
            .ref(`/portal_company_documents/all/other_documents/${document.firebaseName}`)
            .delete()
            .then(async () => {
                const response = await makeAxiosCall(
                    "delete",
                    `company-documents/${document.id}`
                ).catch((error) => {
                    console.error("Error deleting document:");
                    console.error(error);
                });

                if (response?.data?.id) {
                    fetchGenericDocuments();
                } else {
                    setMediaBeingDeleted(mediaBeingDeleted.filter((d) => d.id != document.id));
                }
            })
            .catch((error) => {
                console.error("Error deleting document:");
                console.error(error);
                setMediaBeingDeleted(mediaBeingDeleted.filter((d) => d.id != document.id));
            });
    };

    const renderListItem = (document: DocumentInterface) => {
        return (
            <div key={document.id + "-list-item"}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <FileCopy onClick={() => handleSelectMedia(document.id)} />
                    </ListItemAvatar>

                    <ListItemText
                        primary={document.name}
                        secondary={
                            <React.Fragment>
                                Uploaded {document.createdAt.toLocaleDateString()}
                            </React.Fragment>
                        }
                        onClick={() => window.open(document.url, "_blank")}
                        style={{ cursor: "pointer" }}
                    />

                    <div>
                        {internalUser && currentUser?.accountLevel?.canManageMembers ?
                            <>
                                <span
                                    style={{
                                        marginRight: 30
                                    }}
                                >
                                    Visible to {accountLevels.find((l) => l.id == document.accountLevelId).name} users
                                    of {companyTypes.find((t) => t.id == document.companyTypeId).name} companies
                                </span>

                                <IconButton
                                    style={{
                                        position: "relative",
                                        right: 20,
                                    }}
                                    onClick={() => deleteDocument(document)}
                                >
                                    {mediaBeingDeleted.some(d => d.id == document.id) ? (
                                        <CircularProgress
                                            size={24}
                                            color="inherit"
                                        />
                                    ) : (
                                        <DeleteForever
                                            style={{ color: "#828282" }}
                                        />
                                    )}
                                </IconButton>
                            </>
                            : null}


                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={() => downloadDocument(document)}
                        >
                            <GetApp color="primary" />
                        </IconButton>
                    </div>

                    <Checkbox
                        checked={selectedMedia.some((i) => i.id == document.id)}
                        onChange={() => handleSelectMedia(document.id)}
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </div>
        );
    };

    const renderDocumentList = () => {
        const documentList = [];

        for (let di = 0; di < visibleDocuments.length; di++) {
            const document = visibleDocuments[di];
            documentList.push(renderListItem(document));
        }

        documentList.push(
            <ListItem key="bulkzone" alignItems="flex-start" style={{ height: 50 }}>
                <ListItemText primary={" "} />

                <Zoom
                    in={selectedMedia.length > 0}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${selectedMedia.length > 0 ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >

                    <Button
                        type="submit"
                        variant="contained"
                        color={"primary"}
                        className="btn"
                        fullWidth={true}
                        onClick={() => {
                            handleBulkDownload();
                        }}
                        endIcon={
                            <GetApp />
                        }
                        style={{ marginLeft: 15 }}
                    >
                        {"Zip and Download " + selectedMedia.length + " file" + (selectedMedia.length > 1 ? "s" : "")}
                    </Button>
                </Zoom>

                <Checkbox
                    checked={selectedMedia.length == visibleDocuments.length}
                    onChange={handleSelectAllDownloads}
                />
            </ListItem>
        );

        return (
            <List>
                {documentList}
            </List>
        );
    };

    const renderBody = () => {
        if (companyDocuments) {
            if (visibleDocuments.length > 0) {
                return (
                    <Grid item xs={12}>
                        {renderDocumentList()}
                    </Grid>
                );
            } else {
                return <p className="body-message">No Documents Found</p>;
            }
        } else {
            return <Loading height="40vh" title="" position="relative" />;
        }
    };

    return (
        <>
            <Grid container alignItems="center" >
                <Grid item xs={12} sm={9}>
                    <h2>Documents</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {internalUser && currentUser?.accountLevel?.canManageMembers && (
                        <DocumentUpload
                            creationCallback={fetchGenericDocuments}
                        />
                    )}
                </Grid>
                {renderBody()}
            </Grid>
        </>
    );
};

export default GenericDocuments;