import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

export default class CustomerDetails extends Component {
  renderDistributor() {
    const config = this.props.config;
    if (this.props.distributorStatus || this.props.admin) {
      return (
        <div>
          <TextField
            id="yourName"
            label="Your Name"
            margin="normal"
            value={config.yourName}
            InputProps={{
              className: this.props.classes.input,
            }}
            onChange={this.props.handleInputChange("yourName")}
          />
          {/* <TextField
            id="discount"
            label="Discount"
            margin="normal"
            type="number"
            value={config.discount}
            InputProps={{
              min: "0",
              max: "15",
              step: "5",
              endAdornment: (
                <InputAdornment variant="filled" position="end">
                  %
                </InputAdornment>
              )
            }}
            onChange={this.props.handleInputChange("discount")}
          /> */}
        </div>
      );
    }
  }
  render() {
    const config = this.props.config;
    return (
      <div className="form-section">
        <div className="input-styles">
          {/* <TextField
            id="discount"
            label="Give Project a Name"
            margin="normal"
            value={config.saveName}
            InputProps={{
              className: this.props.classes.input,
            }}
            onChange={this.props.handleInputChange("saveName")}
          /> */}
        </div>
      </div>
    );
  }
}
