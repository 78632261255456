import React, { useState, createContext } from "react";
import { UserContext } from "./User";
import { makeAxiosCall } from "utils";
import { BuildOrderStatusOptions, OrderItemInterface, OrderInterface, OrderStatusInterface, CreateEmptyOrder } from "interfaces/Order";
import { CompanyInterface } from "interfaces/Company";
import { AuthorizePaymentProfileInterface, CCDataInterface, CCInfoInterface } from "interfaces/Payment";


interface CartContextInterface {
    orderId?: number,
    setOrderId: (orderId: number | null) => void,
    ccInfo: CCInfoInterface,
    loadPreviousOrder: (order: OrderInterface) => void,
    deleteCartItem: (badProduct: OrderItemInterface) => void,
    updateCartItem: (product: OrderItemInterface) => void,
    addCartItem: (product: any) => void,
    clearCart: () => void,
    savePaymentOption: (paymentOption: CCDataInterface) => void,
    fetchCC: (gatewayProfileId: number) => void,
    orderingOnBehalfOf: CompanyInterface,
    setOrderAs: (company: CompanyInterface) => void,
    orderStatusOptions: Array<OrderStatusInterface>,
    saveCurrentActiveStep: (step: number) => void,
    currentActiveStep: number,
    currentOrder: OrderInterface,
    saveCurrentOrder: (order: OrderInterface) => void,
    saveCurrentOrderNote: (note: string) => void,
    currentOrderNote: string,
    saveOrderingDemo: (demo: boolean) => void,
}


export const CartContext = createContext<CartContextInterface>(null);

interface CartProviderInterface {
    children: any,
}

export const CartProvider = ({ children }: CartProviderInterface) => {
    const [paymentOption, setPaymentOption] = useState<AuthorizePaymentProfileInterface>();
    const [ccInfo, setCCInfo] = useState<CCInfoInterface>();
    const [orderingOnBehalfOf, setOrderingOnBehalfOf] = useState<CompanyInterface>();
    const [orderId, setOrderId] = useState<number | null>();
    const [currentActiveStep, setCurrentActiveStep] = useState<number | null>();
    const [orderStatusOptions, setOrderStatusOptions] = useState<OrderStatusInterface[]>([]);
    const [currentOrderNote, setCurrentOrderNote] = useState<string>("");

    const [currentOrder, setCurrentOrder] = useState<OrderInterface>();

    const { userToken, currentUser } = React.useContext(UserContext);

    const saveCurrentActiveStep = (step: number) => {
        setCurrentActiveStep(step);
        localStorage.setItem("checkout-step", "" + step);
    };

    const setOrderAs = (company: CompanyInterface) => {
        setOrderingOnBehalfOf(company);

        if (!currentOrder.id) {
            const updatedOrder = CreateEmptyOrder(company, currentUser);
            updatedOrder.orderItems = currentOrder.orderItems;
            saveCurrentOrder(updatedOrder);
        }

        localStorage.setItem("orderas", JSON.stringify(company));
    };

    const saveCurrentOrderNote = (note: string) => {
        setCurrentOrderNote(note);
        localStorage.setItem("current-order-note", note);
    };

    const saveCurrentOrder = (order: OrderInterface) => {

        console.log("Saving order");
        console.log(order);


        setCurrentOrder(order);
        localStorage.setItem("current-order", JSON.stringify(order));
    };


    const saveOrderingDemo = (demo: boolean) => {
        saveCurrentOrder({ ...currentOrder, demoOrder: demo });
    };


    const savePaymentOption = (paymentOption: CCDataInterface) => {
        if (!paymentOption) {
            setPaymentOption(null);
            localStorage.removeItem("cart-payment-option");
        } else {
            if (paymentOption.address) {
                saveCurrentOrder({ ...currentOrder, billingAddress: paymentOption.address, authorizePaymentProfileId: paymentOption.card.authorizeCustomerPaymentProfileId });
            } else {
                saveCurrentOrder({ ...currentOrder, authorizePaymentProfileId: paymentOption.card.authorizeCustomerPaymentProfileId });
            }
            setPaymentOption({
                customerPaymentProfileId: paymentOption.card.authorizeCustomerPaymentProfileId,
                payment: {
                    creditCard: {
                        cardNumber: paymentOption.card.cardNumber,
                        expirationDate: paymentOption.card.expirationDate,
                        cardType: paymentOption.card.cardType,
                        issuerNumber: paymentOption.card.issuerNumber
                    }
                }
            });
            localStorage.setItem("cart-payment-option", JSON.stringify(paymentOption.card));
        }
    };

    const loadPreviousOrder = (order: OrderInterface) => {
        if (order.company.id != currentUser.company.id) {
            setOrderAs(order.company);
        }

        order.orderItems = order.orderItems.sort((a, b) => a.product.name.localeCompare(b.product.name));
        saveCurrentOrder(order);
    };

    const deleteCartItem = (badProduct: OrderItemInterface) => {
        let newCart = [...currentOrder.orderItems];

        newCart = newCart.filter((item: OrderItemInterface) => item.product.id !== badProduct.product.id);

        if (orderId) {
            makeAxiosCall(
                "delete",
                `order-item/${badProduct.id}`
            ).then(res => {
                console.log("Removed item from cart");
            }).catch(error => {
                console.error(error);
            });
        }

        newCart = newCart.sort((a, b) => a.product.name.localeCompare(b.product.name));
        saveCurrentOrder({ ...currentOrder, orderItems: newCart });
    };


    const updateCartItem = (product: OrderItemInterface) => {
        let newCart = [...currentOrder.orderItems];

        newCart = newCart.filter((item: OrderItemInterface) => item.product.id !== product.product.id);

        newCart.push(product);

        newCart = newCart.sort((a, b) => a.product.name.localeCompare(b.product.name));

        saveCurrentOrder({ ...currentOrder, orderItems: newCart });
    };

    const addCartItem = (product: OrderItemInterface) => {
        let newCart = [...currentOrder.orderItems];

        if (newCart.some((prod) => prod.product.id == product.product.id)) {
            const existingIndex = newCart.findIndex((prod) => prod.product.id == product.product.id);
            newCart[existingIndex] = { ...newCart[existingIndex], quantity: newCart[existingIndex].quantity + product.quantity };
        } else {
            newCart.push(product);
        }

        newCart = newCart.sort((a, b) => a.product.name.localeCompare(b.product.name));

        saveCurrentOrder({ ...currentOrder, orderItems: newCart });
        saveCurrentActiveStep(0);
    };

    const clearCart = () => {
        localStorage.removeItem("checkout-step");
        localStorage.removeItem("orderas");

        if(!currentUser) {
            setOrderingOnBehalfOf(null);
        } else {
            setOrderingOnBehalfOf(currentUser.company);
            saveCurrentOrder(CreateEmptyOrder(currentUser.company, currentUser));
        }
    };

    const fetchCC = React.useCallback(
        async (gatewayProfileId) => {
            if (gatewayProfileId !== undefined) {
                makeAxiosCall(
                    "get",
                    "cc/" + gatewayProfileId,
                )
                    .then((res) => {
                        setCCInfo(res.data);
                    })
                    .catch((error) => console.log("Error:" + error));
            } else {
                setCCInfo(null);
            }
        },
        [userToken]
    );

    const loadOrderStatusOptions = () => {
        if (!orderStatusOptions || orderStatusOptions.length < 1) {
            makeAxiosCall(
                "get",
                "order-status-options",
            )
                .then((res) => {
                    const options = res.data;
                    if (options && options.length > 0) {
                        localStorage.setItem("order-status-options", JSON.stringify(res.data));
                        setOrderStatusOptions(BuildOrderStatusOptions(res.data));
                    }
                })
                .catch((error) => console.log("Error:" + error));
        }
    };


    React.useEffect(() => {
        if (currentUser && currentUser.company) {
            try {
                const localStatusOptions = localStorage.getItem("order-status-options");
                if (localStatusOptions) {
                    setOrderStatusOptions(JSON.parse(localStatusOptions));
                }

                const localActiveStep = localStorage.getItem("checkout-step");
                if (localActiveStep) {
                    setCurrentActiveStep(parseInt(localActiveStep));
                } else {
                    setCurrentActiveStep(0);
                }

                const localCurrentOrder = localStorage.getItem("current-order");
                if (localCurrentOrder) {
                    setCurrentOrder(JSON.parse(localCurrentOrder));
                } else {
                    saveCurrentOrder(CreateEmptyOrder(currentUser.company, currentUser));
                }

                const localPaymentOption = localStorage.getItem("cart-payment-option");
                if (localPaymentOption) {
                    setPaymentOption(JSON.parse(localPaymentOption));
                }

                const localOA = localStorage.getItem("orderas");
                if (localOA) {
                    setOrderingOnBehalfOf(JSON.parse(localOA));
                } else if (!orderingOnBehalfOf) {
                    setOrderingOnBehalfOf(currentUser.company);
                }
            } catch (e) {
                console.log(e);
                clearCart();
            }

            let co: CompanyInterface;
            if (orderingOnBehalfOf) {
                co = orderingOnBehalfOf;
            } else {
                co = currentUser.company;
            }

            if (co && currentOrder) {
                if (!currentOrder.shippingAddress || !currentOrder.shippingAddress.id) {
                    const defaultShippingAddress = co.addresses.find((a) => a.addressTypes.find((at) => at.name == "Shipping"));
                    if (defaultShippingAddress) {
                        saveCurrentOrder({ ...currentOrder, shippingAddress: defaultShippingAddress });
                    }
                }

                if (!currentOrder.billingAddress || !currentOrder.billingAddress.id) {
                    saveCurrentOrder({ ...currentOrder, billingAddress: co.defaultBillingAddress });
                }
                if (!currentOrder.billingContact || !currentOrder.billingContact.id) {
                    saveCurrentOrder({ ...currentOrder, billingContact: co.defaultBillingContact });
                }
            }

            if (orderStatusOptions.length < 1) {
                loadOrderStatusOptions();
            }
        }
    }, [currentUser]);

    return (
        <CartContext.Provider
            value={{
                orderId,
                setOrderId,
                ccInfo,
                loadPreviousOrder,
                deleteCartItem,
                updateCartItem,
                addCartItem,
                savePaymentOption,
                fetchCC,
                orderingOnBehalfOf,
                setOrderAs,
                clearCart,
                orderStatusOptions,
                saveCurrentActiveStep,
                currentActiveStep,
                currentOrder,
                saveCurrentOrder,
                saveCurrentOrderNote,
                currentOrderNote,
                saveOrderingDemo
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
