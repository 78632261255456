const DefaultState = {
  loading: false,
  allProducts: [],
  errorMsg: "",
  irisPanels: [],
  irisProcessors: [],
  irisAddons: [],
  novastarTariffs: [],
};

export const productReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case "GET_PANELS_LOADING":
      return {
        ...state,
        errorMsg: "",
      };
    case "GET_PANELS_FAIL":
      return {
        ...state,
        errorMsg: "Eror Loading PANELS",
      };
    case "GET_PANELS_SUCCESS":
      return {
        ...state,
        loading: false,
        irisPanels: action.payload,
        errMsg: "",
      };
    case "GET_PROCESSORS_LOADING":
      return {
        ...state,
        errorMsg: "",
      };
    case "GET_PROCESSORS_FAIL":
      return {
        ...state,
        errorMsg: "Eror Loading PROCESSORS",
      };
    case "GET_PROCESSORS_SUCCESS":
      return {
        ...state,
        loading: false,
        irisProcessors: action.payload,
        errMsg: "",
      };
    case "GET_ADDONS_LOADING":
      return {
        ...state,
        errorMsg: "",
      };
    case "GET_ADDONS_FAIL":
      return {
        ...state,
        errorMsg: "Eror Loading ADDONS",
      };
    case "GET_ADDONS_SUCCESS":
      return {
        ...state,
        loading: false,
        irisAddons: action.payload,
        errMsg: "",
      };
    case "GET_PRODUCTS_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_PRODUCTS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Eror Loading Products",
      };
    case "GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        loading: false,
        allProducts: action.payload,
        errMsg: "",
      };
    case "GET_TARIFFS_LOADING":
      return {
        ...state,
        errorMsg: "",
      };
    case "GET_TARIFFS_FAIL":
      return {
        ...state,
        errorMsg: "Eror Loading TARIFFS",
      };
    case "GET_TARIFFS_SUCCESS":
      return {
        ...state,
        novastarTariffs: action.payload,
        errMsg: "",
      };
    default:
      return state;
  }
};
