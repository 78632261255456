import React, { useContext } from "react";
import { UserContext } from "context/User";
import Home from "components/Home";
import AuthPortal from "components/Auth/AuthPortal";
import Loading from "components/utils/Loading";
import { isIE } from "react-device-detect";
import UnauthorizedPage from "./UnauthorizedPage";
import RestrictedPage from "./RestrictedPage";
import { Button, Grid } from "@material-ui/core";

const PrivateRoute = ({ children, admin }) => {
  const { currentUser, isLoading, internalUser, signOut } = useContext(UserContext);
  if (!isIE) {
    if (currentUser) {
      if (!admin) {
        if (currentUser.company && currentUser.accountLevel.name == "Restricted") {
          return <RestrictedPage />
        } else {
          return <Home>{children}</Home>;
        }
      } else {
        if (internalUser) {
          return <Home>{children}</Home>
        } else {
          return <Home><UnauthorizedPage /></Home>
        }
      }
    } else if (isLoading) {
      return (<Grid container spacing={2}>
        <Grid item xs={12}>
          <Loading height="60vh" position="relative" title="Loading"/>
        </Grid>
        <Grid item xs={12}>
          <p style={{textAlign: "center", width: "100%", fontWeight: "bold"}}>Stuck?</p>
          <Button
            onClick={() => {
              signOut();
            }}
            variant="contained"
            style={{ width: "200px", margin: "auto", display: "block" }}
            color="primary"
            className="btn"
          >
            Sign out
          </Button>
        </Grid>
      </Grid>);
    } else {
      return <AuthPortal />;
    }
  } else {
    return (
      <div className="notif">
        <div className="container">
          <i className="far fa-lightbulb-exclamation"></i>
          <h2>Insecure/Outdated Browser</h2>
          <p>
            In order to protect your information and site security, we recommend
            you use a modern browser.
          </p>
          <h3>Supported Browsers</h3>
          <div className="icon-flex">
            <a href="https://www.google.com/chrome/">
              <i className="fab fa-chrome"></i>Chrome ›
            </a>
            <a href="https://support.apple.com/downloads/safari">
              <i className="fab fa-safari"></i>Safari ›
            </a>
            <a href="https://www.mozilla.org/en-US/firefox/new/">
              <i className="fab fa-firefox"></i>Firefox ›
            </a>
            <a href="https://www.microsoft.com/en-us/edge">
              <i className="fab fa-edge"></i>Edge ›
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default PrivateRoute;
