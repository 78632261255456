import React, { useState } from "react";
import { useContext } from "react";
import { UserContext } from "context/User";
import _ from "lodash";
import { storage, storageRef } from "base.js";
import { Button, TextField } from "@material-ui/core";
import { handleDownload } from "utils";

const MyFiles = () => {
  const { user } = useContext(UserContext);
  const [currentUserFiles, setCurrentUserFiles] = React.useState([]);
  const [input, setInput] = useState("");

  let searchResults = [];

  const getFiles = () => {
    if (user) {
      let files = [];
      const userStorageRef = storageRef.child(
        `/resale-certificate/${user.uid}`
      );

      userStorageRef
        .listAll()
        .then((res) => {
          let count = res.items.length;
          res.items.forEach((itemRef) => {
            const pathRef = storage.ref(itemRef.fullPath);
            pathRef.getDownloadURL().then((url) => {
              files.push({ url: url, name: itemRef.name });

              if (currentUserFiles.length === 0 && files.length === count) {
                setCurrentUserFiles(files);
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });

      return files;
    }
  };

  const renderFiles = () => {
    const searchInput = input.toLowerCase(),
      inputIgnoredChar = searchInput.replace(/[^a-zA-Z ]/g, " ");

    searchResults = _.filter(currentUserFiles, (o) => {
      let name = "",
        url = "";

      if (o.name !== null) {
        name = o.name.toLowerCase();
      }
      if (o.url !== null) {
        url = o.url;
      }

      const arr = [name, url];
      const allTerms = arr.join();

      return _.includes(allTerms, inputIgnoredChar);
    });

    if (searchResults.length > 0) {
      return _.map(
        _.orderBy(searchResults, [(results) => results.name], ["asc"]),
        (file, i) => {
          return (
            <div className="line-item" style={{ textAlign: "left" }}>
              <div className="" style={{ textAlign: "left" }}>
                {file.name}
              </div>
              <Button
                type="submit"
                style={{ width: 150 }}
                variant="contained"
                color="primary"
                className="btn"
                onClick={() => handleDownload(file.url, file.name)}
              >
                Download
              </Button>
            </div>
          );
        }
      );
    }
  };

  React.useEffect(() => {
    getFiles();
  }, [user]);

  return (
    <div className="view">
      <h2>My Files</h2>

      <div className="inventory-search">
        <TextField
          label="File Search"
          variant="outlined"
          onChange={(e) => setInput(e.target.value)}
          fullWidth
        />
      </div>
      <div className="inventory-results" style={{ marginTop: 30 }}>
        {renderFiles()}
      </div>
    </div>
  );
};

export default MyFiles;
