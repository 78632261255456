import React, { useContext, useState } from "react";
import { app } from "base.js";
import {
    Button,
    TextField,
    CircularProgress,
} from "@material-ui/core";
import { updateHubspotContact, makeAxiosCall } from "utils";
import { CartContext } from "context/Cart";

interface SignUpProps {
    setActiveAuth: (auth: string) => void,
}

const SignUp = ({ setActiveAuth }: SignUpProps) => {
    const { clearCart } = useContext(CartContext);
    const [pw, setPw] = useState("");
    const [pw2, setPw2] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const urlParams = new URLSearchParams(window.location.search);
    const emailRef = urlParams.get("email");
    const newReseller = urlParams.get("newReseller");

    const pwValidate = pw === pw2 && pw.length > 7;
    const nameValidate = firstName.length > 0 && lastName.length > 0;

    const validate = pwValidate && nameValidate;

    React.useEffect(() => {
        if (emailRef) {
            setEmail(emailRef);
        }
    }, [emailRef]);

    const handleSignUp = async () => {
        // cancelUserListener();
        setLoading(true);

        clearCart();
        const auth = await app.auth();
        try {
            const signUp = await auth.createUserWithEmailAndPassword(email, pw);
            createUser(signUp.user.uid, email);
        } catch (error) {
            if (error.code === "auth/email-already-in-use") { //IF error is EMAIL_EXISTS
                // Check if the user has data in firebase
                const existingUserData = await makeAxiosCall(
                    "post",
                    "check-user-exists",
                    {
                        email: email,
                    }
                ).then((r) => r.data).catch(e => console.log(e));

                console.log(existingUserData);

                if (existingUserData.exists) {
                    setError(error.message);
                } else { // if user exists in auth but not in db, continue with user creation
                    app.auth().signInWithEmailAndPassword(email, pw).then(function (user) {
                        createUser(user.user.uid, email);
                    }).catch(() => 
                        setError("That email is already in use, and your password does not match.")
                    );
                }
            } else {
                setError(error.message);
            }
            setLoading(false);
        }
    };

    const createUser = async (uid, email) => {
        console.log("TRYING TO CREATE USER");
        //Update Contact in Hubspot
        updateHubspotContact({
            email: email,
            reseller_portal_user: true,
        });

        makeAxiosCall(
            "post",
            "account",
            {
                id: uid,
                email: email,
                firstName: firstName ?? "",
                lastName: lastName ?? "",
                companyName: companyName
            }
        ).then((result) => {
            console.log("TRIED TO CREATE USER");
            console.log(result);
            if (newReseller) {
                window.location.assign("/apply");
            } else {
                window.location.reload();
            }
        }).catch(e => {
            console.log("FAILED TO CREATE USER");
            console.log(e);
            console.log(e.data);
            setLoading(false);
        });
    };

    return (
        <div>
            <h1>Create an Account</h1>
            <p className="underline" onClick={() => setActiveAuth("login")}>
                Already have an account?
            </p>
            <TextField
                className="input-full"
                label="Email"
                autoComplete="off"
                margin="normal"
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                value={email}
                inputProps={{
                    name: "email",
                }}
                required
            />
            {pw.length > 1 && email.length === 0 && (
                <div className="highlight red">Please provide your email </div>
            )}
            <div
                className="flex-container"
                style={{ justifyContent: "space-between" }}
            >
                <TextField
                    className="input-full"
                    label="First Name"
                    autoComplete="off"
                    margin="normal"
                    style={{ width: "48%" }}
                    variant="outlined"
                    onChange={(e) => setFirstName(e.target.value)}
                    inputProps={{
                        name: "firstname",
                    }}
                    required
                />

                <TextField
                    className="input-full"
                    label="Last Name"
                    autoComplete="off"
                    margin="normal"
                    style={{ width: "48%" }}
                    variant="outlined"
                    onChange={(e) => setLastName(e.target.value)}
                    inputProps={{
                        name: "lastname",
                    }}
                    required
                />
            </div>

            {!emailRef && (
                <TextField
                    className="input-full"
                    label="Company Name"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => setCompanyName(e.target.value)}
                    inputProps={{
                        name: "company-name",
                    }}
                />
            )}

            <TextField
                className="input-full"
                label="Password"
                autoComplete="off"
                margin="normal"
                variant="outlined"
                type="password"
                onChange={(e) => setPw(e.target.value)}
                inputProps={{
                    name: "password",
                }}
                required
            />

            {pw.length < 8 && pw.length > 1 && (
                <div className="highlight red">
                    Please enter a password that is greater than 8 characters.
                </div>
            )}

            <TextField
                className="input-full"
                label="Confirm Password"
                autoComplete="off"
                margin="normal"
                onChange={(e) => setPw2(e.target.value)}
                variant="outlined"
                type="password"
                inputProps={{
                    name: "confirm-password",
                }}
                required
            />

            {pw !== pw2 && pw.length > 3 && pw2.length > 3 && (
                <div className="highlight red">Your passwords do no match.</div>
            )}

            <div style={{
              textAlign: "right",
              marginTop: "0.5em",
              color: "rgba(0, 0, 0, 0.26)",
            }}>
              * Required
            </div>

            <Button
                style={{ width: "100%", marginTop: 10 }}
                onClick={() => handleSignUp()}
                variant="contained"
                color="primary"
                disabled={!validate}
            >
                {loading ?
                    <CircularProgress
                        color="inherit"
                        size={25}
                    /> :
                    "Sign Up"
                }
            </Button>
            {error && <p className="error">{error}</p>}
            {/* <Infobox
        title="Not Yet Registered?"
        paragraph="Request an invite code to gain access to the Blizzard Customer Portal"
        buttonTitle="Request Invite"
      /> */}
        </div>
    );
};

export default SignUp;
