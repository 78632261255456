import { AddressInterface, buildAddresses } from "./Address";
import { buildContacts, ContactInterface } from "./Contact";

export interface ResellerApprovalFormInterface {
    id?: number,
    // STEP 1
    legalEntityName: string,
    dbas?: string,
    businessType: string,
    // STEP 2
    addresses: Array<AddressInterface>,
    // STEP 3
    contacts: Array<ContactInterface>,
    // STEP 4
    businessDescription: string,
    website: string,
    connections: Array<string>,
    thirdPartyMarketing: Array<string>,
    primaryLineOfBusiness: string,
    // STEP 5
    percentProduction: string,
    percentTheatrical: string,
    percentWorship: string,
    percentDJ: string,
    percentArchitectural: string,
    percentOther: string,
    retailStore: boolean,
    showRoom: boolean
}

export const buildResellerInfo = (resellerData, addresses?: Array<AddressInterface>, contacts?: Array<ContactInterface>): ResellerApprovalFormInterface => {

    let addressList = [];
    if (addresses) {
        addressList = addresses;
    } else if (resellerData.company) {
        addressList = buildAddresses(resellerData.company.addresses);
    }


    let contactList = [];
    if (contacts) {
        contactList = contacts;
    } else if (resellerData.company) {
        contactList = buildContacts(resellerData.company.contacts);
    }

    return {
        id: resellerData.id,
        legalEntityName: resellerData.legal_entity_name,
        dbas: resellerData.dbas,
        businessType: resellerData.business_type,
        addresses: addressList,
        contacts: contactList,
        businessDescription: resellerData.primary_business_description,
        website: resellerData.website,
        connections: resellerData.connections.split(", "),
        thirdPartyMarketing: resellerData.third_party_marketing.split(", "),
        primaryLineOfBusiness: resellerData.primary_line_of_business,
        percentProduction: resellerData.production_business_percentage,
        percentTheatrical: resellerData.theatrical_business_percentage,
        percentWorship: resellerData.worship_business_percentage,
        percentDJ: resellerData.dj_business_percentage,
        percentArchitectural: resellerData.architectural_business_percentage,
        percentOther: resellerData.other_business_percentage,
        retailStore: resellerData.has_retail_store,
        showRoom: resellerData.has_showroom
    };
};

export const roleOptions = [
    "Restricted",
    "Viewer",
    "Member",
    "Admin"
];

export const resellerConnectionOptions = [
    "Email",
    "Trade Shows",
    "Social Media",
    "Online Paid Search",
    "Print",
    "In-person/In-store",
    "Online display networks",
    "Other"
];

export const thirdPartyMarketingOptions = [
    "Amazon",
    "Ebay",
    "Google",
    "Bing",
    "Facebook",
    "Reverb.com",
    "Instagram",
    "Other"
];

export const primaryLineOfBusinessOptions = [
    "Retail/E-tail",
    "Production",
    "Systems integration",
    "Other"
];

export const percentBusinessOptions = [
    "None",
    "0-25%",
    "25-50%",
    "50-75%",
    "75-100%"
];