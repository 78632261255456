
import React from "react";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { CompanyInterface } from "interfaces/Company";
import { makeAxiosCall } from "utils";
import Loading from "components/utils/Loading";
import { UserContext } from "context/User";
import PaymentBook from "components/Account/PaymentBook";
import { Button, FormControl, InputLabel, ListSubheader, MenuItem, Select, Snackbar, Switch, TextField } from "@material-ui/core";
import AddressBook from "components/Account/Address/AddressBook";
import { AddressInterface } from "interfaces/Address";
import { buildShippingOptions, NoShippingPreference, ShippingCarrierInterface, ShippingCarrierServiceInterface } from "interfaces/ShippingPreference";
import ContactSelect from "components/Account/Contact/ContactSelect";
import { ContactInterface } from "interfaces/Contact";
import ContactEditForm from "./ContactEditForm";
import { Alert } from "@material-ui/lab";
import { CompanyContext } from "context/Company";

interface CompanyBillingProps {
    company: CompanyInterface
}

const CompanyBilling = ({ company }: CompanyBillingProps) => {
    const { fetchCurrentUser, currentUser } = React.useContext(UserContext);
    const [shippingOptions, setShippingOptions] = React.useState<ShippingCarrierInterface[]>();
    const [accountNumber, setAccountNumber] = React.useState<string>();
    const [showBillingContactForm, setShowBillingContactForm] = React.useState<boolean>();
    const [showShippingOptions, setShowShippingOptions] = React.useState<boolean>();
    const [saving, setSaving] = React.useState<boolean>(false);
    const [selectedCarrier, setSelectedCarrier] = React.useState<ShippingCarrierInterface>(NoShippingPreference.carrier);
    const [selectedService, setSelectedService] = React.useState<ShippingCarrierServiceInterface>(NoShippingPreference.service);
    const [defaultBillingAddress, setDefaultBillingAddress] = React.useState<AddressInterface>();
    const [defaultBillingContact, setDefaultBillingContact] = React.useState<ContactInterface>();
    const { setCurrentCompany, currentCompany } = React.useContext(CompanyContext);
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });

    React.useEffect(() => {
        if (company) {
            setCurrentCompany(company);
            makeAxiosCall(
                "get",
                "shipping-options"
            ).catch((error) => {
                console.log(error);
            }).then((result) => {
                setShippingOptions(buildShippingOptions(result.data));
                if (company.defaultShippingPreference.carrier) {
                    setSelectedCarrier(company.defaultShippingPreference.carrier);
                }

                if (company.defaultShippingPreference.service) {
                    setSelectedService(company.defaultShippingPreference.service);
                }
            });

            setDefaultBillingAddress(company.defaultBillingAddress);
            setDefaultBillingContact(company.defaultBillingContact);
            if (company.defaultShippingPreference) {
                setAccountNumber(company.defaultShippingPreference.accountNumber);
            }

        }
    }, [company]);

    React.useEffect(() => {
        setCurrentCompany(currentUser.company);
    }, [currentUser]);

    const savePreferences = () => {
        setSaving(true);

        let prefData: any = {
            billingAddressId: defaultBillingAddress ? defaultBillingAddress.id : null,
            billingContactId: defaultBillingContact ? defaultBillingContact.id : null
        };

        if (showShippingOptions) {
            prefData = {
                ...prefData,
                shippingPreferenceId: currentCompany.defaultShippingPreference ? currentCompany.defaultShippingPreference.id : null,
                carrierId: selectedCarrier ? selectedCarrier.id : null,
                serviceId: selectedService ? selectedService.id : null,
                accountNumber: accountNumber
            };
        } else if (currentCompany.defaultShippingPreference && currentCompany.defaultShippingPreference.id) {
            prefData.deleteShippingPreferenceId = currentCompany.defaultShippingPreference.id;
        }


        makeAxiosCall(
            "post",
            "company-shipping-preferences/" + currentCompany.id,
            prefData
        ).then(response => {
            if (response.status === 200) {
                setSaving(false);
                setSnackbar({
                    isOpen: true,
                    message: "Saved Default Shipping Preferences",
                    severity: "success",
                });
            } else {
                setSaving(false);
                setSnackbar({
                    isOpen: true,
                    message: "Something went wrong. Please try again.",
                    severity: "error",
                });
            }
        }).catch(error => {
            console.error(error);
            setSaving(false);
            setSnackbar({
                isOpen: true,
                message: "Something went wrong. Please try again.",
                severity: "error",
            });
        });
    };

    const buildShippingCarrierSelections = () => {
        const inHouse: ShippingCarrierInterface[] = [];
        const thirdParty: ShippingCarrierInterface[] = [];

        if (shippingOptions) {
            shippingOptions.forEach((option) => {
                if (option.thirdParty) {
                    thirdParty.push(option);
                } else {
                    inHouse.push(option);
                }
            });
        }

        return [
            ..._.map(
                _.orderBy(inHouse, ["id"], ["asc"]),
                (option) => {
                    return <MenuItem key={"shipping-option-" + option.id} value={option.id}>{option.name}</MenuItem>;
                }
            ),
            <ListSubheader key="shipping-option-3rd-party">Third Party (Requires Account Number)</ListSubheader>,
            ..._.map(
                _.orderBy(thirdParty, ["name"], ["asc"]),
                (option) => {
                    return <MenuItem key={"shipping-option-" + option.id} value={option.id}>{option.name}</MenuItem>;
                }
            ),
        ];
    };

    const buildShippingServiceSelections = () => {
        const options = [];

        if (shippingOptions && selectedCarrier && selectedCarrier.id) {
            selectedCarrier.services.forEach((option) => {
                options.push(option);
            });
        }

        if (options.length == 0) {
            return [
                <MenuItem key={"shipping-option-0"} value={0}>None</MenuItem>
            ];
        }

        return [
            ..._.map(
                _.orderBy(options, ["name"], ["asc"]),
                (option) => {
                    return <MenuItem key={"shipping-option-" + option.id} value={option.id}>{option.name}</MenuItem>;
                }
            ),
        ];
    };


    return currentCompany ? (
        <div className="grid-search">
            <Grid container alignItems="center" >
                <Grid item xs={12}>
                    <h2>Billing</h2>
                </Grid>

                <Grid container item xs={12} spacing={2} style={{ padding: 16 }}>
                    <Grid item xs={12}>
                        <AddressBook
                            currentReseller={currentCompany}
                            key={1}
                            title="Select Default Billing Address"
                            type={"Billing"}
                            onSelect={(address: AddressInterface) => {
                                setCurrentCompany({ ...currentCompany, defaultBillingAddress: address });
                                setDefaultBillingAddress(address);
                                fetchCurrentUser();
                            }}
                            currentSelection={defaultBillingAddress}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ padding: 16 }}>
                        <Grid container spacing={2} className="new-cc-form">
                            <Grid item xs={12}>
                                <h3>Default Billing Contact</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <ContactSelect
                                    company={currentCompany}
                                    label="Default Billing Contact"
                                    setNewFormActive={(open) => {
                                        setShowBillingContactForm(open);
                                    }}
                                    currentSelection={defaultBillingContact}
                                    onSelect={(newContact: ContactInterface) => setDefaultBillingContact(newContact)}
                                />

                                {showBillingContactForm ? <ContactEditForm
                                    createCallback={(contact: ContactInterface, newId: number) => {
                                        contact.id = newId;
                                        currentCompany.contacts.push(contact);
                                        setDefaultBillingContact(contact);
                                        setCurrentCompany({ ...currentCompany, defaultBillingContact: contact, contacts: currentCompany.contacts });
                                        setShowBillingContactForm(false);
                                        fetchCurrentUser();
                                    }}
                                    companyId={currentCompany.id}
                                /> : null}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ padding: 16 }}>
                        <Grid container spacing={2} className="new-cc-form">
                            <Grid container item xs={12}>
                                <Grid item>
                                    <h3 className="center">Default Carrier Preferences</h3>
                                </Grid>
                                <Grid item style={{ marginTop: -8 }}>
                                    <Switch
                                        checked={showShippingOptions}
                                        onChange={() => {
                                            setShowShippingOptions(!showShippingOptions);
                                        }}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: "center", paddingBottom: 18 }}>
                                        Specifying carrier options may result in additional shipping charges, regardless of whether or not the order qualifies for free shipping.
                                    </p>
                                </Grid>
                            </Grid>
                            {showShippingOptions ?
                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="shipping-carrier-select">Default Carrier</InputLabel>
                                            <Select
                                                onChange={(e: any) => {
                                                    if (e.target.value) {
                                                        const selectedOption = shippingOptions.find((o) => o.id == e.target.value);
                                                        setSelectedCarrier(selectedOption);
                                                        setSelectedService(null);
                                                    }
                                                }}
                                                labelId="ship-pref-car-select-label"
                                                id="ship-pref-car-select"
                                                style={{ minWidth: 400 }}
                                                value={selectedCarrier ? selectedCarrier.id : 0}
                                            >
                                                {buildShippingCarrierSelections()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="shipping-service-select">Service</InputLabel>
                                            <Select
                                                onChange={(e: any) => {
                                                    if (e.target.value) {
                                                        setSelectedService(selectedCarrier.services.find((s) => s.id == e.target.value));
                                                    }
                                                }}
                                                labelId="ship-pref-serv-select-label"
                                                id="ship-pref-serv-select"
                                                style={{ minWidth: 400 }}
                                                value={selectedService ? selectedService.id : 0}
                                            >
                                                {buildShippingServiceSelections()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            style={{ width: "100%" }}
                                            label="Shipping Account Number"
                                            margin="normal"
                                            value={accountNumber}
                                            disabled={!selectedCarrier || !selectedCarrier.thirdParty}
                                            variant="outlined"
                                            onChange={(e) => {
                                                setAccountNumber(e.target.value);
                                            }}
                                            inputProps={{ maxLength: 40 }}
                                            placeholder={"Optional"}
                                        />
                                    </Grid>
                                </Grid>
                                : null}
                        </Grid>
                    </Grid>


                    <Grid item xs={12}>
                        <Button
                            onClick={savePreferences}
                            disabled={saving}
                            variant="contained"
                            color="primary"
                            style={{ float: "right" }}
                        >
                            {saving ? "Saving" : "Save Defaults"}
                        </Button>
                    </Grid>
                </Grid>

                <hr style={{ borderTop: "3px solid #bbb" }} />

                <Grid item xs={12}>
                    <PaymentBook cart={false} title="Add/Edit Payment Methods" />
                </Grid>

            </Grid>

            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={3000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );

};

export default CompanyBilling;