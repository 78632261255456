import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";
import allReducers from "./reducers";

const Store = createStore(
  allReducers,
  composeWithDevTools(applyMiddleware(thunk))
);

export default Store;
