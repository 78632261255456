import React from "react";
import TextField from "@material-ui/core/TextField";
import { states, countries } from "temp/index";
import _ from "lodash";
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Paper, Snackbar, Switch, Tooltip } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { UserContext } from "context/User";
import SuccessAni from "components/utils/SuccessAni";
import { CSSTransition } from "react-transition-group";
import { AddressContext } from "context/Address";
import "scss/components/Account/AddressForm.scss";
import { makeAxiosCall } from "utils/";
import { AddressInterface, AddressTypeInterface, createEmptyAddress, DEFAULT_ADDITIONAL_SHIPPING_OPTIONS } from "interfaces/Address";
import ContactSelect from "../Contact/ContactSelect";
import { ContactInterface } from "interfaces/Contact";
import ContactEditForm from "components/MyCompany/ContactEditForm";
import { buildCompanyInfo, CompanyInterface } from "interfaces/Company";
import { Alert, Autocomplete } from "@material-ui/lab";

interface AddressFormValidation {
    streetAddress: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    addressType: string,
    contact: string,
}

interface AddressFormProps {
    companyId: number,
    address?: AddressInterface,
    addCallback?: (address: AddressInterface) => void,
    updateCallback?: (address: AddressInterface, id: number) => void,
    deleteCallback?: (addressId: number) => void,
    addressType?: string,
    skipAdvancedOptions?: boolean
}

const AddressForm = ({ companyId, address, addCallback, updateCallback, deleteCallback, addressType, skipAdvancedOptions }: AddressFormProps) => {
    const { currentUser } = React.useContext(UserContext);
    const { addNewAddress, updateAddress, deleteAddress, addressTypes } = React.useContext(AddressContext);
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });

    const startingValidation: AddressFormValidation = {
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        addressType: "",
        contact: "",
    };

    const INITIAL_NEW_ADDRESS: AddressInterface = createEmptyAddress();

    const [form, setForm] = React.useState<AddressInterface>(INITIAL_NEW_ADDRESS);
    const [company, setCompany] = React.useState<CompanyInterface>();
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [failureAlert, setFailureAlert] = React.useState(false);
    const [showShippingContactForm, setShowShippingContactForm] = React.useState(false);
    const [showAdvancedShippingOptions, setShowAdvancedShippingOptions] = React.useState(false);
    const [currentProcess, setCurrentProcess] = React.useState<string>("");
    const [stateInputValue, setStateInputValue] = React.useState("");
    const [countryInputValue, setCountryInputValue] = React.useState("");
    const [validation, setValidation] = React.useState(startingValidation);
    const inputLabel = React.useRef(null);
    const formControl = "16px";

    React.useEffect(() => {
        if (!companyId || companyId == currentUser.company.id) {
            setCompany(currentUser.company);
        } else {
            fetchCompany(companyId);
        }
    }, []);

    React.useEffect(() => {
        if (address) {
            if (address.addressTypes.findIndex((t) => ["Shipping", "Primary", "Drop Ship"].includes(t.name)) > -1) {
                if(!address.additionalShippingDetails) {
                    address.additionalShippingDetails = DEFAULT_ADDITIONAL_SHIPPING_OPTIONS;
                }

                if(!address.additionalShippingDetails.contact) {
                    address.additionalShippingDetails.contact = currentUser.company.contacts[0];
                }

                setForm(address);
            } else {
                setForm(address);
            }
        } else {
            setForm(INITIAL_NEW_ADDRESS);
        }
    }, [address]);


    const fetchCompany = async (companyId: number) => {
        const response = await makeAxiosCall(
            "get",
            `company/${companyId}`
        ).catch(error => {
            console.error("Caught error getting company info:");
            console.error(error);
        });

        if (response.data) {
            const companyData: CompanyInterface = await buildCompanyInfo(response.data);
            setCompany(companyData);
        }
    };


    const handleSave = async () => {
        if ((!form.additionalShippingDetails || !form.additionalShippingDetails.id) && address) {
            if (address.additionalShippingDetails && address.additionalShippingDetails.id) {
                // TODO: DELETE EXISTING ADDITIONAL SHIPPING OPTIONS
            }
            address.additionalShippingDetails = null;
        }

        const response = address ? await updateAddress(form, company.id) : await addNewAddress(form, company.id, addressType);

        setCurrentProcess("saving");
        if (response && response.data.id) {
            const newAddressObject: AddressInterface = {
                contactName: form.contactName,
                streetAddress: form.streetAddress,
                streetAddressTwo: form.streetAddressTwo,
                city: form.city,
                state: form.state,
                zip: form.zip,
                country: form.country,
                addressTypes: form.addressTypes,
                id: response.data.id,
                additionalShippingDetails: form.additionalShippingDetails
            };

            if (!address) {
                if (addCallback) {
                    addCallback(newAddressObject);
                }
            } else {
                if (updateCallback) updateCallback(newAddressObject, response.data.id);
            }
        } else {
            console.log("failure?");

            setFailureAlert(true);
        }
        setCurrentProcess("");
    };

    const handleDelete = async () => {
        let hasPrimary = false;
        let hasBilling = false;
        let hasShipping = false;

        for (let ai = 0; ai < company.addresses.length; ai++) {
            const existingAddress = company.addresses[ai];
            if(existingAddress.id != address.id) {
                for (let ati = 0; ati < existingAddress.addressTypes.length; ati++) {
                    const addressType = existingAddress.addressTypes[ati];
                    if(addressType.id == 1) {
                        hasPrimary = true;
                    } else if(addressType.id == 2) {
                        hasBilling = true;
                    } else if(addressType.id == 3) {
                        hasShipping = true;
                    }
                }
            }
        }
        if(!hasPrimary && address.addressTypes.find((at) => at.id == 1)) {
            setSnackbar({
                isOpen: true,
                message: "You must have at least one primary address.",
                severity: "error",
            });
            return;
        }
        
        if(!hasBilling && address.addressTypes.find((at) => at.id == 2)) {
            setSnackbar({
                isOpen: true,
                message: "You must have at least one billing address.",
                severity: "error",
            });
            return;
        }

        if(!hasShipping && address.addressTypes.find((at) => at.id == 3)) {
            setSnackbar({
                isOpen: true,
                message: "You must have at least one shipping address.",
                severity: "error",
            });
            return;
        }

        setCurrentProcess("delete");
        const response = await deleteAddress(address.id);
        if (response && response.data.id) {
            if (deleteCallback) deleteCallback(response.data.id);
        } else {
            setFailureAlert(true);
            setCurrentProcess("");
        }
    };

    const handleAddressChange = (name: string) => (e) => {

        setForm({
            ...form,
            [name]: e.target.value,
        });
        // if (name === "state") {
        //   let state = findState(e.target.value);
        //   setForm({
        //     ...form,
        //     state: state.code
        //   });
        // } else if (name === "country") {


        //   let country = findCountry(e.target.value);
        //   setForm({
        //     ...form,
        //     country: country.name,
        //     countryId: e.target.value
        //   });
        // } else {
        //   setForm({
        //     ...form,
        //     [name]: e.target.value,
        //   });
        // }
        setValidation({ ...validation, [name]: "" });
    };

    const handleType = (type: AddressTypeInterface) => {
        let newTypes = [];
        let newAdditionalDetails = null;
        if (form.addressTypes.some((selected) => selected.id === type.id)) {
            newTypes = _.filter(form.addressTypes, (selected) => selected.id !== type.id);
        } else {
            newTypes = [...form.addressTypes, type];
        }

        // Make sure that these types of addresses have additional details.
        if(newTypes.findIndex((t) => ["Shipping", "Primary", "Drop Ship"].includes(t.name)) > -1) {
            if(!form.additionalShippingDetails) {
                newAdditionalDetails = DEFAULT_ADDITIONAL_SHIPPING_OPTIONS;
                newAdditionalDetails.contact = currentUser.company.contacts[0];
            } else {
                newAdditionalDetails = form.additionalShippingDetails;
            }
        } else {
            newAdditionalDetails = null;
        }

        setForm({ ...form, addressTypes: newTypes, additionalShippingDetails: newAdditionalDetails });
    };

    const validate = () => {
        const errors: AddressFormValidation = {
            streetAddress: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            addressType: "",
            contact: "",
        };

        errors.streetAddress = form.streetAddress.length === 0 ? "Required" : "";
        errors.city = form.city.length === 0 ? "Required" : "";
        errors.state = form.state.length === 0 ? "Required" : "";
        errors.zip = form.zip.length === 0 ? "Required" : "";
        errors.country = form.country.length === 0 ? "Required" : "";
        errors.addressType = form.addressTypes.length === 0 ? "Required" : "";

        if (showAdvancedShippingOptions) {
            errors.contact = form.additionalShippingDetails.contact ? "" : "Required";
        }

        if(address && currentUser && currentUser.company && currentUser.company.addresses) {
            // if this is an update, make sure they're not removing types that are required... somehow
            if(address.addressTypes != form.addressTypes) {
                // changing types
                let hasPrimary = false;
                let hasBilling = false;
                let hasShipping = false;
                for (let ai = 0; ai < currentUser.company.addresses.length; ai++) {
                    const existingAddress = currentUser.company.addresses[ai];
                    if(existingAddress.id != address.id) {
                        for (let ati = 0; ati < existingAddress.addressTypes.length; ati++) {
                            const addressType = existingAddress.addressTypes[ati];
                            if(addressType.id == 1) {
                                hasPrimary = true;
                            } else if(addressType.id == 2) {
                                hasBilling = true;
                            } else if(addressType.id == 3) {
                                hasShipping = true;
                            }
                        }
                    }
                }
                if(!hasPrimary && address.addressTypes.find((at) => at.id == 1) && !form.addressTypes.find((at) => at.id == 1)) {
                    errors.addressType += "You must have at least one primary address. "
                }

                if(!hasBilling && address.addressTypes.find((at) => at.id == 2) && !form.addressTypes.find((at) => at.id == 2)) {
                    errors.addressType += "You must have at least one billing address. "
                }

                if(!hasShipping && address.addressTypes.find((at) => at.id == 3) && !form.addressTypes.find((at) => at.id == 3)) {
                    errors.addressType += "You must have at least one shipping address. "
                }
            }
        }

        setValidation({ ...errors });

        return Object.values(errors).every(x => x === "");
    };

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label="Addressee"
                    margin="normal"
                    value={form.contactName}
                    variant="outlined"
                    onChange={handleAddressChange("contactName")}
                    inputProps={{ maxLength: 40 }}
                    placeholder={currentUser.displayName}
                    autoComplete="name"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label="Street Address"
                    margin="normal"
                    className="street"
                    variant="outlined"
                    onChange={handleAddressChange("streetAddress")}
                    value={form.streetAddress}
                    error={validation.streetAddress.length !== 0}
                    helperText={validation.streetAddress}
                    inputProps={{ maxLength: 90 }}
                    autoComplete="street-address"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label="Address Line 2"
                    margin="normal"
                    className="street"
                    variant="outlined"
                    onChange={handleAddressChange("streetAddressTwo")}
                    value={form.streetAddressTwo}
                    inputProps={{ maxLength: 90 }}
                    autoComplete="address-line2"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    style={{ width: "100%" }}
                    label="City"
                    margin="normal"
                    className="city"
                    variant="outlined"
                    onChange={handleAddressChange("city")}
                    value={form.city}
                    error={validation.city.length !== 0}
                    helperText={validation.city}
                    inputProps={{ maxLength: 30 }}
                    autoComplete="address-level2"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl
                    style={{ marginTop: formControl, width: "100%" }}
                    className="state"
                    variant="outlined"
                    error={validation.state.length !== 0}
                >
                    <Autocomplete
                        onChange={(e, newState: string) => {
                            console.log("ON CHANGE");
                            setForm({
                                ...form,
                                state: newState
                            });
                        }}
                        id="state-select-outlined"
                        value={form.state}
                        inputValue={stateInputValue}
                        autoSelect={true}
                        autoComplete={true}
                        blurOnSelect={true}
                        onInputChange={(event, newInputValue) => {
                            const stateMatch = states.find((s) => s.name.toLowerCase() === newInputValue.toLowerCase() || s.code.toLowerCase() === newInputValue.toLowerCase());
                            if (stateMatch) {
                                setStateInputValue(stateMatch.code);
                                setForm({
                                    ...form,
                                    state: stateMatch.name
                                });
                            } else {
                                setStateInputValue(newInputValue);
                            }
                        }}
                        getOptionLabel={(o) => o && o != undefined ? o : ""}
                        options={_.map(states, (s) => s.code)}

                        renderInput={(p) => <TextField
                            {...p}

                            inputProps={{
                                ...p.inputProps,
                                autoComplete: "address-level1",
                            }}
                            label="State" variant="outlined" name="state" />}
                    />
                    <FormHelperText>{validation.state}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                    style={{ width: "100%" }}
                    label="Postal Code"
                    margin="normal"
                    className="postal"
                    variant="outlined"
                    onChange={handleAddressChange("zip")}
                    value={form.zip}
                    error={validation.zip.length !== 0}
                    helperText={validation.zip}
                    inputProps={{ maxLength: 10 }}
                    autoComplete="postal-code"
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl
                    style={{ marginTop: formControl, width: "100%" }}
                    variant="outlined"
                >
                    <Autocomplete
                        onChange={(e, newCountry: string) => {
                            console.log("change happening");

                            setForm({
                                ...form,
                                country: newCountry
                            });
                        }}
                        id="country-select-outlined"
                        value={form.country}
                        inputValue={countryInputValue}
                        onInputChange={(event, newInputValue) => {
                            const countryMatch = countries.find((c) => c.abbreviation.toLowerCase() === newInputValue.toLowerCase());
                            if (countryMatch) {
                                setCountryInputValue(countryMatch.name);
                            } else {
                                setCountryInputValue(newInputValue);
                            }
                        }}
                        getOptionLabel={(o) => o && o != undefined ? o : ""}
                        options={_.map(countries, (c) => c.name)}
                        renderInput={(p) => <TextField
                            {...p}
                            // inputProps={{
                            //   ...p.inputProps,
                            //   autoComplete: 'country',
                            // }}
                            label="Country" variant="outlined" name="country" />}
                    />
                    <FormHelperText>{validation.country}</FormHelperText>
                </FormControl>
            </Grid>

            {form.additionalShippingDetails ?
                <Grid container item xs={12} style={{textAlign: "left"}}>
                    <Paper className={"bordered-container"} style={{padding: "0 15px"}}>
                        <Grid container item xs={12}>
                            <Grid item container md={6} xs={12}>
                                <Grid item xs={12}>
                                    <h3 className="center">Location Details</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title={"Does this address have a truck height loading dock?"} placement="top" arrow>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={form.additionalShippingDetails.hasTruckHeightLoadingDock}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            additionalShippingDetails: {
                                                                ...form.additionalShippingDetails,
                                                                hasTruckHeightLoadingDock: !form.additionalShippingDetails.hasTruckHeightLoadingDock,
                                                                liftGateRequired: form.additionalShippingDetails.hasTruckHeightLoadingDock
                                                            }
                                                        });
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={"Truck Height Loading Dock"}
                                            classes={{ label: "search-filter-option" }}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title={"Is this a residential location?"} placement="top" arrow>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!form.additionalShippingDetails.isCommercialLocation}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            additionalShippingDetails: {
                                                                ...form.additionalShippingDetails,
                                                                isCommercialLocation: !form.additionalShippingDetails.isCommercialLocation
                                                            }
                                                        });
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={"Residential address"}
                                            classes={{ label: "search-filter-option" }}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title={"Is this a Job Site?"} placement="top" arrow>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={form.additionalShippingDetails.isJobsiteLocation}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            additionalShippingDetails: {
                                                                ...form.additionalShippingDetails,
                                                                isJobsiteLocation: !form.additionalShippingDetails.isJobsiteLocation
                                                            }
                                                        });
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={"Job site"}
                                            classes={{ label: "search-filter-option" }}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item container md={6} xs={12}>
                                <Grid item xs={12}>
                                    <h3 className="center">Available Delivery Hours</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title={"Does this address allow for deliveries between 8AM and Noon?"} placement="top" arrow>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={form.additionalShippingDetails.deliveryHoursMorning}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            additionalShippingDetails: {
                                                                ...form.additionalShippingDetails,
                                                                deliveryHoursMorning: !form.additionalShippingDetails.deliveryHoursMorning
                                                            }
                                                        });
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={"8AM - NOON"}
                                            classes={{ label: "search-filter-option" }}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title={"Does this address allow for afternoon deliveries?"} placement="top" arrow>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={form.additionalShippingDetails.deliveryHoursAfternoon}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            additionalShippingDetails: {
                                                                ...form.additionalShippingDetails,
                                                                deliveryHoursAfternoon: !form.additionalShippingDetails.deliveryHoursAfternoon
                                                            }
                                                        });
                                                    }}
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={"NOON - 4PM"}
                                            classes={{ label: "search-filter-option" }}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12}>
                                
                                    <Grid item xs={12}>
                                        <Tooltip title={"Does this location have limited or special access?"} placement="top" arrow>
                                            <TextField
                                                style={{ width: "100%" }}
                                                label={"Location Notes"}
                                                margin="normal"
                                                value={form.additionalShippingDetails.locationNotes}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        additionalShippingDetails: {
                                                            ...form.additionalShippingDetails,
                                                            locationNotes: e.target.value
                                                        }
                                                    });
                                                }}
                                                placeholder={"Does this location have limited or special access?"}
                                            />
                                        </Tooltip>
                                    </Grid>
                            </Grid>

                            <Grid item container xs={12}>
                                    <Grid item xs={12}>
                                        <ContactSelect
                                            company={company}
                                            label="Recieving Contact"
                                            setNewFormActive={(open) => {
                                                setShowShippingContactForm(open);
                                            }}
                                            currentSelection={form.additionalShippingDetails.contact}
                                            onSelect={(contact: ContactInterface) => {

                                                setForm({
                                                    ...form,
                                                    additionalShippingDetails: {
                                                        ...form.additionalShippingDetails,
                                                        contact: contact
                                                    }
                                                });
                                            }}
                                        />

                                        {validation.contact.length > 0 && (
                                            <p style={{ color: "red" }}>
                                                Required
                                            </p>
                                        )}

                                        {showShippingContactForm ? <ContactEditForm
                                            createCallback={(contact: ContactInterface, newId: number) => {
                                                contact.id = newId;
                                                (company).contacts.push(contact);
                                                setShowShippingContactForm(false);
                                                setForm({
                                                    ...form,
                                                    additionalShippingDetails: {
                                                        ...form.additionalShippingDetails,
                                                        contact: contact
                                                    }
                                                });
                                            }}
                                            companyId={company.id}
                                        /> : null}
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>
                : null
            }

            <Grid item xs={12} style={{marginTop: 12, minHeight: 100}}>
                <FormControl fullWidth error={validation.addressType.length !== 0}>
                    <FormLabel ref={inputLabel}>Address Type: {validation.addressType}</FormLabel>
                    <FormGroup row className="contact-type-flex" style={{alignSelf: "center"}}>
                        {
                            addressTypes.map((type) => {
                                return <FormControlLabel
                                    key={type.id}
                                    control={
                                        <Tooltip title={type.name == "Billing" && !currentUser.accountLevel.canEditPaymentMethods ? "Only Admins can edit billing addresses" : ""}>
                                            <div>
                                                <Checkbox
                                                    checked={form.addressTypes.some((selected) => selected.id == type.id)}
                                                    onChange={() => handleType(type)}
                                                    disabled={type.name == "Billing" && !currentUser.accountLevel.canEditPaymentMethods}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                    label={type.name}
                                />;
                            })
                        }
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" spacing={2} xs={12}>
                {address && address.id && (
                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            type="submit"
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={handleDelete}
                            className="btn red"
                        >
                            {currentProcess === "delete" ?
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                /> :
                                "Delete"
                            }
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSuccessAlert(false);
                            setFailureAlert(false);
                            if (validate()) {
                                setCurrentProcess("saving");
                                handleSave();
                            }
                        }}
                        className="btn"
                    >
                        {currentProcess === "saving" ?
                            <CircularProgress
                                color="inherit"
                                size={24}
                            /> :
                            "Save Address"
                        }
                    </Button>
                </Grid>
            </Grid>
            <CSSTransition
                in={successAlert}
                unmountOnExit
                timeout={{ appear: 200, enter: 0, exit: 0 }}
                classNames="dialog"
            >
                <Grid container justifyContent="center">
                    <SuccessAni marginTop={20} title="Saved Address"></SuccessAni>
                </Grid>
            </CSSTransition>
            <CSSTransition
                in={failureAlert}
                unmountOnExit
                timeout={{ appear: 200, enter: 0, exit: 0 }}
                classNames="dialog"
            >
                <Grid container justifyContent="center" direction="column" style={{ color: "#d06079", textAlign: "center", marginTop: 20 }}>
                    <i className="fa-light fa-triangle-exclamation" style={{ fontSize: "3em" }}></i>
                    <p style={{ fontSize: "1.25em" }}>Failed to Save Address</p>
                    <p>Please try again. If the issue persists, contact our support.</p>
                </Grid>
            </CSSTransition>

            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={5000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    // For Future Devon - This snackbar is the toast
                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AddressForm;
