import { Grid, Paper } from "@material-ui/core";
import Loading from "components/utils/Loading";
import { UserContext } from "context/User";
import { productFromHasuraObject, ProductInterface } from "interfaces/Product";
import React from "react";
import { makeAxiosCall } from "utils";
import ProductEditorNav from "components/ProductEditor/ProductEditorNav";
import SingleProductDimensions from "components/Products/SingleProductDimensions";
import SingleProductGeneral from "components/Products/SingleProductGeneral";
import ProductEditorMedia from "components/ProductEditor/ProductEditorMedia";
import ProductImages from "./ProductImages";
import ProductDownloads from "./ProductDownloads";
import { ProductContext } from "context/Product";
import WebflowEditor from "components/ProductEditor/WebflowEditor";
import ProductSalesHistory from "./ProductSalesHistory";
import { useLocation } from "react-router-dom";

const SingleProductPage = () => {
  const [product, setProduct] = React.useState<ProductInterface>();
  const { currentUser, internalUser } = React.useContext(UserContext);

  const [activeMenu, setActiveMenu] = React.useState("General");
  const [writeAccess, setWriteAccess] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { products, baseProducts, setSingleProductDetails } = React.useContext(ProductContext);

  const productId = window.location.pathname.split("/products/")[1];

  const { state } = useLocation();

  React.useEffect(() => {
    if (state?.tab) setActiveMenu(state.tab);
    setProduct(products.find((p) => p.id == parseInt(productId)));
    fetchProductDetails();
  }, []);

  // Debugging log

  // React.useEffect(() => {
  //   console.log(product);
  // }, [product]);

  React.useEffect(() => {
    if (currentUser && internalUser) {
      setWriteAccess(true);
    } else {
      setWriteAccess(false);
    }
  }, [currentUser, internalUser]);

  const websiteUrl = React.useMemo(() => {
    if (product) {
      return product.baseProduct?.websiteUrl ?? product.websiteUrl;
    }
  }, [product]);

  const fetchProductDetails = async () => {
    const response = await makeAxiosCall(
      "get",
      `product/${productId}`
    );

    setLoading(false);
    if (response?.status == 200) {
      let productDetailedData = productFromHasuraObject(response.data);

      setSingleProductDetails(productDetailedData);
      setProduct(productDetailedData);
    }
  }

  const renderView = () => {
    if(loading) {
      return <Loading title="" height="40vh" position={"relative"}/>;
    }
    if (activeMenu === "Dimensions") {
      return (
        <SingleProductDimensions
          product={product}
          writeAccess={writeAccess}
        />
      );
    } else if (activeMenu === "Admin") {
      return (
        <WebflowEditor
          product={product}
          setProduct={setProduct}
        />
      );
    } else if (activeMenu === "Media") {
      return (
        <ProductEditorMedia
          product={product}
          setProduct={setProduct}
          writeAccess={writeAccess}
        />
      );
    } else if (activeMenu === "Images") {
      return (
          <ProductImages
          product={product}
          writeAccess={writeAccess}
        />
      );
    } else if (activeMenu === "Downloads") {
      return (
        <ProductDownloads
          product={product}
          writeAccess={writeAccess}
        />
      );
    } else if (activeMenu === "Sales") {
      return (
        <ProductSalesHistory 
          product={product}
          startDate={state?.startDate}
          endDate={state?.endDate}
        />
      );
    } else {
      return (
        <SingleProductGeneral
          product={product}
          writeAccess={writeAccess}
        />
      );
    }
  };

  return product ? (
    <div className="admin view">
      <Paper style={{ padding: 20 }}>
        <Grid container>

          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={6}>
              <h1 className="reveal-text">{product.name}</h1>
            </Grid>

            {websiteUrl && (
              <Grid item style={{ textAlign: "right" }}>
                <a
                  href={`https://www.blizzardpro.com/products/${websiteUrl}`}
                  target="_blank"
                  style={{ borderBottom: "none" }}
                  rel="noreferrer"
                >
                  View on blizzardpro.com <i className="fas fa-chevron-right"></i>
                </a>
              </Grid>
            )}
          </Grid>

          <Grid item container xs={12} alignItems="flex-end">
            <ProductEditorNav product={product} activeMenu={activeMenu} setActiveMenu={setActiveMenu} writeAccess={writeAccess} />
          </Grid>

          <Grid item container xs={12} style={{ padding: 10 }} >
            {renderView()}
          </Grid>
        </Grid>
      </Paper>
    </div>
  ) : (<Loading title="" height="40vh" position={"relative"}/>);
}

export default SingleProductPage;