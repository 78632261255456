import React from "react";
import { Grid, TextField, Button, CircularProgress, Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { makeAxiosCall, validateEmail } from "utils";
import { UserContext } from "context/User";
import ModalBasic from "components/utils/ModalBasic";
import { Info } from "@material-ui/icons";

interface NewResellerInviteProps {
    creationCallback?: () => void,
    title?: string,
}

const NewResellerInvite = ({ creationCallback, title = "Invite Company" }: NewResellerInviteProps) => {
    const { currentUser } = React.useContext(UserContext);

    const [email, setEmail] = React.useState("");
    const [emailValidation, setEmailValidation] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [sendingInvite, setSendingInvite] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState<{ text: string, severity: Color }>({ text: "", severity: "error" });
    const [modal, setModal] = React.useState(false);
    const [moreInfo, setMoreInfo] = React.useState(false);

    const inviteReseller = async () => {

        const response = await makeAxiosCall(
            "post",
            "reseller-invite",
            {
                email: email.toLowerCase().trim(),
                companyName: companyName,
                createdById: currentUser.uid
            }
        ).catch((error) => {
            const errorMessage = error.data.display ?
                error.data.error :
                "An error occurred. Please try again.";
            setSnackbar({ text: errorMessage, severity: "error" });
            setSendingInvite(false);
        });

        if (response) {
            if (response.status === 200) {
                setSnackbar({ text: "Invite sent successfully", severity: "success" });
                if (creationCallback) creationCallback();
                closeModal();
            } else {
                setSnackbar({ text: "An unknown error occurred", severity: "error" });
            }
        }

        setSendingInvite(false);
    };

    const validate = () => {
        if (email.length < 1) {
            setEmailValidation("Required");
            return false;
        }
        if (!validateEmail(email)) {
            setEmailValidation("Not a valid email");
            return false;
        }
        return true;
    };

    const closeModal = () => {
        setEmail("");
        setCompanyName("");
        setMoreInfo(false);
        setModal(false);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        style={{ width: "100%", color: "white" }}
                        className="btn"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        {title}
                    </Button>
                </Grid>
            </Grid>

            <ModalBasic
                open={modal}
                close={closeModal}
                unmountOnExit
            >
                <div style={{ padding: 25, margin: 10, maxWidth: 1000 }}>
                    <h3>
                        Invite Company
                        <Info
                            style={{ paddingLeft: 4, cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => setMoreInfo(!moreInfo)}
                        />
                    </h3>
                    {moreInfo && (
                        <div style={{ 
                            backgroundColor: "#eaeaea", 
                            maxWidth: 400,
                            margin: "10px auto",
                            padding: "4px 15px"
                        }}
                        >
                            <p>
                                This will send an invite email to a prospective 
                                Blizzard Lighting reseller. By following the link
                                in the email, they will create an account on
                                the portal and be taken straight to
                                the reseller application.
                            </p>
                        </div>
                    )}
                    <Grid container className="address-line" style={{ textAlign: "center", marginTop: 10 }} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Email"
                                value={email}
                                variant="outlined"
                                onChange={(e) => {
                                    setEmailValidation("");
                                    setEmail(e.target.value);
                                }}
                                error={emailValidation.length !== 0}
                                helperText={emailValidation}
                                inputProps={{ autoComplete: "off", type: "email" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Company Name"
                                value={companyName}
                                variant="outlined"
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                }}
                                helperText="Optional"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSnackbar({ ...snackbar, text: "" });
                                    if (validate()) {
                                        setSendingInvite(true);
                                        inviteReseller();
                                    }
                                }}
                                className="btn blue"
                            >
                                {sendingInvite ?
                                    <CircularProgress
                                        color="inherit"
                                        size={25}
                                    /> :
                                    "Invite"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </ModalBasic>
            <Snackbar
                open={snackbar.text.length > 0}
                autoHideDuration={5000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }
                    setSnackbar({ ...snackbar, text: "" });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity}
                    style={{ textAlign: "center" }}
                >
                    {snackbar.text}
                </Alert>
            </Snackbar>
        </>
    );
};

export default NewResellerInvite;