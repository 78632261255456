import React from "react";
import _ from "lodash";
import ModalBasic from "components/utils/ModalBasic";
import { Button, Chip, Grid } from "@material-ui/core";
import { NewsfeedPostInterface } from "interfaces/Newsfeed";
import NewsfeedPostForm from "./NewsfeedPostForm";
import { makeAxiosCall } from "utils";
import { CompanyContext } from "context/Company";
import { CSSTransition } from "react-transition-group";

interface PostProps {
  post: NewsfeedPostInterface,
  canDelete: boolean,
  removePost?: Function,
  refresh?: Function,
}

const Post = ({ post, canDelete, removePost, refresh }: PostProps) => {

  const [open, setOpen] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const { companyTypes } = React.useContext(CompanyContext)

  const updatePost = async (updatedPostData: NewsfeedPostInterface) => {
    let response = await makeAxiosCall(
      "post",
      `newsfeed-update/${post.id}`,
      updatedPostData,
    ).catch(error => {
      console.error(error);
    });

    if (response && response.data && response.data.id) {
      setEditing(false);
      if (refresh) refresh();
    }
  }

  function createMarkup(markup) {
    return { __html: markup };
  }

  const close = () => {
    setOpen(false);
  };

  const renderTags = () => {
    return _.map(post.tags, (tag, i) => {
      return (
        <Grid item key={i}><Chip label={tag} color="primary" /></Grid>
      );
    })
  }

  const buildIFrame = () => {
    let videoUrl = post.videoUrl;

    let useIframe = true;

    if (videoUrl.includes("youtube")) {
      let videoId = videoUrl.split("watch?v=")[1];
      if (videoId) {
        if (videoId.includes("&")) {
          videoId = videoId.split("&")[0];
        }
        videoUrl = `https://youtube.com/embed/${videoId}`;
      }
    } else if (videoUrl.includes("youtu.be")) {
      let videoId = videoUrl.split(".be/")[1];
      if (videoId) {
        videoUrl = `https://youtube.com/embed/${videoId}`;
      }
    } else if (videoUrl.includes("vimeo")) {
      let videoId = videoUrl.split(".com/")[1];
      if (videoId) {
        videoUrl = `https://player.vimeo.com/video/${videoId}`;
      }
    } else {
      useIframe = false;
    }

    return (
      <div style={{ textAlign: "center", margin: 10 }}>
        {
          useIframe ? 
            <iframe 
              width="560" 
              height="315" 
              src={videoUrl} 
              frameBorder="0" 
              allowFullScreen
            /> : 
            <video width="560" height="315" src={videoUrl} controls />
        }
      </div>
      // <div style={{padding:"54.05% 0 0 0", position: "relative"}}>
      //   <iframe 
      //     src={videoUrl}
      //     style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} 
      //     frameBorder="0" 
      //     allowFullScreen
      //   />
      // </div>
    );
  }

  return (
    <div className="feed-element" style={{width: "100%"}}>
      <ModalBasic open={open} close={close}>
        <div className="add-prompt">
          <h2>Do you want to delete this post?</h2>
          <p>This cannot be undone</p>
          <div className="btn-group">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="btn"
              onClick={() => close()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(false);
                removePost(post.id);
              }}
              className="btn red"
            >
              Delete
            </Button>
          </div>
        </div>
      </ModalBasic>
      <CSSTransition 
        in={editing}
        unmountOnExit
        timeout={200}
      >
        <NewsfeedPostForm 
          savePost={updatePost}
          setFormActive={setEditing}
          existingPost={post}
        />
      </CSSTransition>
      {canDelete && removePost && (
        <div className="post-tools">
          <i onClick={() => setEditing(true)} className="far fa-pen-to-square" style={{ marginRight: 10 }}></i>
          
          <i onClick={() => setOpen(true)} className="far fa-trash-alt"></i>
        </div>
      )}
      <Grid container>
        {post.title && post.title.length > 0 && (<Grid item xs={12} style={{fontSize: "2em", fontWeight: "bold"}}>{post.title}</Grid>)}
        <Grid item xs={12} >Posted on {post.createdAt.toLocaleString("en-US", {dateStyle: "short"})}</Grid>
        {canDelete && (
          <Grid item xs={12} >Visible to {companyTypes.find((type) => type.id == post.audience).name} users</Grid>
        )}
        <Grid item xs={12}><hr /></Grid>
        {post.tags && post.tags.length > 0 && (
          <>
            <Grid item container spacing={1} xs={12} >{renderTags()}</Grid>
            <Grid item xs={12}><hr /></Grid>
          </>
        )}
        {post.topText && post.topText.length > 0 && (
          <Grid item xs={12}>
            <p 
              className="content"
              dangerouslySetInnerHTML={createMarkup(post.topText)}
            />
          </Grid>
        )}
        {post.imageUrl && post.imageUrl.length > 0 && (
          <Grid item xs={12} style={{ textAlign: "center", margin: "10px 0" }}>
            <img src={post.imageUrl} style={{ maxWidth: "100%" }}></img>
          </Grid>
        )}
        {post.videoUrl && post.videoUrl.length > 0 && (
          <Grid item xs={12}>
            {buildIFrame()}
          </Grid>
        )}
        {post.bottomText && post.bottomText.length > 0 && (
          <Grid item xs={12}>
            <p 
              className="content"
              dangerouslySetInnerHTML={createMarkup(post.bottomText)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Post;
