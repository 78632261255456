import React, { useState, createContext } from "react";
import _ from "lodash";
import { CompanyInterface } from "interfaces/Company";
import { makeAxiosCall } from "utils";

interface CompanyContextInterface {
    currentCompany: CompanyInterface,
    companyTypes: { id: number, name: string }[],
    setCurrentCompany: (company: CompanyInterface) => void,
}

export const CompanyContext = createContext<CompanyContextInterface>(null);

interface CompanyProviderProps {
    children: any,
}

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
    const [currentCompany, setCurrentCompany] = useState<CompanyInterface>();
    const [companyTypes, setCompanyTypes] = useState<{ id: number, name: string }[]>();

    React.useEffect(() => {
        fetchCompanyTypes();
    }, []);

    const fetchCompanyTypes = async () => {
        const result = await makeAxiosCall(
            "get",
            "company-types"
        );

        if (result.data) {
            const typeList = _.map(
                result.data,
                (type) => { return { id: type.id, name: type.name }; }
            );

            setCompanyTypes(typeList);
        }
    };

    return (
        <CompanyContext.Provider value={{
            currentCompany,
            companyTypes,
            setCurrentCompany
        }}>
            {children}
        </CompanyContext.Provider>
    );
};
