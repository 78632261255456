export interface ProductRegistrationInterface {
    id: number,
    purchaseDate: Date,
    receiptUrl: string,
    products: ProductRegistrationProductInterface[],
    name: string,
    email: string
}


export const buildProductRegistration = (registrationData): ProductRegistrationInterface => {
    let products: ProductRegistrationProductInterface[] = [];

    for (let pi = 0; pi < registrationData.products.length; pi++) {
        const pd = registrationData.products[pi];
        products.push({
            ...pd,
            warrantyExpiration: new Date(pd.warrantyExpiration),

        })
    }
    return {
        id: registrationData.id,
        purchaseDate: new Date(registrationData.purchaseDate),
        receiptUrl: registrationData.receiptUrl,
        products: products,
        name: registrationData.createdBy.name,
        email: registrationData.createdBy.userSuppliedEmail ?? registrationData.createdBy.email
    };
};

export interface ProductRegistrationProductInterface {
    id: number,
    serialNumber: string,
    new: boolean,
    checked: boolean,
    warrantyExpiration: Date,
    warrantyExtensionDeclined: boolean,
    so: string,
    product: {
        id: number,
        shopifyId: number,
        fishbowlId: number,
        productType: string,
        description: string,
        imageUrl: string,
        name: string,
        sku: number,
        websiteUrl: string,
        warranty: {
            id: number,
            name: string,
            extendable: boolean,
            lifetime: boolean,
            days: number,
            months: number,
            years: number
        }
    }
}