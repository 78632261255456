import React from "react";
import { CCDataInterface } from "interfaces/Payment";

interface PaymentCardPropsInterface {
    ccData: CCDataInterface,
    handleSelect: (data: CCDataInterface) => void,
    handleEdit?: (data: CCDataInterface) => void,
    active: boolean
}

const PaymentCard = ({ ccData, handleSelect, handleEdit, active }: PaymentCardPropsInterface) => {

    const returnCardIcon = () => {
        switch (ccData.card.cardType) {
            case "Visa":
                return <i className="fab fa-cc-visa"></i>;
            case "AmericanExpress":
                return <i className="fab fa-cc-amex"></i>;
            case "Discover":
                return <i className="fab fa-cc-discover"></i>;
            case "MasterCard":
                return <i className="fab fa-cc-mastercard"></i>;
            case "JCB":
                return <i className="fab fa-cc-jcb"></i>;
            case "Diners Club":
                return <i className="fab fa-cc-diners-club"></i>;
        }
    };

    const convertX = str => {
        return str.replace(new RegExp("X", "g"), "•");
    };

    return (
        <div className={active ? "card active-card" : "card"}>
            <div className="container">
                <div className="flex-container" onClick={() => handleSelect(ccData)}>
                    {returnCardIcon()}
                    <div className="cc-info">
                        <p>{`${ccData.card.cardType} ${convertX(ccData.card.cardNumber)}`}</p>
                        <p>Expires {ccData.card.expirationDate}</p>
                    </div>
                </div>
                <div className="bottom-nav" onClick={() => {
                    if (handleEdit) {
                        handleEdit(ccData);
                    } else {
                        handleSelect(ccData);
                    }
                }}>
                    <h4>
                        <i className="fas fa-arrow-right"> </i>
                        {handleEdit ? "Use Card" : "Edit Card Info"}
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default PaymentCard;
