
export interface ShippingPreferenceInterface {
    id: number,
    carrier: ShippingCarrierInterface,
    service: ShippingCarrierServiceInterface,
    accountNumber?: string
}

export interface ShippingCarrierInterface {
    id: number,
    name: string,
    thirdParty?: boolean,
    services: Array<ShippingCarrierServiceInterface>
}

export interface ShippingCarrierServiceInterface {
    id: number,
    name: string
}

export const buildShippingCarrier = (prefData): ShippingCarrierInterface => {
    if (!prefData) return null;

    return {
        id: prefData.id,
        name: prefData.name,
        thirdParty: prefData.third_party,
        services: buildShippingCarrierServicesList(prefData.services)
    };
};

export const buildShippingPreference = (prefData): ShippingPreferenceInterface => {
    if (!prefData) return null;

    return {
        id: prefData.id,
        carrier: buildShippingCarrier(prefData.shipping_carrier),
        service: prefData.shipping_service,
        accountNumber: prefData.account_number
    };
};

export const buildShippingOptions = (rawData): ShippingCarrierInterface[] => {
    const options: Array<ShippingCarrierInterface> = [];

    for (let soi = 0; soi < rawData.length; soi++) {
        const option = rawData[soi];

        options.push(buildShippingCarrier(option));
    }

    return options;
};



export const buildShippingCarrierServicesList = (rawData): ShippingCarrierServiceInterface[] => {
    const options: Array<ShippingCarrierServiceInterface> = [];

    if (rawData && rawData.length > 0) {
        for (let soi = 0; soi < rawData.length; soi++) {
            const option = rawData[soi];

            options.push({
                id: option.id,
                name: option.name
            });
        }
    }

    // options.push(DefaultShippingOption);

    return options;
};

export const NoShippingPreference: ShippingPreferenceInterface = {
    id: 0,
    accountNumber: "",
    carrier: {
        id: 0,
        name: "No Preference",
        services: []
    },
    service: {
        id: 0,
        name: "No Preference"
    }
};
