import React, { useCallback, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { app } from "base.js";
import { UserContext } from "context/User";
import { Button, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import ReactGA from "react-ga4";
import { makeAxiosCall } from "utils";
import { CartContext } from "context/Cart";

interface LoginProps {
    setActiveAuth: (auth: string) => void,
}

const Login = ({ setActiveAuth }: LoginProps) => {
    const { currentUser } = useContext(UserContext);
    const { clearCart } = useContext(CartContext);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({ email: false, password: false });
    const [dialogOpen, setDialogOpen] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const newReseller = urlParams.get("newReseller");

    const handleLogin = useCallback(async (e) => {
        e.preventDefault();
        clearCart();

        localStorage.removeItem("logout-event");

        const { email, password } = e.target.elements;

        let emailPresent = false;
        let passwordPresent = false;

        if (email.value && email.value.length > 0) {
            emailPresent = true;
        }

        if (password.value && password.value.length > 0) {
            passwordPresent = true;
        }

        if (emailPresent && passwordPresent) {
            try {
                setLoading(true);
                // ReactGA.initialize("G-TLB5SZ3EZD");
                ReactGA.send({ hitType: "event", eventCategory: "auth", eventAction: "login", eventLabel: email.value });
                // makeAxiosCall(
                //     "post",
                //     "email/login",
                //     { email: email.value }
                // );

                await app.auth().signInWithEmailAndPassword(email.value, password.value);

                setTimeout(() => {
                    if (!currentUser) {
                        makeAxiosCall(
                            "post",
                            "create-data",
                            {
                                id: app.auth().currentUser.uid,
                                email: email.value
                            }
                        ).then((result) => {
                            if (newReseller) {
                                window.location.assign("/apply");
                            } else {
                                window.location.reload();
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                }, 5000);
            } catch (error) {
                console.error(error);
                const errorCode = error.code.substring(5);

                let errorMessage = "Something went wrong. Please try again.";
                if (errorCode == "invalid-email" || errorCode == "wrong-password") {
                    errorMessage = "Incorrect email or password";
                }

                setLoading(false);
                setError(errorMessage);
            }
        } else {
            setValidation({ email: !emailPresent, password: !passwordPresent });
        }
    }, []);

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const handleResellerApplication = () => {
        const search = new URLSearchParams();
        search.set("type", "signup");
        search.set("newReseller", "true");
        window.location.search = search.toString();
    };

    if (currentUser) {
        clearCart();
        return <Navigate to="/" />;
    }

    return (
        <div>
            <h1>Log in</h1>
            <p className="underline" onClick={() => setActiveAuth("signup")}>
                Create an account
            </p>
            <form onSubmit={handleLogin}>
                <TextField
                    className="input-full"
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                        name: "email",
                    }}
                    error={validation.email}
                    helperText={validation.email ? "Required" : ""}
                />
                <TextField
                    className="input-full"
                    label="Password"
                    margin="normal"
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                    inputProps={{
                        name: "password",
                    }}
                    error={validation.password}
                    helperText={validation.password ? "Required" : ""}
                />
                <Button
                    style={{ width: "100%" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {loading ?
                        <CircularProgress
                            color="inherit"
                            size={25}
                        /> :
                        "Log In"
                    }
                </Button>

                <p
                    className="underline fine"
                    onClick={() => setActiveAuth("forgot")}
                >
                    Forgot your password?
                </p>
                {error && <p className="error">{error}</p>}
            </form>

            <Button
                style={{ width: "100%", marginTop: 50 }}
                variant="contained"
                color="secondary"
                onClick={openDialog}
            >
                Apply to Become a Reseller
            </Button>

            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
            >
                <DialogTitle>
                    Interested in becoming a reseller?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        First, sign up for an account. From there, you can fill out our New
                        Reseller application. Someone from our team will contact you with
                        follow up steps afterwards.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResellerApplication} autoFocus>
                        Take me there!
                    </Button>
                    <Button onClick={closeDialog}>
                        No Thanks
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Login;
