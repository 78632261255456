import { Grid, TextField, FormControlLabel, Switch, Button, CircularProgress } from "@material-ui/core";
import { PDProjectInterface } from "interfaces/ProductDevelopment";
import React, { Dispatch, SetStateAction } from "react";
import { makeAxiosCall } from "utils";

interface PDProductDimensionsProps {
    project: PDProjectInterface,
    setProject: Dispatch<SetStateAction<PDProjectInterface>>,
    showErrorText: (errorText: string) => void,
}

const PDProductDimensions = ({ project, setProject, showErrorText }: PDProductDimensionsProps) => {
    const [editProjectDimensions, setEditProjectDimensions] = React.useState(false);
    const [savingDimensions, setSavingDimensions] = React.useState(false);
    const [useMetric, setUseMetric] = React.useState(false);

    const lengthMeasure = React.useMemo(() => {
        return useMetric ? "cm" : "in";
    }, [useMetric]);

    const weightMeasure = React.useMemo(() => {
        return useMetric ? "kg" : "lbs";
    }, [useMetric]);

    const handleNumberInput = (value: string): number | undefined => {
        return isNaN(parseFloat(value)) ? undefined : parseFloat(value);
    }



    const toggleMetric = () => {
        if (!useMetric) {
            // Convert to metric units
            setProject({
                ...project,
                productDimensions: {
                    ...project.productDimensions,
                    length: project.productDimensions?.length ? project.productDimensions?.length / 0.393701 : project.productDimensions?.length,
                    width: project.productDimensions?.width ? project.productDimensions?.width / 0.393701 : project.productDimensions?.width,
                    height: project.productDimensions?.height ? project.productDimensions?.height / 0.393701 : project.productDimensions?.height,
                    weight: project.productDimensions?.weight ? project.productDimensions?.weight / 2.204 : project.productDimensions?.weight,
                    assembledLength: project.productDimensions?.assembledLength ? project.productDimensions?.assembledLength / 0.393701 : project.productDimensions?.assembledLength,
                    assembledWidth: project.productDimensions?.assembledWidth ? project.productDimensions?.assembledWidth / 0.393701 : project.productDimensions?.assembledWidth,
                    assembledHeight: project.productDimensions?.assembledHeight ? project.productDimensions?.assembledHeight / 0.393701 : project.productDimensions?.assembledHeight,
                    assembledWeight: project.productDimensions?.assembledWeight ? project.productDimensions?.assembledWeight / 2.204 : project.productDimensions?.assembledWeight,
                    masterPackLength: project.productDimensions?.masterPackLength ? project.productDimensions?.masterPackLength / 0.393701 : project.productDimensions?.masterPackLength,
                    masterPackWeight: project.productDimensions?.masterPackWeight ? project.productDimensions?.masterPackWeight / 2.204 : project.productDimensions?.masterPackWeight,
                    masterPackHeight: project.productDimensions?.masterPackHeight ? project.productDimensions?.masterPackHeight / 0.393701 : project.productDimensions?.masterPackHeight,
                    masterPackWidth: project.productDimensions?.masterPackWidth ? project.productDimensions?.masterPackWidth / 0.393701 : project.productDimensions?.masterPackWidth,
                }
            });
        } else {
            // Convert to imperial units
            setProject({
                ...project,
                productDimensions: {
                    ...project.productDimensions,
                    length: project.productDimensions?.length ? project.productDimensions?.length * 0.393701 : project.productDimensions?.length,
                    width: project.productDimensions?.width ? project.productDimensions?.width * 0.393701 : project.productDimensions?.width,
                    height: project.productDimensions?.height ? project.productDimensions?.height * 0.393701 : project.productDimensions?.height,
                    weight: project.productDimensions?.weight ? project.productDimensions?.weight * 2.204 : project.productDimensions?.weight,
                    assembledLength: project.productDimensions?.assembledLength ? project.productDimensions?.assembledLength * 0.393701 : project.productDimensions?.assembledLength,
                    assembledWidth: project.productDimensions?.assembledWidth ? project.productDimensions?.assembledWidth * 0.393701 : project.productDimensions?.assembledWidth,
                    assembledHeight: project.productDimensions?.assembledHeight ? project.productDimensions?.assembledHeight * 0.393701 : project.productDimensions?.assembledHeight,
                    assembledWeight: project.productDimensions?.assembledWeight ? project.productDimensions?.assembledWeight * 2.204 : project.productDimensions?.assembledWeight,
                    masterPackLength: project.productDimensions?.masterPackLength ? project.productDimensions?.masterPackLength * 0.393701 : project.productDimensions?.masterPackLength,
                    masterPackWeight: project.productDimensions?.masterPackWeight ? project.productDimensions?.masterPackWeight * 2.204 : project.productDimensions?.masterPackWeight,
                    masterPackHeight: project.productDimensions?.masterPackHeight ? project.productDimensions?.masterPackHeight * 0.393701 : project.productDimensions?.masterPackHeight,
                    masterPackWidth: project.productDimensions?.masterPackWidth ? project.productDimensions?.masterPackWidth * 0.393701 : project.productDimensions?.masterPackWidth,
                }
            });
        }
        setUseMetric(!useMetric);
    }

    const saveProjectDimensions = async () => {
        setSavingDimensions(true);
        let projectToSave = { ...project };

        // DB expects imperial units
        if (useMetric) {
            projectToSave = {
                ...project,
                productDimensions: {
                    ...project.productDimensions,
                    length: project.productDimensions?.length ? project.productDimensions?.length * 0.393701 : project.productDimensions?.length,
                    width: project.productDimensions?.width ? project.productDimensions?.width * 0.393701 : project.productDimensions?.width,
                    height: project.productDimensions?.height ? project.productDimensions?.height * 0.393701 : project.productDimensions?.height,
                    weight: project.productDimensions?.weight ? project.productDimensions?.weight * 2.204 : project.productDimensions?.weight,
                    assembledLength: project.productDimensions?.assembledLength ? project.productDimensions?.assembledLength * 0.393701 : project.productDimensions?.assembledLength,
                    assembledWidth: project.productDimensions?.assembledWidth ? project.productDimensions?.assembledWidth * 0.393701 : project.productDimensions?.assembledWidth,
                    assembledHeight: project.productDimensions?.assembledHeight ? project.productDimensions?.assembledHeight * 0.393701 : project.productDimensions?.assembledHeight,
                    assembledWeight: project.productDimensions?.assembledWeight ? project.productDimensions?.assembledWeight * 2.204 : project.productDimensions?.assembledWeight,
                    masterPackLength: project.productDimensions?.masterPackLength ? project.productDimensions?.masterPackLength * 0.393701 : project.productDimensions?.masterPackLength,
                    masterPackWeight: project.productDimensions?.masterPackWeight ? project.productDimensions?.masterPackWeight * 2.204 : project.productDimensions?.masterPackWeight,
                    masterPackHeight: project.productDimensions?.masterPackHeight ? project.productDimensions?.masterPackHeight * 0.393701 : project.productDimensions?.masterPackHeight,
                    masterPackWidth: project.productDimensions?.masterPackWidth ? project.productDimensions?.masterPackWidth * 0.393701 : project.productDimensions?.masterPackWidth,
                }
            }
        }

        const response = await makeAxiosCall(
            "post",
            `pd/dimensions/${project.id}`,
            projectToSave
        ).catch((error) => {
            console.log(error);
            showErrorText("Issue updating product dimensions");
        });

        if (response.status === 200 && response.data.success) {
            setProject({ ...project, productDimensions: { ...project.productDimensions, id: response.data.id } });
        }

        setSavingDimensions(false);
    }

    return (
        <Grid item container xs={12} spacing={2} style={{ textAlign: "right", color: "lightgray" }}>
            <Grid item xs={12} style={{ textAlign: "left", color: "black" }}>
                <h3>Assembled</h3>
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="assembled-length"
                    label={"Length"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.assembledLength}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, assembledLength: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="assembled-width"
                    label={"Width"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.assembledWidth}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, assembledWidth: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="assembled-height"
                    label={"Height"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.assembledHeight}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, assembledHeight: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="assembled-weight"
                    label={"Weight"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.assembledWeight}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, assembledWeight: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {weightMeasure}
            </Grid>

            <Grid item xs={12} style={{ textAlign: "left", color: "black" }}>
                <h3>Single Pack</h3>
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="single-pack-length"
                    label={"Length"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.length}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, length: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="single-pack-width"
                    label={"Width"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.width}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, width: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="single-pack-height"
                    label={"Height"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.height}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, height: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="single-pack-weight"
                    label={"Weight"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.weight}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, weight: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {weightMeasure}
            </Grid>

            <Grid item xs={12} style={{ textAlign: "left", color: "black" }}>
                <h3>Master Pack</h3>
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="master-pack-length"
                    label={"Length"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.masterPackLength}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, masterPackLength: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="master-pack-width"
                    label={"Width"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.masterPackWidth}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, masterPackWidth: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="master-pack-height"
                    label={"Height"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.masterPackHeight}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, masterPackHeight: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {lengthMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="master-pack-weight"
                    label={"Weight"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.masterPackWeight}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, masterPackWeight: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                {weightMeasure}
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    aria-label="master-pack-quantity"
                    label={"Quantity"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder=""
                    value={project.productDimensions?.masterPackQuantity}
                    onChange={(e) => {
                        setProject({ ...project, productDimensions: { ...project.productDimensions, masterPackQuantity: handleNumberInput(e.target.value) } });
                        setEditProjectDimensions(true);
                    }}
                />
                units
            </Grid>
            <Grid item xs={12} md={9}></Grid>

            <Grid item xs={12} md={3} style={{ textAlign: "left", color: "black" }}>
                <FormControlLabel
                    label="Use Metric"
                    labelPlacement="start"
                    control={<Switch color="primary" checked={useMetric} onChange={toggleMetric} />}
                />
            </Grid>
            <Grid item xs={12} md={6}></Grid>

            <Grid item xs={12} md={3}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    style={{ height: "100%" }}
                    disabled={!editProjectDimensions || savingDimensions}
                    onClick={() => {
                        saveProjectDimensions();
                    }}
                >
                    {savingDimensions ? <CircularProgress size={25} /> : "Save Dimensions"}
                </Button>
            </Grid>
        </Grid>
    );
}

export default PDProductDimensions;