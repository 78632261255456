import React from "react";
import _ from "lodash";
import { CompanyInterface } from "interfaces/Company";
import { BuildOrdersList, OrderInterface } from "interfaces/Order";
import { makeAxiosCall } from "utils";
import Loading from "components/utils/Loading";
import { Grid, TextField } from "@material-ui/core";
import { List, WindowScroller } from "react-virtualized";
import PendingOrderResult from "components/OrderHistory/PendingOrderResult";

interface CompanyPOsProps {
    company: CompanyInterface
}

const CompanyPOs = ({ company }: CompanyPOsProps) => {
    const [orders, setOrders] = React.useState<OrderInterface[]>();
    const [input, setInput] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    let isMounted = true;

    React.useEffect(() => {
        if (company) {
            fetchPortalOrders();
        }
        return () => {
            isMounted = false;
        };
    }, [company]);

    const fetchPortalOrders = async () => {
        const result = await makeAxiosCall(
            "get",
            `orders/${company.id}`,
        ).catch(error => {
            console.error("Caught error getting pending orders:");
            console.error(error);
        });

        if (result.data) {
            const orderData = BuildOrdersList(result.data, [company]);
            if (isMounted) {
                setOrders(orderData);
            }
        }
        setLoading(false);
    };

    const searchResults: OrderInterface[] = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        let results: OrderInterface[] = orders;
        if (searchInput.length > 0) {
            results = _.filter(orders, (o: OrderInterface) => {
                return o.customerPONum && _.includes(o.customerPONum.toLowerCase(), searchInput);
            });
        }

        return _.orderBy(results, ["createdAt"], ["desc"]);
    }, [input, orders]);

    const renderRow = ({ index, key, style }, phoneMode) => {
        const order: OrderInterface = searchResults[index];
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <PendingOrderResult order={order} phoneMode={phoneMode} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return (
            <h4 style={{ textAlign: "center", fontSize: 23, fontWeight: "normal" }}>
                No pending orders found
            </h4>
        );
    };

    return company ? (
        <div className="admin">
            <div className="grid-search">
                <h2 className="reveal-text">Customer POs</h2>
                <p>These are orders made in the Portal</p>
                <div className="search-field">
                    <TextField
                        className="prod-search"
                        label="Search for previous orders"
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setInput(e.target.value)}
                        value={input}
                        style={{ marginRight: 0, width: "100%" }}
                    />
                </div>


                {loading ?
                    (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) : (
                        <WindowScroller>
                            {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                                const phoneMode = width < 500;
                                return (
                                    <Grid container className="results">
                                        <Grid container item xs={12} className="center-text grid-search-headers">
                                            <Grid xs={1} item className="result-name">
                                                PO
                                            </Grid>
                                            <Grid item xs={3}>
                                                Date Created
                                            </Grid>
                                            <Grid item xs={4} className="result-name">
                                                Customer
                                            </Grid>
                                            <Grid item xs={4} md={2}>
                                                Status
                                            </Grid>
                                            {!phoneMode && <Grid item xs={2}>
                                                View
                                            </Grid>}
                                        </Grid>
                                        <List
                                            autoHeight
                                            width={width}
                                            height={height}
                                            isScrolling={isScrolling}
                                            onScroll={onChildScroll}
                                            scrollTop={scrollTop}
                                            rowHeight={80}
                                            rowRenderer={(props) => renderRow(props, phoneMode)}
                                            noRowsRenderer={renderPlaceholder}
                                            rowCount={searchResults.length}
                                            overscanRowCount={3}
                                        />
                                    </Grid>
                                );
                            }}
                        </WindowScroller>
                    )}
            </div>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyPOs;