import React, { Component } from "react";

export default class HandlingTab extends Component {
  isInt = (n) => {
    return n % 1 === 0;
  };

  render() {
    const config = this.props.config;

    return (
      <div className="columns">
        <div className="column">
          <p>
            <span className="info-item">Panels per case:</span>
            {config.currentProd.casePak}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Cases Needed:</span>
            {config.caseNeeded}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Dual Bumpers Needed:</span>
            {config.dualBumpers}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Single Bumpers Needed:</span>
            {config.singleBumpers}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Total Weight:</span>
            {config.totalWeight}
          </p>
        </div>
      </div>
    );
  }
}
