
export interface ContactInterface {
    // companyId: number,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    title: string,
    contactTypes: ContactType[],
    id?: number,
}

interface ContactType {
    id: number,
    name: string,
}

export const buildContacts = (rawContactList): Array<ContactInterface> => {
    const contacts: ContactInterface[] = [];
    if (!rawContactList) return contacts;
    for (let rcli = 0; rcli < rawContactList.length; rcli++) {
        const rawContact = rawContactList[rcli];
        contacts.push(buildContact(rawContact));
    }

    return contacts;
};

export const buildContact = (rawContact): ContactInterface => {
    if (!rawContact) return null;
    return {
        id: rawContact.id,
        // companyId: rawContact.company_id,
        firstName: rawContact.first_name ?? "",
        lastName: rawContact.last_name ?? "",
        email: rawContact.email,
        phoneNumber: rawContact.phone_number,
        title: rawContact.title,
        contactTypes: rawContact.contact_types ? rawContact.contact_types.map(type => { return { id: type.type.id, name: type.type.name }; }) : [],
    };
};


export const createEmptyContact = (): ContactInterface => {
    return {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        title: "",
        contactTypes: [],
    };
};

