import _ from "lodash";

export function getHubspotProductId(name) {
  console.log(name);
  const hubspotId = _.find(productIds, { product: name });
  if (hubspotId !== undefined) {
    return hubspotId.id;
  }
}

const productIds = [
  {
    product: "IRiS-ICON-2.6",
    id: 1131437484,
  },
  {
    product: "IRiS-ICON-2.9",
    id: 1141404576,
  },
  {
    product: "IRiS-ICON-IP3 XL",
    id: 1131428463,
  },
  {
    product: "IRiS-IP3",
    id: 1131431016,
  },
  {
    product: "IRiS-R2",
    id: 6931926,
  },
  {
    product: "IRiS-R3G2",
    id: 16063721,
  },
  {
    product: "CASE-IRiS-6",
    id: 0,
  },
  {
    product: "CASE-IRiS-ICON-XL-5",
    id: 0,
  },
  {
    product: "EC-3",
    id: 248396275,
  },
  {
    product: "ECPC-3",
    id: 6932492,
  },
  {
    product: "IRiS-R2, R3G2, & IP3-FLY1",
    id: 6932395,
  },
  {
    product: "IRiS-R2, R3G2, & IP3-FLY2",
    id: 6932449,
  },
  {
    product: "IRiS-ICON-FLY1",
    id: 1131454073,
  },
  {
    product: "IRiS-ICON-FLY2",
    id: 1131442530,
  },

  {
    product: "PC-INTER-1403",
    id: 6932539,
  },
  {
    product: "PC-MAIN-1403",
    id: 6932416,
  },
  {
    product: "PCT-INTER-1403",
    id: 6932420,
  },
  {
    product: "NovaStar-VX1000",
    id: 1036579755,
  },
  {
    product: "NovaStar-VX4S-N",
    id: 731165737,
  },
  {
    product: "NovaStar-VX6S",
    id: 7762316,
  },
];
