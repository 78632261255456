import React from "react";
import _ from "lodash";
import { UserContext } from "context/User";
import { buildCompanyInfo, CompanyInterface } from "interfaces/Company";
import { makeAxiosCall } from "utils";
import { buildUser, UserInterface } from "interfaces/User";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import CompanyContacts from "./CompanyContacts";
import CompanyAddresses from "./CompanyAddresses";
import CompanyResellers from "./CompanyResellers";
import CompanyInvites from "./CompanyInvites";
import CompanyMembers from "./CompanyMembers";
import CompanyRep from "./CompanyRep";
import CompanyApproval from "components/Admin/Approval/CompanyApproval";
import CompanyBilling from "./CompanyBilling";
import CompanyDocuments from "./CompanyDocuments";
import { useLocation } from "react-router-dom";

const MyCompany = () => {
    const { currentUser } = React.useContext(UserContext);
    const [activeMenu, setActiveMenu] = React.useState("Members");
    const [company, setCompany] = React.useState<CompanyInterface>();
    const [members, setMembers] = React.useState<UserInterface[]>();
    const [selectedReseller, setSelectedReseller] = React.useState<any>(0);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const redirectTab = searchParams.get("tab");
    const resellerTab = searchParams.get("reseller");

    let isMounted = true;

    React.useEffect(() => {
        if (redirectTab?.toLowerCase() == "documents") {
            setActiveMenu("Documents");
        } else {
            setActiveMenu("Members");
        }
        if (resellerTab != null) {
            let resellerId = parseInt(resellerTab);
            if(resellerId) {

                setSelectedReseller(resellerId);
                setActiveMenu("Members");
            }
        }
    }, [redirectTab, resellerTab]);

    React.useEffect(() => {
        if (selectedReseller != 0) {
            fetchCompany(selectedReseller);
        } else {
            fetchCompany(currentUser.company.id);
        }

        return () => {
            isMounted = false;
        };
    }, [selectedReseller]);

    const fetchCompany = async (id) => {
        const response = await makeAxiosCall(
            "get",
            `company/${id}`
        ).catch(error => {
            console.error("Caught error getting company info:");
            console.error(error);
        });

        if (response.data) {
            const companyData = await buildCompanyInfo(response.data);
            const membersList: UserInterface[] = await Promise.all(_.map(
                response.data.members,
                (member) => {
                    return buildUser(member);
                }
            ));
            if (isMounted) {
                setCompany(companyData);
                setMembers(_.orderBy(membersList, (result) => result.lastName.toLowerCase(), ["asc"]));
            }
        }
    };

    const renderView = () => {
        if (activeMenu === "Invites") {
            return <CompanyInvites company={company} canEdit={selectedReseller == 0} />;
        } else if (activeMenu === "Addresses") {
            return <CompanyAddresses company={company} />;
        } else if (activeMenu === "Contacts") {
            return <CompanyContacts company={company} />;
        } else if (activeMenu === "Billing") {
            return <CompanyBilling company={company} />;
        } /* else if (activeMenu === "Account") {
      return <AccountSettings company={company}/>
    }  */else if (activeMenu === "Resellers") {
            return <CompanyResellers company={company} />;
        } else if (activeMenu === "Rep") {
            return <CompanyRep company={company} />;
        } else if (activeMenu === "Documents") {
            return (
                <>
                    <CompanyDocuments company={company} />
                    {currentUser?.accountLevel?.canManageMembers && (
                        <CompanyApproval company={company} />
                    )}
                </>
            );
        } else {
            return <CompanyMembers company={company} members={members} refreshMembers={fetchCompany} canEdit={selectedReseller == 0} />;
        }
    };

    const buildResellerSelect = () => {
        if (currentUser.company && currentUser.company.resellers && currentUser.company.resellers.length > 0) {
            const resellerSelections = [{ id: 0, name: "None" }, ...currentUser.company.resellers];
            return (
                <FormControl
                    fullWidth
                >
                    <InputLabel id="demo-simple-select-outlined-label">
                        Reseller
                    </InputLabel>
                    <Select
                        onChange={(e) => {
                            setSelectedReseller(e.target.value);
                            setActiveMenu("Members");
                        }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedReseller}
                    >
                        {_.map(resellerSelections, (reseller) => {
                            return <MenuItem key={reseller.name} value={reseller.id}>{reseller.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            );
        } else {
            return (
                <h3>No Associated Resellers</h3>
            );
        }
    };

    return (
        <div className="admin view">
            <h1 className="reveal-text">
                {currentUser.company ? currentUser.company.name : "My Company"}
            </h1>
            {currentUser.company && currentUser.company.companyType == "Rep" && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <h2>View Reseller:</h2>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {buildResellerSelect()}
                    </Grid>
                </Grid>
            )}
            <div className="settings-bar">
                <div
                    onClick={() => setActiveMenu("Members")}
                    className={activeMenu === "Members" ? "item active" : "item"}
                >
                    Members
                </div>
                {currentUser.accountLevel.canManageMembers && (
                    <div
                        onClick={() => setActiveMenu("Invites")}
                        className={activeMenu === "Invites" ? "item active" : "item"}
                    >
                        Invites
                    </div>
                )}
                <div
                    onClick={() => setActiveMenu("Addresses")}
                    className={activeMenu === "Addresses" ? "item active" : "item"}
                >
                    Addresses
                </div>
                <div
                    onClick={() => setActiveMenu("Contacts")}
                    className={activeMenu === "Contacts" ? "item active" : "item"}
                >
                    Contacts
                </div>
                {selectedReseller == 0 && currentUser.accountLevel.canEditPaymentMethods && (
                    <div
                        onClick={() => setActiveMenu("Billing")}
                        className={activeMenu === "Billing" ? "item active" : "item"}
                    >
                        Billing
                    </div>
                )}
                {company && company.companyType === "Rep" && (
                    <div
                        onClick={() => setActiveMenu("Resellers")}
                        className={activeMenu === "Resellers" ? "item active" : "item"}
                    >
                        Resellers
                    </div>
                )}
                {company && company.companyType === "Reseller" && (
                    <div
                        onClick={() => setActiveMenu("Rep")}
                        className={activeMenu === "Rep" ? "item active" : "item"}
                    >
                        Representative
                    </div>
                )}
                {currentUser && selectedReseller == 0 && (
                    <div
                        onClick={() => setActiveMenu("Documents")}
                        className={activeMenu === "Documents" ? "item active" : "item"}
                    >
                        Documents
                    </div>
                )}
            </div>
            {renderView()}
        </div>
    );
};

export default MyCompany;
