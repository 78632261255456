import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { OrderInterface } from "interfaces/Order";
import { Link } from "react-router-dom";
import { WebOrderInterface } from "interfaces/WebOrder";
import { stringToMoney } from "utils";

interface WebOrderSearchResultProps {
    order: WebOrderInterface,
    mobileMode: boolean
}

const WebOrderSearchResult = ({ order, mobileMode }: WebOrderSearchResultProps) => {

    const renderFinancialStatus = (status) => {
        if (status === "paid") {
            return "status highlight green";
        } else if (status === "refunded") {
            return "status highlight red";
        } else if (status === "partially_refunded") {
            return "status highlight purple";
        } else {
            return "status highlight subtle";
        }
    };

    const renderFulfillmentStatus = (status) => {
        if (status === "restocked") {
            return "status highlight blue";
        } else if (status === "fulfilled") {
            return "status highlight green";
        } else if (status === "unfulfilled") {
            return "status highlight red";
        } else if (status === "partial") {
            return "status highlight purple";
        } else {
            return "status highlight subtle";
        }
    };

    return (
        <Paper key={order.id} elevation={2} className="grid-search-result">
            <Link to={"/web-orders/" + order.id} className="no-underline">
                <Grid container item xs={12} className="center-text" alignItems="center">
                    <Grid item container md={3} xs={3}>
                        <Grid item xs={8} md={5} className="result-name">
                            <Typography noWrap variant="body2">
                                {order.soNumber ? order.soNumber : " No SO# "}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            {order.createdAt.toLocaleString("en-US", { dateStyle: "short" })}
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Typography noWrap variant="body2">
                            {order.customer ? (order.customer.firstName + " " + order.customer.lastName) : order.anonymousCheckoutEmail}
                        </Typography>
                    </Grid>
                    {!mobileMode && <Grid item md={1}>
                        <Typography noWrap variant="body2">
                            {stringToMoney(order.total)}
                        </Typography>
                    </Grid>}
                    <Grid item md={2} xs={3}>
                        <div className={order.financialStatus ? renderFinancialStatus(order.financialStatus) : ""} style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis", marginRight: 5 }}>
                            {order.financialStatus ? order.financialStatus : ""}
                        </div>
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <div className={order.fulfillmentStatus ? renderFulfillmentStatus(order.fulfillmentStatus) : ""} style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis" }}>
                            {order.fulfillmentStatus ? order.fulfillmentStatus : ""}
                        </div>
                    </Grid>
                    {!mobileMode && <Grid item md={1}>
                        <i className="fas fa-eye blue"></i>
                    </Grid>}
                </Grid>
            </Link>
        </Paper>
    );
};

export default WebOrderSearchResult;