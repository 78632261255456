import { AddressInterface, buildAddress, createEmptyAddress } from "./Address";
import { buildCompanyInfo, CompanyInterface } from "./Company";
import { buildContact, ContactInterface, createEmptyContact } from "./Contact";
import { buildShippingPreference, NoShippingPreference, ShippingPreferenceInterface } from "./ShippingPreference";
import { UserInterface } from "./User";

export interface OrderInterface {
    id: number,
    createdAt: Date,
    updatedAt: Date,
    company: CompanyInterface,
    orderedBy: {
        id: string,
        displayName: string,
        companyId?: number,
        email?: string,
    },
    customerPONum?: string,
    fishbowlSONum?: string,
    orderStatus: OrderStatusInterface,
    billingAddress: AddressInterface,
    billingContact: ContactInterface,
    shippingAddress: AddressInterface,
    shippingContact: ContactInterface,
    customerPOURL?: string,
    notes: Array<OrderNotesInterface>,
    authorizePaymentProfileId?: string,
    lastUpdatedBy: { id: string, displayName: string },
    isDropShip: boolean,
    preferredPackingListURL?: string,
    shippingPreference: ShippingPreferenceInterface,
    shippingAccountNumber?: string,
    orderItems: Array<OrderItemInterface>,
    demoOrder?: boolean,
    returningDemo?: boolean
}

export interface OrderItemInterface {
    id: number,
    createdAt?: Date,
    updatedAt?: Date,
    quantity: number,
    product: {
        name: string,
        id: number,
        price: number,
        bstock: number,
        inventory: number,
        sku: string
    },
    lastUpdatedBy?: { name: string, id: number },
    notes?: string,
    fillWithBstock?: boolean
}

export interface OrderNotesInterface {
    id: number,
    createdAt?: Date,
    updatedAt?: Date,
    creator: { id: number, name: string },
    type: OrderNotesTypeInterface,
    content: string
}

export interface OrderNotesTypeInterface {
    id: number,
    name: string
}

export const CreateEmptyOrder = (company: CompanyInterface, currentUser: UserInterface): OrderInterface => {
    if (!company) return null;
    let shippingAddress: AddressInterface = company.addresses.find((a) => a.addressTypes.find((at) => at.name == "Shipping"));
    let shippingContact: ContactInterface = company.contacts.find((a) => a.contactTypes.find((at) => at.name == "Shipping"));

    if (!shippingAddress) shippingAddress = company.addresses.length ? company.addresses[0] : createEmptyAddress();
    if (!shippingContact) shippingContact = company.contacts.length ? company.contacts[0] : createEmptyContact();

    const tempOrder = {
        id: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        company: company,
        orderedBy: { id: currentUser.uid, displayName: currentUser.displayName },
        orderStatus: {
            id: 0,
            name: "New Order"
        },
        billingAddress: company.defaultBillingAddress,
        billingContact: company.defaultBillingContact,
        shippingAddress: shippingAddress,
        shippingContact: shippingContact,
        lastUpdatedBy: { id: currentUser.uid, displayName: currentUser.displayName },
        isDropShip: false,
        shippingPreference: company.defaultShippingPreference ? company.defaultShippingPreference : NoShippingPreference,
        orderItems: [],
        notes: [],
        demoOrder: false
    };

    if (shippingAddress.additionalShippingDetails && shippingAddress.additionalShippingDetails.contact) {
        tempOrder.shippingContact = shippingAddress.additionalShippingDetails.contact;
    }

    return tempOrder;
};

export const BuildOrderItemObject = (rawData: any): OrderItemInterface => {
    return {
        id: rawData.id,
        createdAt: rawData.created_at ? new Date(rawData.created_at) : null,
        updatedAt: rawData.updated_at ? new Date(rawData.updated_at) : null,
        quantity: rawData.quantity,
        product: {
            id: rawData.product.id,
            name: rawData.product.name,
            price: rawData.product.price,
            bstock: rawData.product.inventory_bstock,
            inventory: rawData.product.inventory,
            sku: rawData.product.sku
        },
        lastUpdatedBy: rawData.lastUpdatedBy ? {
            id: rawData.lastUpdatedBy.id,
            name: rawData.lastUpdatedBy.name
        } : null,
        notes: rawData.notes,
        fillWithBstock: rawData.fill_with_bstock,
    };
};



export const BuildOrderObjectForList = (rawOrderData: any, companyList: Array<CompanyInterface>): OrderInterface => {
    let company = null;
    let billingAddress = null;
    let billingContact = null;
    let shippingAddress = null;
    let shippingContact = null;
    
    if(companyList != null && companyList.length > 0) {
        company = companyList.find((c) => c.id == rawOrderData.company_id);
        if(!company) {
            console.log("Couldn't find company data associated with this order");
            return null;
        }
        billingAddress = company.addresses ? company.addresses.find((a) => a.id == rawOrderData.billing_address_id) : null;
        billingContact = company.contacts ? company.contacts.find((c) => c.id == rawOrderData.billing_contact_id) : null;
        shippingAddress = company.addresses ? company.addresses.find((a) => a.id == rawOrderData.shipping_address_id) : null;
        shippingContact = company.contacts ? company.contacts.find((c) => c.id == rawOrderData.shipping_contact_id) : null;
    } else {
        if(rawOrderData.billing_address) {
            billingAddress = buildAddress(rawOrderData.billing_address);
        }
        
        if(rawOrderData.shipping_address) {
            shippingAddress = buildAddress(rawOrderData.shipping_address);
        }
        
        if(rawOrderData.billing_contact) {
            billingContact = buildContact(rawOrderData.billing_contact);
        }
        
        if(rawOrderData.shipping_contact) {
            shippingContact = buildContact(rawOrderData.shipping_contact);
        }
    }
    
    const orderItems = [];
    for (let ori = 0; ori < rawOrderData.order_items.length; ori++) {
        const orderItemData = rawOrderData.order_items[ori];
        const orderItem = BuildOrderItemObject(orderItemData);

        orderItems.push(orderItem);
    }

    return {
        id: rawOrderData.id,
        createdAt: new Date(rawOrderData.created_at),
        updatedAt: new Date(rawOrderData.updated_at),
        company: company ?? rawOrderData.company,
        orderedBy: {
            ...rawOrderData.ordered_by,
            companyId: rawOrderData.ordered_by.associated_company_id,
            displayName: rawOrderData.ordered_by.name,
            email: rawOrderData.ordered_by.email,
        },
        customerPONum: rawOrderData.customer_po_num,
        fishbowlSONum: rawOrderData.fishbowl_so_num,
        orderStatus: rawOrderData.order_status,
        billingAddress: billingAddress,
        billingContact: billingContact,
        shippingAddress: shippingAddress,
        shippingContact: shippingContact,
        customerPOURL: rawOrderData.customer_po_upload_url,
        notes: rawOrderData.notes ? buildOrderNotes(rawOrderData.notes) : [],
        authorizePaymentProfileId: rawOrderData.authorize_payment_profile_id,
        lastUpdatedBy: { ...rawOrderData.last_updated_by, displayName: rawOrderData.last_updated_by.name },
        isDropShip: rawOrderData.drop_ship,
        preferredPackingListURL: rawOrderData.preferred_packing_list_upload_url,
        shippingPreference: rawOrderData.shipping_preference,
        shippingAccountNumber: rawOrderData.shipping_account_number,
        demoOrder: rawOrderData.demo_order ?? false,
        returningDemo: rawOrderData.returning_demo ?? false,
        orderItems: orderItems
    };
};

export const BuildOrdersList = (rawOrderData: Array<any>, companyList: Array<CompanyInterface>): Array<OrderInterface> => {

    const orderList: Array<OrderInterface> = [];

    for (let rodi = 0; rodi < rawOrderData.length; rodi++) {
        const orderData = rawOrderData[rodi];
        const orderObject = BuildOrderObjectForList(orderData, companyList);
        if(orderObject) orderList.push(orderObject);
    }

    return orderList;
};

export const buildOrderNotes = (rawNoteData): Array<OrderNotesInterface> => {

    const noteList: Array<OrderNotesInterface> = [];

    if (!rawNoteData) return noteList;

    for (let rodi = 0; rodi < rawNoteData.length; rodi++) {
        const noteData = rawNoteData[rodi];
        noteList.push({
            id: noteData.id,
            createdAt: new Date(noteData.created_at),
            updatedAt: new Date(noteData.updated_at),
            creator: noteData.creator ? {
                id: noteData.creator.id,
                name: noteData.creator.first_name + " " + noteData.creator.last_name
            } : null,
            type: noteData.type,
            content: noteData.content
        });
    }

    return noteList;
};

export interface OrderStatusInterface {
    id: number,
    name: string
}

// export interface PONotesInterface {
//     shippingOption: string,
//     other: string
// }

export const BuildOrderObject = async (rawData): Promise<OrderInterface> => {
    const orderItems = [];
    for (let ori = 0; ori < rawData.order_items.length; ori++) {
        const orderItemData = rawData.order_items[ori];
        const orderItem = BuildOrderItemObject(orderItemData);

        orderItems.push(orderItem);
    }
    return {
        id: rawData.id,
        createdAt: new Date(rawData.created_at),
        updatedAt: new Date(rawData.updated_at),
        company: await buildCompanyInfo(rawData.company),
        orderedBy: {
            ...rawData.ordered_by,
            companyId: rawData.ordered_by.associated_company_id,
            displayName: rawData.ordered_by.name,
            email: rawData.ordered_by.email,
        },
        customerPONum: rawData.customer_po_num ? rawData.customer_po_num : null,
        fishbowlSONum: rawData.fishbowl_so_num,
        orderStatus: BuildOrderStatusObject(rawData.order_status),
        billingAddress: buildAddress(rawData.billing_address),
        billingContact: buildContact(rawData.billing_contact),
        shippingAddress: buildAddress(rawData.shipping_address),
        shippingContact: buildContact(rawData.shipping_contact),
        customerPOURL: rawData.customer_po_upload_url,
        notes: buildOrderNotes(rawData.notes),
        authorizePaymentProfileId: rawData.authorize_payment_profile_id,
        lastUpdatedBy: { ...rawData.last_updated_by, displayName: rawData.last_updated_by.name },
        isDropShip: rawData.drop_ship,
        preferredPackingListURL: rawData.preferred_packing_list_upload_url,
        shippingPreference: buildShippingPreference(rawData.shipping_preference),
        demoOrder: rawData.demo_order ?? false,
        returningDemo: rawData.returning_demo ?? false,
        orderItems: orderItems
    };
};

export const BuildOrderStatusObject = (rawData): OrderStatusInterface => {
    return {
        id: rawData.id,
        name: rawData.name,
    };
};

export const BuildOrderStatusOptions = (rawData): Array<OrderStatusInterface> => {
    const options = [];
    for (let osoi = 0; osoi < rawData.length; osoi++) {
        const optionData = rawData[osoi];
        options.push(BuildOrderStatusObject(optionData));
    }

    return options;
};