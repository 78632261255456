import React, { useEffect, createContext } from "react";
import { makeAxiosCall } from "utils";
import { buildUserList, UserInterface } from "interfaces/User";

interface UserListContextInterface {
    loadUsers: () => Promise<UserInterface[]>,
    users: Array<UserInterface>,
}

export const UserListContext = createContext<UserListContextInterface>(null);

interface UserListProvider {
    children: any,
}

export const UserListProvider = ({ children }: UserListProvider) => {
    const [users, setUsers] = React.useState<UserInterface[]>([]);

    const loadUsers = async () => {
        const response = await makeAxiosCall("get", "account");
        if (response.data) {
            const hasuraUsers = await buildUserList(response.data);

            setUsers(hasuraUsers);
            localStorage.setItem("users", JSON.stringify(hasuraUsers));
            return hasuraUsers;
        }
    };

    useEffect(() => {
        const usersRaw = localStorage.getItem("users");
        if (usersRaw) {
            setUsers(JSON.parse(usersRaw));
        }
    }, []);

    return (
        <UserListContext.Provider
            value={{
                loadUsers,
                users
            }}
        >
            {children}
        </UserListContext.Provider>
    );
};
