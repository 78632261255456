import React, { useState, useContext } from "react";
import _ from "lodash";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Accordion, AccordionSummary, AccordionDetails, ButtonGroup, ListItem, ListItemText, Paper, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ProductContext } from "context/Product";
import { UserContext } from "context/User";
import { CartContext } from "context/Cart";
import { useNavigate } from "react-router-dom";
import { BaseProductListingInterface, getSortOrder, ProductInterface } from "interfaces/Product";
import Loading from "components/utils/Loading";
import { List, ListRowProps, WindowScroller } from "react-virtualized";
import { DeleteForever, ExpandMore, FiberNew, NewReleasesOutlined, Star, StarOutline } from "@material-ui/icons";
import DemoOrderFlag from "components/utils/DemoOrderFlag";
import { makeAxiosCall } from "utils";
import { Alert } from "@material-ui/lab";

const BaseProductSearch = (props) => {
  const navigate = useNavigate();
  const { products, baseProducts } = useContext(ProductContext);
  const { currentUser } = useContext(UserContext);
  const [input, setInput] = useState<string>(localStorage.getItem("last-search") ?? "");
  const [visibleProducts, setVisibleProducts] = React.useState<BaseProductListingInterface[]>();
  const [productListings, setProductListings] = React.useState<BaseProductListingInterface[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [baseToDelete, setBaseToDelete] = React.useState<BaseProductListingInterface>();
  const [snackbar, setSnackbar] = React.useState({
      isOpen: false,
      message: "",
      severity: "success",
  });


  const windowScrollerRef = React.useRef<WindowScroller>();
  const virtualizedListRef = React.useRef<List>();

  React.useEffect(() => {
    filterProducts();
  }, [productListings]);


  React.useEffect(() => {
    const baseProductListings: BaseProductListingInterface[] = baseProducts.map((bp) => {
      const bpp =  products.filter((prod) => prod.baseProduct?.id == bp.id);
      return {
        ...bp,
        status: {
          visiblePortal: bpp.every((p) => p.status.visiblePortal),
          visiblePublic: bpp.every((p) => p.status.visiblePublic),
          discontinued: bpp.every((p) => p.status.discontinued),
        },
        featured: bpp.findIndex((p) => p.featured) > -1,
        products: bpp,
        productTypeSortOrder: bpp.length > 0 ? getSortOrder(bpp[0].productType) : 15
      }
    });

    setProductListings(baseProductListings);
  }, [baseProducts]);

  React.useEffect(() => {
    filterProducts();
  }, [input]);


  const filterProducts = () => {
    let searchResults: BaseProductListingInterface[] = [];
    if(input.length > 0) {
      const searchInput = input.toLowerCase(),
      inputIgnoredChar = searchInput;

      searchResults = _.filter(productListings, (p: BaseProductListingInterface) => {
        let prodNum = "";
        let prodDesc = "";
        let prodSku = "";
        if (p.name !== null) {
          prodNum = p.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");
        }
        if (p.sku !== null) {
          prodSku = p.sku;
        }

        const arr = [prodNum, prodDesc, prodSku];
        const regexQuery = RegExp(inputIgnoredChar.replaceAll(" ", ".*").replaceAll("-", ".*"));
        for (let ati = 0; ati < arr.length; ati++) {
          const element = arr[ati];

          if(regexQuery.test(element)) return true;
        }
        return false;
      });
    } else {
      searchResults = productListings;
    }

    if(!currentUser || !currentUser.company || currentUser.company.companyType != "Internal") {
      searchResults = searchResults.filter((p) => p.status.visiblePortal);
    }
    
    searchResults = _.orderBy(searchResults, [ "name"], ["asc"]);

    setVisibleProducts(searchResults);

    if (loading && searchResults?.length > 0) setLoading(false);
  }

  const renderBaseProduct = (lrProps: ListRowProps) => {
    const prod: BaseProductListingInterface = visibleProducts[lrProps.index];
    return (
      <div key={lrProps.key} style={lrProps.style}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Paper style={{height: 50, padding: 8, margin: 2}} onClick={() => navigate("/base-products/" + prod.id)}>
                <Grid container>
                    <Grid item xs={11} className="result-name">
                        <h3 style={{margin: 6}}>{prod.name}</h3>
                    </Grid>
                    <Grid item xs={1}>
                        <h3 style={{margin: 6, textAlign: "end"}}>{prod.products.length}</h3>
                    </Grid>
                </Grid>
            </Paper>
          </Grid>
          <Grid item xs={1}>
              <h3>
                <DeleteForever onClick={() => {
                  setBaseToDelete(prod);
                  setShowDeleteDialog(true);
                }} />
              </h3>
          </Grid>
        </Grid>
      </div>
    );
  }

  const deleteBase = () => {
    makeAxiosCall("DELETE", `base-product/${baseToDelete.id}`).then(() => {

      setSnackbar({
          isOpen: true,
          message: "Base product deleted. The cache might take a minute to refresh.",
          severity: "error",
      });

      setShowDeleteDialog(false);
    });
  }

  const renderPlaceholder = () => {
    return <h3 className="body-message center-text">No products meet your search parameters.</h3>;
  }

  const renderResults = () => {
    if(loading) {
      return <Loading height="100vh" title={"Loading Products"} position={"center"} />;
    } else {
      return <WindowScroller ref={windowScrollerRef} style={{overflow: "hidden"}}>
        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
          return (<Grid container className="results" style={{overflow: "hidden"}}>
          <List
            style={{overflow: "visible"}}
            ref={virtualizedListRef}
            autoHeight
            width={width}
            height={height}
            isScrolling={isScrolling}
            onScroll={onChildScroll}
            scrollTop={scrollTop}
            rowHeight={60}
            rowRenderer={(itemProps) => renderBaseProduct(itemProps)}
            noRowsRenderer={renderPlaceholder}
            rowCount={visibleProducts.length}
            containerStyle={{backgroundColor: "transparent", overflow: "visible", paddingTop: 10}}
            overscanRowCount={3}
          />
        </Grid>)
        }}
      </WindowScroller>;
    }
  };

  return (
    <div className="inventory-results" style={{overflow: "hidden"}}>
      <Dialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
      >
          <DialogTitle>Delete a Base Product</DialogTitle>
          <DialogContent className="wizard">
              <p>Are you sure you want to delete <strong>{baseToDelete ? baseToDelete.name : "something"}</strong>, and orphan {baseToDelete ? baseToDelete.products.length : 0} child products?</p>
          </DialogContent>
          <DialogActions>
              <Grid container justifyContent="space-between">
                  <Button
                      onClick={() => setShowDeleteDialog(false)}
                      variant="contained"
                      color="primary"
                  >
                      Close
                  </Button>
                  <Button
                      onClick={deleteBase}
                      variant="contained"
                      color="primary"
                      style={{ backgroundColor: "tomato" }}
                  >
                      D-d-drop the base
                  </Button>
              </Grid>
          </DialogActions>
      </Dialog>
      <h1 className="reveal-text">BASED PRODUCTS</h1>
      <Grid container spacing={2} style={{marginBottom: 8}}>
        <Grid item xs={12} md={8}>
            <div className="inventory-search">
              <TextField
                label="Product Search"
                variant="outlined"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  // filterProducts();
                }}
                fullWidth
              />
            </div>
          </Grid>
          
          <Grid item xs={12} md={4}>
              <Button
              fullWidth={true}
                    onClick={() => navigate("/base-products/0")}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="btn otl"
                >
                    New Base Product
              </Button>
            </Grid>
        </Grid>
      <div style={{overflow: "hidden"}}>
        <div className="search-results-container" style={{overflow: "hidden"}}>{renderResults()}</div>
      </div>


      <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={3000}
          onClose={(_, reason) => {
              if (reason === "clickaway") {
                  return;
              }
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
          <Alert
              severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
          >
              {snackbar.message}
          </Alert>
      </Snackbar>
    </div>
  );
};

export default BaseProductSearch;
