import React from "react";
import Grid from "@material-ui/core/Grid";
import { CompanyInterface } from "interfaces/Company";
import Loading from "components/utils/Loading";
import MemberInvite from "components/MyCompany/MemberInvite";
import { UserContext } from "context/User";
import { UserInterface } from "interfaces/User";
import { Paper, Typography, Button } from "@material-ui/core";
import ModalBasic from "components/utils/ModalBasic";
import EditMember from "./EditMember";
import { useWindowWidth } from "hooks/useWindowSize";

interface CompanyMembersProps {
  company: CompanyInterface,
  members: UserInterface[],
  refreshMembers: (companyId: number) => void,
  canEdit?: boolean,
}

const CompanyMembers = ({ company, members, refreshMembers, canEdit = true }: CompanyMembersProps) => {
    const { currentUser } = React.useContext(UserContext);
    const [modal, setModal] = React.useState(false);
    const [memberEdit, setMemberEdit] = React.useState<UserInterface>();
    const windowWidth = useWindowWidth();

    const editUserCallback = () => {
        setModal(false);
        refreshMembers(company.id);
    };

    const renderBody = () => {
        if (members && members.length > 0) {
            return members.map(member => {
                return (
                    <Paper key={member.uid} elevation={2} className="grid-search-result">
                        <Grid container item xs={12} alignItems="center">
                            <Grid item md={3} xs={8} className="result-name">
                                <Typography noWrap variant="body2">
                                    {member.displayName}
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={2} className="center-text">
                                {member.accountLevel.name}
                            </Grid>
                            <Grid item md={4} xs={8} className="center-text">
                                {member.email}
                            </Grid>
                            <Grid item md={2} xs={2} className="center-text">
                                {(member.lastActivity && (typeof member.lastActivity == "object")) ? ((member.lastActivity.getMonth() + 1) + "/" + member.lastActivity.getDate() + "/" + member.lastActivity.getFullYear()) : "None"}
                            </Grid>
                            <Grid item md={1} xs={4}>
                                {canEdit && currentUser.accountLevel.canManageMembers && (
                                    <Button
                                        onClick={() => {
                                            setMemberEdit(member);
                                            setModal(true);
                                        }}
                                        fullWidth={true}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className="btn blue"
                                        style={{color: "white"}}
                                    >
                                        <i className="far fa-edit"></i>
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                );
            });
        } else {
            return <p className="body-message">No Members Found</p>;
        }
    };

    return members ? (
        <div className="grid-search">
            <ModalBasic
                open={modal}
                close={() => setModal(false)}
            >
                <EditMember
                    selectedUser={memberEdit}
                    company={company}
                    editCallback={editUserCallback}
                />
            </ModalBasic>
            <Grid container alignItems="center" >
                <Grid item xs={12} sm={9}>
                    <h2>Members</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {canEdit && currentUser.accountLevel.canManageMembers && (
                        <MemberInvite company={company} />
                    )}
                </Grid>
            </Grid>
            <Grid container className="results">
                {windowWidth > 700 && <Grid container item xs={12} className="grid-search-headers">
                    <Grid xs={3} item className="result-name">
            Name
                    </Grid>
                    <Grid item xs={2} className="center-text">
            Account Level
                    </Grid>
                    <Grid item xs={4} className="center-text">
            Email
                    </Grid>
                    <Grid item xs={2} className="center-text">
            Last Activity
                    </Grid>
                    <Grid item xs={1} className="center-text">
                        {canEdit && currentUser.accountLevel.canManageMembers && (
                            "Edit User"
                        )}
                    </Grid>
                </Grid>}
                {renderBody()}
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyMembers;