import React, { useContext } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, OutlinedInput, Popover, Select } from "@material-ui/core";
import { UserContext } from "context/User";
import { CSSTransition } from "react-transition-group";
import { SideDrawerTab, ViewContext } from "context/View";
import { useNavigate } from "react-router-dom";
import { CartContext } from "context/Cart";
import { Button } from "@material-ui/core";
import OrderIcon from "components/Nav/OrderIcon";
// import CustomFieldSyncButton from "components/Nav/CustomFieldSyncButton";
import { Link } from "react-router-dom";
import ApprovalExpirationPopup from "components/utils/ApprovalExpirationPopup";
import { makeAxiosCall } from "utils";
import 'scss/components/Nav.scss';
import NewDemoButton from "components/utils/NewDemoButton";
import { useWindowWidth } from "hooks/useWindowSize";

const TopNav = () => {
    const { user, currentUser, clearTempUser, signOut, internalUser } = useContext(UserContext);
    const { currentOrder, saveOrderingDemo, clearCart } = React.useContext(CartContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ghosting, setGhosting] = React.useState(user !== currentUser);
    const windowWidth = useWindowWidth();

    const [showExpirationPopup, setShowExpirationPopup] = React.useState<boolean>(false);

    const openExpirationPopup = () => {
        setShowExpirationPopup(true);
    };

    const closeExpirationPopup = () => {
        setShowExpirationPopup(false);
    };

    React.useEffect(() => {
        setGhosting(user !== currentUser);
    }, [user, currentUser]);

    const showOrderButton = React.useMemo(() => {
        return currentOrder && currentOrder.orderItems.length < 1 && !currentOrder.demoOrder;
    }, [currentOrder]);

    const showOrderDemoButton = React.useMemo(() => {
        // return false;
        return currentUser && currentUser.company && currentUser.company.companyType == "Rep" && currentOrder && currentOrder.orderItems.length < 1 && !currentOrder.demoOrder;
    }, [currentUser, currentOrder]);

    const showExpirationWarning = React.useMemo(() => {
        return currentUser.company?.approvalStatus?.expirationDate !== null;
    }, [currentUser]);

    const showCompanyOptionsDropdown = React.useMemo(() => {
        return currentUser.otherCompanyOptions && currentUser.otherCompanyOptions.length > 1;
    }, [currentUser]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const { saveCurrentPanel } = React.useContext(ViewContext);

    const id = open ? "simple-popover" : undefined;

    return (
        <div className="top-nav" style={windowWidth < 700 ? {height: 60} : {}}>
            {/* {internalUser ? <CustomFieldSyncButton /> : <></>} */}
            <div className="tool-bar" style={windowWidth < 700 ? {height: 60} : {}}>
                <CSSTransition
                    in={ghosting}
                    unmountOnExit
                    timeout={200}
                    classNames="alert"
                >
                    <div className="user-as">
                        {ghosting ? "Operating as " + currentUser.displayName : " "}
                        {ghosting && (
                            <i
                                onClick={() => clearTempUser()}
                                className="far fa-times-circle"
                            ></i>
                        )}
                    </div>
                </CSSTransition>

                {showExpirationWarning && (
                    <i 
                        className="far fa-triangle-exclamation"
                        style={{ 
                            color: "orangered",
                            fontSize: "1.5em"
                        }}
                        onClick={openExpirationPopup}
                    ></i>
                )}

                {showCompanyOptionsDropdown && windowWidth > 700 && (
                    <Select
                        className={"company-options-select"}
                        key="company-options"
                        label="Company"
                        value={currentUser.company.id}
                        
                        renderValue={() => (
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {currentUser.company.name}
                            </Box>
                        )}
                        onChange={(e: React.ChangeEvent<{
                            name?: string;
                            value: number;
                        }>) => {
                            makeAxiosCall("get", `update-account-company/${e.target.value}`).then((r) => {
                                window.location.reload();
                            });
                        }}
                    >
                        {currentUser.otherCompanyOptions.map(
                            (t) => {
                                return (
                                    <MenuItem
                                        key={t.id}
                                        value={t.id}
                                    >
                                        <i className={t.id == currentUser.company.id ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: t.id == currentUser.company.id ? "green" : "grey" }}></i>
                                        {t.name}
                                    </MenuItem>
                                );
                            }
                        )}
                    </Select>
                )}

                <ApprovalExpirationPopup 
                    popupOpen={showExpirationPopup}
                    onClose={closeExpirationPopup}
                />

                {showOrderDemoButton && <NewDemoButton topNavButton={true} />}

                {showOrderButton ? (<Link style={{ width: "auto", marginRight: 20, borderBottom: "none" }} to={currentUser.company.companyType == "Internal" || currentUser.company.companyType == "Rep" ? "/checkout" : "/products"}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="btn"
                        onClick={() => {
                            if (currentUser.company.companyType == "Internal" || currentUser.company.companyType == "Rep") {
                                saveOrderingDemo(false);
                                saveCurrentPanel("checkout");
                            } else {
                                saveCurrentPanel(SideDrawerTab.products);
                            }
                        }}
                    >
                        Start an Order
                    </Button>
                </Link>) : <OrderIcon />}
                <i onClick={handleClick} className="fas fa-user"></i>
                <Popover
                    id={id}
                    className="account-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    {currentUser && (
                        <div className="account-container">
                            <div className="user">
                                <span>{currentUser.displayName}</span>
                                <div className="email">{currentUser.email}</div>
                                <div className="account-level highlight blue">
                                    {` ${currentUser.company.name}  ${currentUser.accountLevel.name}`}
                                </div>
                            </div>
                            <Link to="/account">
                                <p
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Account
                                </p>
                            </Link>
                            <p
                                onClick={() => {
                                    clearCart();
                                    localStorage.setItem("logout-event", "true");
                                    signOut();
                                }}
                            >
                                Sign out
                            </p>
                        </div>
                    )}
                </Popover>
            </div>
        </div>
    );
};

export default TopNav;
