import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { UserContext } from "context/User";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import _ from "lodash";
import { CompanyInterface } from "interfaces/Company";
import { ContactInterface } from "interfaces/Contact";

type ContactSelectProps = {
    setNewFormActive: (active: boolean) => void,
    onSelect?: (contact: ContactInterface) => void,
    currentSelection?: ContactInterface,
    hasError?: boolean,
    label?: string,
    company?: CompanyInterface
}

const ContactSelect = ({ setNewFormActive, onSelect, currentSelection, hasError, label, company }: ContactSelectProps) => {
    const { currentUser } = React.useContext(UserContext);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [error, setError] = React.useState(hasError);
    const [contacts, setContacts] = React.useState<ContactInterface[]>();
    const [selectedContact, setSelectedContact] = React.useState<ContactInterface>(currentSelection);
    const inputLabel = React.useRef(null);

    React.useEffect(() => {
        if (currentSelection != selectedContact) {
            setSelectedContact(currentSelection);
        }
    }, [currentSelection]);

    React.useEffect(() => {
        if (error != hasError) {
            setError(hasError);
        }
    }, [hasError]);

    React.useEffect(() => {
        if (company) {
            setContacts(company.contacts);
        } else {
            setContacts(currentUser.company.contacts);
        }
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = (e) => {
        const newContact = _.filter(contacts, ["id", e.target.value])[0];

        if (e.target.value === "New Contact") {
            setNewFormActive(true);
        } else {
            setSelectedContact(newContact);
            if (onSelect) {
                onSelect(newContact);
            }
            setNewFormActive(false);
        }
    };

    return (
        <div className="contact-select">
            <FormControl
                style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
                variant="outlined"
            >
                <InputLabel ref={inputLabel} id="contact-select-outlined-label">
                    {label ? label : "Select a Contact"}
                </InputLabel>

                <Select
                    error={error}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    labelId="contact-select-label"
                    labelWidth={labelWidth}
                    value={
                        selectedContact && selectedContact.id
                            ? selectedContact.id
                            : "No Contacts Found"
                    }
                >
                    <MenuItem key="new-contact-key" value="New Contact">
                        New Contact
                    </MenuItem>
                    {contacts &&
                        _.map(contacts, (contact: ContactInterface) => {
                            return (
                                <MenuItem key={contact.id} value={contact.id}>
                                    {`${contact.firstName} ${contact.lastName} (${contact.contactTypes.map((t) => t.name).join(", ")})`}
                                </MenuItem>
                            );
                        })}
                </Select>

            </FormControl>
        </div>
    );
};

export default ContactSelect;
