import React from "react";
import logo from "assets/images/logo.svg";
import {
    Button,
    Select,
    MenuItem,
    TextField,
    Grid,
    OutlinedInput,
    Box,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import "scss/components/Account/Wizard/ResellerInfo.scss";
import { buildResellerApplicationInterface, ResellerApplicationInterface } from "interfaces/ResellerApplication";
import { makeAxiosCall } from "utils";
import { UserContext } from "context/User";
import SuccessAni from "components/utils/SuccessAni";

const ResellerApplication = () => {

    const DEFAULT_FORM_DATA: ResellerApplicationInterface = {
        name: "",
        phone: "",
        email: "",
        legalEntityName: "",
        website: "",
        dba: "",
        hasEIN: false,
        yearsInBusiness: "",
        businessType: "",
        businessDescription: "",
        hasRetail: false,
        hasShowroom: false,
        accreditations: "",
        otherManufacturers: "",
        primaryAddressAddress: "",
        primaryAddressCity: "",
        primaryAddressState: "",
        primaryAddressZip: "",
        shippingAddressAddress: "",
        shippingAddressCity: "",
        shippingAddressState: "",
        shippingAddressZip: "",
        tradeReference1Name: "",
        tradeReference1Email: "",
        tradeReference1Phone: "",
        tradeReference2Name: "",
        tradeReference2Email: "",
        tradeReference2Phone: "",
        tradeReference3Name: "",
        tradeReference3Email: "",
        tradeReference3Phone: "",
    };

    const startingValidation = {
        name: "",
        phone: "",
        email: "",
        legalEntityName: "",
        website: "",
        yearsInBusiness: "",
        businessType: "",
        businessDescription: "",
        accreditations: "",
        otherManufacturers: "",
        primaryAddressAddress: "",
        primaryAddressCity: "",
        primaryAddressState: "",
        primaryAddressZip: "",
        shippingAddressAddress: "",
        shippingAddressCity: "",
        shippingAddressState: "",
        shippingAddressZip: "",
        tradeReference1Name: "",
        tradeReference1Email: "",
        tradeReference1Phone: "",
        tradeReference2Name: "",
        tradeReference2Email: "",
        tradeReference2Phone: "",
        tradeReference3Name: "",
        tradeReference3Email: "",
        tradeReference3Phone: "",
    };

    const { currentUser } = React.useContext(UserContext);
    const [formData, setFormData] = React.useState<ResellerApplicationInterface>(DEFAULT_FORM_DATA);
    const [businessType, setBusinessType] = React.useState<number>();
    const [validation, setValidation] = React.useState(startingValidation);
    const [sameShipping, setSameShipping] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
    const [redirectTimeout, setRedirectTimeout] = React.useState<NodeJS.Timeout>();
    

    const navigate = useNavigate();

    React.useEffect(() => {
        if (currentUser /* && currentUser.accountSignupStatus == "applied" */) {
            setLoggedIn(true);
            clearTimeout(redirectTimeout);
            fetchResellerApplication();
        }
    }, [currentUser]);

    React.useEffect(() => {
        if (!currentUser) {
            setRedirectTimeout(setTimeout(checkLoggedIn, 3000));
        }
    }, []);

    const checkLoggedIn = () => {
        if(!loggedIn) {
            // user hasn't logged in yet
            window.location.href = "https://portal.blizzardpro.com/?type=signup&newReseller=true";
        }
    }

    const fetchResellerApplication = async () => {
        const response = await makeAxiosCall(
            "get",
            `application/${currentUser.uid}`
        ).catch((error) => {
            console.error(error);
        });

        if (response?.status == 200) {
            try {
                const existingData = buildResellerApplicationInterface(response.data);
                setBusinessType(businessTypes.find((t) => t.name == existingData.businessType).id);
                setFormData(existingData);
            } catch (error) {
                console.log("Failed to get existing application");
            }
        }
    };

    const businessTypes = [
    // {id: 0, name: "Business Type"},
        { id: 1, name: "Sole Proprietorship" },
        { id: 2, name: "Partnership" },
        { id: 3, name: "Corporation" },
        { id: 4, name: "LLC" }
    ];

    const handleChange = (name: string) => (e) => {
        setFormData({
            ...formData,
            [name]: e.target.value,
        });
    };

    const handleCheckbox = (name: string) => (e) => {
        setFormData({
            ...formData,
            [name]: e.target.checked
        });
    };

    const handleShippingAddressCheckbox = (e) => {
        if (!e.target.checked) {
            setFormData({
                ...formData,
                shippingAddressAddress: "",
                shippingAddressCity: "",
                shippingAddressState: "",
                shippingAddressZip: "",
            });
        }

        setSameShipping(e.target.checked);
    };

    const validate = () => {
        const errors = {
            name: "",
            phone: "",
            email: "",
            legalEntityName: "",
            website: "",
            yearsInBusiness: "",
            businessType: "",
            businessDescription: "",
            accreditations: "",
            otherManufacturers: "",
            primaryAddressAddress: "",
            primaryAddressCity: "",
            primaryAddressState: "",
            primaryAddressZip: "",
            shippingAddressAddress: "",
            shippingAddressCity: "",
            shippingAddressState: "",
            shippingAddressZip: "",
            tradeReference1Name: "",
            tradeReference1Email: "",
            tradeReference1Phone: "",
            tradeReference2Name: "",
            tradeReference2Email: "",
            tradeReference2Phone: "",
            tradeReference3Name: "",
            tradeReference3Email: "",
            tradeReference3Phone: "",
        };

        errors.name = formData.name.length === 0 ? "Required" : "";
        errors.phone = formData.phone.length === 0 ? "Required" : "";
        errors.email = formData.email.length === 0 ? "Required" : "";
        errors.legalEntityName = formData.legalEntityName.length === 0 ? "Required" : "";
        errors.website = formData.website.length === 0 ? "Required" : "";
        errors.yearsInBusiness = formData.yearsInBusiness.length === 0 ? "Required" : "";
        errors.businessType = formData.businessType.length === 0 ? "Required" : "";
        errors.businessDescription = formData.businessDescription.length === 0 ? "Required" : "";
        errors.accreditations = formData.accreditations.length === 0 ? "Required" : "";
        errors.otherManufacturers = formData.otherManufacturers.length === 0 ? "Required" : "";
        errors.primaryAddressAddress = formData.primaryAddressAddress.length === 0 ? "Required" : "";
        errors.primaryAddressCity = formData.primaryAddressCity.length === 0 ? "Required" : "";
        errors.primaryAddressState = formData.primaryAddressState.length === 0 ? "Required" : "";
        errors.primaryAddressZip = formData.primaryAddressZip.length === 0 ? "Required" : "";

        if (!sameShipping) {
            errors.shippingAddressAddress = formData.shippingAddressAddress.length === 0 ? "Required" : "";
            errors.shippingAddressCity = formData.shippingAddressCity.length === 0 ? "Required" : "";
            errors.shippingAddressState = formData.shippingAddressState.length === 0 ? "Required" : "";
            errors.shippingAddressZip = formData.shippingAddressZip.length === 0 ? "Required" : "";
        }

        errors.tradeReference1Name = formData.tradeReference1Name.length === 0 ? "Required" : "";
        errors.tradeReference1Email = formData.tradeReference1Email.length === 0 ? "Required" : "";
        errors.tradeReference1Phone = formData.tradeReference1Phone.length === 0 ? "Required" : "";
        errors.tradeReference2Name = formData.tradeReference2Name.length === 0 ? "Required" : "";
        errors.tradeReference2Email = formData.tradeReference2Email.length === 0 ? "Required" : "";
        errors.tradeReference2Phone = formData.tradeReference2Phone.length === 0 ? "Required" : "";
        errors.tradeReference3Name = formData.tradeReference3Name.length === 0 ? "Required" : "";
        errors.tradeReference3Email = formData.tradeReference3Email.length === 0 ? "Required" : "";
        errors.tradeReference3Phone = formData.tradeReference3Phone.length === 0 ? "Required" : "";

        setValidation({ ...errors });

        return Object.values(errors).every(x => x === "");
    };

    const updateUserStatus = () => {
        makeAxiosCall(
            "post",
            `account/${currentUser.uid}`,
            {
                accountSignupStatus: "applied"
            }
        ).catch(error => {
            console.error(error);
        });
    };

    const handleSubmit = async () => {
        if (validate()) {
            let submitData: ResellerApplicationInterface = {...formData};
  
            if (sameShipping) {
                submitData = {
                    ...formData,
                    shippingAddressAddress: formData.primaryAddressAddress,
                    shippingAddressCity: formData.primaryAddressCity,
                    shippingAddressState: formData.primaryAddressState,
                    shippingAddressZip: formData.primaryAddressZip,
                };
            }

            console.log(submitData);

            const response = await makeAxiosCall(
                "post",
                `application/${currentUser.uid}`,
                submitData,
            ).catch((error) => {
                console.error(error);
            });

            if (response?.status == 200) {
                setSubmitted(true);
                updateUserStatus();
            }
        }
    };

    const submitButton = () => {
        return (
            <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className="btn"
            >
        Submit
            </Button>
        );
    };

    const companyNameField = () => {
        return (
            <Grid item xs={8}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Legal Entity Name"}
                    margin="normal"
                    variant="outlined"
                    value={formData.legalEntityName}
                    onChange={handleChange("legalEntityName")}
                    error={validation.legalEntityName.length !== 0}
                    helperText={validation.legalEntityName}
                />
            </Grid>
        );
    };

    const dbaField = () => {
        return (
            <Grid item xs={6}>
                <TextField
                    style={{ width: "100%" }}
                    label={"DBA*"}
                    margin="normal"
                    variant="outlined"
                    helperText={"* denotes optional field"}
                    value={formData.dba}
                    onChange={handleChange("dba")}
                />
            </Grid>
        );
    };

    const contactNameField = () => {
        return (
            <Grid item xs={4}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Your Name"}
                    margin="normal"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleChange("name")}
                    error={validation.name.length !== 0}
                    helperText={validation.name}
                />
            </Grid>
        );
    };

    const contactEmailField = () => {
        return (
            <Grid item xs={4}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Email"}
                    margin="normal"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleChange("email")}
                    error={validation.email.length !== 0}
                    helperText={validation.email}
                />
            </Grid>
        );
    };

    const contactPhoneField = () => {
        return (
            <Grid item xs={4}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Phone Number"}
                    margin="normal"
                    variant="outlined"
                    value={formData.phone}
                    onChange={handleChange("phone")}
                    error={validation.phone.length !== 0}
                    helperText={validation.phone}
                />
            </Grid>
        );
    };

    const primaryAddressField = () => {
        return (
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <h3>Primary Address</h3>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Address"}
                            margin="normal"
                            variant="outlined"
                            value={formData.primaryAddressAddress}
                            onChange={handleChange("primaryAddressAddress")}
                            error={validation.primaryAddressAddress.length !== 0}
                            helperText={validation.primaryAddressAddress}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"City"}
                            margin="normal"
                            variant="outlined"
                            value={formData.primaryAddressCity}
                            onChange={handleChange("primaryAddressCity")}
                            error={validation.primaryAddressCity.length !== 0}
                            helperText={validation.primaryAddressCity}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"State"}
                            margin="normal"
                            variant="outlined"
                            value={formData.primaryAddressState}
                            onChange={handleChange("primaryAddressState")}
                            error={validation.primaryAddressState.length !== 0}
                            helperText={validation.primaryAddressState}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"ZIP Code"}
                            margin="normal"
                            variant="outlined"
                            value={formData.primaryAddressZip}
                            onChange={handleChange("primaryAddressZip")}
                            error={validation.primaryAddressZip.length !== 0}
                            helperText={validation.primaryAddressZip}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const shippingAddressField = () => {
        return (
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <h3>Shipping Address</h3>

                    <FormControlLabel
                        style={{ width: "100%" }}
                        control={
                            <Checkbox
                                color="primary"
                                value={sameShipping}
                                onChange={handleShippingAddressCheckbox}
                            />
                        }
                        label={"Same as Primary"}
                    />
                </Grid>

                {!sameShipping && (
                    <>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"Address"}
                                    margin="normal"
                                    variant="outlined"
                                    value={formData.shippingAddressAddress}
                                    onChange={handleChange("shippingAddressAddress")}
                                    error={validation.shippingAddressAddress.length !== 0}
                                    helperText={validation.shippingAddressAddress}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"City"}
                                    margin="normal"
                                    variant="outlined"
                                    value={formData.shippingAddressCity}
                                    onChange={handleChange("shippingAddressCity")}
                                    error={validation.shippingAddressCity.length !== 0}
                                    helperText={validation.shippingAddressCity}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"State"}
                                    margin="normal"
                                    variant="outlined"
                                    value={formData.shippingAddressState}
                                    onChange={handleChange("shippingAddressState")}
                                    error={validation.shippingAddressState.length !== 0}
                                    helperText={validation.shippingAddressState}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={"ZIP Code"}
                                    margin="normal"
                                    variant="outlined"
                                    value={formData.shippingAddressZip}
                                    onChange={handleChange("shippingAddressZip")}
                                    error={validation.shippingAddressZip.length !== 0}
                                    helperText={validation.shippingAddressZip}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    };

    const yearsInBusinessField = () => {
        return (
            <Grid item xs={3}>
                <TextField
                    style={{ width: "100%" }}
                    label={"# of Years in Business"}
                    margin="normal"
                    variant="outlined"
                    value={formData.yearsInBusiness}
                    onChange={handleChange("yearsInBusiness")}
                    error={validation.yearsInBusiness.length !== 0}
                    helperText={validation.yearsInBusiness}
                />
            </Grid>
        );
    };

    const businessDescriptionField = () => {
        return (
            <Grid item xs={6}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Primary Business Description"}
                    margin="normal"
                    variant="outlined"
                    value={formData.businessDescription}
                    onChange={handleChange("businessDescription")}
                    error={validation.businessDescription.length !== 0}
                    helperText={validation.businessDescription}
                />
            </Grid>
        );
    };

    const businessTypeField = () => {
        return (
            <Grid item xs={3}>
                <FormControl
                    variant="outlined"
                    style={{ width: "100%", marginTop: 16, marginBottom: 8 }}
                    error={validation.businessType.length !== 0}
                >
                    <InputLabel
                        style={{ backgroundColor: "white" }}
                    >
            Business Type
                    </InputLabel>
                    <Select
                        style={{ width: "100%" }}
                        key="business-type"
                        label="Business Type"
                        value={formData.businessType}
                        renderValue={() => (
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {businessTypes.find((t) => t.id == businessType).name}
                            </Box>
                        )}
                        onChange={(e: React.ChangeEvent<{
              name?: string;
              value: number;
            }>) => {
                            setBusinessType(e.target.value);
                            setFormData(
                                {
                                    ...formData,
                                    businessType: businessTypes.find((type) => type.id == e.target.value).name
                                }
                            );
                        }}
                        input={<OutlinedInput />}
                    >
                        {businessTypes.map(
                            (t) => {
                                return (
                                    <MenuItem
                                        key={t.id}
                                        value={t.id}
                                    >
                                        <i className={t.id == businessType ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: t.id == businessType ? "green" : "grey" }}></i>
                                        {t.name}
                                    </MenuItem>
                                );
                            }
                        )}
                    </Select>
                    <FormHelperText>{validation.businessType}</FormHelperText>
                </FormControl>
            </Grid>
        );
    };

    const einField = () => {
        return (
            <Grid item xs={6}>
                <FormControlLabel
                    style={{ width: "100%", marginTop: 30 }}
                    control={
                        <Checkbox
                            color="primary"
                            value={formData.hasEIN}
                            onChange={handleCheckbox("hasEIN")}
                        />
                    }
                    label={"Do you have a Federal Employer Identification Number?"}
                />
            </Grid>
        );
    };

    const retailStoreField = () => {
        return (
            <Grid item xs={3}>
                <FormControlLabel
                    style={{ width: "100%", marginTop: 30 }}
                    control={
                        <Checkbox
                            color="primary"
                            value={formData.hasRetail}
                            onChange={handleCheckbox("hasRetail")}
                        />
                    }
                    label={"Do you have a retail store?"}
                />
            </Grid>
        );
    };

    const showroomField = () => {
        return (
            <Grid item xs={2}>
                <FormControlLabel
                    style={{ width: "100%", marginTop: 30 }}
                    control={
                        <Checkbox
                            color="primary"
                            value={formData.hasShowroom}
                            onChange={handleCheckbox("hasShowroom")}
                        />
                    }
                    label={"A Showroom?"}
                />
            </Grid>
        );
    };

    const websiteField = () => {
        return (
            <Grid item xs={4}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Website URL"}
                    margin="normal"
                    variant="outlined"
                    value={formData.website}
                    onChange={handleChange("website")}
                    error={validation.website.length !== 0}
                    helperText={validation.website}
                />
            </Grid>
        );
    };

    const accreditationsField = () => {
        return (
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Professional Accreditations/Licensures"}
                    margin="normal"
                    variant="outlined"
                    value={formData.accreditations}
                    onChange={handleChange("accreditations")}
                    error={validation.accreditations.length !== 0}
                    helperText={validation.accreditations}
                />
            </Grid>
        );
    };

    const otherManufacturersField = () => {
        return (
            <Grid item xs={12}>
                <TextField
                    style={{ width: "100%" }}
                    label={"Which Sound/Lighting Manufacturers/Distributors do you currently buy from?"}
                    margin="normal"
                    variant="outlined"
                    value={formData.otherManufacturers}
                    onChange={handleChange("otherManufacturers")}
                    error={validation.otherManufacturers.length !== 0}
                    helperText={validation.otherManufacturers}
                />
            </Grid>
        );
    };

    const referencesField = () => {
        return (
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <h3>Trade References: <span style={{ fontWeight: "lighter" }}>(Current Vendors in Pro Light/Sound Industry)</span></h3>
                </Grid>
                <Grid container item xs={12} spacing={2}>

                    <Grid item xs={1} style={{ marginTop: 16, marginBottom: 8 }}>
                        <h3 style={{ textAlign: "right" }}>1.</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Name"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference1Name}
                            onChange={handleChange("tradeReference1Name")}
                            error={validation.tradeReference1Name.length !== 0}
                            helperText={validation.tradeReference1Name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Email"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference1Email}
                            onChange={handleChange("tradeReference1Email")}
                            error={validation.tradeReference1Email.length !== 0}
                            helperText={validation.tradeReference1Email}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Phone Number"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference1Phone}
                            onChange={handleChange("tradeReference1Phone")}
                            error={validation.tradeReference1Phone.length !== 0}
                            helperText={validation.tradeReference1Phone}
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={1} style={{ marginTop: 16, marginBottom: 8 }}>
                        <h3 style={{ textAlign: "right" }}>2.</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Name"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference2Name}
                            onChange={handleChange("tradeReference2Name")}
                            error={validation.tradeReference2Name.length !== 0}
                            helperText={validation.tradeReference2Name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Email"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference2Email}
                            onChange={handleChange("tradeReference2Email")}
                            error={validation.tradeReference2Email.length !== 0}
                            helperText={validation.tradeReference2Email}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Phone Number"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference2Phone}
                            onChange={handleChange("tradeReference2Phone")}
                            error={validation.tradeReference2Phone.length !== 0}
                            helperText={validation.tradeReference2Phone}
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={1} style={{ marginTop: 16, marginBottom: 8 }}>
                        <h3 style={{ textAlign: "right" }}>3.</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Name"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference3Name}
                            onChange={handleChange("tradeReference3Name")}
                            error={validation.tradeReference3Name.length !== 0}
                            helperText={validation.tradeReference3Name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Email"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference3Email}
                            onChange={handleChange("tradeReference3Email")}
                            error={validation.tradeReference3Email.length !== 0}
                            helperText={validation.tradeReference3Email}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            style={{ width: "100%" }}
                            label={"Phone Number"}
                            margin="normal"
                            variant="outlined"
                            value={formData.tradeReference3Phone}
                            onChange={handleChange("tradeReference3Phone")}
                            error={validation.tradeReference3Phone.length !== 0}
                            helperText={validation.tradeReference3Phone}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderForm = () => {
        return (
            <Grid container>
                <Grid container item xs={12} spacing={2}>
                    {contactNameField()}
                    {contactPhoneField()}
                    {contactEmailField()}
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    {companyNameField()}
                    {websiteField()}
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    {dbaField()}
                    {einField()}
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    {yearsInBusinessField()}
                    {businessTypeField()}
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    {businessDescriptionField()}
                    {retailStoreField()}
                    {showroomField()}
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    {accreditationsField()}
                </Grid>

                <Grid container item xs={12} spacing={2}>
                    {otherManufacturersField()}
                </Grid>

                <Grid item xs={12} style={{ height: 30 }}></Grid>
                {primaryAddressField()}
                <Grid item xs={12} style={{ height: 30 }}></Grid>
                {shippingAddressField()}

                <Grid item xs={12} style={{ height: 30 }}></Grid>
                {referencesField()}
            </Grid>
        );
    };

    return (
        <Grid container className="reseller-info-form-wrapper">
            {!submitted ? <Grid container item xs={12}>
                <Grid item xs={3}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate("/");
                        }}
                        className="btn"
                    >
            Back
                    </Button>
                </Grid>
            </Grid> : null }
            {!submitted ? <Grid container item xs={12}>
                <Grid item>
                    <img style={{
                        width: 50,
                        borderRadius: "50%",
                        padding: 10,
                        marginTop: 25
                    }} src={logo} alt="Blizzard" />
                </Grid>
                <Grid item xs={9} style={{ marginTop: 20 }}>
                    <h1>Reseller Application</h1>
                </Grid>
            </Grid> : null }

            {!submitted ? (
                <>
                    <Grid container item xs={12} className="form-container">
                        {renderForm()}
                    </Grid>

                    <Grid container item xs={12} style={{ marginTop: 20 }}>
                        <Grid item xs={10}>
                        </Grid>
                        <Grid item xs={2}>
                            {submitButton()}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <SuccessAni title={"Application Submitted"} marginTop={30} />
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ textAlign: "center" }}>
              A Blizzard representative will review your application and contact
              you with further details.
                        </p>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                navigate("/");
                            }}
                            className="btn"
                        >
              Back
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ResellerApplication;
