
export interface InviteInterface {
    email: string,
    company: InviteCompanyInterface,
    accountLevel: InviteAccountLevelInterface,
    lastUpdate: Date
    id?: number,
}

export interface InviteCompanyInterface {
    id: number,
    name: string,
}

export interface InviteAccountLevelInterface {
    id: number,
    name: string,
}

export const buildInvite = (inviteData): InviteInterface => {

    return {
        id: inviteData.id,
        email: inviteData.email,
        company: {
            id: inviteData.portal_company.id,
            name: inviteData.portal_company.name,
        },
        accountLevel: {
            id: inviteData.portal_account_level.id,
            name: inviteData.portal_account_level.name,
        },
        lastUpdate: inviteData.updated_at ? new Date(inviteData.updated_at) : new Date()
    };
};

export interface NewResellerInviteInterface {
    id?: number,
    email: string,
    createdBy: {
        id: string,
        displayName: string,
        company?: {
            id: number,
            name: string,
        }
    },
    companyName?: string,
    newUser?: {
        id: string,
        displayName: string,
        company?: {
            id: number,
            name: string,
        }
    }
}

export const buildNewResellerInvite = (rawData): NewResellerInviteInterface => {
    return {
        id: rawData.id,
        email: rawData.email,
        companyName: rawData.company_name,
        createdBy: {
            id: rawData.created_by.id,
            displayName: rawData.created_by.name,
            company: {
                id: rawData.created_by.company?.id,
                name: rawData.created_by.company?.name,
            }
        },
        newUser: {
            id: rawData.new_user?.id,
            displayName: rawData.new_user?.name,
            company: {
                id: rawData.new_user?.company?.id,
                name: rawData.new_user?.company?.name,
            }
        }
    };
};

export const buildNewResellerInviteList = (rawData): NewResellerInviteInterface[] => {
    return rawData.map((invite) => buildNewResellerInvite(invite));
};