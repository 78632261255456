import React from "react";
import AddressForm from "components/Account/Address/AddressForm";
import { CSSTransition } from "react-transition-group";
import AddressSelect from "components/Account/Address/AddressSelect";
import { UserContext } from "context/User";
import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { CompanyInterface } from "interfaces/Company";
import { AddressInterface } from "interfaces/Address";

interface AddressBookProps {
    title: string,
    type?: string,
    currentReseller?: CompanyInterface,
    onSelect?: (address: AddressInterface) => void,
    currentSelection?: AddressInterface,
    containerClass?: string,
    refresh?: () => void,
}

const AddressBook = ({ title, type, currentReseller, onSelect, currentSelection, containerClass, refresh }: AddressBookProps) => {
    const { currentUser } = React.useContext(UserContext);
    const [showAll, setShowAll] = React.useState<boolean>(false);
    const [newFormActive, setNewFormActive] = React.useState(false);

    const addressList: AddressInterface[] = React.useMemo(() => {
        const addresses = currentReseller && currentReseller.id != currentUser.company.id ? currentReseller.addresses : currentUser.company.addresses;
        if (showAll || !type) {
            return addresses;
        } else {
            return addresses.filter((address) => address.addressTypes.some((t) => t.name == type));
        }
    }, [currentReseller, currentUser, type, showAll]);

    return (
        <div className={"account-info " + (containerClass ? containerClass : "")}>
            <Grid container alignItems="center">
                <Grid item md={5} xs={12}>
                    {title && (<h3>{title}</h3>)}
                </Grid>
                <Grid item md={5} xs={5} style={{ textAlign: "right" }}>
                    {type && (
                        <FormControlLabel
                            control={<Checkbox checked={showAll} />}
                            onChange={() => setShowAll(!showAll)}
                            label="Show All"
                        />
                    )}
                </Grid>
                <Grid item md={2} xs={7}>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => setNewFormActive(!newFormActive)}
                        className="btn"
                    >
                        {newFormActive ? "Cancel" : "Add New Address"}
                    </Button>
                </Grid>
            </Grid>


            <React.Fragment>
                <AddressSelect
                    setNewFormActive={setNewFormActive}
                    addresses={addressList}
                    onSelect={onSelect}
                    currentSelection={currentSelection}
                />
            </React.Fragment>

            <CSSTransition
                in={newFormActive}
                unmountOnExit
                timeout={200}
                classNames="alert"
            >
                <Grid container spacing={1} className="new-form">
                    <h3>New Address</h3>
                    <AddressForm addressType={type} companyId={currentReseller && currentReseller.id ? currentReseller.id : currentUser.company.id} addCallback={(newAddress) => {
                        onSelect(newAddress);
                        setNewFormActive(false);
                        if (refresh) refresh();
                    }} />
                </Grid>
            </CSSTransition>
        </div>
    );
};

export default AddressBook;