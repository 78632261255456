import React from "react";
import { CartContext } from "context/Cart";
import 'scss/components/Checkout/OrderOnBehalfOf.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Info } from "@material-ui/icons";

interface DemoOrderFlagProps {
    inSummary?: boolean
}

const DemoOrderFlag = (props: DemoOrderFlagProps) => {

  const {
    inSummary
  } = props;

  const { currentOrder, saveOrderingDemo } = React.useContext(CartContext);
  const [ showInfoOpen, setShowInfo ] = React.useState<boolean>(false);

  const hideInfo = () => {
    setShowInfo(false);
  };

  const showInfo = () => {
    setShowInfo(true);
  };

  const infoDialog = () => {
    return (
      <Dialog
        open={showInfoOpen}
        onClose={hideInfo}
      >
        <DialogTitle>
          You are ordering a Demo
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Demo orders are subject to approval by our sales team. <br />
            <ul>
              <li>40% discount off of reseller price</li>
              <li>Net 90 terms</li>
              <li>Free shipping, unless returned by RMA prior to 90 day expiration</li>
            </ul>
            If there are any questions or comments, a member of our team will reach out personally to provide more details on the individual product and request.<br /><br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideInfo} autoFocus>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if(!currentOrder || !currentOrder.demoOrder) return null;
  return (<div>
        {inSummary ?
            (<h1>
                <Info fontSize="small" onClick={showInfo} />
                <span onClick={showInfo}> This is an order for demo product(s)</span>
            </h1>) : 
            <h3>
                <Info fontSize="small" onClick={showInfo} />
                <span onClick={showInfo}> This is a demo order</span>
                <a style={{fontSize: 12, fontWeight: "lighter", marginLeft: 10}} onClick={() => saveOrderingDemo(false)}>cancel</a>
            </h3>
        }

        { infoDialog() }
    </div>);
};

export default DemoOrderFlag;
