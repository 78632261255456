import React, { useContext } from "react";
import Loading from "components/utils/Loading";
import _ from "lodash";
import { ProductContext } from "context/Product";
import { Button, CircularProgress, Grid, IconButton, Snackbar, TextField } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { BaseProductInterface, ProductInterface } from "interfaces/Product";
import { DeleteForever, LinkOff, SportsKabaddi, Star, StarBorderOutlined } from "@material-ui/icons";
import { makeAxiosCall } from "utils";

const SingleBaseProduct = () => {
    const { products, baseProducts } = useContext(ProductContext);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [featuredChild, setFeaturedChild] = React.useState<number>();
    const [baseProduct, setBaseProduct] = React.useState<BaseProductInterface>();
    const [linkedProducts, setLinkedProducts] = React.useState<ProductInterface[]>([]);
    const [linkableProducts, setLinkableProducts] = React.useState<ProductInterface[]>([]);
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });

    const baseProductId = window.location.pathname.split("/base-products/")[1] ? parseInt(window.location.pathname.split("/base-products/")[1]) : 0;


    React.useEffect(() => {
        if(baseProduct) return;

        const linkableProducts = products.filter((prod) => true);
        setLinkableProducts(linkableProducts);

        if(baseProductId == 0) {
            setBaseProduct({
                id: 0,
                name: "",
                sku: "",
                imageUrl: "",
                websiteUrl: ""
            });
            return;
        }

        let matchingBase = baseProducts.find((bp) => bp.id == baseProductId);
        if(matchingBase) {
            const matchingProducts = products.filter((prod) => prod.baseProduct?.id == baseProductId);

            setBaseProduct(matchingBase);
            setLinkedProducts(matchingProducts);
        }
    }, [products, baseProducts]);

    const renderSaveButton = () => {
        return (<Button
            onClick={saveBaseProduct}
            type="submit"
            style={{ margin: 20, width: "calc(100% - 40px)" }}
            variant="contained"
            color="primary"
            className="btn otl"
        >
            {saving ? (
                <CircularProgress
                    color="inherit"
                    size={24}
                />
            ) : ("Save Base Product")
            }
        </Button>);
    };

    const renderSingleProduct = (product: ProductInterface) => {
        let dangerZone = (product.baseProduct && product.baseProduct.id != baseProductId);

        return (
            <Grid key={'sp-' + product.id} item container xs={12} spacing={2}>
                <Grid item xs={1}>
                    <IconButton
                        style={{
                        }}
                        onClick={() => setBaseProduct({...baseProduct, featuredChildId: product.id})}
                    >
                        {
                            baseProduct.featuredChildId == product.id ? 
                            <Star
                                style={{ color: "gold" }}
                            /> :
                            <StarBorderOutlined
                                style={{ color: "#828282" }}
                            />
                        }
                        
                    </IconButton>
                </Grid>
                <Grid item xs={6}><h3>{product.name}</h3></Grid>
                <Grid item xs={3}>
                    <TextField
                        onChange={(e) => {
                            let newList = [];

                            for (let lpi = 0; lpi < linkedProducts.length; lpi++) {
                                const lp = linkedProducts[lpi];
                                if(lp.id == product.id) {
                                    newList.push({...lp, variantLabel: e.target.value});
                                } else {
                                    newList.push(lp);
                                }
                                
                            }
                            setLinkedProducts(newList);
                        }}
                        value={product.variantLabel ?? ""}
                        style={{ width: "100%" }}
                        label={"Variant Label"}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={2} style={{margin: "auto"}}>
                    <Button
                        fullWidth={true}
                        onClick={() => {

                            let newList = [];

                            for (let lpi = 0; lpi < linkedProducts.length; lpi++) {
                                const lp = linkedProducts[lpi];
                                if(lp.id != product.id) {
                                    newList.push(lp);
                                }
                            }
                            setLinkedProducts(newList);
                        }}
                        variant="contained"
                        color="primary"
                        className="btn"
                    >
                        Remove <SportsKabaddi />
                    </Button>
                </Grid>
                {dangerZone && <Grid item xs={12}><strong>Heads up! It looks like {product.name} is already associated with a different base. Saving will overwrite the existing connection.</strong></Grid>}
            </Grid>
        );
    };

    const renderProductList = () => {
        let singleProductElements = [];

        for (let spi = 0; spi < linkedProducts.length; spi++) {
            const singleProd = linkedProducts[spi];
            singleProductElements.push(renderSingleProduct(singleProd))
        }

        return (
            <Grid container>
                {singleProductElements}
                {renderAddProductField()}
            </Grid>
        );
    };

    const renderAddProductField = () => {
        return (
            <Grid item xs={12} key={"add-new-child-product"}>
                <Autocomplete
                    fullWidth={true}
                    options={linkableProducts ? linkableProducts : []}
                    clearOnBlur={true}
                    onChange={(event, newValue) => {
                        if(!newValue) return;
                        let newList = [...linkedProducts];
                        switch(typeof newValue) {
                            case 'string':
                                break;
                            default:
                               newList.push(newValue);
                        }
                        
                        setLinkedProducts(newList);
                    }}
                    getOptionLabel={(product) => product ? product.name : "Product Not Found"}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={"Add another product"}
                            variant="outlined"
                            fullWidth
                        />
                    )}
                />
            </Grid>
        );
    };

    const saveBaseProduct = () => {
        if(saving) return;
        else {
            setSaving(true);
        }

        if(!baseProduct.featuredChildId) {
            setSnackbar({
                isOpen: true,
                message: "You must pick a favorite to be displayed on the website!",
                severity: "error",
            });
            setSaving(false);
            return;
        }

        if(!baseProduct.name.length) {
            setSnackbar({
                isOpen: true,
                message: "Bruh, the base product needs a name.",
                severity: "error",
            });
            setSaving(false);
            return;
        }

        for (let lpi = 0; lpi < linkedProducts.length; lpi++) {
            const lp = linkedProducts[lpi];
            if(!lp.variantLabel) {
                setSnackbar({
                    isOpen: true,
                    message: lp.name + " needs a variant label.",
                    severity: "error",
                });
                setSaving(false);
                return;
            }
        }

        
        makeAxiosCall("POST", `base-product/${baseProductId}`, {
            baseProduct: baseProduct, 
            linkedProducts: linkedProducts
        }).then((response) => {
            setSaving(false);
            if(response.error) {
                setSnackbar({
                isOpen: true,
                message: "Save Failed: " + response.error,
                severity: "error"
                });
            } else {
                setSnackbar({
                    isOpen: true,
                    message: "Save Successful",
                    severity: "success"
                });
                if(!baseProductId && response.data && response.data.id) {
                    setTimeout(() => {
                        window.location.href = "https://portal.blizzardpro.com/base-products/" + response.data.id;    
                    }, 1000);
                }
            }
        }).catch((response) => {
            setSaving(false);
            if(response.data && response.data.error) {
                console.log(response);
                setSnackbar({
                    isOpen: true,
                    message: "Save Failed: " + response.data.error,
                    severity: "error"
                });
            }
            else {
                setSnackbar({
                    isOpen: true,
                    message: "Save Failed. Check the logs, or talk to Devon.",
                    severity: "error"
                });
            }
        })
    };

    return (
        <div className="view">
            {baseProduct ? (
                <div>
                    <h1>{baseProduct.name}</h1>
                    <h2>New Name:</h2>
                    <TextField
                        onChange={(e) => {
                            setBaseProduct({...baseProduct, name: e.target.value});
                        }}
                        value={baseProduct ? baseProduct.name : ""}
                        style={{ width: "100%" }}
                        label={"New Name"}
                        margin="normal"
                        variant="outlined"
                    />

                    <h2>Child Products:</h2>
                    {renderProductList()}
                    {renderSaveButton()}
                </div>
            ) : <Loading height="40vh" title="Loading Products" position="relative" />}

            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={3000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    // For Future Devon - This snackbar is the toast
                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SingleBaseProduct;
