import React, { createContext } from "react";
import { UserContext } from "./User";
import { makeAxiosCall } from "../utils";
import { AddressTypeInterface } from "interfaces/Address";
import { CartContext } from "./Cart";

interface AddressContextInterface {
    addNewAddress: (newAddress: any, companyId: number, type: string) => any,
    updateAddress: (address: any, companyId: number) => any,
    deleteAddress: (addressId: number) => any,
    addressTypes: AddressTypeInterface[],
}

export const AddressContext = createContext<AddressContextInterface>(null);

interface AddressProviderProps {
    children: any,
}

export const AddressProvider = ({ children }: AddressProviderProps) => {

    const { fetchCurrentUser } = React.useContext(UserContext);
    const { currentOrder, saveCurrentOrder } = React.useContext(CartContext);

    const [addressTypes, setAddressTypes] = React.useState<AddressTypeInterface[]>([]);

    React.useEffect(() => {
        fetchAddressTypes();
    }, []);

    const fetchAddressTypes = async () => {
        const result = await makeAxiosCall(
            "get",
            "address-types"
        );

        if (result.data) {
            setAddressTypes(result.data);
        }
    };

    const addNewAddress = (newAddress, companyId: number, type: string) => {
        return addAddressToHasura({ ...newAddress, companyId: companyId }, type).then((success) => {
            if (success) {
                // addAddressToFishbowl(newAddress, type);
            }
            return success;
        });
    };

    const addAddressToHasura = (newAddress, type: string) => {
        return makeAxiosCall(
            "post",
            "address",
            newAddress,
            { headers: { "Content-Type": "application/json" } }
        )
            .then((response) => {
                if (response.data.id) {
                    fetchCurrentUser();

                    if (type === "Billing") {
                        saveCurrentOrder({ ...currentOrder, billingAddress: { ...currentOrder.billingAddress, id: response.data.id } });
                    } else if (type === "Shipping") {
                        saveCurrentOrder({ ...currentOrder, shippingAddress: { ...currentOrder.shippingAddress, id: response.data.id } });
                    }

                    return response;
                } else {
                    return false;
                }
            })
            .catch((error) => {
                console.log("CAUGHT ERROR IN ADD ADDRESS TO HASURA");
                console.error(error);
                return false;
            });
    };

    // const addAddressToFishbowl = (newAddress: AddressInterface, type: string) => {
    //   console.log("INSIDE OF ADD NEW FISHBOWL ADDRESS");
    //   console.log(user);
    //   let fishbowlCustomerName = currentCustomer.name;
    //   if(!fishbowlCustomerName && currentUser && currentUser.company && currentUser.company.name) {
    //     fishbowlCustomerName = currentUser.company.name;
    //   }

    //   let address = newAddress.streetAddressTwo.length > 0 ?
    //       newAddress.streetAddress + "\n" + newAddress.streetAddressTwo :
    //       newAddress.streetAddress;

    //   const customerAddress = [
    //     {
    //       name: fishbowlCustomerName,
    //       addressContact: newAddress.contactName,
    //       addressType: type === "billing" ? 20 : 10,
    //       address: address,
    //       city: newAddress.city,
    //       state: newAddress.state,
    //       zip: newAddress.zip,
    //       country: newAddress.country,
    //       taxRate: currentCustomer.taxRateName,
    //       salesman: currentCustomer.username,
    //       carrierName: currentCustomer.carrierName,
    //       carrierService: currentCustomer.carrierService,
    //       shippingTerms: currentCustomer.shipTerms,
    //     },
    //   ];

    //   return makeAxiosCall(
    //     "post",
    //     "write-address",
    //     customerAddress,
    //     { headers: {"Content-Type": "application/json"} }
    //   )
    //   .then((res) => {
    //     if (res.status === 201) {
    //       return true;
    //     } else {
    //       // Failsafe
    //       console.log(res);
    //       return false;
    //     }
    //   })
    //   .catch((error) => {
    //     // Fishbowl Errors
    //     if (error.response && error.response.data && error.response.data.message) {
    //       console.error(error.response.data.message);
    //     } else {
    //       console.error(error);
    //     }
    //     return false;
    //   });
    // };

    const updateAddress = (address, companyId: number): Promise<boolean> => {
        return updateAddressInHasura({ ...address, companyId: companyId }).then(response => {
            return response;
        });
    };

    const updateAddressInHasura = (address): Promise<boolean> => {
        return makeAxiosCall(
            "post",
            `address/${address.id}`,
            address,
            { headers: { "Content-Type": "application/json" } }
        )
            .then((response) => {
                if (response.data.id) {
                    fetchCurrentUser();
                    return response;
                } else {
                    return false;
                }
            })
            .catch((error) => {
                console.log("CAUGHT ERROR IN UPDATE ADDRESS IN HASURA");
                console.error(error);
                return false;
            });
    };

    const deleteAddress = (id: number) => {
        return makeAxiosCall(
            "delete",
            `address/${id}`
        ).then(response => {
            if (response.data.id) {
                fetchCurrentUser();
                return response;
            } else {
                return false;
            }
        }).catch(error => {
            console.error("FAILED TO DELETE");
            console.error(error);
            return false;
        });
    };

    return (
        <AddressContext.Provider
            value={{
                addNewAddress,
                updateAddress,
                deleteAddress,
                addressTypes,
            }}
        >
            {children}
        </AddressContext.Provider>
    );
};
