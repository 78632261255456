import Button from "@material-ui/core/Button";


const UnauthorizedPage = () => {

  return (
    <div className="error-boundary">
      <div className="container">
        <i
          style={{ fontSize: "4em", color: "tomato" }}
          className="fa-duotone fa-do-not-enter"
        ></i>
        <h2>Unauthorized</h2>
        <p>You do not have authorization to view this page. Please reach out to Blizzard Support if you believe this is a mistake.</p>
        <Button
          onClick={() => (window.location.href = "/")}
          type="submit"
          style={{ width: "48%", background: "#282bf6" }}
          variant="contained"
          color="primary"
          className="btn"
        >
          Return
        </Button>
      </div>
    </div>
  );
}

export default UnauthorizedPage;