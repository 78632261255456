import React, { useContext } from "react";
import _ from "lodash";
import { Grid, ListItem, ListItemText, ListItemAvatar, Button } from "@material-ui/core";
import { UserContext } from "context/User";
import Loading from "components/utils/Loading";
import { ListAlt } from "@material-ui/icons";
import { makeAxiosCall } from "utils";
import { buildFeedbackListsFromRaw, buildFeedbackSearchLists,DemoFeedbackRepReportInterface, DemoFeedbackUserReportInterface } from "interfaces/DemoFeedback";
import { Link } from "react-router-dom";

import "scss/components/Demos/DemoFeedbackList.scss";
import { buildBasicUserInterfaceList } from "interfaces/User";

const DemoFeedbackOverview = (props) => {
  const { currentUser, internalUser } = useContext(UserContext);
  const [allRepFeedbackList, setAllRepFeedbackList] = React.useState<DemoFeedbackRepReportInterface[]>([]);
  const [allUserFeedbackList, setAllUserFeedbackList] = React.useState<DemoFeedbackUserReportInterface[]>([]);
  const [loading, setLoading] = React.useState(true);



  React.useEffect(() => {
    loadFeedback();
  }, []);

  const loadFeedback = async () => {
    let result;

    if(internalUser || currentUser && currentUser.company && currentUser.company.companyType == "Internal") {
        result = await makeAxiosCall(
            "get",
            "demo-feedback?recent=true"
        ).catch(error => {
            console.error(error);
        });
    } else {
        result = await makeAxiosCall(
            "get",
            "demo-feedback/" + currentUser.company.id + "?recent=true"
        ).catch(error => {
            console.error(error);
        });
    }

    if(result && result.data) {
        let {fullRepFeedbackList, fullUserFeedbackList} = buildFeedbackSearchLists(
            buildFeedbackListsFromRaw(result.data.portal_demo_feedback), 
            result.data.reps, // These should be ok raw
            buildBasicUserInterfaceList(result.data.users)
        );

        fullRepFeedbackList.sort((a, b) => b.numberDemoed - a.numberDemoed);
        fullUserFeedbackList.sort((a, b) => b.demoFeedback.length - a.demoFeedback.length);
        setAllRepFeedbackList(fullRepFeedbackList);
        setAllUserFeedbackList(fullUserFeedbackList);
        setLoading(false);
    }
  }

  const renderRep = (rep: DemoFeedbackRepReportInterface) => {
    return (
        <ListItem 
            key={"feedback-overview-list-feedback-" + rep.listKey}
            className={"feedback-list-list-item"}
            dense={true}
        >
            <ListItemAvatar>
                <ListAlt />
            </ListItemAvatar>
            <ListItemText
                primary={rep.name}
                style={{ cursor: "pointer" }}
            />
            <ListItemAvatar>
                <h4>{rep.numberDemoed}</h4>
            </ListItemAvatar>
        </ListItem>
    );
  }

  const renderUser = (user: DemoFeedbackUserReportInterface) => {
    return (
        <ListItem 
            key={"feedback-overview-list-feedback-" + user.listKey}
            className={"feedback-list-list-item"}
        >
            <ListItemAvatar>
                <ListAlt />
            </ListItemAvatar>
            <ListItemText
                primary={user.name}
                style={{ cursor: "pointer" }}
            />
            <ListItemAvatar>
                <h4>{user.demoFeedback.length}</h4>
            </ListItemAvatar>
        </ListItem>
    );
  }

  const renderRow = (index: number) => {
    if(internalUser) {
        return renderRep(allRepFeedbackList[index]);            
    } else {

        return renderUser(allUserFeedbackList[index]);
    }
  }

  const renderResults = () => {
    if(loading) {
      return <Loading height="100%" title={"Loading Products"} position={"center"} />;
    } else {
        let listItems = [];

        for (let ri = 0; ri < ((internalUser ? allRepFeedbackList : allUserFeedbackList)).length; ri++) {
            listItems.push(renderRow(ri))
        }

        return (<Grid item xs={12} container className="results" style={{maxHeight: 400, overflowY: "auto"}}>
            {listItems}
        </Grid>)
    }
  };


  return (
    <Grid item xs={12} className="overview-card" key={"rep-toolbox-card"}>
        <div
            className="card no-hover"
            // style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/logo-bg.jpg?alt=media&token=685a2f24-2094-4b5f-9f83-bff2f3f9fc17')" }}
            style={{ backgroundColor: "#282bf6", minHeight: 200, padding: 24 }}
        >
            <div className="top-info">
                
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <h2>Demo Feedback</h2>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            style={{ width: "100%", backgroundColor: "white", color: "black" }}
                            variant="contained"
                            color="secondary"
                            className="btn"
                            component={Link}
                            to={"/demos"}
                        >
                            {internalUser ? "More info" : "My Demo Feedback"}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="bottom-info">
                            Demos in the last 30 days
                        </div>
                    </Grid>
                    {renderResults()}

                </Grid>
            </div>

        </div>
    </Grid>
);

};

export default DemoFeedbackOverview;
