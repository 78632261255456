const DefaultState = {
  loading: false,
  volumePricing: [],
  pricingRule: [],
  errorMsg: "",
};

export const pricingReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case "GET_PRICING_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_PRICING_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Eror Loading Pricing",
      };
    case "GET_PRICING_SUCCESS":
      return {
        ...state,
        loading: false,
        volumePricing: action.payload,
        errMsg: "",
      };
    case "GET_DISCOUNT_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_DISCOUNT_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Eror Loading Discount",
      };
    case "GET_DISCOUNT_SUCCESS":
      return {
        ...state,
        loading: false,
        pricingRule: action.payload,
        errMsg: "",
      };
    default:
      return state;
  }
};

