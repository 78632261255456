import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import "scss/components/Admin/EditUser.scss";
import { makeAxiosCall } from "../../utils";
import { buildUser, UserInterface } from "interfaces/User";
import { buildCompanyInfo, CompanyInterface } from "interfaces/Company";
import CompanyContacts from "components/MyCompany/CompanyContacts";
import CompanyAddresses from "components/MyCompany/CompanyAddresses";
import CompanyResellers from "components/MyCompany/CompanyResellers";
import CompanyMembers from "components/MyCompany/CompanyMembers";
import CompanyInvites from "components/MyCompany/CompanyInvites";
import CompanyApproval from "./Approval/CompanyApproval";
import CompanyPOs from "./CompanyPOs";
import CompanySOs from "./CompanySOs";
import { Grid } from "@material-ui/core";
import CompanyDocuments from "components/MyCompany/CompanyDocuments";

const EditCompany = () => {
    // Allow routing to specific tab
    const navigation = useLocation();
    let startingPage = "Start";
    if (navigation && navigation.state && navigation.state.startingPage) {
        startingPage = navigation.state.startingPage;
    }

    const companyId = window.location.pathname.split("/edit-company/")[1];
    const [company, setCompany] = React.useState<CompanyInterface>();
    const [members, setMembers] = React.useState<UserInterface[]>();
    const [activeMenu, setActiveMenu] = React.useState(startingPage);

    let isMounted = true;

    React.useEffect(() => {
        fetchCompany();
        return () => {
            isMounted = false;
        };
    }, []);


    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectTab = urlParams.get("tab");
        if(activeMenu == "Start" && redirectTab) {
            if (redirectTab?.toLowerCase() == "documents") {
                setActiveMenu("Documents");
            } else if (redirectTab?.toLowerCase() == "sos") {
                setActiveMenu("SOs");
            } else if (redirectTab?.toLowerCase() == "pos") {
                setActiveMenu("POs");
            } else {
                setActiveMenu("Users");
            }
        }
    }, [window.location.search]);

    React.useEffect(() => {
        if (navigation.state) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?tab=' + activeMenu;
            window.history.pushState({path:newurl},'',newurl);
        }
    }, [activeMenu]);

    const fetchCompany = async () => {
        const response = await makeAxiosCall(
            "get",
            `company/${companyId}`
        ).catch(error => {
            console.error("Caught error getting company info:");
            console.error(error);
        });

        if (response.data) {
            const companyData: CompanyInterface = await buildCompanyInfo(response.data);
            const membersList: UserInterface[] = await Promise.all(_.map(
                response.data.members,
                (member) => {
                    return buildUser(member);
                }
            ));
            if (isMounted) {
                setCompany(companyData);
                setMembers(membersList);
                if (activeMenu === startingPage && (!companyData.approved || (companyData.approvalStatus && companyData.approvalStatus.expirationDate != null))) {
                    setActiveMenu("Approval");
                }
            }
        }
    };

    const renderView = () => {
        if (activeMenu === "Users") {
            return <CompanyMembers company={company} members={members} refreshMembers={fetchCompany} canEdit={true} />;
        } else if (activeMenu === "Invites") {
            return <CompanyInvites company={company} canEdit={true} />;
        } else if (activeMenu === "Contacts") {
            return <CompanyContacts company={company} />;
        } else if (activeMenu === "Addresses") {
            return <CompanyAddresses company={company} />;
        } else if (activeMenu === "Resellers") {
            return <CompanyResellers company={company} />;
        } else if (activeMenu === "Approval") {
            return <CompanyApproval company={company} refresh={fetchCompany} canEdit={true} members={members} />;
        } else if (activeMenu === "Documents") {
            return <CompanyDocuments company={company} />;
        } else if (activeMenu === "POs") {
            return <CompanyPOs company={company} />;
        } else if (activeMenu === "SOs") {
            return <CompanySOs company={company} />;
        } else {
            return <CompanyMembers company={company} members={members} refreshMembers={fetchCompany} canEdit={true} />;
            // return <CompanyInfoPage company={company} />
        }
    };

    return (
        <Grid container className="admin view">
            <Grid item xs={12}>
                <h1 className="reveal-text mobile-padding">
                    {company && company.name ? `${company.name}` : ""}
                    <span style={{fontSize: "0.6em", paddingLeft: 25}}>
                      {company && company.fishbowlCustomerId ? `(FB ID: ${company.fishbowlCustomerId})` : ""}
                    </span>
                </h1>
            </Grid>
            <Grid item xs={12} className="settings-bar mobile-padding">

                {/* <div
          onClick={() => setActiveMenu("General")}
          className={activeMenu === "General" ? "item active" : "item"}
        >
          General
        </div> */}
                <div
                    onClick={() => setActiveMenu("Users")}
                    className={activeMenu === "Users" || activeMenu === "Start" ? "item active" : "item"}
                >
                    Users
                </div>
                <div
                    onClick={() => setActiveMenu("Invites")}
                    className={activeMenu === "Invites" ? "item active" : "item"}
                >
                    Invites
                </div>
                <div
                    onClick={() => setActiveMenu("Addresses")}
                    className={activeMenu === "Addresses" ? "item active" : "item"}
                >
                    Addresses
                </div>
                <div
                    onClick={() => setActiveMenu("Contacts")}
                    className={activeMenu === "Contacts" ? "item active" : "item"}
                >
                    Contacts
                </div>
                <div
                    onClick={() => setActiveMenu("Approval")}
                    className={activeMenu === "Approval" ? "item active" : "item"}
                >
                    Approval
                </div>
                <div
                    onClick={() => setActiveMenu("Documents")}
                    className={activeMenu === "Documents" ? "item active" : "item"}
                >
                    Documents
                </div>
                {company && company.companyType === "Rep" && (
                    <div
                        onClick={() => setActiveMenu("Resellers")}
                        className={activeMenu === "Resellers" ? "item active" : "item"}
                    >
                        Resellers
                    </div>
                )}
                <div
                    onClick={() => setActiveMenu("POs")}
                    className={activeMenu === "POs" ? "item active" : "item"}
                >
                    POs
                </div>
                <div
                    onClick={() => setActiveMenu("SOs")}
                    className={activeMenu === "SOs" ? "item active" : "item"}
                >
                    SOs
                </div>
            </Grid>
            <Grid item xs={12}>
                {renderView()}
            </Grid>
        </Grid>
    );
};

export default EditCompany;
