
export interface ResellerApplicationInterface {
    id?: number,
    name: string,
    phone: string,
    email: string,
    legalEntityName: string,
    website: string,
    dba?: string,
    hasEIN: boolean,
    yearsInBusiness: string,
    businessType: string,
    businessDescription: string,
    hasRetail: boolean,
    hasShowroom: boolean,
    accreditations: string,
    otherManufacturers: string,
    primaryAddressAddress: string,
    primaryAddressCity: string,
    primaryAddressState: string,
    primaryAddressZip: string,
    shippingAddressAddress: string,
    shippingAddressCity: string,
    shippingAddressState: string,
    shippingAddressZip: string,
    tradeReference1Name: string,
    tradeReference1Email: string,
    tradeReference1Phone: string,
    tradeReference2Name: string,
    tradeReference2Email: string,
    tradeReference2Phone: string,
    tradeReference3Name: string,
    tradeReference3Email: string,
    tradeReference3Phone: string,
}

export const buildResellerApplicationInterface = (data) => {
    return {
        id: data.id,
        name: data.name,
        phone: data.phone,
        email: data.email,
        legalEntityName: data.legal_entity_name,
        website: data.website,
        dba: data.dba,
        hasEIN: data.has_ein,
        yearsInBusiness: data.years_in_business,
        businessType: data.business_type,
        businessDescription: data.business_description,
        hasRetail: data.has_retail,
        hasShowroom: data.has_showroom,
        accreditations: data.accreditations,
        otherManufacturers: data.other_manufacturers,
        primaryAddressAddress: data.primary_address_address,
        primaryAddressCity: data.primary_address_city,
        primaryAddressState: data.primary_address_state,
        primaryAddressZip: data.primary_address_zip,
        shippingAddressAddress: data.shipping_address_address,
        shippingAddressCity: data.shipping_address_city,
        shippingAddressState: data.shipping_address_state,
        shippingAddressZip: data.shipping_address_zip,
        tradeReference1Name: data.reference_1_name,
        tradeReference1Email: data.reference_1_email,
        tradeReference1Phone: data.reference_1_phone,
        tradeReference2Name: data.reference_2_name,
        tradeReference2Email: data.reference_2_email,
        tradeReference2Phone: data.reference_2_phone,
        tradeReference3Name: data.reference_3_name,
        tradeReference3Email: data.reference_3_email,
        tradeReference3Phone: data.reference_3_phone,
    };
};