import React from "react";
import snowflake from "assets/images/logo.svg";

const ConnectionAlert = () => {
  return (
    <>
      <img src={snowflake} alt="Blizzard Lighting" className="logo-active" />
    </>
  );
};

export default ConnectionAlert;
