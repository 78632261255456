import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { ProductInterface } from "interfaces/Product";
import { UserContext } from "context/User";
import { FiberNew } from "@material-ui/icons";

interface SingleProductGeneralProps {
  product: ProductInterface,
  writeAccess: boolean,
}

const SingleProductGeneral = ({ product, writeAccess }: SingleProductGeneralProps) => {
  const { internalUser } = useContext(UserContext);

  const mainFeatures = React.useMemo(() => {
    const featuresField = product.customFields?.find((field) => field.name == "Main Features");

    return featuresField ? featuresField.value : "";
  }, [product]);

  const imageUrl = React.useMemo(() => {
    return product.imageUrl ?? product.baseProduct?.imageUrl;
  }, [product]);


  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const renderPriceDisplay = (price: number, title: string) => {

    /* TEMP PIXELHUE EXCLUSION */
    if(title !== "Reseller" && !internalUser && product.name.toLowerCase().indexOf("pixelhue") > -1) {
      return null;
    }

    return price ? (
      <Grid item xs={12} md={4}>
        <p style={{
          fontSize: 25,
        }}>${price}</p>
        <h3 style={{ 
          color: "white",
          backgroundColor: "blue",
          borderRadius: "100px",
          margin: "0 15px",
          padding: 5,
        }}>{title}</h3>
      </Grid>
    ) : (
      <Grid item xs={12} md={4}>
        <p style={{
          fontSize: 25,
        }}> - </p>
        <h3 style={{ 
          color: "white",
          backgroundColor: "blue",
          borderRadius: "100px",
          margin: "0 15px",
          padding: 5,
        }}>{title}</h3>
      </Grid>
    );
  }

  const renderInventoryDisplay = (quantity: number, title: string) => {
    return (
      <Grid item xs={12} md={"auto"}>
        <p style={{
          fontSize: 25,
        }}>{quantity ?? 0}</p>
        <h3 style={{
          color: "white",
          backgroundColor: "blue",
          borderRadius: "100px",
          margin: "0 15px",
          padding: "5px 15px",
        }}>{title}</h3>
      </Grid>
    );
  }

  return (
    <>
      <Grid item md={6}>
        <h2>Main Features</h2>
        <div dangerouslySetInnerHTML={{__html: `${mainFeatures}`}}>

        </div>
      </Grid>
      <Grid item md={6}>
        {imageUrl && (
          <div style={{
            backgroundColor: "#f1f2f3",
            padding: 15,
            borderRadius: "50%",
          }}>
            <img src={imageUrl} alt={product.name} style={{
              maxWidth: "100%"
            }}/>
          </div>
        )}
      </Grid>

      <Grid item xs={12}>
        <h2>Pricing</h2>
        {
          product.productLaunchDate && product.productLaunchDate > sixMonthsAgo ? 
          <h3 style={{}}><FiberNew color={"primary"} style={{marginTop: 12, marginRight: 12, fontSize: 40, marginBottom: -12}} /> <span
          style={{
            padding: ".25em 1em",
            background: "#d4e7d6",
            borderRadius: 50,
            fontWeight: 700,
            marginBottom: 12,
            color: "#3e8e46"
          }}
          >- This is a new product, and you will receive 10% off. (Calculated in PO)</span></h3> : null
        }
      </Grid>
      <Grid item container xs={12} style={{ textAlign: "center" }}>
        {renderPriceDisplay(product.price, "Reseller")}
        {renderPriceDisplay(product.mapPrice, "MAP")}
        {renderPriceDisplay(product.msrpPrice, "MSRP")}
      </Grid>

      {product.warranty && (
        <Grid item xs={12} md={6}>
          <h2>Warranty</h2>
          <p>{product.warranty}</p>
        </Grid>
      )}

      {(product.sku || product.upc) && (
        <Grid item xs={12} md={6}>
          <h2>Identification</h2>
          {product.sku && (<p>SKU - {product.sku}</p>)}
          {product.upc && (<p>UPC - {product.upc}</p>)}
        </Grid>
      )}

      {writeAccess && (
        <>
          <Grid item xs={12}>
            <h2>Inventory</h2>
          </Grid>
          <Grid item container xs={12} style={{ textAlign: "center" }} justifyContent={"space-between"}>
            {renderInventoryDisplay(product.inventory, "Inventory")}
            {renderInventoryDisplay(product.bstock, "B-Stock")}
            {renderInventoryDisplay(product.inventoryNotAvailable, "Not Available")}
            {renderInventoryDisplay(product.inventoryAllocated, "Allocated")}
            {renderInventoryDisplay(product.inventoryOnOrder, "On Order")}
          </Grid>
        </>
      )}
    </>
  )
}

export default SingleProductGeneral;