import { Grid, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { OrderInterface } from "interfaces/Order";
import { useNavigate } from "react-router-dom";

interface PendingOrderResultProps {
  order: OrderInterface,
  phoneMode: boolean
}
const PendingOrderResult = ( {order, phoneMode}: PendingOrderResultProps ) => {
  let navigate = useNavigate();
  const renderStatus = (status) => {
    if (status === "Submitted") {
      return "status highlight blue";
    } else if (status === "Accepted") {
      return "status highlight green";
    } else if (status === "Canceled") {
      return "status highlight red";
    } else if (status === "Rep Responded") {
      return "status highlight purple";
    } else {
      return "status highlight subtle";
    }
  };

  return (
    <Paper key={order.id} elevation={2} className="grid-search-result line-item" onClick={() => navigate(`/pending-orders/${order.id}`)}>
      <Grid container item xs={12} className="center-text" alignItems="center">
        <Grid item xs={1} className="result-name">
          <Typography noWrap variant="body2">
            {order.customerPONum ? order.customerPONum : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {order.createdAt ? order.createdAt.toLocaleString("en-US", {dateStyle: "short"}) : ""}
        </Grid>
        <Grid item xs={4} className="result-name">
          <Typography noWrap>
            {order.company.name}
          </Typography>
        </Grid>
        <Grid item md={2} xs={4}>
          <div className={order.orderStatus ? renderStatus(order.orderStatus.name) : ""}>
              {order.orderStatus ? order.orderStatus.name : ""}
          </div>
        </Grid>
        {!phoneMode && <Grid item xs={2}>
          <div className="add-to-po">
            <p className="hide-mobile" style={{color: "black", margin: 0}}>View</p>
            <i className="fas fa-chevron-right"></i>
          </div>
        </Grid>}
      </Grid>
    </Paper>
  );
}

export default PendingOrderResult;