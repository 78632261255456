import React from "react";
import { CompanyInterface } from "interfaces/Company";
import { Button, Grid } from "@material-ui/core";
import LocationForm from "./LocationForm";
import LocationCard from "./LocationCard";
import Loading from "components/utils/Loading";
import { makeAxiosCall } from "utils";
import { buildLocations, LocationInterface } from "interfaces/Location";

interface CompanyLocationsProps {
    company: CompanyInterface
}

const CompanyLocations = ({ company }: CompanyLocationsProps) => {
    const [locations, setLocations] = React.useState<LocationInterface[]>();
    const [loading, setLoading] = React.useState(true);
    const [newForm, setNewForm] = React.useState(false);

    let isMounted = true;

    React.useEffect(() => {
        if (company) {
            fetchLocations();
        }
        return () => {
            isMounted = false;
        };
    }, [company]);


    const fetchLocations = async () => {
        const response = await makeAxiosCall(
            "get",
            `reseller-locations/${company.id}`
        ).catch(error => {
            console.error("Caught error getting company locations:");
            console.error(error);
        });

        if (response.data) {
            const navigation = buildLocations(company.addresses, response.data);
            if (isMounted) {
                setLocations(navigation);
                setLoading(false);
            }
        }
    };

    const addLocation = (location: LocationInterface, newId: number) => {
        setLocations([...locations, { ...location, id: newId }]);
        setNewForm(false);
    };

    const updateLocation = (navigation: LocationInterface, id: number) => {
        const unchanged = locations.filter((location) => location.id !== id);
        setLocations([...unchanged, { ...navigation, id: id }]);
        setNewForm(false);
    };

    const deleteLocation = (locationId: number) => {
        setLocations(locations.filter((location) => location.id !== locationId));
        setNewForm(false);
    };

    const renderBody = () => {
        if (loading) {
            return <Loading height="40vh" title="" position="relative" />;
        } else {
            if (locations && locations.length > 0) {
                return locations.map(location => {
                    return <LocationCard key={location.id} companyId={company.id} location={location} updateCallback={updateLocation} deleteCallback={deleteLocation} />;
                });
            } else {
                return <p className="body-message">No Locations Found</p>;
            }
        }
    };

    return company ? (
        <>
            <Grid container alignItems="center" >
                <Grid item xs={12} sm={9}>
                    <h2>Locations</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setNewForm(!newForm);
                        }}
                    >
                        {newForm ? "Cancel" : "New Location"}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{ fontWeight: "lighter" }}>These locations will be shown <a style={{ color: "#282bf6" }} target="_blank" href="https://www.blizzardpro.com/a/wheretobuy" rel="noreferrer">HERE</a> on Blizzard Lighting&apos;s website of available resellers</h3>
                </Grid>

                {newForm ?
                    <Grid container spacing={1} className="new-form">
                        <h3>New Location</h3>
                        <LocationForm
                            companyId={company.id}
                            addCallback={(newLocation) => {
                                addLocation(newLocation, newLocation.id);
                            }}
                        />
                    </Grid>
                    : <Grid item xs={12}>{renderBody()}</Grid>}
            </Grid>
        </>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default CompanyLocations;