import React from "react";
import _ from "lodash";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Snackbar, TextField, Tooltip } from "@material-ui/core";
import { ContactInterface, createEmptyContact } from "interfaces/Contact";
import { makeAxiosCall } from "utils";
import { UserContext } from "context/User";
import { Alert } from "@material-ui/lab";

interface ContactEditFormProps {
    companyId: number
    contact?: ContactInterface,
    createCallback?: (contactData: ContactInterface, newId: number) => void,
    updateCallback?: (contactData: ContactInterface, id: number) => void,
    deleteCallback?: (contactId: number) => void,
}

const ContactEditForm = ({ companyId, contact, createCallback, updateCallback, deleteCallback }: ContactEditFormProps) => {

    const { currentUser } = React.useContext(UserContext);

    const initialFormData = createEmptyContact();

    const [form, setForm] = React.useState<ContactInterface>(initialFormData);
    const [validationError, setValidationError] = React.useState(false);
    const [saveError, setSaveError] = React.useState<string>("");
    const [currentProcess, setCurrentProcess] = React.useState<string>();
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });

    React.useEffect(() => {
        // Turn any null values into empty strings for the form state object
        if (contact) {
            const formData = _.mapValues(contact, (key) => key ? key : "");
            setForm(formData);
        }
    }, [contact]);

    const handleFormChange = (name: string) => (e) => {
        setValidationError(false);
        setForm({
            ...form,
            [name]: e.target.value,
        });
    };

    const handleType = (typeId: number, typeName: string) => (e) => {
        if (form.contactTypes.some(type => type.id === typeId)) {
            setForm({ ...form, contactTypes: _.filter(form.contactTypes, (type) => type.id !== typeId) });
        } else {
            setForm({ ...form, contactTypes: [...form.contactTypes, { id: typeId, name: typeName }] });
        }
    };

    const handleError = (error: any) => {
        console.log("Caught error mutating contact:");
        console.log(error);
        setSaveError("Something went wrong. Please try again.");
        setCurrentProcess("");
    };

    const validateContactTypes = (deleting: boolean) => {
        console.log(contact);
        console.log(form);
        
        
        if(contact && currentUser && currentUser.company && currentUser.company.contacts) {
            // if this is an update, make sure they're not removing types that are required... somehow
            if(contact.contactTypes != form.contactTypes || deleting) {
                // changing types
                let hasPrimary = false;
                let hasBilling = false;
                let hasMarketing = false;
                let hasPurchasing = false;
                let hasSales = false;
                for (let ci = 0; ci < currentUser.company.contacts.length; ci++) {
                    const existingContact = currentUser.company.contacts[ci];
                    if(existingContact.id != contact.id) {
                        for (let cti = 0; cti < existingContact.contactTypes.length; cti++) {
                            const contactType = existingContact.contactTypes[cti];
                            if(contactType.id == 1) {
                                hasPrimary = true;
                            } else if(contactType.id == 2) {
                                hasBilling = true;
                            } else if(contactType.id == 3) {
                                hasMarketing = true;
                            } else if(contactType.id == 4) {
                                hasPurchasing = true;
                            } else if(contactType.id == 5) {
                                hasSales = true;
                            }
                        }
                    }
                }

                if(!hasPrimary && contact.contactTypes.find((at) => at.id == 1) && (deleting || !form.contactTypes.find((ct) => ct.id == 1))) {
                    setSnackbar({
                        isOpen: true,
                        message: "You must have at least one primary contact. Contacts can have multiple types.",
                        severity: "error",
                    });
                    return false;
                }

                if(!hasBilling && contact.contactTypes.find((at) => at.id == 2) && (deleting || !form.contactTypes.find((ct) => ct.id == 2))) {
                    setSnackbar({
                        isOpen: true,
                        message: "You must have at least one billing contact. Contacts can have multiple types.",
                        severity: "error",
                    });
                    return false;
                }

                if(!hasMarketing && contact.contactTypes.find((at) => at.id == 3) && (deleting || !form.contactTypes.find((ct) => ct.id == 3))) {
                    setSnackbar({
                        isOpen: true,
                        message: "You must have at least one marketing contact. Contacts can have multiple types.",
                        severity: "error",
                    });
                    return false;
                }

                if(!hasPurchasing && contact.contactTypes.find((at) => at.id == 4) && (deleting || !form.contactTypes.find((ct) => ct.id == 4))) {
                    setSnackbar({
                        isOpen: true,
                        message: "You must have at least one purchasing contact. Contacts can have multiple types.",
                        severity: "error",
                    });
                    return false;
                }

                if(!hasSales && contact.contactTypes.find((at) => at.id == 5) && (deleting || !form.contactTypes.find((ct) => ct.id == 5))) {
                    setSnackbar({
                        isOpen: true,
                        message: "You must have at least one sales contact. Contacts can have multiple types.",
                        severity: "error",
                    });
                    return false;
                }

                return true;
            } else {
                return true;
            }
        } else {
            return true;
        } 
    };
    
    const submitForm = async () => {
        setCurrentProcess("save");
        setSaveError("");

        const submitData = {
            firstName: form.firstName.trim(),
            lastName: form.lastName.trim(),
            email: form.email.trim(),
            phoneNumber: form.phoneNumber.trim(),
            title: form.title.trim(),
            contactTypes: form.contactTypes,
            companyId: companyId,
        };

        if (contact) {
            // Update existing contact
            const response = await makeAxiosCall(
                "post",
                `contact/${contact.id}`,
                submitData,
                { headers: { "Content-Type": "application/json" } },
            ).catch(error => {
                handleError(error);
            });

            if (response && response.data.id) {
                if (updateCallback) updateCallback(form, response.data.id);
            } else if (response) {
                handleError(response);
            }
        } else {
            // Create new contact
            const response = await makeAxiosCall(
                "post",
                "contact",
                submitData,
                { headers: { "Content-Type": "application/json" } },
            ).catch(error => {
                handleError(error);
            });

            if (response && response.data.id) {
                if (createCallback) createCallback(form, response.data.id);
            } else if (response) {
                handleError(response);
            }
        }
    };

    const deleteContact = async () => {
        if(validateContactTypes(true)) {
            setCurrentProcess("delete");

            const response = await makeAxiosCall(
                "delete",
                `contact/${contact.id}`,
            ).catch(error => {
                handleError(error);
            });

            if (response && response.data.id) {
                if (deleteCallback) deleteCallback(response.data.id);
            } else if (response) {
                handleError(response);
            }
        }
    };

    return (
        <>
                <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        style={{ width: "100%" }}
                        label="First Name"
                        margin="normal"
                        value={form.firstName}
                        variant="outlined"
                        onChange={handleFormChange("firstName")}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        style={{ width: "100%" }}
                        label="Last Name"
                        margin="normal"
                        value={form.lastName}
                        variant="outlined"
                        onChange={handleFormChange("lastName")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        style={{ width: "100%" }}
                        label="Title"
                        margin="normal"
                        value={form.title}
                        variant="outlined"
                        onChange={handleFormChange("title")}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        style={{ width: "100%" }}
                        label="Email"
                        margin="normal"
                        value={form.email}
                        variant="outlined"
                        onChange={handleFormChange("email")}
                        error={validationError}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        style={{ width: "100%" }}
                        label="Phone Number"
                        margin="normal"
                        value={form.phoneNumber}
                        variant="outlined"
                        onChange={handleFormChange("phoneNumber")}
                        error={validationError}
                        helperText={validationError ? "Email address or phone number is required" : ""}
                    />
                </Grid>
                <Grid item xs={12} style={{minHeight: 100}}>
                    <FormControl fullWidth >
                        <FormLabel>Contact Type:</FormLabel>
                        <FormGroup row className="contact-type-flex">
                            <FormControlLabel control={<Checkbox checked={form.contactTypes.some(type => type.id === 1)} onChange={handleType(1, "Primary")} />} label="Primary" />
                            <Tooltip title={!currentUser.accountLevel.canEditPaymentMethods ? "Only Admins can edit billing contacts" : ""}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form.contactTypes.some(type => type.id === 2)}
                                            onChange={handleType(2, "Billing")}
                                        />
                                    }
                                    label="Billing"
                                    disabled={!currentUser.accountLevel.canEditPaymentMethods}
                                />
                            </Tooltip>
                            <FormControlLabel control={<Checkbox checked={form.contactTypes.some(type => type.id === 3)} onChange={handleType(3, "Marketing")} />} label="Marketing" />
                            <FormControlLabel control={<Checkbox checked={form.contactTypes.some(type => type.id === 4)} onChange={handleType(4, "Purchasing")} />} label="Purchasing" />
                            <FormControlLabel control={<Checkbox checked={form.contactTypes.some(type => type.id === 5)} onChange={handleType(5, "Sales")} />} label="Sales" />
                            <FormControlLabel control={<Checkbox checked={form.contactTypes.some(type => type.id === 6)} onChange={handleType(6, "Other")} />} label="Other" />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item container justifyContent="center" spacing={2} xs={12}>
                    {saveError.length > 0 && (
                        <Grid item xs={12} className="save-error">
                            {saveError}
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            type="submit"
                            style={{ width: "100%", color: "white" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (
                                    (!form.email || form.email.trim().length === 0) &&
                                    (!form.phoneNumber || form.phoneNumber.trim().length === 0)) {
                                    setValidationError(true);
                                } else if(!validateContactTypes(false)) {
                                    console.log("hello");
                                } else {
                                    submitForm();
                                }
                            }}
                            className="btn blue"
                        >
                            {currentProcess === "save" ?
                                <CircularProgress
                                    color="inherit"
                                    size={24}
                                /> :
                                "Save"
                            }
                        </Button>
                    </Grid>
                    {contact && contact.id && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={deleteContact}
                                className="btn red"
                            >
                                {currentProcess === "delete" ?
                                    <CircularProgress
                                        color="inherit"
                                        size={24}
                                    /> :
                                    "Delete"
                                }
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={5000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ContactEditForm;