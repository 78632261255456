import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

export default class HeightInput extends Component {
  render() {
    const { config } = this.props;
    return (
      <TextField
        id="height"
        label="Height"
        margin="normal"
        value={config.heightSelected || ""}
        onChange={this.props.handleInputChange(
          "heightSelected",
          this.props.dimSelect
        )}
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment variant="filled" position="end">
              {config.format}
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
