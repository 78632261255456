import React from "react";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Tooltip, Snackbar } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { UserContext } from "context/User";
import { makeAxiosCall } from "utils";
import { buildUser, UserInterface } from "interfaces/User";
import { Alert } from "@material-ui/lab";

interface ApprovalExpirationPopupProps {
    popupOpen: boolean,
    onClose: () => void,
}

const ApprovalExpirationPopup = ({ popupOpen, onClose }: ApprovalExpirationPopupProps) => {

    const { currentUser, accountLevels } = React.useContext(UserContext);
    const [errorBar, setErrorBar] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });
    const navigate = useNavigate();

    const adminUser = React.useMemo(() => {
        if (currentUser && accountLevels) {
            return currentUser.accountLevel.canManageMembers;
        } else {
            return false;
        }
    }, [currentUser]);

    const expirationDate: string = React.useMemo(() => {
        if (currentUser && currentUser.company && currentUser.company.approvalStatus && currentUser.company.approvalStatus.expirationDate) {
            return new Date (currentUser.company.approvalStatus.expirationDate).toLocaleDateString();
        } else {
            return "";
        }
    }, [currentUser]);

    const goToWizard = () => {
        window.scrollTo(0, 0);
        navigate("/approval");
    };

    const handleCloseAttempt = (event, reason) => {
        if (reason) return;
        onClose();
    };

    const emailAdmins = async () => {
        // Get admin users
        const companyData = await makeAxiosCall(
            "get",
            "company/" + currentUser.company.id
        );

        const members: UserInterface[] = await Promise.all(_.map(
            companyData.data.members,
            (member) => {
                return buildUser(member);
            }
        ));

        console.log(members);

        const adminLevelId = accountLevels.find((level) => level.name == "Admin").id;
        const admins = members.filter((member) => member.accountLevel.id == adminLevelId);
        const adminData = admins.map((admin) => {
            return {
                id: admin.uid,
                email: admin.email,
                companyId: currentUser.company.id
            };
        });

        // Send email
        if (admins.length > 0) {
            const response = await makeAxiosCall(
                "post",
                "email/expiration-reminder",
                {
                    admins: adminData,
                    userName: currentUser.displayName,
                    expirationDate: currentUser.company.approvalStatus.expirationDate.toLocaleDateString(),
                    expirationReason: currentUser.company.approvalStatus.expirationReason,
                }
            );

            if (response.status == 200) {
                setSnackbar({
                    isOpen: true,
                    message: "Your company admins have been notified.",
                    severity: "success"
                });

                onClose();
            } else {
                setErrorBar(true);
            }
        } else {
            setErrorBar(true);
        }
    };

    const dialogContent = React.useMemo(() => {
        return adminUser ? (
            <DialogContentText>
                {`One or more of your documents needs an update, please 
                complete the following before ${expirationDate} in order to 
                keep using the portal. After ${expirationDate} your account 
                will be locked and you will no longer have access.`}
                <br /><br />
                Reason: {currentUser.company.approvalStatus.expirationReason}
            </DialogContentText>
        ) : (
            <DialogContentText>
                {`One or more of your documents needs an update. Please have a
                company admin complete the following before ${expirationDate} in
                order to keep using the portal. After ${expirationDate} your 
                company will no longer have access.`}
                <br />
                Reason: {currentUser.company.approvalStatus.expirationReason}
            </DialogContentText>
        );
    }, [currentUser]);

    const dialogActions = React.useMemo(() => {
        return adminUser ? (
            <Grid container justifyContent="space-between">
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "tomato" }}
                >
                    I understand I will lose access
                </Button>
                <Button
                    onClick={goToWizard}
                    variant="contained"
                    color="primary"
                >
                    Complete Requirements
                </Button>
            </Grid>
        ) : (
            <Grid container justifyContent="space-between">
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "tomato" }}
                >
                    I understand I will lose access
                </Button>
                <Tooltip
                    title="This will send an email to the admin(s) of your company reminding them to complete the necessary documents."
                >
                    <Button
                        onClick={emailAdmins}
                        variant="contained"
                        color="primary"
                    >
                        Email my Admin
                    </Button>
                </Tooltip>
            </Grid>
        );
    }, [currentUser]);

    return (
        <>
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={3000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={popupOpen}
                onClose={handleCloseAttempt}
            >
                <Snackbar
                    open={errorBar}
                    autoHideDuration={3000}
                    onClose={(_, reason) => {
                        if (reason === "clickaway") {
                            return;
                        }

                        setErrorBar(false);
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert
                        severity={"error"}
                    >
                        Something went wrong. Please try again.
                    </Alert>
                </Snackbar>
                <DialogTitle>
                    Your Portal Access is Expiring
                </DialogTitle>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
                <DialogActions>
                    {dialogActions}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ApprovalExpirationPopup;