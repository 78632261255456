import React from "react";
import _ from "lodash";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@material-ui/core";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import { CartContext } from "context/Cart";
import { OrderInterface, OrderStatusInterface } from "interfaces/Order";
import PortalOrderSearchResult from "./PortalOrderSearchResult";
import { useWindowWidth } from "hooks/useWindowSize";

/*
    id: number,
    createdAt: Date,
    updatedAt: Date,
    company: CompanyInterface,
    orderedBy: {id: string, displayName: string},
    fishbowlSONum?: string,
    orderStatus: OrderStatusInterface,
    billingAddress: AddressInterface,
    shippingAddress: AddressInterface,
    customerPOURL?: string,
    notes?: string,
    authorizePaymentProfileId?: string,
    lastUpdatedBy: {id: string, displayName: string},
    isDropShip: boolean,
    preferredPackingListURL?: string,
    shippingPreference: ShippingPreferenceInterface,
    shippingAccountNumber?: string,
    orderItems: Array<OrderItemInterface>
*/

interface PortalOrderSearchProps {
    allOrders: Array<OrderInterface>
}

const PortalOrderSearch = ({ allOrders }: PortalOrderSearchProps) => {
    const { orderStatusOptions } = React.useContext(CartContext);
    const [input, setInput] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [orderStatusFilters, setOrderStatusFilters] = React.useState<string[]>([]);
    const [filteredList, setFilteredList] = React.useState<OrderInterface[]>([]);
    const [sortMethods, setSortMethods] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);
    const windowWidth = useWindowWidth();

    const windowScrollerRef = React.useRef<WindowScroller>();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 800,
                width: 250,
            },
        },
    };

    const sortOptions = [
        {
            label: "Portal ID (A - Z)",
            value: "id",
            order: "asc",
        },
        {
            label: "Portal ID (Z - A)",
            value: "id",
            order: "desc",
        },
        {
            label: "SO Num (A - Z)",
            value: "fishbowlSONum",
            order: "asc",
        },
        {
            label: "SO Num (Z - A)",
            value: "fishbowlSONum",
            order: "desc",
        },
        {
            label: "Newest",
            value: "createdAt",
            order: "desc",
        },
        {
            label: "Oldest",
            value: "createdAt",
            order: "asc",
        },
        {
            label: "Company (A - Z)",
            value: "company.name",
            order: "asc",
        },
        {
            label: "Company (Z - A)",
            value: "company.name",
            order: "desc",
        },
    ];

    React.useEffect(() => {
        console.log(allOrders);

        if (allOrders) {
            filterOrders();
            setLoading(false);
        }
    }, [allOrders, orderStatusFilters]);

    React.useLayoutEffect(() => {
        // Adjust the scroll view when filters are opened or closed
        if (windowScrollerRef && windowScrollerRef.current) {
            windowScrollerRef.current.updatePosition();
        }
    }, [showFilters]);

    const filterOrders = () => {
        // Only apply filters once one is selected
        const applyFilters = orderStatusFilters.length > 0;

        if (applyFilters) {
            const orderStatusResults = [];
            let results = [];

            allOrders.forEach((order) => {
                if (orderStatusFilters.some((status) => status == order.orderStatus.name)) {
                    orderStatusResults.push(order);
                }
            });
            results = orderStatusResults;

            setFilteredList(results);
        } else {
            setFilteredList(allOrders);
        }
    };

    const sortOrders = (searchResults: OrderInterface[]) => {
        let returnable: OrderInterface[];

        if (sortMethods.length > 0) {
            returnable = _.orderBy(
                searchResults,
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).value),
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
            );
        } else {
            returnable = _.orderBy(searchResults, (order: OrderInterface) => order.createdAt, ["desc"]);
        }

        return returnable;
    };

    const renderOrderStatusFilterOption = (option: OrderStatusInterface) => {
        return (
            <Grid key={option.id} item xs={12} md={4}>
                <Tooltip title={option.name} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={orderStatusFilters.some((status) => status === option.name)}
                                onChange={(e) => {
                                    if (orderStatusFilters.some((type) => type === option.name)) {
                                        setOrderStatusFilters(orderStatusFilters.filter((type) => type !== option.name));
                                    } else {
                                        setOrderStatusFilters([...orderStatusFilters, option.name]);
                                    }
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={option.name}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderOrderStatusFilters = () => {
        return orderStatusOptions.map((type) => {
            return renderOrderStatusFilterOption(type);
        });
    };

    const renderSortOptions = () => {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    key="sortMethods"
                    multiple
                    value={sortMethods}
                    renderValue={(_) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {sortMethods.map((method) => (
                                <Chip key={method} label={method} style={{ marginRight: 5 }} />
                            ))}
                        </Box>
                    )}
                    onChange={(e: React.ChangeEvent<{
                        name?: string;
                        value: string[];
                    }>) => {
                        setSortMethods(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    {sortOptions.map(
                        (option) => {
                            const index = sortMethods.findIndex((n) => n == option.label);
                            return (
                                <MenuItem
                                    key={option.label}
                                    value={option.label}
                                >
                                    <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </FormControl>
        );
    };

    const searchResults = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        const hits = _.filter(filteredList, (order: OrderInterface) => {
            return _.includes(order.company.name.toLowerCase(), searchInput) || (order.fishbowlSONum && _.includes(order.fishbowlSONum.toLowerCase(), searchInput));
        });

        return sortOrders(hits);
    }, [filteredList, input, sortMethods]);

    const renderRow = ({ index, key, style }) => {
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <PortalOrderSearchResult order={searchResults[index]} mobileMode={windowWidth < 700} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return <p className="body-message center-text">No Orders Found</p>;
    };

    return allOrders ? (
        <div className="grid-search">
            <h3 className="title">Portal Orders</h3>
            <div className="search-field">
                <TextField
                    label="Order Search"
                    variant="outlined"
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                />
            </div>
            <Grid container className="container-spacing">
                <Grid item container alignItems="center" xs={12}>
                    <Grid item xs={9}><h3>Filter and Sort {`(${filteredList ? filteredList.length : ""})`}</h3></Grid>
                    <Grid item xs={3}>
                        <Button
                            variant={showFilters ? "contained" : "outlined"}
                            color={showFilters ? "primary" : "secondary"}
                            onClick={() => setShowFilters(!showFilters)}
                            fullWidth
                        >
                            {showFilters ? "Hide Filters" : "Show Filters"}
                        </Button>
                    </Grid>
                </Grid>
                {showFilters && (
                    <>
                        <Grid item container xs={9}>
                            <Grid item container alignContent="flex-start" xs={12}>
                                <Grid item xs={12}>Order Status</Grid>
                                {renderOrderStatusFilters()}
                            </Grid>
                            {/* <Grid item container alignContent="flex-start" xs={6}>
                <Grid item xs={12}>Different Filter</Grid>
                <p>product?</p>
              </Grid> */}
                        </Grid>
                        <Grid item alignContent="flex-start" container xs={3}>
                            <Grid item xs={12}>Sort By</Grid>
                            <Grid item xs={12}>
                                {renderSortOptions()}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container className="results">
                {windowWidth > 700 && <Grid container item xs={12} className="center-text grid-search-headers">
                    <Grid item container xs={4}>
                        <Grid xs={5} item className="result-name">
                            PO
                        </Grid>
                        <Grid item xs={5}>
                            SO
                        </Grid>
                        <Grid item xs={2}>
                            Date
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        Company
                    </Grid>
                    <Grid item xs={2}>
                        Items
                    </Grid>
                    <Grid item xs={2}>
                        Status
                    </Grid>
                    <Grid item xs={1}>
                        View
                    </Grid>
                </Grid>}
                {loading ?
                    (<Loading height="40vh" title="Connecting to Inventory System" position="relative" />) :
                    (<WindowScroller ref={windowScrollerRef}>
                        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                            return <List
                                autoHeight
                                width={width}
                                height={height}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowHeight={70}
                                rowRenderer={renderRow}
                                noRowsRenderer={renderPlaceholder}
                                rowCount={searchResults.length}
                                overscanRowCount={3}
                            />;
                        }}
                    </WindowScroller>)
                }
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default PortalOrderSearch;
