export const ProductWarrantyTypes = [
    "None",
    "2 Year - Extendable",
    "2 Year",
    "1 Year",
    "90 Day",
    "6 Month",
    "Lifetime",
    "7 Year",
    "3 Year"
];

export const ProductWarrantyTypeObjects = [
    {name: "None", id: 1},
    {name: "2 Year - Extendable", id: 2},
    {name: "2 Year", id: 3},
    {name: "1 Year", id: 4},
    {name: "90 Day", id: 5},
    {name: "6 Month", id: 6},
    {name: "Lifetime", id: 7},
    {name: "7 Year", id: 8},
    {name: "3 Year", id: 9}
];

export const getWarrantyTypeIdFromName = (name: string): number => {
    const matchingWarrantyType = ProductWarrantyTypeObjects.find((w) => w.name == name);

    if(matchingWarrantyType) return matchingWarrantyType.id;
    else return 1;
}