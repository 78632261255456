import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import TagFacesIcon from "@material-ui/icons/TagFaces";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function ChipArray(props) {
  const { chipData, setChipData, callback } = props;
  const classes = useStyles();

  const handleDelete = (chipToDelete) => () => {
    if (typeof chipToDelete === "string") {
      const newArray = chipData.filter((c) => {
        return c !== chipToDelete;
      });
      setChipData(newArray);
      if (callback) {
        callback(chipToDelete);
      }
    } else {
      setChipData((chips) =>
        chips.filter((chip) => chip.id !== chipToDelete.id)
      );
    }
  };

  return (
    <div className="chip-array">
      {chipData.map((data) => {
        let icon;

        if (data.label === "React") {
          icon = <TagFacesIcon />;
        }

        return (
          <li key={data.id ?? data}>
            <Chip
              icon={icon}
              label={data.label ? data.label : data}
              onDelete={data.label === "React" ? undefined : handleDelete(data)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </div>
  );
}
