import React from "react";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Tooltip, Typography, useTheme, Zoom } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { ApprovalCardTypes } from "./CompanyApproval";
import { storageRef } from "base.js";
import ModalBasic from "components/utils/ModalBasic";
import ResellerInfo from "components/Account/Wizard/ResellerInfo";
import Upload, { UploadType } from "components/utils/Upload";
import { DateRange, Delete, DeleteForever, FileCopy, GetApp } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";
import { logFileDownload, makeAxiosCall } from "utils";
import { Moment } from "moment";

const enum ApprovalCardProcesses {
    approving,
    denying,
    resetting,
    gettingFiles,
    saving,
    none,
}

interface CompanyApprovalCardProps {
    title: "Application" | "Info Form" | "Reseller Agreement" | "Certificate",
    complete: boolean,
    approved: boolean,
    denialReason: string,
    lastUpdated: string,
    update: (updateData: object) => Promise<boolean>,
    refresh?: Function,
    cardType: ApprovalCardTypes,
    infoFormId?: number,
    companyId?: number,
    approvalId?: number,
    canEdit: boolean,
    sendDenyEmail: (denialReason: string) => void,
    certExpiration?: Date,
    certExpires?: boolean,
}

const CompanyApprovalCard = ({ title, complete, approved, denialReason, lastUpdated, update, cardType, infoFormId, companyId, approvalId, canEdit, sendDenyEmail, certExpiration, certExpires, refresh }: CompanyApprovalCardProps) => {

    const [editing, setEditing] = React.useState(false);
    const [formValue, setFormValue] = React.useState("");
    const [formError, setFormError] = React.useState(false);
    const [certExpirationDate, setCertExpirationDate] = React.useState<string>(certExpiration ? certExpiration.toISOString() : null);
    const [certNeverExpires, setCertNeverExpires] = React.useState(certExpires);
    const [currentProcess, setCurrentProcess] = React.useState(ApprovalCardProcesses.none);
    const [approvalFiles, setApprovalFiles] = React.useState<{ url: string, name: string, time?: string }[]>();
    const [selectedMedia, setSelectedMedia] = React.useState<{ url: string, name: string, time?: string }[]>([]);
    const [fileSearchDone, setFileSearchDone] = React.useState(false);
    const [openInfoForm, setOpenInfoForm] = React.useState(false);
    const [openDeleteDocument, setOpenDeleteDocument] = React.useState(false);
    const [documentToDelete, setDocumentToDelete] = React.useState(null);
    const [uploaderOpen, setUploaderOpen] = React.useState(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const approveItem = async () => {
        setCurrentProcess(ApprovalCardProcesses.approving);
        let updateData;
        switch (cardType) {
            case ApprovalCardTypes.application:
                updateData = { applicationApproved: true, applicationDenialReason: "" };
                break;
            case ApprovalCardTypes.infoForm:
                updateData = { infoFormApproved: true, infoFormDenialReason: "" };
                break;
            case ApprovalCardTypes.agreement:
                updateData = { agreementApproved: true, agreementDenialReason: "" };
                break;
            case ApprovalCardTypes.certificate:
                updateData = { certificateApproved: true, certificateDenialReason: "", certExpirationDate: certExpirationDate, certNeverExpires: certNeverExpires };
                break;
        }

        await update(updateData);
        setCurrentProcess(ApprovalCardProcesses.none);
    };

    const denyItem = async () => {
        setCurrentProcess(ApprovalCardProcesses.denying);
        let updateData;
        switch (cardType) {
            case ApprovalCardTypes.application:
                updateData = { applicationDenialReason: formValue.trim(), applicationComplete: false, applicationApproved: false };
                break;
            case ApprovalCardTypes.infoForm:
                updateData = { infoFormDenialReason: formValue.trim(), infoFormComplete: false, infoFormApproved: false };
                break;
            case ApprovalCardTypes.agreement:
                updateData = { agreementDenialReason: formValue.trim(), agreementComplete: false, agreementApproved: false };
                break;
            case ApprovalCardTypes.certificate:
                updateData = { certificateDenialReason: formValue.trim(), certificateUploaded: false, certificateApproved: false };
                break;
        }

        const response = await update(updateData);
        if (response) {
            sendDenyEmail(formValue.trim());
        } else {
            setCurrentProcess(ApprovalCardProcesses.none);
        }

        setEditing(false);
    };

    const resetItem = async () => {
        setCurrentProcess(ApprovalCardProcesses.resetting);
        let updateData;
        switch (cardType) {
            case ApprovalCardTypes.application:
                updateData = { applicationComplete: false, applicationApproved: false, applicationDenialReason: "" };
                break;
            case ApprovalCardTypes.infoForm:
                updateData = { infoFormComplete: false, infoFormApproved: false, infoFormDenialReason: "" };
                break;
            case ApprovalCardTypes.agreement:
                updateData = { agreementComplete: false, agreementApproved: false, agreementDenialReason: "" };
                break;
            case ApprovalCardTypes.certificate:
                updateData = { certificateUploaded: false, certificateApproved: false, certificateDenialReason: "" };
                break;
        }

        await update(updateData);
        setCurrentProcess(ApprovalCardProcesses.none);
    };

    const saveExpiration = async () => {
        setCurrentProcess(ApprovalCardProcesses.saving);
        await update({ certExpirationDate: certExpirationDate, certNeverExpires: certNeverExpires });
        setCurrentProcess(ApprovalCardProcesses.none);
    }

    const openDeleteDocumentModal = (doc) => {
        setDocumentToDelete(doc);
        setOpenDeleteDocument(true);
    }

    const deleteDocument = async () => {
        if(!companyId || !documentToDelete) {
            console.log("!companyId || !documentToDelete");
            return;
        }
        let bucketUrl = '';
        switch (cardType) {
            case ApprovalCardTypes.agreement:
                bucketUrl = `/portal_company_documents/${companyId}/reseller_agreement`;
                break;
            case ApprovalCardTypes.certificate:
                bucketUrl = `/portal_company_documents/${companyId}/reseller_certificates`
                break;
            default: return;
        }

        const documentStorageRef = storageRef.child(bucketUrl);

        const files = await documentStorageRef.listAll();

        for (let fi = 0; fi < files.items.length; fi++) {
            const file = files.items[fi];
            if(file.name == documentToDelete.name) {
                return file.delete().then(() => {
                    let newFileList = [];
                    for (let pafi = 0; pafi < approvalFiles.length; pafi++) {
                        const af = approvalFiles[pafi];
                        if(af && af.name != documentToDelete.name) {
                            newFileList.push(af);
                        }
                    }
                    setApprovalFiles(newFileList);
                    closeDeleteDocumentModal();
                    return;
                }).catch((e) => {
                    console.log(e);
                    console.log("failed to delete doc"); 
                });
            }
        }
    }

    const closeDeleteDocumentModal = () => {
        setOpenDeleteDocument(false);
        setDocumentToDelete(null);
    }

    const closeUploader = () => {
        if(refresh) refresh();
        setUploaderOpen(false);
    }

    const status = React.useMemo(() => {
        if (denialReason && denialReason.length > 0) {
            return "Denied";
        } else if (approved) {
            return "Approved";
        } else if (complete) {
            return "Pending";
        } else {
            return "Incomplete";
        }
    }, [complete, approved, denialReason]);

    const approvalIcon = React.useMemo(() => {
        let icon: JSX.Element;
        let message: string;
        switch (status) {
            case "Denied":
                icon = <i className="far fa-close tomato"></i>;
                message = "Denied";
                break;
            case "Approved":
                icon = <i className="fa-solid fa-check green"></i>;
                message = "Approved";
                break;
            case "Pending":
                icon = <i className="fa-solid fa-circle-exclamation orange"></i>;
                message = "Pending Approval";
                break;
            default:
                icon = <i className="fa-solid fa-circle-question blue"></i>;
                message = "Incomplete";
        }

        return (
            <Tooltip
                title={message}
            >
                {icon}
            </Tooltip>
        );
    }, [status]);

    const handleSelectMedia = (url: string) => {
        if (selectedMedia.some((i) => i.url == url)) {
            setSelectedMedia(selectedMedia.filter((i) => i.url != url));
        } else {
            setSelectedMedia([...selectedMedia, approvalFiles.find((i) => i.url == url)]);
        }
    };

    const handleSelectAllDownloads = () => {
        if (selectedMedia.length == approvalFiles.length) {
            setSelectedMedia([]);
        } else {
            setSelectedMedia([...approvalFiles]);
        }
    };

    const downloadDocument = (file: { url: string, name: string, time?: string }) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function () {
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = file.name; // Name the file anything you'd like.
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
        };
        xhr.open("GET", file.url);
        xhr.send();

        logFileDownload(file.name, file.url, null);
    };

    const handleBulkDownload = async () => {
        const zip = new JSZip();
        let count = 0;
        const zipFilename = "blizzard-lighting-documents.zip";

        selectedMedia.forEach((file) => {
            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if (err) {
                    throw err; // or handle the error
                }

                logFileDownload(file.name, file.url);
                zip.file(file.name, data, { binary: true });
                count++;
                if (count == selectedMedia.length) {
                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        FileSaver.saveAs(content, zipFilename);
                        setSelectedMedia([]);
                    });
                }
            });
        });
    };

    const renderListItem = (document: { url: string, name: string, time?: string }, index: number) => {
        
        let uploadDate = new Date(Number(document.time?.substring(0, 13))).toLocaleString();

        return (
            <div key={(document.time ?? "0") + index + "-list-item"}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <FileCopy onClick={() => handleSelectMedia(document.url)} />
                    </ListItemAvatar>

                    <ListItemText
                        primary={document.name}
                        secondary={
                            <>
                                {uploadDate != "Invalid Date" ? `Uploaded ${uploadDate}` : ""}
                            </>
                        }
                        onClick={() => window.open(document.url, "_blank")}
                        style={{ cursor: "pointer" }}
                    />

                    {canEdit ? <div>
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={() => openDeleteDocumentModal(document)}
                        >
                            <Delete color="error" />
                        </IconButton>
                    </div> : null}
                    

                    <div>
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={() => downloadDocument(document)}
                        >
                            <GetApp color="primary" />
                        </IconButton>
                    </div>

                    <Checkbox
                        checked={selectedMedia.some((i) => i.url == document.url)}
                        onChange={() => handleSelectMedia(document.url)}
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </div>
        );
    };

    const renderDocumentList = () => {
        const documentList = [];

        for (let di = 0; di < approvalFiles.length; di++) {
            const document = approvalFiles[di];
            documentList.push(renderListItem(document, di));
        }

        documentList.push(
            <ListItem key="bulkzone" alignItems="flex-start" style={{ height: 50 }}>
                <ListItemText primary={" "} />

                <Zoom
                    in={selectedMedia.length > 0}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${selectedMedia.length > 0 ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >

                    <Button
                        type="submit"
                        variant="contained"
                        color={"primary"}
                        className="btn"
                        fullWidth={true}
                        onClick={() => {
                            handleBulkDownload();
                        }}
                        endIcon={
                            <GetApp />
                        }
                        style={{ marginLeft: 15 }}
                    >
                        {"Zip and Download " + selectedMedia.length + " file" + (selectedMedia.length > 1 ? "s" : "")}
                    </Button>
                </Zoom>

                <Checkbox
                    checked={selectedMedia.length == approvalFiles.length}
                    onChange={handleSelectAllDownloads}
                />
            </ListItem>
        );

        return (
            <List>
                {documentList}
            </List>
        );
    };

    const viewButtonPressed = async () => {
        switch (cardType) {
            case ApprovalCardTypes.application:
                // ? Nothing ATM
                break;
            case ApprovalCardTypes.infoForm:
                // SHOW INFO FORM RESULTS
                if (canEdit) {
                    if (infoFormId) {
                        navigate("/view-reseller-info-form/" + infoFormId);
                    }
                } else {
                    setOpenInfoForm(true);
                }
                break;
            case ApprovalCardTypes.agreement:
                setCurrentProcess(ApprovalCardProcesses.gettingFiles);
                if (companyId) {
                    const documentStorageRef = storageRef.child(
                        `/portal_company_documents/${companyId}/reseller_agreement`
                    );
        
                    const files = await documentStorageRef.listAll();
                    const uploadedFiles = [];
        
                    for (let i = 0; i < files.items.length; i++) {
                        const itemRef = files.items[i];
        
                        // Ignore resized images
                        if (!itemRef.fullPath.includes("_500x500")) {
                            const url = await itemRef.getDownloadURL();
                            uploadedFiles.push({
                                time: itemRef.name.split("resellerAgreement-")[1],
                                name: itemRef.name,
                                url: url,
                            });
                        }
                    }
        
                    setApprovalFiles(uploadedFiles);
                    setFileSearchDone(true);
                }
                setCurrentProcess(ApprovalCardProcesses.none);
                break;
            case ApprovalCardTypes.certificate:
                setCurrentProcess(ApprovalCardProcesses.gettingFiles);
                if (companyId) {
                    const documentStorageRef = storageRef.child(
                        `/portal_company_documents/${companyId}/reseller_certificates`
                    );

                    const files = await documentStorageRef.listAll();
                    const uploadedFiles = [];

                    for (let i = 0; i < files.items.length; i++) {
                        const itemRef = files.items[i];

                        // Ignore resized images
                        if (!itemRef.fullPath.includes("_500x500")) {
                            const url = await itemRef.getDownloadURL();
                            uploadedFiles.push({
                                time: itemRef.name.split("resale_certificate-")[1],
                                name: itemRef.name,
                                url: url,
                            });
                        }
                    }

                    setApprovalFiles(uploadedFiles);
                    setFileSearchDone(true);
                }
                setCurrentProcess(ApprovalCardProcesses.none);
                break;
        }
    };

    const renderBody = () => {
        if (status === "Incomplete") {
            return (
                <Grid item xs={12}>
                    Last updated: {lastUpdated && lastUpdated.length > 1 ? lastUpdated : "Never"}
                </Grid>
            );
        } else if (status === "Pending" || status === "Denied" || status === "Approved") {
            return (
                <Grid item container xs={12} justifyContent="space-between">
                    {cardType == ApprovalCardTypes.agreement && status == "Pending" && (
                        <Grid item xs={12} style={{ marginBottom: 10 }}>
                            Awaiting signature from Blizzard Lighting in DocuSign
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Button
                            style={{ width: "100%", marginBottom: 8 }}
                            variant="contained"
                            color="primary"
                            onClick={viewButtonPressed}
                        >
                            {(cardType == ApprovalCardTypes.certificate || cardType == ApprovalCardTypes.agreement) ?
                                (currentProcess === ApprovalCardProcesses.gettingFiles ?
                                    <CircularProgress
                                        color="inherit"
                                        size={24}
                                    /> :
                                    "Get Files"
                                ) :
                                "View"
                            }
                        </Button>

                        {denialReason ? <Grid item xs={12}>
                            {`Denial Reason: ${denialReason}`}
                        </Grid> : <></>}
                    </Grid>
                    {canEdit && (
                        <>
                            <Grid item container xs={4} spacing={1}>
                                <Grid item xs={4}>
                                    <Button
                                        style={{ width: "100%" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={approveItem}
                                        disabled={status === "Approved"}
                                    >
                                        {currentProcess === ApprovalCardProcesses.approving ?
                                            <CircularProgress
                                                color="inherit"
                                                size={24}
                                            /> :
                                            "Approve"
                                        }
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        style={{ width: "100%", backgroundColor: status == "Denied" ? "lightgray" : "tomato" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { setEditing(true); }}
                                        disabled={status === "Denied"}
                                    >
                                        Deny
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        style={{ width: "100%", backgroundColor: "slateblue" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={resetItem}
                                    >
                                        {currentProcess === ApprovalCardProcesses.resetting ?
                                            <CircularProgress
                                                color="inherit"
                                                size={24}
                                            /> :
                                            "Reset"
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                            {(status == "Pending" || status == "Approved") && cardType == ApprovalCardTypes.certificate && (
                                <>
                                    <Grid item xs={12}>
                                        <h5>Certificate Expiration Date</h5>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "left" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={certNeverExpires}
                                                    onChange={() => setCertNeverExpires(!certNeverExpires)}
                                                    color="primary"
                                                    size="small"
                                                />
                                            }
                                            label={"Cert Does Not Expire"}
                                            style={{ margin: "0 auto" }}
                                        />
                                    </Grid>
                                    <Grid item container xs={8} alignItems="center" justifyContent="space-between">
                                        <Grid item xs={8}>
                                            {!certNeverExpires ?
                                            <div>
                                                <DateRange />
                                                <DatePicker 
                                                    value={certExpirationDate}
                                                    onChange={function (date: Moment): void {
                                                        setCertExpirationDate(date.toISOString());
                                                    } }
                                                    format={"MM/DD/yy"}
                                                    style={{width: "80%"}}
                                                />
                                                
                                            </div>
                                            : null}
                                        </Grid>
                                        
                                        <Grid item xs={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={saveExpiration}
                                                fullWidth={true}
                                                disabled={status != "Approved"}
                                            >
                                                {currentProcess === ApprovalCardProcesses.saving ?
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={24}
                                                    /> :
                                                    (status == "Approved" ? "Save Expiration" : "Pending approval")
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {editing && (
                        <>
                            <Grid item xs={12} style={{ marginTop: 10 }}><Typography variant="h6">Denial Reason:</Typography></Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    value={formValue}
                                    onChange={(e) => {
                                        setFormValue(e.target.value);
                                    }}
                                    variant="outlined"
                                    error={formError}
                                    helperText={formError ? "Required" : ""}
                                />
                            </Grid>
                            <Grid item container xs={12} justifyContent="space-between" style={{ marginTop: 10 }}>
                                <Grid item xs={2}>
                                    <Button
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            setEditing(false);
                                            setFormValue("");
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        style={{ width: "100%", backgroundColor: "tomato" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            if (formValue.trim().length > 0) {
                                                setFormError(false);
                                                denyItem();
                                            } else {
                                                setFormError(true);
                                            }
                                        }}
                                    >
                                        {currentProcess === ApprovalCardProcesses.denying ?
                                            <CircularProgress
                                                color="inherit"
                                                size={24}
                                            /> :
                                            "Submit"
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {approvalFiles && approvalFiles.length > 0 && (
                        <Grid item xs={12}>
                            {renderDocumentList()}
                        </Grid>
                    )}
                    {fileSearchDone && approvalFiles && approvalFiles.length == 0 && (
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <h2>No files found</h2>
                        </Grid>
                    )}
                </Grid>
            );
        }
    };

    return (
        <>
            <Dialog
                open={openDeleteDocument}
                onClose={closeDeleteDocumentModal}
            >
                <DialogTitle>Delete a File</DialogTitle>
                <DialogContent className="wizard">
                    <p>Are you sure you want to delete <strong>{documentToDelete ? documentToDelete.name : "unknown file"}</strong>?</p>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button
                            onClick={closeUploader}
                            variant="contained"
                            color="primary"
                        >
                            Close
                        </Button>
                        <Button
                            onClick={deleteDocument}
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "tomato" }}
                        >
                            Delete
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ModalBasic
                open={openInfoForm}
                close={() => setOpenInfoForm(false)}
            >
                <div style={{ padding: 20, overflow: "scroll", maxHeight: "80vh", backgroundColor: "#f2f5f8" }}>
                    <ResellerInfo closeForm={() => setOpenInfoForm(false)} />
                </div>
            </ModalBasic>
            <Dialog
                open={uploaderOpen}
                onClose={closeUploader}
            >
                <DialogTitle>Upload a File</DialogTitle>
                <DialogContent className="wizard">
                    <Upload 
                        uploadType={cardType == ApprovalCardTypes.certificate ? UploadType.cert : UploadType.agreement}
                        maxFileSize={10000000}
                        companyId={companyId}
                        approvalId={approvalId}
                        successMessage="Document uploaded successfully"
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button
                            onClick={closeUploader}
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "tomato" }}
                        >
                            Close
                        </Button>
                        {/* <Button
                            onClick={goToWizard}
                            variant="contained"
                            color="primary"
                        >
                            Complete Requirements
                        </Button> */}
                    </Grid>
                </DialogActions>
            </Dialog>
            <Paper elevation={2} className="contact-card">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                        <Typography variant="h5">
                            {title}&nbsp;
                            {canEdit && cardType != ApprovalCardTypes.infoForm && (
                                <i 
                                    className="far fa-upload" 
                                    style={{ fontSize: 16, cursor: "pointer" }}
                                    onClick={() => setUploaderOpen(true)}
                                ></i>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} justifyContent="center" className="center-text">
                        <Grid item xs={12}>{approvalIcon}</Grid>
                        <Grid item xs={12}>{status}</Grid>
                    </Grid>
                    {renderBody()}
                </Grid>
            </Paper>
        </>
    );
};

export default CompanyApprovalCard;