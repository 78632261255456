import React from "react";
import _ from "lodash";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, TextField, Tooltip } from "@material-ui/core";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import InviteSearchResult from "./InviteSearchResult";
import { NewResellerInviteInterface } from "interfaces/Invite";
import { UserContext } from "context/User";
import NewResellerInvite from "components/utils/NewResellerInvite";

interface InviteSearchProps {
    allInvites: NewResellerInviteInterface[],
    refresh: () => void,
}

const InviteSearch = ({ allInvites, refresh }: InviteSearchProps) => {
    const { accountLevels } = React.useContext(UserContext);
    const [input, setInput] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [selectedFilters, setSelectedFilters] = React.useState({
        pending: true,
        accepted: false,
        awaitingCompanyAssignment: true
    });
    const [filteredList, setFilteredList] = React.useState([]);
    const [sortMethods, setSortMethods] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);

    const windowScrollerRef = React.useRef<WindowScroller>();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 800,
                width: 250,
            },
        },
    };

    const sortOptions = [
        {
            label: "Email (A - Z)",
            value: "email",
            order: "asc",
        },
        {
            label: "Email (Z - A)",
            value: "email",
            order: "desc",
        },
        {
            label: "Company (A - Z)",
            value: "companyName",
            order: "asc",
        },
        {
            label: "Company (Z - A)",
            value: "companyName",
            order: "desc",
        },
        {
            label: "Invited By (A - Z)",
            value: "createdBy.company?.name",
            order: "asc",
        },
        {
            label: "Invited By (Z - A)",
            value: "createdBy.company?.name",
            order: "desc",
        },
    ];

    React.useEffect(() => {
        if (allInvites) {
            filterInvites();
            setLoading(false);
        }
    }, [allInvites]);

    React.useEffect(() => {
        filterInvites();
    }, [selectedFilters]);

    React.useLayoutEffect(() => {
        // Adjust the scroll view when filters are opened or closed
        if (windowScrollerRef && windowScrollerRef.current) {
            windowScrollerRef.current.updatePosition();
        }
    }, [showFilters]);

    const filterInvites = () => {
        // Only apply filters once one is selected
        const applyFilters = _.some(selectedFilters, Boolean);

        console.log(allInvites);
        

        if (applyFilters) {
            let pending = [];
            let accepted = [];
            let awaitingCompanyAssignment = [];

            if (selectedFilters.pending) {
                pending = allInvites?.filter((invite) => !invite.newUser?.id);
            }

            if (selectedFilters.accepted) {
                accepted = allInvites?.filter((invite) => invite.newUser?.id && invite.newUser?.company?.id);
            }

            if (selectedFilters.awaitingCompanyAssignment) {
                awaitingCompanyAssignment = allInvites?.filter((invite) => invite.newUser?.id && !invite.newUser?.company?.id);
            }

            setFilteredList(_.union(pending, accepted, awaitingCompanyAssignment));
        } else {
            setFilteredList(allInvites);
        }
    };

    const sortInvites = (searchResults) => {
        let returnable;

        if (sortMethods.length > 0) {
            returnable = _.orderBy(
                searchResults,
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).value),
                sortMethods.map((method) => sortOptions.find((option) => option.label == method).order),
            );
        } else {
            returnable = _.orderBy(searchResults, ["companyName"], ["asc"]);
        }

        return returnable;
    };

    const renderFilterOption = (option, label, tooltip) => {
        return (
            <Grid item xs={12} >
                <Tooltip title={tooltip} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedFilters[option]}
                                onChange={() => {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        [option]: !selectedFilters[option]
                                    });
                                }}
                                color="primary"
                                size="small"
                            />
                        }
                        label={label}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
        );
    };

    const renderSortOptions = () => {
        return (
            <FormControl style={{ width: "100%" }}>
                <Select
                    key="sortMethods"
                    multiple
                    value={sortMethods}
                    renderValue={() => (
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {sortMethods.map((method) => (
                                <Chip
                                    key={method}
                                    label={method}
                                    style={{ marginRight: 5 }}
                                />
                            ))}
                        </Box>
                    )}
                    onChange={(e: React.ChangeEvent<{
                        name?: string;
                        value: string[];
                    }>) => {
                        setSortMethods(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    {sortOptions.map(
                        (option) => {
                            const index = sortMethods.findIndex((n) => n == option.label);
                            return (
                                <MenuItem
                                    key={option.label}
                                    value={option.label}
                                >
                                    <span className={index > -1 ? "green" : "grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: index > -1 ? "green" : "grey" }}>{index > -1 ? index + 1 : " "}</span>
                                    {option.label}
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </FormControl>
        );
    };

    const searchResults = React.useMemo(() => {
        const searchInput = input.toLowerCase();

        const hits = _.filter(filteredList, (invite) => {
            return _.includes(invite.email.toLowerCase(), searchInput) || (invite.company && _.includes(invite.company.name.toLowerCase(), searchInput));
        });

        return sortInvites(hits);
    }, [filteredList, input, sortMethods]);

    const renderRow = ({ index, key, style }) => {
        return (
            <div style={style} key={key} >
                <div style={{ padding: 5 }}>
                    <InviteSearchResult invite={searchResults[index]} refresh={refresh} />
                </div>
            </div>
        );
    };

    const renderPlaceholder = () => {
        return <p className="body-message center-text">No Invites Found</p>;
    };

    return allInvites ? (
        <div className="grid-search">
            <Grid container alignItems="center">
                <Grid item xs={12} sm={9}>
                    <h3 className="title">New Reseller Invites</h3>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <NewResellerInvite creationCallback={refresh}/>
                </Grid>

            </Grid>
            <div className="search-field">
                <TextField
                    label="Invite Search"
                    variant="outlined"
                    onChange={(e) => setInput(e.target.value)}
                    fullWidth
                />
            </div>
            <Grid container className="container-spacing">
                <Grid item container alignItems="center" xs={12}>
                    <Grid item md={9} xs={7}><h3>Filter and Sort {`(${filteredList ? filteredList.length : ""})`}</h3></Grid>
                    <Grid item md={3} xs={5}>
                        <Button
                            variant={showFilters ? "contained" : "outlined"}
                            color={showFilters ? "primary" : "secondary"}
                            onClick={() => setShowFilters(!showFilters)}
                            fullWidth
                        >
                            {showFilters ? "Hide Filters" : "Show Filters"}
                        </Button>
                    </Grid>
                </Grid>
                {showFilters && (
                    <>
                        <Grid item container alignContent="flex-start" xs={9}>
                            <Grid item xs={12}>Account Level</Grid>
                            {renderFilterOption("pending", "Pending", "Invited users that have not signed up yet")}
                            {renderFilterOption("accepted", "Accepted", "Invites that were used to create an account and are a part of a company")}
                            {renderFilterOption("awaitingCompanyAssignment", "Needs Company", "Users that have created an account but aren't assigned to a company")}
                        </Grid>
                        <Grid item container alignContent="flex-start" xs={3}>
                            <Grid item xs={12}>Sort By</Grid>
                            <Grid item xs={12}>
                                {renderSortOptions()}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container className="results">
                <Grid container item xs={12} className="center-text grid-search-headers">
                    <Grid xs={3} item className="result-name">
                        Company
                    </Grid>
                    <Grid item xs={3}>
                        Email
                    </Grid>
                    <Grid item xs={3}>
                        Invited By
                    </Grid>
                    <Grid item xs={2}>
                        Status
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                {loading ?
                    (<Loading height="40vh" title="" position="relative" />) :
                    (<WindowScroller ref={windowScrollerRef}>
                        {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                            return <List
                                autoHeight
                                width={width}
                                height={height}
                                isScrolling={isScrolling}
                                onScroll={onChildScroll}
                                scrollTop={scrollTop}
                                rowHeight={70}
                                rowRenderer={renderRow}
                                noRowsRenderer={renderPlaceholder}
                                rowCount={searchResults.length}
                                overscanRowCount={3}
                            />;
                        }}
                    </WindowScroller>)
                }
            </Grid>
        </div>
    ) : (
        <Loading height="40vh" title="" position="relative" />
    );
};

export default InviteSearch;
