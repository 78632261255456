const blue = "rgb(65, 140, 249)",
  white = "#F5F6FB";
const styles = {
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)"
  },
  checkbox: {
    color: "white"
  },
  select: {
    background: white,
    fullWidth: true,
    width: "100%",
    paddingLeft: "10px",
    marginTop: "10px"
  },
  textField: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    fontWeight: 500,
    color: "white",
    "&:after": {
      borderBottomColor: blue
    }
  },
  input: {
    color: white,
    borderBottomColor: blue,

    "&:after": {
      borderBottomColor: blue
    }
  },
  label: {
    color: "white !important",
    borderBottomColor: blue
  },
  tired: {
    backgroundColor: "red"
  },
  checked: {
    "&, & + $label": {
      color: "blue"
    }
  }
};

export default styles;
