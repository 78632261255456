import React from "react";
import Button from "@material-ui/core/Button";
import { UserContext } from "context/User";

const UnauthorizedPage = () => {
  const { currentUser, signOut } = React.useContext(UserContext);

  return (
    <div className="error-boundary">
      <div className="container">
        <i
          style={{ fontSize: "4em", color: "tomato" }}
          className="fa-duotone fa-do-not-enter"
        ></i>
        <h2>Access to {currentUser.company.name} Restricted</h2>
        <p>
          Your account's access is currently restricted. Please reach out to a
          portal admin within your company so they can grant you the correct
          permission levels.
        </p>
        <Button
          onClick={() => {signOut()}}
          type="submit"
          style={{ width: "48%", background: "#282bf6" }}
          variant="contained"
          color="primary"
          className="btn"
        >
          Log Out
        </Button>
      </div>
    </div>
  );
}

export default UnauthorizedPage;