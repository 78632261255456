import React, { Component } from "react";

export default class VideoTab extends Component {
  numberWithCommas = x => {
    if (x !== undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  render() {
    const config = this.props.config;

    return (
      <div className="columns">
        <div className="column">
          <p>
            <span className="info-item">Native Resolution:</span>
            {config.natResWidth + " x " + config.natResHeight}
          </p>
          <p>
            <span className="info-item">Native HD?:</span>
            {config.hd}
          </p>
          <p>
            <span className="info-item">Total Pixels:</span>
            {this.numberWithCommas(config.pixelsPerPanel)}
          </p>
        </div>
        <div className="column">
          <p>
            <span className="info-item">Max Power:</span>
            {this.numberWithCommas(config.powerConsumption)}
          </p>
          <p>
            <span className="info-item">Circuits Needed(15A):</span>
            {config.cirNeeded}
          </p>
          <p>
            <span className="info-item">Controllers Needed:</span>
            {config.conNeeded}
          </p>
        </div>
      </div>
    );
  }
}
