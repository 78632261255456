export interface SalesOrderInterface {
  id: number,
  createdAt: Date,
  updatedAt: Date,
  customerId: number,
  repId: number,
  status: SalesOrderStatusInterface,
  customerPoNumber: string,
  soNumber: string,
  total: number,
  items: SalesOrderItemInterface[],
}

export interface SalesOrderStatusInterface {
  id: number,
  name: string
}

export const buildSalesOrder = (rawData): SalesOrderInterface => {
  if (!rawData) return null;
  return {
    id: rawData.id,
    createdAt: new Date(rawData.created_at),
    updatedAt: new Date(rawData.updated_at),
    customerId: rawData.customer_id,
    repId: rawData.rep_id,
    status: {
      id: rawData.status.id,
      name: rawData.status.name
    },
    customerPoNumber: rawData.customer_po_number,
    soNumber: rawData.so_number,
    total: rawData.total,
    items: buildSalesOrderItemList(rawData.items),
  }
}

export const buildSalesOrderList = (rawData): SalesOrderInterface[] => {
  let orders: SalesOrderInterface[] = [];
  if (!rawData) return orders;
  for (let soi = 0; soi < rawData.length; soi++) {
    orders.push(buildSalesOrder(rawData[soi]));
  }
  return orders;
}

export interface SalesOrderItemInterface {
  id: number,
  productId?: number,
  productName?: string,
  quantityOrdered: number,
  totalPrice: number,
  salesOrderId: number,
  status: SalesOrderItemStatusInterface,
}

export interface SalesOrderItemStatusInterface {
  id: number,
  name: string,
}

export const buildSalesOrderItem = (rawData): SalesOrderItemInterface => {
  if (!rawData) return null;
  return {
    id: rawData.id,
    productId: rawData.product_id,
    productName: rawData.product_name,
    quantityOrdered: rawData.quantity_ordered,
    totalPrice: rawData.total_price,
    salesOrderId: rawData.sales_order_id,
    status: {
      id: rawData.status.id,
      name: rawData.status.name
    }
  }
}

export const buildSalesOrderItemList = (rawData): SalesOrderItemInterface[] => {
  let items: SalesOrderItemInterface[] = [];
  if (!rawData) return items;
  for (let soii = 0; soii < rawData.length; soii++) {
    items.push(buildSalesOrderItem(rawData[soii]));
  }
  return items;
}