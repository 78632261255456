import React from "react";
import _ from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, Grid, List, ListItem, Paper, Typography } from "@material-ui/core";
import { PDProjectInterface, PDProjectUpdateInterface } from "interfaces/ProductDevelopment";
import { DeleteForever, ExpandMore } from "@material-ui/icons";

interface PDProjectCommunicationsListItemProps {
    update: PDProjectUpdateInterface,
    deleteUpdate: () => void
}

const PDProjectCommunicationsListItem = ({ update, deleteUpdate }: PDProjectCommunicationsListItemProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const canExpand = (update.details && update.details.length) || update.headline.length > 70;

    return (
        <Accordion
            key={update.id + "-comm-up-accordion"}
            style={{
                boxShadow: "rgb(0 0 0 / 10%) 0px 20px 30px 0px"
            }}
            elevation={0}
            expanded={expanded}
            onChange={(e, shouldExpand) => {
                if(canExpand) setExpanded(shouldExpand);
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore style={!canExpand ? {display: "none"} : {}} />} 
                className="line-item"
                style={!canExpand ? {cursor: "auto"} : {}}
            >
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <Typography noWrap={!expanded} component={"h5"} display={"block"} style={{maxWidth: 800, userSelect: "text"}}>
                            {update.headline}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <span style={{textAlign: "right", width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {update.createdAt.toDateString()} {canExpand ? "" : (" - " + update.createdBy)}
                            <DeleteForever style={{marginLeft: 12}} onClick={deleteUpdate} />
                        </span>
                    </Grid>
                </Grid>
                
                
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column", borderTop: "1px solid lightgrey", paddingTop: 24 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: update.details}} />
                    </Grid>
                    <Grid item xs={12}>
                        <strong>- {update.createdBy}</strong>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
};

export default PDProjectCommunicationsListItem;

