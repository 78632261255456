import React from "react";
import { Button, Grid, Paper, Snackbar, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, FormControlLabel, Checkbox } from "@material-ui/core";
import _ from "lodash";
import { makeAxiosCall, stringToMoney } from "utils";
import Loading from "components/utils/Loading";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import 'scss/components/Orders/PendingOrderSummary.scss';
import AdditionalShippingDetails from "components/utils/AdditionalShippingDetails";
import { Help, Save, Visibility } from "@material-ui/icons";
import { BuildWebOrderObject, TaxDetailsInterface, WebOrderInterface, WebOrderItemInterface } from "interfaces/WebOrder";
import { BuildWebCustomerObject, WebCustomerInterface } from "interfaces/WebCustomer";
import { AddressInterface } from "interfaces/Address";
import { useNavigate } from "react-router-dom";

const WebCustomerDetails = () => {
  const customerId = window.location.pathname.split("/web-customer/")[1];
  const [customer, setCustomer] = React.useState<WebCustomerInterface>();
  const [taxExempt, setTaxExempt] = React.useState<boolean>(false);

  let navigate = useNavigate();
  const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };

  const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
  
  React.useEffect(() => {
    fetchCustomerInfo();
  }, []);


    const goToOrder = (orderId: number) => {
        navigate(`/web-orders/${orderId}`);
    }

  const fetchCustomerInfo = async () => {
        if(!customerId) {
            setSnackbar({
                isOpen: true,
                message: "No Customer ID",
                severity: "error",
            });
        }
        let response = await makeAxiosCall(
            "get",
            `customer-info/${customerId}`,
        ).catch((error) => {
            console.error(error);
            setSnackbar({
                isOpen: true,
                message: "Failed to get Customer",
                severity: "error",
            });
        });
        if (response && response.data) {
            let customerData = BuildWebCustomerObject(response.data);
            setTaxExempt(customerData.taxExempt);
            setCustomer(customerData);
        } else {
            setSnackbar({
            isOpen: true,
            message: "Failed to get Customer",
            severity: "error",
            });
        }
    }

    const saveCustomer = async () => {
        let response = await makeAxiosCall(
            "post",
            `customer-info/${customerId}`,
            {taxExempt: taxExempt}
        ).catch((error) => {
            console.error(error);
            setSnackbar({
                isOpen: true,
                message: "Failed to get Customer",
                severity: "error",
            });
        });

        if (response && response.data) {
            let customerData = BuildWebCustomerObject(response.data);
            setTaxExempt(customerData.taxExempt);
            setCustomer(customerData);
        } else {
            setSnackbar({
                isOpen: true,
                message: "Failed to save Customer",
                severity: "error",
            });
        }
    }

    const renderAddresses = () => {
        return (
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <h3 className="center">Addresses</h3>
                </Grid>
                {customer && customer.addresses.length ? 
                    _.map(
                        customer.addresses,
                        (a: AddressInterface, i) => {
                            return (<Grid item md={4} xs={12} key={a.id + "-address"}>
                                <Paper className={"bordered-container"}>
                                    <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                        {a.companyName && <li>{a.companyName}</li>}
                                        <li>{a.contactName}</li>
                                        <li>{a.streetAddress}</li>
                                        {a.streetAddressTwo && (
                                            <li>{a.streetAddressTwo}</li>
                                        )}
                                        <li>
                                            {a.city}, {a.state} {a.zip}
                                        </li>
                                        <li>{a.country}</li>
                                    </ul>
                                </Paper>
                            </Grid>);
                        }
                    ) : <strong>No Addresses</strong>
                }
            </Grid>);
    }

    const renderOrders = () => {
        return (
            <Grid item container xs={12}>
                <h3 className="center">Orders</h3>
                <Grid container item xs={12} className="row-heads">
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        <p>SO #</p>
                    </Grid>
                    <Grid item sm={4}>
                        <p style={{ fontWeight: "bold" }}>Ordered On</p>
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        <p>Total</p>
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        <p>Fulfillment Status</p>
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                        
                    </Grid>
                </Grid>
                {customer && customer.orders.length ? 
                    _.map(
                        customer.orders,
                        (o: WebOrderInterface, i) => {
                            return (
                                <Grid container item xs={12} className="line-item" key={`${o.id}-order`} style={{marginBottom: 8}} onClick={() => goToOrder(o.id)}>
                                    <Grid item sm={2} style={{textAlign: "center"}}>
                                        <p>{o.soNumber ? o.soNumber : "No SO"}</p>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <p style={{ fontWeight: "bold" }}>{o.createdAt.toLocaleString()}</p>
                                    </Grid>
                                    <Grid item sm={2} style={{textAlign: "center"}}>
                                        <p>{stringToMoney(o.total)}</p>
                                    </Grid>
                                    <Grid item sm={2} style={{textAlign: "center"}}>
                                        <p>{o.fulfillmentStatus}</p>
                                    </Grid>
                                    <Grid item sm={2} style={{textAlign: "center"}}>
                                        <Visibility />
                                    </Grid>
                                </Grid>
                            );
                        }
                    ) : <strong>No Orders</strong>
                }
            </Grid>);
    };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, isOpen: false });
  };


  if (customer) {
    return (
    <Grid container className="admin view" style={{paddingLeft: 60, paddingRight: 60}}>
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Grid item xs={12}>
            <h1 style={{fontSize: 34}}>{customer.firstName + " " + customer.lastName}</h1>
        </Grid>

        <Grid container style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
            <Grid container item xs={6} md={4} spacing={2}  style={{textAlign: "center"}}>
                <Grid item xs={12}>
                    <h3>Contact</h3>
                </Grid>
                <Grid item md={12} xs={12}>
                    <h4>{customer.email}</h4>
                </Grid>

                {customer.phone && <Grid item md={12} xs={12}>
                    <h4>{customer.phone}</h4>
                </Grid>}
            </Grid>
            <Grid container item md={4} xs={3} style={{textAlign: "center"}} spacing={2} alignItems="center" >
                <Grid item xs={12}>
                    <h3 style={{textAlign: "center"}}>Rewards Balance</h3>
                </Grid>
                <Grid item xs={12}  className={"status highlight green"} style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis", maxWidth: 200, margin: "auto" }}>
                    {stringToMoney(customer.rewardsBalance)}
                </Grid>
            </Grid>
            <Grid container item md={4} xs={3} style={{textAlign: "center"}} spacing={2}>
                <Grid item xs={12}>
                    <h3>Customer Since</h3>
                </Grid>
                <Grid item xs={12} className={"status highlight blue"} style={{ whiteSpace: "nowrap", overflow: "clip", textOverflow: "ellipsis", maxWidth: 200, margin: "auto" }}>
                    {customer.createdAt.toLocaleDateString()}
                </Grid>
            </Grid>
        </Grid>

        <Grid container alignItems="center" style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
            <Grid item container xs={12} alignItems="center">
                {renderOrders()}
                {renderAddresses()}
            </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ backgroundColor: "#dddddd", padding: 20, borderRadius: 10, marginTop: 20 }} spacing={4}>
            <Grid item xs={12}>
                <h3>Tax Exemption</h3>
            </Grid>
            <Grid item xs={4}>
                <Tooltip title={"Is this a customer exempt from tax?"} placement="top" arrow>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={taxExempt}
                                onChange={() => {
                                    setTaxExempt(!taxExempt)
                                }}
                                color="primary"
                            />
                        }
                        label={"Tax Exempt"}
                        classes={{ label: "search-filter-option" }}
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={8}>
                <Button
                    onClick={() => saveCustomer()}
                    disabled={taxExempt == customer.taxExempt}
                    style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
                    variant="contained"
                    color="primary"
                    className="btn otl"
                >
                    Save Customer
                </Button>
            </Grid>
        </Grid>
    </Grid>
    );
  } else {
    return (
      <Loading position={"relative"} title={""} height={"40vh"} />
    );
  }
};

export default WebCustomerDetails;
