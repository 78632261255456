import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Button } from "@material-ui/core";
import _ from "lodash";
import { dateFormat } from "temp";
import { logFileDownload, makeAxiosCall, stringToMoney } from "utils";
import { ExpandMore } from "@material-ui/icons";
import { FishbowlSalesOrderItemInterface } from "interfaces/FishbowlSalesOrder";
import { API_URL } from "config";
import { useWindowWidth } from "hooks/useWindowSize";

const OrderInvoices = ({ so }) => {
  const [invoices, setInvoices] = React.useState<Array<{invoiceNum: string, items: Array<FishbowlSalesOrderItemInterface>}>>([]);
  const windowWidth = useWindowWidth();

  React.useEffect(() => {
    if (so) {
      let items = [];
      for (let item of so) {
        if (!item.invoiceNum || item.invoiceNum.length == 0) {
          continue;
        }
        if (!items.some((stored) => stored.invoiceNum == item.invoiceNum)) {
          items.push({invoiceNum: item.invoiceNum, items: [item]});
        } else {
          let existingInvoiceIndex = items.findIndex((stored) => stored.invoiceNum == item.invoiceNum);
          items[existingInvoiceIndex] = {
            ...items[existingInvoiceIndex],
            items: [
              ...items[existingInvoiceIndex].items,
              item
            ]
          }
        }
      };
      setInvoices(items);
    }
  }, [so]);

  const downloadInvoice = async (invoiceNumber: string) => {
    let targetInvoice = invoices.find((invoice) => invoice.invoiceNum == invoiceNumber);
    let invoiceSubtotal = targetInvoice.items.reduce((acc, b) => acc + Number(b.invoiceItemTotalPrice), 0);
    let invoiceTax = targetInvoice.items.find((item) => item.totalTax) ?
        Number(targetInvoice.items.find((item) => item.totalTax).totalTax) : 0;
    let formattedInvoice = {
      invoiceNum: targetInvoice.invoiceNum,
      items: targetInvoice.items.map((item) => {
        return {
          ...item,
          invoiceItemQty: parseInt(item.invoiceItemQty, 10),
          formattedUnitPrice: stringToMoney(item.unitPrice),
          formattedLinePrice: stringToMoney(parseInt(item.invoiceItemQty) * Number(item.unitPrice)),
        }
      }),
      customerContact: so[0].customerContact,
      billToName: so[0].billToName,
      billToAddress: so[0].billToAddress,
      billToCity: so[0].billToCity,
      billStateCode: so[0].billStateCode,
      billToZip: so[0].billToZip,
      shipToName: so[0].shipToName,
      shipToAddress: so[0].shipToAddress,
      shipToCity: so[0].shipToCity,
      shipStateCode: so[0].shipStateCode,
      shipToZip: so[0].shipToZip,
      salesman: so[0].salesman,
      paymentTerms: so[0].paymentTerms,
      fobpoint: so[0].fobpoint,
      carrier: so[0].carrier,
      carrierservice: so[0].carrierservice ? so[0].carrierservice : "N/A",
      dateScheduledFulfillment: dateFormat(so[0].dateScheduledFulfillment),
      formattedSubtotal: stringToMoney(invoiceSubtotal),
      formattedTax: stringToMoney(invoiceTax),
      formattedTotal: stringToMoney(invoiceSubtotal + invoiceTax),
      poNum: so[0].customerPO ?? so[0].num,
      dateIssued: dateFormat(so[0].dateIssued)
    }
    makeAxiosCall(
      "post",
      `invoice/${invoiceNumber}`,
      {
        invoice: formattedInvoice,
      },
      {
        headers: {
          "content-type": "application/json"
        }
      }
    ).then((res) => {
      if (res.data.invoiceNumber) {
        window.open(`${API_URL}invoice/${res.data.invoiceNumber}`, "_blank");
        logFileDownload("Invoice " + invoiceNumber, `${API_URL}invoice/${res.data.invoiceNumber}`);
      }
    });
  }
    
  const renderInvoices = () => {
    if (invoices && invoices.length > 0) {
      return _.map(
        _.orderBy(invoices, [(results) => results.soLineItem], ["asc"]),
        (o, i) => {
          let invoiceSubtotal = o.items.reduce((acc, b) => acc + Number(b.invoiceItemTotalPrice), 0);
          let invoiceTax = o.items.find((item) => item.totalTax && item.totalTax > 0) ?
              o.items.find((item) => item.totalTax && item.totalTax > 0).totalTax :
              0;
          return (
            <Accordion key={o.invoiceNum + i}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Grid container alignItems="center" style={{ fontWeight: "bold" }}>
                  <Grid item xs={4}>
                    Invoice {o.invoiceNum}
                  </Grid>
                  <Grid item xs={4}>
                  </Grid>
                  <Grid item xs={4} style={{textAlign: "right"}}>
                    {stringToMoney(invoiceSubtotal + invoiceTax)}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container key={`${o.soLineItem}`} id={o.invoiceNum}>
                  <Grid item xs={12}>
                    <h2>Invoice {o.invoiceNum}</h2>
                  </Grid>
                  <Grid container style={{ marginBottom: 30 }}>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      style={{
                        background: "white",
                        padding: 30,
                        borderRadius: 8,
                        marginRight: 10,
                      }}
                    >
                      <h4 style={{ margin: 0 }}>Bill To</h4>
                      <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                        <li>{so[0].customerContact}</li>
                        <li>{so[0].billToName}</li>
                        <li>{so[0].billToAddress}</li>
                        <li>
                          {so[0].billToCity}, {so[0].billStateCode} {so[0].billToZip}
                        </li>
                      </ul>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      style={{ background: "white", padding: 30, borderRadius: 8 }}
                    >
                      <h4 style={{ margin: 0 }}>Ship To</h4>
                      <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                        <li>{so[0].customerContact}</li>
                        <li>{so[0].shipToName}</li>
                        <li>{so[0].shipToAddress}</li>
                        <li>
                          {so[0].shipToCity}, {so[0].shipStateCode} {so[0].shipToZip}
                        </li>
                      </ul>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item md={2} xs={6} className="so-box">
                      <p
                        style={{
                          background: "#383838",
                          color: "white",
                          padding: 10,
                          borderRadius: 5,
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Sales Rep
                      </p>
                      <h4 style={{ margin: 0, paddingLeft: 10 }}>{so[0].salesman}</h4>
                    </Grid>
                    <Grid item md={2} xs={6} className="so-box">
                      <p
                        style={{
                          background: "#383838",
                          color: "white",
                          padding: 10,
                          borderRadius: 5,
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Payment Terms
                      </p>
                      <h4>{so[0].paymentTerms}</h4>
                    </Grid>
                    <Grid item md={2} xs={6} className="so-box">
                      <p
                        style={{
                          background: "#383838",
                          color: "white",
                          padding: 10,
                          borderRadius: 5,
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        FOB Point
                      </p>
                      <h4>{so[0].fobpoint}</h4>
                    </Grid>
                    <Grid item md={2} xs={6} className="so-box">
                      <p
                        style={{
                          background: "#383838",
                          color: "white",
                          padding: 10,
                          borderRadius: 5,
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Carrier
                      </p>
                      <h4>{so[0].carrier}</h4>
                    </Grid>
                    <Grid item md={2} xs={6} className="so-box">
                      <p
                        style={{
                          background: "#383838",
                          color: "white",
                          padding: 10,
                          borderRadius: 5,
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Ship Service
                      </p>
                      <h4>{so[0].carrierservice ? so[0].carrierservice : "N/A"}</h4>
                    </Grid>
                    <Grid item md={2} xs={6} className="so-box">
                      <p
                        style={{
                          background: "#383838",
                          color: "white",
                          padding: 10,
                          borderRadius: 5,
                          marginRight: 10,
                          fontWeight: "bold",
                        }}
                      >
                        Date Scheduled
                      </p>
                      <h4>{dateFormat(so[0].dateScheduledFulfillment)}</h4>
                    </Grid>
                  </Grid>
                  {windowWidth > 700 &&
                  <Grid container className="row-heads">
                    <Grid item sm={4}>
                      <p>Item</p>
                    </Grid>
                    <Grid item sm={3}>
                      <p>Unit Price</p>
                    </Grid>
                    <Grid item sm={3}>
                      <p>Quantity</p>
                    </Grid>
                    <Grid item sm={2}>
                      <p style={{ fontWeight: "bold" }}>
                        Total Price
                      </p>
                    </Grid>
                  </Grid>}
                  {_.map(o.items, (item, index) => {
                    return (
                      <Grid container item xs={12} key={`${item.invoiceNum}${item.soLineItem}${index}`} style={{padding: "0 20px"}}>
                        <Grid item md={4} xs={12}>
                          <p style={{ fontWeight: "bold" }}>{item.productNum}</p>
                        </Grid>
                        <Grid item md={3} xs={4}>
                          <p>{stringToMoney(item.unitPrice)}</p>
                        </Grid>
                        <Grid item md={3} xs={4}>
                          <p>{parseInt(item.invoiceItemQty, 10)}</p>
                        </Grid>
                        <Grid item md={2} xs={4}>
                          <p style={{ fontWeight: "bold" }}>
                            {stringToMoney(parseInt(item.invoiceItemQty) * Number(item.unitPrice))}
                          </p>
                        </Grid>
                        {item.type == "Shipping" && (
                          <Grid item xs={12}>{item.description}</Grid>
                        )}
                      </Grid>
                    );
                  })}
                  <Grid container style={{marginTop: 30}} justifyContent="flex-end">
                    <Grid
                      item
                      md={4}
                      xs={12}
                      style={{ background: "white", padding: 30, borderRadius: 8 }}
                    >
                      <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                        <li
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                          <strong>Subtotal</strong> {stringToMoney(invoiceSubtotal)}
                        </li>
                        <li
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                          <strong>Tax</strong> {stringToMoney(invoiceTax)}
                        </li>
                        <li
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                          <strong>Total</strong>{" "}
                          {stringToMoney(
                            invoiceSubtotal + invoiceTax
                          )}
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                        variant="contained"
                        fullWidth={true}
                        color="primary"
                        className="btn"
                        onClick={() => {
                          downloadInvoice(o.invoiceNum);
                        }}
                      >
                      Download
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        }
      );
    } else {
      return (
        <h4 style={{ textAlign: "center", fontSize: 23, fontWeight: "normal" }}>
          No finalized invoices found for this order.
        </h4>
      )
    }
  };

  if (so && invoices) {
    return (
      <div
        style={{ backgroundColor: "#dddddd", padding: 40, borderRadius: 10, marginTop: 20 }}
      >
        <Grid container={true} className="order-meta flex">
          <Grid item={true} md={2} xs={6} className="col" style={{ marginBottom: 30 }}>
            <h3
              style={{
                borderBottom: "1px solid gray",
                margin: 0,
                marginBottom: 10,
                display: "table",
              }}
            >
              PO #
            </h3>
            <h1 style={{ fontWeight: 900, margin: 0 }}>{so[0].customerPO ?? so[0].num}</h1>
          </Grid>
          <Grid item md={2} xs={6} className="col" style={{ marginBottom: 30 }}>
            <h3
              style={{
                borderBottom: "1px solid gray",
                margin: 0,
                marginBottom: 10,
                display: "table",
              }}
            >
              Date Created
            </h3>
            <h3 style={{ fontWeight: 900, margin: 0 }}>
              {dateFormat(so[0].dateCreated)}
            </h3>
          </Grid>
        </Grid>

        {renderInvoices()}

        <Grid container style={{marginTop: 30}} justifyContent="flex-end">
          <Grid
            item
            md={4}
            xs={12}
            style={{ background: "white", padding: 30, borderRadius: 8 }}
          >
            <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
              <li
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <strong>Subtotal</strong> {stringToMoney(so[0].subTotal)}
              </li>
              <li
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <strong>Tax</strong> {stringToMoney(so[0].totalTax)}
              </li>
              <li
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <strong>Total</strong>{" "}
                {stringToMoney(
                  parseFloat(so[0].totalTax) + parseFloat(so[0].subTotal)
                )}
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <h3>No order info</h3>
      </div>
    );
  }
};

export default OrderInvoices;
