import React, { useContext } from "react";
import { UserContext } from "context/User";
import { Button } from "@material-ui/core";

const Loading = ({height, title, position}) => {

  const [timeout, initTimeout] = React.useState(false);
  const { signOut } = useContext(UserContext);

  React.useEffect(() => {
    let isSubscribed;
    setTimeout(() => {
      if (isSubscribed) {
        initTimeout(true);
      }
    }, 30000);
    return () => (isSubscribed = false);
  }, [initTimeout]);

  if (!timeout) {
    return (
      <div className="loading" style={{ position: position }}>
        <div className="bloogle" style={{ height: height }}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 879.89 1200"
            //   style="enable-background:new 0 0 879.89 1200;"
          >
            <path
              className="yellow"
              d="M547.14,467.3c-25.34,14.72-56.88,22.08-94.63,22.08H226.26V202.79h11.18L0,0v576.86h675.42L547.14,467.3z"
            />
            <path
              className="green"
              d="M0.99,1200l226.8-207.82h-1.53V683.8h244.69c30.77,0,57.68,3.87,80.75,11.57L675.42,582v-5.14H0V1200H0.99z"
            />

            <path
              className="blue"
              d="M544.18,693.03c25.5,7.35,46.62,19.07,63.36,35.18c30.72,29.62,46.09,66.78,46.09,111.45
    c0,44.7-15.08,81.28-45.25,109.78c-30.17,28.49-73.74,42.74-130.73,42.74h-251.4v-2.68L0.53,1200h485.51
    c54.73,0,105.85-7.8,153.35-23.46c47.48-15.63,89.09-37.97,124.86-67.04c35.75-29.04,63.95-63.95,84.64-104.75
    c20.66-40.77,31.01-86.86,31.01-138.27c0-70.39-18.72-129.31-56.15-176.82c-37.45-47.48-86.89-85.3-148.32-107.66L544.18,693.03z"
            />
            <g>
              <path
                className="red"
                d="M237.44,202.79h221.78c49.16,0,87.7,13.98,115.64,41.9c27.92,27.94,41.9,61.46,41.9,100.56
      c0,39.12-14.24,72.9-42.74,101.4c-8.01,8.01-16.97,14.9-26.88,20.65l128.28,109.56v-5.35c45.8-22.34,84.06-55.02,114.8-98.04
      c30.72-43,46.09-96.37,46.09-160.06c0-43.57-9.5-84.35-28.49-122.35c-19.01-37.97-44.99-71.23-77.93-99.72
      c-32.97-28.49-71.8-50.83-116.48-67.04C568.71,8.12,520.65,0,469.27,0H0L237.44,202.79z"
              />
            </g>
          </svg>
          {title && <p>{title}</p>}
        </div>
      </div>
    );
  } else
    return (
      <div className="alert-dialogue">
        <i className="far fa-exclamation-circle"></i>
        <h3>There was a connection error. Please try again later.</h3>
        <Button
          type="submit"
          style={{ width: "48%", marginTop: 20, marginLeft: 10 }}
          variant="contained"
          color="primary"
          className="btn"
          onClick={() => (window.location.href = "/")}
        >
          Refresh
        </Button>
        <Button
          type="submit"
          style={{ width: "48%", marginTop: 20 }}
          variant="contained"
          color="primary"
          className="btn"
          onClick={() => {
            signOut();
          }}
        >
          Log Out
        </Button>
      </div>
    );
};

export default Loading;
