import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Card from "@material-ui/core/Card";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SideDrawer from "components/Nav/SideDrawer";
import TopNav from "components/Nav/TopNav";
import logo from "assets/images/logo.svg";

import { ViewProvider } from "context/View";
import { AddressProvider } from "context/Address";
import { UserContext } from "context/User";
import Wizard from "components/Account/Wizard/Wizard";
import { CardContent, Button, Grid, CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
// import { SyncingFishbowlCustomFieldsProvider } from "context/SyncingFishbowlCustomFields";
import { makeAxiosCall } from "utils";
import { MenuOpen } from "@material-ui/icons";
import ApprovalExpirationPopup from "./utils/ApprovalExpirationPopup";
import { CartContext } from "context/Cart";

interface HomeProps {
    children: any,
}

export default function Home({ children }: HomeProps) {
    const { currentUser, signOut } = React.useContext(UserContext);
    const { clearCart } = React.useContext(CartContext);

    // const [hsCompanyProps, setCompanyProps] = React.useState({
    //   form: false,
    //   cert: false,
    //   agreement: false,
    // });

    const drawerWidth = 100;
    const navigate = useNavigate();


    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
        },
        appBar: {
            backgroundColor: "#000",
            borderBottom: "1px solid #08131b",
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `100%`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: "#000",
            color: "#828282",
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        content: {
            flexGrow: 1,

            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentShift: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }));

    const classes = useStyles();

    const [open, setOpen] = useState(true);
    const [showExpirationPopup, setShowExpirationPopup] = React.useState<boolean>(false);
    const [existingResellerFlag, setExistingResellerFlag] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [mobileMode, setMobileMode] = useState(false);
    const [error, setError] = useState("");
    const [companyAdminEmails, setCompanyAdminEmails] = useState([]);

    React.useEffect(() => {
        const mediaQuery = window.matchMedia("(min-width: 768px)");
        if (mediaQuery.matches) {
            handleDrawerOpen();
        } else {
            handleDrawerClose();
        }

        setMobileMode(!mediaQuery.matches);
        
        // mediaQuery.addListener((mq) => {
        //   if (mq.matches) {
        //     handleDrawerOpen();
        //   } else {
        //     handleDrawerClose();
        //   }
        // });
    }, []);

    React.useEffect(() => {
        if(currentUser && currentUser.company && !currentUser.company.approved && currentUser.accountLevel.name != "Admin"  && currentUser.accountLevel.name != "Super Admin") {
            loadCompanyAdminEmails();
        }

        if (currentUser && currentUser.accountSignupStatus) {
            setExistingResellerFlag(currentUser.accountSignupStatus == "needs review");
        }
        if (currentUser) {
            if (currentUser.company?.approvalStatus?.expirationDate) {
                const warningPopupViewed = localStorage.getItem("warningPopupViewed");
                if (!warningPopupViewed && window.location.href.indexOf('approval') == -1) {
                    localStorage.setItem("warningPopupViewed", "true");
                    
                    openExpirationPopup();
                }
            }
        }
    }, [currentUser]);


    const loadCompanyAdminEmails = () => {
        makeAxiosCall(
            "get",
            `company-admin-emails`
        ).then(response => {
            console.log(response);
            
            setCompanyAdminEmails(response.data);
        }).catch(error => {
            console.error(error);
            setError("Something went wrong. Please refresh the page and try again.");
            setRequesting(false);
        });
    };

    const requestCompanyReview = () => {
        setRequesting(true);

        makeAxiosCall(
            "post",
            `account/${currentUser.uid}`,
            {
                accountSignupStatus: "needs review"
            }
        ).then(response => {
            if (response.status === 200) {
                makeAxiosCall(
                    "post",
                    "email/new-user",
                    {
                        to: {
                            id: currentUser.uid,
                            email: currentUser.email,
                        }
                    }
                );
                setExistingResellerFlag(true);
            } else {
                setError("Something went wrong. Please refresh the page and try again.");
            }
            setRequesting(false);
        }).catch(error => {
            console.error(error);
            setError("Something went wrong. Please refresh the page and try again.");
            setRequesting(false);
        });
    };

    const goToApplication = () => {
        window.scrollTo(0, 0);
        navigate("/apply");
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleMobileEvent = (type) => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        if (mediaQuery.matches && type === "mobile") {
            setOpen(false);
        }
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const openExpirationPopup = () => {
        setShowExpirationPopup(true);
    };

    const closeExpirationPopup = () => {
        setShowExpirationPopup(false);
    };

    const bypassWizard =
        (
            currentUser.company != null &&
            currentUser.company.approved
        ) ||
        currentUser.accountLevel.name === "Super Admin";

    if (!currentUser.company) {
        // Fresh user, show how to join a company
        return (
            <div className="overview view" style={{ display: "grid" }}>
                <div className="top-floating-nav">
                    <div
                        className="back-crumb"
                        onClick={() => {
                            clearCart();
                            signOut();
                        }}
                    >
                        <i className="fad fa-sign-out"></i> Log Out
                    </div>
                </div>
                <img style={{ maxWidth: 150, margin: "auto", display: "inline-block", marginBottom: 20 }} className="logo" src={logo} alt="Blizzard pro" />
                <h1 style={{ textAlign: "center" }}>Thank you for signing up</h1>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Card raised={true} style={{ padding: 10, margin: 10, display: "inline-block", height: "100%" }} >
                            <CardContent style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                <h1 style={{ textAlign: "center" }}>Existing Reseller</h1>
                                {existingResellerFlag ?
                                    (
                                        <>
                                            <p style={{ textAlign: "center" }}>
                                                You&apos;re all set for now!
                                            </p>
                                            <p style={{ textAlign: "center" }}>
                                                Our team will review your account and set up access
                                                to your company. A representative will contact you
                                                when your account has been verified.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    signOut();
                                                }}
                                                variant="contained"
                                                style={{ width: "48%", marginTop: "auto", marginBottom: 50, marginRight: "auto", marginLeft: "auto", display: "block" }}
                                                color="primary"
                                                className="btn"
                                            >
                                                Sign out
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <p style={{ textAlign: "center" }}>
                                                If your company is an existing Blizzard reseller, but new to the Blizzard Customer Portal click the link below to
                                                submit your account for review.
                                            </p>
                                            <p style={{ textAlign: "center" }}>A representative will contact you once your account has been verified.</p>
                                            <p style={{ textAlign: "center" }}>
                                                If your company already uses the Blizzard Customer Portal, have an admin user from
                                                your company send you an invite email.
                                            </p>
                                            {error.length > 0 && (
                                                <p style={{ textAlign: "center", color: "tomato" }}>
                                                    {error}
                                                </p>
                                            )}
                                            <Button
                                                onClick={() => {
                                                    requestCompanyReview();
                                                }}
                                                variant="contained"
                                                style={{ width: "48%", marginTop: "auto", marginBottom: 50, marginRight: "auto", marginLeft: "auto", display: "block" }}
                                                color="primary"
                                                className="btn"
                                            >
                                                {requesting ?
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={24}
                                                    /> :
                                                    "Request Review"
                                                }
                                            </Button>
                                        </>
                                    )
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card raised={true} style={{ padding: 10, margin: 10, display: "inline-block", height: "100%" }} >
                            <CardContent style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                <h1 style={{ textAlign: "center" }}>Become a Reseller</h1>

                                {currentUser.accountSignupStatus == "applied" ? (
                                    <>
                                        <p style={{ textAlign: "center" }}>
                                            Your application has been submitted.
                                            A representative will contact you with more information
                                            once your application has been reviewed.
                                        </p>
                                        <Button
                                            onClick={goToApplication}
                                            variant="contained"
                                            style={{ width: "48%", marginTop: "auto", marginBottom: 50, marginRight: "auto", marginLeft: "auto", display: "block" }}
                                            color="primary"
                                            className="btn"
                                        >
                                            Edit your Application
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <p style={{ textAlign: "center" }}>
                                            If your company is looking to become a reseller, click the
                                            link below and fill out our reseller application form. A representative
                                            will contact you with more information about the approval process.
                                        </p>
                                        <Button
                                            onClick={goToApplication}
                                            variant="contained"
                                            style={{ width: "48%", marginTop: "auto", marginBottom: 50, marginRight: "auto", marginLeft: "auto", display: "block" }}
                                            color="primary"
                                            className="btn"
                                        >
                                            Apply
                                        </Button>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    } else if (!currentUser.company.fishbowlCustomerId) {
        // User company still under review
        return (
            <div className="overview view" style={{ display: "grid" }}>
                <img style={{ maxWidth: 200, margin: "auto", display: "inline-block", marginBottom: 20 }} className="logo" src={logo} alt="Blizzard pro" />
                <Card raised={true} style={{ padding: 20, margin: "auto", display: "inline-block", marginBottom: 20 }} >
                    <CardContent>
                        <h1 style={{ textAlign: "center" }}>Thank you for signing up</h1>
                        <p style={{ textAlign: "center" }}>Your acccount is still under review.<br />You will recieve an email soon with more details.</p>

                        <Button
                            onClick={() => {
                                signOut();
                            }}
                            variant="contained"
                            style={{ width: "48%", marginTop: 50, marginRight: "auto", marginLeft: "auto", display: "block" }}
                            color="primary"
                            className="btn"
                        >
                            Sign out
                        </Button>
                    </CardContent>
                </Card>
            </div>
        );
    } else if (!currentUser.company.approved && currentUser.accountLevel.name != "Admin"  && currentUser.accountLevel.name != "Super Admin") {
        return (
            <div className="overview view" style={{ display: "grid" }}>
                <img style={{ maxWidth: 200, margin: "auto", display: "inline-block", marginBottom: 20 }} className="logo" src={logo} alt="Blizzard pro" />
                <Card raised={true} style={{ padding: 20, margin: "auto", display: "inline-block", marginBottom: 20 }} >
                    <CardContent>
                        <h1 style={{ textAlign: "center" }}>You're too early!</h1>
                        <p style={{ textAlign: "center" }}>Your company's portal admin is still setting up the account.<br />Please reach out to your company's account admin to finish setup and get access to the portal.</p>
                        <br /><br />
                        <p style={{ textAlign: "center", fontWeight: "bold" }}>Your comany's admin{companyAdminEmails.length > 1 ? "(s) are:" : " is" }  {companyAdminEmails.join(', ')}</p>

                        <Button
                            onClick={() => {
                                signOut();
                            }}
                            variant="contained"
                            style={{ width: "48%", marginTop: 50, marginRight: "auto", marginLeft: "auto", display: "block" }}
                            color="primary"
                            className="btn"
                        >
                            Sign out
                        </Button>
                    </CardContent>
                </Card>
            </div>
        );

    } else if (!bypassWizard) {
        
        return (
            <AddressProvider>
                <Wizard />
            </AddressProvider>
        );
        // } else if (primaryRoleSetup) {
        //   return (
        //     <InviteCenter tempUser={tempUser} clearTempUser={clearTempUser} user={user} />
        //   );
    } else {
        return (
            <ViewProvider>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}
                    >
                        <Toolbar style={{ minHeight: 0 }}>
                            <div>
                                <IconButton
                                    style={{
                                        position: "relative",
                                        right: 20,
                                    }}
                                    className={clsx(!open && classes.hide)}
                                    onClick={handleDrawerClose}
                                >
                                    <MenuOpen
                                        style={{ color: "#828282" }}
                                    />
                                </IconButton>
                            </div>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(
                                    classes.menuButton,
                                    open && classes.hide
                                )}
                            >
                                <MenuIcon />
                            </IconButton>
                            <TopNav />
                        </Toolbar>
                    </AppBar>

                    <Drawer
                        id="custom-scroll"
                        className={classes.drawer}
                        variant={mobileMode ? "temporary" : "persistent"}
                        anchor="left"
                        open={open}
                        onClick={mobileMode ? handleDrawerClose : null}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <SideDrawer handleMobileEvent={handleMobileEvent} />
                    </Drawer>

                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <ApprovalExpirationPopup 
                            popupOpen={showExpirationPopup}
                            onClose={closeExpirationPopup}
                        />
                        {children}
                    </main>
                </div>
            </ViewProvider>
        );
    }
}
