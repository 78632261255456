import React from "react";

interface IconBubbleProps {
    icon: string,
    complete: boolean,
    denied: boolean,
}

const IconBubble = ({ icon, complete, denied }: IconBubbleProps) => {
    return (
        <div className={denied ? " icon-bubble red" : (complete ? " icon-bubble green" : " icon-bubble blue")}>
            {denied ? <i className="fad fa-flag"></i> : (complete ? <i className="fad fa-check"></i> : <i className={icon}></i>)}
        </div>
    );
};

export default IconBubble;
