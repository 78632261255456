import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { CartContext } from "context/Cart";
import { ViewContext } from "context/View";
const OrderIcon = () => {
  const { currentOrder } = useContext(CartContext);
  const { saveCurrentPanel } = useContext(ViewContext);

  return (
    <Link to="/checkout">
      <div
        className={currentOrder && currentOrder.orderItems.length > 0 ? "po-cart active" : "po-cart"}
        onClick={() => saveCurrentPanel("checkout")}
      >
        {currentOrder && currentOrder.orderItems.length > 0 && <div className="qty">{currentOrder.orderItems.length}</div>}
        <i className="far fa-cart-plus"></i>
      </div>
    </Link>
  );
};

export default OrderIcon;
