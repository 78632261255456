import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

export default class RatioInput extends Component {
  render() {
    const config = this.props.config;
    return (
      <div className="ratio-inputs">
        <TextField
          id="ratio"
          label="Ratio"
          margin="normal"
          maxLength="3"
          value={config.ratioSelected_1}
          onChange={this.props.handleInputChange(
            "ratioSelected_1",
            config.dimSelect
          )}
          type="number"
        />
        <span>:</span>
        <TextField
          id="ratio"
          label="Ratio"
          margin="normal"
          maxLength="3"
          value={config.ratioSelected_2}
          onChange={this.props.handleInputChange(
            "ratioSelected_2",
            config.dimSelect
          )}
          type="number"
        />
      </div>
    );
  }
}
