import React from "react";
import {
    Button,
    Select,
    MenuItem,
    TextField,
    FormControl,
    Grid,
    OutlinedInput,
    Box,
    Chip,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Snackbar,
    CircularProgress,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";

import { ResellerApprovalFormInterface, resellerConnectionOptions, thirdPartyMarketingOptions, primaryLineOfBusinessOptions, percentBusinessOptions } from "interfaces/ResellerApprovalForm";
import { makeAxiosCall } from "utils";
import { UserContext } from "context/User";
import CompanyAddresses from "components/MyCompany/CompanyAddresses";
import CompanyContacts from "components/MyCompany/CompanyContacts";
import "scss/components/Account/Wizard/ResellerInfo.scss";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ContactInterface } from "interfaces/Contact";
import { Info } from "@material-ui/icons";
import PermissionTable from "components/utils/PermissionTable";

interface ResellerInfoProps {
    closeForm: () => void,
}

const ResellerInfo = ({ closeForm }: ResellerInfoProps) => {

    const defaultFormData = {
        legalEntityName: "",
        dbas: "",
        businessType: "Sole Proprietorship",
        addresses: [],
        contacts: [],
        businessDescription: "",
        website: "",
        connections: [],
        thirdPartyMarketing: [],
        primaryLineOfBusiness: "",
        percentProduction: "",
        percentTheatrical: "",
        percentWorship: "",
        percentDJ: "",
        percentArchitectural: "",
        percentOther: "",
        retailStore: false,
        showRoom: false
    };

    const businessTypes = [
        { id: 1, name: "Sole Proprietorship" },
        { id: 2, name: "Partnership" },
        { id: 3, name: "Corporation" },
        { id: 4, name: "LLC" }
    ];

    const { currentUser, accountLevels } = React.useContext(UserContext);
    const [step, setStep] = React.useState<number>(1);
    const [formData, setFormData] = React.useState<ResellerApprovalFormInterface>(defaultFormData);
    const [businessType, setBusinessType] = React.useState<number>(1);
    const [roleData, setRoleData] = React.useState({});
    const [otherInviteEmail, setOtherInviteEmail] = React.useState<string>();
    const [invitingOther, setInvitingOther] = React.useState<boolean>(false);
    const [currentlyInviting, setCurrentlyInviting] = React.useState<ContactInterface[]>([]);
    const [invited, setInvited] = React.useState<ContactInterface[]>([]);
    const [showRoleInfo, setShowRoleInfo] = React.useState<boolean>(false);
    const [errorsOn, setErrorsOn] = React.useState<boolean>(false);
    const [badContact, setBadContact] = React.useState<ContactInterface>(null);
    const [snackbar, setSnackbar] = React.useState({
        isOpen: false,
        message: "",
        severity: "success",
    });

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 800,
                width: 250,
            },
        },
    };

    React.useEffect(() => {
        if (currentUser.company.resellerInfo) {
            setFormData(currentUser.company.resellerInfo);
            const btype = businessTypes.find((type) => type.name == currentUser.company.resellerInfo.businessType);
            
            if (btype) setBusinessType(btype.id);
        } else {
            setFormData({...formData, addresses: currentUser.company.addresses, contacts: currentUser.company.contacts});
        }
    }, [currentUser]);

    React.useEffect(() => {
        if (badContact) {
            validateStep();
        }
    }, [formData]);

    const saveForLater = () => {

        makeAxiosCall(
            "post",
            "reseller-info-form/" + currentUser.company.id,
            { ...formData, resellerApprovalStatusId: currentUser.company.approvalStatus.id, infoFormComplete: false }
        ).then(() => closeForm()).catch((err) => console.log(err));
    }
    const nextStep = () => {
        if (step == 6) {
            // SAVE
            makeAxiosCall(
                "post",
                "reseller-info-form/" + currentUser.company.id,
                { ...formData, resellerApprovalStatusId: currentUser.company.approvalStatus.id, infoFormComplete: true }
            )
                .then(() => closeForm())
                .catch((err) => console.log(err));
        } else {
            if (validateStep()) {
                setStep(step + 1);
                window.scrollTo(0, 0);
                if(step == 5) {
                    setShowRoleInfo(true);
                }
            } else {
                setErrorsOn(true);
            }
        }
    };

    const renderNavigation = () => {
        return (
            <div className="form-nav btn-group">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="btn"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        setStep(step - 1);
                    }}
                    disabled={step === 1 ? true : false}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className="btn"
                    onClick={() => {
                        saveForLater();
                    }}
                    disabled={step === 1 ? true : false}
                >
                    Save For Later
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        nextStep();
                    }}
                    className="btn"
                >
                    {step === 6 ? "Submit" : "Next"}
                </Button>
            </div>
        );
    };

    const renderProgressBar = () => {
        const barWidth = (step / 6) * 100;
        return (
            <div className="bar">
                <div className="progress" style={{ width: barWidth + "%" }}></div>
            </div>
        );
    };

    const renderGeneralInfoForm = () => {
        return (
            <Grid container>
                <h1>General Info</h1>
                <Grid item xs={12}>
                    <TextField
                        key="legalEntityName"
                        style={{ width: "100%" }}
                        id="outlined-basic"
                        value={formData.legalEntityName}
                        label={"Legal Entity Name"}
                        error={
                            !formData.legalEntityName && errorsOn ? true : false
                        }
                        onChange={
                            (e) => setFormData(
                                {
                                    ...formData,
                                    legalEntityName: e.target.value
                                }
                            )
                        }
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        key="dbas"
                        style={{ width: "100%" }}
                        id="outlined-basic"
                        value={formData.dbas}
                        label={"DBA(s)"}
                        onChange={
                            (e) => setFormData(
                                {
                                    ...formData,
                                    dbas: e.target.value
                                }
                            )
                        }
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: 16, marginBottom: 8 }}
                        error={
                            !formData.businessType && errorsOn ? true : false
                        }
                    >
                        <InputLabel
                            style={{ backgroundColor: "white" }}
                            error={
                                !formData.businessType && errorsOn ? true : false
                            }
                        >
                            Business Type
                        </InputLabel>
                        <Select
                            key="business-type"
                            label="Business Type"
                            value={businessType}
                            renderValue={() => (
                                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                    {businessTypes.find((t) => t.id == businessType).name}
                                </Box>
                            )}
                            onChange={(e: React.ChangeEvent<{
                                name?: string;
                                value: number;
                            }>) => {
                                setBusinessType(e.target.value);
                                setFormData(
                                    {
                                        ...formData,
                                        businessType: businessTypes.find((type) => type.id == e.target.value).name,
                                    }
                                );
                            }}
                            input={<OutlinedInput />}
                        >
                            {businessTypes.map(
                                (t) => {
                                    return (
                                        <MenuItem
                                            key={t.id}
                                            value={t.id}
                                        >
                                            <i className={t.id == businessType ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: t.id == businessType ? "green" : "grey" }}></i>
                                            {t.name}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    };

    const renderAddressForm = () => {
        const hasPrimary: boolean = formData.addresses && !!formData.addresses.find((a) => a.addressTypes.find((at) => at.name == "Primary"));
        const hasBilling: boolean = formData.addresses && !!formData.addresses.find((a) => a.addressTypes.find((at) => at.name == "Billing"));
        const hasShipping: boolean = formData.addresses && !!formData.addresses.find((a) => a.addressTypes.find((at) => at.name == "Shipping"));

        return (
            <Grid container direction="column" style={{ minWidth: 800 }}>
                <h1>Addresses</h1>
                <Grid item className="address-form">
                    <CompanyAddresses resellerInfoForm={true} resellerFormData={formData} setResellerFormData={setFormData} company={currentUser.company} autoShowForm={!(hasPrimary && hasBilling && hasShipping)} />
                </Grid>
                <h3>Required Addresses</h3>
                <Grid item container spacing={2} justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasPrimary ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasPrimary ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Primary</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasBilling ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasBilling ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Billing</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasShipping ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasShipping ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Shipping</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {errorsOn ? <Grid item xs={12} style={{ backgroundColor: "pink", border: "1px solid tomato", padding: 12 }}>
                    Each address type is required. Addresses can fulfil multiple types.
                </Grid> : ""}
            </Grid>
        );
    };

    const renderContactsForm = () => {
        const hasPrimary: boolean = formData.contacts && !!formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Primary"));
        const hasBilling: boolean = formData.contacts && !!formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Billing"));
        const hasMarketing: boolean = formData.contacts && !!formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Marketing"));
        const hasPurchasing: boolean = formData.contacts && !!formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Purchasing"));
        const hasSales: boolean = formData.contacts && !!formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Sales"));

        return (
            <Grid container direction="column" style={{ minWidth: 800 }}>
                <h1>Contacts </h1>
                <Grid item className="contact-form">
                    <CompanyContacts autoOpenContact={badContact} resellerInfoForm={true} resellerFormData={formData} setResellerFormData={setFormData} company={currentUser.company} />
                </Grid>
                <h3>Required Contacts</h3>
                <Grid item container spacing={2} justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasPrimary ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasPrimary ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Primary</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasBilling ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasBilling ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Billing</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasMarketing ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasMarketing ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Marketing</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasPurchasing ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasPurchasing ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Purchasing</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid item container direction="column">
                            <Grid item>
                                <i className={hasSales ? "far fa-check green" : "far fa-close tomato"} style={{ fontSize: "2em", color: hasSales ? "green" : "tomato" }}></i>
                            </Grid>
                            <Grid item>
                                <p>Sales</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {errorsOn ? <Grid item xs={12} style={{ backgroundColor: "pink", border: "1px solid tomato", padding: 12 }}>
                    Each contact type is required. Contacts can fulfil multiple types. <b>Each contact needs at least a name, a type, and an email.</b>
                </Grid> : ""}
            </Grid>
        );
    };

    const renderMarketingFormOne = () => {
        return (
            <Grid container>
                <h1>Marketing Information</h1>
                <Grid item container spacing={2} justifyContent="space-around" alignItems="center">
                    <Grid item xs={12}>
                        <TextField
                            key="primaryBusinessDescription"
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            value={formData.businessDescription}
                            label={"Primary Business Description"}
                            error={
                                !formData.businessDescription && errorsOn ? true : false
                            }
                            onChange={
                                (e) => setFormData(
                                    {
                                        ...formData,
                                        businessDescription: e.target.value
                                    }
                                )
                            }
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="website"
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            value={formData.website}
                            label={"Website"}
                            error={
                                !formData.website && errorsOn ? true : false
                            }
                            onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What ways do you connect with customers? (check all that apply)</h3>
                            <Select
                                key="connections"
                                label="What ways do you connect with customers? (check all that apply)"
                                multiple
                                value={formData.connections}
                                renderValue={() => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                        {formData.connections.map((value) => (
                                            value.length < 1 ? <></> : <Chip key={"ello" + value} label={value} style={{ marginRight: 5 }} />
                                        ))}
                                    </Box>
                                )}
                                onChange={(e: React.ChangeEvent<{
                                    name?: string;
                                    value: string[];
                                }>) => {
                                    let newConnections = [...e.target.value];
                                    newConnections = newConnections.filter((s) => s.length > 1);
                                    setFormData({ ...formData, connections: newConnections });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {resellerConnectionOptions.map(
                                    (name) => {
                                        const active = formData.connections.findIndex((n) => n == name) > -1;
                                        return (
                                            <MenuItem
                                                key={"conn" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>Do you utilize any of the following thrid-party ad platforms? (check all that apply)</h3>
                            <Select
                                key="3rd-party"
                                label="Do you utilize any of the following thrid-party ad platforms? (check all that apply)"
                                multiple
                                value={formData.thirdPartyMarketing}
                                renderValue={() => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                        {formData.thirdPartyMarketing.map((value) => (
                                            value.length < 1 ? <div key="no3"></div> : <Chip key={"3rd" + value} label={value} style={{ marginRight: 5 }} />
                                        ))}
                                    </Box>
                                )}
                                onChange={(e: React.ChangeEvent<{
                                    name?: string;
                                    value: string[];
                                }>) => {
                                    let newConnections = [...e.target.value];
                                    newConnections = newConnections.filter((s) => s.length > 1);
                                    setFormData({ ...formData, thirdPartyMarketing: newConnections });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {thirdPartyMarketingOptions.map(
                                    (name) => {
                                        const active = formData.thirdPartyMarketing.findIndex((n) => n == name) > -1;
                                        return (
                                            <MenuItem
                                                key={"3rdp" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What is your primary line of business (select one)</h3>
                            <Select
                                key="primaryBusiness"
                                label="What is your primary line of business (select one)"
                                value={formData.primaryLineOfBusiness}
                                error={
                                    !formData.primaryLineOfBusiness && errorsOn ? true : false
                                }
                                onChange={(e: React.ChangeEvent<{
                                    name?: string;
                                    value: string;
                                }>) => {
                                    setFormData({ ...formData, primaryLineOfBusiness: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {primaryLineOfBusinessOptions.map(
                                    (name) => {
                                        const active = formData.primaryLineOfBusiness == name;
                                        return (
                                            <MenuItem
                                                key={"plb" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>Do you have a retail store?</h3>
                            <ToggleButtonGroup
                                value={formData.retailStore}
                                exclusive
                                onChange={(e, v) => setFormData({ ...formData, retailStore: !formData.retailStore })}
                            >
                                <ToggleButton value={false}>
                                    No
                                </ToggleButton>
                                <ToggleButton value={true}>
                                    Yes
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>Do you have a showroom?</h3>
                            <ToggleButtonGroup
                                value={formData.showRoom}
                                exclusive
                                onChange={(e, v) => setFormData({ ...formData, showRoom: !formData.showRoom })}
                            >
                                <ToggleButton value={false}>
                                    No
                                </ToggleButton>
                                <ToggleButton value={true}>
                                    Yes
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderMarketingFormTwo = () => {
        return (
            <Grid container>
                <h1>Marketing Cont.</h1>
                <Grid item xs={12}>
                    <p style={{ fontStyle: "italic", marginBottom: 0 }}>
                        Please estimate your sales for the following use cases: a best guess is fine!
                    </p>
                </Grid>
                <Grid item container spacing={2} justifyContent="space-around" alignItems="center">
                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What percentage of your business is Production based?</h3>
                            <Select
                                key="prodPercent"
                                value={formData.percentProduction}
                                error={
                                    !formData.percentProduction && errorsOn ? true : false
                                }
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    setFormData({ ...formData, percentProduction: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {percentBusinessOptions.map(
                                    (name) => {
                                        const active = formData.percentProduction == name;
                                        return (
                                            <MenuItem
                                                key={"prod" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What percentage of your business is Theatrical based?</h3>
                            <Select
                                key="theaterPercent"
                                value={formData.percentTheatrical}
                                error={
                                    !formData.percentTheatrical && errorsOn ? true : false
                                }
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    setFormData({ ...formData, percentTheatrical: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {percentBusinessOptions.map(
                                    (name) => {
                                        const active = formData.percentTheatrical == name;
                                        return (
                                            <MenuItem
                                                key={"thea" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What percentage of your business is Worship based?</h3>
                            <Select
                                key="worshipPercent"
                                value={formData.percentWorship}
                                error={
                                    !formData.percentWorship && errorsOn ? true : false
                                }
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    setFormData({ ...formData, percentWorship: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {percentBusinessOptions.map(
                                    (name) => {
                                        const active = formData.percentWorship == name;
                                        return (
                                            <MenuItem
                                                key={"wor" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What percentage of your business is DJ based?</h3>
                            <Select
                                key="djPercent"
                                value={formData.percentDJ}
                                error={
                                    !formData.percentDJ && errorsOn ? true : false
                                }
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    setFormData({ ...formData, percentDJ: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {percentBusinessOptions.map(
                                    (name) => {
                                        const active = formData.percentDJ == name;
                                        return (
                                            <MenuItem
                                                key={"dj" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What percentage of your business is Architectural based?</h3>
                            <Select
                                key="archPercent"
                                value={formData.percentArchitectural}
                                error={
                                    !formData.percentArchitectural && errorsOn ? true : false
                                }
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    setFormData({ ...formData, percentArchitectural: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {percentBusinessOptions.map(
                                    (name) => {
                                        const active = formData.percentArchitectural == name;
                                        return (
                                            <MenuItem
                                                key={"arch" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                            <h3>What percentage of your business is something other than what&apos;s listed above?</h3>
                            <Select
                                key="otherPercent"
                                value={formData.percentOther}
                                error={
                                    !formData.percentOther && errorsOn ? true : false
                                }
                                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                                    setFormData({ ...formData, percentOther: e.target.value });
                                }}
                                input={<OutlinedInput />}
                                MenuProps={MenuProps}
                            >
                                {percentBusinessOptions.map(
                                    (name) => {
                                        const active = formData.percentOther == name;
                                        return (
                                            <MenuItem
                                                key={"oth" + name}
                                                value={name}
                                            >
                                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                                {name}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const showRoleChart = () => {
        setShowRoleInfo(true);
    };

    const hideRoleChart = () => {
        setShowRoleInfo(false);
    };

    const renderRoleChart = () => {
        return (
            <Dialog
                open={showRoleInfo}
                onClose={hideRoleChart}
            >
                <DialogTitle>
                    Last step!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please take your time to invite users that you'd like to have access to the Portal. <br /><br />
                        The list contains some suggestions based on your contacts, however you'll need to assign them roles and invite them. You can also invite other users that aren't in your contact list. <br /><br />
                        The table below shows what each role can and cannot do. <br /><br />
                    </DialogContentText>
                    <PermissionTable />
                </DialogContent>
                <DialogActions>
                    <Button onClick={hideRoleChart} autoFocus>
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderInviteForm = () => {
        return (
            <Grid container>
                {renderRoleChart()}
                <h1>Invite Members</h1>
                <Grid item xs={12}>
                    <p style={{ textAlign: "left", padding: "0 10px" }}>
                        Now&apos;s the time to invite any other members of your company that may need
                        access to the portal: someone who will be viewing inventory levels or making
                        orders, shipping and receiving, accounting, or marketing.
                    </p>
                </Grid>
                <Grid item container spacing={2} justifyContent="space-around" alignItems="center">
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="right">Role</TableCell>
                                    <TableCell align="right">Invite</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formData.contacts.map((contact: ContactInterface) => {
                                    if (contact.email == currentUser.email) return;
                                    return (
                                        <TableRow key={contact.id}>
                                            <TableCell component="th" scope="row">
                                                {contact.firstName + " " + contact.lastName}
                                            </TableCell>
                                            <TableCell>{contact.email}</TableCell>
                                            <TableCell align="right">
                                                <Info style={{ paddingRight: 4, cursor: "pointer" }} onClick={showRoleChart} />
                                                {renderRoleDropdown(contact.id)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    onClick={() => invited.some((current) => current.id == contact.id) ? null : inviteUser(contact)}
                                                    variant={roleData[contact.id] ? "contained" : "outlined"}
                                                    color={roleData[contact.id] ? "primary" : "secondary"}
                                                    style={{ width: "100%" }}
                                                    disabled={!roleData[contact.id]}
                                                >
                                                    {currentlyInviting.some((current) => current.id == contact.id) ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={24}
                                                        />
                                                    ) : invited.some((current) => current.id == contact.id) ? ("Invited") : ("Invite")
                                                    }
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableRow key={"new-contact-invite"}>
                                    <TableCell component="th" scope="row">
                                        Invite Other
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            key="new-contact-email"
                                            style={{ width: "100%" }}
                                            id="outlined-basic"
                                            value={otherInviteEmail}
                                            label={"Email"}
                                            onChange={
                                                (e) => setOtherInviteEmail(e.target.value)
                                            }
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Info style={{ paddingRight: 4, cursor: "pointer" }} onClick={showRoleChart} />
                                        {renderRoleDropdown(-1)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            onClick={() => inviteOtherUser()}
                                            variant={otherInviteEmail && roleData[-1] ? "contained" : "outlined"}
                                            color={otherInviteEmail && roleData[-1] ? "primary" : "secondary"}
                                            disabled={!otherInviteEmail || !roleData[-1]}
                                            style={{ width: "100%" }}
                                        >
                                            {invitingOther ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={24}
                                                />
                                            ) : ("Invite")
                                            }
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderRoleDropdown = (contactId: number) => {
        return (
            <Select
                key={"role-dropdown-" + contactId}
                value={roleData[contactId]}
                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                    setRoleData({ ...roleData, [contactId]: e.target.value });
                }}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                style={{ minWidth: 200, textAlign: "left" }}
            >
                {accountLevels.map(
                    (role) => {
                        if (role.name == "Super Admin") return;
                        const active = roleData[contactId] == role.name;
                        return (
                            <MenuItem
                                key={role.name + contactId}
                                value={role.name}
                            >
                                <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                                {role.name}
                            </MenuItem>
                        );
                    }
                )}
            </Select>
        );
    };

    const inviteUser = (contact: ContactInterface) => {
        // INVITE USER
        if (roleData[contact.id]) {
            setCurrentlyInviting([...currentlyInviting, contact]);
            makeAxiosCall(
                "post",
                "invite",
                {
                    accountLevelId: accountLevels.find((r) => r.name == roleData[contact.id]).id,
                    companyId: currentUser.company.id,
                    email: contact.email.toLowerCase().trim(),
                }
            )
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        setSnackbar({
                            isOpen: true,
                            message: "This user will be invited once you've completed the onboarding process",
                            severity: "success",
                        });
                    } else {
                        setSnackbar({
                            isOpen: true,
                            message: "Something went wrong. " + res.data.error,
                            severity: "error",
                        });
                    }
                    setCurrentlyInviting(currentlyInviting.filter((current) => current.id !== contact.id));
                    setInvited([...invited, contact]);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.data && err.data.display) {
                        setSnackbar({
                            isOpen: true,
                            message: err.data.error,
                            severity: "error",
                        });
                    }
                    setCurrentlyInviting(currentlyInviting.filter((current) => current.id !== contact.id));
                });
        }
    };

    const inviteOtherUser = () => {
        // INVITE USER
        if (otherInviteEmail && roleData[-1]) {
            setInvitingOther(true);
            makeAxiosCall(
                "post",
                "invite",
                {
                    accountLevelId: accountLevels.find((r) => r.name == roleData[-1]).id,
                    companyId: currentUser.company.id,
                    email: otherInviteEmail.toLowerCase().trim(),
                }
            )
                .then((res) => {
                    setOtherInviteEmail("");
                    if (res.status === 200 || res.status === 201) {
                        setSnackbar({
                            isOpen: true,
                            message: "This user will be invited once you've completed the onboarding process",
                            severity: "success",
                        });
                    } else {
                        setSnackbar({
                            isOpen: true,
                            message: "Something went wrong. " + res.data.error,
                            severity: "error",
                        });
                    }
                    setInvitingOther(false);
                })
                .catch((err) => {
                    setOtherInviteEmail("");
                    setSnackbar({
                        isOpen: true,
                        message: "Something went wrong. " + err.data.error,
                        severity: "error",
                    });
                    setInvitingOther(false);
                });
        }
    };


    const renderStep = () => {
        switch (step) {
            case 1:
                return renderGeneralInfoForm();
            case 2:
                return renderAddressForm();
            case 3:
                return renderContactsForm();
            case 4:
                return renderMarketingFormOne();
            case 5:
                return renderMarketingFormTwo();
            case 6:
                return renderInviteForm();
        }
    };

    const validateContact = (contact: ContactInterface): boolean => {
        return (
            contact && 
            contact.contactTypes && contact.contactTypes.length > 0 && 
            contact.email && contact.email.length > 0 && 
            contact.firstName && contact.firstName.length > 0 && 
            contact.lastName && contact.lastName.length > 0
        );
    };

    const openContact = (contact: ContactInterface) => {

        setSnackbar({
            isOpen: true,
            message: "It looks like this contact doesn't have all the required fields filled out.",
            severity: "error"
        });

        setBadContact(contact);
    }

    const validateStep = () => {
        let valid = false;
        switch (step) {
            case 1: {
                valid = (
                    formData.legalEntityName.length > 0 &&
                    formData.businessType.length > 0
                );
                break;
            }
            case 2: {
                const hasPrimary: boolean = formData.addresses && !!formData.addresses.find((a) => a.addressTypes.find((at) => at.name == "Primary"));
                const hasBilling: boolean = formData.addresses && !!formData.addresses.find((a) => a.addressTypes.find((at) => at.name == "Billing"));
                const hasShipping: boolean = formData.addresses && !!formData.addresses.find((a) => a.addressTypes.find((at) => at.name == "Shipping"));

                valid = (hasPrimary && hasBilling && hasShipping);
                break;
            }
            case 3: {
                if(!formData.contacts) return false;
                const primaryContact = formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Primary"));
                const hasPrimaryContact: boolean = formData.contacts && validateContact(primaryContact);

                if(primaryContact && !hasPrimaryContact) {
                    openContact(primaryContact);
                }

                const billingContact = formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Billing"));
                const hasBillingContact: boolean = formData.contacts && validateContact(billingContact);

                if(billingContact && !hasBillingContact) {
                    openContact(billingContact);
                }

                const marketingContact = formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Marketing"));
                const hasMarketing: boolean = formData.contacts && validateContact(marketingContact);

                if(marketingContact && !hasMarketing) {
                    openContact(marketingContact);
                }

                const purchasingContact = formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Purchasing"));
                const hasPurchasing: boolean = formData.contacts && validateContact(purchasingContact);

                if(purchasingContact && !hasPurchasing) {
                    openContact(purchasingContact);
                }

                const salesContact = formData.contacts.find((a) => a.contactTypes.find((at) => at.name == "Sales"));
                const hasSales: boolean = formData.contacts && validateContact(salesContact);

                if(salesContact && !hasSales) {
                    openContact(salesContact);
                }

                if(hasPrimaryContact && hasBillingContact && hasMarketing && hasPurchasing && hasSales) {
                    if(badContact) setBadContact(null);
                    valid = true;
                } else {
                    valid = false;
                }
                break;
            }
            case 4: {
                valid = (
                    formData.businessDescription.length > 0 &&
                    formData.website.length > 0 &&
                    formData.primaryLineOfBusiness.length > 0
                );
                break;
            }
            case 5: {
                valid = (
                    formData.percentProduction.length > 0 &&
                    formData.percentTheatrical.length > 0 &&
                    formData.percentWorship.length > 0 &&
                    formData.percentDJ.length > 0 &&
                    formData.percentArchitectural.length > 0 &&
                    formData.percentOther.length > 0
                );
                break;
            }
        }

        setErrorsOn(!valid);
        return valid;
    };

    return (
        <div className="reseller-info-form-wrapper">
            {renderProgressBar()}
            {step === 1 && (
                <div style={{ width: "100%" }}>
                    <h1>Reseller Info Form</h1>
                    <p>Please complete this form to help us serve you better.</p>
                </div>
            )}
            <div className="form-container">
                {renderStep()}

                <Snackbar
                    open={snackbar.isOpen}
                    autoHideDuration={3000}
                    onClose={(_, reason) => {
                        if (reason === "clickaway") {
                            return;
                        }

                        setSnackbar({ ...snackbar, isOpen: false });
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert
                        severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </div>
            {renderNavigation()}

        </div>
    );
};

export default ResellerInfo;
